import React, { useEffect, useState } from 'react';
import Dropdown from 'react-dropdown';
import TextareaAutosize from 'react-textarea-autosize';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import Loader from 'react-loader-spinner';
import map from 'lodash/map';
import find from 'lodash/find';
import filter from 'lodash/filter';
import includes from 'lodash/includes';

import { AppState } from '../state/reducers';
import Footer from '../components/Footer';
import { actions as hospitalAccessCodeActions } from '../state/hospitalAccessCodes';
import { selectors as hospitalsSelectors } from '../state/hospitals';
import { selectors as settingsSelectors } from '../state/settings';
import { actions as apiActions } from '../state/api';
import { formValidation, useFormFieldValidation } from '../utils/formValidation';
import { options as optionsSchema, text as textSchema } from '../utils/schemas';
import getUnixTime from '../utils/getUnixTime';
import Navigation from '../components/Navigation';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRightHeader from '../components/DrawerRightHeader';
import Drawer from '../components/Drawer';
import Button from '../components/Button';
import Colors from '../theme/Colors';

import '../css/HospitalAccessCodeAdd.css';

type TFormErrors = {
  hospitalId?: string;
  note?: string;
};

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const textareaStyle = {
  width: '100%',
  maxWidth: 1500,
  padding: 5,
};

const HospitalAccessCodeAdd = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [errors, setErrors] = useState<TFormErrors>({});
  const [submitted, setSubmitted] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const hospitals = useSelector((state: AppState) => hospitalsSelectors.getAll(state));

  const hospitalGroupAccessCodes = useSelector(
    (state: AppState) => settingsSelectors.getAllHospitalGroupAccessCodes(state),
  );

  const allowedHospitals = filter(hospitals, ({ hospitalGroup }) => (
    includes(hospitalGroupAccessCodes, hospitalGroup)
  ));

  const hospitalOptions = (
    map(allowedHospitals, (hospital) => ({ label: hospital.name, value: hospital.id }))
  );

  const hospitalSchema = optionsSchema(
    true,
    map(hospitalOptions, 'value'),
    t('errors:field-required-web', { field: t('hospital') }),
  );
  const noteSchema = textSchema(false);

  const [hospitalId, setHospitalId] = useFormFieldValidation(
    hospitalSchema,
    'hospital',
    '',
    submitted,
    errors,
    setErrors,
  );

  const [note, setNote] = useFormFieldValidation(
    noteSchema,
    'note',
    '',
    submitted,
    errors,
    setErrors,
  );

  const hospitalAccessCodeAdd = () => {
    setLoading(true);
    const schema = yup
      .object()
      .shape({
        hospitalId: hospitalSchema,
        note: noteSchema,
      });

    setSubmitted(true);
    setErrors({});

    if (!formValidation(schema, { hospitalId, note }, setErrors)) {
      setLoading(false);
      return false;
    }

    const hospital = find(allowedHospitals, { id: hospitalId });

    if (!hospital) {
      setLoading(false);
      return false;
    }
    setDisabled(true);
    return dispatch(apiActions.getUniqueHospitalAccessCode({
      hospitalId: hospital.id,
      onSuccess: (uniqueId: string) => {
        dispatch(hospitalAccessCodeActions.add([{
          id: uuidv4(),
          createdAt: getUnixTime(),
          type: 'trends',
          active: true,
          note,
          hospitalId: hospital.id,
          numberOfViews: 0,
          period: 'year',
          shareKey: uniqueId,
        }]));
        setDisabled(false);
        setLoading(false);
        return history.goBack();
      },
      onError: (error: any) => {
        if (__DEV__) {
          console.log('[hospitalAccessCodeAdd]', 'error', error); // eslint-disable-line no-console
        }
        setDisabled(false);
        setLoading(false);
        return false;
      },
    }));
  };

  useEffect(() => {
    if (!hospitalId) {
      return setDisabled(true);
    }

    const hospital = find(allowedHospitals, { id: hospitalId });

    if (!hospital) {
      return setDisabled(true);
    }

    return setDisabled(false);
  }, [hospitalId]);

  return (
    <article className="page change-password row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('my-shared-data')} showRightButton />
          <div className="share-hospital-container">
            <section className="content">
              <Dropdown
                className="dropdown"
                options={hospitalOptions}
                onChange={(option) => setHospitalId(option.value)}
                value={hospitalId}
                placeholder={t('hospital')}
              />
              { errors.hospitalId && <p className="body error">{t(errors.hospitalId)}</p> }
            </section>
            <section className="content">
              <TextareaAutosize
                placeholder={t('placeholders.note')}
                style={textareaStyle}
                minRows={5}
                onChange={(
                  event: React.ChangeEvent<HTMLTextAreaElement>,
                ) => setNote(event.currentTarget.value)}
              />
              { errors.note && <p className="body error">{t(errors.note)}</p> }
            </section>
            {/* <Button
              labelText={t('send')}
              onClick={hospitalAccessCodeAdd}
              disabled={disabled}
              size="medium"
            /> */}
            <Button
              onClick={hospitalAccessCodeAdd}
              disabled={disabled}
              size="medium"
            >
              {
              loading
                ? <Loader type="TailSpin" color={Colors.white} height={20} width={20} />
                : t('send')
            }
            </Button>
          </div>
        </section>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default HospitalAccessCodeAdd;

// ---------------------------------------------  Previous component with old design ------------------------------------------------- //
// import React, { useState } from 'react';
// import Dropdown from 'react-dropdown';
// import TextareaAutosize from 'react-textarea-autosize';
// import * as yup from 'yup';
// import { useHistory } from 'react-router-dom';
// import { useSelector, useDispatch } from 'react-redux';
// import { useTranslation } from 'react-i18next';
// import { v4 as uuidv4 } from 'uuid';
// import map from 'lodash/map';
// import find from 'lodash/find';
// import filter from 'lodash/filter';
// import includes from 'lodash/includes';

// import { AppState } from '../state/reducers';

// import '../css/HospitalAccessCodeAdd.css';
// import Header from '../components/Header';
// import PageHeader from '../components/PageHeader';
// import Footer from '../components/Footer';
// import { actions as hospitalAccessCodeActions } from '../state/hospitalAccessCodes';
// import { selectors as hospitalsSelectors } from '../state/hospitals';
// import { selectors as settingsSelectors } from '../state/settings';
// import { formValidation, useFormFieldValidation } from '../utils/formValidation';
// import { options as optionsSchema, text as textSchema } from '../utils/schemas';
// import getUnixTime from '../utils/getUnixTime';

// type TFormErrors = {
//   hospitalId?: string;
//   note?: string;
// };

// const HospitalAccessCodeAdd = () => {
//   const history = useHistory();

//   const dispatch = useDispatch();

//   const { t } = useTranslation();

//   const [errors, setErrors] = useState<TFormErrors>({});
//   const [submitted, setSubmitted] = useState(false);

//   const hospitals = useSelector((state: AppState) => hospitalsSelectors.getAll(state));

//   const hospitalGroupAccessCodes = useSelector(
//     (state: AppState) => settingsSelectors.getAllHospitalGroupAccessCodes(state),
//   );

//   const allowedHospitals = filter(hospitals, ({ hospitalGroup }) => (
//     includes(hospitalGroupAccessCodes, hospitalGroup)
//   ));

//   const hospitalOptions = (
//     map(allowedHospitals, (hospital) => ({ label: hospital.name, value: hospital.id }))
//   );

//   const hospitalSchema = optionsSchema(
//     true,
//     map(hospitalOptions, 'value'),
//     t('errors:field-required-web', { field: t('hospital') }),
//   );
//   const noteSchema = textSchema(false);

//   const [hospitalId, setHospitalId] = useFormFieldValidation(
//     hospitalSchema,
//     'hospital',
//     '',
//     submitted,
//     errors,
//     setErrors,
//   );

//   const [note, setNote] = useFormFieldValidation(
//     noteSchema,
//     'note',
//     '',
//     submitted,
//     errors,
//     setErrors,
//   );

//   const hospitalAccessCodeAdd = () => {
//     const schema = yup
//       .object()
//       .shape({
//         hospitalId: hospitalSchema,
//         note: noteSchema,
//       });

//     setSubmitted(true);
//     setErrors({});

//     if (!formValidation(schema, { hospitalId, note }, setErrors)) {
//       return false;
//     }

//     const hospital = find(allowedHospitals, { id: hospitalId });

//     if (!hospital) {
//       return false;
//     }

//     dispatch(hospitalAccessCodeActions.add([{
//       id: uuidv4(),
//       createdAt: getUnixTime(),
//       type: 'trends',
//       active: true,
//       note,
//       hospitalId: hospital.id,
//       numberOfViews: 0,
//       period: 'year',
//     }]));

//     return history.push('/settings/share-my-data');
//   };

//   return (
//     <article className="app hospital-access-code-add">
//       <Header auth documentTitle={t('my-shared-data')} />
//       <PageHeader title={t('my-shared-data')} />
//       <section className="content">
//         <section>
//           <h1 className="heading">{t('hospital')}</h1>
//           <Dropdown
//             options={hospitalOptions}
//             onChange={(option) => setHospitalId(option.value)}
//             value={hospitalId}
//             placeholder={t('hospital')}
//           />
//           {
//             errors.hospitalId && <p className="body error">{t(errors.hospitalId)}</p>
//           }
//         </section>
//         <section>
//           <h1 className="heading">{t('note')}</h1>
//           <TextareaAutosize
//             minRows={5}
//             onChange={(
//               event: React.ChangeEvent<HTMLTextAreaElement>,
//             ) => setNote(event.currentTarget.value)}
//           />
//           {
//             errors.note && <p className="body error">{t(errors.note)}</p>
//           }
//         </section>
//         <button className="button accent" type="button" onClick={hospitalAccessCodeAdd}>
//           {t('save')}
//         </button>
//       </section>
//       <Footer />
//     </article>
//   );
// };

// export default HospitalAccessCodeAdd;
