import toArray from 'lodash/toArray';
import size from 'lodash/size';
import get from 'lodash/get';
import countBy from 'lodash/countBy';
import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';
import first from 'lodash/first';
import compact from 'lodash/compact';

import {
  TReportType,
  TSortOrder,
  TReport,
} from '../../../types';

import { AppState } from '../reducers';
import { ReportsState } from './types';
import EMPTY_OBJECT from '../../utils/empty-object';
import createDeepEqualSelector from '../../selectors/createDeepEqualSelector';
import localConfig from '../../config';

const sort = (
  collection: TReport[],
  key = 'createdAt',
  order = 'desc' as TSortOrder,
): TReport[] => (
  orderBy(collection, [key], [order])
);

const getAllSelector = (state: ReportsState) => toArray(state);

const getAll = createDeepEqualSelector(
  (state: AppState) => state.reports,
  getAllSelector,
);

const getByIdSelector = (state: ReportsState, id: string) => (
  get(state, [id]) || EMPTY_OBJECT
);

const makeGetById = () => (
  createDeepEqualSelector(
    (state: AppState) => state.reports,
    (state: any, id: string) => id,
    getByIdSelector,
  )
);

const countSelector = (state: ReportsState) => size(state);

const count = createDeepEqualSelector(
  (state: AppState) => state.reports,
  countSelector,
);

const countsSelector = (state: ReportsState) => ({
  total: size(state),
  ...countBy(state, 'type'),
});

const counts = createDeepEqualSelector(
  (state: AppState) => state.reports,
  countsSelector,
);

const getByTypeSelector = (state: ReportsState, type: TReportType) => sort(filter(state, { type }));

const makeGetByType = () => (
  createDeepEqualSelector(
    (state: AppState) => state.reports,
    (state: any, type: TReportType) => type,
    getByTypeSelector,
  )
);

const getMostRecentByTypeSelector = (state: ReportsState) => {
  const decisionAidReport = first(getByTypeSelector(state, 'decisionAid-full'));
  const treatmentFullReport = first(getByTypeSelector(state, 'treatment-full'));
  const treatmentSummaryReport = first(getByTypeSelector(state, 'treatment-summary'));
  const diagnosticReport = first(getByTypeSelector(state, 'diagnostic-full'));

  if (localConfig.isPC) {
    return compact([decisionAidReport, diagnosticReport, treatmentFullReport]);
  }

  return compact([treatmentFullReport, treatmentSummaryReport]);
};

const getMostRecentByType = createDeepEqualSelector(
  (state: AppState) => state.reports,
  getMostRecentByTypeSelector,
);

export {
  getAll,
  makeGetById,
  count,
  counts,
  makeGetByType,
  getMostRecentByType,
};
