import React from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '../state/reducers';
import { selectors as runtimeSelectors } from '../state/runtime';
import { SyncInProgressIcon, SyncStaleIcon } from '../theme/Icons';
import '../css/Sync.css';

const Sync = () => {
  const sync = useSelector(
    (state: AppState) => runtimeSelectors.getSync(state),
  );

  if (sync.userSyncInProgress || sync.systemSyncInProgress) {
    return (
      <div className="sync animated">
        {SyncInProgressIcon}
      </div>
    );
  }

  if (sync.userSyncStale || sync.systemSyncStale) {
    return (
      <div className="sync">
        {SyncStaleIcon}
      </div>
    );
  }

  return null;
};

export default Sync;
