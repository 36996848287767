/* eslint react/require-default-props: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Path } from 'react-native-svg';
import ResizeableSVG from '../ResizeableSVG';

import Colors from '../../theme/Colors';
import Constants from '../../theme/Constants';

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

type Props = {
  color?: string;
  size?: number;
};

const Message = (props: Props) => {
  const {
    color = Colors.primary,
    size = Constants.defaultIconSize,
  } = props;

  return (
    <ResizeableSVG size={size}>
      <Path d="M24.2917981,5.9 L3.68191377,5.9 C2.47266036,5.9 1.5,6.87266036 1.5,8.08191377 L1.5,19.964143 C1.5,21.1733964 2.47266036,22.1460568 3.68191377,22.1460568 L24.3180862,22.1460568 C25.5273396,22.1460568 26.5,21.1733964 26.5,19.964143 L26.5,8.08191377 C26.4737119,6.87266036 25.5010515,5.9 24.2917981,5.9 Z M25.159306,8.08191377 L25.159306,19.964143 C25.159306,20.0167192 25.1330179,20.0430074 25.1330179,20.0955836 L18.0878023,13.8127234 L25.1330179,7.9767613 C25.1330179,8.02933754 25.159306,8.05562566 25.159306,8.08191377 Z M2.81440589,19.964143 L2.81440589,8.08191377 C2.81440589,8.05562566 2.84069401,8.02933754 2.84069401,7.9767613 L9.88590957,13.8127234 L2.84069401,20.0955836 C2.84069401,20.0430074 2.81440589,20.0167192 2.81440589,19.964143 Z M14.9332282,14.7065195 C14.3811777,15.1534175 13.5925342,15.1534175 13.0404837,14.7065195 L3.99737119,7.24069401 L23.9763407,7.24069401 L14.9332282,14.7065195 Z M10.9111462,14.6802313 L12.1992639,15.731756 C12.7250263,16.1523659 13.3559411,16.388959 13.9868559,16.388959 C14.6177708,16.388959 15.2486856,16.178654 15.7744479,15.731756 L17.0625657,14.6802313 L23.9763407,20.8053628 L3.99737119,20.8053628 L10.9111462,14.6802313 Z" fill={color} fillRule="evenodd" />
    </ResizeableSVG>
  );
};

Message.propTypes = propTypes;

export default Message;
