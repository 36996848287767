import localforage from 'localforage';

const getApiTokenAsync = async (): Promise<string> => {
  const token = await localforage.getItem('@api/token') as string;

  if (!token) {
    if (__DEV__) {
      console.log('[getApiTokenAsync]', 'No api token'); // eslint-disable-line no-console
    }

    return Promise.reject(new Error('No api token'));
  }

  return token;
};

export default getApiTokenAsync;
