import map from 'lodash/map';
import fpPick from 'lodash/fp/pick';
import countBy from 'lodash/countBy';
import get from 'lodash/get';

import { AppState } from '../state/reducers';

import createDeepEqualSelector from './createDeepEqualSelector';

const getSettingsSelector = (state: AppState) => map(state.settings.trendIndicators, fpPick(['id', 'order', 'disabled', 'favorite']));

const getSettings = createDeepEqualSelector(
  getSettingsSelector,
  (state) => state,
);

const getMaxReachedFavoriteTrendIndicators = createDeepEqualSelector(
  [getSettings],
  // Maximum favorites is 5
  (settings) => get(countBy(settings, 'favorite'), 'true') >= 5,
);

export default getMaxReachedFavoriteTrendIndicators;
