import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { selectors as appointmentsSelectors } from '../state/appointments';
import { selectors as notesSelectors } from '../state/notes';
import { selectors as photosSelectors } from '../state/photos';
import { selectors as audioRecordingsSelectors } from '../state/audioRecordings';
import { selectors as messagesSelectors } from '../state/messages';
import { selectors as pushNotificationsSelectors } from '../state/notifications';
import { AppState } from '../state/reducers';

type TileContentProps = {
  date: Date;
};

const DiaryCalendarTile = (props: TileContentProps) => {
  const { date } = props;

  const memoizedAppointmentsGetByDateSelector = useMemo(
    appointmentsSelectors.makeGetByDate,
    [],
  );

  const appointments = useSelector(
    (state: AppState) => memoizedAppointmentsGetByDateSelector(state, date),
  );

  const memoizedNotesGetByDate = useMemo(
    notesSelectors.makeGetByDate,
    [],
  );

  const notes = useSelector(
    (state: AppState) => memoizedNotesGetByDate(state, date),
  );

  const memoizedMessagesGetByDate = useMemo(
    messagesSelectors.makeGetByDate,
    [],
  );

  const messages = useSelector(
    (state: AppState) => memoizedMessagesGetByDate(state, date),
  );

  const memoizePushNotificationsGetByDateSelector = useMemo(
    pushNotificationsSelectors.makeGetByDate,
    [],
  );

  const pushNotifications = useSelector(
    (state: AppState) => memoizePushNotificationsGetByDateSelector(state, date),
  );

  const memoizedPhotosGetByDate = useMemo(
    photosSelectors.makeGetByDate,
    [],
  );

  const photos = useSelector(
    (state: AppState) => memoizedPhotosGetByDate(state, date),
  );

  const memoizedAudioRecordingsGetByDate = useMemo(
    audioRecordingsSelectors.makeGetByDate,
    [],
  );

  const audioRecordings = useSelector(
    (state: AppState) => memoizedAudioRecordingsGetByDate(state, date),
  );

  const pushAndMessages = [...pushNotifications, ...messages].sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  );

  const activity = (
    !isEmpty(appointments)
    || !isEmpty(notes)
    || !isEmpty(pushAndMessages)
    || !isEmpty(photos)
    || !isEmpty(audioRecordings)
  );

  if (!activity) {
    return null;
  }

  return <div className="dot"><div /></div>;
};

export default React.memo(DiaryCalendarTile);
