import React from 'react';

type Props = {
  children: React.ReactNode;
  errorHandler: (error: Error, errorInfo: React.ErrorInfo) => void;
};

type ErrorState = {
  error?: null | Error;
};

class ErrorBoundary extends React.Component<Props, ErrorState> {
  state = {
    error: undefined,
  };

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const { errorHandler } = this.props;

    if (errorHandler) {
      errorHandler(error, errorInfo);
    }
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return <h1>Something went wrong.</h1>;
    }

    return children;
  }
}

export default ErrorBoundary;
