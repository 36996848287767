import {
  takeLatest,
  delay,
  put,
  select,
} from 'redux-saga/effects';
import localforage from 'localforage';
import Crypto from 'crypto-js';
import isFunction from 'lodash/isFunction';
import get from 'lodash/get';
import noop from 'lodash/noop';
import includes from 'lodash/includes';
import toLower from 'lodash/toLower';
import random from 'lodash/random';
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import keys from 'lodash/keys';
import reduce from 'lodash/reduce';

import authApi from '../services/authApi';
import api from '../services/api';
import { actions as tokenActions } from '../state/token';
import { actions as photosActions } from '../state/photos';

import {
  getLocale as i18nGetLocale,
  changeLanguageAsync as i18nChangeLanguageAsync,
} from '../services/i18n';
import {
  actionTypes as apiActionTypes,
  ApiAuthRegisterActionType,
  ApiAuthLoginActionType,
  ApiAuthResetActionType,
  ApiAuthPasswordActionType,
  ApiValidateInviteActionType,
  ApiShareTrendsActionType,
  ApiShareCustomTrendsActionType,
  ApiShareCustomTrendsByIdActionType,
  ApiBLOBPostActionType,
  ApiDeactivateAccountActionType,
  ApiDeleteAccountActionType,
  ApiGetUniqueHospitalAccessCodeType,
  ApiValidateNavigationActionType,
} from '../state/api';
import { actions as authActions } from '../state/auth';
import { DrugModuleData, actions as settingsActions, selectors as settingsSelectors } from '../state/settings';
import { actions as UIActions } from '../state/ui';
import { syncUserData } from './watchSync';
import { actions as tagsActions } from '../state/tags';
// import getProfileProgressSelector from '../selectors/getProfileProgress';
import formatLocale from '../utils/formatLocale';
import getBrowserLanguage from '../utils/getBrowserLanguage';
import getApiTokenAsync from '../utils/getApiTokenAsync';

import invitationCodesJSON from '../../data/invitation-codes.json';

import localConfig from '../config';
import invitationCodePatterns from '../../data/invitationCodePatterns';
import testInvitationCodeByRegex from '../utils/testInvitationCodeByRegex';
import getConfigAsync from '../utils/getConfigAsync';
import getEmailAsync from '../utils/getEmailAsync';
import getUnixTime from '../utils/getUnixTime';
import { TContentModule, TDrugModule, TPhoto } from '../../types';
import testInvitationCodeByArray from '../utils/testInvitationCodeByArray';
import azInvitationCodes from '../../data/azInvitationCodes';
import checkIsSupportedDrugModule from '../utils/checkIsSupportedDrugModule';

function* authRegister(action: ApiAuthRegisterActionType): Generator<any, any, any> {
  const {
    payload: {
      email: actionEmail,
      password,
      environment,
      additionalData,
      onSuccess: actionOnSuccess,
      onError: actionOnError,
    },
  } = action;

  const email = toLower(actionEmail);
  const onSuccess = isFunction(actionOnSuccess) ? actionOnSuccess : noop;
  const onError = isFunction(actionOnError) ? actionOnError : noop;

  if (!email) {
    if (__DEV__) {
      console.log('[authRegister]', 'Missing email'); // eslint-disable-line no-console
    }

    return onError({ code: 'err_malformed_email' });
  }

  if (!password) {
    if (__DEV__) {
      console.log('[authRegister]', 'Missing password'); // eslint-disable-line no-console
    }

    return onError({ code: 'err_malformed_request' });
  }

  if (!isEmpty() && !isPlainObject(additionalData)) {
    if (__DEV__) {
      console.log('[authRegister]', 'Invalid additionalData', additionalData); // eslint-disable-line no-console
    }

    return onError({ code: 'err_malformed_request' });
  }

  try {
    const invitationCodes = yield select(settingsSelectors.getAllInvitationCodes);

    const activeDrugContentModules = yield select(
      settingsSelectors.getDrugContentModules,
    );

    const treatments = reduce(keys(
      activeDrugContentModules,
    ) as TContentModule[], (acc, moduleName) => {
      const code = activeDrugContentModules[moduleName].invitationCode!;

      return ({ ...acc, [code]: moduleName });
    }, {});

    const data = {
      email,
      password,
      locale: i18nGetLocale(),
      additionalData: {
        ...additionalData,
        invitationCodes,
        treatments,
      },
      requestDocumentId: localConfig.document.id,
      deviceLocale: formatLocale(getBrowserLanguage()),
    };

    // Make sure environment setting does not exist
    yield localforage.removeItem('@Settings:environment');

    // Create setting when not using prod env. This setting is used in the authApi
    // interceptor.
    if (
      environment
      && environment !== 'prod'
      && includes(localConfig.environments, environment)
    ) {
      yield localforage.setItem('@Settings:environment', environment);
    }

    const response = yield authApi.post('/register', data);

    if (response.status !== 204) {
      if (__DEV__) {
        console.log('[authRegister]', 'Received invalid HTTP status code', response); // eslint-disable-line no-console
      }

      const errorCode = get(response, 'data.code');

      return onError({ code: errorCode });
    }

    return onSuccess(response);
  } catch (error: any) {
    if (__DEV__) {
      console.log('[authRegister]', 'catch', error); // eslint-disable-line no-console
      console.log('[authRegister]', 'catch', error.response); // eslint-disable-line no-console
    }

    return onError(error);
  }
}

function* authLogin(action: ApiAuthLoginActionType): Generator<any, any, any> {
  const {
    payload: {
      email: actionEmail,
      password,
      environment,
      onSuccess: actionOnSuccess,
      onError: actionOnError,
    },
  } = action;

  const email = toLower(actionEmail);
  const onSuccess = isFunction(actionOnSuccess) ? actionOnSuccess : noop;
  const onError = isFunction(actionOnError) ? actionOnError : noop;

  if (!email || !password) {
    if (__DEV__) {
      console.log('[authLogin]', 'Missing email and/or password'); // eslint-disable-line no-console
    }

    return onError({ code: 'err_invalid_credentials' });
  }

  const hash = Crypto.SHA256(`${localConfig.document.id}-${email}`);
  const emailHash = hash.toString(Crypto.enc.Hex);

  try {
    const data = {
      emailHash,
      password,
      locale: i18nGetLocale(),
      requestDocumentId: localConfig.document.id,
    };

    // Make sure environment setting does not exist
    yield localforage.removeItem('@Settings:environment');

    // Create setting when not using prod env. This setting is used in the authApi
    // interceptor.
    if (
      environment
      && environment !== 'prod'
      && includes(localConfig.environments, environment)
    ) {
      yield localforage.setItem('@Settings:environment', environment);
    }

    const response = yield authApi.post('/login', data);

    const token = get(response, 'data.token');
    const invitationCodes = get(response, 'data.additionalData.invitationCodes');
    const treatments = get(response, 'data.additionalData.treatments') as Record<string, TContentModule> | undefined;
    const country = get(response, 'data.additionalData.country');

    if (!token) {
      if (__DEV__) {
        console.log('[authLogin]', 'No token'); // eslint-disable-line no-console
      }

      return onError({ code: 'err_invalid_credentials' });
    }

    yield localforage.setItem('@api/token', token);
    yield localforage.setItem('email', email);

    const userSynced = yield* syncUserData(true);

    if (!userSynced) {
      throw new Error('Error loading user data');
    }

    const isFirstLaunch = yield select(
      settingsSelectors.getIsFirstLaunch,
    );

    type TRedirectUrl = {
      pathname: string,
      state: {
        invitationCode: string,
        drugModule: TContentModule,
      }} | string;

    let redirectUrl: TRedirectUrl = '/';

    // const { percentage } = yield select(getProfileProgressSelector);

    // if (percentage === 0) {
    //   redirectUrl = '/settings/profile/start';
    // }

    if (!isEmpty(invitationCodes) && isFirstLaunch) {
      const treatmentInvitationCodes = keys(treatments);

      if (treatments && !isEmpty(treatmentInvitationCodes)) {
        const drugModule = treatments[treatmentInvitationCodes[0]];
        redirectUrl = {
          pathname: `/support-menu/${drugModule}/welcome-sign-in/`,
          state: {
            invitationCode: invitationCodes[0],
            drugModule,
          },
        };

        const drugModulesData = [];

        for (let i = 0; i < treatmentInvitationCodes.length; i += 1) {
          const invitationCode = treatmentInvitationCodes[i];

          drugModulesData.push({
            invitationCode,
            drugModule: treatments[invitationCode],
          });
        }

        yield put(settingsActions.drugModulesAdd(drugModulesData));
      }

      yield put(settingsActions.invitationCodesAdd(invitationCodes));
    }

    if (country) {
      yield put(tagsActions.add([{ key: 'country', value: country }]));
    }

    // Update language according to the available languages and country of signup
    yield i18nChangeLanguageAsync(i18nGetLocale(), country);

    if (country) {
      yield put(settingsActions.generalUpdate({ appLanguage: i18nGetLocale() }));
    }

    yield put(authActions.set(true));

    return onSuccess({ ...response, redirectUrl });
  } catch (error: any) {
    if (__DEV__) {
      console.log('[authLogin]', 'catch', error); // eslint-disable-line no-console
      console.log('[authLogin]', 'catch', error.response); // eslint-disable-line no-console
    }

    return onError(error);
  }
}

function* authReset(action: ApiAuthResetActionType): Generator<any, any, any> {
  const {
    payload: {
      email: actionEmail,
      onSuccess: actionOnSuccess,
      onError: actionOnError,
    },
  } = action;

  const email = toLower(actionEmail);
  const onSuccess = isFunction(actionOnSuccess) ? actionOnSuccess : noop;
  const onError = isFunction(actionOnError) ? actionOnError : noop;

  if (!email) {
    return onError({ code: 'err_malformed_request' });
  }

  try {
    const data = {
      email,
      locale: i18nGetLocale(),
      requestDocumentId: localConfig.document.id,
    };

    const response = yield authApi.post('/reset', data);

    if (response.status !== 204) {
      if (__DEV__) {
        console.log('[authReset]', 'Received invalid HTTP status code', response); // eslint-disable-line no-console
      }

      return onError({ code: 'err_unknown' });
    }

    return onSuccess(response);
  } catch (error: any) {
    if (__DEV__) {
      console.log('[authReset]', 'catch', error); // eslint-disable-line no-console
      console.log('[authReset]', 'catch', error.response); // eslint-disable-line no-console
    }

    return onError(error);
  }
}

function* authPassword(action: ApiAuthPasswordActionType): Generator<any, any, any> {
  const {
    payload: {
      password,
      onSuccess: actionOnSuccess,
      onError: actionOnError,
    },
  } = action;

  const onSuccess = isFunction(actionOnSuccess) ? actionOnSuccess : noop;
  const onError = isFunction(actionOnError) ? actionOnError : noop;

  if (!password) {
    return onError({ code: 'err_malformed_request' });
  }

  try {
    const data = {
      password,
    };

    const token = yield getApiTokenAsync();

    if (!token) {
      if (__DEV__) {
        console.error('[authPassword] Invalid or missing token'); // eslint-disable-line no-console
      }

      throw new Error('Invalid or missing token');
    }

    const headers = {
      authentication: `Bearer ${token}`,
    };

    const response = yield authApi.post('/password', data, { headers });

    if (response.status !== 204) {
      if (__DEV__) {
        console.log('[authPassword]', 'Received invalid HTTP status code', response); // eslint-disable-line no-console
      }

      const errorCode = get(response, 'data.code');

      return onError({ code: errorCode });
    }

    // TODO: Update token somehow

    return onSuccess(response);
  } catch (error: any) {
    if (__DEV__) {
      console.log('[authPassword]', 'catch', error); // eslint-disable-line no-console
      console.log('[authPassword]', 'catch', error.response); // eslint-disable-line no-console
    }

    return onError(error);
  }
}

function* validateInvite(action: ApiValidateInviteActionType): Generator<any, any, any> {
  const {
    payload: {
      code,
      country,
      onSuccess: actionOnSuccess,
      onError: actionOnError,
    },
  } = action;

  const onSuccess = isFunction(actionOnSuccess) ? actionOnSuccess : noop;
  const onError = isFunction(actionOnError) ? actionOnError : noop;

  if (!code) {
    return onError({ code: 'err_invalid_code' });
  }

  try {
    // const data = {
    //   code,
    // };

    // const response = yield api.post('/validate/invite', data);

    // Mock api response delay
    yield delay(random(100, 200));

    // Mock response
    let response = {
      status: 204,
      data: {},
    };

    if (
      !testInvitationCodeByRegex(code, invitationCodePatterns)
      && !testInvitationCodeByArray(code, azInvitationCodes[country])
      && !includes(invitationCodesJSON, toLower(code))) {
      response = {
        status: 400,
        data: {
          code: 'err_invalid_code',
        },
      };
    }

    if (response.status === 400) {
      const errorCode = get(response, 'data.code');

      return onError({ code: errorCode });
    }
    return onSuccess(response);
  } catch (error: any) {
    if (__DEV__) {
      console.log('[validateInvite]', 'catch', error); // eslint-disable-line no-console
      console.log('[validateInvite]', 'catch', error.response); // eslint-disable-line no-console
    }

    return onError(error);
  }
}

function* validateNavigation(action: ApiValidateNavigationActionType): Generator<any, any, any> {
  const {
    payload: {
      contentType,
      onSuccess: actionOnSuccess,
      onError: actionOnError,
    },
  } = action;

  const onSuccess = isFunction(actionOnSuccess) ? actionOnSuccess : noop;
  const onError = isFunction(actionOnError) ? actionOnError : noop;

  if (!contentType) {
    return onError({ code: 'err_empty_contentType' });
  }

  try {
    // Check actions for drug modules
    if (checkIsSupportedDrugModule(contentType)) {
      const activeDrugContentModules: Record<TDrugModule, DrugModuleData> = yield select(
        settingsSelectors.getDrugContentModules,
      );

      if (isEmpty(activeDrugContentModules)) {
        yield put(UIActions.validateNavigationModalShow({ type: 'unavailableContent' }));
        return onError({ code: 'err_unauthorized_navigation' });
      }

      if (activeDrugContentModules[contentType]) {
        // If all checks are passed
        return onSuccess();
      }

      // If all checks are not passed
      yield put(UIActions.validateNavigationModalShow({ type: 'unavailableContent' }));
      return onError({ code: 'err_unauthorized_navigation' });
    }

    // Check actions for non drug modules
    // ....

    return false;
  } catch (error: any) {
    if (__DEV__) {
      console.log('[validateNavigation]', 'catch', error); // eslint-disable-line no-console
      console.log('[validateNavigation]', 'catch', error.response); // eslint-disable-line no-console
    }

    return onError(error);
  }
}

function* BLOBPost(action: ApiBLOBPostActionType): Generator<any, any, any> {
  const {
    payload: {
      data,
      type,
      contentType,
      file,
      onSuccess: actionOnSuccess,
      onError: actionOnError,
    },
  } = action;

  const onSuccess = isFunction(actionOnSuccess) ? actionOnSuccess : noop;
  const onError = isFunction(actionOnError) ? actionOnError : noop;

  const id = get(data, 'id');
  const uri = get(data, 'uri');

  if (
    isEmpty(data)
    || !isPlainObject(data)
    || !id
    || !uri
    || !file
  ) {
    if (__DEV__) {
      console.log('[BLOBPost]', 'Invalid data object', data); // eslint-disable-line no-console
    }

    return onError({ code: 'err_unknown' });
  }

  if (type !== 'photo' && type !== 'audioRecording') {
    if (__DEV__) {
      console.log('[BLOBPost]', 'Invalid type', type); // eslint-disable-line no-console
    }

    return onError({ code: 'err_unknown' });
  }

  if (!contentType) {
    if (__DEV__) {
      console.log('[BLOBPost]', 'Missing contentType', contentType); // eslint-disable-line no-console
    }

    return onError({ code: 'err_unknown' });
  }

  try {
    const responseBLOB = yield api.post('/blob', { size: file.size });

    if (responseBLOB.status !== 200) {
      if (__DEV__) {
        console.log('[BLOBPost]', 'Received invalid HTTP status code', responseBLOB); // eslint-disable-line no-console
      }

      // TODO: Retry failed uploads

      return onError({ code: 'err_unknown' });
    }

    const uploadUrl = get(responseBLOB, 'data.uploadUrl');
    const uploadMethod = get(responseBLOB, 'data.uploadMethod');
    const contentPermalink = get(responseBLOB, 'data.contentPermalink');

    if (!uploadUrl || !uploadMethod || !contentPermalink) {
      if (__DEV__) {
        console.log('[BLOBPost]', 'uploadUrl, uploadMethod or contentPermalink empty', responseBLOB); // eslint-disable-line no-console
      }

      return onError({ code: 'err_unknown' });
    }

    const responseUpload = yield fetch(uploadUrl, {
      method: uploadMethod,
      body: file,
    });

    const status = yield responseUpload.status;

    if (status !== 200 && status !== 204) {
      if (__DEV__) {
        console.log('Upload failed', responseUpload); // eslint-disable-line no-console
      }
    }

    if (status !== 200 && status !== 204) {
      if (__DEV__) {
        console.log('Upload failed', responseUpload); // eslint-disable-line no-console
      }
      return onError({ code: 'err_unknown' });
    }

    if (type === 'photo') {
      yield put(photosActions.add([{
        ...data as TPhoto,
        uploadedAt: getUnixTime(),
        uri: contentPermalink,
      }]));
    }

    return onSuccess();
  } catch (error: any) {
    if (__DEV__) {
      console.log('[BLOBPost]', 'catch', error); // eslint-disable-line no-console
      console.log('[BLOBPost]', 'catch', error.response); // eslint-disable-line no-console
    }

    // TODO: Retry failed uploads

    return onError(error);
  }
}

function* shareTrends(action: ApiShareTrendsActionType): Generator<any, any, any> {
  const {
    payload: {
      onLoadLinkStart: actionOnLoadStart,
      onLoadLinkSuccess: actionOnSuccess,
      onLoadLinkError: actionOnError,
    },
  } = action;

  const onLoad = isFunction(actionOnLoadStart) ? actionOnLoadStart : noop;
  const onSuccess = isFunction(actionOnSuccess) ? actionOnSuccess : noop;
  const onError = isFunction(actionOnError) ? actionOnError : noop;

  try {
    onLoad();

    const response = yield api.post(`/user/current/${localConfig.document.id}/share/link`);

    if (response.status === 401) {
      if (__DEV__) {
        console.log('[shareTrends]', 'Refresh token', response.status); // eslint-disable-line no-console
      }

      yield put(tokenActions.refresh());

      return onError();
    }

    if (response.status !== 201) {
      if (__DEV__) {
        console.log('[shareTrends]', response.status); // eslint-disable-line no-console
      }

      return onError();
    }

    const link = get(response, 'data.link');

    if (isEmpty(link)) {
      if (__DEV__) {
        console.log('[shareTrends]', 'Empty link response', response.status); // eslint-disable-line no-console
      }

      return onError();
    }

    return onSuccess(link);
  } catch (error: any) {
    if (__DEV__) {
      console.log('[shareTrends]', 'catch', error); // eslint-disable-line no-console
      console.log('[shareTrends]', 'catch', error.response); // eslint-disable-line no-console
    }
    return onError();
  }
}

function* shareCustomTrends(action: ApiShareCustomTrendsActionType): Generator<any, any, any> {
  const {
    payload: {
      onLoadLinkStart: actionOnLoadStart,
      onLoadLinkSuccess: actionOnSuccess,
      onLoadLinkError: actionOnError,
      trends,
    },
  } = action;

  const onLoad = isFunction(actionOnLoadStart) ? actionOnLoadStart : noop;
  const onSuccess = isFunction(actionOnSuccess) ? actionOnSuccess : noop;
  const onError = isFunction(actionOnError) ? actionOnError : noop;

  try {
    onLoad();
    const { userId } = yield getConfigAsync();

    const response = yield api.post(
      `/user/${userId}/${localConfig.document.id}/share/link?source=client&type=custom`,
      {
        data_list: trends,
      },
    );

    if (response.status === 401) {
      if (__DEV__) {
        console.log('[shareCustomTrends]', 'Refresh token', response.status); // eslint-disable-line no-console
      }

      yield put(tokenActions.refresh());

      return onError();
    }

    if (response.status !== 201) {
      if (__DEV__) {
        console.log('[shareCustomTrends]', response.status); // eslint-disable-line no-console
      }

      return onError();
    }

    const link = get(response, 'data.link');
    if (isEmpty(link)) {
      if (__DEV__) {
        console.log('[shareCustomTrends]', 'Empty link response', response.status); // eslint-disable-line no-console
      }

      return onError();
    }

    return onSuccess(link);
  } catch (error: any) {
    if (__DEV__) {
      console.log('[shareCustomTrends]', 'catch', error); // eslint-disable-line no-console
      console.log('[shareCustomTrends]', 'catch', error.response); // eslint-disable-line no-console
    }
    return onError();
  }
}

function* shareCustomTrendsById(
  action: ApiShareCustomTrendsByIdActionType,
): Generator<any, any, any> {
  const {
    payload: {
      onLoadLinkStart: actionOnLoadStart,
      onLoadLinkSuccess: actionOnSuccess,
      onLoadLinkError: actionOnError,
      trends,
      startDate,
      endDate,
      type,
    },
  } = action;

  const onLoad = isFunction(actionOnLoadStart) ? actionOnLoadStart : noop;
  const onSuccess = isFunction(actionOnSuccess) ? actionOnSuccess : noop;
  const onError = isFunction(actionOnError) ? actionOnError : noop;

  const data: { data_list: string[], start_date?: number, end_date?: number } = {
    data_list: trends,
  };

  if (startDate) {
    data.start_date = getUnixTime(startDate);
  }

  if (endDate) {
    data.end_date = getUnixTime(endDate);
  }

  try {
    onLoad();
    const { userId } = yield getConfigAsync();

    const response = yield api.post(
      `/user/${userId}/${localConfig.document.id}/share/link?source=document&type=${type || 'shareById'}`,
      data,
    );

    if (response.status === 401) {
      if (__DEV__) {
        console.log('[shareCustomTrendsByID]', 'Refresh token', response.status); // eslint-disable-line no-console
      }

      yield put(tokenActions.refresh());

      return onError();
    }

    if (response.status !== 201) {
      if (__DEV__) {
        console.log('[shareCustomTrendsByID]', response.status); // eslint-disable-line no-console
      }

      return onError();
    }

    const link = get(response, 'data.link');
    if (isEmpty(link)) {
      if (__DEV__) {
        console.log('[shareCustomTrendsByID]', 'Empty link response', response.status); // eslint-disable-line no-console
      }

      return onError();
    }

    return onSuccess(link);
  } catch (error: any) {
    if (__DEV__) {
      console.log('[shareCustomTrendsByID]', 'catch', error); // eslint-disable-line no-console
      console.log('[shareCustomTrendsByID]', 'catch', error.response); // eslint-disable-line no-console
    }
    return onError();
  }
}

// export function* watchBLOBPost() {
//   const channel = yield actionChannel(apiActionTypes.BLOB_POST);
//
//   while (true) {
//     const action = yield take(channel);
//
//     yield call(BLOBPost, action);
//   }
// }

function* deleteAccount(action: ApiDeleteAccountActionType): Generator<any, any, any> {
  const {
    payload: {
      onSuccess: actionOnSuccess,
      onError: actionOnError,
      action: deletionAction,
      partial,
    },
  } = action;

  const email = yield getEmailAsync();

  const onSuccess = isFunction(actionOnSuccess) ? actionOnSuccess : noop;
  const onError = isFunction(actionOnError) ? actionOnError : noop;

  const { userId } = yield getConfigAsync();

  const locale = i18nGetLocale();
  try {
    const stringPartial = partial.toString();

    yield api.post(
      `/user/${userId}/${localConfig.document.id}/delete?action=${deletionAction}&partial=${stringPartial}&locale=${locale}`,
      { email },
    );

    const userSynced = yield syncUserData(true);

    if (!userSynced) {
      throw new Error('Error loading user data');
    }

    return onSuccess();
  } catch (error: any) {
    if (__DEV__) {
      console.log('[deleteAccount]', 'catch', error); // eslint-disable-line no-console
      console.log('[deleteAccount]', 'catch', error.response); // eslint-disable-line no-console
    }

    return onError(error);
  }
}

function* deactivateAccount(action: ApiDeactivateAccountActionType): Generator<any, any, any> {
  const {
    payload: {
      onSuccess: actionOnSuccess,
      onError: actionOnError,
      action: deactivationAction,
    },
  } = action;

  const email = yield getEmailAsync();

  const onSuccess = isFunction(actionOnSuccess) ? actionOnSuccess : noop;
  const onError = isFunction(actionOnError) ? actionOnError : noop;

  const token = yield getApiTokenAsync();
  const { userId } = yield getConfigAsync();
  const locale = i18nGetLocale();
  try {
    yield authApi.post(
      `/deactivate?action=${deactivationAction}&userId=${userId}&locale=${locale}`,
      { email, requestDocumentId: localConfig.document.id },
      { headers: { Authentication: `Bearer ${token}` } },
    );

    const userSynced = yield syncUserData(true);

    if (!userSynced) {
      throw new Error('Error loading user data');
    }

    return onSuccess();
  } catch (error: any) {
    if (__DEV__) {
      console.log('[deactivateAccount]', 'catch', error); // eslint-disable-line no-console
      console.log('[deactivateAccount]', 'catch', error.response); // eslint-disable-line no-console
    }

    return onError(error);
  }
}

// eslint-disable-next-line max-len
function* getUniqueHospitalAccessCode(action: ApiGetUniqueHospitalAccessCodeType): Generator<any, any, any> {
  const {
    payload: {
      hospitalId,
      onSuccess: actionOnSuccess,
      onError: actionOnError,
    },
  } = action;

  const onSuccess = isFunction(actionOnSuccess) ? actionOnSuccess : noop;
  const onError = isFunction(actionOnError) ? actionOnError : noop;

  try {
    const data = {
      requestDocumentId: localConfig.document.id,
      hospital_id: hospitalId,
    };

    const token = yield getApiTokenAsync();

    if (!token) {
      if (__DEV__) {
        console.error('[uniqueHospitalAccessCode] Invalid or missing token'); // eslint-disable-line no-console
      }

      throw new Error('Invalid or missing token');
    }

    const headers = { authentication: `Bearer ${token}` };

    const response = yield authApi.post(
      '/codesMaintenance?type=hospital-access-code',
      data,
      { headers },
    );

    if (response.status !== 200) {
      if (__DEV__) {
        console.log('[uniqueHospitalAccessCode]', 'Received invalid HTTP status code', response); // eslint-disable-line no-console
      }

      const errorCode = get(response, 'data.code');

      return onError({ code: errorCode });
    }

    return onSuccess(response.data.access_code);
  } catch (error: any) {
    if (__DEV__) {
      console.log('[uniqueHospitalAccessCode]', 'catch', error); // eslint-disable-line no-console
      console.log('[uniqueHospitalAccessCode]', 'catch', error.response); // eslint-disable-line no-console
    }

    return onError(error);
  }
}

export default function* watchApi() {
  yield takeLatest(apiActionTypes.AUTH_REGISTER, authRegister);
  yield takeLatest(apiActionTypes.AUTH_LOGIN, authLogin);
  yield takeLatest(apiActionTypes.DEACTIVATE_ACCOUNT, deactivateAccount);
  yield takeLatest(apiActionTypes.DELETE_ACCOUNT, deleteAccount);
  yield takeLatest(apiActionTypes.AUTH_RESET, authReset);
  yield takeLatest(apiActionTypes.AUTH_PASSWORD, authPassword);
  yield takeLatest(apiActionTypes.VALIDATE_INVITE, validateInvite);
  yield takeLatest(apiActionTypes.VALIDATE_NAVIGATION, validateNavigation);
  yield takeLatest(apiActionTypes.SHARE_TRENDS, shareTrends);
  yield takeLatest(apiActionTypes.SHARE_CUSTOM_TRENDS, shareCustomTrends);
  yield takeLatest(apiActionTypes.SHARE_CUSTOM_TRENDS_BY_ID, shareCustomTrendsById);
  yield takeLatest(apiActionTypes.BLOB_POST, BLOBPost);
  yield takeLatest(apiActionTypes.GET_HOSPITAL_UNIQUE_ACCESS_CODE, getUniqueHospitalAccessCode);
}
