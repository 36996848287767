import produce, { Draft } from 'immer';
import omit from 'lodash/omit';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import union from 'lodash/union';
import without from 'lodash/without';
import set from 'lodash/set';
import keyBy from 'lodash/keyBy';

import {
  ContactsState,
  ContactsAddActionType,
  ContactsAddPhotosActionType,
  ContactsRemoveActionType,
  ContactsRemovePhotosActionType,
  ContactsUpdateActionType,
} from './types';

const add = (state: ContactsState, action: ContactsAddActionType) => {
  const { payload } = action;

  const contacts = keyBy(payload, 'id');

  return {
    ...state,
    ...contacts,
  };
};

const addPhotos = produce((
  draft: Draft<ContactsState>,
  action: ContactsAddPhotosActionType,
) => {
  const { payload } = action;
  const { contactId, photoIds } = payload;

  const contact = get(draft, [contactId]);

  if (isEmpty(contact)) {
    return draft;
  }

  const draftPhotoIds = get(draft, [contactId, 'photoIds']);
  const newPhotoIds = union(draftPhotoIds, photoIds);

  set(draft, [contactId, 'photoIds'], newPhotoIds);

  return draft;
});

const remove = (state: ContactsState, action: ContactsRemoveActionType) => {
  const { payload } = action;

  return omit(state, payload);
};

const removePhotos = produce((
  draft: Draft<ContactsState>,
  action: ContactsRemovePhotosActionType,
) => {
  const { payload } = action;
  const { contactId, photoIds } = payload;

  const contact = get(draft, [contactId]);

  if (isEmpty(contact)) {
    return draft;
  }

  const draftPhotoIds = get(draft, [contactId, 'photoIds']);
  const newPhotoIds = without(draftPhotoIds, ...photoIds);

  set(draft, [contactId, 'photoIds'], newPhotoIds);

  return draft;
});

const update = (state: ContactsState, action: ContactsUpdateActionType) => {
  const { payload } = action;

  const contacts = keyBy(payload, 'id');

  return {
    ...state,
    ...contacts,
  };
};

const clear = () => ({});

export {
  add,
  addPhotos,
  remove,
  removePhotos,
  update,
  clear,
};
