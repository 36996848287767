import { put, takeLatest } from 'redux-saga/effects';
import localforage from 'localforage';

import { t } from '../services/i18n';
import { actionTypes as authActionTypes } from '../state/auth';
import { actions as UIActions } from '../state/ui';

function* logout() {
  try {
    yield localforage.removeItem('@api/token');
    yield localforage.removeItem('@Settings:language');
    yield localforage.removeItem('@Settings:baseURL');

    yield put({ type: 'KILLETAG' });
    yield put({ type: 'RESET' });
  } catch (error) {
    if (__DEV__) {
      console.log('[auth.logout]', 'catch', error); // eslint-disable-line no-console
    }

    yield put(UIActions.addNotification({ text: t('unknown-error'), type: 'error' }));
  }
}

export default function* watchAuth() {
  yield takeLatest(authActionTypes.LOGOUT, logout);
}
