import React from 'react';
import { useTranslation } from 'react-i18next';

import { NoteType } from '../../types';

import ListItem from './ListItem';
import dateFormat from '../utils/dateFormat';
import { ReactComponent as IconNote } from '../assets/icons/Note.svg';
import { ReactComponent as IconChevronRight } from '../assets/icons/ChevronRight.svg';
import Colors from '../theme/Colors';

type Props = {
  note: NoteType;
  divider?: boolean;
  dividerOffset?: number;
};

const NoteListItem = (props: Props) => {
  const { i18n } = useTranslation();

  const { note, divider = true, dividerOffset = 0 } = props;

  return (
    <ListItem
      key={note.id}
      primaryText={note.title}
      secondaryText={dateFormat(i18n.language, note.createdAt)}
      leftIcon={<IconNote fill={Colors.primary} className="icon" />}
      rightIcon={<IconChevronRight fill={Colors.primary} className="icon small" />}
      divider={divider}
      dividerOffset={dividerOffset}
      to={`/note/${note.id}`}
    />
  );
};

export default NoteListItem;
