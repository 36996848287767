import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import * as yup from 'yup';
import ReactPlayer from 'react-player';
import { useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';

import { TAudioRecording } from '../../types';
import { AudioRecordingPropType } from '../../propTypes';

import Modal from './Modal';
import Button from './Button';
import { actions as audioRecordingsActions } from '../state/audioRecordings';
import { text as textSchema, date as dateSchema } from '../utils/schemas';
import { formValidation, useFormFieldValidation } from '../utils/formValidation';
import defaultGet from '../utils/defaultGet';
import isOfType from '../utils/isOfType';
import getUnixTime from '../utils/getUnixTime';
import Colors from '../theme/Colors';
import '../css/AudioRecording.css';
import toDate from '../utils/toDate';

type Props = {
  audioRecording: TAudioRecording;
};

const propTypes = {
  audioRecording: AudioRecordingPropType,
};

type TFormErrors = {
  title?: string;
  createdAt?: string;
};

const playerStyle = {
  maxWidth: '440px',
};

const AudioRecording = (props: Props) => {
  const { audioRecording = {} } = props;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const history = useHistory();

  const [visible, setVisible] = useState(false);

  const [errors, setErrors] = useState<TFormErrors>({});
  const [submitted, setSubmitted] = useState(false);

  const titleSchema = textSchema(true, t('errors:field-required', { field: t('title') }));
  const createdAtSchema = dateSchema(true, t('errors:field-required', { field: t('date-time') }));

  const [title, setTitle] = useFormFieldValidation(
    titleSchema,
    'title',
    defaultGet(audioRecording, 'title', ''),
    submitted,
    errors,
    setErrors,
  );

  const [createdAt, setCreatedAt] = useFormFieldValidation(
    createdAtSchema,
    'createdAt',
    defaultGet(audioRecording, 'createdAt', ''),
    submitted,
    errors,
    setErrors,
  );

  const uri = get(audioRecording, 'contentPermalink')
    || get(audioRecording, 'source.uri')
    || get(audioRecording, 'uri');

  const handleSave = () => {
    const schema = yup
      .object()
      .shape({
        title: titleSchema,
      });

    setSubmitted(true);
    setErrors({});

    const formData = {
      title,
    };

    if (!formValidation(schema, formData, setErrors)) {
      return false;
    }

    if (isOfType<TAudioRecording>(audioRecording, 'id')) {
      dispatch(audioRecordingsActions.update([{
        ...audioRecording,
        updatedAt: getUnixTime(),
        title,
      }]));
    }

    return history.goBack();
  };

  const handleRemove = () => {
    dispatch(audioRecordingsActions.remove([get(audioRecording, 'id')]));
    dispatch(audioRecordingsActions.cleanup([audioRecording as TAudioRecording]));

    return history.goBack();
  };

  return (
    <>
      <section className="audio-recording-content">
        <label // eslint-disable-line jsx-a11y/label-has-associated-control
          htmlFor="createdAt"
        >
          <span className="body light">{t('date-time')}</span>
          <DatePicker
            selected={toDate(createdAt)}
            onChange={(date: Date) => setCreatedAt(date)}
            showTimeSelect
            dateFormat="dd/LL/yyyy HH:mm"
            timeFormat="HH:mm"
            id="createdAt"
            disabled
          />
          {
            errors.createdAt && <p className="body error">{t(errors.createdAt)}</p>
          }
        </label>
        <label htmlFor="title">
          <span className="body light">{t('title')}</span>
          <input
            value={title}
            onChange={(event) => setTitle(event.currentTarget.value)}
            name="title"
            id="title"
          />
          {
            errors.title && <p className="body error">{t(errors.title)}</p>
          }
        </label>
        <ReactPlayer
          url={uri}
          controls
          config={{
            file: {
              forceAudio: true,
            },
          }}
          style={playerStyle}
          width="100%"
          height="56px"
        />
        <section className="buttons">
          {
            isOfType<TAudioRecording>(audioRecording, 'id')
              ? (
                <Button
                  labelText={t('delete')}
                  onClick={() => setVisible(true)}
                  color={Colors.destructiveRed}
                  size="medium"
                />
              )
              : null
          }
          <Button
            labelText={t('save')}
            onClick={handleSave}
            size="medium"
            disabled={get(audioRecording, 'title') === title || !title}
          />
        </section>
      </section>
      <Modal
        visible={visible}
        hideModal={() => setVisible(false)}
        actions={[
          {
            title: t('cancel'),
            onClick: () => setVisible(false),
          },
          {
            title: t('delete'),
            onClick: handleRemove,
            destructive: true,
          },
        ]}
      >
        <h1 className="heading">{t('alerts.delete.title', { w: t('recording') })}</h1>
        <p className="body">{t('alerts.delete.body', { w: t('recording') })}</p>
      </Modal>
    </>
  );
};

AudioRecording.propTypes = propTypes;

export default AudioRecording;
