import * as actionTypes from './actionTypes';
import { AppointmentType } from '../../../types';
import {
  AppointmentsActionTypes,
  AppointmentsAddPhotosPayload,
  AppointmentsAddAudioRecordingsPayload,
  AppointmentsAddNotesPayload,
  AppointmentsRemovePhotosPayload,
  AppointmentsRemoveAudioRecordingsPayload,
} from './types';

const add = (payload: AppointmentType[]): AppointmentsActionTypes => ({
  type: actionTypes.ADD,
  payload,
});

const addPhotos = (payload: AppointmentsAddPhotosPayload): AppointmentsActionTypes => ({
  type: actionTypes.ADD_PHOTOS,
  payload,
});

const addAudioRecordings = (
  payload: AppointmentsAddAudioRecordingsPayload,
): AppointmentsActionTypes => ({
  type: actionTypes.ADD_AUDIO_RECORDINGS,
  payload,
});

const addNotes = (payload: AppointmentsAddNotesPayload): AppointmentsActionTypes => ({
  type: actionTypes.ADD_NOTES,
  payload,
});

const remove = (payload: string[]): AppointmentsActionTypes => ({
  type: actionTypes.REMOVE,
  payload,
});

const removePhotos = (
  payload: AppointmentsRemovePhotosPayload,
): AppointmentsActionTypes => ({
  type: actionTypes.REMOVE_PHOTOS,
  payload,
});

const removeAudioRecordings = (
  payload: AppointmentsRemoveAudioRecordingsPayload,
): AppointmentsActionTypes => ({
  type: actionTypes.REMOVE_AUDIO_RECORDINGS,
  payload,
});

const update = (payload: AppointmentType[]): AppointmentsActionTypes => ({
  type: actionTypes.UPDATE,
  payload,
});

const clear = (): AppointmentsActionTypes => ({
  type: actionTypes.CLEAR,
});

export {
  add,
  addPhotos,
  addAudioRecordings,
  addNotes,
  remove,
  removePhotos,
  removeAudioRecordings,
  update,
  clear,
};
