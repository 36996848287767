import first from 'lodash/first';
import get from 'lodash/get';

const getBrowserLanguage = (): string => {
  if (!navigator) {
    return '';
  }

  // This has been slightly modified from:
  // https://github.com/i18next/i18next-browser-languageDetector/blob/master/src/browserLookups/navigator.js
  const language = first(get(navigator, 'languages'))
    || get(navigator, 'userLanguage')
    || get(navigator, 'language');

  if (!language) {
    return '';
  }

  return language;
};

export default getBrowserLanguage;
