import * as actionTypes from './actionTypes';
import { ProfileQuestionAnswersActionTypes } from './types';
import { TProfileQuestionAnswerState, TUUIDv4 } from '../../../types';

const add = (payload: TProfileQuestionAnswerState[]): ProfileQuestionAnswersActionTypes => ({
  type: actionTypes.ADD,
  payload,
});

const update = (payload: TProfileQuestionAnswerState[]): ProfileQuestionAnswersActionTypes => ({
  type: actionTypes.UPDATE,
  payload,
});

const deleteById = (payload: TUUIDv4): ProfileQuestionAnswersActionTypes => ({
  type: actionTypes.DELETE_BY_ID,
  payload,
});

const clear = (): ProfileQuestionAnswersActionTypes => ({
  type: actionTypes.CLEAR,
});

export {
  add,
  update,
  deleteById,
  clear,
};
