import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Navigation from '../components/Navigation';
import DrawerButtons from '../components/DrawerButtons';
import Footer from '../components/Footer';
import Button from '../components/Button';
import {
  passwordChange as passwordChangeSchema,
  password as passwordSchema,
} from '../utils/schemas';
import { formValidation, useFormFieldValidation } from '../utils/formValidation';
import defaultGet from '../utils/defaultGet';
import { actions as UIActions } from '../state/ui';
import { actions as apiActions } from '../state/api';

type TFormErrors = {
  newPassword?: string;
  confirmNewPassword?: string;
};

const ChangePassword = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const dispatch = useDispatch();

  const [errors, setErrors] = useState<TFormErrors>({});
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState({ score: 0, label: '' }); // eslint-disable-line @typescript-eslint/no-unused-vars

  const [newPassword, setNewPassword] = useFormFieldValidation(
    passwordSchema,
    'newPassword',
    '',
    submitted,
    errors,
    setErrors,
  );

  const [confirmNewPassword, setConfirmNewPassword] = useFormFieldValidation(
    passwordSchema,
    'confirmNewPassword',
    '',
    submitted,
    errors,
    setErrors,
  );

  const handleSave = async () => {
    setSubmitted(true);
    setErrors({});

    if (!formValidation(
      passwordChangeSchema,
      {
        newPassword,
        confirmNewPassword,
      },
      setErrors,
    )) {
      return false;
    }

    setLoading(true);

    return dispatch(apiActions.authPassword({
      password: newPassword,
      onSuccess: () => {
        setLoading(false);

        history.goBack();

        dispatch(UIActions.addNotification({ text: t('password-changed'), type: 'info' }));
      },
      onError: (error: any) => {
        const errorCode = defaultGet(error, 'code', 'err_unknown');
        const text = t([`errorCodes.password.${errorCode}`, 'errorCodes.err_unknown']);

        dispatch(UIActions.addNotification({ text, type: 'error' }));

        setLoading(false);
      },
    }));
  };

  return (
    <article className="page change-password row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('change-password')} />
          <label htmlFor="new-password">
            <span className="body light">{t('new-password')}</span>
            <input
              value={newPassword}
              onChange={(event) => setNewPassword(event.currentTarget.value)}
              name="new-password"
              id="new-password"
              type="password"
            />
            {
                errors.newPassword
                  ? <p className="body error">{t(errors.newPassword)}</p>
                  : null
              }
          </label>
          <label htmlFor="confirm-password">
            <span className="body light">{t('confirm-password')}</span>
            <input
              value={confirmNewPassword}
              onChange={(event) => setConfirmNewPassword(event.currentTarget.value)}
              name="confirm-password"
              id="confirm-password"
              type="password"
            />
            {
                errors.confirmNewPassword
                  ? <p className="body error">{t(errors.confirmNewPassword)}</p>
                  : null
              }
          </label>
          <Button
            labelText={t('save')}
            onClick={handleSave}
            disabled={loading}
            size="medium"
          />
        </section>
        <Footer />
      </section>
    </article>
  );
};

export default ChangePassword;
