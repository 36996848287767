const prefix = 'PROFILE_QUESTION_ANSWERS';

const ADD = `${prefix}/ADD`;
const UPDATE = `${prefix}/UPDATE`;
const DELETE_BY_ID = `${prefix}/DELETE_BY_ID`;
const CLEAR = `${prefix}/CLEAR`;

export {
  ADD,
  UPDATE,
  DELETE_BY_ID,
  CLEAR,
};
