/* eslint react/require-default-props: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Path } from 'react-native-svg';
import ResizeableSVG from '../ResizeableSVG';

import Colors from '../../theme/Colors';
import Constants from '../../theme/Constants';

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

type Props = {
  color?: string;
  size?: number;
};

const ShareData = (props: Props) => {
  const {
    color = Colors.primary,
    size = Constants.defaultIconSize,
  } = props;

  return (
    <ResizeableSVG size={size}>
      <Path d="m8.5186 13.8438c0 1.36-1.053 2.427-2.396 2.427-1.342 0-2.394-1.067-2.394-2.427s1.052-2.426 2.394-2.426c1.343 0 2.396 1.066 2.396 2.426m9.063-8.667c0-1.36 1.051-2.426 2.394-2.426s2.395 1.066 2.395 2.426-1.052 2.427-2.395 2.427-2.394-1.067-2.394-2.427m4.789 17.334c0 1.36-1.052 2.426-2.395 2.426s-2.394-1.066-2.394-2.426 1.051-2.427 2.394-2.427 2.395 1.067 2.395 2.427m-2.395-4.177c-1.138 0-2.238.496-3.026 1.364l-6.909-4.546c.137-.418.207-.858.207-1.308 0-.452-.07-.892-.207-1.308l6.896-4.546c.783.868 1.888 1.364 3.039 1.364 2.274 0 4.124-1.874 4.124-4.177s-1.85-4.177-4.124-4.177-4.124 1.874-4.124 4.177c0 .449.069.89.206 1.309l-6.908 4.545c-.788-.868-1.889-1.364-3.027-1.364-2.273 0-4.123 1.874-4.123 4.177s1.85 4.177 4.123 4.177c1.152 0 2.256-.496 3.04-1.364l6.895 4.546c-.137.416-.206.856-.206 1.308 0 2.303 1.85 4.177 4.124 4.177s4.124-1.874 4.124-4.177-1.85-4.177-4.124-4.177" fill={color} fillRule="evenodd" />
    </ResizeableSVG>
  );
};

ShareData.propTypes = propTypes;

export default ShareData;
