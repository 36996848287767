const prefix = 'API';

const AUTH_REGISTER = `${prefix}/AUTH_REGISTER`;
const DEACTIVATE_ACCOUNT = `${prefix}/DEACTIVATE_ACCOUNT`;
const DELETE_ACCOUNT = `${prefix}/DELETE_ACCOUNT`;
const AUTH_LOGIN = `${prefix}/AUTH_LOGIN`;
const AUTH_RESET = `${prefix}/AUTH_RESET`;
const AUTH_PASSWORD = `${prefix}/AUTH_PASSWORD`;
const VALIDATE_INVITE = `${prefix}/VALIDATE_INVITE`;
const VALIDATE_NAVIGATION = `${prefix}/VALIDATE_NAVIGATION`;
const BLOB_POST = `${prefix}/BLOB_POST`;
const SHARE_TRENDS = `${prefix}/SHARE_TRENDS`;
const SHARE_CUSTOM_TRENDS = `${prefix}/SHARE_CUSTOM_TRENDS`;
const SHARE_CUSTOM_TRENDS_BY_ID = `${prefix}/SHARE_CUSTOM_TRENDS_BY_ID`;
const DOWNLOAD_REPORT = `${prefix}/DOWNLOAD_REPORT`;
const GET_HOSPITAL_UNIQUE_ACCESS_CODE = `${prefix}/GET_HOSPITAL_UNIQUE_ACCESS_CODE`;

export {
  AUTH_REGISTER,
  AUTH_LOGIN,
  AUTH_RESET,
  AUTH_PASSWORD,
  VALIDATE_INVITE,
  VALIDATE_NAVIGATION,
  BLOB_POST,
  SHARE_TRENDS,
  SHARE_CUSTOM_TRENDS,
  SHARE_CUSTOM_TRENDS_BY_ID,
  DOWNLOAD_REPORT,
  DEACTIVATE_ACCOUNT,
  DELETE_ACCOUNT,
  GET_HOSPITAL_UNIQUE_ACCESS_CODE,
};
