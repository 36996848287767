import { createSelector } from 'reselect';
import get from 'lodash/get';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import size from 'lodash/size';
import toArray from 'lodash/toArray';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';

import { AppState } from '../reducers';
import { ProfileQuestionsState } from './types';
import { ProfileQuestionReturnType, ProfileQuestionsReturnType } from '../../../types';

import EMPTY_OBJECT from '../../utils/empty-object';
import EMPTY_ARRAY from '../../utils/empty-array';
import includesAny from '../../utils/includesAny';

const getByIdSelector = (state: ProfileQuestionsState, id: string): ProfileQuestionReturnType => (
  get(state, [id]) || EMPTY_OBJECT
);

const makeGetById = () => (
  createSelector(
    (state: AppState) => state.profileQuestions,
    (state: any, id: string) => id,
    getByIdSelector,
  )
);

const getByIdsSelector = (
  state: ProfileQuestionsState,
  ids: string[],
): ProfileQuestionsReturnType => (
  filter(state, (question) => includes(ids, question.id))
);

const makeGetByIds = () => (
  createSelector(
    (state: AppState) => state.profileQuestions,
    (state: any, ids: string[]) => ids,
    getByIdsSelector,
  )
);

const getByTagsSelector = (
  state: ProfileQuestionsState,
  tags: string[],
): ProfileQuestionReturnType => (
  sortBy(
    filter(state, (question) => {
      if (isEmpty(question.tags)) {
        return true;
      }

      return includesAny(question.tags, tags);
    }),
    'order',
  )
);

const makeGetByTags = () => (
  createSelector(
    (state: AppState) => state.profileQuestions,
    (state: any, tags: string[]) => tags,
    getByTagsSelector,
  )
);

const getByProfileQuestionCategoryIdSelector = (
  state: ProfileQuestionsState,
  profileQuestionCategoryId: string,
): ProfileQuestionsReturnType => (
  filter(state, { profileQuestionCategoryId }) || EMPTY_ARRAY
);

const makeGetByProfileQuestionCategoryId = () => (
  createSelector(
    (state: AppState) => state.profileQuestions,
    (state: any, profileQuestionCategoryId: string) => profileQuestionCategoryId,
    getByProfileQuestionCategoryIdSelector,
  )
);

const getAllSelector = (state: ProfileQuestionsState): ProfileQuestionsReturnType => (
  toArray(state)
);

const getAll = createSelector(
  (state: AppState) => state.profileQuestions,
  getAllSelector,
);

const countSelector = (state: ProfileQuestionsState) => size(state);

const count = createSelector(
  (state: AppState) => state.profileQuestions,
  countSelector,
);

const makeCountByTags = () => (
  createSelector(
    (state: AppState) => state.profileQuestions,
    (state: any, tags: string[]) => tags,
    getByTagsSelector,
    countSelector,
  )
);

const countByProfileQuestionCategoryIdSelector = (
  state: ProfileQuestionsState,
  profileQuestionCategoryId: string,
) => (
  size(filter(state, { profileQuestionCategoryId }))
);

const countByProfileQuestionCategoryId = createSelector(
  (state: AppState) => state.profileQuestions,
  (state: any, profileQuestionCategoryId: string) => profileQuestionCategoryId,
  countByProfileQuestionCategoryIdSelector,
);

export {
  makeGetById,
  makeGetByIds,
  makeGetByTags,
  makeGetByProfileQuestionCategoryId,
  getAll,
  count,
  makeCountByTags,
  countByProfileQuestionCategoryId,
};
