const config = {
  api: {
    timeout: 20000,
  },
  authApi: {
    dev: {
      url: 'https://auth.dev.uk.owise.net/api/1/auth',
    },
    staging: {
      url: 'https://auth.stg.uk.owise.net/api/1/auth',
    },
    prod: {
      url: 'https://auth.owise.net/api/1/auth',
    },
    timeout: 20000,
  },
  environments: [
    'dev',
    'staging',
    'prod',
  ],
  showEnvironments: true,
  document: {
    id: 'f4312562-6107-4d2c-938a-fcd4aefe1a8e',
    version: '0002',
    name: 'breast-0002',
    systemDocumentRefreshInterval: 60 * 60 * 1000,
    userDocumentRefreshInterval: 60 * 1000,
    failureRetryDelay: 60 * 1000,
  },
  dailySuggestions: {
    max: 5,
    intervalInHours: 24,
  },
  languageMenuEnable: true,
  developmentMenuEnable: true,
  photoUploadButtonEnable: false,
  photoDownloadButtonEnable: false,
  audioRecordingUploadButtonEnable: false,
  audioRecordingDownloadButtonEnable: false,
  enableGraphListFullScreen: false,
  shareMyDataEnable: true,
  availableLanguages: {
    us: [
      { name: 'English (US)', language: 'en-US', default: true },
      { name: 'Español (US)', language: 'es-US' },
    ],
    uk: [
      { name: 'English (UK)', language: 'en-GB', default: true },
    ],
    nl: [
      { name: 'Nederlands (NL)', language: 'nl-NL', default: true },
    ],
  },
  allowedCountries: ['uk', 'nl', 'us'],
  isPC: false,
  isBC: true,
  countly: {
    url: 'https://analytics.pxhealthcare.com',
    appKey: '3538cd9401c39cb469e22c670b8ee9f0faf1a8e7',
  },
  walkTestFitnessCounterSetting: 360, // 6 minutes
  availableContentModules: {
    enhertu: {
      type: 'drug',
      requiresActivation: true,
      requiresSelection: true,
      enableOnboarding: true,
      availableRegions: ['en-US', 'nl-NL', 'en-GB'],
      requiresContentSync: false,
      syncUrl: '',
      syncInterval: 60 * 60 * 10,
    },
    lynparza: {
      type: 'drug',
      requiresActivation: true,
      requiresSelection: true,
      enableOnboarding: true,
      availableRegions: ['en-US', 'nl-NL', 'en-GB'],
      requiresContentSync: false,
      syncUrl: '',
      syncInterval: 60 * 60 * 10,
    },
  },
  testUserCodePatterns: ['TEST', 'LYN', 'DM1', 'DM2', 'ENH'],
} as const;

export default config;
