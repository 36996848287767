import { takeEvery, put } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';

import {
  actionTypes as photosActionTypes,
  actions as photosActions,
  PhotosCleanupActionType,
} from '../state/photos';
import { actions as questionnairesActions } from '../state/questionnaires';

function* cleanup(action: PhotosCleanupActionType) {
  const {
    payload: photos,
  } = action;

  for (const photo of photos) { // eslint-disable-line no-restricted-syntax
    if (isEmpty(photo)) {
      return false;
    }

    const {
      id,
      uri,
      questionnaireId,
      questionnaireQuestionId,
    } = photo;

    if (questionnaireId && questionnaireQuestionId) {
      yield put(questionnairesActions.removeQuestionPhotos({
        questionnaireId,
        questionId: questionnaireQuestionId,
        photoIds: [id],
      }));
    }

    yield put(photosActions.unlinkUri(uri));
  }

  return true;
}

export default function* watchPhotos() {
  yield takeEvery(photosActionTypes.CLEANUP, cleanup);
}
