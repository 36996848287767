import toArray from 'lodash/toArray';
import reject from 'lodash/reject';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';

import { AppState } from '../reducers';
import { TDailySuggestionReturn, TDailySuggestionsReturn, TSortOrder } from '../../../types';
import { DailySuggestionsState } from './types';

import createDeepEqualSelector from '../../selectors/createDeepEqualSelector';
import EMPTY_OBJECT from '../../utils/empty-object';

const sort = (
  collection: TDailySuggestionsReturn | DailySuggestionsState,
  key = 'order',
  order = 'asc' as TSortOrder,
): TDailySuggestionsReturn => (
  orderBy(collection, [key], [order])
);

const getByIdSelector = (state: DailySuggestionsState, id: string): TDailySuggestionReturn => (
  get(state, [id]) || EMPTY_OBJECT
);

const makeGetById = () => (
  createDeepEqualSelector(
    (state: AppState) => state.dailySuggestions,
    (state: any, id: string) => id,
    getByIdSelector,
  )
);

const getAllSelector = (state: DailySuggestionsState) => sort(toArray(state));

const getAll = createDeepEqualSelector(
  (state: AppState) => state.dailySuggestions,
  getAllSelector,
);

const getOpenSelector = (state: DailySuggestionsState) => (
  sort(toArray(reject(state, { closed: true })))
);

const getOpen = createDeepEqualSelector(
  (state: AppState) => state.dailySuggestions,
  getOpenSelector,
);

export {
  makeGetById,
  getAll,
  getOpen,
};
