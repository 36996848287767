import getTime from 'date-fns/getTime';
import dateFnsGetUnixTime from 'date-fns/getUnixTime';
import { TDate } from '../../types';

import toDate from './toDate';

const getUnixTime = (date?: TDate, millis = false): number => {
  if (!date) {
    if (millis) {
      return getTime(new Date());
    }

    return dateFnsGetUnixTime(new Date());
  }

  if (millis) {
    return getTime(toDate(date));
  }

  return dateFnsGetUnixTime(toDate(date));
};

export default getUnixTime;
