/* eslint react/require-default-props: 0 */
import React, {
  useState,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import startOfMinute from 'date-fns/startOfMinute';
import map from 'lodash/map';
import noop from 'lodash/noop';
import isEqual from 'lodash/isEqual';
import size from 'lodash/size';
import DatePicker from 'react-datepicker';
import { useHistory } from 'react-router-dom';

import { TState } from './types';
import {
  AppointmentType,
  TPhoto,
  TAudioRecording,
  NoteType,
} from '../../../types';
import { AppointmentPropType } from '../../../propTypes';
import { AppState } from '../../state/reducers';

import reducer from './reducer';
import * as actions from './actions';
import Button from '../Button';
import Modal from '../Modal';
import PhotoListItem from '../PhotoListItem';
import AudioRecordingListItem from '../AudioRecordingListItem';
import NoteListItem from '../NoteListItem';
import { actions as appointmentsActions } from '../../state/appointments';
import { selectors as photosSelectors } from '../../state/photos';
import { selectors as audioRecordingsSelectors } from '../../state/audioRecordings';
import { selectors as notesSelectors } from '../../state/notes';
import { text as textSchema, date as dateSchema } from '../../utils/schemas';
import { formValidation } from '../../utils/formValidation';
import toDate from '../../utils/toDate';
import dateAddDuration from '../../utils/dateAddDuration';
import getUnixTime from '../../utils/getUnixTime';
import getDateTime from '../../utils/getDateTime';
import getTimezone from '../../utils/getTimezone';
import EMPTY_ARRAY from '../../utils/empty-array';
import defaultGet from '../../utils/defaultGet';
import isOfType from '../../utils/isOfType';
import Colors from '../../theme/Colors';
import '../../css/Appointment.css';

type Props = {
  appointment?: AppointmentType;
};

const propTypes = {
  appointment: AppointmentPropType,
};

const Appointment = (props: Props) => {
  const {
    appointment = {},
  } = props;

  const history = useHistory();

  const [disabled, setDisabled] = useState(true);
  const [visible, setVisible] = useState(false);

  const now = startOfMinute(new Date());

  const title = defaultGet(appointment, 'title', '');
  const locationAddress = defaultGet(appointment, 'location.address', '');
  const startAt = toDate(defaultGet(appointment, 'startDateTime', now));
  const endAt = toDate(defaultGet(
    appointment,
    'endDateTime',
    dateAddDuration(now, 1, 'hours'),
  ));
  const body = defaultGet(appointment, 'body', '');

  const initialState: TState = {
    title,
    location: {
      address: locationAddress,
    },
    startAt,
    endAt,
    body,
  };

  const [state, localDispatch] = useReducer(reducer, initialState);

  const photoIds = defaultGet(appointment, 'photoIds', EMPTY_ARRAY);
  const audioRecordingIds = defaultGet(appointment, 'audioRecordingIds', EMPTY_ARRAY);
  const noteIds = defaultGet(appointment, 'noteIds', EMPTY_ARRAY);

  const memoizedPhotosGetByIdsSelector = useMemo(
    photosSelectors.makeGetByIds,
    [],
  );

  const photos = useSelector(
    (reduxState: AppState) => memoizedPhotosGetByIdsSelector(reduxState, photoIds),
  );

  const memoizedAudioRecordingsGetByIdsSelector = useMemo(
    audioRecordingsSelectors.makeGetByIds,
    [],
  );

  const audioRecordings = useSelector(
    (reduxState: AppState) => (
      memoizedAudioRecordingsGetByIdsSelector(reduxState, audioRecordingIds)
    ),
  );

  const memoizedNotesGetByIdsSelector = useMemo(
    notesSelectors.makeGetByIds,
    [],
  );

  const notes = useSelector(
    (reduxState: AppState) => memoizedNotesGetByIdsSelector(reduxState, noteIds),
  );

  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    if (!isEqual(state, initialState)) {
      const shape = {
        title: textSchema(true),
        locationAddress: textSchema(false),
        startAt: dateSchema(true),
        endAt: dateSchema(true),
        body: textSchema(false),
      };

      const schema = yup
        .object()
        .shape(shape);

      const formData = {
        title: state.title,
        locationAddress: state.location.address,
        startAt: state.startAt,
        endAt: state.endAt,
        body: state.body,
      };

      if (!formValidation(schema, formData, noop)) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    } else {
      setDisabled(true);
    }
  }, [state]);

  const handleSave = () => {
    if (isOfType<AppointmentType>(appointment, 'id')) {
      dispatch(appointmentsActions.update([{
        ...appointment,
        updatedAt: getUnixTime(),
        startDateTime: getDateTime(state.startAt),
        endDateTime: getDateTime(state.endAt),
        userTimezone: getTimezone(),
        locationTimezone: '',
        location: {
          ...appointment.location,
          ...state.location,
        },
        title: state.title,
        body: state.body,
      }]));
    } else {
      dispatch(appointmentsActions.add([{
        id: uuidv4(),
        createdAt: getUnixTime(),
        startDateTime: getDateTime(state.startAt),
        endDateTime: getDateTime(state.endAt),
        userTimezone: getTimezone(),
        locationTimezone: '',
        location: {
          title: '',
          address: state.location.address,
          coordinates: {
            latitude: 0,
            longitude: 0,
          },
        },
        title: state.title,
        body: state.body,
      }]));
    }

    return history.goBack();
  };

  const handleRemove = () => {
    if (!isOfType<AppointmentType>(appointment, 'id')) {
      return false;
    }

    dispatch(appointmentsActions.remove([appointment.id]));

    return history.goBack();
  };

  const onEndDateChange = (date: Date) => {
    if (date.getTime() < state.startAt.getTime()) {
      return;
    }
    localDispatch(actions.updateEndAt(date));
  };

  return (
    <>
      <section className="appointment-content">
        <label htmlFor="title">
          <span className="body light">{t('title')}</span>
          <input
            value={state.title}
            onChange={(event) => localDispatch(actions.updateTitle(event.currentTarget.value))}
            name="title"
            id="title"
          />
        </label>
        <label htmlFor="address">
          <span className="body light">{t('location-address')}</span>
          <input
            value={state.location.address}
            onChange={(event) => localDispatch(actions.updateLocation({
              address: event.currentTarget.value,
            }))}
            name="address"
            id="address"
          />
        </label>
        <div className="separator" />
        <label // eslint-disable-line jsx-a11y/label-has-associated-control
          htmlFor="start-at"
        >
          <span className="body light">{t('starts')}</span>
          <DatePicker
            selected={state.startAt}
            onChange={(date: Date) => localDispatch(actions.updateStartAt(startOfMinute(date)))}
            showTimeSelect
            dateFormat="dd/LL/yyyy HH:mm"
            timeFormat="HH:mm"
            id="start-at"
          />
        </label>
        <label // eslint-disable-line jsx-a11y/label-has-associated-control
          htmlFor="end-at"
        >
          <span className="body light">{t('ends')}</span>
          <DatePicker
            selected={state.endAt}
            onChange={(date: Date) => onEndDateChange(date)}
            showTimeSelect
            dateFormat="dd/LL/yyyy HH:mm"
            timeFormat="HH:mm"
            id="end-at"
          />
        </label>
        {
          isOfType<AppointmentType>(appointment, 'id')
            ? map(photos, (photo: TPhoto, index: number) => (
              <PhotoListItem
                photo={photo}
                divider={size(photos) !== (index + 1)}
                key={photo.id}
              />
            ))
            : null
        }
        {
          isOfType<AppointmentType>(appointment, 'id')
            ? map(audioRecordings, (audioRecording: TAudioRecording, index: number) => (
              <AudioRecordingListItem
                audioRecording={audioRecording}
                divider={size(audioRecordings) !== (index + 1)}
                key={audioRecording.id}
              />
            ))
            : null
        }
        {
          isOfType<AppointmentType>(appointment, 'id')
            ? map(notes, (note: NoteType, index: number) => (
              <NoteListItem
                note={note}
                divider={size(notes) !== (index + 1)}
                key={note.id}
              />
            ))
            : null
        }
        <section className="buttons">
          {
            isOfType<AppointmentType>(appointment, 'id')
              ? (
                <Button
                  labelText={t('delete')}
                  onClick={() => setVisible(true)}
                  color={Colors.destructiveRed}
                  size="medium"
                />
              )
              : null
          }
          <Button
            labelText={t('save')}
            onClick={handleSave}
            size="medium"
            disabled={disabled}
          />
        </section>
      </section>
      <Modal
        visible={visible}
        hideModal={() => setVisible(false)}
        actions={[
          {
            title: t('cancel'),
            onClick: () => setVisible(false),
          },
          {
            title: t('delete'),
            onClick: handleRemove,
            destructive: true,
          },
        ]}
      >
        <h1 className="heading">{t('alerts.delete.title', { w: t('appointment') })}</h1>
        <p className="body">{t('alerts.delete.body', { w: t('appointment') })}</p>
      </Modal>
    </>
  );
};

Appointment.propTypes = propTypes;

export default Appointment;
