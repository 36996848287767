import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    paddingLeft: 17,
    paddingRight: 11,
    paddingVertical: 8,
  },
  slider: {
    width: '100%',
  },
  label: {
    marginRight: 8,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 12,
  },
  value: {
    textAlign: 'right',
  },
});
