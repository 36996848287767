/* eslint react/require-default-props: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Path } from 'react-native-svg';
import ResizeableSVG from '../ResizeableSVG';

import Colors from '../../theme/Colors';
import Constants from '../../theme/Constants';

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

type Props = {
  color?: string;
  size?: number;
};

const Info = (props: Props) => {
  const {
    color = Colors.primary,
    size = Constants.defaultIconSize,
  } = props;

  return (
    <ResizeableSVG size={size}>
      <Path d="m14 1c7.1797017 0 13 5.82029825 13 13 0 7.1797017-5.8202983 13-13 13-7.17970175 0-13-5.8202983-13-13 0-7.17970175 5.82029825-13 13-13zm0 2c-6.07513225 0-11 4.92486775-11 11 0 6.0751322 4.92486775 11 11 11 6.0751322 0 11-4.9248678 11-11 0-6.07513225-4.9248678-11-11-11zm1.2603306 7.9728916c.5991735 0 .7024793.8810241.4132231 2.2364457l-1.1776859 5.2409639c-.1446281.6551205-.0826447.8810241.0619834.8810241.1859505 0 .7231405-.3162651 1.3223141-.9036145l.3512397.5873494c-1.4669422 1.7620482-3.016529 2.4849398-3.6363637 2.4849398-.5991735 0-1.053719-.4518072-.6198347-2.439759l1.0743802-5.060241c.1033058-.564759.1239669-.7906627 0-.7906627-.1859504 0-.7644628.3840362-1.2603306.7906627l-.2892562-.6325301c1.4876033-1.5135542 3.0165289-2.3945783 3.7603306-2.3945783zm.0826446-4.4728916c.785124 0 1.1570248.63253012 1.1570248 1.33283133 0 .90361445-.661157 1.76204819-1.5702479 1.76204819-.7231405 0-1.1363637-.51957832-1.1570248-1.40060241 0-.72289157.5371901-1.69427711 1.5702479-1.69427711z" fill={color} fillRule="evenodd" />
    </ResizeableSVG>
  );
};

Info.propTypes = propTypes;

export default Info;
