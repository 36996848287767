import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import startCase from 'lodash/startCase';
import Icon from 'react-native-vector-icons/dist/FontAwesome';

import Colors from '../theme/Colors';
import appStore from '../assets/images/app-store.svg';
import playStore from '../assets/images/play-store.svg';
import '../css/Footer.css';
import versionJSON from '../version.json';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="app-footer">
      <nav>
        <a className="navigation" href={t('urls.about-owise')} target="_blank" rel="noopener noreferrer">{startCase(t('about-us'))}</a>
        <Link className="navigation" to="/useful-links">{startCase(t('useful-links'))}</Link>
        <Link className="navigation" to="/glossary">{startCase(t('glossary'))}</Link>
        <a className="navigation" href={t('urls.contact')} target="blank" rel="noopener noreferrer">{startCase(t('contact'))}</a>
        <a className="navigation" href={t('urls.terms-of-use')} target="_blank" rel="noopener noreferrer">{startCase(t('terms-of-use'))}</a>
        <a className="navigation" href={t('urls.privacy-policy')} target="_blank" rel="noopener noreferrer">{startCase(t('privacy'))}</a>
        <a className="navigation" href={t('urls.faq')} target="_blank" rel="noopener noreferrer">{startCase(t('faq'))}</a>
      </nav>
      <section className="links">
        <a href="https://www.facebook.com/owiseapp/" target="_blank" rel="noopener noreferrer" className="icon">
          <Icon name="facebook-f" size={28} color={Colors.primary} />
        </a>
        <a href="https://twitter.com/owisebreast" target="_blank" rel="noopener noreferrer" className="icon">
          <Icon name="twitter" size={28} color={Colors.primary} />
        </a>
        <a href="https://www.linkedin.com/company/px-healthcare" target="_blank" rel="noopener noreferrer" className="icon">
          <Icon name="linkedin" size={28} color={Colors.primary} />
        </a>
        <a href="https://www.instagram.com/owisebreast" target="_blank" rel="noopener noreferrer" className="icon">
          <Icon name="instagram" size={28} color={Colors.primary} />
        </a>
        <a href="https://youtube.com/PxHealthCare" target="_blank" rel="noopener noreferrer" className="icon">
          <Icon name="youtube" size={28} color={Colors.primary} />
        </a>
        <div className="links">
          <a href="https://apps.apple.com/gb/app/owise-borstkanker/id558158100" target="_blank" rel="noopener noreferrer">
            <img src={appStore} alt="Apple App Store" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=nl.onesixty.owise&hl=en_US" target="_blank" rel="noopener noreferrer">
            <img src={playStore} alt="Google Play Store" />
          </a>
        </div>
      </section>
      <section>
        <p className="body light">
          {t('cookie-notice')}
          <a className="body light" href={t('urls.cookies')} target="_blank" rel="noopener noreferrer">{t('more-info')}</a>
        </p>
        <p className="body light">Copyright © {new Date().getFullYear()} Px HealthCare Ltd.</p>
        <p className="body light version">{`${versionJSON.version} (${versionJSON.buildNumber})`}</p>
      </section>
    </footer>
  );
};

export default Footer;
