import { Reducer } from 'redux';

import * as utils from './utils';
import * as actionTypes from './actionTypes';
import {
  TrendIndicatorsState,
  TrendIndicatorsActionTypes,
  TrendIndicatorsAddActionType,
  TrendIndicatorsReplaceActionType,
} from './types';

import { trendIndicators } from '../../../data/system-document.json';

const initialState = trendIndicators as TrendIndicatorsState;

const reducer: Reducer<
TrendIndicatorsState,
TrendIndicatorsActionTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD: {
      return utils.add(state, action as TrendIndicatorsAddActionType);
    }
    case actionTypes.REPLACE: {
      return utils.replace(state, action as TrendIndicatorsReplaceActionType);
    }
    case actionTypes.CLEAR: return utils.clear();
    default: return state;
  }
};

export default reducer;
