import React, { ReactNode, Fragment } from 'react';
import map from 'lodash/map';
import includes from 'lodash/includes';

import '../css/FAQAccordion.css';

type Props = {
  sections: any[];
  renderContent: (item: any, isActive: boolean) => ReactNode;
  renderHeader: (item: any, isActive: boolean) => ReactNode;
  activeSections: number[];
  onChange: (activeSections: number[]) => void;
  keyExtractor: (item: any) => string;
};

const FAQAccordion = (props: Props) => {
  const {
    sections,
    renderContent,
    renderHeader,
    activeSections,
    onChange,
    keyExtractor,
  } = props;

  return (
    <section className="accordion bordered">
      {map(sections, (item, index) => {
        const isActive = includes(activeSections, index);

        const handleChange = () => {
          if (!isActive) {
            return onChange([index]);
          }
          return onChange([]);
        };

        return (
          <Fragment key={keyExtractor(item)}>
            <section className="accordion bordered">
              <button type="button" onClick={handleChange}>
                {renderHeader(item, isActive)}
              </button>
              {renderContent(item, isActive)}
            </section>
          </Fragment>
        );
      })}
    </section>
  );
};

export default FAQAccordion;
