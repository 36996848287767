import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import includes from 'lodash/includes';
import round from 'lodash/round';
import size from 'lodash/size';
import clamp from 'lodash/clamp';

import { selectors as tagsSelectors } from '../state/tags';
import { selectors as profileQuestionsSelectors } from '../state/profileQuestions';
import { selectors as profileQuestionAnswersSelectors } from '../state/profileQuestionAnswers';
import includesAny from '../utils/includesAny';
import createDeepEqualSelector from './createDeepEqualSelector';

const getProfileProgress = createDeepEqualSelector(
  [tagsSelectors.getAll, profileQuestionsSelectors.getAll, profileQuestionAnswersSelectors.getAll],
  (tags, profileQuestions, profileQuestionAnswers) => {
    const questionIds = map(
      filter(profileQuestions, (question) => {
        if (isEmpty(question.tags)) {
          return false;
        }

        return includesAny(question.tags, tags);
      }),
      'id',
    );

    const answerIds = map(
      filter(profileQuestionAnswers, (answer) => includes(questionIds, answer.id)),
      'id',
    );

    const fraction = clamp(round((size(answerIds) / size(questionIds)), 2) || 0, 0, 1);
    const percentage = clamp(round(fraction * 100), 0, 100);
    const completed = percentage === 100;

    return {
      fraction,
      percentage,
      completed,
    };
  },
);

export default getProfileProgress;
