import find from 'lodash/find';
import addSeconds from 'date-fns/addSeconds';
import addDays from 'date-fns/addDays';
import addWeeks from 'date-fns/addWeeks';
import addMonth from 'date-fns/addMonths';

import { TContentModule } from '../../types';
import getUnixTime from './getUnixTime';

const seconds = (count:number) => () => addSeconds(new Date(), count);
const days = (count:number) => () => addDays(new Date(), count);
const weeks = (count:number) => () => addWeeks(new Date(), count);
const month = (count:number) => () => addMonth(new Date(), count);

type TIntervalTriggerItem = {
  id: string,
  generateDate: () => Date,
  templateMessageId?: string
};

type TIntervalTrigger= {
  [k in TContentModule] : TIntervalTriggerItem[]
};

export const intervalTriggerIds = {
  enhertu: {
    fitness: 'fcb5b310-0f74-4e6f-8e22-6ba3ef09e17a', // Daily Fitness
    breathing: '58166ef9-64d7-4068-addb-082d32a0a46b', // Track your breathing
    treatment: 'b32065d4-68f1-40a8-bfa2-93e4e4e9fcee', // Add Enhertu treatment
    dosage: '675a2654-6bea-444b-b562-1fa363b360ea', // Dose change
    researchForm: 'd945624f-c78b-418c-802f-1cd4789c9db5', // Research Form
    myResearch: '371bf62b-2fe9-46b4-bea9-a38e6290dd2c', // Get Involved in research
  },
  lynparza: {
    fitness: 'ad7e5297-8f4c-45d4-9c14-1779a9fe7532', // Weekly Fitness
    fatigue: 'd380f674-5e05-480e-b169-5760beccd6d8', // Track your fatigue
    treatment: '345052c2-5e52-4403-a92e-2a92b8b79728', // Add Lynparza treatment
    dosage: '01abc7a8-eb7f-4b86-9d6d-918574bd2bed', // Dose change
    researchForm: '1b7e0219-2f3f-48bc-9ce3-cdc3b6496ee5', // Research Form
    myResearch: '52bfc732-2ab8-4335-8b24-d1e133606006', // Get Involved in research
  },
};

export const intervalTriggerData: TIntervalTrigger = {
  enhertu: [
    { id: intervalTriggerIds.enhertu.fitness, generateDate: days(2) },
    { id: intervalTriggerIds.enhertu.breathing, generateDate: weeks(1) },
    { id: intervalTriggerIds.enhertu.treatment, generateDate: weeks(1) },
    { id: intervalTriggerIds.enhertu.dosage, generateDate: month(2) },
    { id: intervalTriggerIds.enhertu.researchForm, generateDate: month(3) },
    { id: intervalTriggerIds.enhertu.myResearch, generateDate: days(1), templateMessageId: 'b49a3097-aeb9-4e36-8ca2-ae35096f411d' },
  ],
  lynparza: [
    { id: intervalTriggerIds.lynparza.fitness, generateDate: days(7) },
    { id: intervalTriggerIds.lynparza.fatigue, generateDate: weeks(1) },
    { id: intervalTriggerIds.lynparza.treatment, generateDate: weeks(1) },
    { id: intervalTriggerIds.lynparza.dosage, generateDate: month(2) },
    { id: intervalTriggerIds.lynparza.researchForm, generateDate: month(3) },
    { id: intervalTriggerIds.lynparza.myResearch, generateDate: days(1), templateMessageId: '57e01a92-351e-41cd-b8e6-3b7c0afbd5a7' },
  ],
};
export const testIntervalTriggerData: TIntervalTrigger = {
  enhertu: [
    { id: intervalTriggerIds.enhertu.fitness, generateDate: seconds(0) },
    { id: intervalTriggerIds.enhertu.breathing, generateDate: seconds(15) },
    { id: intervalTriggerIds.enhertu.treatment, generateDate: seconds(30) },
    { id: intervalTriggerIds.enhertu.dosage, generateDate: seconds(45) },
    { id: intervalTriggerIds.enhertu.researchForm, generateDate: seconds(60) },
    { id: intervalTriggerIds.enhertu.myResearch, generateDate: seconds(75), templateMessageId: 'b49a3097-aeb9-4e36-8ca2-ae35096f411d' },
  ],
  lynparza: [
    { id: intervalTriggerIds.lynparza.fitness, generateDate: seconds(0) },
    { id: intervalTriggerIds.lynparza.fatigue, generateDate: seconds(15) },
    { id: intervalTriggerIds.lynparza.treatment, generateDate: seconds(30) },
    { id: intervalTriggerIds.lynparza.dosage, generateDate: seconds(45) },
    { id: intervalTriggerIds.lynparza.researchForm, generateDate: seconds(60) },
    { id: intervalTriggerIds.lynparza.myResearch, generateDate: seconds(75), templateMessageId: '57e01a92-351e-41cd-b8e6-3b7c0afbd5a7' },
  ],
};

export const getIntervalTriggerDate = (
  module: TContentModule, id: string, testMode?: boolean,
) => {
  const searchData = testMode ? testIntervalTriggerData : intervalTriggerData;
  const intervalTriggerItem = find(searchData[module], { id });

  if (intervalTriggerItem) {
    return getUnixTime(intervalTriggerItem.generateDate());
  }
  return null;
};

export const getTemplateMessageId = (
  module: TContentModule, id: string, testMode?: boolean,
): string | undefined => {
  const searchData = testMode ? testIntervalTriggerData : intervalTriggerData;
  const intervalTriggerItem = find(searchData[module], { id });

  if (intervalTriggerItem) {
    return intervalTriggerItem.templateMessageId;
  }

  return undefined;
};
