/* eslint react/require-default-props: 0 */
import React, {
  useRef,
  useEffect,
  ReactNode,
  memo,
} from 'react';
import { View as RNView, TouchableOpacity as RNTouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import round from 'lodash/round';
import * as d3Format from 'd3-format';

import Text from '../Text';
import Divider, { DividerProps } from '../Divider';
import { PlusIconContrast, MinusIconContrast } from '../../theme/Icons';
import Colors from '../../theme/Colors';
import styles from './styles';

type Props = {
  value: number;
  onChange: (value: number) => void;
  minValue?: number;
  maxValue?: number;
  labelText: string;
  unitLabelText?: string;
  decimals?: number;
  step?: number;
  divider?: boolean,
  dividerProps?: DividerProps,
  pristine?: boolean,
  headerButton?: ReactNode,
  centeredHeader?: boolean
};

const propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  labelText: PropTypes.string.isRequired,
  unitLabelText: PropTypes.string,
  decimals: PropTypes.number,
  step: PropTypes.number,
  divider: PropTypes.bool,
  dividerProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  pristine: PropTypes.bool,
  headerButton: PropTypes.node,
};

const NumericInput = (props: Props) => {
  const {
    value,
    onChange,
    minValue = null,
    maxValue = null,
    labelText,
    unitLabelText,
    decimals = 0,
    step = 1,
    divider = false,
    dividerProps = {},
    pristine = true,
    headerButton = null,
    centeredHeader = false,
  } = props;

  const valueRef = useRef(value);
  const timeoutRef = useRef<number>();

  useEffect(() => (
    () => {
      if (timeoutRef && timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }
  ), []);

  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  const minReached = value === minValue;
  const maxReached = value === maxValue;

  const leftButtonStyle = [
    styles.button,
    {
      backgroundColor: pristine ? Colors.softPrimary : Colors.secondary,
    },
    minReached ? styles.disabled : null,
  ];

  const rightButtonStyle = [
    styles.button,
    {
      backgroundColor: pristine ? Colors.softPrimary : Colors.secondary,
    },
    maxReached ? styles.disabled : null,
  ];

  const textColorStyle = {
    color: pristine ? Colors.lightGray : Colors.black,
  };

  const incrementStart = () => {
    if (pristine) {
      return onChange(valueRef.current);
    }

    if (maxValue && valueRef.current + step > maxValue) {
      return false;
    }

    onChange(round(valueRef.current + step, decimals));

    timeoutRef.current = window.setTimeout(incrementStart, 100);

    return true;
  };

  const incrementStop = () => {
    clearTimeout(timeoutRef.current);
  };

  const decrementStart = () => {
    if (pristine) {
      return onChange(valueRef.current);
    }

    if ((minValue || minValue === 0) && valueRef.current - step < minValue) {
      return false;
    }

    onChange(round(valueRef.current - step, decimals));

    timeoutRef.current = window.setTimeout(decrementStart, 100);

    return true;
  };

  const decrementStop = () => {
    clearTimeout(timeoutRef.current);
  };

  return (
    <RNView>
      <RNView style={styles.container}>
        <RNView style={[styles.header, centeredHeader && styles.centeredHeader]}>
          <Text type="heading">{labelText}</Text>
          {headerButton}
        </RNView>
        <RNView style={styles.inputContainer}>
          <RNTouchableOpacity
            onPressIn={decrementStart}
            onPressOut={decrementStop}
            disabled={minReached}
          >
            <RNView style={leftButtonStyle}>
              {MinusIconContrast}
            </RNView>
          </RNTouchableOpacity>
          <RNTouchableOpacity
            onPress={() => onChange(value)}
            disabled={!pristine}
            style={styles.textContainer}
          >
            <Text style={[styles.text, textColorStyle]} type="numeric-input">
              {d3Format.format(`.${decimals}f`)(value)}
            </Text>
            {
                  unitLabelText
                  && <Text type="body-accent" style={[styles.units, textColorStyle]}>{unitLabelText}</Text>
              }
          </RNTouchableOpacity>
          <RNTouchableOpacity
            onPressIn={incrementStart}
            onPressOut={incrementStop}
            disabled={maxReached}
          >
            <RNView style={rightButtonStyle}>
              {PlusIconContrast}
            </RNView>
          </RNTouchableOpacity>
        </RNView>
      </RNView>
      {
          divider
            ? <Divider {...dividerProps} /> // eslint-disable-line react/jsx-props-no-spreading
            : null
        }
    </RNView>
  );
};

NumericInput.propTypes = propTypes;

export default memo(NumericInput);
