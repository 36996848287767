import { Reducer } from 'redux';

import * as utils from './utils';
import * as actionTypes from './actionTypes';
import {
  QuestionnairesState,
  QuestionnairesActionTypes,
  QuestionnairesAddActionType,
  QuestionnairesAddQuestionsActionType,
  QuestionnairesAddQuestionPhotosActionType,
  QuestionnairesAddQuestionAudioRecordingsActionType,
  QuestionnairesRemoveActionType,
  QuestionnairesRemoveQuestionsActionType,
  QuestionnairesRemoveQuestionPhotosActionType,
  QuestionnairesRemoveQuestionAudioRecordingsActionType,
  QuestionnairesUpdateActionType,
  QuestionnairesUpdateQuestionsActionType,
  QuestionnairesReplaceQuestionsActionType,
  QuestionnairesCheckQuestionActionType,
  QuestionnairesUncheckQuestionActionType,
} from './types';

const initialState: QuestionnairesState = {};

const reducer: Reducer<
QuestionnairesState,
QuestionnairesActionTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD: {
      return utils.add(state, action as QuestionnairesAddActionType);
    }
    case actionTypes.ADD_QUESTIONS: {
      return utils.addQuestions(state, action as QuestionnairesAddQuestionsActionType);
    }
    case actionTypes.ADD_QUESTION_PHOTOS: {
      return utils.addQuestionPhotos(state, action as QuestionnairesAddQuestionPhotosActionType);
    }
    case actionTypes.ADD_QUESTION_AUDIO_RECORDINGS: {
      return utils.addQuestionAudioRecordings(
        state,
        action as QuestionnairesAddQuestionAudioRecordingsActionType,
      );
    }
    case actionTypes.REMOVE: {
      return utils.remove(state, action as QuestionnairesRemoveActionType);
    }
    case actionTypes.REMOVE_QUESTIONS: {
      return utils.removeQuestions(state, action as QuestionnairesRemoveQuestionsActionType);
    }
    case actionTypes.REMOVE_QUESTION_PHOTOS: {
      return utils.removeQuestionPhotos(
        state,
        action as QuestionnairesRemoveQuestionPhotosActionType,
      );
    }
    case actionTypes.REMOVE_QUESTION_AUDIO_RECORDINGS: {
      return utils.removeQuestionAudioRecordings(
        state,
        action as QuestionnairesRemoveQuestionAudioRecordingsActionType,
      );
    }
    case actionTypes.UPDATE: {
      return utils.update(state, action as QuestionnairesUpdateActionType);
    }
    case actionTypes.UPDATE_QUESTIONS: {
      return utils.updateQuestions(state, action as QuestionnairesUpdateQuestionsActionType);
    }
    case actionTypes.REPLACE_QUESTIONS: {
      return utils.replaceQuestions(state, action as QuestionnairesReplaceQuestionsActionType);
    }
    case actionTypes.CLEAR: {
      return utils.clear();
    }
    case actionTypes.CHECK_QUESTION: {
      return utils.checkQuestion(state, action as QuestionnairesCheckQuestionActionType);
    }
    case actionTypes.UNCHECK_QUESTION: {
      return utils.uncheckQuestion(state, action as QuestionnairesUncheckQuestionActionType);
    }
    default: return state;
  }
};

export default reducer;
