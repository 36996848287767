/* eslint react/require-default-props: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Path } from 'react-native-svg';
import ResizeableSVG from '../ResizeableSVG';

import Colors from '../../theme/Colors';
import Constants from '../../theme/Constants';

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

type Props = {
  color?: string;
  size?: number;
};

const Logo = (props: Props) => {
  const {
    color = Colors.primary,
    size = Constants.defaultIconSize,
  } = props;

  return (
    <ResizeableSVG size={size}>
      <Path d="m25.8600026 12.124925c-.7411352-4.87515912-4.3065639-8.76411269-9.0810223-9.9069938-.1475016-.03522163-.2962123-.0655851-.4437139-.09473404.367545.54654251.6202322 1.17810274.7229998 1.86310268.203117 1.3299201-.1946538 2.61611681-.9877773 3.57924616 2.3914608.78094851 4.1457629 2.8347338 4.5302344 5.3658329.2696136 1.7720122-.163219 3.54281-1.219911 4.9868967-1.056692 1.4428722-2.6090862 2.3889981-4.3718515 2.6574112-.8608292.1335992-1.7192403.0995921-2.5558889-.1020213-2.65865637-.6352039-4.64267416-2.8007267-5.05616233-5.5152212-.01692642-.110523-.03022574-.2222606-.04110701-.3327836-.00604515-.0546543-.00967224-.1105231-.0120903-.1651773-.00483612-.0546543-.01088127-.1093085-.01329933-.1639628-.00362709-.0740869-.00604515-.1481737-.00604515-.2210461-.00120902-.0340071-.00241805-.0680141-.00362708-.1020212 0-.0862323.00241806-.1724646.00604514-.2586968 0-.0206472 0-.0400798.00120903-.060727.00362709-.094734.01088127-.189468.01813545-.2842021.00120903-.0097163.00241806-.0206471.00241806-.031578.00967224-.0995922.02176253-.2003989.03506186-.2999911 0-.0036436.00120903-.0072872.00120903-.0109308.02901672-.2064717.06649664-.4129433.11485783-.6145567.60693292-2.58575336 2.7203169-4.67354574 5.491413-5.09863436 1.4508357-.22347515 2.4494943-1.58618781 2.2270328-3.04484903-.2224615-1.45987577-1.5789928-2.46065582-3.0310375-2.2383952-6.53964185 1.00442367-11.04811373 7.16577954-10.04824614 13.73522049.01571739.1044503.03989798.2076861.06770567.3084929-.00483612.002429-.00967224.0048581-.01329933.0072872.84511178 4.7257709 4.3549251 8.4726234 9.026616 9.5912137 1.506451.3607181 3.051591.4226596 4.5918949.187039 3.1688669-.4870301 5.9580985-2.1837409 7.8562752-4.777996 1.8969677-2.5942551 2.6755828-5.7751325 2.1919709-8.9572245" fill={color} fillRule="evenodd" />
    </ResizeableSVG>
  );
};

Logo.propTypes = propTypes;

export default Logo;
