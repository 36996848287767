import produce, { Draft } from 'immer';
import merge from 'deepmerge';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import reject from 'lodash/reject';
import includes from 'lodash/includes';
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import union from 'lodash/union';
import without from 'lodash/without';
import set from 'lodash/set';
import keyBy from 'lodash/keyBy';

import * as arrayHelpers from '../../utils/arrayHelpers';
import combineMerge from '../../utils/combineMerge';
import getUnixTime from '../../utils/getUnixTime';

import {
  QuestionnairesState,
  QuestionnairesAddActionType,
  QuestionnairesAddQuestionsActionType,
  QuestionnairesAddQuestionPhotosActionType,
  QuestionnairesAddQuestionAudioRecordingsActionType,
  QuestionnairesRemoveActionType,
  QuestionnairesRemoveQuestionsActionType,
  QuestionnairesRemoveQuestionPhotosActionType,
  QuestionnairesRemoveQuestionAudioRecordingsActionType,
  QuestionnairesUpdateActionType,
  QuestionnairesUpdateQuestionsActionType,
  QuestionnairesReplaceQuestionsActionType,
  QuestionnairesCheckQuestionActionType,
  QuestionnairesUncheckQuestionActionType,
} from './types';

const add = produce((
  draft: Draft<QuestionnairesState>,
  action: QuestionnairesAddActionType,
) => {
  const { payload } = action;

  map(payload, (questionnaire) => {
    const { id } = questionnaire;

    if (isEmpty(draft[id])) {
      draft[id] = questionnaire;

      return true;
    }

    if (isEqual(draft[id], questionnaire)) {
      return false;
    }

    draft[id] = merge(questionnaire, draft[id], { arrayMerge: combineMerge });

    return true;
  });
});

const addQuestions = (state: QuestionnairesState, action: QuestionnairesAddQuestionsActionType) => {
  const { payload } = action;
  const { questionnaireId, questions } = payload;

  const questionnaire = state[questionnaireId];

  if (isEmpty(questionnaire)) {
    return state;
  }

  return {
    ...state,
    [questionnaireId]: {
      ...questionnaire,
      updatedAt: getUnixTime(),
      questions: arrayHelpers.addItems(
        questionnaire.questions,
        questions,
      ),
    },
  };
};

const addQuestionPhotos = produce((
  draft: Draft<QuestionnairesState>,
  action: QuestionnairesAddQuestionPhotosActionType,
) => {
  const { payload } = action;
  const { questionnaireId, questionId, photoIds } = payload;

  const questions = get(draft, [questionnaireId, 'questions']);

  if (isEmpty(questions)) {
    return draft;
  }

  const index = findIndex(questions, { id: questionId });

  if (index === -1) {
    return draft;
  }

  const draftPhotoIds = get(draft, [questionnaireId, 'questions', index, 'photoIds']);
  const newPhotoIds = union(draftPhotoIds, photoIds);

  set(draft, [questionnaireId, 'updatedAt'], getUnixTime());
  set(draft, [questionnaireId, 'questions', index, 'photoIds'], newPhotoIds);

  return draft;
});

const remove = (state: QuestionnairesState, action: QuestionnairesRemoveActionType) => {
  const { payload } = action;

  return omit(state, payload);
};

const addQuestionAudioRecordings = produce((
  draft: Draft<QuestionnairesState>,
  action: QuestionnairesAddQuestionAudioRecordingsActionType,
) => {
  const { payload } = action;
  const { questionnaireId, questionId, audioRecordingIds } = payload;

  const questions = get(draft, [questionnaireId, 'questions']);

  if (isEmpty(questions)) {
    return draft;
  }

  const index = findIndex(questions, { id: questionId });

  if (index === -1) {
    return draft;
  }

  const draftAudioRecordingIds = get(draft, [questionnaireId, 'questions', index, 'audioRecordingIds']);
  const newAudioRecordingIds = union(draftAudioRecordingIds, audioRecordingIds);

  set(draft, [questionnaireId, 'questions', index, 'audioRecordingIds'], newAudioRecordingIds);

  return draft;
});

const removeQuestions = (
  state: QuestionnairesState,
  action: QuestionnairesRemoveQuestionsActionType,
) => {
  const { payload } = action;
  const { questionnaireId, questionIds } = payload;

  const questionnaire = state[questionnaireId];

  if (isEmpty(questionnaire)) {
    return state;
  }

  const questions = reject(
    questionnaire.questions,
    (question) => includes(questionIds, question.id),
  );

  return {
    ...state,
    [questionnaireId]: {
      ...questionnaire,
      questions,
    },
  };
};

const removeQuestionPhotos = produce((
  draft: Draft<QuestionnairesState>,
  action: QuestionnairesRemoveQuestionPhotosActionType,
) => {
  const { payload } = action;
  const { questionnaireId, questionId, photoIds } = payload;

  const questions = get(draft, [questionnaireId, 'questions']);

  if (isEmpty(questions)) {
    return draft;
  }

  const index = findIndex(questions, { id: questionId });

  if (index === -1) {
    return draft;
  }

  const draftPhotoIds = get(draft, [questionnaireId, 'questions', index, 'photoIds']);
  const newPhotoIds = without(draftPhotoIds, ...photoIds);

  set(draft, [questionnaireId, 'questions', index, 'photoIds'], newPhotoIds);

  return draft;
});

const removeQuestionAudioRecordings = produce((
  draft: Draft<QuestionnairesState>,
  action: QuestionnairesRemoveQuestionAudioRecordingsActionType,
) => {
  const { payload } = action;
  const { questionnaireId, questionId, audioRecordingIds } = payload;

  const questions = get(draft, [questionnaireId, 'questions']);

  if (isEmpty(questions)) {
    return draft;
  }

  const index = findIndex(questions, { id: questionId });

  if (index === -1) {
    return draft;
  }

  const draftAudioRecordingIds = get(draft, [questionnaireId, 'questions', index, 'audioRecordingIds']);
  const newAudioRecordingIds = without(draftAudioRecordingIds, ...audioRecordingIds);

  set(draft, [questionnaireId, 'questions', index, 'audioRecordingIds'], newAudioRecordingIds);

  return draft;
});

const update = (state: QuestionnairesState, action: QuestionnairesUpdateActionType) => {
  const { payload } = action;

  const questionnaires = keyBy(payload, 'id');

  return {
    ...state,
    ...questionnaires,
  };
};

const updateQuestions = produce((
  draft: Draft<QuestionnairesState>,
  action: QuestionnairesUpdateQuestionsActionType,
) => {
  const { payload } = action;
  const { questionnaireId, questions } = payload;

  const questionnaire = draft[questionnaireId];

  map(questions, (question) => {
    const index = findIndex(questionnaire.questions, { id: question.id });

    set(draft, [questionnaireId, 'updatedAt'], getUnixTime());
    set(draft, [questionnaireId, 'questions', index], question);
  });
});

const replaceQuestions = (
  state: QuestionnairesState,
  action: QuestionnairesReplaceQuestionsActionType,
) => {
  const { payload } = action;
  const { questionnaireId, questions } = payload;

  const questionnaire = state[questionnaireId];

  if (isEmpty(questionnaire)) {
    return state;
  }

  return {
    ...state,
    [questionnaireId]: {
      ...questionnaire,
      updatedAt: getUnixTime(),
      questions,
    },
  };
};

const clear = () => ({});

const checkQuestion = produce((
  draft: Draft<QuestionnairesState>,
  action: QuestionnairesCheckQuestionActionType,
) => {
  const { payload } = action;
  const { questionnaireId, questionId } = payload;

  const questions = get(draft, [questionnaireId, 'questions']);

  if (isEmpty(questions)) {
    return draft;
  }

  const index = findIndex(questions, { id: questionId });

  if (index === -1) {
    return draft;
  }

  set(draft, [questionnaireId, 'updatedAt'], getUnixTime());
  set(draft, [questionnaireId, 'questions', index, 'checked'], true);

  return draft;
});

const uncheckQuestion = produce((
  draft: Draft<QuestionnairesState>,
  action: QuestionnairesUncheckQuestionActionType,
) => {
  const { payload } = action;
  const { questionnaireId, questionId } = payload;

  const questions = get(draft, [questionnaireId, 'questions']);

  if (isEmpty(questions)) {
    return draft;
  }

  const index = findIndex(questions, { id: questionId });

  if (index === -1) {
    return draft;
  }

  set(draft, [questionnaireId, 'updatedAt'], getUnixTime());
  set(draft, [questionnaireId, 'questions', index, 'checked'], false);

  return draft;
});

export {
  add,
  addQuestions,
  addQuestionPhotos,
  addQuestionAudioRecordings,
  remove,
  removeQuestions,
  removeQuestionPhotos,
  removeQuestionAudioRecordings,
  update,
  updateQuestions,
  replaceQuestions,
  clear,
  checkQuestion,
  uncheckQuestion,
};
