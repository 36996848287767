import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ProfileQuestion from '../components/ProfileQuestion';
import Header from '../components/Header';
import PageHeader from '../components/PageHeader';
import Footer from '../components/Footer';

const ProfileQuestionScreen = () => {
  const { questionId } = useParams<any>();

  const { t } = useTranslation();

  return (
    <article className="app profile">
      <Header auth documentTitle={t('profile')} />
      <PageHeader title={t('profile')} />
      <ProfileQuestion questionId={questionId} />
      <Footer />
    </article>
  );
};

export default ProfileQuestionScreen;
