import React from 'react';
import '../css/Switch.css';

type SwitchParams = {
  value: boolean
  disabled?: boolean
  onValueChange: () => void,
};

const Switch = ({ value, onValueChange, disabled }: SwitchParams) => (
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  <label className="toggle-switch">
    <input type="checkbox" checked={value} onChange={onValueChange} disabled={disabled} />
    <span className="switch" />
  </label>
);

export default Switch;
