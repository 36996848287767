import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MarkdownView from 'react-showdown';
import every from 'lodash/every';

import { actions as dailySuggestions } from '../state/dailySuggestions';
import { selectors as settingsSelectors, actions as settingsActions } from '../state/settings';
import { AppState } from '../state/reducers';

import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import ListItem from '../components/ListItem';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRightHeader from '../components/DrawerRightHeader';
import Modal from '../components/Modal';
import Agree from '../components/Agree';
import Button from '../components/Button';

import { ReactComponent as IconInfo } from '../assets/icons/Info.svg';
import { ReactComponent as IconQuestionnaire } from '../assets/icons/Questionnaire.svg';
import { ReactComponent as IconChevronRight } from '../assets/icons/ChevronRight.svg';
import { ReactComponent as IconCode } from '../assets/icons/Code.svg';
import { ReactComponent as IconMyResearch } from '../assets/icons/MyResearch.svg';

import Colors from '../theme/Colors';
import getUnixTime from '../utils/getUnixTime';
import { intervalTriggerIds } from '../utils/intervalTrigger';

import { TDrugModule } from '../../types';

import '../css/Consent.css';
import LeaveDrugModuleSupportHoc from '../hocs/LeaveDrugModuleSupportHoc';
import DrugModuleBreadcrumbs from '../components/DrugModuleBreadcrumbs';
import dateFormat from '../utils/dateFormat';

type Params = {
  drugModule: TDrugModule;
};

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const surveyLinks = {
  enhertu: 'https://pxhc.limesurvey.net/755631?lang=en ',
  lynparza: 'https://pxhc.limesurvey.net/755631?lang=en ',
};

const MyResearch = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { drugModule } = useParams<Params>();

  const { i18n } = useTranslation();

  const [openedAboutResearchModal, setOpenedAboutResearchModal] = useState(false);
  const [openedConsentModal, setOpenedConsentModal] = useState(false);
  const [openedRevokeModal, setOpenedRevokeModal] = useState(false);
  const [openedUniqueKeyModal, setOpenedUniqueKeyModal] = useState(false);
  const [consent, setConsent] = useState([false, false, false, false, false, false, false]);

  const memoizedSettingsGetDrugModuleSelector = useMemo(
    () => settingsSelectors.makeGetDrugModule(drugModule),
    [drugModule],
  );

  const setting = useSelector(
    (state: AppState) => memoizedSettingsGetDrugModuleSelector(state),
  );

  useEffect(() => {
    const intervalTriggerId = intervalTriggerIds?.[drugModule]?.researchForm;

    if (intervalTriggerId) {
      dispatch(dailySuggestions.intervalTrigger(
        { ids: [intervalTriggerId], close: true },
      ));
    }
  }, [drugModule, dispatch]);

  const toggleAboutResearchModal = () => {
    setOpenedAboutResearchModal((prev) => !prev);
  };

  const toggleConsentModal = () => {
    setOpenedConsentModal((prev) => !prev);
  };

  const toggleRevokeModal = () => {
    setOpenedRevokeModal((prev) => !prev);
    setOpenedConsentModal(false);
  };

  const agreementData = [
    t('contentModules:drug:common:consent-agreement:adult'),
    t('contentModules:drug:common:consent-agreement:read-above-information'),
    t('contentModules:drug:common:consent-agreement:maximum-surveys'),
    t('contentModules:drug:common:consent-agreement:treatment-data-collection'),
    t('contentModules:drug:common:consent-agreement:information-for-reports'),
    t('contentModules:drug:common:consent-agreement:information-used-to-support'),
    t('contentModules:drug:common:consent-agreement:voluntarily-participating'),
  ];

  const toggleConsent = (index: number) => () => {
    if (!setting.surveyConsentedAt) {
      setConsent((prevConsent) => {
        const newConsent = [...prevConsent];
        newConsent[index] = !newConsent[index];

        return newConsent;
      });
    }
  };

  const openSurvey = () => {
    window.open(
      surveyLinks[drugModule], '_blank',
    );
  };

  const toggleUniqueKeyModal = () => {
    setOpenedUniqueKeyModal((prev) => !prev);
  };

  const accept = () => {
    dispatch(settingsActions.contentModulesUpdate(
      {
        moduleName: drugModule,
        surveyConsentedAt: getUnixTime(),
      },
    ));

    toggleConsentModal();
  };

  const copyUniqueKey = () => {
    const TempTextInput = document.createElement('input');
    TempTextInput.value = setting.surveyKey!;
    document.body.appendChild(TempTextInput);
    TempTextInput.select();

    document.execCommand('copy');
    document.body.removeChild(TempTextInput);

    toggleUniqueKeyModal();
  };

  const revoke = () => {
    dispatch(settingsActions.contentModulesUpdate(
      {
        moduleName: drugModule,
        surveyConsentedAt: 0,
      },
    ));

    toggleRevokeModal();
  };

  const aboutResearchAndData = (
    <ListItem
      primaryText={t('contentModules:drug:common:about-this-research-data')}
      leftIcon={<IconInfo fill={Colors.primary} className="icon" />}
      rightIcon={<IconChevronRight fill={Colors.primary} className="icon small" />}
      onClick={toggleAboutResearchModal}
      dividerTop
      divider
    />
  );

  let content = (
    <>
      <h3> {t(`contentModules:drug:${drugModule}:my-surveys:title`)}</h3>
      <p>{t(`contentModules:drug:${drugModule}:my-surveys:subtitle`)}</p>
      <h2> {t('contentModules:drug:common:surveys-to-do:title')}</h2>
      <p>{t('contentModules:drug:common:surveys-to-do:subtitle')}</p>
      <ListItem
        className="add-survey"
        primaryText={dateFormat(i18n.language, setting.surveyConsentedAt, 'd MMM yyyy')}
        rightIcon={<Button labelText={t('contentModules:drug:common:fill-in-now')} onClick={openSurvey} />}
        leftIcon={<IconQuestionnaire fill={Colors.primary} className="icon" />}
        onClick={openSurvey}
        dividerTop
        divider
      />

      <div className="empty-block" />

      {/* <h2>{t('contentModules:common:completed-surveys:title')}</h2> */}
      {/* <p> {t('contentModules:common:completed-surveys:subtitle')}</p> */}
      {/* <div className="list-item-content"> */}
      {/*  <ListItem */}
      {/*    primaryText="No 2 December 2024" */}
      {/*    rightIcon={<IconChevronRight fill={Colors.primary} className="icon small" />} */}
      {/*    leftIcon={<IconQuestionnaire fill={Colors.primary} className="icon" />} */}
      {/*    onClick={openSurvey} */}
      {/*    dividerTop */}
      {/*    divider */}
      {/*  /> */}
      {/*  <ListItem */}
      {/*    primaryText="No 3 December 2024" */}
      {/*    rightIcon={<IconChevronRight fill={Colors.primary} className="icon small" />} */}
      {/*    leftIcon={<IconQuestionnaire fill={Colors.primary} className="icon" />} */}
      {/*    onClick={openSurvey} */}
      {/*    dividerTop */}
      {/*  /> */}
      {/* </div> */}

      {aboutResearchAndData}

      <ListItem
        primaryText={t('your-unique-key')}
        leftIcon={<IconCode fill={Colors.primary} className="icon" />}
        rightIcon={<IconChevronRight fill={Colors.primary} className="icon small" />}
        onClick={toggleUniqueKeyModal}
        divider
      />
      <ListItem
        primaryText={t('contentModules:drug:common:your-consent')}
        leftIcon={<IconMyResearch fill={Colors.primary} className="icon" />}
        rightIcon={<IconChevronRight fill={Colors.primary} className="icon small" />}
        onClick={toggleConsentModal}
        divider
      />
    </>
  );

  if (!setting?.surveyConsentedAt) {
    content = (
      <>
        <h3> {t(`contentModules:drug:${drugModule}:my-surveys:title`)}</h3>
        <p>{t(`contentModules:drug:${drugModule}:medical-research`)}</p>
        {aboutResearchAndData}
        <p>{t(`contentModules:drug:${drugModule}:personal-identifiable-details`)}</p>
        <div className="give-consent-button align-center">
          <Button labelText={t('contentModules:drug:common:give-consent')} size="medium" onClick={toggleConsentModal} />
        </div>
      </>
    );
  }

  return (
    <article className="page when-seek-help row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('contentModules:drug:common:my-research')} showRightButton />
          <DrugModuleBreadcrumbs drugModule={drugModule} />
          {setting ? (
            <section className="consent-container">
              {content}
              <p className="keep-updated">{t('contentModules:drug:common:keep-updated')}</p>

              <Modal
                className="researchModal about-research"
                visible={openedAboutResearchModal}
                hideModal={toggleAboutResearchModal}
                actions={[
                  {
                    title: t('close'),
                    onClick: toggleAboutResearchModal,
                  },
                ]}
              >
                <MarkdownView markdown={t(`contentModules:drug:${drugModule}:markdown:about-research`)} />
              </Modal>
              <Modal
                visible={openedUniqueKeyModal}
                hideModal={toggleUniqueKeyModal}
                actions={[
                  {
                    title: t('alerts.unique-key.copy-btn'),
                    onClick: copyUniqueKey,
                  },
                  {
                    title: t('alerts.unique-key.ok-btn'),
                    onClick: toggleUniqueKeyModal,
                  },
                ]}
              >
                <p className="semi-bold">{t('alerts.unique-key.title')}: {setting.surveyKey}</p>
              </Modal>

              <Modal
                className="default-modal align-center"
                visible={openedRevokeModal}
                hideModal={toggleRevokeModal}
                actions={[
                ]}
              >
                <h1 className="heading">{t('modals.revoke-consent.header')}</h1>
                <p>{t('modals.revoke-consent.body')}</p>
                <Button labelText={t('yes')} onClick={revoke} />
                <p>{t('modals.revoke-consent.yes')}</p>
                <Button labelText={t('no')} onClick={revoke} />
                <p>{t('modals.revoke-consent.no')}</p>
              </Modal>

              <Modal
                className="research-modal"
                visible={openedConsentModal}
                hideModal={toggleConsentModal}
                actions={[
                  {
                    title: setting.surveyConsentedAt ? t('revoke-consent') : t('accept'),
                    onClick: setting.surveyConsentedAt ? toggleRevokeModal : accept,
                    disabled: setting.surveyConsentedAt ? false : !every(consent, Boolean),
                    buttonClass: 'research-consent-button',
                  },
                ]}
              >
                <>
                  <h1 className="heading align-center">{t('contentModules:drug:common:consent')}</h1>
                  <div className="markdown-consent">
                    <MarkdownView
                      markdown={t(`contentModules:drug:${drugModule}:markdown:consent`)}
                    />
                  </div>
                  {agreementData.map((agreement, index) => (
                    <Agree
                      key={agreement}
                      question={`${index + 1}. ${agreement}`}
                      value={!!setting.surveyConsentedAt || consent[index]}
                      disabled={!!setting.surveyConsentedAt}
                      onValueChange={toggleConsent(index)}
                    />
                  ))}
                  <p className="primary semi-bold">{t('your-unique-key')}</p>
                  <p className="surveyKey semi-bold">{setting.surveyKey}</p>
                </>
              </Modal>
            </section>
          ) : null}
        </section>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default LeaveDrugModuleSupportHoc(MyResearch);
