/* eslint react/require-default-props: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Path } from 'react-native-svg';
import ResizeableSVG from '../ResizeableSVG';

import Colors from '../../theme/Colors';
import Constants from '../../theme/Constants';

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

type Props = {
  color?: string;
  size?: number;
};

const Add = (props: Props) => {
  const {
    color = Colors.primary,
    size = Constants.defaultIconSize,
  } = props;

  return (
    <ResizeableSVG size={size}>
      <Path d="m12.5694544 22.5832737v-7.1527281h-7.15272805c-.79109172 0-1.43054561-.6408844-1.43054561-1.4305456s.63945389-1.4305456 1.43054561-1.4305456h7.15272805v-7.15272805c0-.78966118.6394539-1.43054561 1.4305456-1.43054561s1.4305456.64088443 1.4305456 1.43054561v7.15272805h7.1527281c.7910917 0 1.4305456.6408844 1.4305456 1.4305456s-.6394539 1.4305456-1.4305456 1.4305456h-7.1527281v7.1527281c0 .7896611-.6394539 1.4305456-1.4305456 1.4305456s-1.4305456-.6408845-1.4305456-1.4305456z" fill={color} fillRule="evenodd" />
    </ResizeableSVG>
  );
};

Add.propTypes = propTypes;

export default Add;
