import React, { ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Colors from '../theme/Colors';
import { ReactComponent as IconAlert } from '../assets/icons/Alert.svg';

import '../css/AnimatedTextInput.css';

type Props = {
  value: string;
  labelText: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  inputType?: string;
  errorText?: string;
};

const propTypes = {
  value: PropTypes.string.isRequired,
  labelText: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  inputType: PropTypes.string,
  errorText: PropTypes.string,
};

const AnimatedTextInput = (props: Props) => {
  const {
    value,
    labelText = '',
    onChange,
    inputType = 'text',
    errorText = '',
  } = props;

  return (
    <>
      <section className={classNames('animated-text-input', { error: errorText })}>
        <input
          type={inputType}
          value={value}
          onChange={onChange}
          className={classNames({ active: value, error: errorText })}
          placeholder={labelText}
        />
        <div className="bar" />
        <span className="label">{value && labelText}</span>
      </section>
      {
        errorText
          ? (
            <div className="row center error">
              <IconAlert fill={Colors.destructiveRed} className="icon small margin right" />
              <p className="body error">{errorText}</p>
            </div>
          )
          : null
      }
    </>
  );
};

AnimatedTextInput.propTypes = propTypes;

export default AnimatedTextInput;
