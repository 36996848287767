import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Header from '../components/Header';
import PageHeader from '../components/PageHeader';
import Footer from '../components/Footer';
import Modal from '../components/Modal';
import '../css/RightToBeForgotten.css';

const RightToBeForgotten = () => {
  const { t } = useTranslation();
  const [modal, setModal] = useState({ visible: false, title: '', description: '' });

  const hideModal = () => setModal({ visible: false, title: '', description: '' });

  return (
    <article className="app right-to-be-forgotten">
      <Header auth />
      <PageHeader title={t('right-to-be-forgotten')} />
      <section className="content">
        <button
          type="button"
          className="button destructive"
          onClick={() => setModal({ visible: true, title: t('delete-account'), description: t('delete-account-confirm') })}
        >
          {t('delete-account')}
        </button>
        <button
          type="button"
          className="button destructive"
          onClick={() => setModal({ visible: true, title: t('delete-account-data'), description: t('delete-account-data-confirm') })}
        >
          {t('delete-account-data')}
        </button>
      </section>
      <Footer />
      <Modal
        visible={modal.visible}
        hideModal={hideModal}
        actions={[
          {
            title: t('close'),
            onClick: hideModal,
          },
          {
            title: t('delete'),
            destructive: true,
            onClick: hideModal,
          },
        ]}
      >
        <>
          <h1 className="heading">{modal.title}</h1>
          <p className="body">{modal.description}</p>
        </>
      </Modal>
    </article>
  );
};

export default RightToBeForgotten;
