/* eslint react/require-default-props: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Path } from 'react-native-svg';
import ResizeableSVG from '../ResizeableSVG';

import Colors from '../../theme/Colors';
import Constants from '../../theme/Constants';

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

type Props = {
  color?: string;
  size?: number;
};

const Appointment = (props: Props) => {
  const {
    color = Colors.primary,
    size = Constants.defaultIconSize,
  } = props;

  return (
    <ResizeableSVG size={size}>
      <Path d="m5.52849118 6.77100671c-.48230379 0-.87433807.39216108-.87433807.87409396v3.04751673h18.69169379v-3.04751673c0-.48193288-.3920343-.87409396-.8741018-.87409396h-3.1431272v.8504698c0 .46350604-.3632047.82684564-.8270765.82684564-.4636355 0-.8270766-.3633396-.8270766-.82684564v-.8504698h-7.3489296v.8504698c0 .46350604-.36344109.82684564-.82707657.82684564-.46387179 0-.82707655-.3633396-.82707655-.82684564v-.8504698zm-.87433807 15.26120809c0 .4819329.39203428.8740939.87433807.8740939h16.91962322c.4711973 0 .8653584-.3770416.8972599-.8582657l.0004726-9.7253584h-18.69169379zm.87433807 2.5277852c-1.39421476 0-2.52849118-1.1339597-2.52849118-2.5277852v-14.38711413c0-1.3938255 1.13427642-2.52778523 2.52849118-2.52778523h3.11926014v-.8504698c0-.46374228.36320476-.82684564.82707655-.82684564.46363549 0 .82707653.36310336.82707653.82684564v.8504698h7.349166v-.8504698c0-.46374228.3632047-.82684564.8270765-.82684564s.8270766.36310336.8270766.82684564v.8504698h3.1428909c1.4091021 0 2.5303816 1.11198926 2.5518856 2.53132886v14.3835705c0 1.3938255-1.1340401 2.5277852-2.5282549 2.5277852z" fill={color} fillRule="evenodd" />
    </ResizeableSVG>
  );
};

Appointment.propTypes = propTypes;

export default Appointment;
