import { StyleSheet } from 'react-native';

import Colors from '../../theme/Colors';

export default StyleSheet.create({
  container: {
    marginHorizontal: 2,
    paddingVertical: 10,
    borderWidth: 2,
    borderColor: Colors.primary,
  },
  titleText: {
    paddingBottom: 5,
    paddingHorizontal: 16,
    alignSelf: 'center',
  },
  text: {
    textAlign: 'center',
    paddingHorizontal: 16,
    alignSelf: 'center',
  },
  innerText: {
    textAlign: 'center',
    alignSelf: 'center',
  },
  emphasized: {
    textDecorationLine: 'underline',
    color: Colors.secondary,
  },
});
