import first from 'lodash/first';
import last from 'lodash/last';
import * as d3 from 'd3';
import differenceInDays from 'date-fns/differenceInDays';

import { TGraphData, TrendGraphProps } from '../../../types';

import getUnixTime from '../../utils/getUnixTime';

export const getStartDate = (data: TGraphData[]): number => {
  const { date } = first(data) as TGraphData;

  return date;
};

export const getEndDate = (data: TGraphData[]): number => {
  const { date } = last(data) as TGraphData;

  return date;
};

export const getFixedScaleX = (
  startTimestamp: number,
  endTimestamp: number,
  width: number,
  horizontalPadding: number,
): d3.ScaleTime<number, number> => d3
  .scaleTime()
  .domain([startTimestamp, endTimestamp])
  .range([horizontalPadding, width - horizontalPadding]);

export const getScaleY = (
  height: number,
  min: number,
  max: number,
  verticalPadding: number,
): d3.ScaleLinear<number, number> => d3
  .scaleLinear()
  .domain([min, max])
  .range([height - verticalPadding, verticalPadding]);

// Wraps d3.timeFormat formatters such that they can accept a number or a value rather than just a date.
// This allows time formatters to be used for example in axis.tickFormat(...).
export const wrapTimeFormatter = (
  timeFormatter: (date: Date) => string,
): (
  domainValue: number | Date | { valueOf(): number },
  index: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ) => string => (domainValue, index) => {
  if (domainValue instanceof Date) {
    return timeFormatter(domainValue);
  }

  if (typeof domainValue === 'number') {
    return timeFormatter(new Date(domainValue));
  }

  return timeFormatter(new Date(domainValue.valueOf()));
};

// Adds a datapoint at the beginning and end of each series that copies the earliest
// and latest values and extends their dates.
export const scaleRange5ToRange10 = (value: number): number => 1 + (value - 1) * 2;

export const scaleRange10ToRange5 = (value: number): number => Math.round((value - 1) / 2) + 1;

export const getTimeFormatting = (
  startDate: Date,
  endDate: Date,
): {
  timeFormat: string;
  timeAxisStep: d3.TimeInterval | null;
} => {
  let timeFormat = '%e %b';
  let timeAxisStep: d3.TimeInterval | null;

  const days = differenceInDays(endDate, startDate);

  // Year
  if (days >= 365) {
    timeFormat = '%b %Y';
    timeAxisStep = d3.timeMonth.every(3);
  // Month
  } else if (days > 7) {
    timeAxisStep = d3.timeWeek.every(1);
  // Week
  } else {
    timeAxisStep = d3.timeDay.every(2);
  }

  return { timeAxisStep, timeFormat };
};

export interface D3GraphInfo {
  scaleX: d3.ScaleTime<number, number>;
  scaleY: d3.ScaleLinear<number, number>;

  xAxis: d3.Axis<number | Date | { valueOf(): number }>;

  line: d3.Line<[number, number]>;

  bisectDate: d3.Bisector<TGraphData, unknown>;
}

export const setUpTrendGraph = ({
  startDate,
  endDate,
  height,
  maximumValue,
  minimumValue,
  width,
  xPadding,
  yPadding,
  millis = true,
}: TrendGraphProps): D3GraphInfo => {
  const { timeAxisStep, timeFormat } = getTimeFormatting(startDate, endDate);

  const startTimestamp = getUnixTime(startDate, millis);
  const endTimestamp = getUnixTime(endDate, millis);

  const scaleX = getFixedScaleX(
    startTimestamp,
    endTimestamp,
    width,
    xPadding,
  );
  const scaleY = getScaleY(height, minimumValue, maximumValue, yPadding);

  const xAxis = d3
    .axisBottom(scaleX)
    .ticks(timeAxisStep)
    .tickFormat(wrapTimeFormatter(d3.timeFormat(timeFormat)))
    .tickSizeOuter(0);

  const line = d3
    .line()
    .x((d: any) => scaleX(d.date) || d.date)
    .y((d: any) => scaleY(d.value) || d.value)
    .curve(d3.curveMonotoneX);

  const bisectDate = d3.bisector((d: TGraphData) => d.date);

  return {
    scaleX,
    scaleY,
    xAxis,
    line,
    bisectDate,
  };
};
