import * as actionTypes from './actionTypes';
import { TagsActionTypes, TagsAddPayload, TagsRemovePayload } from './types';

const add = (payload: TagsAddPayload): TagsActionTypes => ({
  type: actionTypes.ADD,
  payload,
});

const remove = (payload: TagsRemovePayload): TagsActionTypes => ({
  type: actionTypes.REMOVE,
  payload,
});

const clear = (): TagsActionTypes => ({
  type: actionTypes.CLEAR,
});

export {
  add,
  remove,
  clear,
};
