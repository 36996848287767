import omit from 'lodash/omit';
import keyBy from 'lodash/keyBy';

import {
  AudioRecordingsState,
  AudioRecordingsAddActionType,
  AudioRecordingsRemoveActionType,
  AudioRecordingsUpdateActionType,
} from './types';

const add = (state: AudioRecordingsState, action: AudioRecordingsAddActionType) => {
  const { payload } = action;

  const audioRecordings = keyBy(payload, 'id');

  return {
    ...state,
    ...audioRecordings,
  };
};

const remove = (state: AudioRecordingsState, action: AudioRecordingsRemoveActionType) => {
  const { payload } = action;

  return omit(state, payload);
};

const update = (state: AudioRecordingsState, action: AudioRecordingsUpdateActionType) => {
  const { payload } = action;

  const audioRecordings = keyBy(payload, 'id');

  return {
    ...state,
    ...audioRecordings,
  };
};

const clear = () => ({});

export {
  add,
  remove,
  update,
  clear,
};
