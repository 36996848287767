import { Reducer } from 'redux';

import * as utils from './utils';
import * as actionTypes from './actionTypes';
import {
  TreatmentTypesState,
  TreatmentTypesActionTypes,
  TreatmentTypesAddActionType,
  TreatmentTypesReplaceActionType,
} from './types';

import { treatmentTypes } from '../../../data/system-document.json';

const initialState = treatmentTypes as TreatmentTypesState;

const reducer: Reducer<
TreatmentTypesState,
TreatmentTypesActionTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD: {
      return utils.add(state, action as TreatmentTypesAddActionType);
    }
    case actionTypes.REPLACE: {
      return utils.replace(state, action as TreatmentTypesReplaceActionType);
    }
    case actionTypes.CLEAR: return utils.clear();
    default: return state;
  }
};

export default reducer;
