import { StyleSheet as RNStyleSheet } from 'react-native';

import Colors from '../../theme/Colors';

export default RNStyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 12,
    paddingLeft: 17,
    paddingRight: 11,
    paddingTop: 8,
  },
  label: {
    marginRight: 8,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
    paddingHorizontal: 24,
    alignSelf: 'center',
    borderWidth: 2,
    borderColor: Colors.lightPrimary,
    borderRadius: 28,
    marginBottom: 12,
  },
  input: {
    fontFamily: 'Avenir-Heavy',
    color: Colors.black,
    fontSize: 28,
    lineHeight: 38,
    minWidth: 92,
  },
  units: {
    lineHeight: 38,
    marginLeft: 4,
  },
  error: {
    paddingLeft: 17,
    marginBottom: 12,
  },
});
