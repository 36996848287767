import React, { useState, useMemo, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import nl2br from 'react-nl2br';
import Dropdown from 'react-dropdown';
import map from 'lodash/map';
import get from 'lodash/get';

import { AppState } from '../state/reducers';

import '../css/Language.css';
import Header from '../components/Header';
import PageHeader from '../components/PageHeader';
import Footer from '../components/Footer';
import { getLocale, changeLanguageAsync } from '../services/i18n';
import { actions as UIActions } from '../state/ui';
import { selectors as tagsSelectors } from '../state/tags';
import localConfig from '../config';

const Language = () => {
  const currentLanguage = getLocale();

  const [newLanguage, setNewLanguage] = useState(currentLanguage);

  const memoizedTagsMakeGetValueByKey = useMemo(
    tagsSelectors.makeGetValueByKey,
    [],
  );

  let country = useSelector(
    (state: AppState) => memoizedTagsMakeGetValueByKey(state, 'country'),
  ) as string;

  // Default to UK if there is no country tag
  if (!country) {
    country = 'uk';
  }

  const languages = get(localConfig, ['availableLanguages', country]);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    try {
      // Update i18n with new language
      await changeLanguageAsync(newLanguage);
    } catch (error) {
      dispatch(UIActions.addNotification({ text: t('unknown-error'), type: 'error' }));
    }
  };

  const options = map(languages, ({ language, name }) => ({
    value: language,
    label: name,
  }));

  return (
    <article className="app language">
      <Header auth documentTitle={t('language')} />
      <PageHeader
        title={t('pageHeaders.language.title')}
        subtitle={nl2br(t('pageHeaders.language.subTitle')) as unknown as string}
      />
      <form className="content" onSubmit={handleSubmit}>
        <Dropdown
          options={options}
          onChange={(option) => setNewLanguage(option.value)}
          value={newLanguage}
          placeholder={t('country')}
        />
        <button type="submit" className="button accent" disabled={newLanguage === currentLanguage}>{t('save')}</button>
      </form>
      <Footer />
    </article>
  );
};

export default Language;
