import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';

import '../css/PageHeader.css';

type Props = {
  title?: string;
  subtitle?: string;
  children?: ReactNode;
};

const propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
};

const PageHeader = (props: Props) => {
  const {
    title,
    subtitle = '',
    children,
  } = props;

  return (
    <section className="page-header">
      <section>
        {title && <h1 className="heading-large accent">{title}</h1>}
        {subtitle ? <p className="body">{subtitle}</p> : null}
        {children}
      </section>
    </section>
  );
};

PageHeader.propTypes = propTypes;

export default PageHeader;
