import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import capitalize from 'lodash/capitalize';
import has from 'lodash/has';
import orderBy from 'lodash/orderBy';

import { SettingsInvitationCodesReturnType, TDrugModule } from 'types';
import { AppState } from '../state/reducers';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRightHeader from '../components/DrawerRightHeader';
import ListItem from '../components/ListItem';
import Modal from '../components/Modal';
import Button from '../components/Button';
import Colors from '../theme/Colors';
import {
  selectors as settingsSelectors,
  actions as settingsActions,
} from '../state/settings';
import { ReactComponent as IconCode } from '../assets/icons/Code.svg';
import { ReactComponent as IconDelete } from '../assets/icons/Delete.svg';
import testInvitationCodeByArray from '../utils/testInvitationCodeByArray';
import azInvitationCodes from '../../data/azInvitationCodes';
import InvitationCodeEditModal from '../components/InvitationCodeEditModal';
import { selectors as tagsSelectors } from '../state/tags';
import { CodeTypes, getCurrentCodeType, getLinkedCodesTypes } from '../utils/inviteCodeHelpers';
import useDeleteHospitalCodeWithRelations from '../hooks/useDeleteHospitalCodeWithRelations';

import '../css/InvitationCodes.css';

type TRightButtonBlock = { [key: string]: string };

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const InvitationCodesEdit = () => {
  const history = useHistory();

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { deleteHospitalCodeWithRelations } = useDeleteHospitalCodeWithRelations();

  const [editCodeError, setEditCodeError] = useState(false);
  const [editedCode, setEditedCode] = useState('');
  const [isSupportedDrugModule, setIsSupportedDrugModule] = useState(false);
  const [visibleCode, setVisibleCode] = useState('');
  const [removedDrug, setRemovedDrug] = useState('');
  const [moduleRemoving, setModuleRemoving] = useState(false);
  const [regionCodeDeletionFlag, setRegionCodeDeletionFlag] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [buttonPressed, setButtonPressed] = useState(false);
  const [isCodeDeletionModal, setIsCodeDeletionModal] = useState(false);
  const [modal, setModal] = useState({
    visible: false, title: '', body: '', bodyYes: '', bodyNo: '',
  });

  const invitationCodes = useSelector(
    (state: AppState) => settingsSelectors.getAllInvitationCodes(state),
  );

  const drugContentModule = useSelector(
    (state: AppState) => settingsSelectors.getDrugContentModules(state),
  );

  const memoizedTagsMakeGetValueByKey = useMemo(
    tagsSelectors.makeGetValueByKey,
    [],
  );

  const country = useSelector(
    (state: AppState) => memoizedTagsMakeGetValueByKey(state, 'country'),
  ) as string;

  const hospitalCodes = (
    useSelector((state: AppState) => settingsSelectors.getAllHospitalGroupAccessCodes(state))
  );

  const codeTypes = getLinkedCodesTypes(invitationCodes, country);
  const [allCodes, setAllCodes] = useState([...invitationCodes, ...hospitalCodes]);

  useEffect(() => {
    setAllCodes([...invitationCodes, ...hospitalCodes]);
  }, [hospitalCodes, invitationCodes]);

  const sortedAllCodes: string[] = orderBy(
    allCodes,
    [(value: SettingsInvitationCodesReturnType) => (value === codeTypes.regional ? 0 : 1)],
  );

  const isVisibleCodeRegional = getCurrentCodeType(visibleCode, country) === CodeTypes.regional;
  const typeOfVisibleCode = getCurrentCodeType(visibleCode, country, hospitalCodes);

  const showModal = (code: string, codeType: CodeTypes) => {
    setVisibleCode(code);
    let title = '';
    switch (codeType) {
      case CodeTypes.hospital:
        title = `${t('modals.delete-invitation-code.hospital-title')}`;
        break;
      default:
        title = `${t('modals.delete-invitation-code.invitation-title')}`;
        break;
    }
    setModal({
      visible: true,
      title,
      body: `${t('modals.delete-invitation-code.body')}`,
      bodyYes: `${t('modals.delete-invitation-code.bodyYes')}`,
      bodyNo: `${t('modals.delete-invitation-code.bodyNo')}`,
    });
  };

  const hideModal = () => {
    setVisibleCode('');
    setModal({
      visible: false, title: '', body: '', bodyYes: '', bodyNo: '',
    });
  };

  const drugs = keys(drugContentModule) as TDrugModule[];

  const forceWindowReload = () => {
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    const modalOpenTimes = regionCodeDeletionFlag ? drugs.length - 1 : 0;
    if (currentIndex < modalOpenTimes) {
      setCurrentIndex(currentIndex + 1);
      setIsModalVisible(true);
    } else if (currentIndex === modalOpenTimes) {
      setRegionCodeDeletionFlag(false);
      setCurrentIndex(0);
    }
    if (regionCodeDeletionFlag && currentIndex >= modalOpenTimes) {
      forceWindowReload();
    }
  };

  useEffect(() => {
    if (buttonPressed && !isModalVisible) {
      setIsModalVisible(true);
      setButtonPressed(false);
    }
  }, [isModalVisible, buttonPressed]);

  const toggleDeleteModal = (
    code?: string,
    removeContentModule = false,
    isCodeDeletion = false,
    drug?: TDrugModule,
  ) => () => {
    setButtonPressed(true);
    setIsModalVisible(false);
    setRemovedDrug(drug as TDrugModule);
    setRegionCodeDeletionFlag(isCodeDeletion);

    if (code) {
      if (isEmpty(drugs)) {
        setIsSupportedDrugModule(false);
      } else {
        setIsSupportedDrugModule(
          testInvitationCodeByArray(code, azInvitationCodes[country]),
        );
      }
    }

    setModuleRemoving(removeContentModule);
    setVisibleCode(code || '');
  };

  const deleteDrugs = (deleteData: boolean, drug?: TDrugModule) => {
    if (deleteData) {
      dispatch(settingsActions.drugModulesRemove({
        removeCodes: !moduleRemoving,
        invitationCodes: [visibleCode],
        drugModule: drug ?? removedDrug as TDrugModule,
      }));
    } else {
      dispatch(settingsActions.drugModulesRemove({
        removeCodes: !moduleRemoving,
        invitationCodes: [visibleCode],
        drugModule: drug ?? removedDrug as TDrugModule,
      }));
    }
  };

  const handleDeleteCode = (deleteData: boolean = false) => () => {
    if (isSupportedDrugModule) {
      if (regionCodeDeletionFlag) {
        // Regional code delete button pressed (with existing support tool)
        window.Countly.opt_in();
        deleteDrugs(deleteData, drugs[0]);
      } else {
        // Support tool delete button pressed
        deleteDrugs(deleteData);
        setVisibleCode('');
      }

      closeModal();
      return;
    }

    if (getCurrentCodeType(visibleCode, country) === CodeTypes.regional) {
      window.Countly.opt_in();
      forceWindowReload();
    }

    dispatch(settingsActions.invitationCodesRemove([visibleCode]));
    setVisibleCode('');
  };

  const handleRegionCodeDelete = (code: string, typeOfDeletedCode: CodeTypes) => {
    if (typeOfDeletedCode === CodeTypes.regional) {
      if (isEmpty(drugs)) {
        setIsSupportedDrugModule(false);
        setIsModalVisible(true);
      } else {
        setIsSupportedDrugModule(testInvitationCodeByArray(code, azInvitationCodes[country]));
        setIsModalVisible(false);
      }
    } else {
      setIsSupportedDrugModule(false);
      setIsModalVisible(true);
    }

    setVisibleCode(code || '');
    setIsCodeDeletionModal(true);
  };

  const handleOtherCodeDelete = () => {
    if (typeOfVisibleCode === CodeTypes.hospital) {
      deleteHospitalCodeWithRelations(visibleCode);
    } else {
      dispatch(settingsActions.invitationCodesRemove([visibleCode]));
    }
    setVisibleCode('');
    hideModal();
  };

  const submitToolsDeletion = () => {
    setVisibleCode('');
    setIsCodeDeletionModal(false);
    toggleDeleteModal(visibleCode, false, true)();
  };

  const rejectToolsDeletion = () => {
    setIsCodeDeletionModal(false);
    setVisibleCode('');
  };

  const isModalRegionalDeletion = isCodeDeletionModal && isSupportedDrugModule;
  // eslint-disable-next-line max-len
  const isModalSupportToolDeletion = isModalVisible && isSupportedDrugModule && !!visibleCode && !isCodeDeletionModal && isVisibleCodeRegional;

  useEffect(() => {
    if (allCodes.length <= 0) {
      history.goBack();
    }
  }, [allCodes]);

  const RightButtonBlock = (params: TRightButtonBlock) => {
    const { code } = params;
    const typeOfDeletedCode = getCurrentCodeType(code, country, hospitalCodes);
    return (
      <section style={{}}>
        <button
          type="button"
          onClick={() => (
            typeOfDeletedCode === CodeTypes.regional
              ? handleRegionCodeDelete(code, typeOfDeletedCode)
              : showModal(code, typeOfDeletedCode as CodeTypes))}
        >
          <IconDelete fill={Colors.destructiveRed} className="icon" />
        </button>
      </section>
    );
  };

  return (
    <article className="page diary row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('codes-edit-title')} showRightButton />
          <section className="diary-item">
            <h2 className="heading-secondary">{t('my-codes')}</h2>

            {!isEmpty(sortedAllCodes) ? (
              map(sortedAllCodes, (code) => (
                <div key={code}>
                  <ListItem
                    key={code}
                    primaryText={code}
                    leftIcon={<IconCode fill={Colors.primary} className="icon" />}
                    rightIcon={<RightButtonBlock code={code} />}
                    divider
                  />
                  {getCurrentCodeType(code, country) === CodeTypes.regional ? (
                    <section className="edit-code-padding">
                      <h1>{t('edit-code-question')}</h1>
                      <div className="add-code align-left mt10">
                        <Button onClick={() => setEditedCode(code)} labelText={t('edit-code-button')} />
                      </div>
                    </section>
                  ) : null}
                </div>
              ))
            ) : (
              <p className="body light">{t('empty-state-my-codes')}</p>
            )}
          </section>

          {has(codeTypes, 'regional') && !isEmpty(drugs) ? (
            <>
              <div className="support-tools-header-block mt40">
                <h1 className="heading-large accent mb16">{t('my-support-tools')}</h1>
              </div>

              <section className={isEmpty(drugs) ? '' : 'diary-item'}>
                { map(drugs, (drug) => (
                  <ListItem
                    key={drug}
                    primaryText={t(`contentModules:drug:${drug}:my-research-navigation-title`)}
                    secondaryText={t(`contentModules:drug:${drug}:my-research-subtitle`)}
                    leftIcon={(
                      <div
                        className="drug-left-icon"
                        style={
                          {
                            height: 50,
                            width: 15,
                            backgroundColor: Colors.drugModuleColors.secondary[drug],
                          }
                        }
                      />
                    )}
                    rightIcon={(
                      <button
                        type="button"
                        onClick={toggleDeleteModal(
                          drugContentModule[drug].invitationCode,
                          true,
                          false,
                          drug,
                        )}
                      >
                        <IconDelete fill={Colors.destructiveRed} className="icon" />
                      </button>
                    )}
                    divider
                  />
                ))}
              </section>
            </>
          ) : null}
        </section>
        <Footer />
      </section>
      <DrawerRight />

      {/* Regional Code with support tool deletion */}
      <Modal
        visible={isModalRegionalDeletion}
        hideModal={rejectToolsDeletion}
        actions={[
          {
            title: t('cancel'),
            onClick: rejectToolsDeletion,
          },
        ]}
      >
        <div className="delete-treatment-code-modal">
          <h1 className="heading mb16">{t('delete-code-with-support-tools.title')}</h1>
          {map(drugs, (drug) => <div className="body mb16" key={drug}>{capitalize(drug)}</div>)}
          <p className="body">{t('delete-code-with-support-tools.description')}</p>
          <Button onClick={submitToolsDeletion} extraClass="wide">
            {t('yes')}
          </Button>
        </div>
      </Modal>

      {/* Support tool deletion */}
      <Modal
        visible={isModalSupportToolDeletion}
        hideModal={toggleDeleteModal()}
        actions={[
          {
            title: t('cancel'),
            onClick: () => setIsModalVisible(false),
          },
        ]}
      >
        <div className="delete-treatment-code-modal">
          <h1 className="heading">{`${t('modals.delete-support-tool.title-start')} ${regionCodeDeletionFlag ? capitalize(drugs[0]) : capitalize(removedDrug)} ${t('modals.delete-support-tool.title-end')}`}</h1>
          <p className="body">{t('modals.delete-code.body')}</p>

          <div className="modal-button-block-wrapper">
            <Button onClick={handleDeleteCode(false)} extraClass="wide">
              {t('yes')}
            </Button>
            <p className="body">{t('modals.delete-code.yes.body')}</p>
          </div>

          <div className="modal-button-block-wrapper">
            <Button onClick={handleDeleteCode(true)} extraClass="wide">
              {t('no')}
            </Button>
            <p className="body">{t('modals.delete-code.no.body')}</p>
          </div>
        </div>
      </Modal>

      {/* KNL and Hospital Codes deletion */}
      <Modal
        visible={modal.visible}
        hideModal={hideModal}
        actions={[
          {
            title: t('cancel'),
            onClick: () => hideModal(),
          },
        ]}
      >
        <div className="delete-treatment-code-modal">
          <h1 className="heading">{modal.title}</h1>
          <p className="body">{modal.body}</p>
          <div className="modal-button-block-wrapper">
            <Button onClick={handleOtherCodeDelete} extraClass="wide">
              {t('yes')}
            </Button>
            <p className="body">{modal.bodyYes}</p>
          </div>
          <div className="modal-button-block-wrapper">
            <Button onClick={handleOtherCodeDelete} extraClass="wide">
              {t('no')}
            </Button>
            <p className="body">{modal.bodyNo}</p>
          </div>
        </div>
      </Modal>

      {/* Regional Code deletion */}
      <Modal
        visible={isModalVisible && !isSupportedDrugModule && !!visibleCode}
        hideModal={toggleDeleteModal()}
        actions={[
          {
            title: t('cancel'),
            onClick: toggleDeleteModal(),
          },
        ]}
      >
        <div className="delete-treatment-code-modal">
          <h1 className="heading">{t('delete-code')}</h1>
          <p className="body">{t('delete-code-confirm')}</p>
          <Button onClick={handleDeleteCode(false)} extraClass="wide">
            {t('yes')}
          </Button>
        </div>
      </Modal>

      <InvitationCodeEditModal
        editedCode={editedCode}
        setEditedCode={setEditedCode}
        setEditCodeError={setEditCodeError}
        country={country}
      />

      <Modal
        className="default-modal welcome-modal align-center"
        visible={editCodeError}
        hideModal={() => setEditCodeError(false)}
        actions={[
        ]}
      >
        <p>{t('modals.invalid-code.title')}</p>
        <Button labelText={t('close')} size="medium" onClick={() => setEditCodeError(false)} />
      </Modal>

    </article>
  );
};

export default InvitationCodesEdit;
