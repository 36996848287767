import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { GlossaryState, GlossaryReplaceActionType } from './types';

const replace = (state: GlossaryState, action: GlossaryReplaceActionType) => {
  const { payload } = action;

  if (isEmpty(payload) || isEqual(state, payload)) {
    return state;
  }

  return payload;
};

const clear = () => ({});

export {
  replace,
  clear,
};
