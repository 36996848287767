const prefix = 'HOSPITAL_ACCESS_CODES';

const ADD = `${prefix}/ADD`;
const REMOVE = `${prefix}/REMOVE`;
const UPDATE = `${prefix}/UPDATE`;
const CLEAR = `${prefix}/CLEAR`;

export {
  ADD,
  REMOVE,
  UPDATE,
  CLEAR,
};
