import * as actionTypes from './actionTypes';
import {
  ReportsActionTypes,
  ReportsAddPayload,
  ReportsGeneratePayload,
  ReportsRemovePayload,
  ReportsUpdatePayload,
} from './types';

const add = (payload: ReportsAddPayload): ReportsActionTypes => ({
  type: actionTypes.ADD,
  payload,
});

const generate = (payload: ReportsGeneratePayload): ReportsActionTypes => ({
  type: actionTypes.GENERATE,
  payload,
});

const remove = (payload: ReportsRemovePayload): ReportsActionTypes => ({
  type: actionTypes.REMOVE,
  payload,
});

const update = (payload: ReportsUpdatePayload): ReportsActionTypes => ({
  type: actionTypes.UPDATE,
  payload,
});

const clear = (): ReportsActionTypes => ({
  type: actionTypes.CLEAR,
});

export {
  add,
  generate,
  remove,
  update,
  clear,
};
