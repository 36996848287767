import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MarkdownView from 'react-showdown';
import isEmpty from 'lodash/isEmpty';

import { AppState } from '../state/reducers';

import '../css/TreatmentReport.css';
import { selectors as generatedReportsSelectors } from '../state/generatedReports';
import getLocalizedText from '../utils/getLocalizedText';
import DrawerButtons from '../components/DrawerButtons';

const TreatmentReport = () => {
  const { type } = useParams<any>();

  const { t, i18n } = useTranslation();

  const generatedReports = useSelector(
    (state: AppState) => generatedReportsSelectors.getAll(state),
  );

  const report = getLocalizedText(generatedReports, i18n.language, [`treatment-${type}`]);

  return (
    <section className="content">
      <DrawerButtons
        title={t(`${type}-report`)}
        showRightButton
      />
      <section className="content">
        {
          !isEmpty(report)
            ? <MarkdownView markdown={report} />
            : <div>{t('share.treatment-report.failed-notification')}</div>
        }
      </section>
    </section>
  );
};

export default TreatmentReport;
