import { Reducer } from 'redux';

import { SharedDataState, SharedDataActionTypes } from './types';

const initialState: SharedDataState = {};

const reducer: Reducer<SharedDataState, SharedDataActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    default: return state;
  }
};

export default reducer;
