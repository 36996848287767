import { StyleSheet as RNStyleSheet, Platform as RNPlatform } from 'react-native';

import Colors from '../../theme/Colors';

const styles = (
  offsetLeft: number,
  offsetRight: number,
  offsetColor: string,
) => (
  RNStyleSheet.create({
    container: {
      width: '100%',
      flexDirection: 'row',
    },
    line: {
      borderBottomColor: Colors.lightGray,
      borderBottomWidth: RNStyleSheet.hairlineWidth,
      marginVertical: RNStyleSheet.hairlineWidth,
      flex: 1,
    },
    offsetLeft: {
      width: offsetLeft,
      flexShrink: 0,
      backgroundColor: offsetColor || Colors.white,
    },
    offsetRight: {
      width: offsetRight,
      flexShrink: 0,
      backgroundColor: offsetColor || Colors.white,
    },
    text: {
      width: '100%',
      backgroundColor: Colors.softGray,
      paddingRight: 8,
      paddingBottom: 2,
      paddingLeft: 8,
      height: 24,
      ...RNPlatform.select({
        ios: {
          paddingTop: 2,
        },
        android: {
          paddingTop: 4,
        },
      }),
    },
  })
);

export default styles;
