import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Tooltip from 'react-tooltip';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import toUpper from 'lodash/toUpper';
import keys from 'lodash/keys';
import includes from 'lodash/includes';
import has from 'lodash/has';
import uniq from 'lodash/uniq';
import orderBy from 'lodash/orderBy';

import { SettingsInvitationCodesReturnType, TDrugModule } from 'types';
import { AppState } from '../state/reducers';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRightHeader from '../components/DrawerRightHeader';
import ListItem from '../components/ListItem';
import Modal from '../components/Modal';
import Button from '../components/Button';
import Colors from '../theme/Colors';
import {
  selectors as settingsSelectors,
  actions as settingsActions,
} from '../state/settings';
import { ReactComponent as IconCode } from '../assets/icons/Code.svg';
import { ReactComponent as IconInfo } from '../assets/icons/Info.svg';
import { ReactComponent as IconSettings } from '../assets/icons/Settings.svg';
import { ForwardIconSmall } from '../theme/Icons';
import { actions as apiActions } from '../state/api';
import testInvitationCodeByArray from '../utils/testInvitationCodeByArray';
import azInvitationCodes from '../../data/azInvitationCodes';
import { selectors as tagsSelectors } from '../state/tags';
import { selectors as hospitalsSelectors } from '../state/hospitals';
import { CodeTypes, getCurrentCodeType, getLinkedCodesTypes } from '../utils/inviteCodeHelpers';

import '../css/InvitationCodes.css';

type TFormErrors = {
  newCode?: string;
};

type TRightButtonBlock = { [key: string]: string };

const DrawerRight = ({ isSettings }: {isSettings: number}) => {
  const { t } = useTranslation();

  return (
    <Drawer position="right">
      <section>
        <DrawerRightHeader />
        {isSettings > 0 ? (
          <section className="drawer-item">
            <ListItem
              primaryText={t('codes-edit-title')}
              leftIcon={<IconSettings fill={Colors.primary} className="icon" />}
              divider
              to="/settings/invitation-codes-edit"
            />
          </section>
        ) : null}
      </section>
    </Drawer>
  );
};

const InvitationCodes = () => {
  const history = useHistory();

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<TFormErrors>({});
  const [newCode, setNewCode] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [buttonPressed, setButtonPressed] = useState(false);
  const [showInvalidCodeModal, setShowInvalidCodeModal] = useState(false);

  const invitationCodes = useSelector(
    (state: AppState) => settingsSelectors.getAllInvitationCodes(state),
  );

  const drugContentModule = useSelector(
    (state: AppState) => settingsSelectors.getDrugContentModules(state),
  );

  const memoizedTagsMakeGetValueByKey = useMemo(
    tagsSelectors.makeGetValueByKey,
    [],
  );

  const country = useSelector(
    (state: AppState) => memoizedTagsMakeGetValueByKey(state, 'country'),
  ) as string;

  const hospitalCodes = (
    useSelector((state: AppState) => settingsSelectors.getAllHospitalGroupAccessCodes(state))
  );

  const codeTypes = getLinkedCodesTypes(invitationCodes, country);
  const [allCodes, setAllCodes] = useState([...invitationCodes, ...hospitalCodes]);

  useEffect(() => {
    setAllCodes([...invitationCodes, ...hospitalCodes]);
  }, [hospitalCodes, invitationCodes]);

  const sortedAllCodes: string[] = orderBy(
    allCodes,
    [(value: SettingsInvitationCodesReturnType) => (value === codeTypes.regional ? 0 : 1)],
  );

  const hospitals = useSelector((state: AppState) => hospitalsSelectors.getAll(state));

  const validHospitalCodes = uniq(map(hospitals, 'hospitalGroup'));

  const showErrorCodeModal = () => {
    setShowInvalidCodeModal(true);
  };

  const hideErrorCodeModal = () => {
    setNewCode('');
    setShowInvalidCodeModal(false);
  };

  const handleHospitalCodeAdd = () => {
    if (!includes(validHospitalCodes, newCode)) {
      showErrorCodeModal();
      return;
    }

    dispatch(settingsActions.hospitalGroupAccessCodesAdd([newCode]));
    setLoading(false);
    setNewCode('');
  };

  const handleCodeAdd = () => {
    if (!isEmpty(errors)) {
      setErrors({});
      return false;
    }

    if (!newCode) {
      return false;
    }

    if (includes(invitationCodes, newCode.toUpperCase())
    || has(codeTypes, getCurrentCodeType(newCode, country))) {
      showErrorCodeModal();
      return false;
    }

    if (includes(hospitalCodes, newCode.toUpperCase())) {
      showErrorCodeModal();
      return false;
    }

    setLoading(true);

    if (getCurrentCodeType(newCode, country, validHospitalCodes) === CodeTypes.hospital) {
      handleHospitalCodeAdd();
    } else {
      dispatch(
        apiActions.validateInvite({
          code: newCode,
          country,
          onSuccess: () => {
            dispatch(settingsActions.invitationCodesAdd([newCode.toUpperCase()]));
            setLoading(false);
            setNewCode('');

            if (testInvitationCodeByArray(newCode, azInvitationCodes[country])) {
            // to stop tracking user data call
              window.Countly.q.push(['opt_out']);
              history.push({
                pathname: '/settings/invitation-codes/treatment-selection',
                state: {
                  invitationCode: newCode,
                  isFromAddCodeAndSupport: true,
                },
              });
            }
            return true;
          },
          onError: () => {
            if (testInvitationCodeByArray(newCode, azInvitationCodes[country])) {
              window.Countly.opt_in();
            }
            setLoading(false);
            showErrorCodeModal();
            return false;
          },
        }),
      );
    }
    return true;
  };

  const navigateToTreatmentSelectionScreen = () => {
    history.push({
      pathname: '/settings/invitation-codes/treatment-selection',
      state: {
        invitationCode: codeTypes.regional as string | undefined,
        isFromAddCodeAndSupport: true,
      },
    });
  };

  const drugs = keys(drugContentModule) as TDrugModule[];

  useEffect(() => {
    if (buttonPressed && !isModalVisible) {
      setIsModalVisible(true);
      setButtonPressed(false);
    }
  }, [isModalVisible, buttonPressed]);

  const getInfoTooltip = (code: string) => {
    switch (getCurrentCodeType(code, country, validHospitalCodes)) {
      case CodeTypes.regional:
        return `${t('modals.about-codes.regional.description')}`;
      case CodeTypes.knl:
      case CodeTypes.other:
        return `${t('modals.about-codes.invitation.description')}`;
      case CodeTypes.hospital:
        return `${t('modals.about-codes.hospital.description')}`;
      default:
        return `${t('modals.about-codes.other.description')}`;
    }
  };
  const description = t('modals.support-tool-info.body');

  const RightButtonBlock = (params: TRightButtonBlock) => {
    const { code } = params;
    return (
      <section style={{}}>
        <div data-tip={getInfoTooltip(code)}>
          <IconInfo fill={Colors.primary} className="icon" />
        </div>
        <Tooltip
          className="tooltip body contrast"
          backgroundColor={Colors.black}
        />
      </section>
    );
  };

  return (
    <article className="page diary row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('add-invitation-code-and-support')} showRightButton />
          <section className="diary-item">
            <div className={errors.newCode ? 'add-code mb5' : 'add-code'}>
              <label htmlFor="new-code">
                <span className="body light">{isEmpty(invitationCodes) ? t('add-code') : t('add-another-code')}</span>
                <input
                  value={newCode}
                  onChange={(event) => setNewCode(toUpper(event.currentTarget.value))}
                  id="new-code"
                  name="new-code"
                />
              </label>
              {!loading ? (
                <Button onClick={handleCodeAdd} labelText={t('add')} />
              ) : (
                <Loader
                  type="TailSpin"
                  color={Colors.primary}
                  height={24}
                  width={24}
                />
              )}
            </div>
            {errors.newCode && (<p className="body error mt0">{t(errors.newCode)}</p>)}

            <h2 className="heading-secondary">{t('my-codes')}</h2>

            {!isEmpty(sortedAllCodes) ? (
              map(sortedAllCodes, (inviteCode) => (
                <ListItem
                  key={inviteCode}
                  primaryText={inviteCode}
                  leftIcon={<IconCode fill={Colors.primary} className="icon" />}
                  rightIcon={<RightButtonBlock code={inviteCode} />}
                  divider
                />
              ))
            ) : (
              <p className="body light">{t('empty-state-my-codes')}</p>
            )}

          </section>

          {has(codeTypes, 'regional') ? (
            <>
              {isEmpty(sortedAllCodes) ? null : (
                <div className="support-tools-header-block mt40">
                  <h1 className="heading-large accent mb16">{t('my-support-tools')}</h1>
                  <div className="mr21" data-tip={description}>
                    <IconInfo fill={Colors.primary} className="icon" />
                  </div>
                  <Tooltip
                    className="tooltip body contrast"
                    backgroundColor={Colors.black}
                  />
                </div>
              )}

              <section className={isEmpty(drugs) ? '' : 'diary-item'}>

                <div className="add-code align-right mt10">
                  <Button onClick={navigateToTreatmentSelectionScreen} labelText={t('add-support-tools')} disabled={isEmpty(sortedAllCodes)} />
                </div>

                {!isEmpty(drugs) ? (
                  map(drugs, (drug) => (
                    <ListItem
                      key={drug}
                      onClick={() => history.push({ pathname: `/support-menu/${drug}`, state: { isFromDrugWelcomeScreen: false } })}
                      primaryText={t(`contentModules:drug:${drug}:my-research-navigation-title`)}
                      secondaryText={t(`contentModules:drug:${drug}:my-research-subtitle`)}
                      leftIcon={(
                        <div
                          className="drug-left-icon"
                          style={
                            {
                              height: 50,
                              width: 15,
                              backgroundColor: Colors.drugModuleColors.secondary[drug],
                            }
                          }
                        />
                      )}
                      rightIcon={ForwardIconSmall}
                      divider
                    />
                  ))
                ) : null}
              </section>
            </>
          ) : null}
        </section>
        <Footer />
      </section>
      <DrawerRight isSettings={sortedAllCodes.length} />

      <Modal
        className="default-modal welcome-modal align-center"
        visible={showInvalidCodeModal}
        hideModal={hideErrorCodeModal}
        actions={[
        ]}
      >
        <p>{ getCurrentCodeType(newCode, country) === CodeTypes.regional
          ? t('modals.invalid-region-code.title')
          : t('modals.invalid-code.title')}
        </p>
        <Button labelText={t('close')} size="medium" onClick={hideErrorCodeModal} />
      </Modal>

    </article>
  );
};

export default InvitationCodes;
