import { createSelector } from 'reselect';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import toUpper from 'lodash/toUpper';

import { AppState } from '../reducers';
import { UsefulLinksState } from './types';
import { UsefulLinksReturnType } from '../../../types';

import EMPTY_ARRAY from '../../utils/empty-array';

const getByLocaleSelector = (state: UsefulLinksState, locale: string): UsefulLinksReturnType => {
  if (!locale) {
    return EMPTY_ARRAY;
  }

  const usefulLinks = get(state, [locale]);

  if (isEmpty(usefulLinks)) {
    return EMPTY_ARRAY;
  }

  return orderBy(usefulLinks, (item) => toUpper(item.title), ['asc']);
};

const getByLocale = createSelector(
  (state: AppState) => state.usefulLinks,
  (state: any, locale: string) => locale,
  getByLocaleSelector,
);

export {
  getByLocale, // eslint-disable-line import/prefer-default-export
};
