import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { TQuestionnaireQuestion } from '../../types';

import Modal from './Modal';
import { actions as questionnairesActions } from '../state/questionnaires';
import defaultGet from '../utils/defaultGet';

type Props = {
  questionnaireId: string;
  question: TQuestionnaireQuestion;
  visible: boolean;
  hideModal: () => void;
};

const QuestionEditModal = (props: Props) => {
  const {
    questionnaireId,
    question,
    hideModal,
    visible,
  } = props;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [title, setTitle] = useState(defaultGet(question, 'question', ''));
  const [answer, setAnswer] = useState(defaultGet(question, 'answer', ''));

  useEffect(() => {
    if (question && question.question) {
      setTitle(question.question);
    }

    if (question && question.answer) {
      setAnswer(question.answer);
    }
  }, [question]);

  const handleClose = () => {
    setTitle('');
    setAnswer('');

    hideModal();
  };

  const handleSave = () => {
    if (!title.trim()) {
      return false;
    }

    const updatedQuestion = {
      ...question,
      question: title,
      answer,
    };

    dispatch(questionnairesActions.updateQuestions({
      questionnaireId,
      questions: [updatedQuestion],
    }));

    return handleClose();
  };

  return (
    <Modal
      visible={visible}
      hideModal={handleClose}
      actions={[
        {
          title: t('cancel'),
          onClick: handleClose,
        },
        {
          title: t('save'),
          onClick: handleSave,
          disabled: !title.trim(),
          primary: true,
        },
      ]}
    >
      <h1 className="heading">{t('edit-question')}</h1>
      <label htmlFor="question">
        <span className="body light">{t('question')}</span>
        <input
          value={title}
          onChange={(event) => setTitle(event.currentTarget.value)}
          name="question"
          id="question"
        />
      </label>
      <label htmlFor="answer">
        <span className="body light">{t('answer')}</span>
        <input
          value={answer}
          onChange={(event) => setAnswer(event.currentTarget.value)}
          name="answer"
          id="answer"
        />
      </label>
    </Modal>
  );
};

export default QuestionEditModal;
