import { Reducer } from 'redux';

import * as utils from './utils';
import * as actionTypes from './actionTypes';
import { DecisionAidQuestionsState, DecisionAidQuestionsActionTypes } from './types';

import { decisionAidQuestions } from '../../../data/system-document.json';

const initialState = decisionAidQuestions as DecisionAidQuestionsState;

const reducer: Reducer<
DecisionAidQuestionsState,
DecisionAidQuestionsActionTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLEAR: return utils.clear();
    default: return state;
  }
};

export default reducer;
