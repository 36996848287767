import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import includes from 'lodash/includes';
import Icon from 'react-native-vector-icons/Ionicons';
import Tooltip from 'react-tooltip';

import ListItem from './ListItem';
import Colors from '../theme/Colors';
import { InfoIcon } from '../theme/Icons';

type Props = {
  values: string[];
  options: {
    value: string,
    labelText: string;
    description?: string;
  }[];
  onChange: (value: string, checked: boolean) => void;
};

const propTypes = {
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    labelText: PropTypes.node.isRequired,
    description: PropTypes.string,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
};

const CheckboxGroup = (props: Props) => {
  const { options, values, onChange } = props;

  return (
    <section className="checkbox-group">
      {map(options, (option) => {
        const checked = includes(values, option.value);

        return (
          <ListItem
            key={option.value}
            primaryText={option.labelText}
            onClick={() => onChange(option.value, checked)}
            leftIcon={(
              <Icon
                name={checked ? 'checkbox' : 'square-outline'}
                color={Colors.secondary}
                size={28}
              />
            )}
            divider
            active={checked}
            rightIcon={
              option.description
                ? (
                  <>
                    <div data-tip={option.description}>
                      {InfoIcon}
                    </div>
                    <Tooltip
                      className="tooltip body contrast"
                      backgroundColor={Colors.black}
                    />
                  </>
                )
                : null
            }
          />
        );
      })}
    </section>
  );
};

CheckboxGroup.propTypes = propTypes;

export default CheckboxGroup;
