import React, { useEffect, useMemo } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import QuestionAddModal from 'src/components/QuestionAddModal';
import InvitationCodeEditModal from 'src/components/InvitationCodeEditModal';
import {
  getLocale as i18nGetLocale,
  changeLanguageAsync as i18nChangeLanguageAsync,
} from '../services/i18n';
import Home from '../screens/Home';
import Login from '../screens/Login';
import Signup from '../screens/Signup';
import Diary from '../screens/Diary';
import AppointmentAdd from '../screens/AppointmentAdd';
import AudioRecordingEdit from '../screens/AudioRecordingEdit';
import Photos from '../screens/Photos';
import NoteAdd from '../screens/NoteAdd';
import NoteEdit from '../screens/NoteEdit';
import Trends from '../screens/Trends';
import TrendsAdd from '../screens/TrendsAdd';
import TrendsSettings from '../screens/TrendsSettings';
import Questionnaires from '../screens/Questionnaires';
import QuestionnaireAdd from '../screens/QuestionnaireAdd';
import Questionnaire from '../screens/Questionnaire';
import Glossary from '../screens/Glossary';
import UsefulLinks from '../screens/UsefulLinks';
import Messages from '../screens/Messages';
import InvitationCodes from '../screens/InvitationCodes';
import InvitationCodeTreatmentSelection from '../screens/InvitationCodeTreatmentSelection';
import Treatments from '../screens/Treatments';
import ShareMyData from '../screens/ShareMyData';
import HospitalAccessCodeAdd from '../screens/HospitalAccessCodeAdd';
import HospitalAccessCode from '../screens/HospitalAccessCode';
import Profile from '../screens/Profile';
import ProfileComplete from '../screens/ProfileComplete';
import ProfileQuestion from '../screens/ProfileQuestion';
import ProfileChange from '../screens/ProfileChange';
import ForgotPassword from '../screens/ForgotPassword';
import HospitalGroupAccessCodes from '../screens/HospitalGroupAccessCodes';
import TreatmentAdd from '../screens/TreatmentAdd';
import TreatmentEdit from '../screens/TreatmentEdit';
import ChangePassword from '../screens/ChangePassword';
import ChangeAccountSettings from '../screens/ChangeAccountSettings';
import BlockedAccount from '../screens/BlockedAccount';
import Message from '../screens/Message';
import TreatmentReportMarkdown from '../screens/TreatmentReportMarkdown';
import ProfileStart from '../screens/ProfileStart';
import RightToBeForgotten from '../screens/RightToBeForgotten';
import Language from '../screens/Language';
import Library from '../screens/Library';
import Notification from '../components/Notification';
import Countly from '../components/Countly';
import { AppState } from '../state/reducers';
import { actions as authActions, selectors as authSelectors } from '../state/auth';
import { selectors as tagsSelectors } from '../state/tags';
import getApiTokenAsync from '../utils/getApiTokenAsync';
import Report from '../screens/Report';
import Contacts from '../screens/Contacts';
import Contact from '../screens/Contact';
import ContactAdd from '../screens/ContactAdd';
import Settings from '../screens/Settings';
import Appointment from '../screens/Appointment';
import Appointments from '../screens/Appointments';
import Notes from '../screens/Notes';
import AudioRecording from '../screens/AudioRecording';
import AudioRecordings from '../screens/AudioRecordings';
import Photo from '../screens/Photo';
import PhotoAdd from '../screens/PhotoAdd';
import ProfileCompletedModal from '../components/ProfileCompletedModal';
import ProfileIncompleteModal from '../components/ProfileIncompleteModal';
import TreatmentGuide from '../screens/TreatmentGuide';
import SideEffects from '../screens/SideEffects';
import FillInSideEffects from '../screens/FillInSideEffects';
import WhenSeekHelp from '../screens/WhenSeekHelp';
import MyResearch from '../screens/MyResearch';
import TestFitness from '../screens/TestFitness';
import TestFitnessResult from '../screens/TestFitnessResult';
import DrugModule from '../screens/DrugModule';
import getEmailAsync from '../utils/getEmailAsync';
import DrugWelcomeScreen from '../screens/DrugWelcomeScreen';
import checkIsTestUser from '../utils/checkIsTestUser';
import InvitationCodesEdit from '../screens/InvitationCodesEdit';
import InvitationCodeAdd from '../screens/InvitationCodeAdd';
import ValidateNavigationModal from '../components/ValidateNavigationModal';
import { selectors as settingsSelectors, actions as settingsActions } from '../state/settings';
import FAQ from '../screens/FAQ';

const Router = () => {
  const auth = useSelector((state: AppState) => authSelectors.get(state));
  const status = useSelector((state:AppState) => state.settings.accountStatus.status);
  const invitationCodes = useSelector(
    (state: AppState) => settingsSelectors.getAllInvitationCodes(state),
  );

  const appLanguage = useSelector(
    (state: AppState) => settingsSelectors.getGeneralAppLanguage(state),
  );

  const memoizedTagsMakeGetValueByKey = useMemo(
    tagsSelectors.makeGetValueByKey,
    [],
  );

  let country = useSelector(
    (state: AppState) => memoizedTagsMakeGetValueByKey(state, 'country'),
  ) as string;

  // Default to UK if there is no country tag
  if (!country) {
    country = 'uk';
  }

  const dispatch = useDispatch();

  useEffect(() => {
    const apiTokenAsync = async () => {
      try {
        await getApiTokenAsync();

        await getEmailAsync();

        // Update language according to the available languages and country of signup
        await i18nChangeLanguageAsync(i18nGetLocale(), country);

        dispatch(authActions.set(true));
      } catch (error) {
        dispatch(authActions.set(false));
      }
    };

    if (!status || status === 'active') {
      apiTokenAsync();
    }
  });

  const UnauthSwitch = (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/signup" component={Signup} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/" component={Login} />
    </Switch>
  );

  const BlockedSwitch = (
    <Switch>
      <Route path="/" component={BlockedAccount} />
    </Switch>
  );

  const AuthSwitch = (
    <>
      <Switch>
        <Route path="/questionnaires/add" component={QuestionnaireAdd} />
        <Route path="/questionnaires/:questionnaireId" component={Questionnaire} />
        <Route path="/questionnaires" component={Questionnaires} />
        <Route path="/audio-recordings/:audioRecordingId" component={AudioRecordingEdit} />
        <Route path="/notes/add" component={NoteAdd} />
        <Route path="/note/:noteId" component={NoteEdit} />
        <Route path="/notes/:view/:date?" component={Notes} />
        <Route path="/photo/:photoId" component={Photo} />
        <Route path="/photos/add" component={PhotoAdd} />
        <Route path="/photos/:view/:date?" component={Photos} />
        <Route path="/recording/:audioRecordingId" component={AudioRecording} />
        <Route path="/recordings/:view/:date?" component={AudioRecordings} />
        <Route path="/message/:messageId/:isPushNotification?" component={Message} />
        <Route path="/messages" component={Messages} />
        <Route path="/appointments/add" component={AppointmentAdd} />
        <Route path="/appointment/:appointmentId" component={Appointment} />
        <Route path="/appointments/:view/:date?" component={Appointments} />
        <Route path="/diary" component={Diary} />
        <Route path="/trends/settings" component={TrendsSettings} />
        <Route path="/trends/add" component={TrendsAdd} />
        <Route path="/trends" component={Trends} />
        <Route path="/glossary" component={Glossary} />
        <Route path="/useful-links" component={UsefulLinks} />
        <Route path="/settings/invitation-codes-edit" component={InvitationCodesEdit} />
        <Route path="/settings/invitation-codes/treatment-selection" component={InvitationCodeTreatmentSelection} />
        <Route path="/settings/invitation-codes" component={InvitationCodes} />
        <Route path="/settings/invitation-code-add" component={InvitationCodeAdd} />
        <Route path="/treatments/report/:type" component={TreatmentReportMarkdown} />
        <Route path="/treatments/add" component={TreatmentAdd} />
        <Route path="/treatments/:treatmentId" component={TreatmentEdit} />
        <Route path="/treatments" component={Treatments} />
        <Route path="/share-my-data/add" component={HospitalAccessCodeAdd} />
        <Route path="/share-my-data/:hospitalAccessCodeId" component={HospitalAccessCode} />
        <Route path="/share-my-data" component={ShareMyData} />
        <Route path="/settings/hospital-group-access-codes" component={HospitalGroupAccessCodes} />
        <Route path="/profile/complete/:questionId?" component={ProfileComplete} />
        <Route path="/profile/:questionId" component={ProfileChange} />
        <Route path="/profile" component={Profile} />
        <Route path="/settings/profile/start" component={ProfileStart} />
        <Route path="/settings/profile/:questionId" component={ProfileQuestion} />
        <Route path="/settings/change-password" component={ChangePassword} />
        <Route path="/settings/change-account-settings" component={ChangeAccountSettings} />
        <Route path="/settings/right-to-be-forgotten" component={RightToBeForgotten} />
        <Route path="/settings/language" component={Language} />
        <Route path="/settings" component={Settings} />
        <Route path="/reports/:reportId" component={Report} />
        <Route path="/library" component={Library} />
        <Route path="/contacts/add" component={ContactAdd} />
        <Route path="/contacts/:contactId" component={Contact} />
        <Route path="/contacts" component={Contacts} />
        <Route path="/support-menu/:drugModule/fill-in-side-effects/settings" component={TrendsSettings} />
        <Route path="/support-menu/:drugModule/treatment-guide/:navigateFrom?" component={TreatmentGuide} />
        <Route path="/support-menu/:drugModule/side-effects/" component={SideEffects} />
        <Route path="/support-menu/:drugModule/fill-in-side-effects/" component={FillInSideEffects} />
        <Route path="/support-menu/:drugModule/faq/" component={FAQ} />
        <Route path="/support-menu/:drugModule/when-seek-help/" component={WhenSeekHelp} />
        <Route path="/support-menu/:drugModule/my-research/" component={MyResearch} />
        <Route path="/support-menu/:drugModule/test-fitness/" component={TestFitness} />
        <Route path="/support-menu/:drugModule/test-fitness-result/:lastTestResult" component={TestFitnessResult} />
        <Route path="/support-menu/:drugModule/test-fitness-result/" component={TestFitnessResult} />
        <Route path="/support-menu/:drugModule/welcome/" component={DrugWelcomeScreen} />
        <Route path="/support-menu/:drugModule/welcome-sign-in/" component={DrugWelcomeScreen} />
        <Route path="/support-menu/:drugModule" component={DrugModule} />
        <Route path="/" component={Home} />
      </Switch>
      <InvitationCodeEditModal editedCode="" setEditedCode={(): void => {}} setEditCodeError={(): void => {}} country="" />
      <QuestionAddModal />
      <ProfileCompletedModal />
      <ProfileIncompleteModal />
      <ValidateNavigationModal />
    </>
  );

  let routes = auth ? AuthSwitch : UnauthSwitch;

  if (status && status !== 'active') {
    routes = BlockedSwitch;
  }

  // Add appLanguage to existing logged in users
  if (auth && !appLanguage) {
    dispatch(settingsActions.generalUpdate({ appLanguage: i18nGetLocale() }));
  }

  const isTestUser = checkIsTestUser(invitationCodes);

  return (
    <BrowserRouter>
      <Countly isTestUser={isTestUser}>
        {routes}
      </Countly>
      <Notification />
    </BrowserRouter>
  );
};

export default Router;
