import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import classNames from 'classnames';

import '../css/Tabs.css';

export type TabType = {
  name: string;
  labelText: ReactNode;
};

type Props = {
  tabs: TabType[];
  activeTab?: string;
  onTabClick: (tab: TabType) => void;
};

const propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    labelText: PropTypes.node,
  })).isRequired,
  activeTab: PropTypes.string,
  onTabClick: PropTypes.func.isRequired,
};

const Tabs = (props: Props) => {
  const { tabs, onTabClick, activeTab = '' } = props;

  return (
    <section className="tabs">
      {map(tabs, (tab) => {
        const active = activeTab === tab.name;

        return (
          <button key={tab.name} type="button" className={classNames('tab', { active })} onClick={() => onTabClick(tab)}>
            <p className={classNames('button', { contrast: active })}>{tab.labelText}</p>
          </button>
        );
      })}
    </section>
  );
};

Tabs.propTypes = propTypes;

export default Tabs;
