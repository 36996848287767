import * as actionTypes from './actionTypes';
import { TDrugModule, TrendType } from '../../../types';
import { TrendsActionTypes } from './types';

const add = (payload: TrendType[]): TrendsActionTypes => ({
  type: actionTypes.ADD,
  payload,
});

const clear = (): TrendsActionTypes => ({
  type: actionTypes.CLEAR,
});

const clearDrugModuleTrends = (payload: TDrugModule): TrendsActionTypes => ({
  type: actionTypes.CLEAR_DRUG_MODULE,
  payload,
});

export {
  add,
  clear,
  clearDrugModuleTrends,
};
