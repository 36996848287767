import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import omitBy from 'lodash/omitBy';

import {
  DailySuggestionsState,
  DailySuggestionsAddActionType,
  DailySuggestionsUpdateActionType,
  DailySuggestionsClearDrugModuleActionType,
} from './types';

const add = (state: DailySuggestionsState, action: DailySuggestionsAddActionType) => {
  const { payload } = action;

  if (isEmpty(payload)) {
    return state;
  }

  const dailySuggestions = keyBy(payload, 'id');

  return {
    ...state,
    ...dailySuggestions,
  };
};

const update = (state: DailySuggestionsState, action: DailySuggestionsUpdateActionType) => {
  const { payload } = action;

  if (isEmpty(payload)) {
    return state;
  }

  const dailySuggestions = keyBy(payload, 'id');

  return {
    ...state,
    ...dailySuggestions,
  };
};

const clear = () => ({});

const clearDrugModuleSuggestions = (
  state: DailySuggestionsState,
  action: DailySuggestionsClearDrugModuleActionType,
):DailySuggestionsState => {
  const { payload } = action;

  if (isEmpty(payload)) {
    return state;
  }

  return omitBy(state, ({ params }) => params?.drugModule === payload);
};

export {
  add,
  update,
  clear,
  clearDrugModuleSuggestions,
};
