/* eslint react/require-default-props: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Path } from 'react-native-svg';
import ResizeableSVG from '../ResizeableSVG';

import Colors from '../../theme/Colors';
import Constants from '../../theme/Constants';

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

type Props = {
  color?: string;
  size?: number;
};

const Cross = (props: Props) => {
  const {
    color = Colors.primary,
    size = Constants.defaultIconSize,
  } = props;

  return (
    <ResizeableSVG size={size}>
      <Path d="m17.7090112 19.1926156-3.7090112-3.7090111-3.7090111 3.7090111c-.4102167.4102167-1.0741297.4094749-1.48360454 0-.40947483-.4094748-.41021663-1.0733878 0-1.4836044l3.70901124-3.7090112-3.70901124-3.7090111c-.40947483-.4094749-.41021663-1.0733879 0-1.48360454.41021664-.41021663 1.07412964-.40947483 1.48360454 0l3.7090111 3.70901124 3.7090112-3.70901124c.4102166-.41021663 1.0741296-.40947483 1.4836044 0 .4094749.40947484.4102167 1.07338784 0 1.48360454l-3.7090111 3.7090111 3.7090111 3.7090112c.4094749.4094748.4102167 1.0733879 0 1.4836044-.4102165.4102167-1.0741296.4094749-1.4836044 0z" fill={color} fillRule="evenodd" />
    </ResizeableSVG>
  );
};

Cross.propTypes = propTypes;

export default Cross;
