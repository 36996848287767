import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import nl2br from 'react-nl2br';

import PageHeader from '../components/PageHeader';
import '../css/ProfileStart.css';
import DrawerButtons from '../components/DrawerButtons';

const ProfileStart = () => {
  const { t } = useTranslation();

  return (
    <section className="content">
      <DrawerButtons title={t('profile')} showRightButton />
      <PageHeader
        title={t('pageHeaders.profileStart.title')}
        subtitle={nl2br(t('pageHeaders.profileStart.subTitle')) as unknown as string}
      >
        <section className="row between">
          <Link to="/" className="button contrast">{t('create-profile-later')}</Link>
          <Link to="/settings/profile" className="button contrast">{t('complete-profile')} &gt;</Link>
        </section>
      </PageHeader>
      <section className="text-block">
        <section>
          <h1 className="heading">{t('screens.profileStart.why.title')}</h1>
          <p className="body">{nl2br(t('screens.profileStart.why.description'))}</p>
        </section>
        <section className="row">
          <section>
            <h1 className="heading">{t('screens.profileStart.treatmentPlan.title')}</h1>
            <p className="body">{nl2br(t('screens.profileStart.treatmentPlan.description'))}</p>
          </section>
          <section>
            <h1 className="heading">{t('screens.profileStart.treatmentReport.title')}</h1>
            <p className="body">{nl2br(t('screens.profileStart.treatmentReport.description'))}</p>
          </section>
        </section>
      </section>
    </section>
  );
};

export default ProfileStart;
