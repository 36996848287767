import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Loader from 'react-loader-spinner';

import Modal from './Modal';
import Button from './Button';
import { actions as apiActions, ApiShareCustomTrendsByIdPayload, ApiShareCustomTrendsPayload } from '../state/api';
import Colors from '../theme/Colors';

type Props = {
  visible: boolean;
  hideModal: () => void;
  data?: ApiShareCustomTrendsPayload | ApiShareCustomTrendsByIdPayload,
  type: 'default' | 'custom' | 'id'
};

const TrendsShareModal = (props: Props) => {
  const {
    visible, hideModal, data, type,
  } = props;

  const dispatch = useDispatch();

  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [link, setLink] = useState('');

  const { t } = useTranslation();

  const handleHide = () => {
    setError(false);
    setIsLoading(false);
    setLink('');

    hideModal();
  };

  const getShareTrendsLink = () => {
    const defaultShareObject = {
      onLoadLinkStart: () => {
        setIsLoading(true);
        setError(false);
      },
      onLoadLinkError: () => {
        setIsLoading(false);
        setError(true);
      },
      onLoadLinkSuccess: (l: string) => {
        setIsLoading(false);
        setLink(l);
      },
    };

    if (type === 'default') {
      dispatch(apiActions.shareTrends(defaultShareObject));
    }

    if (type === 'id') {
      dispatch(apiActions.shareCustomTrendsById({
        ...defaultShareObject,
        ...data as ApiShareCustomTrendsByIdPayload,
      }));
    }

    if (type === 'custom') {
      dispatch(apiActions.shareCustomTrends({
        ...defaultShareObject,
        ...data as ApiShareCustomTrendsPayload,
      }));
    }
  };

  useEffect(() => {
    if (visible) {
      getShareTrendsLink();
    }
  }, [visible]);

  const subject = t('share.trends.title');
  const body = `${t('share.trends.message')}%0d%0a%0d%0a${link}`;

  const handleShare = () => {
    // @ts-ignore
    window.location = `mailto:?subject=${subject}&body=${body}`;

    handleHide();
  };

  let content = (
    <>
      <p className="body">{t('share.trends.loading-notification')}</p>
      <Loader type="TailSpin" color={Colors.secondary} height={28} width={28} />
    </>
  );

  if (error) {
    content = (
      <>
        <p className="body error">{t('share.trends.failed-notification')}</p>
        <Button labelText={t('retry')} onClick={getShareTrendsLink} disabled={isLoading} />
      </>
    );
  }

  if (link) {
    content = (
      <>
        <p className="body">
          <Trans i18nKey="modals.trends-share-web.body" values={{ link, newline: '<br /><br />' }}>
            <a href={link} target="_blank" rel="noreferrer" className="navigation accent trends-share">{link}</a>
          </Trans>
        </p>
      </>
    );
  }

  return (
    <Modal
      visible={visible}
      hideModal={handleHide}
      actions={[
        {
          title: t('cancel'),
          onClick: handleHide,
        },
        {
          title: t('share-trends'),
          onClick: handleShare,
          primary: true,
          disabled: isLoading || error,
        },
      ]}
      className="trends-share-modal"
    >
      <h1 className="heading">{t('modals.trends-share-web.title')}</h1>
      {content}
    </Modal>
  );
};

export default TrendsShareModal;
