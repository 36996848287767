import filter from 'lodash/filter';
import isWithinInterval from 'date-fns/isWithinInterval';
import map from 'lodash/map';
import keys from 'lodash/keys';
import reduce from 'lodash/reduce';
import eachMinuteOfInterval from 'date-fns/eachMinuteOfInterval';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import assignIn from 'lodash/assignIn';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import startOfHour from 'date-fns/startOfHour';
import endOfHour from 'date-fns/endOfHour';
import eachHourOfInterval from 'date-fns/eachHourOfInterval';
import concat from 'lodash/concat';
import addMinutes from 'date-fns/addMinutes';
import uniq from 'lodash/uniq';
import forEach from 'lodash/forEach';
import toArray from 'lodash/toArray';
import {
  TDrugModule, TDate, TrendIndicatorType, TrendSeries,
} from '../../../types';
import createDeepEqualSelector from '../../selectors/createDeepEqualSelector';
import { TestFitnessDrugData, TestFitnessState, TestFitnessTrend } from './types';
import { AppState } from '../reducers';
import { TrendIndicatorsState } from '../trendIndicators';
import toDate from '../../utils/toDate';
import getUnixTime from '../../utils/getUnixTime';
import defaultGet from '../../utils/defaultGet';

const getFilteredByDateData = (
  arr: TestFitnessDrugData[],
  startDate: TDate,
  endDate: TDate,
):TestFitnessDrugData[] => filter(
  arr,
  (testFitnessDrugData) => {
    const interval = { start: toDate(startDate), end: toDate(endDate) };

    const { measuredAt: startMeasuredAt } = testFitnessDrugData.start || {};
    const { measuredAt: endMeasuredAt } = testFitnessDrugData.end || {};

    if (!startMeasuredAt || !endMeasuredAt) {
      return false;
    }

    const startInInterval = isWithinInterval(toDate(startMeasuredAt), interval);
    const endInInterval = isWithinInterval(toDate(endMeasuredAt), interval);

    return startInInterval && endInInterval;
  },
);

const getSelectorTrendData = (
  type: 'day' | 'minute' | 'hour',
  startDate: TDate,
  endDate: TDate,
  state: TestFitnessState,
  trendIndicatorId: string,
  drugModule: TDrugModule,
  defaultValue: number,
  maximumValue?: number,
  combined?: boolean,
) => {
  let interval = {
    start: startOfDay(toDate(startDate)),
    end: endOfDay(toDate(endDate)),
  };
  let periodInterval = eachDayOfInterval(interval);

  if (type === 'minute') {
    interval = {
      start: toDate(startDate),
      end: addMinutes(toDate(endDate), 1),
    };
    periodInterval = eachMinuteOfInterval(interval);
  }

  if (type === 'hour') {
    interval = {
      start: startOfHour(toDate(startDate)),
      end: endOfHour(toDate(endDate)),
    };
    periodInterval = eachHourOfInterval(interval);
  }

  const testFitnessData = state[drugModule];

  const orderedData = orderBy(getFilteredByDateData(testFitnessData, interval.start, interval.end), ['measuredAt'], ['asc']);
  const startData = {};
  const endData = {};

  let startValue: number | null = null;

  const startTimestamp = getUnixTime(interval.start);
  const endTimestamp = getUnixTime(interval.end);

  const generateData = (dataObject: {}, trend: TestFitnessTrend) => {
    if (!trend) {
      return;
    }

    const { measuredAt } = trend;

    if (!measuredAt) {
      return;
    }

    // Only select explicit data
    const value = get(trend, ['explicitData', trendIndicatorId]);

    if (value == null) {
      return;
    }

    // Only update startValue once
    if (startValue == null) {
      startValue = value;
    }

    // Get UNIX timestamp
    const date = getUnixTime(startOfDay(toDate(measuredAt)));

    const dateField = type === 'day' ? date : measuredAt;

    // Get values for date
    const values = defaultGet(dataObject, [dateField, 'values'], []);

    // Add current value to values
    const newValues = [...values, value];

    assignIn(
      dataObject,
      {
        [dateField]: {
          date: dateField,
          values: newValues,
          labelValue: value,
          selectable: true,
          originalValue: value,
          value: combined ? (value / (maximumValue || 10)) * 10 : value,
        },
      },
    );

    if (!get(dataObject, [startTimestamp])) {
      assignIn(
        dataObject,
        {
          [startTimestamp]:
            {
              date: startTimestamp,
              labelValue: defaultValue,
              selectable: false,
              originalValue: defaultValue,
              value: combined ? (defaultValue / (maximumValue || 10)) * 10 : defaultValue,
            },
        },
      );
    }

    // Make sure we have a value for endTimestamp
    if (!get(dataObject, [endTimestamp])) {
      assignIn(
        dataObject,
        {
          [endTimestamp]:
            {
              date: endTimestamp * 10,
              labelValue: value,
              selectable: false,
              originalValue: value,
              value: combined ? (value / (maximumValue || 10)) * 10 : value,
            },
        },
      );
    }
  };

  forEach(orderedData, (trend) => {
    generateData(startData, trend.start);

    generateData(endData, trend.end);
  });

  return {
    data: { startData, endData },
    periodInterval,
  };
};

const getTestFitnessDataSelector = (state: AppState) => state.customTrends.testFitness;

const getLastTestFitnessDataSelector = (
  state: TestFitnessState,
  drugModule: TDrugModule,
) => state[drugModule][state[drugModule].length - 1];

const getLastTestFitnessData = createDeepEqualSelector(
  getTestFitnessDataSelector,
  (state: AppState, drugModule: TDrugModule) => drugModule,
  getLastTestFitnessDataSelector,
);

const getTestFitnessTrends = (
  state: { trendIndicators: TrendIndicatorsState, testFitness: TestFitnessState },
  startDate: Date,
  endDate: Date,
  drugModule: TDrugModule,
) => {
  const { testFitness, trendIndicators } = state;

  const testFitnessData = testFitness[drugModule];

  const filteredData = getFilteredByDateData(testFitnessData, startDate, endDate);

  const testFitnessTrendIds: string[] = [];

  const testFitnessTrendData = reduce(
    filteredData,
    (acc, curr) => {
      acc.push({ measuredAt: curr.start.measuredAt, explicitData: curr.start.explicitData });
      acc.push({ measuredAt: curr.end.measuredAt, explicitData: curr.end.explicitData });

      const explicitDataKeys = concat(keys(curr.start.explicitData), keys(curr.end.explicitData));

      explicitDataKeys.forEach((key) => {
        if (!testFitnessTrendIds.includes(key)) {
          testFitnessTrendIds.push(key);
        }
      });

      return acc;
    },
    [] as TestFitnessTrend[],
  );

  const testFitnessUniqueTrendIds = uniq(testFitnessTrendIds);

  const testFitnessTrendIndicators = map(
    testFitnessUniqueTrendIds,
    (indicatorId) => trendIndicators[indicatorId],
  );

  return {
    testFitnessTrendIndicators,
    testFitnessTrendData,
  };
};

const makeGetTestFitnessTrends = () => createDeepEqualSelector(
  (state: AppState) => (
    {
      trendIndicators: state.trendIndicators,
      testFitness: state.customTrends.testFitness,
    }),
  (state: any, startDate: Date) => startDate,
  (state: any, startDate: Date, endDate: Date) => endDate,
  (state: any, startDate: Date, endDate: Date, drugModule: TDrugModule) => drugModule,
  getTestFitnessTrends,
);

const getLatestDataPerDayByTrendIndicatorsSelector = (
  state: TestFitnessState,
  drugModule:TDrugModule,
  trendIndicators: TrendIndicatorType[],
  startDate: Date,
  endDate: Date,
  combined: boolean,
) => {
  const trendSeries: TrendSeries[] = [];
  trendIndicators.forEach((trendIndicator) => {
    const {
      defaultValue = 0,
      maximumValue = 10,
      id,
    } = trendIndicator;

    const { data } = getSelectorTrendData(
      'day',
      startDate,
      endDate,
      state,
      id,
      drugModule,
      defaultValue,
      maximumValue,
      combined,
    );

    trendSeries.push({
      ...trendIndicator, data: toArray(data.startData), id: `${trendIndicator.id}-start`, color: `${trendIndicator.color}70`,
    });

    trendSeries.push({ ...trendIndicator, data: toArray(data.endData) });
  });

  return {
    trendSeries,
  };
};

const makeGetLatestDataPerDayByTrendIndicators = () => (
  createDeepEqualSelector(
    (state: AppState) => state.customTrends.testFitness,
    (state: TestFitnessState, drugModule: TDrugModule) => drugModule,
    (
      state: TestFitnessState,
      drugModule: TDrugModule,
      trendIndicators: TrendIndicatorType[],
    ) => trendIndicators,
    (
      state: TestFitnessState,
      drugModule: TDrugModule,
      trendIndicators: TrendIndicatorType[],
      startDate: Date,
    ) => startDate,
    (
      state: TestFitnessState,
      drugModule: TDrugModule,
      trendIndicators: TrendIndicatorType[],
      startDate: Date,
      endDate: Date,
    ) => endDate,
    (
      state: TestFitnessState,
      drugModule: TDrugModule,
      trendIndicators: TrendIndicatorType[],
      startDate: Date,
      endDate: Date,
      combined: boolean,
    ) => combined,
    getLatestDataPerDayByTrendIndicatorsSelector,
  )
);

export {
  // eslint-disable-next-line import/prefer-default-export
  getLastTestFitnessData,
  makeGetTestFitnessTrends,
  makeGetLatestDataPerDayByTrendIndicators,
};
