import React from 'react';
import { useTranslation } from 'react-i18next';
import find from 'lodash/find';
import get from 'lodash/get';

import { TTreatmentType, TTreatment } from '../../types';

import ListItem from './ListItem';
import defaultGet from '../utils/defaultGet';
import dateFormat from '../utils/dateFormat';
import getLocalizedText from '../utils/getLocalizedText';
import { ReactComponent as IconChevronRight } from '../assets/icons/ChevronRight.svg';
import Colors from '../theme/Colors';
import { treatmentTypeIcons } from '../theme/Icons';

type Props = {
  treatment: TTreatment;
  treatmentTypes: TTreatmentType[];
  divider?: boolean;
};

const TreatmentListItem = (props: Props) => {
  const { treatment, treatmentTypes, divider } = props;

  const { i18n } = useTranslation();

  const treatmentType = find(treatmentTypes, { id: treatment.treatmentTypeId });

  const title = defaultGet(treatmentType, 'title', '');
  const color = defaultGet(treatmentType, 'color', Colors.primary);

  let icon = null;

  if (treatmentType && treatmentType.id) {
    icon = get(treatmentTypeIcons, treatmentType.id);
  }

  const leftIcon = (
    <div className="treatment-icon">
      <div className="treatment-bar" style={{ backgroundColor: color }} />
      {icon}
    </div>
  );

  const formattedStartDateTime = dateFormat(i18n.language, treatment.startDateTime, 'dd/LL/yyyy');
  const formattedEndDateTime = dateFormat(i18n.language, treatment.endDateTime, 'dd/LL/yyyy');
  const primaryText = getLocalizedText(title, i18n.language);
  const secondaryText = `${formattedStartDateTime} - ${formattedEndDateTime}`;

  return (
    <ListItem
      primaryText={primaryText}
      secondaryText={secondaryText}
      divider={divider}
      to={`/treatments/${treatment.id}`}
      leftIcon={leftIcon}
      rightIcon={<IconChevronRight fill={Colors.primary} className="icon small" />}
    />
  );
};

export default TreatmentListItem;
