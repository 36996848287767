import {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import reduce from 'lodash/reduce';
import omit from 'lodash/omit';

type TErrors = {
  [index: string]: string | string[] | undefined;
};

type TSetErrors = Dispatch<SetStateAction<TErrors>>;

const formFieldValidation = (
  schema: any,
  name: string,
  value: any,
  setErrors: TSetErrors,
): boolean => {
  const options = {
    abortEarly: false,
    stripUnknown: true,
  };

  try {
    schema.validateSync(value, options);
  } catch (error) {
    setErrors((prevState: TErrors) => ({ ...prevState, [name]: error.message }));

    return false;
  }

  return true;
};

const formValidation = (
  schema: any,
  value: any,
  setErrors: TSetErrors,
): boolean => {
  const options = {
    abortEarly: false,
    stripUnknown: true,
  };

  try {
    schema.validateSync(value, options);
  } catch (error) {
    const validationErrors = reduce(
      error.inner,
      (result, e) => ({ ...result, [e.path]: e.message }),
      {},
    );

    setErrors(validationErrors);

    return false;
  }

  return true;
};

const useFormFieldValidation = (
  schema: any,
  name: string,
  defaultValue: any,
  enabled: boolean,
  errors: TErrors,
  setErrors: TSetErrors,
) => {
  const [value, setValue] = useState<typeof defaultValue>(defaultValue);

  useEffect(() => {
    if (enabled) {
      if (errors[name]) {
        setErrors((prevState: TErrors) => omit(prevState, name));
      }

      formFieldValidation(schema, name, value, setErrors);
    }
  }, [value]);

  return [value, setValue];
};

export {
  formFieldValidation,
  formValidation,
  useFormFieldValidation,
};
