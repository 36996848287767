/* eslint react/require-default-props: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Path } from 'react-native-svg';
import ResizeableSVG from '../ResizeableSVG';

import Colors from '../../theme/Colors';
import Constants from '../../theme/Constants';

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

type Props = {
  color?: string;
  size?: number;
};

const Other = (props: Props) => {
  const {
    color = Colors.primary,
    size = Constants.defaultIconSize,
  } = props;

  return (
    <ResizeableSVG size={size}>
      <Path d="m20.8944831 8c1.2779861 0 2.3165506 1.03910016 2.3165506 2.3165506v8.3668988c0 .6116765-.2362078 1.1885377-.6647027 1.6245313-.438136.4456347-1.0251741.6920193-1.6518479.6920193h-13.57793248c-1.27745046 0-2.31655062-1.0391002-2.31655062-2.3165506v-8.3668988c0-1.27745044 1.03910016-2.3165506 2.31655062-2.3165506zm0 1.15854312h-13.57793248c-.63845742 0-1.1580075.51955008-1.1580075 1.15800748v8.3668988c0 .638993.51955008 1.1585431 1.1580075 1.1585431h13.57793248c.3133369 0 .6068559-.1231923.825924-.3460096.2142474-.2185324.3326191-.5066953.3326191-.8125335v-8.3668988c0-.6384574-.51955-1.15800748-1.1585431-1.15800748zm-2.7016068 4.19775038c.5559721 0 1.006963.4515265 1.006963 1.0074987v.0005356c0 .5559721-.4509909 1.006963-1.006963 1.006963-.5559722 0-1.0069631-.4509909-1.0069631-1.006963v-.0005356c0-.5559722.4509909-1.0074987 1.0069631-1.0074987zm-3.9501875 0c.5559722 0 1.006963.4515265 1.006963 1.0074987v.0005356c0 .5559721-.4509908 1.006963-1.006963 1.006963-.5565078 0-1.0074987-.4509909-1.0074987-1.006963v-.0005356c0-.5559722.4509909-1.0074987 1.0074987-1.0074987zm-3.9501875 0c.5559722 0 1.0069631.4515265 1.0069631 1.0074987v.0005356c0 .5559721-.4509909 1.006963-1.0069631 1.006963-.55704335 0-1.00749862-.4509909-1.00749862-1.006963v-.0005356c0-.5559722.45045527-1.0074987 1.00749862-1.0074987z" fill={color} fillRule="evenodd" />
    </ResizeableSVG>
  );
};

Other.propTypes = propTypes;

export default Other;
