import find from 'lodash/find';

import Colors from '../theme/Colors';
import defaultGet from './defaultGet';
import { TTreatment, TTreatmentType } from '../../types';

const getTreatmentColor = (treatment: TTreatment, treatmentTypes: TTreatmentType[]) => {
  const treatmentType = find(treatmentTypes, { id: treatment.treatmentTypeId });

  return defaultGet(treatmentType, 'color', Colors.primary);
};

export default getTreatmentColor;
