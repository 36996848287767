import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import find from 'lodash/find';
import map from 'lodash/map';
import includes from 'lodash/includes';
import get from 'lodash/get';
import classNames from 'classnames';

import { ProfileQuestionType } from '../../types';

import { ReactComponent as IconNo } from '../assets/icons/No.svg';
import { ReactComponent as IconHeartOutline } from '../assets/icons/HeartOutline.svg';
import { ReactComponent as IconBloodPressure } from '../assets/icons/BloodPressure.svg';
import { ReactComponent as IconStroke } from '../assets/icons/Stroke.svg';
import { ReactComponent as IconLungs } from '../assets/icons/Lungs.svg';
import { ReactComponent as IconJoints } from '../assets/icons/Joints.svg';
import { ReactComponent as IconDiabetes } from '../assets/icons/Diabetes.svg';
import { ReactComponent as IconBrain } from '../assets/icons/Brain.svg';
import { ReactComponent as IconOtherCancer } from '../assets/icons/OtherCancer.svg';
import { ReactComponent as IconOtherCondition } from '../assets/icons/OtherCondition.svg';
import { ReactComponent as IconMultipleOther } from '../assets/icons/MultipleOther.svg';
import { ReactComponent as IconQuestionmark } from '../assets/icons/Questionmark.svg';
import Colors from '../theme/Colors';
import getLocalizedText from '../utils/getLocalizedText';

const icons = {
  '1e2df9c2-03d4-4cf1-b248-e1c91f20e3ba': IconNo,
  '214fd2a9-e67e-400e-b436-0de4defecfa4': IconHeartOutline,
  'fa432802-431b-4e7b-8f78-5930a7792d5e': IconBloodPressure,
  'dff75c8b-56f7-4753-9cd7-3c333ebbe8b6': IconStroke,
  '90c59ceb-8b83-4da0-aa2d-ee02cf003538': IconLungs,
  '31f2b2bc-291e-4bc4-b31b-2ec84c9da4ab': IconJoints,
  '69cf8fbb-9866-4ddb-b4f1-ea8a7f821ddd': IconDiabetes,
  '6121f566-31ca-4f1a-ad77-0fff0c6b7150': IconBrain,
  '8b42fd8c-b54a-4979-a468-48af8a9567a4': IconOtherCancer,
  '710e23eb-e5af-4fb5-9d94-9075950fe51d': IconOtherCondition,
  '3a8d0f01-739c-4baf-b29f-d3777ffe1d64': IconMultipleOther,
  '32f1bfde-ac71-4eae-be85-b8e931a30d5f': IconQuestionmark,
};

type Props = {
  question: ProfileQuestionType,
  values: string[];
  onChangeValue: (option: string, checked: boolean) => void;
};

const ProfileQuestionMedicalConditionInput = (props: Props) => {
  const {
    question,
    values,
    onChangeValue,
  } = props;

  const { i18n } = useTranslation();

  const [selectedValue, setSelectedValue] = useState('');

  const localizedOptions = map(question.answers, (answer) => ({
    value: answer.id,
    labelText: getLocalizedText(answer, i18n.language, 'title'),
    description: getLocalizedText(answer, i18n.language, 'description'),
  }));

  const selectedAnswer = find(localizedOptions, { value: selectedValue });

  const handleSelect = (value: string) => {
    if (selectedValue === value) {
      return setSelectedValue('');
    }

    return setSelectedValue(value);
  };

  return (
    <section className="medical-condition-input">
      <section className="buttons">
        {map(localizedOptions, (option) => {
          const checked = includes(values, option.value);

          const Icon = get(icons, option.value);

          return (
            <button
              type="button"
              key={option.value}
              onClick={() => {
                onChangeValue(option.value, checked);
                handleSelect(option.value);
              }}
              className={classNames({ active: checked })}
            >
              <Icon
                fill={checked ? Colors.white : Colors.primary}
                className="icon"
              />
              <p className={classNames('button', { contrast: checked, accent: !checked })}>{option.labelText}</p>
            </button>
          );
        })}
      </section>
      {
        selectedAnswer && selectedAnswer.description
          ? (
            <section className="description">
              <h1 className="button">{`${selectedAnswer.labelText}: `}</h1>
              <p className="body">{selectedAnswer.description}</p>
            </section>
          )
          : null
      }
    </section>
  );
};

export default ProfileQuestionMedicalConditionInput;
