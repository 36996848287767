import * as actionTypes from './actionTypes';
import { HospitalsActionTypes, HospitalsAddPayload, HospitalsReplacePayload } from './types';

const add = (payload: HospitalsAddPayload): HospitalsActionTypes => ({
  type: actionTypes.ADD,
  payload,
});

const replace = (payload: HospitalsReplacePayload): HospitalsActionTypes => ({
  type: actionTypes.REPLACE,
  payload,
});

const clear = (): HospitalsActionTypes => ({
  type: actionTypes.CLEAR,
});

export {
  add,
  replace,
  clear,
};
