import * as actionTypes from './actionTypes';
import {
  DailySuggestionsActionTypes,
  DailySuggestionsAddPayload,
  DailySuggestionsUpdatePayload,
  DailySuggestionsTriggerPayload,
  DailySuggestionsIntervalTriggerPayload,
  DailySuggestionsClearDrugModulePayload,
} from './types';

const add = (payload: DailySuggestionsAddPayload): DailySuggestionsActionTypes => ({
  type: actionTypes.ADD,
  payload,
});

const update = (payload: DailySuggestionsUpdatePayload): DailySuggestionsActionTypes => ({
  type: actionTypes.UPDATE,
  payload,
});

const check = (payload?: boolean): DailySuggestionsActionTypes => ({
  type: actionTypes.CHECK,
  payload,
});

const trigger = (payload: DailySuggestionsTriggerPayload): DailySuggestionsActionTypes => ({
  type: actionTypes.TRIGGER,
  payload,
});

const intervalTrigger = (
  payload: DailySuggestionsIntervalTriggerPayload,
): DailySuggestionsActionTypes => ({
  type: actionTypes.INTERVAL_TRIGGER,
  payload,
});

const clear = (): DailySuggestionsActionTypes => ({
  type: actionTypes.CLEAR,
});

const clearDrugModuleSuggestions = (
  payload: DailySuggestionsClearDrugModulePayload,
): DailySuggestionsActionTypes => ({
  type: actionTypes.CLEAR_DRUG_MODULE,
  payload,
});

export {
  add,
  update,
  check,
  trigger,
  clear,
  intervalTrigger,
  clearDrugModuleSuggestions,
};
