import { AppState } from '../reducers';

import createDeepEqualSelector from '../../selectors/createDeepEqualSelector';

const getSync = createDeepEqualSelector(
  (state: AppState) => state.runtime.sync,
  (state: any) => state,
);

const getUserSyncStale = createDeepEqualSelector(
  (state: AppState) => state.runtime.sync,
  (state: any) => state.userSyncStale,
);

export {
  getSync, // eslint-disable-line import/prefer-default-export
  getUserSyncStale,
};
