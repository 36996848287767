import React, { useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import { useTranslation } from 'react-i18next';

import { AppState } from '../state/reducers';

import { selectors as appointmentsSelectors } from '../state/appointments';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import DrawerButtons from '../components/DrawerButtons';
import AppointmentListItem from '../components/AppointmentListItem';
import DrawerRightHeader from '../components/DrawerRightHeader';

type Params = {
  view: string;
  date?: string;
};

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const Appointments = () => {
  const { t } = useTranslation();

  const { view, date } = useParams<Params>();

  const history = useHistory();

  const memoizedGetSelector = useMemo(
    appointmentsSelectors.makeGetAllByViewAndDate,
    [],
  );

  const appointments = useSelector(
    (state: AppState) => memoizedGetSelector(state, view, date, true),
  );

  return (
    <article className="page diary row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('appointments')} showRightButton />
          <button
            type="button"
            className="navigation accent back"
            onClick={history.goBack}
          >
            &lt; {t('back')}
          </button>
          <section className={isEmpty(appointments) ? 'diary-item' : 'list'}>
            {!isEmpty(appointments)
              ? map(appointments, (appointment, index) => (
                <AppointmentListItem
                  appointment={appointment}
                  key={appointment.id}
                  divider={(index + 1 !== size(appointments))}
                />
              ))
              : <span className="body light">{t('appointments:empty-state-no-appointments')}</span>}
          </section>
        </section>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default Appointments;
