import * as actionTypes from './actionTypes';
import { TrendIndicatorType } from '../../../types';
import { TrendIndicatorsActionTypes } from './types';

const add = (payload: TrendIndicatorType[]): TrendIndicatorsActionTypes => ({
  type: actionTypes.ADD,
  payload,
});

const replace = (payload: TrendIndicatorType[]): TrendIndicatorsActionTypes => ({
  type: actionTypes.REPLACE,
  payload,
});

const clear = (): TrendIndicatorsActionTypes => ({
  type: actionTypes.CLEAR,
});

export {
  add,
  replace,
  clear,
};
