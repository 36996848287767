import React, { useMemo, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { StyleSheet as RNStyleSheet } from 'react-native';

import { useSelector } from 'react-redux';
import { TDrugModule } from '../../types';

import '../css/Message.css';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRightHeader from '../components/DrawerRightHeader';
import '../css/WhenSeekHelp.css';
import Button from '../components/Button';
import LeaveDrugModuleSupportHoc from '../hocs/LeaveDrugModuleSupportHoc';
import DrugModuleBreadcrumbs from '../components/DrugModuleBreadcrumbs';
import Modal from '../components/Modal';
import { AppState } from '../state/reducers';
import { selectors as contactsSelectors } from '../state/contacts';
import Contact from '../components/Contact';
import SideEffectReportingBlock from '../components/SideEffectReportingBlock';

const styles = RNStyleSheet.create({
  reportingBlockContainer: {
    borderWidth: 0,
    paddingHorizontal: 0,
    marginHorizontal: 0,
    marginBottom: 5,
  },
  reportingBlockText: {
    textAlign: 'left',
    paddingHorizontal: 0,
  },
});

type Params = {
  drugModule: TDrugModule;
};

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const underlineText = {
  textDecorationLine: 'underline',
};

const WhenSeekHelp = () => {
  const { t } = useTranslation();

  const { drugModule } = useParams<Params>();
  const [showContactModal, setShowContactModal] = useState(false);

  const memoizedContactsGetByContactModuleSelector = useMemo(
    contactsSelectors.makeGetByContentModule,
    [],
  );

  const contact = useSelector(
    (state: AppState) => memoizedContactsGetByContactModuleSelector(state, drugModule),
  );

  const contactCareTeam = () => {
    setShowContactModal(true);
  };

  return (
    <article className="page when-seek-help row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('contentModules:drug:common:when-seek-help')} showRightButton />
          <DrugModuleBreadcrumbs drugModule={drugModule} />
          <section className="when-seek-help-container">
            <h3> {t('contentModules:drug:common:seek-help:title')}</h3>
            <SideEffectReportingBlock
              drugModule={drugModule}
              body="when-seek-help-reporting"
              hideTitle
              containerStyle={styles.reportingBlockContainer}
              textStyle={styles.reportingBlockText}
            />

            <div className="contact-care-button align-center">
              <Button labelText={t('contentModules:drug:common:seek-help:contact-care-team')} size="medium" onClick={contactCareTeam} />
            </div>

            <Trans i18nKey="side-effects">
              {`${t('contentModules:drug:common:seek-help:check-here-all')} `}
              <NavLink to={`/support-menu/${drugModule}/side-effects/`}>
                <span> {t('contentModules:drug:common:side-effects').toLowerCase()}</span>
              </NavLink>
              {' '}
              {`${t('contentModules:drug:common:seek-help:check-here')}`}
            </Trans>

            <p>{t('contentModules:drug:common:seek-help:notice-side-effects')}</p>
            <p className="side-effects-list">
              {t(`contentModules:drug:${drugModule}:seek-help:side-effects-list`)}
            </p>

            <Trans i18nKey="side-effects">
              {`${t(`contentModules:drug:${drugModule}:seek-help:footer`)} `}
              <NavLink style={underlineText} to={`/support-menu/${drugModule}/side-effects/`}>
                <span> {t('contentModules:drug:common:side-effects-may-experience').toLowerCase()}</span>
              </NavLink>
            </Trans>
          </section>
        </section>

        <Modal
          classNameOverlay="delete-modal-overlay"
          className="delete-modal"
          visible={showContactModal}
          hideModal={() => setShowContactModal(false)}
          actions={[
            {
              title: t('close'),
              onClick: () => setShowContactModal(false),
            },
          ]}
        >
          <>
            <Contact contact={contact} contentModule={drugModule} />
          </>
        </Modal>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default LeaveDrugModuleSupportHoc(WhenSeekHelp);
