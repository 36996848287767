import { Reducer } from 'redux';

import * as utils from './utils';
import * as actionTypes from './actionTypes';
import {
  HospitalAccessCodesState,
  HospitalAccessCodesActionTypes,
  HospitalAccessCodesAddActionType,
  HospitalAccessCodesRemoveActionType,
  HospitalAccessCodesUpdateActionType,
} from './types';

const initialState: HospitalAccessCodesState = {};

const reducer: Reducer<
HospitalAccessCodesState,
HospitalAccessCodesActionTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD: {
      return utils.add(state, action as HospitalAccessCodesAddActionType);
    }
    case actionTypes.REMOVE: {
      return utils.remove(state, action as HospitalAccessCodesRemoveActionType);
    }
    case actionTypes.UPDATE: {
      return utils.update(state, action as HospitalAccessCodesUpdateActionType);
    }
    case actionTypes.CLEAR: return utils.clear();
    default: return state;
  }
};

export default reducer;
