import { createSelector } from 'reselect';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import toArray from 'lodash/toArray';
import orderBy from 'lodash/orderBy';
import reduce from 'lodash/reduce';
import assignIn from 'lodash/assignIn';
import last from 'lodash/last';
import sortBy from 'lodash/sortBy';
import concat from 'lodash/concat';
import slice from 'lodash/slice';

import { AppState } from '../reducers';
import { TreatmentTypesState } from './types';
import { TTreatmentTypesReturn } from '../../../types';

import EMPTY_ARRAY from '../../utils/empty-array';
import getCountryCodeFromLocale from '../../utils/getCountryCodeFromLocale';
import getLocalizedText from '../../utils/getLocalizedText';

const getAllSelector = (state: TreatmentTypesState): TTreatmentTypesReturn => toArray(state);

const getAll = createSelector(
  (state: AppState) => state.treatmentTypes,
  getAllSelector,
);

const getByLocaleSelector = (
  state: TreatmentTypesState,
  locale: string,
): TTreatmentTypesReturn => {
  if (!locale) {
    return EMPTY_ARRAY;
  }

  const country = getCountryCodeFromLocale(locale);

  const treatmentTypes = orderBy(
    reduce(
      state,
      (result, treatment) => {
        if (!includes(treatment.availableCountries, country)) {
          return result;
        }

        const filteredOptions = filter(
          treatment.options,
          (option) => includes(option.availableCountries, country),
        );

        // Get last option
        const lastOption = last(filteredOptions);

        // Sort remaining options by title
        const sortedOptions = sortBy(
          slice(filteredOptions, 0, -1),
          (option) => getLocalizedText(option, locale, 'title'),
        );

        return assignIn(
          result,
          {
            [treatment.id]: {
              ...treatment,
              // Combine sortedOptions with the last option (other)
              options: concat(sortedOptions, lastOption),
            },
          },
        );
      },
      {},
    ),
    ['order'],
    ['asc'],
  );

  // Get last treatment
  const lastTreatmentType = last(treatmentTypes);

  // Sort remaining treatment types by title and combine with last treatment (other)
  return concat(
    sortBy(
      slice(treatmentTypes, 0, -1),
      (treatment) => getLocalizedText(treatment, 'en-GB', 'title'),
    ),
    lastTreatmentType,
  );
};

const makeGetByLocale = () => (
  createSelector(
    (state: AppState) => state.treatmentTypes,
    (state: any, locale: string) => locale,
    getByLocaleSelector,
  )
);

export {
  getAll,
  makeGetByLocale,
};
