import { Reducer } from 'redux';

import * as utils from './utils';
import * as actionTypes from './actionTypes';
import {
  NotesState,
  NotesActionTypes,
  NotesAddActionType,
  NotesRemoveActionType,
  NotesUpdateActionType,
} from './types';

const initialState: NotesState = {};

const reducer: Reducer<NotesState, NotesActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD: return utils.add(state, action as NotesAddActionType);
    case actionTypes.REMOVE: return utils.remove(state, action as NotesRemoveActionType);
    case actionTypes.UPDATE: return utils.update(state, action as NotesUpdateActionType);
    case actionTypes.CLEAR: return utils.clear();
    default: return state;
  }
};

export default reducer;
