/* eslint-disable max-len */

import React from 'react';
import { NavLink } from 'react-router-dom';
import { TDrugModule } from '../../types';
import { ReactComponent as IconThreeWeeks } from '../assets/icons/ThreeWeeks.svg';
import { ReactComponent as IconNinetyMinutes } from '../assets/icons/NinetyMinutes.svg';
import { ReactComponent as IconFirstInfusion } from '../assets/icons/FirstInfusion.svg';
import { ReactComponent as IconThirtyMinutes } from '../assets/icons/ThirtyMinutes.svg';
import { ReactComponent as IconWarning } from '../assets/icons/Warning.svg';
import { ReactComponent as IconLungs } from '../assets/icons/Lungs.svg';
import { ReactComponent as IconBloodCell } from '../assets/icons/BloodCell.svg';
import { ReactComponent as IconHeart } from '../assets/icons/HeartOrgan.svg';
import { ReactComponent as IconLiver } from '../assets/icons/Liver.svg';
import { ReactComponent as IconDoubleMG150 } from '../assets/icons/DoubleMG150.svg';
import { ReactComponent as IconMG150 } from '../assets/icons/MG150.svg';
import { ReactComponent as IconMG100 } from '../assets/icons/MG100.svg';
import { ReactComponent as IconSun } from '../assets/icons/Sun.svg';
import { ReactComponent as IconMoon } from '../assets/icons/Moon.svg';
import { ReactComponent as IconAnaemia } from '../assets/icons/Anaemia.svg';
import { ReactComponent as IconFeelSick } from '../assets/icons/FeelSick.svg';
import { ReactComponent as IconAppetite } from '../assets/icons/Appetite.svg';
import { ReactComponent as IconDiarrhoea } from '../assets/icons/Diarrhoea.svg';
import { ReactComponent as IconTiredness } from '../assets/icons/Tiredness.svg';
import { ReactComponent as IconQuestionmarkCircle } from '../assets/icons/QuestionmarkCircle.svg';
import Colors from '../theme/Colors';

const getEnhertuPage1 = () => (
  <>
    <h3>About your Treatmen</h3>

    <h4 className="primary">
      You have received this information guide because you have been prescribed Enhertu▼,
      also known as trastuzumab deruxtecan.
    </h4>
    <div className="content-block">
      <p>
        Trastuzumab deruxtecan is a monotherapy (meaning it is prescribed alone) and is used to treat adults with{' '}
        <strong>inoperable</strong> (which means the cancer cannot be removed by
        surgery) or <strong>metastatic </strong>(which means the cancer has
        spread to other parts of the body) <strong>breast cancer </strong>that:
        <span className="sup">1,2</span>
      </p>
      <ul>
        <li>
          Is <strong>H</strong>uman <strong>E</strong>pidermal growth factor{' '}
          <strong>R</strong>eceptor <strong>2-positive</strong> (HER2+)
        </li>

        <li>
          Has previously been treated with one or more therapies targeted
          against HER2
        </li>
      </ul>

      <p>
        Your oncology treatment team will have checked that you meet these
        criteria before prescribing trastuzumab deruxtecan.
      </p>
      <p>
        In addition to the information contained in this guide, please also read
        the <strong>Trastuzumab Deruxtecan Patient Information Leaflet</strong>,
        which has been provided with your medication and includes additional
        useful information.
      </p>

      <hr />

      <h4>
        See the full <a href="https://www.medicines.org.uk/emc/files/pil.12135.pdf" target="_blank" rel="noreferrer">Enhertu Patient Information Leaflet</a> for important
        safety information. Please note that this Treatment Guide does not replace the Patient Information Leaflet.
      </h4>
      <p>
        ▼This medicine is subject to additional monitoring. This will allow quick
        identification of new safety information. If you experience any side effects from this
        medicine, talk to your doctor, pharmacist or nurse. This includes
        any possible side effects (also known as adverse reactions) not listed
        in the package leaflets. You can also report any suspected side effects
        to this medicine to the MHRA via the Yellow Card Scheme. Reporting forms
        and information can be found at{' '}
        <a href="https://yellowcard.mhra.gov.uk" target="_blank" rel="noreferrer">
          yellowcard.mhra.gov.uk
        </a>{' '}
        or search for MHRA Yellow Card in the Google Play or Apple App store or
        call <a href="tel:0800 731 6789">0800 731 6789</a> for free, Monday to Friday between 9am and 5pm.
        Alternatively, please report any suspected adverse reactions to Daiichi
        Sankyo UK Pharmacovigilance on <a href="tel:0800 028 5122">0800 028 5122</a> or by sending an email to:
        <br />
        <br />
        <a href="mailto:pharmacovigilance@daiichi-sankyo.co.uk">pharmacovigilance@daiichi-sankyo.co.uk</a>
      </p>
    </div>
  </>
);
const getEnhertuPage2 = () => (
  <>
    <h3>Trastuzumab deruxtecan and HER2+ metastatic breast cancer explained</h3>

    <h4 className="primary">
      What is trastuzumab deruxtecan?<span className="sup">1,2</span>
    </h4>
    <div className="content-block">
      <p>
        Trastuzumab deruxtecan is made up of an antibody attached to a
        chemotherapy drug. The antibody targets the <strong>HER2</strong>{' '}
        protein. Trastuzumab deruxtecan kills HER2+ cancer cells by delivering
        the chemotherapy drug directly to them. Although trastuzumab deruxtecan
        is designed to target HER2+ cancer cells, it may still affect some
        healthy cells.
      </p>

      <h4 className="primary">What is HER2+ metastatic breast cancer?</h4>

      <p>
        Around 15–25 out of every 100 women with breast cancer have HER2+ breast
        cancer.<span className="sup">3</span>
        HER2 is a protein involved in normal cell growth. When present in large
        quantities on the surface of cancer cells, HER2 may cause cancer cells
        to grow more quickly.<span className="sup">4</span> Your test results
        will have shown that your breast cancer has high amounts of HER2;
        therefore, it is described as <strong>HER2+</strong>.
      </p>
      <p>You may have de novo (meaning this was the initial diagnosis) inoperable or metastatic
        HER2+ breast cancer, or you may have previously been diagnosed with
        breast cancer that has now metastasised (spread to other parts of your
        body).
      </p>
    </div>
  </>
);
const getEnhertuPage3 = () => (
  <>
    <h3>How you will receive your trastuzumab deruxtecan treatment</h3>

    <h4 className="primary" id="how-is-trastuzumab-deruxtecan-given">
      How is trastuzumab deruxtecan given?<span className="sup">1,2</span>
    </h4>
    <div className="content-block">
      <p>
        Your treatment will be given once every 3 weeks by a trained healthcare
        professional in a hospital or clinic.
      </p>
      <hr />

      <div className="image-block">
        <h3 className="title">Once every 3 weeks</h3>

        <IconThreeWeeks className="treatment img-center icon" />
      </div>
      <div className="image-block">
        <h3 className="title">Your first infusion</h3>
        <p className="align-center">takes about 90 minutes</p>
        <IconNinetyMinutes className="treatment img-center icon" />
      </div>
      <div className="image-block">
        <IconFirstInfusion className="treatment img-center icon" />
      </div>
      <div className="image-block">
        <h3 className="title">After the first infusion</h3>
        <p className="align-center">infusions may take about 30 minutes</p>
        <IconThirtyMinutes className="treatment img-center icon" />
      </div>

      <hr />

      <p>
        Trastuzumab deruxtecan is given through a cannula or tube inserted into
        one of your veins (called an intravenous infusion).
      </p>

      <h4 className="primary" id="dose-need-to-be-change">
        Why might the dose need to be changed?<span className="sup">1,2</span>
      </h4>

      <p>
        Like all medicines, trastuzumab deruxtecan can cause side effects. Your
        doctor may lower your dose, or temporarily or permanently stop your
        treatment depending on your side effects (please see the table of common
        side effects on the next page for more information). This is the most appropriate way to manage any potential side effects to help
        minimise their effect on your health or daily life.
      </p>

      <p>
        It is important to talk to your oncology treatment team, as soon as you
        experience any side effects during treatment. Seeking help immediately
        may stop the side effects from becoming more serious, and many side
        effects can be managed effectively if you report them early.
      </p>

      <h4 className="primary" id="infusion-appointment-is-missed-or-delayed">
        What happens if an infusion appointment is missed or delayed?
        <span className="sup">1,2</span>
      </h4>
      <p>
        If you miss a planned infusion of trastuzumab deruxtecan, contact your
        doctor right away to reschedule your appointment. Do not wait until the
        next planned treatment. It is very important that you do not miss a dose
        of this medicine.
      </p>
      <p>
        Do not stop treatment with Enhertu without checking with your doctor. If you have any
        further questions on the use of this medicine, ask your oncology treatment team.
      </p>
    </div>
  </>
);
const getEnhertuPage4 = () => (
  <>
    <h3 id="side-effects-during-treatment align-center">
      Side effects that you may experience during treatment
      <span className="sup">2</span>
    </h3>
    <div className="content-block">
      <p>
        Like all medicines, this medicine can cause side effects, although not
        everybody gets them. Please note that this information does not replace the Patient Information Leaflet. Read the <a href="https://www.medicines.org.uk/emc/files/pil.12135.pdf" target="_blank" rel="noreferrer">Patient Information Leaflet</a> for additional important safety information. Tell your doctor if
        you get any side effects, including those not listed in this material. Side effects that can
        occur while taking trastuzumab deruxtecan are listed in the table below:
        <span className="sup">2</span>
      </p>

      <hr />

      <h4 className=" align-center">
        Very common side effects (may affect more than 1 in 10 people)
      </h4>
      <p>
        <strong>If you experience any of the following symptoms, it&apos;s important to talk to your doctor
          immediately.
        </strong> These signs could indicate a serious condition that might even be life-
        threatening. Seeking medical help promptly can prevent these issues from getting worse.
      </p>

      <ul>
        <li>A lung disease called interstitial lung disease (ILD) with symptoms that can include
          cough, shortness of breath, fever, or other new or worsening breathing problems
        </li>
        <li>An infection caused by reduced number of neutrophils (a type of white blood cell) with
          symptoms that can include chills, fever, sores in your mouth, stomach pain or pain when
          urinating
        </li>
        <li>A heart problem called decreased left ventricular ejection fraction (LVEF) with symptoms
          that can include new or worsening shortness of breath, cough, tiredness, swelling of
          ankles or legs, irregular heartbeat, sudden weight gain, dizziness or unconsciousness
        </li>
      </ul>

      <hr />

      <h4 className=" align-center">
        Other very common side effects
      </h4>

      <p>
        Side effects can differ in how often they happen and how severe they are, depending on the
        dose you were given. Let your doctor or nurse know if you notice any of these side effects:
      </p>

      <ul>

        <li>Nausea (feeling sick), vomiting</li>
        <li>Tiredness</li>
        <li>Decreased appetite</li>
        <li>Blood tests showing decreased red or white blood cells, or platelets (blood cells that help the blood to clot<span className="sup">5</span>)</li>
        <li>Hair loss</li>
        <li>Diarrhoea</li>
        <li>Constipation</li>
        <li>Blood tests showing increased levels of the liver enzymes such as transaminases</li>
        <li>Pain in muscles and bones</li>
        <li>Weight loss</li>
        <li>Fever</li>
        <li>Abdominal (belly) pain, indigestion</li>
        <li>Infections of the nose and throat, including flu-like symptoms</li>
        <li>Headache</li>
        <li>Blisters in or around the mouth</li>
        <li>Cough</li>
        <li>Blood tests showing low blood potassium levels</li>
        <li>Indigestion</li>
        <li>Infection of the lungs</li>
        <li>Breathing difficulties</li>
        <li>Nosebleeds</li>
        <li>Swelling of ankles and feet</li>
        <li>Dizziness</li>
        <li>Altered/bad taste in mouth</li>

      </ul>

      <hr />

      <h4 className=" align-center">
        Common side effects
        (may affect up to 1 in 10 people)
      </h4>

      <ul>
        <li>Rash</li>
        <li>Blood tests showing increased levels of bilirubin, alkaline phosphatase or creatinine</li>
        <li>Itching</li>
        <li>Dry eye</li>
        <li>Skin discolouration</li>
        <li>Blurred vision</li>
        <li>Feeling thirsty, dry mouth</li>
        <li>Bloating</li>
        <li>Fever along with a decreased number of white blood cells called neutrophils</li>
        <li>Inflammation of the stomach</li>
        <li>Excessive gas in the stomach or intestine</li>
        <li>Reactions related to the infusion of the medicine, which may include fever, chills, flushing, itching or rash</li>
      </ul>

      <div className="bordered-text-block">
        <IconWarning className="treatment img-center icon" fill={Colors.primary} />

        <h3 className="primary align-center">
          What to do if you experience side effects?
        </h3>
        <p className="primary align-center">
          Speak with your doctor, pharmacist, or nurse <strong>immediately</strong> if you experience any of the
          symptoms associated with interstitial lung disease, decreased left ventricular ejection
          fraction, and an infection caused by reduced neutrophils (a type of white blood cell)
          mentioned above. Getting medical treatment right away may help keep these problems
          from becoming more serious.
        </p>
      </div>
    </div>
  </>
);
const getEnhertuPage5 = () => (
  <>
    <div className="common-text-block">
      <h3 id="key-signs-and-symptoms-of-serious-side-effects">
        What are the key signs and symptoms of serious side effects to watch out
        for?
      </h3>
      <div className="content-block">
        <p>
          Trastuzumab deruxtecan can cause serious side effects that you should
          watch out for, including:
        </p>
      </div>

      <div className="bordered-text-block">
        <IconWarning className="treatment img-center icon" fill={Colors.primary} />
        <h4 className="align-center">
          What to do if you experience side effects?
        </h4>
        <p className=" primary align-center">
          Speak with your doctor <strong>immediately</strong> if you experience any of the
          symptoms associated with interstitial lung disease, decreased left ventricular ejection
          fraction, and an infection caused by reduced neutrophils (a type of white blood cell)
          mentioned above. Getting medical treatment right away may help keep these problems
          from becoming more serious.
        </p>
      </div>

      <div className="key-signs-block">

        <IconLungs className="treatment img-center icon" />

        <h4 className="secondary align-center">
          Lung problems like interstitial lung disease
        </h4>

        <p>
          Interstitial lung disease (ILD) can become a serious condition if it is
          not caught and treated early. A patient alert card (see below) can help you
          with the early identification of this potentially serious side effect.
          Call or see your oncology treatment team right away if you develop any of the
          following signs and symptoms or if these symptoms get worse:
          <span className="sup">2</span>
        </p>

        <ul>
          <li>Cough</li>
          <li>Trouble breathing or shortness of breath</li>
          <li>Fever</li>
          <li>Other new or worsening breathing symptoms</li>
        </ul>
        <div className="key-signs-block">
          <IconBloodCell className="treatment img-center icon" />
          <h4 className="secondary align-center">
            An infection caused by a reduced number of neutrophils (a type of
            white blood cell)
            <span className="sup">2,7</span>
          </h4>

          <p>
            Tell your oncology treatment team right away if you develop any of
            the following signs or symptoms as this could indicate neutropenia:
            <span className="sup">2</span>
          </p>

          <ul>
            <li>Chills</li>
            <li>Fever</li>
            <li>Sores In Your Mouth</li>
            <li>Stomach Pain</li>
            <li>Pain When Urinating</li>
          </ul>

          <ul>
            <li>
              Low white blood cell counts are very common with trastuzumab
              deruxtecan treatment
              <span className="sup">2</span>
            </li>
            <li>
              Because white blood cells are vital for your immune system to
              fight off infections,<span className="sup">8</span> your oncology treatment team will
              check your white blood cell counts before starting trastuzumab
              deruxtecan treatment and before each dose
              <span className="sup">1</span>
            </li>
            <li>
              If neutropenia is found, your oncology treatment team may
              temporarily pause your trastuzumab deruxtecan treatment until your
              neutropenia shows signs of improvement or may permanently reduce your dose
              <span className="sup">1 </span>
            </li>
          </ul>
        </div>

        <div className="key-signs-block">
          <IconHeart className="treatment img-center icon" />
          <h4 className="secondary align-center">Heart problems</h4>

          <p>
            Signs and symptoms related to heart problems may include:
            <span className="sup">2</span>
          </p>

          <ul>
            <li>New or worsening shortness of breath</li>
            <li>Coughing</li>
            <li>Feeling tired</li>
            <li>Swelling of your ankles or legs</li>

            <li>Irregular heartbeat</li>
            <li>Sudden weight gain</li>
            <li>Dizziness</li>
            <li>Loss of consciousness</li>
          </ul>

          <p>
            Your oncology team will check your heart function before starting
            treatment with trastuzumab deruxtecan and at regular intervals as
            needed<span className="sup">1</span>
          </p>

          <p>Read more in the{' '}
            <NavLink className="navigation" activeClassName="emphasized" exact to="/support-menu/enhertu/side-effects/">
              <span>side effects to look out for</span>
            </NavLink> section
          </p>
        </div>

        <div className="key-signs-block">
          <IconLiver className="treatment img-center icon" />

          <h4 className="secondary align-center">Liver problems</h4>

          <p>
            Your oncology team may have to monitor your liver function while you are
            taking trastuzumab deruxtecan
            <span className="sup">2</span>
          </p>
        </div>
      </div>

      <div className="common-text-block">
        <div className="bordered-text-block">
          <IconWarning className="treatment img-center icon" fill={Colors.primary} />

          <h3 className=" primary align-center">
            Please see the full Enhertu Patient Information Leaflet for the full
            list of warnings and precautions.
          </h3>
          <p className=" primary align-center">
            An alert card is available for patients prescribed with Enhertu.
            This Patient Card helps you to identify symptoms of lung problems
            that you may experience with Enhertu. You should keep this card with
            you at all times and show it to any doctor you consult (for example,
            if your regular doctor is unavailable or if you are travelling).
            Please speak to your oncology treatment team if you would like an
            alert card.
          </p>
        </div>
      </div>
    </div>
  </>
);
const getEnhertuPage6 = () => (
  <>
    <div className="common-text-block">
      <h3 id="pregnancy">
        Pregnancy, breastfeeding and contraceptive use during trastuzumab
        deruxtecan treatment
        <span className="sup">2</span>
      </h3>
      <div className="content-block">
        <p>
          Trastuzumab deruxtecan is not recommended during pregnancy because
          this medicine may harm the unborn baby. Speak with your oncology
          treatment team immediately if you are pregnant, think you may be
          pregnant or are planning to become pregnant before or during
          treatment.
        </p>
        <p>
          You should use effective contraception (birth control) to avoid
          becoming pregnant while being treated with trastuzumab deruxtecan.
          Talk to your oncology treatment team about the best contraception for
          you, or if you are thinking about becoming pregnant. Talk to your
          oncology treatment team before you stop your contraception. You should
          continue contraception for at least seven months after the last dose
          of trastuzumab deruxtecan.
        </p>
        <p>
          You should not breastfeed during treatment with trastuzumab deruxtecan
          or for at least seven months after your last dose because it is not
          known whether trastuzumab deruxtecan passes into breast milk. Talk to
          your oncology treatment team if you have concerns about this.
        </p>
        <p>
          If you are a man being treated with trastuzumab deruxtecan, you should
          not father a child during treatment and for four months after you have finished treatment.
          You may wish to discuss sperm collection and storage with your
          oncology treatment team before starting treatment as trastuzumab
          deruxtecan may reduce your fertility.
        </p>
      </div>
      <div className="bordered-text-block">
        <IconWarning className="treatment img-center icon" fill={Colors.primary} />

        <h3 className="primary align-center">
          What to do if you experience side effects?
        </h3>
        <p className="primary align-center">
          Speak with your doctor immediately if you notice any of the following{' '}
          <NavLink className="navigation underline" activeClassName="emphasized" exact to="/support-menu/enhertu/side-effects/">
            <span>symptoms</span>
          </NavLink>. They may be signs of a serious, possibly fatal, condition.
          Getting medical treatment right away may help keep these problems from
          becoming more serious.
        </p>
      </div>
    </div>
  </>
);
const getEnhertuPage7 = () => (
  <>
    <div className="common-text-block">
      <h3 id="references">References</h3>
      <div className="content-block">
        <ol>
          <li>
            Daiichi Sankyo Europe GmbH., Enhertu (trastuzumab deruxtecan)
            Summary of Product Characteristics.
          </li>
          <li>
            Daiichi Sankyo Europe GmbH., Package leaflet: Information for the
            patient.
          </li>
          <li>
            Macmillan UK., People with Breast Cancer 2017 update. 2017 (accessed
            May 2024).
          </li>
          <li>
            Cancer.Net., Breast Cancer – Metastatic introduction. (accessed
            May 2024).
          </li>
          <li>
            Cancer.Net., Low Platelet Count or Thrombocytopenia. (accessed
            May 2024).
          </li>
          <li>
            British Lung Foundation., Pulmonary Fibrosis. (accessed May 2024).
          </li>
          <li>
            Boxer, L. and D.C. Dale., Neutropenia: causes and consequences.
            Semin Hematol, 2002. 39(2): p. 75-81
          </li>
          <li>
            NHS. Functions of blood: its role in the immune system (accessed May 2024)
          </li>
        </ol>
      </div>
    </div>
  </>
);

const getLynparzaPage1 = () => (
  <>
    <h3>About your Treatment</h3>

    <div className="content-block">
      <p>
        You have received this information guide because you have been prescribed
        olaparib, also known as Lynparza®.
      </p>

      <h4>
        What is Lynparza®?
      </h4>

      <p>
        Lynparza® is a type of targeted therapy called a PARP inhibitor. The
        active substance of Lynparza® is called &quot;olaparib&quot;. It is a prescription
        oral treatment (taken by mouth) for adults who:<span className="sup">1</span>

        <ul>
          <li>have HER2-negative, high-risk early breast cancer (when the cancer has not spread to
            other parts of the body
          </li>
          <li>have an inherited BRCA mutation</li>
          <li>have been previously treated with chemotherapy before or after surgery</li>
        </ul>
      </p>

      <h4>
        Why have I been prescribed olaparib, and what can I expect?<span className="sup">1</span>
      </h4>

      <p>
        If you have BRCA-mutated, HER2-negative, high-risk early breast cancer and
        have already had chemotherapy, your doctor may think you are suitable for
        treatment with olaparib. If the breast cancer is also hormone receptor-positive,
        your doctor may also prescribe hormonal treatment.
      </p>
      <p>
        Olaparib can also be used for the treatment of BRCA-mutated, HER2-negative breast cancer which has
        spread beyond the original tumour (you should have received chemotherapy before or after
        your cancer has spread). Clinical trials have shown that olaparib can help to
        stop or slow down the growth of your cancer. Talk to your cancer care team
        if you have any questions about why you have been prescribed olaparib.
      </p>

      <hr />

      <div>
        <h4>
          See the full Lynparza® Patient Information Leaflet for important safety information. Please
          note that this Treatment Guide does not replace the Patient Information Leaflet.
        </h4>
        <div className="row">

          <ul>
            <li><a href="http://www.medicines.org.uk/emc/files/pil.9204.pdf" target="_blank" rel="noreferrer">www.medicines.org.uk/emc/files/pil.9204.pdf</a>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </>
);
const getLynparzaPage2 = () => (
  <>
    <h3>Olaparib and gBRCAm early stage breast cancer</h3>

    <p>
      Olaparib is prescribed for people with a specific type of breast cancer
      called <b>germline BRCA-mutated, HER2-negative</b>, high-risk early
      breast cancer.<span className="sup">1,14</span>
    </p>
    <div className="content-block">
      <h4 className="primary">
        What is BRCA?
      </h4>

      <p>
        The name &quot;BRCA&quot; is an abbreviation for &quot;BReast CAncer gene&quot;.
        Genes are pieces of the DNA code that we inherit from our parents.<span className="sup">2</span> We each have
        two copies of every gene - one copy is inherited from our mother and one from our father.<span className="sup">2</span> We have approximately 20,000 genes, each with a specific function that helps our bodies
        grow and
        function normally.<span className="sup">2</span>

        Every human has both the BRCA1 and BRCA2 genes. Despite what their names might suggest,
        BRCA genes do not cause breast cancer. In fact, these genes normally play a big role in
        preventing breast cancer. They help repair damaged DNA in human cells that can lead to
        cancer and the uncontrolled growth of tumours. Because of this, the BRCA genes are known
        as &quot;tumour suppressor genes&quot;<span className="sup">3,4</span> When a BRCA gene is not working
        properly, damaged DNA may not be repaired correctly, resulting in changes in cells that can lead to cancer.<span className="sup">3,4</span>

      </p>

      <h4 className="primary">
        BRCA mutations
      </h4>

      <p>
        A small percentage of people (approximately one in 400, or 0.25%
        of the population) carry mutated BRCA1 or BRCA2 genes (a mutated gene is one
        which is altered from the normal version).<span className="sup">5</span> A BRCA
        mutation occurs when the DNA that makes up the gene becomes damaged. When a BRCA
        gene is mutated, it may no longer be effective at repairing damaged DNA.
        Because of this, people with a BRCA gene mutation are more likely to develop certain cancers
        including breast cancer<span className="sup">5</span>. The carrier of the mutated gene can also pass a gene
        mutation down to his or her children.<span className="sup">5</span> The phrase &quot;germline BRCA-mutated&quot; means the
        mutation is inherited.<span className="sup">5</span>
      </p>
    </div>
  </>
);
const getLynparzaPage3 = () => (
  <>
    <h3>How you will receive your olaparib treatment</h3>

    <div className="content-block">
      <h4 className="primary">
        How to take olaparib<span className="sup">1,14</span>
      </h4>

      <p>
        Olaparib is an oral medication (taken by mouth) that is usually taken
        as <strong>two 150 mg tablets twice a day</strong>,
        for a total daily dose of 600 mg. Your doctor may prescribe a different
        dose to that shown above. It is important that you take your olaparib tablets
        exactly as instructed by your doctor or prescriber.
      </p>

      <ul>
        <li>
          Your doctor or prescriber will also tell you how long to continue your treatment, which may be up to
          12 months.<span className="sup">1</span>
        </li>
        <li>
          Olaparib should be swallowed whole and not chewed, crushed, dissolved or divided.
        </li>
        <li>
          Do not drink grapefruit juice while you are being treated with olaparib, as it can affect the way the
          medicine works.
        </li>
      </ul>

      <hr />

      <div className="image-block">
        <IconDoubleMG150 className="treatment img-center icon" />

        <div className="treatment-expression">
          <div className="wrapper">
            <p className="variable">2</p>
            <p className="description">150 mg tables</p>
          </div>

          <div className="wrapper">
            <p className="variable">x</p>
          </div>

          <div className="wrapper">
            <p className="variable">2</p>
            <p className="description">times a day with tablets or without food</p>
          </div>

        </div>
      </div>
      <hr />

      <h4 className="primary" id="dose-need-to-be-change">
        When to take your dose<span className="sup">1,14</span>
      </h4>

      <p>
        The usual time to take your first olaparib dose is in the morning,
        and your second dose in the evening. The doses should be taken at
        approximately the same time every day. Please do not exceed four tablets a day, as
        this will not improve your condition and may lead to side effects.
      </p>

      <div className="image-block">
        <b><h3 className="secondary ">Morning</h3></b>
        <div className="row gap">
          <IconSun className="treatment img-center icon" />
          <IconMG150 className="treatment img-center icon" />
        </div>
        <br />
        <br />
        <br />
        <b><h3 className="secondary">Evening</h3></b>
        <div className="row gap">
          <IconMoon className="treatment img-center icon" />
          <IconDoubleMG150 className="treatment img-center icon" />
        </div>
      </div>

      <h4 className="primary">
        Why might the dose need to be changed?<span className="sup">14</span>
      </h4>
      <p>
        Your doctor will tell you how many tablets of olaparib to take.
        It is important that you take the total
        recommended dose each day.<span className="sup">1</span>
      </p>

      <div className="row nowrap bordered-text-block">
        <div className="row tablet-block">
          <IconMG150 className="treatment img-center icon" />
          <p className="tablet">
            Tablet, 150mg
          </p>
        </div>

        <p>
          The usual recommended dose of Lynparza® (olaparib) is 300mg
          (2 x 150mg tablets) twice a day - a total of 4
          tablets each day. <span className="sup">1</span>
        </p>
      </div>

      <h4 className="primary">
        Your doctor may prescribe a different dose for a number of reasons, such as:<span className="sup">1,14</span>
      </h4>
      <p>
        <ul>
          <li>You have problems with your kidneys</li>
          <li>You are taking certain medicines that may affect olaparib</li>
          <li>You experience certain side effects while you are taking olaparib</li>
        </ul>
      </p>

      <div className="row nowrap bordered-text-block">
        <div className="row tablet-block">
          <IconMG100 className="treatment img-center icon" />
          <p className="tablet">
            Tablet, 100mg
          </p>
        </div>

        <p>
          Your lowered dose may involve a 100mg tablet, and/or you may have to take
          fewer tablets each day. Alternatively, your treatment might be stopped for
          either a short time or permanently.<span className="sup">1</span>
        </p>
      </div>

      <p>
        If you experience any side effects, you must not modify your treatment without
        consulting your doctor, nurse or pharmacist.
      </p>
    </div>
  </>
);
const getLynparzaPage4 = () => (
  <>
    <h3 id="side-effects-during-treatment align-center">
      Side effects that you may experience during treatment
    </h3>
    <div className="content-block">
      <div className="bordered-text-block">
        <IconWarning className="treatment img-center icon" fill={Colors.primary} />

        <h4 className=" align-center">
          Reporting side effects
        </h4>
        <p className=" align-center">
          You should discuss any side effects/symptoms with your doctor or nurse, including those not listed in the
          <a href="https://www.medicines.org.uk/emc/files/pil.9204.pdf" target="_blank" rel="noreferrer"> Patient Information Leaflet</a>.
          This is so they can help stop them from getting worse. Please see the box below for further information on reporting side effects.
        </p>
      </div>

      <h3 className="primary">What are the common side effects to look out for when taking olaparib?</h3>

      <p>Like all medicines, you may experience some side effects while on your treatment.
        You can work with your cancer  care team to help keep them as mild as possible and manageable.
      </p>

      <div>
        <h3 className="align-center mt40">
          Some of the side effects of olaparib<span className="sup">1,14</span>
        </h3>
        <h4 className="align-center">
          Tell your doctor straight away if you notice any of the following:
        </h4>

        <h4 className="primary align-center mt40">Very common side effects (may affect more than 1 in 10 people)</h4>
        <ul>
          <li>Feeling short of breath</li>
          <li>Feeling very tired</li>
          <li>Pale skin or fast heart beat (these may be symptoms of a decrease in the number of red blood cells, also called anaemia)</li>
          <li>Severe diarrhoea</li>
        </ul>

        <h4 className="primary align-center">Uncommon side effects (may affect up to 1 in 100 people)</h4>
        <ul>
          <li> Allergic reactions (e.g. hives, difficulty breathing or swallowing, dizziness which are signs and symptoms of persensitivity reactions)</li>
          <li> Itchy rash or swollen, reddened skin (dermatitis)</li>
          <li> Serious problems with bone marrow (myelodysplastic syndrome or acute myeloid leukaemia)</li>
          <li> Yellowing of your skin or the whites of your eyes, abnormally dark urine (brown coloured), pain on the right side of your stomach area (abdomen),       tiredness, feeling less hungry than usual or unexplained nausea and vomiting</li>
        </ul>

        <h4 className="primary align-center mt40">Other very common side effects (may affect more than 1 in 10 people) include:</h4>
        <ul>
          <li>Nausea</li>
          <li>Vomiting</li>
          <li>Feeling tired or weak</li>
          <li>Indigestion or heartburn</li>
          <li>Loss of appetite</li>
          <li>Headache</li>
          <li>Changes in taste of foods</li>
          <li>Feeling dizzy</li>
          <li>Cough</li>
          <li>Shortness of breath</li>
          <li>Diarrhoea – if it gets severe, tell your doctor straight away</li>
          <li>Low white blood cell count, which may decrease your ability to fight infection and may be associated with fever</li>
        </ul>

        <h4 className="primary align-center mt40">Common side effects (may affect up to 1 in 10 people) include:</h4>
        <ul>
          <li>Rash</li>
          <li>Sore mouth (stomatitis)</li>
          <li>Pain in the stomach area under the ribs (upper abdominal pain)</li>
          <li>Blood clot in a deep vein, usually in the leg (venous thrombosis) that may cause symptoms such as pain or swelling of
            the legs, or a clot in the lungs (pulmonary embolism) that may cause symptoms such as shortness of breath, chest pain,
            breathing that is more rapid than normal or heart beats faster than normal
          </li>
        </ul>

        <h4 className="primary align-center mt40">Common side effects that may show up in blood tests include:</h4>
        <ul>
          <li>Low white blood cell count (lymphopenia) which may decrease your ability to fight infection and may be associated with fever</li>
          <li>Decrease in the number of platelets in blood (thrombocytopenia) - you may notice the following symptoms bruising or bleeding for longer than usual if you hurt yourself</li>
          <li>Increase in blood creatinine - this test is used to check how your kidneys are working</li>
          <li>Abnormal liver function tests</li>

        </ul>

        <p>Further information on the serious side effects associated with olaparib can be found in the{' '}
          <NavLink className="navigation" activeClassName="emphasized" exact to="/support-menu/lynparza/side-effects/">
            <span>side effects to look out for</span>
          </NavLink> section
        </p>

        <p>
          Please refer to the <a href="https://www.medicines.org.uk/emc/files/pil.9204.pdf" target="_blank" rel="noreferrer">Patient Information Leaflet (PIL)</a> for a more
          comprehensive list of warnings and precautions, and side effects. If you
          experience symptoms other than those listed, contact your doctor
          or pharmacist for more information.
        </p>
      </div>

      <h3 className="primary">Keeping track of your side effects</h3>

      <p>
        It is very helpful to note down any side effects you experience,
        so you can discuss them with your care team at your next appointment.
      </p>
      <p>
        If you have any difficulties or experience any severe symptoms, don&apos;t
        wait until your next appointment: Contact your cancer care team so they
        can help put together a plan to manage them.
      </p>

      <div className="bordered-text-block">
        <IconWarning className="treatment img-center icon" fill={Colors.primary} />

        <p className="primary align-center">
          Adverse events should be reported. Reporting forms and
          information can be found at <a href="https://yellowcard.mhra.gov.uk" target="_blank" rel="noreferrer">yellowcard.mhra.gov.uk</a>, or
          search for MHRA Yellow Card in Google Play or the Apple App Store.
          Adverse events should also be reported to AstraZeneca by visiting
          <a href="http://contactazmedical.astrazeneca.com" target="_blank" rel="noreferrer"> contactazmedical.astrazeneca.com</a> or by calling 0800 783
          0033.
        </p>
      </div>

      <div className="common-text-block gray-color">
        <h3 className="primary">How can I manage side effects related to treatment?</h3>
        <p>Please note this is general advice for managing side effects and is not advice that is specific to olaparib.</p>
        <div className="title-row">
          <IconFeelSick className="treatment img-center icon" fill={Colors.primary} />

          <h4 className="primary">Feeling sick (nausea) and vomiting<span className="sup">5-7</span></h4>
        </div>

        <ul>
          <li>Eat smaller meals more frequently</li>
          <li>Eat plain foods, with a balance of:</li>
          <ol type="a">
            <li>Protein (e.g. plain yoghurt or plain chicken)</li>
            <li>Fruits and vegetables (e.g. bananas, tinned fruit)</li>
            <li>Carbohydrates (e.g. crackers, pasta, plain white rice,
              boiled potatoes, white toast)
            </li>
          </ol>

          <li>Take ginger (e.g. as a tea)</li>
          <li>Avoid fried foods</li>
          <li>Drink water to keep hydrated, or try drinking a clear broth or fruit</li>
          <li>juices such as cranberry juice (avoid grapefruit juice)</li>
          <li>Try not to drink too many fluids before you eat</li>
        </ul>

        <div className="title-row">
          <IconAppetite className="treatment img-center icon" fill={Colors.primary} />

          <h4 className="primary">Loss of appetite<span className="sup">8</span></h4>
        </div>

        <ul>
          <li>Eat smaller meals more frequently</li>
          <li>Sip nourishing drinks throughout the day</li>
        </ul>

        <div className="title-row">
          <IconDiarrhoea className="treatment img-center icon" fill={Colors.primary} />

          <h4 className="primary">Diarrhoea<span className="sup">9,10,11</span></h4>
        </div>

        <ul>
          <li>Things to eat:</li>
          <ol type="a">
            <li>Small, frequent meals made from light foods, e.g. white bread,
              pasta or rice, white fish, poultry, and well-cooked eggs
            </li>
          </ol>

          <li>Drink plenty of fluids - it is very important to stay hydrated.
            As well as water, you could try clear soups, fruit juices
            (avoid grapefruit juice) or caffeine-free herbal teas
          </li>
          <li>Things to avoid include alcohol, live yoghurt, greasy or spicy foods,
            caffeine and raw fruits or vegetables
          </li>
        </ul>

        <p className="primary">
          If your symptoms persist for more than 12 hours, despite following
          the advice you have been given, you should contact your cancer care team
        </p>

        <div className="title-row">
          <IconAnaemia className="treatment img-center icon" fill={Colors.primary} />

          <h4 className="primary">Anaemia<span className="sup">12,14</span></h4>
        </div>

        <ul>
          <li>Anaemia is the name for when your red blood cell count
            falls below a certain level
          </li>
          <li>Some of the symptoms of anaemia are breathlessness/difficulty catching
            your breath even when resting, pale
            skin, having a fast heart beat and feeling very tired. If you notice any of
            these symptoms, you should tell your doctor or nurse straight away
          </li>
        </ul>

        <p className="primary">
          If they suspect you have anaemia, they will give you a blood test. If you
          are diagnosed, your cancer care team will advise you on how to manage this
        </p>

        <div className="title-row">
          <IconTiredness className="treatment img-center icon" />

          <h4 className="primary">Unusual levels of tiredness (fatigue)<span className="sup">13</span></h4>
        </div>

        <ul>
          <li>Spread activities throughout the week</li>
          <li>Plan important activities for times when you have the most energy</li>
          <li>Prioritise what you need to do on a given day, and space out
            active periods with more restful ones
          </li>
          <li>Short naps and rests can help, but try to balance them with
            some activity or exercise
          </li>
          <li>Ask others for help with everyday tasks such as shopping, cooking or cleaning</li>
          <li>Eat a balanced diet and drink plenty of water</li>
          <li>Take light exercise, such as a short walk</li>
          <li>Do not drive or operate machinery while fatigued</li>
        </ul>

        <p className="primary">
          If your symptoms persist, contact your cancer care team
        </p>

      </div>

    </div>
  </>
);
const getLynparzaPage5 = () => (
  <>
    <div className="common-text-block">
      <h3 id="key-signs-and-symptoms-of-serious-side-effects">
        Pregnancy and use during olaparib treatment
      </h3>

      <div className="title-row">
        <IconQuestionmarkCircle className="treatment img-center icon" fill={Colors.primary} />
        <h4 className="primary">Can I take olaparib if I am pregnant?<span className="sup">14</span></h4>
      </div>
      <div className="content-block">

        <p>
          You should not take olaparib if you are pregnant or might become pregnant. You should
          have a pregnancy test at regular intervals during treatment and 6 months after your last
          dose. If you are pregnant or plan to become pregnant, you should immediately tell your
          doctor or prescriber. Olaparib can harm your unborn baby and may cause loss of pregnancy
          (miscarriage).
        </p>
      </div>

      <div className="title-row">
        <IconQuestionmarkCircle className="treatment img-center icon" fill={Colors.primary} />
        <h4 className="primary">Should I take contraception while on olaparib?<span className="sup">14</span></h4>
      </div>
      <div className="content-block">

        <p>
          Females who are able to become pregnant should take contraception while taking this
          medicine and for 6 months after the last dose. Two highly effective and complementary
          forms of contraception are recommended. Please tell your doctor if you are taking a
          hormonal contraceptive, as your doctor may recommend the addition of a non-hormonal contraceptive method.
        </p>
        <p>
          If you are a man being treated with olaparib, you must use contraception with a female
          partner, whilst taking olaparib and for 3 months after taking the last dose, as it is unknown
          whether olaparib passes into semen.
        </p>
      </div>

      <div className="title-row">
        <IconQuestionmarkCircle className="treatment img-center icon" fill={Colors.primary} />
        <h4 className="primary">Can I take olaparib while breastfeeding?<span className="sup">14</span></h4>
      </div>
      <div className="content-block">

        <p>
          You should not breastfeed during treatment with olaparib and for one month after receiving
          the last dose, as it is not known if olaparib passes into your breast milk. Talk to your doctor
          or prescriber about the best way to feed your baby during this time.
        </p>
      </div>
    </div>
  </>
);

const ref = {
  link1: 'https://ghr.nlm.nih.gov/primer/basics/gene',
  link2: 'https://ghr.nlm.nih.gov/gene/BRCA1#',
  link3: 'https://ghr.nlm.nih.gov/gene/BRCA2',
  link4: 'https://www.nationalbreastcancer.org/what-is-brca',
  link5: 'https://www.macmillan.org.uk/cancer-information-and-support/impacts-of-cancer/nausea-and-vomiting',
  link6: 'https://www.macmillan.org.uk/cancer-information-and-support/impacts-of-cancer/eating-problems',
  link7: 'https://www.cancer.gov/about-cancer/treatment/side-effects/nausea',
  link8: 'https://www.macmillan.org.uk/cancer-information-and-support/impacts-of-cancer/eating-problems',
  link9: 'https://www.cancer.gov/about-cancer/treatment/side-effects/diarrhea',
  link10: 'https://www.macmillan.org.uk/cancer-information-and-support/impacts-of-cancer/bladder-and-bowel-problems',
  link11: 'https://www.cancer.org/cancer/managing-cancer/side-effects/low-blood-counts/anemia.html',
  link12: 'https://www.macmillan.org.uk/cancer-information-and-support/impacts-of-cancer/tiredness',
};

const getLynparzaPage6 = () => (
  <>
    <div className="common-text-block">
      <h3 id="references">References</h3>
      <div className="content-block">
        <ol className="gray-color">
          <li>LYNPARZA (olaparib) 100 mg and 150 mg film-coated tablets. Summary of Product Characteristics</li>

          <li>MedlinePlus. What is a gene? Available at: <a href={`${ref.link1}`} target="_blank" rel="noreferrer">{`${ref.link1}`}</a>. National Institutes of Health. Accessed: May 2024.</li>

          <li>MedlinePlus. BRCA1 gene. Available at: <a href={`${ref.link2}`} target="_blank" rel="noreferrer">{`${ref.link2}`}</a>. Accessed: May 2024.</li>

          <li>MedlinePlus. BRCA2 gene. Available at: <a href={`${ref.link3}`} target="_blank" rel="noreferrer">{`${ref.link3}`}</a>. Accessed: May 2024.</li>

          <li>National Breast Cancer Foundation Inc. BRCA: The Breast Cancer Gene. Available at: <a href={`${ref.link4}`} target="_blank" rel="noreferrer">{`${ref.link4}`}</a>. Accessed: May 2024.
          </li>

          <li>Nausea and vomiting. Available at: <a href={`${ref.link5}`} target="_blank" rel="noreferrer">{`${ref.link5}`}</a>. (Accessed May 2024)
          </li>

          <li>If you are feeling sick. Available at: <a href={`${ref.link6}`} target="_blank" rel="noreferrer">{`${ref.link6}`}</a>. (Accessed May 2024)
          </li>

          <li>Nausea and Vomiting and Cancer Treatment - Side Effects. National Cancer Institute
            Available at: <a href={`${ref.link7}`} target="_blank" rel="noreferrer">{`${ref.link7}`}</a>. (Accessed May 2024)
          </li>

          <li>If you have changes in appetite. Available at: <a href={`${ref.link8}`} target="_blank" rel="noreferrer">{`${ref.link8}`}</a>. (Accessed May 2024)
          </li>

          <li>Diarrhea and Cancer Treatment - Side Effects. National Cancer Institute Available at: <a href={`${ref.link9}`} target="_blank" rel="noreferrer">{`${ref.link9}`}</a>. (Accessed
            May 2024)
          </li>

          <li>Bladder and bowel problems. Available at: <a href={`${ref.link10}`} target="_blank" rel="noreferrer">{`${ref.link10}`}</a> (Accessed May 2024)
          </li>

          <li>Anemia in People With Cancer. Available at: <a href={`${ref.link11}`} target="_blank" rel="noreferrer">{`${ref.link11}`}</a>. (Accessed May 2024)
          </li>

          <li>Tiredness (fatigue). Available at: <a href={`${ref.link12}`} target="_blank" rel="noreferrer">{`${ref.link12}`}</a>. (Accessed May 2024)
          </li>

          <li>AstraZeneca. Package Leaflet: Information for the Patient. (Accessed May 2024)</li>
        </ol>
      </div>
    </div>
  </>
);

export const getTreatmentGuideContent = (drugModule: TDrugModule, page: number) => {
  if (drugModule === 'enhertu') {
    switch (page) {
      case 1:
        return getEnhertuPage1();
      case 2:
        return getEnhertuPage2();
      case 3:
        return getEnhertuPage3();
      case 4:
        return getEnhertuPage4();
      case 5:
        return getEnhertuPage5();
      case 6:
        return getEnhertuPage6();
      case 7:
        return getEnhertuPage7();
      default:
        return null;
    }
  }
  if (drugModule === 'lynparza') {
    switch (page) {
      case 1:
        return getLynparzaPage1();
      case 2:
        return getLynparzaPage2();
      case 3:
        return getLynparzaPage3();
      case 4:
        return getLynparzaPage4();
      case 5:
        return getLynparzaPage5();
      case 6:
        return getLynparzaPage6();
      default:
        return null;
    }
  }
  return null;
};

export const treatmentGuideNavigation = {
  enhertu: [
    { page: 1, title: 'About your Treatment' },
    { page: 2, title: 'Trastuzumab deruxtecan and HER2+ metastatic breast cancer explained' },
    { page: 3, title: 'How you will receive your trastuzumab deruxtecan treatment' },
    { page: 4, title: 'Side effects that you may experience during treatment' },
    { page: 5, title: 'What are the key signs and symptoms of serious side effects to watch out for?' },
    { page: 6, title: 'Pregnancy, breastfeeding and contraceptive use during trastuzumab deruxtecan treatment' },
    { page: 7, title: 'References' },
  ],
  lynparza: [
    { page: 1, title: 'About your Treatment' },
    { page: 2, title: 'Olaparib and gBRCAm early stage breast cancer' },
    { page: 3, title: 'How you will receive your olaparib treatment' },
    { page: 4, title: 'Side effects that you may experience during treatment' },
    { page: 5, title: 'Pregnancy during olaparib treatment' },
    { page: 6, title: 'References' },
  ],
};

export const treatmentGuidePDF = {
  enhertu: { title: 'View PDF', link: 'https://www.medicines.org.uk/emc/files/pil.12135.pdf' },
  lynparza: { title: 'View PDF', link: 'https://www.medicines.org.uk/emc/files/pil.9204.pdf' },
};
