import { Reducer } from 'redux';

import * as utils from './utils';
import * as actionTypes from './actionTypes';
import {
  HospitalsState,
  HospitalsActionTypes,
  HospitalsAddActionType,
  HospitalsReplaceActionType,
} from './types';

import { hospitals } from '../../../data/system-document.json';

const initialState = hospitals as HospitalsState;

const reducer: Reducer<HospitalsState, HospitalsActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD: return utils.add(state, action as HospitalsAddActionType);
    case actionTypes.REPLACE: return utils.replace(state, action as HospitalsReplaceActionType);
    case actionTypes.CLEAR: return utils.clear();
    default: return state;
  }
};

export default reducer;
