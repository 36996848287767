/* eslint react/require-default-props: 0 */
import React, { ReactNode, memo } from 'react';
import { Text as RNText, LayoutChangeEvent as RNLayoutChangeEvent } from 'react-native';
import PropTypes from 'prop-types';

import { TPointerEvents } from '../../../types';
import { PointerEventsPropType } from '../../../propTypes';

import styles from './styles';

export type TextProps = {
  type?: string;
  numberOfLines?: number;
  children?: ReactNode;
  style?: object;
  allowFontScaling?: boolean;
  onPress?: () => void;
  pointerEvents?: TPointerEvents;
  onTextLayout?: (event: RNLayoutChangeEvent) => void,
};

const propTypes = {
  type: PropTypes.oneOf([
    'alphabet',
    'body',
    'body-light',
    'body-lighter',
    'body-accent',
    'body-contrast',
    'body-error',
    'button',
    'button-accent',
    'button-accent-secondary',
    'button-contrast',
    'divider',
    'heading',
    'heading-accent',
    'heading-large',
    'heading-large-contrast',
    'heading-contrast',
    'heading-light',
    'heading-secondary',
    'heading-secondary-contrast',
    'navigation',
    'navigation-contrast',
    'navigation-emphasized',
    'navigation-emphasized-contrast',
    'numeric-input',
  ]),
  numberOfLines: PropTypes.number,
  children: PropTypes.node.isRequired,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  allowFontScaling: PropTypes.bool,
  onPress: PropTypes.func,
  pointEvents: PointerEventsPropType,
  onTextLayout: PropTypes.func,
};

const Text = (props: TextProps) => {
  const {
    type = 'body',
    children,
    style,
    allowFontScaling = true,
    onPress = undefined,
    ...custom
  } = props;

  return (
    <RNText
      style={[styles[type], style]}
      allowFontScaling={allowFontScaling}
      onPress={onPress}
      {...custom as any} // eslint-disable-line react/jsx-props-no-spreading
    >
      {children}
    </RNText>
  );
};

Text.whyDidYouRender = true;

Text.propTypes = propTypes;

export default memo(Text);
