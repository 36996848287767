import { Platform, StyleSheet as RNStyleSheet } from 'react-native';

import Colors from '../../theme/Colors';

export default RNStyleSheet.create({
  container: {
    paddingLeft: 17,
    paddingRight: 11,
    paddingTop: 8,
    paddingBottom: 12,
  },
  inputContainer: {
    height: 60,
    paddingHorizontal: 24,
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
    borderWidth: 2,
    borderColor: Colors.lightPrimary,

  },
  input: {
    height: 60,
    minWidth: 60,
    fontFamily: 'Avenir-Heavy',
    fontVariant: ['tabular-nums'],
    fontWeight: '800',
    color: Colors.black,
    fontSize: 28,
    top: Platform.OS === 'ios' ? 0 : 4,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 12,
  },
  units: {
    marginLeft: 4,
    top: Platform.OS === 'ios' ? 0 : 2,
  },
});
