import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import '../css/TreatmentGuide.css';
import { TDrugModule } from '../../types';

type Params = {
  drugModule: TDrugModule;
};

const DrugModuleBreadcrumbs = ({ drugModule } :Params) => {
  const { t } = useTranslation();

  return (
    <nav className="drug-module-breadcrumbs">
      <NavLink className="heading" to={{ pathname: `/support-menu/${drugModule}`, state: '/support-menu/' }} exact>&lt; {t('back-to')} {t(`contentModules:drug:${drugModule}:my-research-navigation-title`)}</NavLink>
    </nav>
  );
};

export default DrugModuleBreadcrumbs;
