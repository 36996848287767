import { Reducer } from 'redux';

import * as actionTypes from './actionTypes';
import { AuthState, AuthActionTypes, AuthSetActionType } from './types';

const initialState: AuthState = false;

const reducer: Reducer<AuthState, AuthActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET: {
      const { payload } = action as AuthSetActionType;

      return payload;
    }
    default: return state;
  }
};

export default reducer;
