/* eslint react/require-default-props: 0 */
import React, { ReactNode, useState, useRef } from 'react';
import { View as RNView, TextInput as RNTextInput, TouchableWithoutFeedback as RNTouchableWithoutFeedback } from 'react-native';
import { useTranslation } from 'react-i18next';
import toString from 'lodash/toString';
import size from 'lodash/size';
import endsWith from 'lodash/endsWith';
import replace from 'lodash/replace';
import includes from 'lodash/includes';
import split from 'lodash/split';
import last from 'lodash/last';
import toNumber from 'lodash/toNumber';

import Text from '../Text';
import Divider from '../Divider';
import styles from './styles';
import Colors from '../../theme/Colors';
import count from '../../utils/count';
import focusRef from '../../utils/focusRef';

type Props = {
  labelText: string;
  labelUnit: string;
  onValueChange: (value: number) => void;
  value: number;
  maximumValue: number;
  headerButton: ReactNode;
  decimals?: number;
  divider?: boolean;
};

const PSALevelInput = (props: Props) => {
  const {
    labelText,
    labelUnit,
    onValueChange,
    value,
    maximumValue,
    headerButton,
    decimals: propsDecimals = 1,
    divider = true,
  } = props;

  const { t } = useTranslation();

  const [internalValue, setInternalValue] = useState(toString(value));
  const [error, setError] = useState('');

  const maxLength = size(toString(maximumValue)) + propsDecimals + 1;

  const handleTextChange = (text: string) => {
    const formattedText = replace(text, ',', '.');

    if (!formattedText) {
      setInternalValue('0');
      return onValueChange(0);
    }

    // Only allow one .
    if (count(formattedText, '.') > 1) {
      return false;
    }

    // Update internal value with partial decimal
    if (endsWith(formattedText, '.')) {
      return setInternalValue(formattedText);
    }

    let decimals = 0;

    // Get decimals
    if (includes(formattedText, '.')) {
      decimals = size(last(split(formattedText, '.')));
    }

    // Make sure we don't exceed max decimals passed via props
    if (decimals > 0 && decimals > propsDecimals) {
      return false;
    }

    let newValue = toString(parseFloat(formattedText));

    if (decimals > 0) {
      newValue = parseFloat(formattedText).toFixed(decimals);
    }

    // Set all values to maximumValue if we exceed maximumValue and display an error
    if (toNumber(newValue) > maximumValue) {
      setError(t('errors:max-value', { maxValue: maximumValue }));
      setInternalValue(toString(maximumValue));
      return onValueChange(maximumValue);
    }

    setError('');
    setInternalValue(newValue);
    return onValueChange(toNumber(newValue));
  };

  const inputRef = useRef(null);

  return (
    <>
      <RNView style={styles.header}>
        <Text type="heading" style={styles.label}>{labelText}</Text>
        {headerButton}
      </RNView>
      <RNTouchableWithoutFeedback onPress={() => focusRef(inputRef)}>
        <RNView
          style={[
            styles.row,
            {
              borderColor: error ? Colors.errorRed : Colors.lightPrimary,
            },
          ]}
        >
          <RNTextInput
            value={internalValue}
            onChangeText={handleTextChange}
            keyboardType="decimal-pad"
            selectionColor={Colors.primary}
            style={styles.input}
            maxLength={maxLength}
            allowFontScaling={false}
            ref={inputRef}
          />
          <Text style={styles.units}>{labelUnit}</Text>
        </RNView>
      </RNTouchableWithoutFeedback>
      {
        error
          ? <Text type="body-error" style={styles.error}>{error}</Text>
          : null
      }
      {divider ? <Divider /> : null}
    </>
  );
};

export default PSALevelInput;
