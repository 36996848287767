import React from 'react';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import first from 'lodash/first';
import find from 'lodash/find';
import classNames from 'classnames';

import { ProfileQuestionType } from '../../types';

import getLocalizedText from '../utils/getLocalizedText';
import defaultGet from '../utils/defaultGet';

type Props = {
  question: ProfileQuestionType,
  value: string,
  onChange: (value: string) => void;
};

const ProfileQuestionTNMInput = (props: Props) => {
  const { question, value, onChange } = props;

  const { i18n } = useTranslation();

  const localizedOptions = map(question.answers, (answer) => ({
    value: answer.id,
    labelText: getLocalizedText(answer, i18n.language, 'title'),
  }));

  const selectedAnswer = find(question.answers, { id: value });
  const description = getLocalizedText(selectedAnswer, i18n.language, 'description');
  const title = getLocalizedText(selectedAnswer, i18n.language, 'title');

  const lastButtonValue = defaultGet(first(localizedOptions), 'value', '');
  const lastButtonSelected = lastButtonValue === value;

  return (
    <section className="tnm-input">
      <section className="tnm-row">
        {map(localizedOptions, (option, index) => {
          if (index === 0) {
            return null;
          }

          const active = value === option.value;

          return (
            <button
              key={option.value}
              type="button"
              className={classNames('button contrast', { active })}
              onClick={() => onChange(option.value)}
            >
              {option.labelText}
            </button>
          );
        })}
        <button
          type="button"
          className={classNames('button contrast', { active: lastButtonSelected })}
          onClick={() => onChange(lastButtonValue)}
        >
          {defaultGet(first(localizedOptions), 'labelText', '')}
        </button>
      </section>
      {
        description
          ? (
            <p className="tnm-description">
              <span className="button">{`${title}: `}</span>
              <span className="body">{description}</span>
            </p>
          )
          : null
      }
    </section>
  );
};

export default ProfileQuestionTNMInput;
