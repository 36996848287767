import differenceInMinutes from 'date-fns/differenceInMinutes';

import { TState, TActions } from './types';

import * as actionTypes from './actionTypes';
import dateAddDuration from '../../utils/dateAddDuration';

const reducer = (state: TState, action: TActions): TState => {
  switch (action.type) {
    case actionTypes.UPDATE_TITLE: {
      const { payload } = action;

      return {
        ...state,
        title: payload,
      };
    }
    case actionTypes.UPDATE_LOCATION: {
      const { payload } = action;

      return {
        ...state,
        location: {
          ...state.location,
          ...payload,
        },
      };
    }
    case actionTypes.UPDATE_START_AT: {
      const { payload } = action;
      const { startAt, endAt: stateEndAt } = state;

      if (!payload) {
        return state;
      }

      const endAt = dateAddDuration(stateEndAt, differenceInMinutes(payload, startAt), 'minutes');

      return {
        ...state,
        startAt: payload,
        endAt,
      };
    }
    case actionTypes.UPDATE_END_AT: {
      const { payload } = action;

      if (!payload) {
        return state;
      }

      return {
        ...state,
        endAt: payload,
      };
    }
    case actionTypes.UPDATE_BODY: {
      const { payload } = action;

      return {
        ...state,
        body: payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
