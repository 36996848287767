import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AppState } from '../state/reducers';

import Modal from './Modal';
import { selectors as uiSelectors, actions as uiActions } from '../state/ui';
import defaultGet from '../utils/defaultGet';

const ValidateNavigationModal = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const history = useHistory();

  const modal = useSelector((state: AppState) => (
    uiSelectors.getValidateNavigationModal(state)
  ));

  const visible = defaultGet(modal, 'visible', false);
  const type = defaultGet(modal, 'type', '');

  const handleClose = () => {
    dispatch(uiActions.validateNavigationModalHide());
  };

  const handlePrimaryButtonClick = () => {
    handleClose();

    history.push('/');
  };

  if (!type) {
    return null;
  }

  let content = <></>;

  if (type === 'unavailableContent') {
    content = <h1 className="heading-large">{t(`modals.${type}.title`)}</h1>;
  }

  return (
    <Modal
      visible={visible}
      hideModal={handleClose}
      actions={[
        {
          title: t('close'),
          onClick: handlePrimaryButtonClick,
          primary: true,
        },
      ]}
      className="profile-completed"
    >
      {content}
    </Modal>
  );
};

export default ValidateNavigationModal;
