import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';

import { ProfileQuestionType } from '../../types';

import { selectors as tagsSelectors } from '../state/tags';
import { selectors as profileQuestionsSelectors } from '../state/profileQuestions';
import EMPTY_ARRAY from '../utils/empty-array';
import isOfType from '../utils/isOfType';
import includesAny from '../utils/includesAny';
import createDeepEqualSelector from './createDeepEqualSelector';

const getAllProfileQuestions = createDeepEqualSelector(
  [tagsSelectors.getAll, profileQuestionsSelectors.getAll],
  (tags, profileQuestions) => {
    if (!isOfType<ProfileQuestionType[]>(profileQuestions)) {
      return EMPTY_ARRAY;
    }

    // Filter profile questions by tags
    const filteredProfileQuestions = filter(profileQuestions, (question) => {
      if (isEmpty(question.tags)) {
        return false;
      }

      return includesAny(question.tags, tags);
    });

    return orderBy(filteredProfileQuestions, ['order']);
  },
);

export default getAllProfileQuestions;
