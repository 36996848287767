import cloneDeep from 'lodash/cloneDeep';
import { Reducer } from 'redux';

import * as actionTypes from './actionTypes';
import {
  SyncState,
  SyncActionTypes,
} from './types';

const initialState: SyncState = { lastLoadedUserData: null };

const reducer: Reducer<
SyncState,
SyncActionTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_USER_DATA:
    case actionTypes.UPLOADED_USER_DATA:
      return { ...state, lastLoadedUserData: cloneDeep(action.payload) };
    default: return state;
  }
};

export default reducer;
