import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { AppState } from '../state/reducers';
import { NoteType } from '../../types';

import { selectors as notesSelectors } from '../state/notes';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRightHeader from '../components/DrawerRightHeader';
import Note from '../components/Note';

type Params = {
  noteId: string;
};

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const NoteScreen = () => {
  const { t } = useTranslation();

  const { noteId } = useParams<Params>();

  const memoizedNoteGetById = useMemo(
    notesSelectors.makeGetById,
    [],
  );

  const note = (
    useSelector((state: AppState) => memoizedNoteGetById(state, noteId))
  ) as NoteType;

  return (
    <article className="page note row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('note')} showRightButton />
          <Note note={note} />
        </section>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default NoteScreen;
