const prefix = 'SYNC';

const USER_DATA = `${prefix}/USER_DATA`;
const UPDATE_USER_DATA = `${prefix}/UPDATE_USER_DATA`;
const UPLOADED_USER_DATA = `${prefix}/UPLOADED_USER_DATA`;

export {
  USER_DATA,
  UPDATE_USER_DATA,
  UPLOADED_USER_DATA,
};
