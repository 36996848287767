import { Reducer } from 'redux';

import * as actionTypes from './actionTypes';
import * as utils from './utils';
import {
  AppointmentsState,
  AppointmentsActionTypes,
  AppointmentsAddActionType,
  AppointmentsAddPhotosActionType,
  AppointmentsAddAudioRecordingsActionType,
  AppointmentsAddNotesActionType,
  AppointmentsRemoveActionType,
  AppointmentsRemovePhotosActionType,
  AppointmentsRemoveAudioRecordingsActionType,
  AppointmentsUpdateActionType,
} from './types';

const initialState: AppointmentsState = {};

const reducer: Reducer<
AppointmentsState,
AppointmentsActionTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD: return utils.add(state, action as AppointmentsAddActionType);
    case actionTypes.ADD_PHOTOS: return utils.addPhotos(
      state,
      action as AppointmentsAddPhotosActionType,
    );
    case actionTypes.ADD_AUDIO_RECORDINGS: return utils.addAudioRecordings(
      state,
      action as AppointmentsAddAudioRecordingsActionType,
    );
    case actionTypes.ADD_NOTES: return utils.addNotes(
      state,
      action as AppointmentsAddNotesActionType,
    );
    case actionTypes.REMOVE: return utils.remove(state, action as AppointmentsRemoveActionType);
    case actionTypes.REMOVE_PHOTOS: {
      return utils.removePhotos(
        state,
        action as AppointmentsRemovePhotosActionType,
      );
    }
    case actionTypes.REMOVE_AUDIO_RECORDINGS: {
      return utils.removeAudioRecordings(
        state,
        action as AppointmentsRemoveAudioRecordingsActionType,
      );
    }
    case actionTypes.UPDATE: return utils.update(state, action as AppointmentsUpdateActionType);
    case actionTypes.CLEAR: return utils.clear();
    default: return state;
  }
};

export default reducer;
