import { useTranslation } from 'react-i18next';
import React from 'react';
import { TDrugModule } from '../../types';
import Modal from './Modal';
import Button from './Button';

import { ReactComponent as LeaveCohortEnhSVG } from '../assets/icons/LeaveCohortEnh.svg';
import { ReactComponent as LeaveCohortLynSVG } from '../assets/icons/LeaveCohortLyn.svg';

import '../css/CohortLeaveModal.css';

interface CohortLeaveModalProps {
  drugModule: TDrugModule;
  openedCohortLeaveModal: boolean;
  toggleCohortLeaveModal: () => void;
  onPressYes: () => void;
  onPressNo: () => void;
}

const DrugModuleLeaveModal = (
  {
    drugModule, openedCohortLeaveModal, toggleCohortLeaveModal, onPressYes, onPressNo,
  }: CohortLeaveModalProps,
) => {
  const { t } = useTranslation();

  const LeaveIcon = drugModule === 'lynparza' ? LeaveCohortLynSVG : LeaveCohortEnhSVG;

  return (
    <Modal
      className="align-center drug-module-leave-modal"
      visible={openedCohortLeaveModal}
      hideModal={toggleCohortLeaveModal}
      actions={[
      ]}
    >
      <h1 className="heading">{t(`contentModules:drug:${drugModule}:modals:leave-modal:title`)}</h1>

      <LeaveIcon width={200} height={200} className="drug-module-leave-icon" />
      <p>{t(`contentModules:drug:${drugModule}:modals:leave-modal:body`)}</p>
      <p>{t('contentModules:drug:common:modals:leave-modal:body')}</p>
      <Button labelText={t('yes')} size="medium" onClick={onPressYes} />
      <button type="button" className="leave-modal-button primary cancel-button" onClick={onPressNo}>{t('cancel')}</button>
    </Modal>
  );
};

export default DrugModuleLeaveModal;
