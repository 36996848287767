import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';

import {
  ProfileQuestionAnswersState,
  ProfileQuestionAnswersAddActionType,
  ProfileQuestionAnswersUpdateActionType,
  ProfileQuestionAnswersDeleteByIdActionType,
} from './types';

const add = (state: ProfileQuestionAnswersState, action: ProfileQuestionAnswersAddActionType) => {
  const { payload } = action;

  const answers = keyBy(payload, 'id');

  return {
    ...state,
    ...answers,
  };
};

const update = (
  state: ProfileQuestionAnswersState,
  action: ProfileQuestionAnswersUpdateActionType,
) => {
  const { payload } = action;

  const answers = keyBy(payload, 'id');

  return {
    ...state,
    ...answers,
  };
};

const deleteById = (
  state: ProfileQuestionAnswersState,
  action: ProfileQuestionAnswersDeleteByIdActionType,
) => {
  const { payload } = action;

  return omit(state, [payload]);
};

const clear = () => ({});

export {
  add,
  clear,
  deleteById,
  update,
};
