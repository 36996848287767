/* eslint react/require-default-props: 0 */
import React, { memo, ReactNode } from 'react';
import PropTypes from 'prop-types';
import {
  Platform as RNPlatform,
  TouchableOpacity as RNTouchableOpacity,
  TouchableNativeFeedback as RNTouchableNativeFeedback,
  TouchableHighlight as RNTouchableHighlight,
  StyleProp as RNStyleProp,
  ViewStyle as RNViewStyle,
} from 'react-native';

import Colors from '../../theme/Colors';
import Constants from '../../theme/Constants';

type Props = {
  children: ReactNode;
  style?: RNStyleProp<RNViewStyle>;
  rippleColor?: string;
  borderlessRipple?: boolean;
  underlayColor?: string;
  opacity?: boolean;
  activeOpacity?: number;
  onPress?: () => void;
  onPressIn?: () => void;
  onLongPress?: () => void;
  disabled?: boolean;
};

const propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  rippleColor: PropTypes.string,
  borderlessRipple: PropTypes.bool,
  underlayColor: PropTypes.string,
  opacity: PropTypes.bool,
  activeOpacity: PropTypes.number,
  onPress: PropTypes.func,
  onPressIn: PropTypes.func,
  onLongPress: PropTypes.func,
  disabled: PropTypes.bool,
};

const Touchable = (props: Props) => {
  const {
    children,
    style,
    rippleColor = Colors.lightGray,
    underlayColor = Colors.softGray,
    borderlessRipple = false,
    opacity = false,
    activeOpacity = 0.5,
    onPress,
    onPressIn,
    onLongPress,
    ...custom
  } = props;

  if (opacity) {
    return (
      <RNTouchableOpacity
        style={style}
        activeOpacity={activeOpacity}
        onPress={onPress}
        onPressIn={onPressIn}
        onLongPress={onLongPress}
        {...custom} // eslint-disable-line react/jsx-props-no-spreading
      >
        {children}
      </RNTouchableOpacity>
    );
  }

  if (Constants.isIOS()) {
    return (
      <RNTouchableHighlight
        style={style}
        underlayColor={underlayColor}
        onPress={onPress}
        onPressIn={onPressIn}
        onLongPress={onLongPress}
        {...custom} // eslint-disable-line react/jsx-props-no-spreading
      >
        {children}
      </RNTouchableHighlight>
    );
  }

  return (
    <RNTouchableNativeFeedback
      background={
        RNPlatform.Version >= 21
          ? RNTouchableNativeFeedback.Ripple(rippleColor, borderlessRipple)
          : RNTouchableNativeFeedback.SelectableBackground()
      }
      onPress={onPress}
      onPressIn={onPressIn}
      onLongPress={onLongPress}
      {...custom} // eslint-disable-line react/jsx-props-no-spreading
    >
      {children}
    </RNTouchableNativeFeedback>
  );
};

Touchable.propTypes = propTypes;

export default memo(Touchable);
