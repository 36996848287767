const prefix = 'DRAFTS';

const SET = `${prefix}/SET`;
const UNSET = `${prefix}/UNSET`;
const CLEAR = `${prefix}/CLEAR`;

export {
  SET,
  UNSET,
  CLEAR,
};
