/* eslint react/require-default-props: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Path } from 'react-native-svg';
import ResizeableSVG from '../ResizeableSVG';

import Colors from '../../theme/Colors';
import Constants from '../../theme/Constants';

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

type Props = {
  color?: string;
  size?: number;
};

const AngiogenesisInhibitors = (props: Props) => {
  const {
    color = Colors.primary,
    size = Constants.defaultIconSize,
  } = props;

  return (
    <ResizeableSVG size={size}>
      <Path d="m13.6661 19.004c3.2700104 0 6.002699 2.4673758 6.3555564 5.6944795.0450226.4117595-.2522764.7820544-.6640359.8270769-.4117595.0450226-.7820544-.2522764-.8270769-.6640359-.2699145-2.4685378-2.3620164-4.3575205-4.8644436-4.3575205-2.5032952 0-4.59551495 1.888853-4.86544356 4.3575205-.04502253.4117595-.41531749.7090585-.82707694.6640359-.41175946-.0450225-.70905847-.4153174-.66403594-.8270769.35287592-3.227273 3.08571854-5.6944795 6.35655644-5.6944795zm10.8594564-9.8313205c.0450226.41175945-.2522764.78205441-.6640359.82707694-2.4685378.26991446-4.3575205 2.36201636-4.3575205 4.86444356 0 2.5032952 1.888853 4.5955149 4.3575205 4.8654436.4117595.0450225.7090585.4153174.6640359.8270769-.0450225.4117595-.4153174.7090585-.8270769.6640359-3.227273-.3528759-5.6944795-3.0857185-5.6944795-6.3565564 0-3.2700104 2.4673758-6.00269903 5.6944795-6.35555644.4117595-.04502253.7820544.25227648.8270769.66403594zm-20.79574768-.86739174.10191178.0042558c3.22727299.35287592 5.6944795 3.08571854 5.6944795 6.35655644 0 3.2700104-2.46737577 6.002699-5.6944795 6.3555564-.41175945.0450226-.78205441-.2522764-.82707694-.6640359s.25227648-.7820544.66403594-.8270769c2.46853783-.2699145 4.3575205-2.3620164 4.3575205-4.8644436 0-2.5032952-1.88885302-4.5955149-4.3575205-4.86544356-.41175946-.04502253-.70905847-.41531749-.66403594-.82707694.04127065-.37744617.3558605-.65871377.72516516-.66829174zm15.72487138-4.30529922.1019119.0042519c.4117612.0450068.7090743.41529039.6640676.82705157-.3528419 3.22810323-3.085526 5.69550799-6.3565596 5.69550799-3.2702061 0-6.00273621-2.46757404-6.35555956-5.69550799-.04500679-.41176118.25230638-.78204477.66406755-.82705157.41176118-.04500679.78204477.25230638.82705157.66406755.26991044 2.46937482 2.36187184 4.35849201 4.86444044 4.35849201 2.5034367 0 4.5955158-1.88898751 4.8654404-4.35849201.0412563-.37744774.3558354-.65872737.7251397-.66831945z" fill={color} fillRule="evenodd" />
    </ResizeableSVG>
  );
};

AngiogenesisInhibitors.propTypes = propTypes;

export default AngiogenesisInhibitors;
