/* eslint react/require-default-props: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Path } from 'react-native-svg';
import ResizeableSVG from '../ResizeableSVG';

import Colors from '../../theme/Colors';
import Constants from '../../theme/Constants';

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

type Props = {
  color?: string;
  size?: number;
};

const Warning = (props: Props) => {
  const {
    color = Colors.primary,
    size = Constants.defaultIconSize,
  } = props;

  return (
    <ResizeableSVG size={size}>
      <Path d="m16.322137 4.03688279 9.3627092 17.38820251c.4399648.8171604.4179917 1.8054368-.0578576 2.6022352-.475892.7968699-1.3356424 1.2849698-2.2637445 1.2849698h-18.72648822c-.92810203 0-1.78785247-.4880999-2.26374449-1.2849698-.47584926-.7967984-.49782243-1.7850748-.05785763-2.6022352l9.36281204-17.38839341c.9961612-1.84895433 3.6479072-1.84895433 4.6441712.0001909zm-3.0963589.83347772-9.36269469 17.38817559-.00974611.0186318c-.13648323.268669-.12596499.5891898.02904651.848753.15864607.265649.44518411.4283232.75437207.4283232h18.72648822c.309188 0 .595726-.1626742.7543721-.4283232.1586164-.2655995.1659408-.595025.0193004-.8673848l-9.3625919-17.38798469c-.3322625-.61670545-1.2163869-.61670545-1.5485466-.0001909zm.7742219 14.67334069c.6068388 0 1.0987789.4919401 1.0987789 1.0987789s-.4919401 1.0987789-1.0987789 1.0987789-1.0987789-.4919401-1.0987789-1.0987789.4919401-1.0987789 1.0987789-1.0987789zm.024771-10.0980263.0212279.00056608c.3076935.01192598.5991353.14225468.8132316.36411616.2278924.23615796.3485697.55568416.333624.88493036l-.3148001 6.6998041c-.0524833 1.1169869-1.703553 1.1170386-1.7561062.000055l-.3157308-6.7116613c-.011808-.322113.1070779-.6353238.3296478-.86847358.2225399-.23311831.5298357-.36640004.8520854-.36965031z" fill={color} fillRule="evenodd" />
    </ResizeableSVG>
  );
};

Warning.propTypes = propTypes;

export default Warning;
