import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';

import Navigation from '../components/Navigation';
import DrawerButtons from '../components/DrawerButtons';
import Footer from '../components/Footer';
import Button from '../components/Button';
import Drawer from '../components/Drawer';
import DrawerRightHeader from '../components/DrawerRightHeader';
import { actions as questionnairesActions } from '../state/questionnaires';
import getUnixTime from '../utils/getUnixTime';

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const QuestionnaireAdd = () => {
  const { t } = useTranslation();

  const [title, setTitle] = useState('');

  const dispatch = useDispatch();

  const history = useHistory();

  const handleSave = () => {
    if (!title.trim()) {
      return false;
    }

    const id = uuidv4();

    const questionnaire = {
      title,
      createdAt: getUnixTime(),
      id,
      questions: [],
    };

    dispatch(questionnairesActions.add([questionnaire]));

    return history.push(`/questionnaires/${id}`);
  };

  return (
    <article className="page questionnaires row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('add-questionnaire')} showRightButton />
          <section className="diary-item">
            <label htmlFor="title">
              <span className="body light">{t('title')}</span>
              <input
                value={title}
                onChange={(event) => setTitle(event.currentTarget.value)}
                name="title"
                id="title"
              />
            </label>
            <Button
              labelText={t('save')}
              onClick={handleSave}
              disabled={!title.trim()}
            />
          </section>
        </section>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default QuestionnaireAdd;
