import get from 'lodash/get';
import map from 'lodash/map';
import toArray from 'lodash/toArray';
import size from 'lodash/size';
import filter from 'lodash/filter';
import slice from 'lodash/slice';
import orderBy from 'lodash/orderBy';
import compact from 'lodash/compact';
import concat from 'lodash/concat';
import uniq from 'lodash/uniq';
import includes from 'lodash/includes';
import isNil from 'lodash/isNil';
import isSameDay from 'date-fns/isSameDay';
import isSameWeek from 'date-fns/isSameWeek';
import isSameMonth from 'date-fns/isSameMonth';

import { AppState } from '../reducers';
import { AudioRecordingsState } from './types';
import {
  TDate,
  TAudioRecordingReturn,
  TAudioRecordingsReturn,
  TSortOrder,
} from '../../../types';

import EMPTY_OBJECT from '../../utils/empty-object';
import EMPTY_ARRAY from '../../utils/empty-array';
import toDate from '../../utils/toDate';
import createDeepEqualSelector from '../../selectors/createDeepEqualSelector';

const sort = (
  collection: TAudioRecordingsReturn | AudioRecordingsState,
  key = 'createdAt',
  order = 'desc' as TSortOrder,
): TAudioRecordingsReturn => (
  orderBy(collection, [key], [order])
);

const getByIdSelector = (state: AudioRecordingsState, id: string): TAudioRecordingReturn => (
  get(state, [id]) || EMPTY_OBJECT
);

const makeGetById = () => (
  createDeepEqualSelector(
    (state: AppState) => state.audioRecordings,
    (state: any, id: string) => id,
    getByIdSelector,
  )
);

const getByIdsSelector = (
  state: AudioRecordingsState,
  ids: string[] | typeof EMPTY_ARRAY,
): TAudioRecordingsReturn => (
  sort(filter(state, (audioRecording) => includes(ids, audioRecording.id)))
);

const makeGetByIds = () => (
  createDeepEqualSelector(
    (state: AppState) => state.audioRecordings,
    (state: any, ids: string[] | typeof EMPTY_ARRAY) => ids,
    getByIdsSelector,
  )
);

const getByDateSelector = (
  state: AudioRecordingsState,
  date?: TDate,
): TAudioRecordingsReturn => (
  sort(
    filter(
      state,
      (audioRecording) => isSameDay(toDate(audioRecording.createdAt), toDate(date)),
    ),
  )
);

const makeGetByDate = () => (
  createDeepEqualSelector(
    (state: AppState) => state.audioRecordings,
    (state: any, date: TDate) => date,
    getByDateSelector,
  )
);

const getByWeekSelector = (state: AudioRecordingsState, date: TDate): TAudioRecordingsReturn => (
  sort(
    filter(
      state,
      (audioRecording) => isSameWeek(toDate(audioRecording.createdAt), toDate(date)),
    ),
  )
);

const makeGetByWeek = () => (
  createDeepEqualSelector(
    (state: AppState) => state.audioRecordings,
    (state: any, date: TDate) => date,
    getByWeekSelector,
  )
);

const getByMonthSelector = (
  state: AudioRecordingsState,
  date?: TDate,
): TAudioRecordingsReturn => (
  sort(
    filter(
      state,
      (audioRecording) => isSameMonth(toDate(audioRecording.createdAt), toDate(date)),
    ),
  )
);

const makeGetByMonth = () => (
  createDeepEqualSelector(
    (state: AppState) => state.audioRecordings,
    (state: any, date: TDate) => date,
    getByMonthSelector,
  )
);

const getAllSelector = (state: AudioRecordingsState): TAudioRecordingsReturn => (
  sort(toArray(state))
);

const getAll = createDeepEqualSelector(
  (state: AppState) => state.audioRecordings,
  getAllSelector,
);

const getAllByViewAndDateSelector = (state: AudioRecordingsState, view = 'all', date: TDate): TAudioRecordingsReturn => {
  switch (view) {
    case 'all': return getAllSelector(state);
    case 'day': return getByDateSelector(state, date);
    case 'week': return getByWeekSelector(state, date);
    case 'month': return getByMonthSelector(state, date);
    default:
  }

  return getAllSelector(state);
};

const getAllByViewAndDate = createDeepEqualSelector(
  (state: AppState) => state.audioRecordings,
  (state: any, view: string) => view,
  (state: any, view: string, date: TDate) => date,
  getAllByViewAndDateSelector,
);

const makeGetAllByViewAndDate = () => (
  createDeepEqualSelector(
    (state: AppState) => state.audioRecordings,
    (state: any, view: string) => view,
    (state: any, view: string, date: TDate) => date,
    getAllByViewAndDateSelector,
  )
);

const getSelector = (state: AudioRecordingsState, limit = 2): TAudioRecordingsReturn => (
  slice(sort(state), 0, limit)
);

const makeGet = () => (
  createDeepEqualSelector(
    (state: AppState) => state.audioRecordings,
    (state: any, limit: number) => limit,
    getSelector,
  )
);

const getAllUrisSelector = (state: AudioRecordingsState) => (
  uniq(compact(concat(map(state, 'uri'), map(state, 'source.uri'))))
);

const getAllUris = createDeepEqualSelector(
  (state: AppState) => state.audioRecordings,
  getAllUrisSelector,
);

const countSelector = (state: AudioRecordingsState) => size(state);

const count = createDeepEqualSelector(
  (state: AppState) => state.audioRecordings,
  countSelector,
);

const getLastUpdatedSelector = (
  state: AudioRecordingsState,
  limit = 2,
): TAudioRecordingsReturn => {
  const updatedAudioRecordings = filter(state, ({ updatedAt }) => !isNil(updatedAt));

  return slice(sort(updatedAudioRecordings, 'updatedAt'), 0, limit);
};

const makeGetLastUpdated = () => (
  createDeepEqualSelector(
    (state: AppState) => state.audioRecordings,
    (state: any, limit: number) => limit,
    getLastUpdatedSelector,
  )
);

export {
  makeGetById,
  makeGetByIds,
  makeGetByDate,
  makeGetByWeek,
  makeGetByMonth,
  makeGet,
  getAll,
  getAllByViewAndDate,
  makeGetAllByViewAndDate,
  getAllUris,
  count,
  makeGetLastUpdated,
};
