import { combineReducers, Reducer } from 'redux';
import * as utils from './utils';
import * as actionTypes from './actionTypes';
import {
  TestFitnessActionTypes,
  TestFitnessAddActionType, TestFitnessClearActionType,
  TestFitnessState,
} from './types';

const testFitnessState: TestFitnessState = {
  enhertu: [],
  lynparza: [],
};

const testFitness: Reducer<TestFitnessState, TestFitnessActionTypes> = (
  state: TestFitnessState = testFitnessState,
  action: TestFitnessActionTypes,
) => {
  switch (action.type) {
    case actionTypes.ADD_TEST_FITNESS_DATA:
      return utils.addTestFitnessData(state, action as TestFitnessAddActionType);
    case actionTypes.CLEAR:
      return utils.clearDrugModuleTrends(state, action as TestFitnessClearActionType);
    default: return state;
  }
};

const reducer = combineReducers({
  testFitness,
});

export default reducer;
