import * as actionTypes from './actionTypes';
import { ProfileQuestionCategoriesActionTypes, ProfileQuestionCategoriesReplacePayload } from './types';

const replace = (
  payload: ProfileQuestionCategoriesReplacePayload,
): ProfileQuestionCategoriesActionTypes => ({
  type: actionTypes.REPLACE,
  payload,
});

const clear = (): ProfileQuestionCategoriesActionTypes => ({
  type: actionTypes.CLEAR,
});

export {
  replace,
  clear,
};
