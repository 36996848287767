import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Icon from 'react-native-vector-icons/dist/Ionicons';
import classNames from 'classnames';
import startCase from 'lodash/startCase';
import includes from 'lodash/includes';

import { AppState } from '../state/reducers';

import '../css/Header.css';
import logo from '../assets/images/logo.svg';
import Sync from './Sync';
import { actions as authActions } from '../state/auth';
import { selectors as messagesSelectors } from '../state/messages';
import Colors from '../theme/Colors';

type Props = {
  auth?: boolean;
  documentTitle?: string;
};

const propTypes = {
  auth: PropTypes.bool,
  documentTitle: PropTypes.string,
};

const Header = (props: Props) => {
  const { auth, documentTitle } = props;

  const messageCountUnread = useSelector(
    (state: AppState) => messagesSelectors.unread(state),
  );

  const dispatch = useDispatch();

  const { t } = useTranslation();
  const logout = () => {
    dispatch(authActions.logout());
  };

  const { pathname } = useLocation();

  useEffect(() => {
    if (documentTitle) {
      document.title = `OWise - ${documentTitle}`;
    }
  }, []);

  return (
    <header className="app-header">
      {
        auth
          ? (
            <div className="auth">
              <Sync />
              <p className="body">{t('welcome')}</p>
              <button className="button accent" type="button" onClick={logout}>{startCase(t('logout'))}</button>
              <p className="button accent">|</p>
              <Link className="button accent" to="/messages">{t('messages')} ({messageCountUnread})</Link>
            </div>
          )
          : null
      }
      <section>
        <Link to="/">
          <img src={logo} alt="OWise" className="logo" />
        </Link>
        {
          auth
            ? (
              <nav>
                <NavLink className="heading" to="/" exact>{startCase(t('overview'))}</NavLink>
                <NavLink className="heading" to="/treatments">{startCase(t('treatment-plan'))}</NavLink>
                <NavLink className="heading" to="/diary">{startCase(t('diary'))}</NavLink>
                <NavLink className="heading" to="/trends">{startCase(t('trends'))}</NavLink>
                <NavLink className="heading" to="/questionnaires">{startCase(t('questionnaires'))}</NavLink>
                <p className={classNames('heading', 'settings', { active: includes(pathname, '/settings') })}>{startCase(t('settings'))}</p>
                <button type="button" onClick={window.print}>
                  <Icon name="print" size={28} color={Colors.black} />
                </button>
                <section className="pop-over">
                  <NavLink className="heading contrast" to="/settings/profile">{startCase(t('profile'))}</NavLink>
                  <NavLink className="heading contrast" to="/settings/change-password">{startCase(t('change-password'))}</NavLink>
                  <NavLink className="heading contrast" to="/settings/right-to-be-forgotten">{startCase(t('right-to-be-forgotten'))}</NavLink>
                  <NavLink className="heading contrast" to="/settings/trends">{startCase(t('trends-settings'))}</NavLink>
                  <NavLink className="heading contrast" to="/settings/invitation-codes">{startCase(t('invitation-codes'))}</NavLink>
                  <NavLink className="heading contrast" to="/settings/hospital-group-access-codes">{startCase(t('hospital-access-codes'))}</NavLink>
                  <NavLink className="heading contrast" to="/settings/share-my-data">{startCase(t('my-shared-data'))}</NavLink>
                  <NavLink className="heading contrast" to="/settings/language">{startCase(t('language'))}</NavLink>
                </section>
              </nav>
            )
            : (
              <nav>
                <a className="heading" href="https://owise.uk/about-us/" target="_blank" rel="noreferrer">{t('about-us')}</a>
                <a className="heading" href="https://owise.uk/how-it-works/" target="_blank" rel="noreferrer">How it works</a>
                <a className="heading" href="https://owise.uk/our-science/" target="_blank" rel="noreferrer">Our science</a>
                <NavLink className="heading" to="/login">{t('login')}</NavLink>
              </nav>
            )
        }
      </section>
    </header>
  );
};

Header.propTypes = propTypes;

export default Header;
