import { Platform as RNPlatform, ScrollViewProps as RNScrollViewProps } from 'react-native';

import { TKeyboardShouldPersistTaps } from '../../types';

const isAndroid = () => RNPlatform.OS === 'android';

const isIOS = () => RNPlatform.OS === 'ios';

const defaultIconSize = 28;

const iconSizeLarge = 44;

const iconSizeMedium = 34;

const iconSizeSmall = 24;

const iconSizeExtraSmall = 20;

const defaultBorderRadius = 10;

const floatingButtonPaddingBottom = 76;

const getFloatingButtonBottom = (bottom: number) => bottom || 16;

const getKeyboardVerticalOffset = (top: number) => top + 44;

const forceInset: any = {
  top: 'never',
};

const pickerContainerForceInset: any = {
  top: 'never',
  left: 'never',
  right: 'never',
};

const pickerHeaderForceInset: any = {
  top: 'never',
  bottom: 'never',
};

const listForceInset: any = {
  top: 'never',
  bottom: 'never',
};

const keyboardShouldPersistTaps: TKeyboardShouldPersistTaps = 'handled';

const defaultScrollViewProps: RNScrollViewProps = {
  overScrollMode: 'never',
  keyboardShouldPersistTaps,
};

const defaultFlatListProps = {
  keyboardShouldPersistTaps,
};

const listItemHeight = 59;

export default {
  isAndroid,
  isIOS,
  defaultIconSize,
  iconSizeLarge,
  iconSizeMedium,
  iconSizeSmall,
  iconSizeExtraSmall,
  defaultBorderRadius,
  floatingButtonPaddingBottom,
  getFloatingButtonBottom,
  getKeyboardVerticalOffset,
  forceInset,
  pickerContainerForceInset,
  pickerHeaderForceInset,
  keyboardShouldPersistTaps,
  defaultScrollViewProps,
  defaultFlatListProps,
  listForceInset,
  listItemHeight,
};
