import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRightHeader from '../components/DrawerRightHeader';
import '../css/SideEffects.css';
import { TDrugModule } from '../../types';
import getSideEffectData from '../utils/getSideEffectData';
import LeaveDrugModuleSupportHoc from '../hocs/LeaveDrugModuleSupportHoc';
import DrugModuleBreadcrumbs from '../components/DrugModuleBreadcrumbs';

type Params = {
  drugModule: TDrugModule;
};

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const SideEffects = () => {
  const { t } = useTranslation();

  const { drugModule } = useParams<Params>();

  return (
    <>
      <article className="page row">
        <Navigation />
        <section className="container">
          <section className="content">
            <DrawerButtons title={t('contentModules:drug:common:side-effects')} showRightButton />
            <DrugModuleBreadcrumbs drugModule={drugModule} />
            <section className="side-effects-container">
              {getSideEffectData(drugModule)}
            </section>
          </section>
          <Footer />
        </section>
        <DrawerRight />
      </article>
    </>
  );
};

export default LeaveDrugModuleSupportHoc(SideEffects);
