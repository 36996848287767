import React from 'react';
import Icon from 'react-native-vector-icons/Ionicons';
import { View as RNView } from 'react-native';
import IconEmailAddress from '../components/Icons/EmailAddress';
import IconPassword from '../components/Icons/Password';
import IconCode from '../components/Icons/Code';
import IconHome from '../components/Icons/Home';
import IconTreatmentPlan from '../components/Icons/TreatmentPlan';
import IconDiary from '../components/Icons/Diary';
import IconTrend from '../components/Icons/Trend';
import IconMore from '../components/Icons/More';
import IconMoreOutline from '../components/Icons/MoreOutline';
import IconAppointment from '../components/Icons/Appointment';
import IconNote from '../components/Icons/Note';
import IconMessage from '../components/Icons/Message';
import IconPhoto from '../components/Icons/Photo';
import IconRecording from '../components/Icons/Recording';
import IconSettings from '../components/Icons/Settings';
import IconGlossary from '../components/Icons/Glossary';
import IconShareData from '../components/Icons/ShareData';
import IconQuestionnaire from '../components/Icons/Questionnaire';
import IconAccount from '../components/Icons/Account';
import IconDelete from '../components/Icons/Delete';
import IconAdd from '../components/Icons/Add';
import IconLink from '../components/Icons/Link';
import IconInfo from '../components/Icons/Info';
import IconDoctor from '../components/Icons/Doctor';
import IconRadiation from '../components/Icons/Radiation';
import IconClose from '../components/Icons/Close';
import IconReport from '../components/Icons/Report';
import IconThumbsUp from '../components/Icons/ThumbsUp';
import IconThumbsDown from '../components/Icons/ThumbsDown';
import IconGlobe from '../components/Icons/Globe';
import IconHealth from '../components/Icons/Health';
import IconDiagnosis from '../components/Icons/Diagnosis';
import IconStage from '../components/Icons/Stage';
import IconMolecular from '../components/Icons/Molecular';
import IconPlus from '../components/Icons/Plus';
import IconMinus from '../components/Icons/Minus';
import IconProfileCard from '../components/Icons/ProfileCard';
import IconCheck from '../components/Icons/Check';
import IconCross from '../components/Icons/Cross';
import IconDraggable from '../components/Icons/Draggable';
import IconShare from '../components/Icons/Share';
import IconEdit from '../components/Icons/Edit';
import IconReportFull from '../components/Icons/ReportFull';
import IconExit from '../components/Icons/Exit';
import IconAngiogenesisInhibitors from '../components/Icons/AngiogenesisInhibitors';
import IconAntiSicknessDrugs from '../components/Icons/AntiSicknessDrugs';
import IconBisphosphonates from '../components/Icons/Bisphosphonates';
import IconChemotherapy from '../components/Icons/Chemotherapy';
import IconGCSF from '../components/Icons/GCSF';
import IconHormoneTherapy from '../components/Icons/HormoneTherapy';
import IconOperation from '../components/Icons/Operation';
import IconPainkillers from '../components/Icons/Painkillers';
import IconRadiotherapy from '../components/Icons/Radiotherapy';
import IconTargetedTherapy from '../components/Icons/TargetedTherapy';
import IconOther from '../components/Icons/Other';
import IconSyncInProgress from '../components/Icons/SyncInProgress';
import IconSyncStale from '../components/Icons/SyncStale';
import IconReload from '../components/Icons/Reload';
import IconWarning from '../components/Icons/Warning';
import IconLogo from '../components/Icons/Logo';
import Constants from './Constants';
import Colors from './Colors';
import DefaultStyles from './DefaultStyles';

const defaultSize = Constants.defaultIconSize;
const defaultColor = Colors.primary;
const contrastColor = Colors.white;
const destructiveColor = Colors.destructiveRed;

const DevelopmentIcon = <Icon name="bug" size={defaultSize} color={defaultColor} />;

const LinkIcon = <IconLink size={defaultSize} color={defaultColor} />;

const LinkIconLarge = <IconLink size={Constants.iconSizeLarge} color={defaultColor} />;

const ShareIcon = <IconShare size={defaultSize} color={defaultColor} />;

const EmailIcon = <IconEmailAddress size={defaultSize} color={defaultColor} />;

const PasswordIcon = <IconPassword size={defaultSize} color={defaultColor} />;

const CodeIcon = <IconCode size={defaultSize} color={defaultColor} />;

const CodeIconLarge = <IconCode size={Constants.iconSizeLarge} color={defaultColor} />;

const ForwardIcon = <Icon name="chevron-forward" size={defaultSize} color={defaultColor} />;

const ForwardIconSmall = <Icon name="chevron-forward" size={Constants.iconSizeSmall} color={defaultColor} />;

const ForwardIconSmallCyan = <Icon name="chevron-forward" size={Constants.iconSizeSmall} color={Colors.secondary} />;

const BackIcon = <Icon name="chevron-back" size={defaultSize} color={defaultColor} />;

const BackIconSmall = <Icon name="chevron-back" size={Constants.iconSizeSmall} color={defaultColor} />;

const BackIconSmallCyan = <Icon name="chevron-back" size={Constants.iconSizeSmall} color={Colors.secondary} />;

const PhotoIcon = <IconPhoto size={defaultSize} color={defaultColor} />;

const PhotoIconLarge = <IconPhoto size={Constants.iconSizeLarge} color={defaultColor} />;

const PhotoIconContrast = <IconPhoto size={defaultSize} color={contrastColor} />;

const DeleteIcon = <IconDelete size={defaultSize} color={defaultColor} />;

const AppointmentIcon = <IconAppointment size={defaultSize} color={defaultColor} />;

const AppointmentIconLarge = (
  <IconAppointment size={Constants.iconSizeLarge} color={defaultColor} />
);

const AppointmentIconContrast = <IconAppointment size={defaultSize} color={contrastColor} />;

const MessageIcon = <IconMessage size={defaultSize} color={defaultColor} />;

const MessageIconLarge = <IconMessage size={Constants.iconSizeLarge} color={defaultColor} />;

const NoteIcon = <IconNote size={defaultSize} color={defaultColor} />;

const NoteIconLarge = <IconNote size={Constants.iconSizeLarge} color={defaultColor} />;

const NoteIconContrast = <IconNote size={defaultSize} color={contrastColor} />;

const UpIcon = <Icon name="chevron-up" size={defaultSize} color={defaultColor} />;

const DownIcon = <Icon name="chevron-down" size={defaultSize} color={defaultColor} />;

const AddIcon = <IconAdd size={defaultSize} color={defaultColor} />;

const AddIconSmall = <IconAdd size={Constants.iconSizeSmall} color={defaultColor} />;

const AddIconContrast = <IconAdd size={defaultSize} color={contrastColor} />;

const AddIconContrastSmall = <IconAdd size={22} color={contrastColor} />;

const PlusIcon = <IconPlus size={defaultSize} color={defaultColor} />;

const MinusIcon = <IconMinus size={defaultSize} color={defaultColor} />;

const MinusIconContrast = <IconMinus size={defaultSize} color={Colors.white} />;

const PlusIconContrast = <IconPlus size={defaultSize} color={Colors.white} />;

const AudioRecordingIcon = <IconRecording size={defaultSize} color={defaultColor} />;

const AudioRecordingIconLarge = (
  <IconRecording size={Constants.iconSizeLarge} color={defaultColor} />
);

const AudioRecordingIconContrast = <IconRecording size={defaultSize} color={contrastColor} />;

const ShareDataIcon = <IconShareData size={defaultSize} color={defaultColor} />;

const ShareDataIconLarge = <IconShareData size={Constants.iconSizeLarge} color={defaultColor} />;

const HomeIcon = <IconHome size={defaultSize} color={defaultColor} />;

const TreatmentPlanIcon = (
  <IconTreatmentPlan size={defaultSize} color={defaultColor} />
);

const DiaryIcon = <IconDiary size={defaultSize} color={defaultColor} />;

const TrendIcon = <IconTrend size={defaultSize} color={defaultColor} />;

const QuestionnaireIcon = <IconQuestionnaire size={defaultSize} color={defaultColor} />;

const QuestionnaireIconLarge = (
  <IconQuestionnaire size={Constants.iconSizeLarge} color={defaultColor} />
);

const SettingsIcon = <IconSettings size={defaultSize} color={defaultColor} />;

const SettingsIconLarge = <IconSettings size={Constants.iconSizeLarge} color={defaultColor} />;

const GlossaryIcon = <IconGlossary size={defaultSize} color={defaultColor} />;

const GlossaryIconLarge = <IconGlossary size={Constants.iconSizeLarge} color={defaultColor} />;

const LogoutIcon = <Icon name="log-out-outline" size={defaultSize} color={defaultColor} />;

const AccountIcon = <IconAccount size={defaultSize} color={defaultColor} />;

const AccountIconLarge = <IconAccount size={Constants.iconSizeLarge} color={defaultColor} />;

const AccountIconMediumContrast = (
  <IconAccount size={Constants.iconSizeMedium} color={contrastColor} />
);

const ExitIcon = <IconExit size={defaultSize} color={defaultColor} />;

const AccountIconContrast = <IconAccount size={defaultSize} color={contrastColor} />;

const QuestionIcon = <Icon name="help" size={defaultSize} color={defaultColor} />;

const QuestionIconContrast = <Icon name="help" size={defaultSize} color={contrastColor} />;

const SuggestedQuestionIcon = <Icon name="help-buoy" size={defaultSize} color={defaultColor} />;

const SuggestedQuestionIconContrast = <Icon name="help-buoy" size={defaultSize} color={contrastColor} />;

const DeleteIconContrast = <IconDelete size={defaultSize} color={contrastColor} />;

const DeleteIconDestructive = <IconDelete size={defaultSize} color={destructiveColor} />;

const InfoIcon = <IconInfo size={defaultSize} color={defaultColor} />;

const DoctorIcon = <IconDoctor size={defaultSize} color={defaultColor} />;

const DoctorIconLarge = <IconDoctor size={Constants.iconSizeLarge} color={defaultColor} />;

const SurgeryIcon = <IconDoctor size={defaultSize} color={defaultColor} />;

const SurgeryIconLarge = (
  <IconDoctor size={Constants.iconSizeLarge} color={defaultColor} />
);

const SurgeryIconMediumContrast = (
  <IconDoctor size={Constants.iconSizeMedium} color={contrastColor} />
);

const RadiationIcon = <IconRadiation size={defaultSize} color={defaultColor} />;

const RadiationIconLarge = (
  <IconRadiation size={Constants.iconSizeLarge} color={defaultColor} />
);

const CloseIcon = <IconClose size={defaultSize} color={defaultColor} />;

const CloseIconContrast = <IconClose size={defaultSize} color={contrastColor} />;

const CrossIcon = <IconCross size={defaultSize} color={defaultColor} />;

const CrossIconContrast = <IconCross size={defaultSize} color={contrastColor} />;

const DraggableIcon = <IconDraggable size={defaultSize} color={defaultColor} />;

const ReportIcon = <IconReport size={defaultSize} color={defaultColor} />;

const ReportFullIcon = <IconReportFull size={defaultSize} color={defaultColor} />;

const MoreIconContrast = <IconMoreOutline size={defaultSize} color={contrastColor} />;

const ThumbsUpIcon = <IconThumbsUp size={defaultSize} color={defaultColor} />;

const ThumbsUpIconContrast = <IconThumbsUp size={defaultSize} color={contrastColor} />;

const ThumbsDownIcon = <IconThumbsDown size={defaultSize} color={defaultColor} />;

const ThumbsDownIconContrast = <IconThumbsDown size={defaultSize} color={contrastColor} />;

const ReloadIcon = <IconReload size={defaultSize} color={defaultColor} />;

const ReloadIconContrast = <IconReload size={defaultSize} color={contrastColor} />;

const ReloadIconLarge = <IconReload size={Constants.iconSizeLarge} color={defaultColor} />;

const ReportIconLarge = (
  <IconReport size={Constants.iconSizeLarge} color={defaultColor} />
);

const TreatmentPlanIconContrastExtraSmall = (
  <IconTreatmentPlan size={Constants.iconSizeExtraSmall} color={Colors.white} />
);

const TabBarHomeIcon = (tintColor: string) => (
  <IconHome size={defaultSize} color={tintColor} />
);

const TabBarTreatmentPlanIcon = (tintColor: string) => (
  <IconTreatmentPlan size={defaultSize} color={tintColor} />
);

const TabBarDiaryIcon = (tintColor: string) => (
  <IconDiary size={defaultSize} color={tintColor} />
);

const TabBarTrendIcon = (tintColor: string) => (
  <IconTrend size={defaultSize} color={tintColor} />
);

const TabBarMoreIcon = (tintColor: string) => (
  <IconMore size={defaultSize} color={tintColor} />
);

const ListItemLinkIcon = (
  <RNView style={DefaultStyles.listItemIcon}>
    {LinkIcon}
  </RNView>
);

const ListItemLinkIconLarge = (
  <RNView style={DefaultStyles.listItemIconLarge}>
    {LinkIconLarge}
  </RNView>
);

const ListItemShareIcon = (
  <RNView style={DefaultStyles.listItemIcon}>
    {ShareIcon}
  </RNView>
);

const ListItemAddIcon = (
  <RNView style={DefaultStyles.listItemIcon}>
    {AddIcon}
  </RNView>
);

const ListItemAppointmentIcon = (
  <RNView style={DefaultStyles.listItemIcon}>
    {AppointmentIcon}
  </RNView>
);

const ListItemAudioRecordingIcon = (
  <RNView style={DefaultStyles.listItemIcon}>
    {AudioRecordingIcon}
  </RNView>
);

const ListItemNoteIcon = (
  <RNView style={DefaultStyles.listItemIcon}>
    {NoteIcon}
  </RNView>
);

const ListItemMessageIcon = (
  <RNView style={DefaultStyles.listItemIcon}>
    {MessageIcon}
  </RNView>
);

const ListItemPhotoIcon = (
  <RNView style={DefaultStyles.listItemIcon}>
    {PhotoIcon}
  </RNView>
);

const ListItemHomeIcon = (
  <RNView style={DefaultStyles.listItemIcon}>
    {HomeIcon}
  </RNView>
);

const ListItemTreatmentPlanIcon = (
  <RNView style={DefaultStyles.listItemIcon}>
    {TreatmentPlanIcon}
  </RNView>
);

const ListItemDiaryIcon = (
  <RNView style={DefaultStyles.listItemIcon}>
    {DiaryIcon}
  </RNView>
);

const ListItemTrendIcon = (
  <RNView style={DefaultStyles.listItemIcon}>
    {TrendIcon}
  </RNView>
);

const ListItemQuestionnaireIcon = (
  <RNView style={DefaultStyles.listItemIcon}>
    {QuestionnaireIcon}
  </RNView>
);

const ListItemQuestionnaireIconLarge = (
  <RNView style={DefaultStyles.listItemIconLarge}>
    {QuestionnaireIconLarge}
  </RNView>
);

const ListItemSettingsIcon = (
  <RNView style={DefaultStyles.listItemIcon}>
    {SettingsIcon}
  </RNView>
);

const ListItemSettingsIconLarge = (
  <RNView style={DefaultStyles.listItemIconLarge}>
    {SettingsIconLarge}
  </RNView>
);

const ListItemGlossaryIcon = (
  <RNView style={DefaultStyles.listItemIcon}>
    {GlossaryIcon}
  </RNView>
);

const ListItemGlossaryIconLarge = (
  <RNView style={DefaultStyles.listItemIconLarge}>
    {GlossaryIconLarge}
  </RNView>
);

const ListItemShareDataIcon = (
  <RNView style={DefaultStyles.listItemIcon}>
    {ShareDataIcon}
  </RNView>
);

const ListItemShareDataIconLarge = (
  <RNView style={DefaultStyles.listItemIconLarge}>
    {ShareDataIconLarge}
  </RNView>
);

const ListItemLogoutIcon = (
  <RNView style={DefaultStyles.listItemIconLarge}>
    {LogoutIcon}
  </RNView>
);

const ListItemAccountIcon = (
  <RNView style={DefaultStyles.listItemIconLarge}>
    {AccountIcon}
  </RNView>
);

const ListItemAccountIconLarge = (
  <RNView style={DefaultStyles.listItemIconLarge}>
    {AccountIconLarge}
  </RNView>
);

const ListItemReportIcon = (
  <RNView style={DefaultStyles.listItemIcon}>
    {ReportIcon}
  </RNView>
);

const ListItemReportIconLarge = (
  <RNView style={DefaultStyles.listItemIconLarge}>
    {ReportIconLarge}
  </RNView>
);

const ListItemExitIcon = (
  <RNView style={DefaultStyles.listItemIcon}>
    {ExitIcon}
  </RNView>
);

const ListItemDevelopmentIcon = (
  <RNView style={DefaultStyles.listItemIcon}>
    {DevelopmentIcon}
  </RNView>
);

const ListItemCodeIcon = (
  <RNView style={DefaultStyles.listItemIcon}>
    {CodeIcon}
  </RNView>
);

const ListItemCodeIconLarge = (
  <RNView style={DefaultStyles.listItemIconLarge}>
    {CodeIconLarge}
  </RNView>
);

const AddCircleIconCustom = (style = {}, color = defaultColor, size = defaultSize) => (
  <Icon name="add-circle" size={size} color={color} style={style} />
);

const AccountIconCustom = (style = {}, color = defaultColor, size = defaultSize) => (
  <Icon name="person" size={size} color={color} style={style} />
);

const QuestionnaireIconCustom = (style = {}, color = defaultColor, size = defaultSize) => (
  <Icon name="list" size={size} color={color} style={style} />
);

const ShareDataIconCustom = (style = {}, color = defaultColor, size = defaultSize) => (
  <Icon name="medkit" size={size} color={color} style={style} />
);

const GlossaryIconCustom = (style = {}, color = defaultColor, size = defaultSize) => (
  <Icon name="bookmarks" size={size} color={color} style={style} />
);

const SettingsIconCustom = (style = {}, color = defaultColor, size = defaultSize) => (
  <Icon name="cog" size={size} color={color} style={style} />
);

const ListItemAppointmentIconLarge = (
  <RNView style={DefaultStyles.listItemIconLarge}>
    {AppointmentIconLarge}
  </RNView>
);

const ListItemAudioRecordingIconLarge = (
  <RNView style={DefaultStyles.listItemIconLarge}>
    {AudioRecordingIconLarge}
  </RNView>
);

const ListItemNoteIconLarge = (
  <RNView style={DefaultStyles.listItemIconLarge}>
    {NoteIconLarge}
  </RNView>
);

const ListItemPhotoIconLarge = (
  <RNView style={DefaultStyles.listItemIconLarge}>
    {PhotoIconLarge}
  </RNView>
);

const ListItemMessageIconLarge = (
  <RNView style={DefaultStyles.listItemIconLarge}>
    {MessageIconLarge}
  </RNView>
);

const DemographicsIcon = <IconGlobe size={defaultSize} color={defaultColor} />;

const DemographicsIconLarge = <IconGlobe size={Constants.iconSizeLarge} color={defaultColor} />;

const DemographicsIconMediumContrast = (
  <IconGlobe size={Constants.iconSizeMedium} color={contrastColor} />
);

const ListItemDemographicsIconLarge = (
  <RNView style={DefaultStyles.listItemIconLarge}>
    {DemographicsIconLarge}
  </RNView>
);

const HealthIcon = <IconHealth size={defaultSize} color={defaultColor} />;

const HealthIconLarge = <IconHealth size={Constants.iconSizeLarge} color={defaultColor} />;

const HealthIconMediumContrast = (
  <IconHealth size={Constants.iconSizeMedium} color={contrastColor} />
);

const ListItemHealthIconLarge = (
  <RNView style={DefaultStyles.listItemIconLarge}>
    {HealthIconLarge}
  </RNView>
);

const DiagnosisIcon = <IconDiagnosis size={defaultSize} color={defaultColor} />;

const DiagnosisIconLarge = <IconDiagnosis size={Constants.iconSizeLarge} color={defaultColor} />;

const DiagnosisIconMediumContrast = (
  <IconDiagnosis size={Constants.iconSizeMedium} color={contrastColor} />
);

const ListItemDiagnosisIconLarge = (
  <RNView style={DefaultStyles.listItemIconLarge}>
    {DiagnosisIconLarge}
  </RNView>
);

const StageIcon = <IconStage size={defaultSize} color={defaultColor} />;

const StageIconLarge = <IconStage size={Constants.iconSizeLarge} color={defaultColor} />;

const StageIconMediumContrast = <IconStage size={Constants.iconSizeMedium} color={contrastColor} />;

const ListItemStageIconLarge = (
  <RNView style={DefaultStyles.listItemIconLarge}>
    {StageIconLarge}
  </RNView>
);

const MolecularIcon = <IconMolecular size={defaultSize} color={defaultColor} />;

const MolecularIconLarge = <IconMolecular size={Constants.iconSizeLarge} color={defaultColor} />;

const MolecularIconMediumContrast = (
  <IconMolecular size={Constants.iconSizeMedium} color={contrastColor} />
);

const ListItemMolecularIconLarge = (
  <RNView style={DefaultStyles.listItemIconLarge}>
    {MolecularIconLarge}
  </RNView>
);

const ListItemSurgeryIconLarge = (
  <RNView style={DefaultStyles.listItemIconLarge}>
    {SurgeryIconLarge}
  </RNView>
);

const CheckIcon = <IconCheck size={defaultSize} color={defaultColor} />;

const CheckIconMediumSuccess = (
  <IconCheck size={Constants.iconSizeMedium} color={Colors.secondary} />
);

const profileQuestionCategoryIcons = {
  user: AccountIconLarge,
  demographics: DemographicsIconLarge,
  health: HealthIconLarge,
  diagnosis: DiagnosisIconLarge,
  stage: StageIconLarge,
  molecular: MolecularIconLarge,
  surgery: SurgeryIconLarge,
};

const profileQuestionCategoryContrastMediumIcons = {
  user: AccountIconMediumContrast,
  demographics: DemographicsIconMediumContrast,
  health: HealthIconMediumContrast,
  diagnosis: DiagnosisIconMediumContrast,
  stage: StageIconMediumContrast,
  molecular: MolecularIconMediumContrast,
  surgery: SurgeryIconMediumContrast,
};

const ProfileCardIcon = <IconProfileCard size={defaultSize} color={defaultColor} />;

const ProfileCardIconLarge = (
  <IconProfileCard size={Constants.iconSizeLarge} color={defaultColor} />
);

const ListItemProfileCardIconLarge = (
  <RNView style={DefaultStyles.listItemIconLarge}>
    {ProfileCardIconLarge}
  </RNView>
);

const ShareIconContrast = <IconShare size={defaultSize} color={contrastColor} />;

const EditIcon = <IconEdit size={defaultSize} color={defaultColor} />;

const AngiogenesisInhibitorsIcon = (
  <IconAngiogenesisInhibitors size={defaultSize} color={defaultColor} />
);

const AngiogenesisInhibitorsIconLarge = (
  <IconAngiogenesisInhibitors size={Constants.iconSizeLarge} color={defaultColor} />
);

const AntiSicknessDrugsIcon = (
  <IconAntiSicknessDrugs size={defaultSize} color={defaultColor} />
);

const AntiSicknessDrugsIconLarge = (
  <IconAntiSicknessDrugs size={Constants.iconSizeLarge} color={defaultColor} />
);

const BisphosphonatesIcon = (
  <IconBisphosphonates size={defaultSize} color={defaultColor} />
);

const BisphosphonatesIconLarge = (
  <IconBisphosphonates size={Constants.iconSizeLarge} color={defaultColor} />
);

const ChemotherapyIcon = (
  <IconChemotherapy size={defaultSize} color={defaultColor} />
);

const ChemotherapyIconLarge = (
  <IconChemotherapy size={Constants.iconSizeLarge} color={defaultColor} />
);

const GCSFIcon = (
  <IconGCSF size={defaultSize} color={defaultColor} />
);

const GCSFIconLarge = (
  <IconGCSF size={Constants.iconSizeLarge} color={defaultColor} />
);

const HormoneTherapyIcon = (
  <IconHormoneTherapy size={defaultSize} color={defaultColor} />
);

const HormoneTherapyIconLarge = (
  <IconHormoneTherapy size={Constants.iconSizeLarge} color={defaultColor} />
);

const OperationIcon = (
  <IconOperation size={defaultSize} color={defaultColor} />
);

const OperationIconLarge = (
  <IconOperation size={Constants.iconSizeLarge} color={defaultColor} />
);

const PainkillersIcon = (
  <IconPainkillers size={defaultSize} color={defaultColor} />
);

const PainkillersIconLarge = (
  <IconPainkillers size={Constants.iconSizeLarge} color={defaultColor} />
);

const RadiotherapyIcon = (
  <IconRadiotherapy size={defaultSize} color={defaultColor} />
);

const RadiotherapyIconLarge = (
  <IconRadiotherapy size={Constants.iconSizeLarge} color={defaultColor} />
);

const TargetedTherapyIcon = (
  <IconTargetedTherapy size={defaultSize} color={defaultColor} />
);

const TargetedTherapyIconLarge = (
  <IconTargetedTherapy size={Constants.iconSizeLarge} color={defaultColor} />
);

const OtherIcon = (
  <IconOther size={defaultSize} color={defaultColor} />
);

const OtherIconLarge = (
  <IconOther size={Constants.iconSizeLarge} color={defaultColor} />
);

const treatmentTypeIcons = {
  '0000000c-0000-1000-a000-000000000001': OperationIcon,
  '0000000c-0000-1000-a000-000000000002': ChemotherapyIcon,
  '0000000c-0000-1000-a000-000000000004': TargetedTherapyIcon,
  '0000000c-0000-1000-a000-000000000003': RadiotherapyIcon,
  '0000000c-0000-1000-a000-000000000007': AngiogenesisInhibitorsIcon,
  '0000000c-0000-1000-a000-000000000005': HormoneTherapyIcon,
  '0000000c-0000-1000-a000-000000000006': GCSFIcon,
  '0000000c-0000-1000-a000-00000000000a': AntiSicknessDrugsIcon,
  '0000000c-0000-1000-a000-00000000000b': PainkillersIcon,
  '0000000c-0000-1000-a000-000000000008': OtherIcon,
  '19786d2a-c211-4d64-8de2-87e08bf5137b': BisphosphonatesIcon,
};

const SyncInProgressIcon = (
  <IconSyncInProgress size={defaultSize} color={defaultColor} />
);

const SyncInProgressIconContrast = (
  <IconSyncInProgress size={defaultSize} color={contrastColor} />
);

const SyncStaleIcon = (
  <IconSyncStale size={defaultSize} color={defaultColor} />
);

const SyncStaleIconContrast = (
  <IconSyncStale size={defaultSize} color={contrastColor} />
);

const WarningIcon = (
  <IconWarning size={defaultSize} color={defaultColor} />
);

const WarningIconLarge = (
  <IconWarning size={Constants.iconSizeLarge} color={defaultColor} />
);

const WarningIconDestructive = (
  <IconWarning size={defaultSize} color={destructiveColor} />
);

const WarningIconDestructiveLarge = (
  <IconWarning size={Constants.iconSizeLarge} color={destructiveColor} />
);

const ListItemWarningIconDestructiveLarge = (
  <RNView style={DefaultStyles.listItemIconLarge}>
    {WarningIconDestructiveLarge}
  </RNView>
);

const LogoIcon = (
  <IconLogo size={defaultSize} color={defaultColor} />
);

const LogoIconLarge = (
  <IconLogo size={Constants.iconSizeLarge} color={defaultColor} />
);

const ListItemLogoIconLarge = (
  <RNView style={DefaultStyles.listItemIconLarge}>
    {LogoIconLarge}
  </RNView>
);

export {
  AngiogenesisInhibitorsIcon,
  AngiogenesisInhibitorsIconLarge,
  AntiSicknessDrugsIcon,
  AntiSicknessDrugsIconLarge,
  BisphosphonatesIcon,
  BisphosphonatesIconLarge,
  ChemotherapyIcon,
  ChemotherapyIconLarge,
  GCSFIcon,
  GCSFIconLarge,
  HormoneTherapyIcon,
  HormoneTherapyIconLarge,
  OperationIcon,
  OperationIconLarge,
  PainkillersIcon,
  PainkillersIconLarge,
  RadiotherapyIcon,
  RadiotherapyIconLarge,
  TargetedTherapyIcon,
  TargetedTherapyIconLarge,
  OtherIcon,
  OtherIconLarge,
  DevelopmentIcon,
  LinkIcon,
  EmailIcon,
  PasswordIcon,
  CodeIcon,
  CodeIconLarge,
  ForwardIcon,
  ForwardIconSmall,
  PhotoIcon,
  PhotoIconLarge,
  DeleteIcon,
  AppointmentIcon,
  MessageIcon,
  NoteIcon,
  NoteIconLarge,
  UpIcon,
  DownIcon,
  AddIcon,
  AddIconSmall,
  AudioRecordingIcon,
  AudioRecordingIconLarge,
  ShareDataIcon,
  ShareDataIconLarge,
  HomeIcon,
  TreatmentPlanIcon,
  DiaryIcon,
  TrendIcon,
  QuestionnaireIcon,
  QuestionnaireIconLarge,
  SettingsIcon,
  SettingsIconLarge,
  GlossaryIcon,
  GlossaryIconLarge,
  LogoutIcon,
  AccountIcon,
  AccountIconLarge,
  AccountIconContrast,
  QuestionIcon,
  QuestionIconContrast,
  SuggestedQuestionIcon,
  SuggestedQuestionIconContrast,
  DeleteIconContrast,
  TreatmentPlanIconContrastExtraSmall,
  TabBarHomeIcon,
  TabBarTreatmentPlanIcon,
  TabBarDiaryIcon,
  TabBarTrendIcon,
  TabBarMoreIcon,
  ListItemLinkIcon,
  ListItemLinkIconLarge,
  ListItemShareIcon,
  ListItemAddIcon,
  ListItemAppointmentIcon,
  ListItemAudioRecordingIcon,
  ListItemNoteIcon,
  ListItemMessageIcon,
  ListItemPhotoIcon,
  ListItemHomeIcon,
  ListItemTreatmentPlanIcon,
  ListItemDiaryIcon,
  ListItemTrendIcon,
  ListItemQuestionnaireIcon,
  ListItemQuestionnaireIconLarge,
  ListItemSettingsIcon,
  ListItemSettingsIconLarge,
  ListItemGlossaryIcon,
  ListItemGlossaryIconLarge,
  ListItemShareDataIcon,
  ListItemShareDataIconLarge,
  ListItemLogoutIcon,
  ListItemAccountIcon,
  ListItemAccountIconLarge,
  ListItemExitIcon,
  ListItemDevelopmentIcon,
  AddIconContrast,
  AppointmentIconContrast,
  NoteIconContrast,
  PhotoIconContrast,
  AudioRecordingIconContrast,
  AddCircleIconCustom,
  AccountIconCustom,
  QuestionnaireIconCustom,
  ShareDataIconCustom,
  GlossaryIconCustom,
  SettingsIconCustom,
  InfoIcon,
  DoctorIcon,
  DoctorIconLarge,
  SurgeryIcon,
  SurgeryIconLarge,
  RadiationIcon,
  RadiationIconLarge,
  CloseIcon,
  CloseIconContrast,
  CrossIcon,
  CrossIconContrast,
  ReportIcon,
  ReportIconLarge,
  ListItemReportIcon,
  ListItemReportIconLarge,
  ListItemAppointmentIconLarge,
  ListItemAudioRecordingIconLarge,
  ListItemNoteIconLarge,
  ListItemPhotoIconLarge,
  ListItemMessageIconLarge,
  ListItemCodeIconLarge,
  MoreIconContrast,
  ThumbsUpIcon,
  ThumbsUpIconContrast,
  ThumbsDownIcon,
  ThumbsDownIconContrast,
  DemographicsIcon,
  DemographicsIconLarge,
  ListItemDemographicsIconLarge,
  HealthIcon,
  HealthIconLarge,
  ListItemHealthIconLarge,
  DiagnosisIcon,
  DiagnosisIconLarge,
  ListItemDiagnosisIconLarge,
  StageIcon,
  StageIconLarge,
  ListItemStageIconLarge,
  MolecularIcon,
  MolecularIconLarge,
  ListItemMolecularIconLarge,
  ListItemSurgeryIconLarge,
  BackIcon,
  BackIconSmall,
  PlusIcon,
  PlusIconContrast,
  MinusIcon,
  MinusIconContrast,
  profileQuestionCategoryIcons,
  ProfileCardIcon,
  ProfileCardIconLarge,
  ListItemProfileCardIconLarge,
  profileQuestionCategoryContrastMediumIcons,
  CheckIcon,
  CheckIconMediumSuccess,
  DraggableIcon,
  ListItemCodeIcon,
  DeleteIconDestructive,
  AddIconContrastSmall,
  ShareIcon,
  ShareIconContrast,
  EditIcon,
  ReportFullIcon,
  treatmentTypeIcons,
  SyncInProgressIcon,
  SyncInProgressIconContrast,
  SyncStaleIcon,
  SyncStaleIconContrast,
  ReloadIcon,
  ReloadIconLarge,
  ReloadIconContrast,
  WarningIcon,
  WarningIconLarge,
  WarningIconDestructiveLarge,
  ListItemWarningIconDestructiveLarge,
  LogoIcon,
  LogoIconLarge,
  ListItemLogoIconLarge,
  ForwardIconSmallCyan,
  BackIconSmallCyan,
  AppointmentIconLarge,
  MessageIconLarge,
  WarningIconDestructive,
};
