import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { AppState } from '../state/reducers';

import Modal from './Modal';
import { actions as questionnairesActions } from '../state/questionnaires';
import { selectors as uiSelectors, actions as uiActions } from '../state/ui';
import getUnixTime from '../utils/getUnixTime';

const QuestionAddModal = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const modal = useSelector((state: AppState) => uiSelectors.getModal(state));

  const visible = modal.visible && modal.type === 'questionAdd';

  const [title, setTitle] = useState('');

  const handleClose = () => {
    setTitle('');
    dispatch(uiActions.modalHide());
  };

  const handleSave = () => {
    if (!title.trim()) {
      return false;
    }

    const question = {
      question: title,
      createdAt: getUnixTime(),
      id: uuidv4(),
      answer: '',
    };

    dispatch(questionnairesActions.addQuestions({
      questionnaireId: modal.data as string,
      questions: [question],
    }));

    return handleClose();
  };

  return (
    <Modal
      visible={visible}
      hideModal={handleClose}
      actions={[
        {
          title: t('cancel'),
          onClick: handleClose,
        },
        {
          title: t('save'),
          onClick: handleSave,
          disabled: !title.trim(),
          primary: true,
        },
      ]}
      className="suggested-questions"
    >
      <h1 className="heading">{t('add-question')}</h1>
      <label htmlFor="question">
        <span className="body light">{t('question')}</span>
        <input
          value={title}
          onChange={(event) => setTitle(event.currentTarget.value)}
          name="question"
          id="question"
        />
      </label>
    </Modal>
  );
};

export default QuestionAddModal;
