import { takeEvery, select, put } from 'redux-saga/effects';
import concat from 'lodash/concat';
import uniq from 'lodash/uniq';
import compact from 'lodash/compact';
import without from 'lodash/without';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';

import { customAlphabet } from 'nanoid';

import find from 'lodash/find';
import {
  actionTypes as settingsActionTypes,
  actions as settingsActions,
  selectors as settingsSelectors,
  ContentDrugModuleAddActionType,
  ContentDrugModuleRemoveActionType,
  DrugModule,
} from '../state/settings';
import { actions as tagsActions, selectors as tagsSelectors } from '../state/tags';
import { selectors as templatesSelectors } from '../state/templates';
import { actions as messagesActions } from '../state/messages';
import { actions as dailySuggestionsActions } from '../state/dailySuggestions';
import { actions as trendActions } from '../state/trends';
import { actions as customTrendsActions } from '../state/customTrends';

import getUnixTime from '../utils/getUnixTime';
import { intervalTriggerIds } from '../utils/intervalTrigger';
import { triggers } from '../../data/system-document.json';
import { TDrugModule } from '../../types';

const nanoid = customAlphabet('1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ', 6);

const dailySuggestionIds = {
  lynparza: '7e3f1b24-071d-4c44-9f83-4dfb1cbc0ba3',
  enhertu: '45dc4d45-1c53-42b8-b8a4-764b9ca9bc0d',
};

function* contentDrugModulesAdd(
  action: ContentDrugModuleAddActionType,
): Generator<any, any, any> {
  const drugModulesData = action.payload;

  for (let i = 0; i < drugModulesData.length; i += 1) {
    const enabledDrugModules = yield select(settingsSelectors.getDrugContentModules);
    const currentDrugModules = yield select(tagsSelectors.getValueByKey, 'drugModules');

    const { drugModule, invitationCode } = drugModulesData[i];

    if (enabledDrugModules[drugModule]) { // module is already enabled
      return;
    }

    const contentModulesTemplate = yield select(templatesSelectors.get, 'contentModules');

    const dailySuggestionId = dailySuggestionIds[drugModule];

    const templateMessageId = get(triggers, ['contentModules', 'drug', drugModule, 'messages', 'welcome', 'templateMessageId']);

    if (templateMessageId) {
      const message = get(contentModulesTemplate, ['drug', drugModule, 'messages', templateMessageId]);

      if (!isEmpty(message)) {
        yield put(messagesActions.add([{ ...message, createdAt: getUnixTime() }]));
      }
    }

    yield put(dailySuggestionsActions.trigger({ ids: [dailySuggestionId] }));

    yield put(settingsActions.contentModulesUpdate({
      active: true,
      moduleName: drugModule,
      invitationCode,
      enabledBy: 'invitation_code',
      surveyKey: nanoid(),
      surveyVisited: false,
      surveyConsentedAt: undefined,
    }));

    const value = compact(uniq(concat(currentDrugModules, [drugModule])));

    yield put(tagsActions.add([{ key: 'drugModules', value }]));
  }
}

function* contentDrugModulesRemove(
  action: ContentDrugModuleRemoveActionType,
): Generator<any, any, any> {
  const { removeCodes, invitationCodes, drugModule } = action.payload;

  for (let i = 0; i < invitationCodes.length; i += 1) {
    const enabledDrugModules: DrugModule = yield select(
      settingsSelectors.getDrugContentModules,
    );

    const currentDrugModules = yield select(tagsSelectors.getValueByKey, 'drugModules');

    const invitationCode = invitationCodes[i];

    const drugModules = keys(enabledDrugModules) as TDrugModule[];

    const drugModuleFind = find(
      drugModules,
      (key) => enabledDrugModules[key].invitationCode === invitationCode,
    );

    if (removeCodes && drugModules.length <= 1) {
      yield put(settingsActions.invitationCodesRemove([invitationCode]));
    }

    if (!drugModuleFind) { // module disabled or doesn't exist
      return;
    }

    const dailySuggestionId = dailySuggestionIds[drugModule];

    const messages = get(triggers, ['contentModules', 'drug', drugModule, 'messages']);

    for (const message of keys(messages)) { // eslint-disable-line no-restricted-syntax
      const templateMessageId = get(messages, [message, 'templateMessageId']);

      if (templateMessageId) {
        yield put(messagesActions.remove([templateMessageId]));
      }
    }

    yield put(settingsActions.contentModulesUpdate({
      active: false,
      moduleName: drugModule,
      invitationCode: undefined,
      enabledBy: undefined,
      surveyKey: undefined,
      surveyConsentedAt: undefined,
      surveyVisited: false,
    }));

    yield put(dailySuggestionsActions.trigger({ ids: [dailySuggestionId] }));

    const value = without(currentDrugModules, drugModule);

    yield put(tagsActions.add([{ key: 'drugModules', value }]));

    yield put(trendActions.clearDrugModuleTrends(drugModule));
    yield put(customTrendsActions.clearDrugModuleTrends(drugModule));
    yield put(dailySuggestionsActions.clearDrugModuleSuggestions(drugModule));

    const ids = Object.values(intervalTriggerIds[drugModule]);

    yield put(dailySuggestionsActions.intervalTrigger({
      ids,
      close: true,
    }));
  }
}

export default function* watchSettings(): Generator<any, any, any> {
  yield takeEvery(settingsActionTypes.CONTENT_DRUG_MODULES_ADD, contentDrugModulesAdd);
  yield takeEvery(settingsActionTypes.CONTENT_DRUG_MODULES_REMOVE, contentDrugModulesRemove);
}
