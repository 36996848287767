const prefix = 'SETTINGS';

const TREND_INDICATORS_UPDATE = `${prefix}/TREND_INDICATORS_UPDATE`;
const TREND_INDICATORS_UPDATE_DISABLED = `${prefix}/TREND_INDICATORS_UPDATE_DISABLED`;
const TREND_INDICATORS_UPDATE_FAVORITE = `${prefix}/TREND_INDICATORS_UPDATE_FAVORITE`;
const TREND_INDICATORS_UPDATE_ORDER = `${prefix}/TREND_INDICATORS_UPDATE_ORDER`;
const TREND_INDICATORS_CLEAR = `${prefix}/TREND_INDICATORS_CLEAR`;
const GENERAL_UPDATE = `${prefix}/GENERAL_UPDATE`;
const INVITATION_CODES_ADD = `${prefix}/INVITATION_CODES_ADD`;
const INVITATION_CODES_EDIT = `${prefix}/INVITATION_CODES_EDIT`;
const INVITATION_CODES_REMOVE = `${prefix}/INVITATION_CODES_REMOVE`;
const INVITATION_LAST_CODE_REMOVE = `${prefix}/INVITATION_LAST_CODE_REMOVE`;
const INVITATION_LAST_CODE_AND_DATA_REMOVE = `${prefix}/INVITATION_LAST_CODE_AND_DATA_REMOVE`;
const HOSPITAL_GROUP_ACCESS_CODES_ADD = `${prefix}/HOSPITAL_GROUP_ACCESS_CODES_ADD`;
const HOSPITAL_GROUP_ACCESS_CODES_REMOVE = `${prefix}/HOSPITAL_GROUP_ACCESS_CODES_REMOVE`;
const BANNERS_TRENDS_NEXT = `${prefix}/BANNERS_TRENDS_NEXT`;
const CLEAR = `${prefix}/CLEAR`;
const CHANGE_STATUS = `${prefix}/CHANGE_STATUS`;
const IS_FIRST_LAUNCH = `${prefix}/IS_FIRST_LAUNCH`;
const CONTENT_MODULES_UPDATE = `${prefix}/CONTENT_MODULES_UPDATE`;
const CONTENT_MODULES_CLEAR = `${prefix}/CONTENT_MODULES_CLEAR`;
const CONTENT_DRUG_MODULES_ADD = `${prefix}/CONTENT_DRUG_MODULES_ADD`;
const CONTENT_DRUG_MODULES_REMOVE = `${prefix}/CONTENT_DRUG_MODULES_REMOVE`;

export {
  TREND_INDICATORS_UPDATE,
  TREND_INDICATORS_UPDATE_DISABLED,
  TREND_INDICATORS_UPDATE_FAVORITE,
  TREND_INDICATORS_UPDATE_ORDER,
  TREND_INDICATORS_CLEAR,
  GENERAL_UPDATE,
  INVITATION_CODES_ADD,
  INVITATION_CODES_EDIT,
  INVITATION_CODES_REMOVE,
  HOSPITAL_GROUP_ACCESS_CODES_ADD,
  HOSPITAL_GROUP_ACCESS_CODES_REMOVE,
  INVITATION_LAST_CODE_REMOVE,
  INVITATION_LAST_CODE_AND_DATA_REMOVE,
  BANNERS_TRENDS_NEXT,
  CLEAR,
  CHANGE_STATUS,
  IS_FIRST_LAUNCH,
  CONTENT_MODULES_UPDATE,
  CONTENT_MODULES_CLEAR,
  CONTENT_DRUG_MODULES_ADD,
  CONTENT_DRUG_MODULES_REMOVE,
};
