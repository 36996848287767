import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { AppState } from '../state/reducers';
import { TAudioRecording } from '../../types';

import { selectors as audioRecordingsSelectors } from '../state/audioRecordings';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRightHeader from '../components/DrawerRightHeader';
import AudioRecording from '../components/AudioRecording';

type Params = {
  audioRecordingId: string;
};

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const AudioRecordingScreen = () => {
  const { t } = useTranslation();

  const { audioRecordingId } = useParams<Params>();

  const memoizedAudioRecordingGetById = useMemo(
    audioRecordingsSelectors.makeGetById,
    [],
  );

  const audioRecording = useSelector(
    (state: AppState) => memoizedAudioRecordingGetById(state, audioRecordingId),
  ) as TAudioRecording;

  return (
    <article className="page photo row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('recording')} showRightButton />
          <AudioRecording audioRecording={audioRecording} />
        </section>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default AudioRecordingScreen;
