/* eslint react/require-default-props: 0 */
import React, {
  memo, MutableRefObject, ReactNode, useRef,
} from 'react';
import {
  View as RNView, TextInput, Pressable,
} from 'react-native';

import Text from '../Text';
import Divider, { DividerProps } from '../Divider';
import Colors from '../../theme/Colors';
import styles from './styles';

type Props = {
  value: number;
  onValueChange: (value: any) => void;
  headerStyle?: object;
  minimumValue: number;
  maximumValue: number;
  labelText: string;
  divider?: boolean;
  pristine?: boolean;
  dividerProps?: DividerProps;
  labelUnit: string;
  headerButton?: ReactNode
};

const HeartRateInput = (props: Props) => {
  const {
    value,
    onValueChange,
    headerStyle = {},
    labelText,
    divider = false,
    pristine = true,
    dividerProps = {},
    labelUnit,
    headerButton,
    minimumValue,
    maximumValue,
  } = props;

  const textColorStyle = {
    color: pristine ? Colors.lightGray : Colors.black,
  };

  const inputRef = useRef() as MutableRefObject<TextInput>;

  const onFocus = () => {
    if (pristine) {
      onValueChange(value);
    }
  };

  const onBlur = () => {
    if (!value) {
      onValueChange(Number(value));
    }
    if (value < minimumValue) {
      onValueChange(minimumValue);
    }
    if (value > maximumValue) {
      onValueChange(maximumValue);
    }
  };

  const onChangeText = (newValue: string) => {
    const newString = newValue.replace(/[^0-9]/g, ''); // android keyboard has , and . symbols
    onValueChange(newString === '' ? '' : Number(newString));
  };

  const focusRef = () => {
    inputRef.current?.focus?.();
  };

  return (
    <RNView>
      <RNView style={styles.container}>
        <RNView style={[styles.header, headerStyle]}>
          <Text type="heading">{labelText}</Text>
          {headerButton}
        </RNView>
        <Pressable
          style={styles.inputContainer}
          onPress={focusRef}
        >
          <TextInput
            ref={inputRef}
            keyboardType="number-pad"
            style={[styles.input, textColorStyle]}
            value={String(value)}
            onChangeText={onChangeText}
            maxLength={3}
            onFocus={onFocus}
            numberOfLines={1}
            scrollEnabled={false}
            onBlur={onBlur}
            allowFontScaling={false}
            maxFontSizeMultiplier={0}
          />
          <Text style={[styles.units, textColorStyle]}>
            {labelUnit}
          </Text>
        </Pressable>
      </RNView>
      {
        divider
          ? <Divider {...dividerProps} /> // eslint-disable-line react/jsx-props-no-spreading
          : null
      }
    </RNView>
  );
};

export default memo(HeartRateInput);
