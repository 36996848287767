const prefix = 'UI';

const NAVIGATION_MODAL_SHOW = `${prefix}/NAVIGATION_MODAL_SHOW`;
const NAVIGATION_MODAL_HIDE = `${prefix}/NAVIGATION_MODAL_HIDE`;
const ADD_NOTIFICATION = `${prefix}/ADD_NOTIFICATION`;
const SHOW_NOTIFICATION = `${prefix}/SHOW_NOTIFICATION`;
const CLEAR_NOTIFICATION = `${prefix}/CLEAR_NOTIFICATION`;
const PROFILE_COMPLETE_MODAL_SHOW = `${prefix}/PROFILE_COMPLETE_MODAL_SHOW`;
const PROFILE_COMPLETE_MODAL_HIDE = `${prefix}/PROFILE_COMPLETE_MODAL_HIDE`;
const PROFILE_COMPLETED_MODAL_SHOW = `${prefix}/PROFILE_COMPLETED_MODAL_SHOW`;
const PROFILE_COMPLETED_MODAL_HIDE = `${prefix}/PROFILE_COMPLETED_MODAL_HIDE`;
const VALIDATE_NAVIGATION_MODAL_SHOW = `${prefix}/VALIDATE_NAVIGATION_MODAL_SHOW`;
const VALIDATE_NAVIGATION_MODAL_HIDE = `${prefix}/VALIDATE_NAVIGATION_MODAL_HIDE`;
const PROFILE_INCOMPLETE_MODAL_SHOW = `${prefix}/PROFILE_INCOMPLETE_MODAL_SHOW`;
const PROFILE_INCOMPLETE_MODAL_HIDE = `${prefix}/PROFILE_INCOMPLETE_MODAL_HIDE`;
const RECORDING_UPDATE = `${prefix}/RECORDING_UPDATE`;
const ADD_ALERT = `${prefix}/ADD_ALERT`;
const TOGGLE_DRAWER_LEFT = `${prefix}/TOGGLE_DRAWER_LEFT`;
const TOGGLE_DRAWER_RIGHT = `${prefix}/TOGGLE_DRAWER_RIGHT`;
const MODAL_SHOW = `${prefix}/MODAL_SHOW`;
const MODAL_HIDE = `${prefix}/MODAL_HIDE`;

export {
  NAVIGATION_MODAL_SHOW,
  NAVIGATION_MODAL_HIDE,
  ADD_NOTIFICATION,
  SHOW_NOTIFICATION,
  CLEAR_NOTIFICATION,
  PROFILE_COMPLETE_MODAL_SHOW,
  PROFILE_COMPLETE_MODAL_HIDE,
  PROFILE_COMPLETED_MODAL_SHOW,
  PROFILE_COMPLETED_MODAL_HIDE,
  PROFILE_INCOMPLETE_MODAL_SHOW,
  PROFILE_INCOMPLETE_MODAL_HIDE,
  VALIDATE_NAVIGATION_MODAL_SHOW,
  VALIDATE_NAVIGATION_MODAL_HIDE,
  RECORDING_UPDATE,
  ADD_ALERT,
  TOGGLE_DRAWER_LEFT,
  TOGGLE_DRAWER_RIGHT,
  MODAL_SHOW,
  MODAL_HIDE,
};
