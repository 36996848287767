import * as actionTypes from './actionTypes';
import { PushNotificationType } from '../../../types';
import { PushNotificationActionTypes } from './types';

const add = (payload: PushNotificationType[]): PushNotificationActionTypes => ({
  type: actionTypes.ADD,
  payload,
});

const remove = (payload: string[]): PushNotificationActionTypes => ({
  type: actionTypes.REMOVE,
  payload,
});

const setReadAt = (payload: string[]): PushNotificationActionTypes => ({
  type: actionTypes.SET_READAT,
  payload,
});

const clear = (): PushNotificationActionTypes => ({
  type: actionTypes.CLEAR,
});

export {
  add,
  remove,
  setReadAt,
  clear,
};
