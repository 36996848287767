import * as actionTypes from './actionTypes';
import { AuthActionTypes } from './types';

const set = (payload: boolean): AuthActionTypes => ({
  type: actionTypes.SET,
  payload,
});

const logout = (): AuthActionTypes => ({
  type: actionTypes.LOGOUT,
});

export {
  set,
  logout,
};
