const prefix = 'PHOTOS';

const ADD = `${prefix}/ADD`;
const REMOVE = `${prefix}/REMOVE`;
const UNLINK_URI = `${prefix}/UNLINK_URI`;
const UNLINK_ALL = `${prefix}/UNLINK_ALL`;
const UPDATE = `${prefix}/UPDATE`;
const DOWNLOAD = `${prefix}/DOWNLOAD`;
const CLEANUP = `${prefix}/CLEANUP`;
const CLEAR = `${prefix}/CLEAR`;

export {
  ADD,
  REMOVE,
  UNLINK_URI,
  UNLINK_ALL,
  UPDATE,
  DOWNLOAD,
  CLEANUP,
  CLEAR,
};
