import { Reducer } from 'redux';

import * as utils from './utils';
import * as actionTypes from './actionTypes';
import {
  UsefulLinksState,
  UsefulLinksActionTypes,
  UsefulLinksReplaceActionType,
} from './types';

import { usefulLinks } from '../../../data/system-document.json';

const initialState = usefulLinks as UsefulLinksState;

const reducer: Reducer<
UsefulLinksState,
UsefulLinksActionTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REPLACE: return utils.replace(state, action as UsefulLinksReplaceActionType);
    case actionTypes.CLEAR: return utils.clear();
    default: return state;
  }
};

export default reducer;
