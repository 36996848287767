/* eslint react/require-default-props: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Path } from 'react-native-svg';
import ResizeableSVG from '../ResizeableSVG';

import Colors from '../../theme/Colors';
import Constants from '../../theme/Constants';

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

type Props = {
  color?: string;
  size?: number;
};

const Globe = (props: Props) => {
  const {
    color = Colors.primary,
    size = Constants.defaultIconSize,
  } = props;

  return (
    <ResizeableSVG size={size}>
      <Path d="M14.1430469,1.5 C20.9821714,1.57899914 26.5,7.14516878 26.5,14 C26.5,20.9035156 20.9035156,26.5 14,26.5 C13.9531648,26.5 13.9063897,26.4997424 13.8596761,26.4992286 C13.856438,26.4995339 13.8557667,26.4990678 13.8550954,26.4986016 C7.01865127,26.4218307 1.5,20.8553422 1.5,14 C1.5,7.09648438 7.09648437,1.5 14,1.5 C14.0473506,1.5 14.0946397,1.50026328 14.1418659,1.50078847 L14.1430469,1.5 Z M25.3079916,14.6793764 L20.2323233,14.6799279 C20.0360408,18.9480836 18.2260709,22.7250423 15.4887013,25.2307307 C20.814087,24.5285315 24.9850407,20.1134714 25.3079916,14.6793764 Z M7.7602356,14.6801974 L2.6920084,14.6793764 C3.01005236,20.0309054 7.06005379,24.3941308 12.269091,25.196131 C9.62779109,22.6538866 7.90673163,18.8989146 7.7602356,14.6801974 Z M18.9601376,14.679743 L9.03176814,14.6797307 C9.19046988,18.7631234 11.0001778,22.5745988 13.8789844,24.9265625 C16.8602356,22.6257943 18.7497516,18.8225049 18.9601376,14.679743 Z M12.511455,2.76926935 L12.2771173,2.80263623 C7.06421457,3.60138798 3.01021569,7.96634634 2.6920084,13.3206236 L7.76783294,13.3200721 C7.96411548,9.05191641 9.77408532,5.27495771 12.511455,2.76926935 Z M14.1211719,3.0734375 C11.1399207,5.37420569 9.2504046,9.1774951 9.04001869,13.320257 L18.9683881,13.3202693 C18.8096864,9.23687659 16.9999784,5.42540118 14.1211719,3.0734375 Z M15.730909,2.80386903 L15.905798,2.9751457 C18.4481234,5.51332896 20.096682,9.1948901 20.2399207,13.3198026 L25.3079916,13.3206236 C24.9899476,7.96909465 20.9399462,3.60586923 15.730909,2.80386903 Z" fill={color} fillRule="evenodd" />
    </ResizeableSVG>
  );
};

Globe.propTypes = propTypes;

export default Globe;
