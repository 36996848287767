import { Reducer } from 'redux';

import * as utils from './utils';
import * as actionTypes from './actionTypes';
import {
  ProfileQuestionAnswersState,
  ProfileQuestionAnswersActionTypes,
  ProfileQuestionAnswersAddActionType,
  ProfileQuestionAnswersUpdateActionType,
  ProfileQuestionAnswersDeleteByIdActionType,
} from './types';

const initialState: ProfileQuestionAnswersState = {};

const reducer: Reducer<
ProfileQuestionAnswersState,
ProfileQuestionAnswersActionTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD: {
      return utils.add(state, action as ProfileQuestionAnswersAddActionType);
    }
    case actionTypes.UPDATE: {
      return utils.update(state, action as ProfileQuestionAnswersUpdateActionType);
    }
    case actionTypes.DELETE_BY_ID: {
      return utils.deleteById(state, action as ProfileQuestionAnswersDeleteByIdActionType);
    }
    case actionTypes.CLEAR: return utils.clear();
    default: return state;
  }
};

export default reducer;
