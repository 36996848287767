import * as actionTypes from './actionTypes';
import { RuntimeSyncActionTypes, RuntimeSyncUpdatePayload } from './types';

const syncUpdate = (payload: RuntimeSyncUpdatePayload): RuntimeSyncActionTypes => ({
  type: actionTypes.SYNC_UPDATE,
  payload,
});

export {
  syncUpdate, // eslint-disable-line import/prefer-default-export
};
