/* eslint react/require-default-props: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Circle } from 'react-native-svg';
import ResizeableSVG from '../ResizeableSVG';

import Colors from '../../theme/Colors';
import Constants from '../../theme/Constants';

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  active: PropTypes.bool,
};

type Props = {
  color?: string;
  size?: number;
  active?: boolean;
};

const Radio = (props: Props) => {
  const {
    color = Colors.primary,
    size = Constants.defaultIconSize,
    active = false,
  } = props;

  return (
    <ResizeableSVG size={size}>
      <Circle cx="14" cy="14" fill={Colors.white} r="12" stroke={color} />
      <Circle cx="14" cy="14" fill={active ? color : Colors.white} r="8.5" />
    </ResizeableSVG>
  );
};

Radio.propTypes = propTypes;

export default Radio;
