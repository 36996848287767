import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import authApi from '../services/authApi';
import getApiTokenAsync from './getApiTokenAsync';

export type TConfig = {
  apiUrl: string;
};

const getConfigAsync = async (): Promise<TConfig> => {
  const token = await getApiTokenAsync();

  if (!token) {
    throw new Error('Invalid token');
  }

  const headers = {
    authentication: `Bearer ${token}`,
  };

  const response = await authApi('/config', { headers });

  const config = get(response, 'data');

  if (isEmpty(config)) {
    throw new Error('Invalid config');
  }

  return config;
};

export default getConfigAsync;
