import { Reducer } from 'redux';

import * as utils from './utils';
import * as actionTypes from './actionTypes';
import {
  AudioRecordingsState,
  AudioRecordingsActionTypes,
  AudioRecordingsAddActionType,
  AudioRecordingsRemoveActionType,
  AudioRecordingsUpdateActionType,
} from './types';

const initialState: AudioRecordingsState = {};

const reducer: Reducer<
AudioRecordingsState,
AudioRecordingsActionTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD: return utils.add(state, action as AudioRecordingsAddActionType);
    case actionTypes.REMOVE: return utils.remove(state, action as AudioRecordingsRemoveActionType);
    case actionTypes.UPDATE: return utils.update(state, action as AudioRecordingsUpdateActionType);
    case actionTypes.CLEAR: return utils.clear();
    default: return state;
  }
};

export default reducer;
