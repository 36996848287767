import _get from 'lodash/get';

import { AppState } from '../reducers';
import { TemplatesState } from './types';

const getAll = (state: AppState): TemplatesState => state.templates;
const get = (state: AppState, template: string) => _get(state, ['templates', template]);

export {
  getAll,
  get,
};
