import React from 'react';
import Icon from 'react-native-vector-icons/Ionicons';

import ListItem from './ListItem';
import Colors from '../theme/Colors';

type Props = {
  label: string;
  checked: boolean;
  onClick: () => void;
  additionalLeftIcon?: React.ReactNode
};

const Checkbox = (props: Props) => {
  const {
    checked, label, onClick, additionalLeftIcon,
  } = props;

  return (

    <ListItem
      key={label}
      primaryText={label}
      onClick={onClick}
      leftIcon={(
        <div className="row center">
          {additionalLeftIcon}
          <Icon
            name={checked ? 'checkbox' : 'square-outline'}
            color={Colors.primary}
            size={28}
          />
        </div>
      )}
      divider
      active={false}
    />

  );
};
export default Checkbox;
