import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { AppState } from '../state/reducers';
import { TAudioRecording } from '../../types';

import '../css/AudioRecording.css';
import Header from '../components/Header';
import PageHeader from '../components/PageHeader';
import Footer from '../components/Footer';
import AudioRecording from '../components/AudioRecording';
import EmptyState from './EmptyState';
import { selectors as audioRecordingsSelectors } from '../state/audioRecordings';
import isOfType from '../utils/isOfType';

const AudioRecordingEdit = () => {
  const { audioRecordingId } = useParams<any>();

  const { t } = useTranslation();

  const memoizedAudioRecordingGetById = useMemo(
    audioRecordingsSelectors.makeGetById,
    [],
  );

  const audioRecording = useSelector(
    (state: AppState) => memoizedAudioRecordingGetById(state, audioRecordingId),
  );

  if (!isOfType<TAudioRecording>(audioRecording, 'id')) {
    return (
      <EmptyState pageHeaderTitle={t('audio-recording')} documentTitle={t('audio-recording')}>
        <p className="body light">{t('audioRecordings:empty-state-not-found-heading')}</p>
      </EmptyState>
    );
  }

  return (
    <article className="app audio-recording">
      <Header auth documentTitle={t('audio-recording')} />
      <PageHeader title={t('audio-recording')} />
      <AudioRecording audioRecording={audioRecording} />
      <Footer />
    </article>
  );
};

export default AudioRecordingEdit;
