import get from 'lodash/get';
import map from 'lodash/map';
import isArray from 'lodash/isArray';
import flatten from 'lodash/flatten';

import { AppState } from '../reducers';
import { TagsState } from './types';

import createDeepEqualSelector from '../../selectors/createDeepEqualSelector';

const getAllSelector = (state: TagsState) => flatten(
  map(state, (value, key) => {
    if (isArray(value)) {
      return map(value, (v) => `${key}:${v}`);
    }

    return `${key}:${value}`;
  }),
);

const getAll = createDeepEqualSelector(
  (state: AppState) => state.tags,
  getAllSelector,
);

const getValueByKeySelector = (state: TagsState, key: string) => get(state, [key]);

const getValueByKey = createDeepEqualSelector(
  (state: AppState) => state.tags,
  (state: any, key: string) => key,
  getValueByKeySelector,
);

const makeGetValueByKey = () => (
  createDeepEqualSelector(
    (state: AppState) => state.tags,
    (state: any, key: string) => key,
    getValueByKeySelector,
  )
);

const getByKeySelector = (state: TagsState, key: string) => {
  const value = get(state, [key]);

  if (value) {
    return `${key}:${value}`;
  }

  return '';
};

const makeGetByKey = () => (
  createDeepEqualSelector(
    (state: AppState) => state.tags,
    (state: any, key: string) => key,
    getByKeySelector,
  )
);

export {
  getAll,
  getValueByKey,
  getValueByKeySelector,
  makeGetValueByKey,
  makeGetByKey,
};
