import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import size from 'lodash/size';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { AppState } from '../state/reducers';
import { TReportType } from '../../types';

import Navigation from '../components/Navigation';
import DrawerButtons from '../components/DrawerButtons';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import DrawerRightHeader from '../components/DrawerRightHeader';
import ListItem from '../components/ListItem';
import ReportListItem from '../components/ReportListItem';
import { ReactComponent as IconReport } from '../assets/icons/Report.svg';
import { ReactComponent as IconChevronRight } from '../assets/icons/ChevronRight.svg';
import { selectors as generatedReportsSelectors } from '../state/generatedReports';
import { actions as reportsActions, selectors as reportsSelectors } from '../state/reports';
import getProfileProgressSelector from '../selectors/getProfileProgress';
import { actions as uiActions } from '../state/ui';
import getLocalizedText from '../utils/getLocalizedText';
import getUnixTime from '../utils/getUnixTime';
import Colors from '../theme/Colors';
import { actions as settingsActions } from '../state/settings';

const DrawerRight = () => {
  const history = useHistory();

  const { i18n, t } = useTranslation();

  const dispatch = useDispatch();

  const generatedReports = useSelector(
    (state: AppState) => generatedReportsSelectors.getAll(state),
  );

  const reportCounts = useSelector((state: AppState) => reportsSelectors.counts(state));

  const { completed: profileCompleted } = useSelector(
    (state: AppState) => getProfileProgressSelector(state),
  );

  const addReport = (type: TReportType) => {
    if (!profileCompleted) {
      dispatch(settingsActions.generalUpdate({ profileIncompleteModalShown: false }));
      return dispatch(uiActions.profileIncompleteModalShow());
    }

    const report = getLocalizedText(generatedReports, i18n.language, type);

    if (!report) {
      return dispatch(uiActions.addNotification({
        type: 'warning',
        text: t('reports:failed-notification'),
      }));
    }

    const reportId = uuidv4();
    const count = get(reportCounts, [type]) || 0;

    dispatch(reportsActions.add([{
      id: reportId,
      createdAt: getUnixTime(),
      type,
      title: `${t(`reports:${type}`)} ${count + 1}`,
      content: {
        body: report,
      },
    }]));

    return history.push(`/reports/${reportId}`);
  };

  return (
    <Drawer position="right">
      <section>
        <DrawerRightHeader />
        <ListItem
          leftIcon={<IconReport fill={Colors.primary} className="icon" />}
          primaryText={t('reports:treatment-full')}
          secondaryText={t('generate-report')}
          rightIcon={<IconChevronRight fill={Colors.primary} className="icon small" />}
          divider
          onClick={debounce(() => addReport('treatment-full'), 200)}
        />
        <ListItem
          leftIcon={<IconReport fill={Colors.primary} className="icon" />}
          primaryText={t('reports:treatment-summary')}
          secondaryText={t('generate-report')}
          rightIcon={<IconChevronRight fill={Colors.primary} className="icon small" />}
          onClick={debounce(() => addReport('treatment-summary'), 200)}
        />
      </section>
    </Drawer>
  );
};

const Library = () => {
  const { t } = useTranslation();

  const memoizedReportsGetByTypeSelector = useMemo(
    reportsSelectors.makeGetByType,
    [],
  );

  const treatmentReports = useSelector((state: AppState) => memoizedReportsGetByTypeSelector(state, 'treatment-full'));
  const treatmentSummaryReports = useSelector((state: AppState) => memoizedReportsGetByTypeSelector(state, 'treatment-summary'));

  return (
    <article className="page library row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('library')} showRightButton />
          <header className="list-header">
            <h1 className="heading-secondary accent">{t('treatment-reports')}</h1>
          </header>

          <section className={classNames('list', { empty: isEmpty(treatmentReports) })}>
            {
              !isEmpty(treatmentReports)
                ? map(treatmentReports, (report, index) => (
                  <ReportListItem
                    key={report.id}
                    report={report}
                    divider={size(treatmentReports) !== (index + 1)}
                  />
                ))
                : <span className="body light">{t('treatments:empty-state-not-found-heading')}</span>
            }
          </section>
          <header className="list-header">
            <h1 className="heading-secondary accent">{t('treatment-summary-reports')}</h1>
          </header>
          <section className={classNames('list', { empty: isEmpty(treatmentSummaryReports) })}>
            {
              !isEmpty(treatmentSummaryReports)
                ? map(treatmentSummaryReports, (report, index) => (
                  <ReportListItem
                    key={report.id}
                    report={report}
                    divider={size(treatmentSummaryReports) !== (index + 1)}
                  />
                ))
                : <span className="body light">{t('treatments:empty-state-not-found-heading')}</span>
            }
          </section>
        </section>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default Library;
