/* eslint react/jsx-props-no-spreading: 0 */
import React, { useEffect, useRef, useMemo } from 'react';
import * as d3 from 'd3';
import * as d3Format from 'd3-format';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import round from 'lodash/round';
import isNaN from 'lodash/isNaN';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import reject from 'lodash/reject';
import map from 'lodash/map';
import clamp from 'lodash/clamp';

import { AppState } from '../../state/reducers';
import { TrendIndicatorType, TGraphData } from '../../../types';

import { HIDE_GUIDE_TEXT_IDS } from './constants';
import { setUpTrendGraph } from './graph';
import { handleTooltipMouseMove, Point } from './tooltips';
import { selectors as trendsSelectors } from '../../state/trends';
import getLocalizedText from '../../utils/getLocalizedText';
import * as graph from '../../utils/graph';

import SvgTrendPlot from './SvgTrendPlot';

import './styles.css';

type Props = {
  startDate: Date;
  endDate: Date;
  width: number;
  height: number;
  xPadding: number;
  yPadding: number;
  trendIndicator: TrendIndicatorType;
};

const getTooltipText = (
  datum: TGraphData,
  trendIndicator: TrendIndicatorType,
  language: string,
): string => {
  const {
    labels,
    decimals = 0,
  } = trendIndicator;

  const labelUnit = getLocalizedText(trendIndicator, language, 'labelUnit');
  let labelValue = round(get(datum, 'value', 0), decimals);

  if (isNaN(labelValue)) {
    labelValue = 0;
  }

  const labelText = get(labels, [labelValue, language]);

  if (labelText) {
    return labelText;
  }

  // Some trend indicators do not have labels, add labelUnit
  if (labelUnit) {
    return `${d3Format.format(`.${decimals}f`)(labelValue)} ${labelUnit}`;
  }

  // Return the value if the indicator has no labels or labelUnit
  return `${d3Format.format(`.${decimals}f`)(labelValue)}`;
};

const shouldShowGuideText = (trendIndicator: TrendIndicatorType): boolean => (
  !isEmpty(trendIndicator.labels) && !includes(HIDE_GUIDE_TEXT_IDS, trendIndicator.id)
);

const TrendGraph = (props: Props) => {
  const {
    startDate,
    endDate,
    width,
    height,
    trendIndicator,
    xPadding,
    yPadding,
  } = props;

  const {
    minimumValue = 0,
    maximumValue = 0,
    defaultValue = 0,
    graphConfig = {
      showThumbs: true,
      data: 'latest',
    },
  } = trendIndicator;

  const { i18n } = useTranslation();

  const svgRef = useRef(null);

  const memoizedGetLatestDataPerDayByTrendIndicatorIdSelector = useMemo(
    trendsSelectors.makeGetLatestDataPerDayByTrendIndicatorId,
    [],
  );

  const selector = memoizedGetLatestDataPerDayByTrendIndicatorIdSelector;

  const { graph: graphDataRaw, xAxis: xAxisDataRaw } = useSelector(
    (state: AppState) => (
      selector(
        state,
        trendIndicator.id,
        defaultValue,
        startDate,
        endDate,
        graphConfig.data,
        true,
      )
    ),
  );

  let max = maximumValue;
  let min = minimumValue;

  let graphData = graphDataRaw;

  // The PSA level uses Math.log on all values if there is a value > 40
  if (trendIndicator.type === 'psa') {
    (
      {
        graphData,
        maxValue: max,
        minValue: min,
      } = graph.getLogData(graphDataRaw, xAxisDataRaw, 40, minimumValue)
    );
  }

  // Sort graph data
  const sortedGraphData = sortBy(graphData, 'date');

  // Remove data that is not selectable (implicit)
  const sortedSelectableGraphData = reject(sortedGraphData, ['selectable', false]);

  const {
    line,
    scaleX,
    scaleY,
    xAxis,
  } = setUpTrendGraph({
    startDate,
    endDate,
    height,
    maximumValue: max,
    minimumValue: min,
    width,
    xPadding,
    yPadding,
  });

  const getSvg = () => d3.select(svgRef.current);

  // This has to be a function for some reason...
  function onMousemove(this: any): void {
    handleTooltipMouseMove(
      this,
      scaleX,
      scaleY,
      trendIndicator,
      sortedSelectableGraphData,
      getTooltipText,
      getSvg(),
      50,
      i18n.language,
    );
  }

  const onMouseOver = () => {
    const svg = getSvg();

    svg.select('.trend-graph-focus').style('display', null);
    svg.select('.trend-series').style('stroke-width', '3px');
  };

  const onMouseOut = () => {
    const svg = getSvg();

    svg.select('.trend-graph-focus').style('display', 'none');
    svg.select('.trend-series').style('stroke-width', '2px');
  };

  const points: Point[] = map(sortedSelectableGraphData, ({ date, value, labelValue }) => (
    new Point(
      clamp(scaleX(date) || 0, 0, width) as number,
      scaleY(value) as number,
      trendIndicator.id,
      labelValue,
    )
  ));

  useEffect(() => {
    const svg = getSvg();

    (svg.select('.axis-line') as d3.Selection<
    SVGGElement,
    unknown,
    null,
    undefined
    >).call(xAxis);

    points.forEach((point, index) => {
      svg
        .select(`.trend-graph-focus-circle.index-${index}`)
        .attr('transform', `translate(${point.x}, ${point.y})`);
    });

    svg
      .select('.trend-series')
      .data([sortedGraphData])
      .attr('d', (d: any) => line(d));

    svg.select('.trend-graph-tooltip-overlay').on('mousemove', onMousemove);
  });

  const circles = map(points, (_, index) => <circle key={index} className={`trend-graph-focus-circle index-${index}`} style={{ fill: trendIndicator.color }} r={5} />);

  return (
    <svg ref={svgRef} width={width} height={height}>
      <SvgTrendPlot
        width={width}
        height={height}
        xPadding={xPadding}
        yPadding={yPadding}
        showGuideText={shouldShowGuideText(trendIndicator)}
        labelUnit={getLocalizedText(trendIndicator.labelUnit, i18n.language)}
        showTooltipTitle={false}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      >
        <>
          <path className="trend-series" style={{ stroke: trendIndicator.color }} />
          {circles}
        </>
      </SvgTrendPlot>
    </svg>
  );
};

export default TrendGraph;
