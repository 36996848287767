import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { AppState } from '../state/reducers';
import { AppointmentType } from '../../types';

import { selectors as appointmentsSelectors } from '../state/appointments';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRightHeader from '../components/DrawerRightHeader';
import Appointment from '../components/Appointment';

type Params = {
  appointmentId: string;
};

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const AppointmentScreen = () => {
  const { t } = useTranslation();

  const { appointmentId } = useParams<Params>();

  const memoizedAppointmentsGetByIdSelector = useMemo(
    appointmentsSelectors.makeGetById,
    [],
  );

  const appointment = useSelector(
    (state: AppState) => memoizedAppointmentsGetByIdSelector(state, appointmentId),
  ) as AppointmentType;

  return (
    <article className="page appointment row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('appointment')} showRightButton />
          <Appointment appointment={appointment} />
        </section>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default AppointmentScreen;
