const prefix = 'APPOINTMENTS';

const ADD = `${prefix}/ADD`;
const ADD_PHOTOS = `${prefix}/ADD_PHOTOS`;
const ADD_AUDIO_RECORDINGS = `${prefix}/ADD_AUDIO_RECORDINGS`;
const ADD_NOTES = `${prefix}/ADD_NOTES`;
const REMOVE = `${prefix}/REMOVE`;
const REMOVE_PHOTOS = `${prefix}/REMOVE_PHOTOS`;
const REMOVE_AUDIO_RECORDINGS = `${prefix}/REMOVE_AUDIO_RECORDINGS`;
const UPDATE = `${prefix}/UPDATE`;
const CLEAR = `${prefix}/CLEAR`;

export {
  ADD,
  ADD_PHOTOS,
  ADD_AUDIO_RECORDINGS,
  ADD_NOTES,
  REMOVE,
  REMOVE_PHOTOS,
  REMOVE_AUDIO_RECORDINGS,
  UPDATE,
  CLEAR,
};
