import { TDrugModule } from '../../types';

const black = '#2C2D30';

const darkGray = '#717274';

const lightGray = '#A0A0A2';

const softGray = '#E8E8E8';

const white = '#FFF';

const offWhite = '#F9F9F9';

const darkPrimary = '#6248B6'; // OBC
// const darkPrimary = '#084637'; // OPC

const primary = '#8068CC'; // OBC
// const primary = '#0B643D'; // OPC

const lightPrimary = '#B0A0E0'; // OBC
// const lightPrimary = '#A6D0BE'; // OPC

const softPrimary = '#E5DDFF'; // OBC
// const softPrimary = '#D1E6DD'; // OPC

const softerPrimary = '#F7F5FE'; // OBC
// const softerPrimary = '#F5F8F5'; // OPC

const secondary = '#32ACF9'; // OBC
// const secondary = '#3943F6'; // OPC

const softSecondary = '#A2D7F9'; // OBC
// const softSecondary = '#A0A4F6'; // OPC

const errorRed = '#EE1C10';

const destructiveRed = '#EE1C10';

const warningOrange = '#E97509';

const informationalYellow = '#F8E8CE';

const successGreen = '#00901E';

const navigationInactive = '#D2CCE6'; // OBC
// const navigationInactive = '#C5DBD1'; // OPC

const navigationActive = '#8068CC'; // OBC
// const navigationActive = '#0B643D'; // OPC

const recordingRed = '#E8101E';

const calendar = {
  calendarBackground: softPrimary,
  textSectionTitleColor: primary,
  selectedDayBackgroundColor: secondary,
  todayTextColor: secondary,
  dayTextColor: primary,
  dotColor: primary,
  arrowColor: secondary,
  monthTextColor: secondary,
  textMonthFontFamily: 'Avenir-Book',
  textDayHeaderFontFamily: 'Avenir-Book',
};

type TDrugModuleColors = {
  primary: { [key in TDrugModule]: string },
  secondary: { [key in TDrugModule]: string }
};

const drugGreen = '#8DB200';
const drugOrange = '#ED6813';
const drugBlue = '#004E74';

const drugModuleColors: TDrugModuleColors = {
  primary: {
    enhertu: drugOrange,
    lynparza: drugBlue,
  },
  secondary: {
    enhertu: drugOrange,
    lynparza: drugGreen,
  },
};

export default {
  black,
  darkGray,
  lightGray,
  softGray,
  white,
  offWhite,
  darkPrimary,
  primary,
  lightPrimary,
  softPrimary,
  softerPrimary,
  secondary,
  softSecondary,
  errorRed,
  destructiveRed,
  warningOrange,
  informationalYellow,
  successGreen,
  navigationInactive,
  navigationActive,
  calendar,
  recordingRed,
  drugModuleColors,
};
