import {
  put, select, takeEvery, takeLatest,
} from 'redux-saga/effects';
import differenceInHours from 'date-fns/differenceInHours';
import { sprintf } from 'sprintf-js';
import fromUnixTime from 'date-fns/fromUnixTime';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInWeeks from 'date-fns/differenceInWeeks';
import startOfDay from 'date-fns/startOfDay';
import map from 'lodash/map';
import difference from 'lodash/difference';
import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';
import first from 'lodash/first';
import size from 'lodash/size';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import find from 'lodash/find';
import slice from 'lodash/slice';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import concat from 'lodash/concat';

import { TIntervalTrigger, MessageType, TDrugModule } from '../../types';

import {
  actions as dailySuggestionsActions,
  actionTypes as dailySuggestionsActionTypes,
  DailySuggestionsAddPayload,
  DailySuggestionsCheckActionType,
  DailySuggestionsIntervalTriggerActionType,
  DailySuggestionsTriggerActionType,
  DailySuggestionsUpdatePayload,
  selectors as dailySuggestionsSelectors,
} from '../state/dailySuggestions';
import { actions as messagesActions, selectors as messagesSelectors } from '../state/messages';
import { selectors as trendsSelectors } from '../state/trends';
import { selectors as treatmentsSelectors } from '../state/treatments';
import { selectors as audioRecordingsSelectors } from '../state/audioRecordings';
import { selectors as settingsSelectors, actions as settingsActions } from '../state/settings';
import { selectors as questionnairesSelectors } from '../state/questionnaires';
import { selectors as appointmentsSelectors } from '../state/appointments';
import { selectors as notesSelectors } from '../state/notes';
import { selectors as photosSelectors } from '../state/photos';
import { selectors as templatesSelectors } from '../state/templates';
// import { selectors as tagsSelectors } from '../state/tags';
import getProfileProgress from '../selectors/getProfileProgress';
import getUnixTime from '../utils/getUnixTime';
import localConfig from '../config';

import { suggestions } from '../../data/system-document.json';
import { getIntervalTriggerDate, getTemplateMessageId, intervalTriggerIds } from '../utils/intervalTrigger';
import { guideSuggestionIds } from '../utils/idsData';

function* putIntervalTrigger(ids: string[]) {
  yield put(dailySuggestionsActions.intervalTrigger({
    ids,
    forceUpdate: true,
  }));
}

function* trigger(action: DailySuggestionsTriggerActionType): Generator<any, any, any> {
  const {
    payload: {
      ids,
      close,
      forceClose,
    },
  } = action;

  if (isEmpty(ids)) {
    return false;
  }

  const updateActions: DailySuggestionsUpdatePayload = [];
  const addActions: DailySuggestionsAddPayload = [];

  for (let i = 0; i < size(ids); i += 1) {
    const id = get(ids, [i]);
    const suggestion = find(suggestions, { id });
    const dailySuggestionsGetByIdSelector = dailySuggestionsSelectors.makeGetById();
    // const tagsMakeGetValueByKeySelector = tagsSelectors.makeGetValueByKey();

    const dailySuggestion = yield select(dailySuggestionsGetByIdSelector, id);

    // const cohorts = yield select(tagsMakeGetValueByKeySelector, 'cohorts');

    // Skip non-existent suggestion
    if (!suggestion) {
      continue; // eslint-disable-line no-continue
    }

    const update = () => (
      updateActions.push({
        ...dailySuggestion,
        id,
        closedAt: getUnixTime(),
        closed: true,
      })
    );

    const add = (params?: any) => (
      addActions.push({
        id,
        createdAt: getUnixTime(),
        order: suggestion.order,
        closed: false,
        params,
      })
    );

    const addWithCustomId = (suggestionId: string, params?: any) => (
      addActions.push({
        id: suggestionId,
        createdAt: getUnixTime(),
        order: suggestion.order,
        closed: false,
        params,
      })
    );

    const updateWithCondition = (condition:any) => {
      if (close) {
        if (condition) {
          update();
        }
      }
    };

    const addWithCondition = (condition: any, params?: any) => {
      if (!close && condition) {
        add(params);
      }
    };

    switch (suggestion.trigger) {
      case 'welcomeMessage': {
        const messages = yield select(messagesSelectors.getAll);
        const message = last(messages);

        if (isEmpty(message)) {
          continue; // eslint-disable-line no-continue
        }

        const { readAt, id: messageId } = message as MessageType;

        // Close open suggestion if message has been read
        updateWithCondition(readAt);

        // Message hasn't been read, add suggestion
        addWithCondition(!readAt, { messageId });

        break;
      }
      case 'addTrends': {
        const count = yield select(trendsSelectors.count);

        updateWithCondition(count > 0);

        addWithCondition(count === 0);

        break;
      }
      case 'addTreatment': {
        const count = yield select(treatmentsSelectors.count);

        updateWithCondition(count > 0);

        addWithCondition(count === 0);

        break;
      }
      case 'addAudioRecording': {
        const count = yield select(audioRecordingsSelectors.count);

        updateWithCondition(count > 0);

        addWithCondition(count === 0);

        break;
      }
      case 'addFavoriteTrends': {
        const favoriteTrendIds = yield select(settingsSelectors.getFavoriteTrendIndicatorIds);
        const count = size(favoriteTrendIds);

        updateWithCondition(count > 0);

        addWithCondition(count === 0);

        break;
      }
      case 'addQuestionnaire': {
        const count = yield select(questionnairesSelectors.count);

        updateWithCondition(count > 0);

        addWithCondition(count === 0);

        break;
      }
      case 'addDiaryItem': {
        const appointmentsCount = yield select(appointmentsSelectors.count);
        const notesCount = yield select(notesSelectors.count);
        const photosCount = yield select(photosSelectors.count);
        const audioRecordingsCount = yield select(audioRecordingsSelectors.count);

        updateWithCondition(appointmentsCount > 0
          || notesCount > 0
          || photosCount > 0
          || audioRecordingsCount > 0);

        addWithCondition(appointmentsCount === 0
          && notesCount === 0
          && photosCount === 0
          && audioRecordingsCount === 0);

        break;
      }
      case 'viewProfileComplete': {
        const views = 0;
        const { completed: profileCompleted } = yield select(getProfileProgress);

        updateWithCondition(profileCompleted || views > 0);

        addWithCondition(!profileCompleted && views === 0);

        break;
      }
      case 'viewTrendsSettings': {
        const views = 0;

        updateWithCondition(views > 0);

        addWithCondition(views === 0);

        break;
      }
      case 'viewGlossary': {
        const views = 0;

        updateWithCondition(views > 0);

        addWithCondition(views === 0);

        break;
      }
      case 'viewUsefulLinks': {
        const views = 0;

        updateWithCondition(views > 0);

        addWithCondition(views === 0);

        break;
      }
      case 'viewQuestionnaires': {
        const views = 0;
        const { completed: profileCompleted } = yield select(getProfileProgress);

        updateWithCondition(profileCompleted && views > 0);

        addWithCondition(profileCompleted && views === 0);

        break;
      }
      case 'viewTreatmentReport': {
        const views = 0;
        const { completed: profileCompleted } = yield select(getProfileProgress);

        updateWithCondition(profileCompleted && views > 0);

        addWithCondition(profileCompleted && views === 0);

        break;
      }
      case 'viewProfileQuestionCategories': {
        const views = 0;
        const { completed: profileCompleted } = yield select(getProfileProgress);

        updateWithCondition(profileCompleted && views >= 2);

        addWithCondition(profileCompleted && views < 2);

        break;
      }
      case 'reviewRating': {
        const state = yield select();

        const keys = [
          'appointments',
          'notes',
          'photos',
          'audioRecordings',
          'treatments',
          'trends',
          'questionnaires',
          'profileQuestionAnswers',
        ];

        const now = Date.now();
        const daysPerWeek: { [index: number]: number[] } = {};

        // Get days per week relative from today from state keys.
        // Note that updatedAt has precedence over createdAt
        map(keys, (key) => (
          map(get(state, [key]), ({ createdAt, updatedAt }) => {
            const timestamp = (updatedAt || createdAt) * 1000;
            const day = differenceInDays(startOfDay(now), startOfDay(timestamp));
            const week = differenceInWeeks(startOfDay(now), startOfDay(timestamp));

            // Ignore days in the future
            if (day < 0) {
              return false;
            }

            if (!daysPerWeek[week]) {
              daysPerWeek[week] = [day];
            } else {
              const days = daysPerWeek[week];
              daysPerWeek[week] = uniq(concat(days, [day]));
            }

            return true;
          })
        ));

        // First two weeks: 3 days per week
        if (size(daysPerWeek[0]) >= 3 && size(daysPerWeek[1]) >= 3) {
          add();

          break;
        }

        // Once a week for four weeks
        if (
          size(daysPerWeek[0]) >= 1
          && size(daysPerWeek[1]) >= 1
          && size(daysPerWeek[2]) >= 1
          && size(daysPerWeek[3]) >= 1
        ) {
          add();

          break;
        }

        break;
      }
      case 'drugModule': {
        if (close) {
          // update();

          break;
        }

        if (forceClose) {
          update();

          break;
        }

        const drugModuleSupportIds = {
          enhertu: '03841889-9ac9-48aa-949c-ef0f93276c4a',
          lynparza: 'c8d99bee-7151-4905-be42-edc574eb51af',
        };

        const researchIds = {
          enhertu: '5a82e189-24c0-4c93-a8cd-5105967a330c',
          lynparza: '6d241d3a-c8a3-4ff8-92f2-f520005a605b',
        };

        const drugModule = suggestion.drugModule as TDrugModule;

        if (!drugModule) {
          break;
        }

        if (drugModule === 'enhertu') {
          yield putIntervalTrigger([
            intervalTriggerIds[drugModule].fitness,
            intervalTriggerIds[drugModule].breathing,
            intervalTriggerIds[drugModule].treatment,
            intervalTriggerIds[drugModule].dosage,
            intervalTriggerIds[drugModule].researchForm,
            intervalTriggerIds[drugModule].myResearch,
          ]);
        } else if (drugModule === 'lynparza') {
          yield putIntervalTrigger([
            intervalTriggerIds[drugModule].fitness,
            intervalTriggerIds[drugModule].fatigue,
            intervalTriggerIds[drugModule].treatment,
            intervalTriggerIds[drugModule].dosage,
            intervalTriggerIds[drugModule].researchForm,
            intervalTriggerIds[drugModule].myResearch,
          ]);
        }

        addWithCustomId(
          researchIds[drugModule],
          { drugModule },
        );

        add({ drugModule, messageId: drugModuleSupportIds[drugModule] });

        addWithCustomId(guideSuggestionIds[drugModule], { drugModule });

        break;
      }
      case 'drugModuleGuide': {
        if (close) {
          // update();

          break;
        }

        if (forceClose) {
          update();

          break;
        }

        add({ drugModule: suggestion.drugModule });

        break;
      }
      case 'drugModuleTestFitness': {
        if (close) {
          // update();

          break;
        }

        if (forceClose) {
          update();

          break;
        }

        add({ drugModule: suggestion.drugModule });

        break;
      }
      default:
        break;
    }
  }

  if (!isEmpty(updateActions)) {
    yield put(dailySuggestionsActions.update(updateActions));
  }

  if (!isEmpty(addActions)) {
    yield put(dailySuggestionsActions.add(addActions));
  }

  if (__DEV__) {
    console.log(sprintf('[dailySuggestions.trigger] %s update(s)', size(updateActions))); // eslint-disable-line no-console
    console.log(sprintf('[dailySuggestions.trigger] %s addition(s)', size(addActions))); // eslint-disable-line no-console
  }

  return true;
}

function* check(action: DailySuggestionsCheckActionType): Generator<any, any, any> {
  const { payload } = action;

  // Check if test mode is enabled
  const test = payload === true;

  const dailySuggestions = yield select(dailySuggestionsSelectors.getAll);

  const filterDailySuggestion = (closed:boolean) => filter(dailySuggestions, { closed });

  const openDailySuggestions = filterDailySuggestion(false);
  const closedDailySuggestions = filterDailySuggestion(true);
  const openDailySuggestionIds = map(openDailySuggestions, 'id');
  const closedDailySuggestionsIds = map(closedDailySuggestions, 'id');
  const totalOpenDailySuggestions = size(openDailySuggestions);

  const isFirstLaunch = yield select(
    settingsSelectors.getIsFirstLaunch,
  );

  let maxNewDailySuggestions = localConfig.dailySuggestions.max - totalOpenDailySuggestions;

  if (isFirstLaunch) {
    maxNewDailySuggestions = 5;
  }

  // Allow all suggestions in test mode
  if (test) {
    maxNewDailySuggestions = size(suggestions);
  }

  if (!isEmpty(openDailySuggestionIds)) {
    if (__DEV__) {
      console.log('[dailySuggestions.check] Execute trigger for open daily suggestions, close if needed'); // eslint-disable-line no-console
    }

    // Close daily suggestion if the trigger condition changed
    yield put(dailySuggestionsActions.trigger({ ids: openDailySuggestionIds, close: true }));
  }
  if (!isEmpty(closedDailySuggestions)) {
    yield put(dailySuggestionsActions.intervalTrigger({ ids: closedDailySuggestionsIds }));
  }

  // Only display a maximum of 5 daily suggestions
  // There is no maximum in test mode
  if (totalOpenDailySuggestions >= localConfig.dailySuggestions.max) {
    if (__DEV__) {
      console.log('[dailySuggestions.check] Open daily suggestions >= 5, skipping'); // eslint-disable-line no-console
    }

    return false;
  }

  // Get last created daily suggestions
  const latestDailySuggestion = first(orderBy(dailySuggestions, ['createdAt'], ['desc']));

  // Make sure the last created daily suggestions was added > 24hrs
  if (latestDailySuggestion && !isFirstLaunch) {
    const { createdAt } = latestDailySuggestion;
    const hoursAgo = differenceInHours(new Date(), fromUnixTime(createdAt));

    // Ignore intervalInHours when in test mode
    if (!test && hoursAgo <= localConfig.dailySuggestions.intervalInHours) {
      if (__DEV__) {
        // eslint-disable-next-line no-console
        console.log(sprintf(
          '[dailySuggestions.check] Latest daily suggestion was added <= %s hours ago, skipping',
          localConfig.dailySuggestions.intervalInHours,
        ));
      }

      return false;
    }
  }

  if (isFirstLaunch) {
    yield put(settingsActions.setIsFirstLaunch(false));
  }

  // Get new suggestion Ids
  const suggestionIds = uniq(map(orderBy(suggestions, ['order'], ['asc']), 'id'));
  const dailySuggestionIds = uniq(map(dailySuggestions, 'id'));

  const newSuggestionIds = (
    slice(difference(suggestionIds, dailySuggestionIds), 0, maxNewDailySuggestions)
  );

  // Return if there are no new suggestions
  if (size(newSuggestionIds) === 0) {
    if (__DEV__) {
      console.log('[dailySuggestions.check] No new suggestions available'); // eslint-disable-line no-console
    }

    return false;
  }

  if (__DEV__) {
    console.log('[dailySuggestions.check] Executing trigger for new suggestions'); // eslint-disable-line no-console
  }

  // Execute trigger for new suggestions
  yield put(dailySuggestionsActions.trigger({ ids: newSuggestionIds }));

  return true;
}

function* intervalTrigger(
  action: DailySuggestionsIntervalTriggerActionType,
): Generator<any, any, any> {
  const {
    payload: {
      ids,
      close,
      forceUpdate,
      testMode,
    },
  } = action;

  if (isEmpty(ids)) {
    return false;
  }

  const dailySuggestionsGetByIdSelector = dailySuggestionsSelectors.makeGetById();
  const addActions: DailySuggestionsAddPayload = [];
  const updateActions: DailySuggestionsUpdatePayload = [];

  for (let i = 0; i < size(ids); i += 1) {
    const id = get(ids, [i]);

    const suggestion = find(suggestions, { id });

    if (!suggestion) {
      continue; // eslint-disable-line no-continue
    }

    const dailySuggestion = yield select(dailySuggestionsGetByIdSelector, id);

    const add = (triggerAt: TIntervalTrigger, closed: boolean, options?: {}) => {
      addActions.push({
        id,
        createdAt: getUnixTime(),
        order: suggestion.order,
        closed,
        triggerAt,
        params: { drugModule: suggestion.drugModule || '', ...(options || {}) },
      });
    };

    const update = (triggerAt: TIntervalTrigger) => {
      const updateObject = { ...dailySuggestion, id, triggerAt };

      if (close) {
        updateObject.closedAt = getUnixTime();
        updateObject.closed = true;
      }

      updateActions.push(updateObject);
    };

    if (close) {
      update(null);

      continue; // eslint-disable-line no-continue
    }

    if (forceUpdate) {
      const intervalTriggerDate = getIntervalTriggerDate(
        suggestion.drugModule as TDrugModule, id, testMode,
      );

      if (!intervalTriggerDate) {
        continue; // eslint-disable-line no-continue
      }

      if (dailySuggestion.id) {
        // update current suggestion
        update(intervalTriggerDate);

        continue; // eslint-disable-line no-continue
      }
      // add if no daily suggestions with the current id
      add(intervalTriggerDate, true);

      continue; // eslint-disable-line no-continue
    }

    const { triggerAt } = yield select(dailySuggestionsGetByIdSelector, id);

    // open a suggestion if a closed daily suggestion has the intervalTrigger field
    if (triggerAt && triggerAt <= getUnixTime()) {
      const templateMessageId = getTemplateMessageId(
        suggestion.drugModule as TDrugModule, id, testMode,
      );

      if (templateMessageId) { // message creation
        const drugsTemplate = yield select(templatesSelectors.get, 'contentModules');
        add(null, false, { messageId: templateMessageId });
        const message = get(drugsTemplate, ['drug', suggestion.drugModule!, 'messages', templateMessageId]);
        if (!isEmpty(message)) {
          yield put(messagesActions.add([{ ...message, createdAt: getUnixTime() }]));
        }
      } else {
        add(null, false);
      }
    }
  }

  if (!isEmpty(updateActions)) {
    yield put(dailySuggestionsActions.update(updateActions));
  }

  if (!isEmpty(addActions)) {
    yield put(dailySuggestionsActions.add(addActions));
  }

  return true;
}

export default function* watchDailySuggestions() {
  yield takeLatest(dailySuggestionsActionTypes.CHECK, check);
  yield takeEvery(dailySuggestionsActionTypes.TRIGGER, trigger);
  yield takeEvery(dailySuggestionsActionTypes.INTERVAL_TRIGGER, intervalTrigger);
}
