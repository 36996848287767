import React from 'react';
import { useTranslation } from 'react-i18next';

import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRightHeader from '../components/DrawerRightHeader';
import Appointment from '../components/Appointment';

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const AppointmentAdd = () => {
  const { t } = useTranslation();

  return (
    <article className="page appointment row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('add-appointment')} showRightButton />
          <Appointment />
        </section>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default AppointmentAdd;
