import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import * as yup from 'yup';
import DatePicker from 'react-datepicker';
import { useHistory } from 'react-router-dom';

import size from 'lodash/size';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { TPhoto } from '../../types';
import { PhotoPropType } from '../../propTypes';

import Modal from './Modal';
import Button from './Button';
import { actions as photosActions } from '../state/photos';
import { actions as apiActions } from '../state/api';

import { text as textSchema, date as dateSchema } from '../utils/schemas';
import { formValidation, useFormFieldValidation } from '../utils/formValidation';
import defaultGet from '../utils/defaultGet';
import isOfType from '../utils/isOfType';
import getUnixTime from '../utils/getUnixTime';
import Colors from '../theme/Colors';
import '../css/Photo.css';
import toDate from '../utils/toDate';
import { ReactComponent as IconPhoto } from '../assets/icons/Photo.svg';

type TFormErrors = {
  title?: string;
  createdAt?: string;
};

type Props = {
  photo?: TPhoto;
};

const propTypes = {
  photo: PhotoPropType,
};

const Photo = (props: Props) => {
  const { photo = {} } = props;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const history = useHistory();

  const [visible, setVisible] = useState(false);
  const [errors, setErrors] = useState<TFormErrors>({});
  const [submitted, setSubmitted] = useState(false);

  const titleSchema = textSchema(true, t('errors:field-required', { field: t('title') }));
  const createdAtSchema = dateSchema(true, t('errors:field-required', { field: t('date-time') }));

  const [title, setTitle] = useFormFieldValidation(
    titleSchema,
    'title',
    defaultGet(photo, 'title', ''),
    submitted,
    errors,
    setErrors,
  );

  const [pickedPhoto, setPickedPhoto] = useState<TPhoto>();
  const [file, setFile] = useState<File>();
  const [initialTitle, setInitialTitle] = useState('');

  const [createdAt, setCreatedAt] = useFormFieldValidation(
    createdAtSchema,
    'createdAt',
    defaultGet(photo, 'createdAt', getUnixTime()),
    submitted,
    errors,
    setErrors,
  );

  const uri = get(photo, 'contentPermalink')
    || get(photo, 'source.uri')
    || get(photo, 'uri');

  const handleSave = () => {
    const schema = yup
      .object()
      .shape({
        title: titleSchema,
      });

    setSubmitted(true);
    setErrors({});

    const formData = {
      title,
    };
    if (!formValidation(schema, formData, setErrors)) {
      return false;
    }

    if (!!initialTitle && initialTitle !== title) {
      dispatch(photosActions.update([{ ...photo, title } as TPhoto]));
    }

    if (pickedPhoto) {
      const photoForUpload = { ...pickedPhoto, title };

      dispatch(apiActions.BLOBPost({
        data: photoForUpload,
        type: 'photo',
        contentType: 'image/jpeg',
        file: file!,
      }));
    }

    return history.goBack();
  };

  const handleRemove = () => {
    if (!isOfType<TPhoto>(photo, 'id')) {
      return false;
    }

    dispatch(photosActions.remove([photo.id]));
    dispatch(photosActions.cleanup([photo]));

    return history.goBack();
  };

  const onChangePhoto = (e: ChangeEvent<HTMLInputElement>) => {
    const imgFile = e.target.files![0];
    const fileReader = new FileReader();
    setFile(imgFile);

    fileReader.addEventListener('load', () => {
      const img = document.createElement('img');
      img.className = 'photo';
      img.src = fileReader.result as string;

      img.onload = () => {
        setPickedPhoto({
          id: uuidv4(),
          createdAt,
          width: img.naturalWidth,
          height: img.naturalHeight,
          title,
          uri: img.src,
        });
      };

      const imgPlaceholder = document.querySelector('.photo-placeholder') as HTMLElement;

      imgPlaceholder.innerHTML = '';
      imgPlaceholder.appendChild(img);
    });

    fileReader.readAsDataURL(imgFile);
  };

  const saveButtonDisabled = get(photo, 'title') === title || !title || (!get(photo, 'uri') && !pickedPhoto?.uri);

  useEffect(() => {
    setInitialTitle(title);
  }, []);

  return (
    <>
      <section className="photo-content">
        <label // eslint-disable-line jsx-a11y/label-has-associated-control
          htmlFor="createdAt"
        >
          <span className="body light">{t('date-time')}</span>
          <DatePicker
            selected={toDate(createdAt)}
            onChange={(date: Date) => setCreatedAt(date)}
            showTimeSelect
            dateFormat="dd/LL/yyyy HH:mm"
            timeFormat="HH:mm"
            id="createdAt"
            disabled={get(photo, 'uri')}
          />
          {
            errors.createdAt && <p className="body error">{t(errors.createdAt)}</p>
          }
        </label>
        <label htmlFor="title">
          <span className="body light">{t('title')}</span>
          <input
            value={title}
            onChange={(event) => setTitle(event.currentTarget.value)}
            name="title"
            id="title"
          />
          {
            errors.title && <p className="body error">{t(errors.title)}</p>
          }
        </label>

        <label className={classNames('photo-picker')} htmlFor="user_photo_input">
          <input disabled={uri || size(photo)} className="photo-input" name="user_photo_input" id="user_photo_input" type="file" accept="image/*" onChange={onChangePhoto} />
          <div className="photo-placeholder">
            {uri
              ? <img src={uri} alt={title} className="photo" />
              : (
                <div className="add-photo-wrapper">
                  <IconPhoto fill={Colors.primary} className="icon large" />
                  <p className="heading bolder">{t('add-photo')}</p>
                </div>
              )}
          </div>
        </label>

        <section className="buttons">
          {
            isOfType<TPhoto>(photo, 'id')
              ? (
                <Button
                  labelText={t('delete')}
                  onClick={() => setVisible(true)}
                  color={Colors.destructiveRed}
                  size="medium"
                />
              )
              : null
          }
          <Button
            labelText={t('save')}
            onClick={handleSave}
            size="medium"
            disabled={saveButtonDisabled}
          />
        </section>
      </section>
      <Modal
        visible={visible}
        hideModal={() => setVisible(false)}
        actions={[
          {
            title: t('cancel'),
            onClick: () => setVisible(false),
          },
          {
            title: t('delete'),
            onClick: handleRemove,
            destructive: true,
          },
        ]}
      >
        <h1 className="heading">{t('alerts.delete.title', { w: t('photo') })}</h1>
        <p className="body">{t('alerts.delete.body', { w: t('photo') })}</p>
      </Modal>
    </>
  );
};

Photo.propTypes = propTypes;

export default Photo;
