import { createSelector } from 'reselect';
import get from 'lodash/get';
import toArray from 'lodash/toArray';
import find from 'lodash/find';
import includes from 'lodash/includes';

import { AppState } from '../reducers';
import { ProfileQuestionCategoriesState } from './types';
import { ProfileQuestionCategoryReturnType, ProfileQuestionCategoriesReturnType } from '../../../types';

import EMPTY_OBJECT from '../../utils/empty-object';

const getByIdSelector = (
  state: ProfileQuestionCategoriesState,
  id: string,
): ProfileQuestionCategoryReturnType => (
  get(state, [id]) || EMPTY_OBJECT
);

const getById = createSelector(
  (state: AppState) => state.profileQuestionCategories,
  (state: any, id: string) => id,
  getByIdSelector,
);

const makeGetById = () => (
  createSelector(
    (state: AppState) => state.profileQuestionCategories,
    (state: any, id: string) => id,
    getByIdSelector,
  )
);

const getByProfileQuestionIdSelector = (
  state: ProfileQuestionCategoriesState, id: string,
): ProfileQuestionCategoryReturnType => (
  find(
    state,
    ({ profileQuestionIds }) => includes(profileQuestionIds, id),
  ) || EMPTY_OBJECT
);

const getByProfileQuestionId = createSelector(
  (state: AppState) => state.profileQuestionCategories,
  (state: any, id: string) => id,
  getByProfileQuestionIdSelector,
);

const getAllSelector = (
  state: ProfileQuestionCategoriesState,
): ProfileQuestionCategoriesReturnType => (
  toArray(state)
);

const getAll = createSelector(
  (state: AppState) => state.profileQuestionCategories,
  getAllSelector,
);

export {
  getById,
  makeGetById,
  getByProfileQuestionId,
  getAll,
};
