/* eslint react/require-default-props: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Path } from 'react-native-svg';
import ResizeableSVG from '../ResizeableSVG';

import Colors from '../../theme/Colors';
import Constants from '../../theme/Constants';

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

type Props = {
  color?: string;
  size?: number;
};

const Report = (props: Props) => {
  const {
    color = Colors.primary,
    size = Constants.defaultIconSize,
  } = props;

  return (
    <ResizeableSVG size={size}>
      <Path d="M17.2542878,1.5 C17.9421095,1.5 18.5995462,1.78338575 19.0718207,2.28344117 L19.0718207,2.28344117 L23.3175329,6.77890111 C23.755826,7.24297619 24,7.85712888 24,8.49545994 L24,8.49545994 L24,24 C24,25.3807119 22.8807119,26.5 21.5,26.5 L21.5,26.5 L6.5,26.5 C5.11928813,26.5 4,25.3807119 4,24 L4,24 L4,4 C4,2.61928813 5.11928813,1.5 6.5,1.5 L6.5,1.5 Z M16.812,2.749 L6.5,2.75 C5.80964406,2.75 5.25,3.30964406 5.25,4 L5.25,4 L5.25,24 C5.25,24.6903559 5.80964406,25.25 6.5,25.25 L6.5,25.25 L21.5,25.25 C22.1903559,25.25 22.75,24.6903559 22.75,24 L22.75,24 L22.75,8.687 L18.6875,8.6875 C17.6969893,8.6875 16.8858677,7.91944407 16.8172078,6.94640484 L16.8125,6.8125 L16.812,2.749 Z M11.2445566,15.7995571 L12.7234601,15.8039801 C13.0686365,15.8050124 13.3476204,16.08567 13.346591,16.4308465 C13.3456418,16.7472582 13.1097326,17.0080498 12.8045472,17.0485226 L12.7197217,17.0539773 L11.243445,17.0495539 L7.59450758,17.0539773 C7.24932986,17.0543952 6.96916886,16.7749126 6.96874999,16.4297348 C6.96836693,16.1133219 7.20317803,15.8515411 7.50819061,15.809786 L7.59299242,15.8039777 L11.2445566,15.7995571 Z M19.46875,11.4857955 C19.813928,11.4857955 20.09375,11.7656175 20.09375,12.1107955 C20.09375,12.4272086 19.8586218,12.6887046 19.5535588,12.7300899 L19.46875,12.7357955 L7.59375,12.7357955 C7.24857203,12.7357955 6.96875,12.4559734 6.96875,12.1107955 C6.96875,11.7943823 7.20387823,11.5328863 7.5089412,11.491501 L7.59375,11.4857955 L19.46875,11.4857955 Z M18.0626668,3.04656606 L18.0625,6.8125 C18.0625,7.12891314 18.2976282,7.39040913 18.6026912,7.43179449 L18.6875,7.4375 L22.219,7.437 L18.1630543,3.14172058 C18.131212,3.10800523 18.0976863,3.07625979 18.0626668,3.04656606 Z" fill={color} />
    </ResizeableSVG>
  );
};

Report.propTypes = propTypes;

export default Report;
