import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';

import '../css/EmptyState.css';
import Header from '../components/Header';
import PageHeader from '../components/PageHeader';
import Footer from '../components/Footer';

type Props = {
  pageHeaderTitle?: string;
  documentTitle?: string;
  children: ReactNode;
  pageHeaderChildren?: ReactNode;
};

const propTypes = {
  pageHeaderTitle: PropTypes.string,
  documentTitle: PropTypes.string,
  children: PropTypes.node.isRequired,
};

const EmptyState = (props: Props) => {
  const {
    pageHeaderTitle,
    pageHeaderChildren = null,
    children,
    documentTitle,
  } = props;

  return (
    <article className="app empty-state">
      <Header auth documentTitle={documentTitle} />
      {
        pageHeaderTitle
          ? <PageHeader title={pageHeaderTitle}>{pageHeaderChildren}</PageHeader>
          : null
      }
      <section className="content">
        {children}
      </section>
      <Footer />
    </article>
  );
};

EmptyState.propTypes = propTypes;

export default EmptyState;
