import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import { selectors as UISelectors, actions as UIActions } from '../state/ui';
import { AppState } from '../state/reducers';
import { CrossIconContrast } from '../theme/Icons';
import '../css/Notification.css';

type Props = {
  position?: 'bottom' | 'top';
};

const propTypes = {
  position: PropTypes.string,
};

const Notification = (props: Props) => {
  const {
    position = 'top',
  } = props;

  const dispatch = useDispatch();

  const notification = (
    useSelector((state: AppState) => UISelectors.getNotification(state))
  );

  if (!notification.visible) {
    return null;
  }

  const clearNotification = () => dispatch(UIActions.clearNotification());

  return (
    <section className={classNames('notification', notification.type, position)}>
      <p className="body contrast">{notification.text}</p>
      <button type="button" onClick={clearNotification}>
        {CrossIconContrast}
      </button>
    </section>
  );
};

Notification.propTypes = propTypes;

export default Notification;
