import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import {
  ProfileQuestionCategoriesState,
  ProfileQuestionCategoriesReplaceActionType,
} from './types';

const replace = (
  state: ProfileQuestionCategoriesState, action: ProfileQuestionCategoriesReplaceActionType,
) => {
  const { payload } = action;

  if (isEmpty(payload) || isEqual(state, payload)) {
    return state;
  }

  return payload;
};

const clear = () => ({});

export {
  replace,
  clear,
};
