/* eslint react/require-default-props: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Path } from 'react-native-svg';
import ResizeableSVG from '../ResizeableSVG';

import Colors from '../../theme/Colors';
import Constants from '../../theme/Constants';

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

type Props = {
  color?: string;
  size?: number;
};

const Delete = (props: Props) => {
  const {
    color = Colors.primary,
    size = Constants.defaultIconSize,
  } = props;

  return (
    <ResizeableSVG size={size}>
      <Path d="m11.9 1.5c-.5496088 0-1.1115237.19124375-1.509375.5859375s-.590625.95214896-.590625 1.49739583v1.04166667h-6.3v2.08333333h1.05v16.66666667c0 1.7130531 1.42324245 3.125 3.15 3.125h12.6c1.7267575 0 3.15-1.4119469 3.15-3.125v-16.66666667h1.05v-2.08333333h-6.3v-1.04166667c0-.54524687-.1927737-1.10270208-.590625-1.49739583s-.9597651-.5859375-1.509375-.5859375zm0 2.08333333h4.2v1.04166667h-4.2zm-5.25 3.125h14.7v16.66666667c0 .5778-.4675776 1.0416667-1.05 1.0416667h-12.6c-.58242135 0-1.05-.4638667-1.05-1.0416667zm2.1 3.125v11.45833337h2.1v-11.45833337zm4.2 0v11.45833337h2.1v-11.45833337zm4.2 0v11.45833337h2.1v-11.45833337z" fill={color} fillRule="evenodd" />
    </ResizeableSVG>
  );
};

Delete.propTypes = propTypes;

export default Delete;
