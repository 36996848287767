import { Reducer } from 'redux';

import * as utils from './utils';
import * as actionTypes from './actionTypes';
import {
  DraftsState,
  DraftsActionTypes,
  DraftsSetActionType,
  DraftsUnsetActionType,
} from './types';

const initialState: DraftsState = {};

const reducer: Reducer<DraftsState, DraftsActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET: return utils.set(state, action as DraftsSetActionType);
    case actionTypes.UNSET: return utils.unset(state, action as DraftsUnsetActionType);
    case actionTypes.CLEAR: return utils.clear();
    default: return state;
  }
};

export default reducer;
