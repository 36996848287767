import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { AppState } from '../state/reducers';

import { selectors as messagesSelectors } from '../state/messages';
import { selectors as pushNotificationsSelectors } from '../state/notifications';
import { ReactComponent as IconMessage } from '../assets/icons/Message.svg';
import { ReactComponent as IconAccount } from '../assets/icons/Account.svg';
import Colors from '../theme/Colors';

const DrawerRightHeader = () => {
  const unreadMessages = useSelector((state: AppState) => messagesSelectors.unread(state));
  const unreadPushNotifications = useSelector((state: AppState) => (
    pushNotificationsSelectors.unread(state)));
  const unread = unreadMessages + unreadPushNotifications;

  return (
    <header className="drawer-header row between center">
      <Link to="/messages" className="unread">
        <IconMessage fill={Colors.primary} className="icon" />
        {unread > 0 ? <div className="badge">{unread > 9 ? '9+' : unread}</div> : null}
      </Link>
      <Link to="/profile">
        <IconAccount fill={Colors.primary} className="icon" />
      </Link>
    </header>
  );
};

export default DrawerRightHeader;
