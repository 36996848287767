/* eslint react/require-default-props: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Path } from 'react-native-svg';
import ResizeableSVG from '../ResizeableSVG';

import Colors from '../../theme/Colors';
import Constants from '../../theme/Constants';

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

type Props = {
  color?: string;
  size?: number;
};

const Reload = (props: Props) => {
  const {
    color = Colors.primary,
    size = Constants.defaultIconSize,
  } = props;

  return (
    <ResizeableSVG size={size}>
      <Path d="m13.3647453 2.2627827c.350377-.35037693.9184502-.35037693 1.2688271 0l4.4859813 4.48598131c.350377.35037693.350377.91845019 0 1.26882712l-4.4859813 4.48598127c-.3503769.350377-.9184501.350377-1.2688271 0-.3503769-.3503769-.3503769-.9184501 0-1.2688271l3.0903953-3.08915475c-.524903-.05278334-1.2480899-.083852-2.1852674-.08881261l-.2707143-.00070317c-4.45957034 0-8.07476638 3.61519603-8.07476638 8.07476633s3.61519604 8.0747664 8.07476638 8.0747664c4.4595703 0 8.0747663-3.6151961 8.0747663-8.0747664l.0001703-.0176563c.009407-.4873586.4074171-.8795399.897026-.8795399.4955078 0 .8971963.4016884.8971963.8971962 0 5.450586-4.418573 9.8691589-9.8691589 9.8691589-5.45058596 0-9.8691589-4.4185729-9.8691589-9.8691589 0-5.4505859 4.41857294-9.86915886 9.8691589-9.86915886.8632224 0 1.5738668.0182289 2.1546618.05767608l-2.7890754-2.7877485c-.3503769-.35037693-.3503769-.91845018 0-1.26882712z" fill={color} fillRule="evenodd" />
    </ResizeableSVG>
  );
};

Reload.propTypes = propTypes;

export default Reload;
