import isBefore from 'date-fns/isBefore';
import differenceInDays from 'date-fns/differenceInDays';
import isAfter from 'date-fns/isAfter';
import startOfDay from 'date-fns/startOfDay';
import isNaN from 'lodash/isNaN';

import { TState, TActions } from './types';

import * as actionTypes from './actionTypes';
import dateAddDuration from '../../utils/dateAddDuration';

const reducer = (state: TState, action: TActions): TState => {
  switch (action.type) {
    case actionTypes.UPDATE_TREATMENT_TYPE_ID: {
      const { payload } = action;

      return {
        ...state,
        treatmentTypeId: payload,
        treatmentTypeOptionId: '',
      };
    }
    case actionTypes.UPDATE_TREATMENT_TYPE_OPTION_ID: {
      const { payload } = action;

      return {
        ...state,
        treatmentTypeOptionId: payload,
      };
    }
    case actionTypes.UPDATE_TREATMENT_DOSAGE: {
      const { payload } = action;

      return {
        ...state,
        dosage: payload,
      };
    }
    case actionTypes.UPDATE_TREATMENT_TYPE_TEXT: {
      const { payload } = action;

      return {
        ...state,
        treatmentTypeText: payload,
      };
    }
    case actionTypes.UPDATE_START_AT: {
      const { payload } = action;
      const { duration } = state;

      if (!payload) {
        return state;
      }

      const endAt = dateAddDuration(payload, duration.amount, duration.unit);

      return {
        ...state,
        startAt: payload,
        endAt,
      };
    }
    case actionTypes.UPDATE_END_AT: {
      const { payload } = action;
      const { startAt } = state;

      if (!payload || isBefore(payload, startAt)) {
        return state;
      }

      let difference = differenceInDays(payload, startAt);

      if (difference < 1 || isNaN(difference)) {
        difference = 1;
      }

      return {
        ...state,
        endAt: payload,
        duration: {
          amount: difference,
          unit: 'days',
        },
      };
    }
    case actionTypes.UPDATE_DURATION: {
      const { payload } = action;
      const { startAt } = state;

      const duration = {
        ...state.duration,
        ...payload,
      };

      const endAt = dateAddDuration(startAt, duration.amount, duration.unit);

      return {
        ...state,
        endAt,
        duration,
      };
    }
    case actionTypes.UPDATE_COMPLETED: {
      const { payload } = action;
      const { startAt } = state;

      const now = new Date();

      // Do not update endAt and duration for treatments in the past
      if (payload.completed && isAfter(now, payload.endAt)) {
        return {
          ...state,
          completed: payload.completed,
        };
      }

      let { endAt } = payload;

      if (payload.completed) {
        endAt = now;
      }

      const difference = differenceInDays(startOfDay(endAt), startOfDay(startAt));

      if (difference < 1 || isNaN(difference)) {
        return {
          ...state,
          completed: payload.completed,
        };
      }

      return {
        ...state,
        completed: payload.completed,
        endAt,
        duration: {
          amount: difference,
          unit: 'days',
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
