import some from 'lodash/some';
import find from 'lodash/find';
import upperCase from 'lodash/upperCase';
import isEmpty from 'lodash/isEmpty';

import { SettingsInvitationCodesReturnType } from 'types';
import config from '../config';

const checkIsTestUser = (inviteCodes: SettingsInvitationCodesReturnType) => {
  if (isEmpty(inviteCodes)) {
    return false;
  }

  const isTestUser = some(
    inviteCodes,
    (code) => find(config.testUserCodePatterns,
      (testCode) => upperCase(testCode) === upperCase(code)),
  );
  return isTestUser;
};

export default checkIsTestUser;
