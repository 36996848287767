import { createStore, applyMiddleware } from 'redux';
import { createMigrate, persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import storage from 'redux-persist/es/storage';
import omit from 'lodash/omit';

import rootSaga from '../sagas';
import rootReducer, { AppState } from '../state/reducers';

const configureStore = () => {
  const migrations = {
    2: (state: AppState): AppState => omit(state, 'profileQuestionsCategories'),
    3: (state: AppState): AppState => omit(state, 'dictionary'),
    4: (state: AppState): AppState => omit(state, 'questionnaireQuestions'),
    5: (state: AppState): AppState => omit(state, 'settings.hospitalAccessCodes'),
    // questionnaireSuggestedQuestions has been replaced with generatedSuggestedQuestions
    6: (state: AppState): AppState => omit(state, 'questionnaireSuggestedQuestions'),
    7: (state: AppState): AppState => omit(state, 'analytics'),
  } as any;

  const persistConfig = {
    key: 'root',
    storage,
    version: 11,
    timeout: 10000,
    stateReconciler: autoMergeLevel2,
    whitelist: [
      'auth',
      'appointments',
      'messages',
      'notifications',
      'notes',
      'photos',
      'audioRecordings',
      'treatments',
      'trends',
      'questionnaires',
      'profileQuestionAnswers',
      'hospitalAccessCodes',
      'dailySuggestions',
      'settings',
      'sync',
      'drafts',
      'sharedData',
      'tags',
      'generatedReports',
      'reports',
      'generatedSuggestedQuestions',
      'contacts',
      'customTrends',
    ],
    migrate: createMigrate(migrations, { debug: __DEV__ }),
  };

  const sagaMiddleware = createSagaMiddleware();

  const persistedReducer = persistReducer(persistConfig, rootReducer as any); // TSC: correctly fix type error

  const store = createStore(
    persistedReducer,
    composeWithDevTools(
      applyMiddleware(sagaMiddleware),
    ),
  );

  const persistor = persistStore(store);

  // Run root saga
  const sagaTask = sagaMiddleware.run(rootSaga);

  return { store, persistor, sagaTask };
};

export default configureStore;
