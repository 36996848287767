import { Reducer } from 'redux';

import * as utils from './utils';
import * as actionTypes from './actionTypes';
import {
  GlossaryState,
  GlossaryActionTypes,
  GlossaryReplaceActionType,
} from './types';

import { glossary } from '../../../data/system-document.json';

const initialState = glossary as GlossaryState;

const reducer: Reducer<GlossaryState, GlossaryActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REPLACE: return utils.replace(state, action as GlossaryReplaceActionType);
    case actionTypes.CLEAR: return utils.clear();
    default: return state;
  }
};

export default reducer;
