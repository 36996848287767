import { TTDrugModuleIndicators } from '../../types';

const guideSuggestionIds = {
  enhertu: '378ce446-65ed-4e1c-9cb2-70371e7311a9',
  lynparza: '229ba1a6-2a9b-4ce9-9f54-99395ed956e5',
};

const testFitnessIndicatorIds: TTDrugModuleIndicators = {
  enhertu: [
    '28e1cd3d-da3d-44d0-8d0c-9e517df9e0fb', // Breathless
    'd4b3e1b7-e78e-4e46-a1b4-d8639d57b012', // Physical effort
    '8100f784-c84e-40d7-9a69-46a9b6642eb1', // Lap distance
    'dd573063-b548-4207-bd6e-d843f6858d0e', // Laps
    '324be7e8-9a12-41d0-95ff-01478e335d2b', // Total distance
  ],
  lynparza: [
    'a9edd7c4-d786-47dc-80f3-5d3f260e6554', // Breathless
    '2665f439-0768-4e38-8b46-b741abaf4798', // Physical effort
    'd6ae813f-6545-4a60-bac5-947a09ca2f44', // Lap distance
    '5a0e863c-b6c8-4023-b2b8-6b40e6ce01c7', // Laps
    '73cd35be-f91a-451f-8118-88cd096e2d33', // Total distance
  ],
};

const vitalSignsIndicatorIds: TTDrugModuleIndicators = {
  lynparza: [
    '7c58d07c-d531-4dd1-a3f4-c882cb2ca321', // Oximetry
    '9130250d-d385-44fb-beeb-edf423858771', // Heart Rate
    '9a169fca-825d-4a50-b317-6984f4ab0a35', // Blood pressure - Systolic
    '04834be1-e7db-47c4-89bd-a84540d26f3e', // Blood pressure - Diastolic
  ],
  enhertu: [
    '477cd411-f959-467a-8ceb-10fb25990827', // Oximetry
    '1b08328a-f609-485c-adb2-015005fb783c', // Heart Rate
    '264bd733-cac7-4d76-a2a2-0d218b1c5f0b', // Blood pressure - Systolic
    'c200c855-5649-4eb5-9064-f65dc34e787a', // Blood pressure - Diastolic
  ],
};

export { testFitnessIndicatorIds, guideSuggestionIds, vitalSignsIndicatorIds };
