import map from 'lodash/map';
import some from 'lodash/some';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import fpPick from 'lodash/fp/pick';

import { AppState } from '../state/reducers';
import { TrendIndicatorType } from '../../types';

import { selectors as trendIndicatorSelectors } from '../state/trendIndicators';
import { selectors as tagsSelectors } from '../state/tags';
import EMPTY_ARRAY from '../utils/empty-array';
import isOfType from '../utils/isOfType';
import includesAny from '../utils/includesAny';
import createDeepEqualSelector from './createDeepEqualSelector';

const getSettingsSelector = (state: AppState) => (
  map(state.settings.trendIndicators, fpPick(['id', 'order']))
);

const getSettings = createDeepEqualSelector(
  getSettingsSelector,
  (state) => state,
);

const getAllTrendIndicators = createDeepEqualSelector(
  [tagsSelectors.getAll, getSettings, trendIndicatorSelectors.getAll],
  (tags, settings, indicators) => {
    if (!isOfType<TrendIndicatorType[]>(indicators)) {
      return EMPTY_ARRAY;
    }

    // Filter trendIndicators by tags
    const filteredIndicators = filter(indicators, (indicator) => {
      if (isEmpty(indicator.tags)) {
        return false;
      }

      return includesAny(indicator.tags, tags);
    });

    const overwriteSystemOrder = some(settings, 'order');

    const updatedIndicators = map(filteredIndicators, (indicator) => {
      const setting = find(settings, { id: indicator.id }) || {};

      const indicatorOrder = get(indicator, 'order');
      const settingOrder = get(setting, 'order');

      let order = indicatorOrder;

      // Overwrite system defined order if user changed order
      if (overwriteSystemOrder && settingOrder >= 0) {
        order = settingOrder;
      }

      return {
        ...indicator,
        order,
      };
    });

    return orderBy(updatedIndicators, ['order']);
  },
);

export default getAllTrendIndicators;
