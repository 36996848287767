import { Reducer } from 'redux';

import * as actionTypes from './actionTypes';
import * as utils from './utils';
import {
  PhotosState,
  PhotosActionTypes,
  PhotosAddActionType,
  PhotosRemoveActionType,
  PhotosUpdateActionType,
} from './types';

const initialState: PhotosState = {};

const reducer: Reducer<PhotosState, PhotosActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD: return utils.add(state, action as PhotosAddActionType);
    case actionTypes.REMOVE: return utils.remove(state, action as PhotosRemoveActionType);
    case actionTypes.UPDATE: return utils.update(state, action as PhotosUpdateActionType);
    case actionTypes.CLEAR: return utils.clear();
    default: return state;
  }
};

export default reducer;
