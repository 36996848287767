import get from 'lodash/get';
import isFunction from 'lodash/isFunction';

const focusRef = (ref: any) => {
  const focus = get(ref, 'current.focus');

  if (!isFunction(focus)) {
    return false;
  }

  return ref.current.focus();
};

export default focusRef;
