import * as actionTypes from './actionTypes';
import { TAudioRecording } from '../../../types';
import { AudioRecordingsActionTypes, AudioRecordingsDownloadPayload } from './types';

const add = (payload: TAudioRecording[]): AudioRecordingsActionTypes => ({
  type: actionTypes.ADD,
  payload,
});

const remove = (payload: string[]): AudioRecordingsActionTypes => ({
  type: actionTypes.REMOVE,
  payload,
});

const unlinkUri = (payload: string): AudioRecordingsActionTypes => ({
  type: actionTypes.UNLINK_URI,
  payload,
});

const unlinkAll = (): AudioRecordingsActionTypes => ({
  type: actionTypes.UNLINK_ALL,
});

const update = (payload: TAudioRecording[]): AudioRecordingsActionTypes => ({
  type: actionTypes.UPDATE,
  payload,
});

const download = (payload: AudioRecordingsDownloadPayload): AudioRecordingsActionTypes => ({
  type: actionTypes.DOWNLOAD,
  payload,
});

const cleanup = (payload: TAudioRecording[]): AudioRecordingsActionTypes => ({
  type: actionTypes.CLEANUP,
  payload,
});

const clear = (): AudioRecordingsActionTypes => ({
  type: actionTypes.CLEAR,
});

export {
  add,
  remove,
  unlinkUri,
  unlinkAll,
  update,
  download,
  cleanup,
  clear,
};
