import keys from 'lodash/keys';

export const fallbackLng = 'en-GB';

type SupportedLocalesType = {
  [key: string]: any;
};

export const supportedLocales: SupportedLocalesType = {
  'en-GB': {
    name: 'English (UK)',
    translationFileLoader: (namespace: string) => {
      switch (namespace) {
        case 'common': return require('../../i18n/en-GB/common.json'); // eslint-disable-line global-require
        case 'treatments': return require('../../i18n/en-GB/treatments.json'); // eslint-disable-line global-require
        case 'appointments': return require('../../i18n/en-GB/appointments.json'); // eslint-disable-line global-require
        case 'notes': return require('../../i18n/en-GB/notes.json'); // eslint-disable-line global-require
        case 'trends': return require('../../i18n/en-GB/trends.json'); // eslint-disable-line global-require
        case 'trendIndicators': return require('../../i18n/en-GB/trendIndicators.json'); // eslint-disable-line global-require
        case 'messages': return require('../../i18n/en-GB/messages.json'); // eslint-disable-line global-require
        case 'photos': return require('../../i18n/en-GB/photos.json'); // eslint-disable-line global-require
        case 'audioRecordings': return require('../../i18n/en-GB/audioRecordings.json'); // eslint-disable-line global-require
        case 'hospitalAccessCodes': return require('../../i18n/en-GB/hospitalAccessCodes.json'); // eslint-disable-line global-require
        case 'shareMyData': return require('../../i18n/en-GB/shareMyData.json'); // eslint-disable-line global-require
        case 'errors': return require('../../i18n/en-GB/errors.json'); // eslint-disable-line global-require
        case 'profileQuestions': return require('../../i18n/en-GB/profileQuestions.json'); // eslint-disable-line global-require
        case 'profileQuestionCategories': return require('../../i18n/en-GB/profileQuestionCategories.json'); // eslint-disable-line global-require
        case 'questionnaires': return require('../../i18n/en-GB/questionnaires.json'); // eslint-disable-line global-require
        case 'suggestedQuestions': return require('../../i18n/en-GB/suggestedQuestions.json'); // eslint-disable-line global-require
        case 'glossary': return require('../../i18n/en-GB/glossary.json'); // eslint-disable-line global-require
        case 'treatmentTypes': return require('../../i18n/en-GB/treatmentTypes.json'); // eslint-disable-line global-require
        case 'trendsDashboard': return require('../../i18n/en-GB/trendsDashboard.json'); // eslint-disable-line global-require
        case 'reports': return require('../../i18n/en-GB/reports.json'); // eslint-disable-line global-require
        case 'contacts': return require('../../i18n/en-GB/contacts.json'); // eslint-disable-line global-require
        case 'contentModules': return require('../../i18n/en-GB/contentModules.json'); // eslint-disable-line global-require
        case 'testFitness': return require('../../i18n/en-GB/testFitness.json'); // eslint-disable-line global-require
        default:
      }

      return {};
    },
    dateLocaleLoader: () => import('date-fns/locale/en-GB'),
  },
  'nl-NL': {
    name: 'Nederlands',
    translationFileLoader: (namespace: string) => {
      switch (namespace) {
        case 'common': return require('../../i18n/nl-NL/common.json'); // eslint-disable-line global-require
        case 'treatments': return require('../../i18n/nl-NL/treatments.json'); // eslint-disable-line global-require
        case 'appointments': return require('../../i18n/nl-NL/appointments.json'); // eslint-disable-line global-require
        case 'notes': return require('../../i18n/nl-NL/notes.json'); // eslint-disable-line global-require
        case 'trends': return require('../../i18n/nl-NL/trends.json'); // eslint-disable-line global-require
        case 'trendIndicators': return require('../../i18n/nl-NL/trendIndicators.json'); // eslint-disable-line global-require
        case 'messages': return require('../../i18n/nl-NL/messages.json'); // eslint-disable-line global-require
        case 'photos': return require('../../i18n/nl-NL/photos.json'); // eslint-disable-line global-require
        case 'audioRecordings': return require('../../i18n/nl-NL/audioRecordings.json'); // eslint-disable-line global-require
        case 'hospitalAccessCodes': return require('../../i18n/nl-NL/hospitalAccessCodes.json'); // eslint-disable-line global-require
        case 'shareMyData': return require('../../i18n/nl-NL/shareMyData.json'); // eslint-disable-line global-require
        case 'errors': return require('../../i18n/nl-NL/errors.json'); // eslint-disable-line global-require
        case 'profileQuestions': return require('../../i18n/nl-NL/profileQuestions.json'); // eslint-disable-line global-require
        case 'profileQuestionCategories': return require('../../i18n/nl-NL/profileQuestionCategories.json'); // eslint-disable-line global-require
        case 'questionnaires': return require('../../i18n/nl-NL/questionnaires.json'); // eslint-disable-line global-require
        case 'suggestedQuestions': return require('../../i18n/nl-NL/suggestedQuestions.json'); // eslint-disable-line global-require
        case 'glossary': return require('../../i18n/nl-NL/glossary.json'); // eslint-disable-line global-require
        case 'treatmentTypes': return require('../../i18n/nl-NL/treatmentTypes.json'); // eslint-disable-line global-require
        case 'trendsDashboard': return require('../../i18n/nl-NL/trendsDashboard.json'); // eslint-disable-line global-require
        case 'reports': return require('../../i18n/nl-NL/reports.json'); // eslint-disable-line global-require
        case 'contacts': return require('../../i18n/nl-NL/contacts.json'); // eslint-disable-line global-require
        case 'contentModules': return require('../../i18n/nl-NL/contentModules.json'); // eslint-disable-line global-require
        case 'testFitness': return require('../../i18n/nl-NL/testFitness.json'); // eslint-disable-line global-require
        default:
      }

      return {};
    },
    dateLocaleLoader: () => import('date-fns/locale/nl'),
  },
  'en-US': {
    name: 'English (US)',
    translationFileLoader: (namespace: string) => {
      switch (namespace) {
        case 'common': return require('../../i18n/en-US/common.json'); // eslint-disable-line global-require
        case 'treatments': return require('../../i18n/en-US/treatments.json'); // eslint-disable-line global-require
        case 'appointments': return require('../../i18n/en-US/appointments.json'); // eslint-disable-line global-require
        case 'notes': return require('../../i18n/en-US/notes.json'); // eslint-disable-line global-require
        case 'trends': return require('../../i18n/en-US/trends.json'); // eslint-disable-line global-require
        case 'trendIndicators': return require('../../i18n/en-US/trendIndicators.json'); // eslint-disable-line global-require
        case 'messages': return require('../../i18n/en-US/messages.json'); // eslint-disable-line global-require
        case 'photos': return require('../../i18n/en-US/photos.json'); // eslint-disable-line global-require
        case 'audioRecordings': return require('../../i18n/en-US/audioRecordings.json'); // eslint-disable-line global-require
        case 'hospitalAccessCodes': return require('../../i18n/en-US/hospitalAccessCodes.json'); // eslint-disable-line global-require
        case 'shareMyData': return require('../../i18n/en-US/shareMyData.json'); // eslint-disable-line global-require
        case 'errors': return require('../../i18n/en-US/errors.json'); // eslint-disable-line global-require
        case 'profileQuestions': return require('../../i18n/en-US/profileQuestions.json'); // eslint-disable-line global-require
        case 'profileQuestionCategories': return require('../../i18n/en-US/profileQuestionCategories.json'); // eslint-disable-line global-require
        case 'questionnaires': return require('../../i18n/en-US/questionnaires.json'); // eslint-disable-line global-require
        case 'questionnaireSuggestedQuestions': return require('../../i18n/en-US/questionnaireSuggestedQuestions.json'); // eslint-disable-line global-require
        case 'glossary': return require('../../i18n/en-US/glossary.json'); // eslint-disable-line global-require
        case 'treatmentTypes': return require('../../i18n/en-US/treatmentTypes.json'); // eslint-disable-line global-require
        case 'reports': return require('../../i18n/en-US/reports.json'); // eslint-disable-line global-require
        case 'contacts': return require('../../i18n/en-US/contacts.json'); // eslint-disable-line global-require
        case 'contentModules': return require('../../i18n/en-US/contentModules.json'); // eslint-disable-line global-require
        case 'testFitness': return require('../../i18n/en-US/testFitness.json'); // eslint-disable-line global-require
        default:
      }

      return {};
    },
    dateLocaleLoader: () => import('date-fns/locale/en-US'),
  },
  'es-US': {
    name: 'Spanish (US)',
    translationFileLoader: (namespace: string) => {
      switch (namespace) {
        case 'common': return require('../../i18n/es-US/common.json'); // eslint-disable-line global-require
        case 'treatments': return require('../../i18n/es-US/treatments.json'); // eslint-disable-line global-require
        case 'appointments': return require('../../i18n/es-US/appointments.json'); // eslint-disable-line global-require
        case 'notes': return require('../../i18n/es-US/notes.json'); // eslint-disable-line global-require
        case 'trends': return require('../../i18n/es-US/trends.json'); // eslint-disable-line global-require
        case 'trendIndicators': return require('../../i18n/es-US/trendIndicators.json'); // eslint-disable-line global-require
        case 'messages': return require('../../i18n/es-US/messages.json'); // eslint-disable-line global-require
        case 'photos': return require('../../i18n/es-US/photos.json'); // eslint-disable-line global-require
        case 'audioRecordings': return require('../../i18n/es-US/audioRecordings.json'); // eslint-disable-line global-require
        case 'hospitalAccessCodes': return require('../../i18n/es-US/hospitalAccessCodes.json'); // eslint-disable-line global-require
        case 'shareMyData': return require('../../i18n/es-US/shareMyData.json'); // eslint-disable-line global-require
        case 'errors': return require('../../i18n/es-US/errors.json'); // eslint-disable-line global-require
        case 'profileQuestions': return require('../../i18n/es-US/profileQuestions.json'); // eslint-disable-line global-require
        case 'profileQuestionCategories': return require('../../i18n/es-US/profileQuestionCategories.json'); // eslint-disable-line global-require
        case 'questionnaires': return require('../../i18n/es-US/questionnaires.json'); // eslint-disable-line global-require
        case 'questionnaireSuggestedQuestions': return require('../../i18n/es-US/questionnaireSuggestedQuestions.json'); // eslint-disable-line global-require
        case 'glossary': return require('../../i18n/es-US/glossary.json'); // eslint-disable-line global-require
        case 'treatmentTypes': return require('../../i18n/es-US/treatmentTypes.json'); // eslint-disable-line global-require
        case 'reports': return require('../../i18n/es-US/reports.json'); // eslint-disable-line global-require
        case 'contacts': return require('../../i18n/es-US/contacts.json'); // eslint-disable-line global-require
        case 'contentModules': return require('../../i18n/es-US/contentModules.json'); // eslint-disable-line global-require
        case 'testFitness': return require('../../i18n/es-US/testFitness.json'); // eslint-disable-line global-require
        default:
      }

      return {};
    },
    dateLocaleLoader: () => import('date-fns/locale/en-US'),
  },
};

export const defaultNamespace = 'common';

export const allowedLanguages = keys(supportedLocales);

export const namespaces = [
  'common',
  'treatments',
  'appointments',
  'notes',
  'trends',
  'trendIndicators',
  'messages',
  'photos',
  'audioRecordings',
  'hospitalAccessCodes',
  'shareMyData',
  'errors',
  'profileQuestions',
  'profileQuestionCategories',
  'questionnaires',
  'suggestedQuestions',
  'glossary',
  'treatmentTypes',
  'trendsDashboard',
  'reports',
  'contacts',
  'contentModules',
  'testFitness',
];
