import * as actionTypes from './actionTypes';
import {
  TActions,
  TUpdateTitlePayload,
  TUpdateLocationPayload,
  TUpdateStartAtPayload,
  TUpdateEndAtPayload,
  TUpdateBodyPayload,
} from './types';

const updateTitle = (payload: TUpdateTitlePayload): TActions => ({
  type: actionTypes.UPDATE_TITLE,
  payload,
});

const updateLocation = (payload: TUpdateLocationPayload): TActions => ({
  type: actionTypes.UPDATE_LOCATION,
  payload,
});

const updateStartAt = (payload: TUpdateStartAtPayload): TActions => ({
  type: actionTypes.UPDATE_START_AT,
  payload,
});

const updateEndAt = (payload: TUpdateEndAtPayload): TActions => ({
  type: actionTypes.UPDATE_END_AT,
  payload,
});

const updateBody = (payload: TUpdateBodyPayload): TActions => ({
  type: actionTypes.UPDATE_BODY,
  payload,
});

export {
  updateTitle,
  updateLocation,
  updateStartAt,
  updateEndAt,
  updateBody,
};
