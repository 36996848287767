import { createSelector } from 'reselect';
import get from 'lodash/get';
import toArray from 'lodash/toArray';

import find from 'lodash/find';
import { AppState } from '../reducers';
import { ContactsState } from './types';

import EMPTY_OBJECT from '../../utils/empty-object';
import { TDrugModule } from '../../../types';

const getByIdSelector = (state: ContactsState, id: string) => (
  get(state, [id]) || EMPTY_OBJECT
);

const getByContentModuleSelector = (
  state: ContactsState,
  contentModule?: TDrugModule,
) => {
  if (!contentModule) {
    return undefined;
  }

  return find(state, (contact) => contact.contentModule === contentModule);
};

const makeGetById = () => (
  createSelector(
    (state: AppState) => state.contacts,
    (state: any, id: string) => id,
    getByIdSelector,
  )
);

const makeGetByContentModule = () => (
  createSelector(
    (state: AppState) => state.contacts,
    (state: any, drugModule: TDrugModule | undefined) => drugModule,
    getByContentModuleSelector,
  )
);

const getAllSelector = (state: ContactsState) => toArray(state);

const getAll = createSelector(
  (state: AppState) => state.contacts,
  getAllSelector,
);

export {
  makeGetByContentModule,
  makeGetById,
  getAll,
};
