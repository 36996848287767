import * as actionTypes from './actionTypes';
import { HospitalAccessCodeType } from '../../../types';
import { HospitalAccessCodesActionTypes } from './types';

const add = (payload: HospitalAccessCodeType[]): HospitalAccessCodesActionTypes => ({
  type: actionTypes.ADD,
  payload,
});

const remove = (payload: string[]): HospitalAccessCodesActionTypes => ({
  type: actionTypes.REMOVE,
  payload,
});

const update = (payload: HospitalAccessCodeType[]): HospitalAccessCodesActionTypes => ({
  type: actionTypes.UPDATE,
  payload,
});

const clear = (): HospitalAccessCodesActionTypes => ({
  type: actionTypes.CLEAR,
});

export {
  add,
  remove,
  update,
  clear,
};
