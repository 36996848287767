import localforage from 'localforage';

const getEmailAsync = async () => {
  const email = await localforage.getItem('email');

  if (!email) {
    if (__DEV__) {
      console.log('[getApiTokenAsync]', 'No api token'); // eslint-disable-line no-console
    }

    return Promise.reject(new Error('No api token'));
  }

  return email;
};

export default getEmailAsync;
