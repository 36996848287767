import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';

import Footer from '../components/Footer';
import { AppState } from '../state/reducers';
import { selectors as hospitalsSelectors } from '../state/hospitals';
import { selectors as hospitalAccessCodesSelectors, actions as hospitalAccessCodesActions } from '../state/hospitalAccessCodes';
import defaultGet from '../utils/defaultGet';
import dateFormat from '../utils/dateFormat';
import Drawer from '../components/Drawer';
import DrawerRightHeader from '../components/DrawerRightHeader';
import Button from '../components/Button';
import Navigation from '../components/Navigation';
import DrawerButtons from '../components/DrawerButtons';
import Divider from '../components/Divider';
import Modal from '../components/Modal';
import Colors from '../theme/Colors';

import '../css/HospitalAccessCode.css';

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const textareaStyle = {
  width: '100%',
  maxWidth: 1500,
  padding: 5,
};

const HospitalAccessCode = () => {
  const [visible, setVisible] = useState(false);

  const { t, i18n } = useTranslation();

  const { hospitalAccessCodeId } = useParams<any>();

  const history = useHistory();

  const dispatch = useDispatch();

  const memoizedHospitalAccessCodesGetByIdSelector = useMemo(
    hospitalAccessCodesSelectors.makeGetById,
    [],
  );

  const hospitalAccessCode = (
    useSelector(
      (state: AppState) => memoizedHospitalAccessCodesGetByIdSelector(state, hospitalAccessCodeId),
    )
  );

  const memoizedHospitalsGetByIdSelector = useMemo(
    hospitalsSelectors.makeGetById,
    [],
  );

  const hospital = useSelector(
    (state: AppState) => memoizedHospitalsGetByIdSelector(state, hospitalAccessCode.hospitalId),
  );

  const hospitalAccessCodeRemove = () => {
    setVisible(false);

    dispatch(hospitalAccessCodesActions.remove([
      hospitalAccessCode.id,
    ]));

    return history.goBack();
  };

  return (
    <article className="page change-password row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('my-shared-data')} showRightButton />
          <div className="share-hospital-container">
            <section className="content">
              <section className="share-hospital-access-code-container">
                <h1 className="heading">{defaultGet(hospital, 'name', '')}</h1>
                <Divider
                  lineStyle={{
                    borderBottomColor: Colors.softGray,
                    borderBottomWidth: 2,
                    marginVertical: 10,
                  }}
                />
                <p className="body light mt0 mb30">{dateFormat(i18n.language, hospitalAccessCode.createdAt)}</p>
                <h1 className="heading-secondary">{t('note')}</h1>
              </section>
              <TextareaAutosize
                style={textareaStyle}
                minRows={5}
                disabled
                value={hospitalAccessCode.note}
              />
              <section className="access-key-container">
                <h1 className="body light">{t('data-access-key')}</h1>
                { hospitalAccessCode.shareKey
                  ? <h1 className="heading-large">{hospitalAccessCode.shareKey}</h1>
                  : <p className="body light">{t('hospital-access-code-loading')}</p> }
              </section>
            </section>
            <Button
              buttonType="button"
              labelText={t('stop-sharing')}
              onClick={() => setVisible(true)}
              size="medium"
              color={Colors.errorRed}
            />
          </div>
        </section>
        <Footer />
        <Modal
          visible={visible}
          hideModal={() => setVisible(false)}
          actions={[
            {
              title: t('cancel'),
              onClick: () => setVisible(false),
            },
            {
              title: t('stop-sharing'),
              onClick: hospitalAccessCodeRemove,
              destructive: true,
            },
          ]}
        >
          <>
            <h1 className="heading">{t('stop-sharing')}</h1>
            <p className="body">{t('stop-sharing-confirm')}</p>
          </>
        </Modal>
      </section>
      <DrawerRight />
    </article>
  );
};

export default HospitalAccessCode;

// ---------------------------------------------  Previous component with old design ------------------------------------------------- //
// import React, { useState, useMemo } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { useTranslation } from 'react-i18next';
// import { useParams, useHistory } from 'react-router-dom';
// import nl2br from 'react-nl2br';

// import Header from '../components/Header';
// import PageHeader from '../components/PageHeader';
// import Footer from '../components/Footer';
// import Modal from '../components/Modal';
// import { AppState } from '../state/reducers';
// import { selectors as hospitalsSelectors } from '../state/hospitals';
// import { selectors as hospitalAccessCodesSelectors, actions as hospitalAccessCodesActions } from '../state/hospitalAccessCodes';
// import defaultGet from '../utils/defaultGet';
// import dateFormat from '../utils/dateFormat';
// import '../css/HospitalAccessCode.css';

// const HospitalAccessCode = () => {
//   const [visible, setVisible] = useState(false);

//   const { t, i18n } = useTranslation();

//   const { hospitalAccessCodeId } = useParams<any>();

//   const history = useHistory();

//   const dispatch = useDispatch();

//   const memoizedHospitalAccessCodesGetByIdSelector = useMemo(
//     hospitalAccessCodesSelectors.makeGetById,
//     [],
//   );

//   const hospitalAccessCode = (
//     useSelector(
//       (state: AppState) => memoizedHospitalAccessCodesGetByIdSelector(state, hospitalAccessCodeId),
//     )
//   );

//   const memoizedHospitalsGetByIdSelector = useMemo(
//     hospitalsSelectors.makeGetById,
//     [],
//   );

//   const hospital = useSelector(
//     (state: AppState) => memoizedHospitalsGetByIdSelector(state, hospitalAccessCode.hospitalId),
//   );

//   const hospitalAccessCodeRemove = () => {
//     setVisible(false);

//     dispatch(hospitalAccessCodesActions.remove([
//       hospitalAccessCode.id,
//     ]));

//     return history.goBack();
//   };

//   return (
//     <article className="app hospital-access-code">
//       <Header auth documentTitle={t('my-shared-data')} />
//       <PageHeader title={t('my-shared-data')} />
//       <section className="content">
//         <h1 className="heading">{defaultGet(hospital, 'name', '')}</h1>
//         <p className="body light">{dateFormat(i18n.language, hospitalAccessCode.createdAt)}</p>
//         <h1 className="heading-secondary">{t('note')}</h1>
//         <p className="body">{hospitalAccessCode.note}</p>
//         <h1 className="heading-secondary">{t('data-access-key')}</h1>
//         {
//           hospitalAccessCode.shareKey
//             ? <h1 className="heading-large">{hospitalAccessCode.shareKey}</h1>
//             : <p className="body light">{t('hospital-access-code-loading')}</p>
//         }
//         <button className="button destructive" type="button" onClick={() => setVisible(true)}>
//           {t('stop-sharing')}
//         </button>
//         <h1 className="heading-secondary">{t('data-access-key-heading')}</h1>
//         <p className="body">{nl2br(t('data-access-key-body'))}</p>
//       </section>
//       <Footer />
//       <Modal
//         visible={visible}
//         hideModal={() => setVisible(false)}
//         actions={[
//           {
//             title: t('cancel'),
//             onClick: () => setVisible(false),
//           },
//           {
//             title: t('stop-sharing'),
//             onClick: hospitalAccessCodeRemove,
//             destructive: true,
//           },
//         ]}
//       >
//         <>
//           <h1 className="heading">{t('stop-sharing')}</h1>
//           <p className="body">{t('stop-sharing-confirm')}</p>
//         </>
//       </Modal>
//     </article>
//   );
// };

// export default HospitalAccessCode;
