import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { AppState } from '../state/reducers';
import { TPhoto } from '../../types';

import { selectors as photosSelectors } from '../state/photos';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRightHeader from '../components/DrawerRightHeader';
import Photo from '../components/Photo';

type Params = {
  photoId: string;
};

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const PhotoScreen = () => {
  const { t } = useTranslation();

  const { photoId } = useParams<Params>();

  const memoizedPhotosGetByIdSelector = useMemo(
    photosSelectors.makeGetById,
    [],
  );

  const photo = (
    useSelector((state: AppState) => memoizedPhotosGetByIdSelector(state, photoId))
  ) as TPhoto;

  return (
    <article className="page photo row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('photo')} showRightButton />
          <Photo photo={photo} />
        </section>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default PhotoScreen;
