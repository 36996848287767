import { StyleSheet as RNStyleSheet } from 'react-native';

import Colors from '../../theme/Colors';

const styles = RNStyleSheet.create({
  content: {
    marginTop: 24,
    backgroundColor: Colors.white,
    position: 'relative',
  },
});

export default styles;
