import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import findIndex from 'lodash/findIndex';
import map from 'lodash/map';
import flatten from 'lodash/flatten';
import difference from 'lodash/difference';
import first from 'lodash/first';
import size from 'lodash/size';
import nth from 'lodash/nth';

import { AppState } from '../state/reducers';

import Navigation from '../components/Navigation';
import DrawerButtons from '../components/DrawerButtons';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import DrawerRightHeader from '../components/DrawerRightHeader';
import ProfileQuestion from '../components/ProfileQuestion';
import getProfileProgressSelector from '../selectors/getProfileProgress';
import { selectors as profileQuestionsSelectors } from '../state/profileQuestions';
import { selectors as profileQuestionAnswersSelectors } from '../state/profileQuestionAnswers';
import { selectors as tagsSelectors } from '../state/tags';

type Params = {
  questionId: string;
};

const DrawerRight = () => {
  const { t } = useTranslation();

  const { percentage } = useSelector(
    (state: AppState) => getProfileProgressSelector(state),
  );

  return (
    <Drawer position="right">
      <section>
        <DrawerRightHeader />
        <section className="drawer-item">
          <header className="row between center">
            <h1 className="heading">{t('profile')}</h1>
            <p className="body light">{t('profile-completed', { percentage })}</p>
          </header>
        </section>
      </section>
    </Drawer>
  );
};

const ProfileComplete = () => {
  let { questionId } = useParams<Params>();

  const { t } = useTranslation();

  const tags = useSelector((state: AppState) => tagsSelectors.getAll(state));

  const memoizedProfileQuestionsGetByTagsSelector = useMemo(
    profileQuestionsSelectors.makeGetByTags,
    [],
  );

  const profileQuestions = useSelector(
    (state: AppState) => memoizedProfileQuestionsGetByTagsSelector(state, tags),
  );

  const answers = useSelector(
    (state: AppState) => profileQuestionAnswersSelectors.getAll(state),
  );

  const questionIds = flatten(map(profileQuestions, 'id'));
  const answeredQuestionIds = map(answers, 'profileQuestionId');
  const unansweredQuestionIds = difference(questionIds, answeredQuestionIds);

  if (!questionId) {
    questionId = first(unansweredQuestionIds);
  }

  const questionTotal = size(questionIds) - 1;
  const questionIndex = findIndex(questionIds, (id) => id === questionId);
  const isFirstQuestion = questionIndex === 0;
  const isLastQuestion = questionIndex === questionTotal;

  let prevQuestionIndex = 0;
  let nextQuestionIndex = 0;

  if (!isFirstQuestion) {
    prevQuestionIndex = questionIndex - 1;
  }

  if (!isLastQuestion) {
    nextQuestionIndex = questionIndex + 1;
  }

  const previousQuestionId = nth(questionIds, prevQuestionIndex);
  const nextQuestionId = nth(questionIds, nextQuestionIndex);

  const content = (
    <ProfileQuestion
      questionId={questionId}
      prevQuestionId={previousQuestionId}
      nextQuestionId={nextQuestionId}
      isFirstQuestion={isFirstQuestion}
      isLastQuestion={isLastQuestion}
    />
  );

  return (
    <article className="page profile row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('profile')} showRightButton />
          {content}
        </section>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default ProfileComplete;
