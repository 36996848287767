import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import Dropdown from 'react-dropdown';
import { TDrugModule } from 'types';

import { AppState } from '../state/reducers';
import { ReactComponent as IconCompleted } from '../assets/icons/Completed.svg';
import { ReactComponent as BulbSVG } from '../assets/icons/Bulb.svg';
import { ReactComponent as IconTreatmentPlan } from '../assets/icons/TreatmentPlan.svg';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import DrawerRightHeader from '../components/DrawerRightHeader';
import Button from '../components/Button';
import Colors from '../theme/Colors';
import { selectors as settingsSelectors, actions as settingsActions } from '../state/settings';
import Text from '../components/Text';
import '../css/InvitationCodeTreatmentSelection.css';

type Params = {
  invitationCode: string;
  isFromAddCodeAndSupport?: boolean;
};

type TTreatmentValue = TDrugModule | 'other' | '';
type TTreatment = { label: string, value: TTreatmentValue };

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const InvitationCodeTreatmentSelection = () => {
  const { t } = useTranslation();

  const location = useLocation<Params>();

  const { invitationCode, isFromAddCodeAndSupport } = location.state;

  const dispatch = useDispatch();

  const history = useHistory();

  const enabledDrugModules = useSelector(
    (state: AppState) => settingsSelectors.getDrugContentModules(state),
  );

  const treatments: TTreatment[] = [
    {
      label: t('other-idk'),
      value: 'other',
    },
    {
      label: 'Lynparza® (olaparib)',
      value: 'lynparza',
    },
    {
      label: 'Enhertu® (trastuzumab deruxtecan)',
      value: 'enhertu',
    },
  ];

  const [treatment, setTreatment] = useState<TTreatment>(treatments[0]);

  const checkIsDrugModule = (value: string): value is TDrugModule => !!value && value !== 'other';

  const handleContinue = () => {
    if (!invitationCode) {
      history.push('/settings');

      return;
    }

    if (checkIsDrugModule(treatment.value)) {
      const moduleName = treatment.value;

      if (enabledDrugModules[moduleName]) { // prevent rewriting
        history.push('/');
        return;
      }

      dispatch(settingsActions.drugModulesAdd([
        {
          invitationCode: invitationCode.toUpperCase(),
          drugModule: moduleName,
        },
      ]));
      history.push({
        pathname: `/support-menu/${moduleName}/welcome/`,
        state: {
          invitationCode,
          drugModule: moduleName,
        },
      });
      return;
    }

    history.push('/');
  };

  const ComingSoonBlock = () => (
    <section className="coming-soon-container">
      <div className="coming-soon-icon">
        <BulbSVG fill={Colors.primary} className="icon medium" />
      </div>
      <p className="select-support-features">{t('invitation-code-screen.coming-soon')}</p>
    </section>
  );

  return (
    <article className="page diary row">
      <Navigation />
      <section className="container">
        <section className="content">
          <div className="select-support-container">
            {!isFromAddCodeAndSupport ? (
              <>
                <h3 className="select-support-title">{t('invitation-code-screen.title')}</h3>
                <IconCompleted fill={Colors.secondary} className="img-center icons" />
                <section className="support-features">
                  <Trans
                    i18nKey="invitation-code-screen.support-features"
                    parent={Text}
                    components={{ bold: <Text style={{ fontWeight: 'bold' }} /> }}
                  />
                </section>
                {ComingSoonBlock()}
              </>
            ) : null}
            <h4 className="select-support-subtitle mt40">{t('invitation-code-screen.subtitle')}</h4>

            <section className="select-support-dropdown-container">
              <div className="coming-soon-icon">
                <IconTreatmentPlan fill={Colors.primary} className="icon" />
              </div>
              <Dropdown
                className="select-support-dropdown"
                options={treatments}
                onChange={
                  (option) => setTreatment(
                    { label: option.label, value: option.value } as TTreatment,
                  )
                }
                placeholder={t('other-idk')}

              />
            </section>
            {isFromAddCodeAndSupport ? ComingSoonBlock() : null}

            <div>
              <Button
                size="medium"
                extraClass="btn-extra-class"
                onClick={handleContinue}
                labelText={t('save')}
                disabled={!treatment.value}
              />
            </div>

            <section className="mt40 mb40">

              <Trans
                className="select-support-note"
                style={{ color: Colors.lightGray }}
                i18nKey="invitation-code-screen.note"
                parent={Text}
                components={{
                  emphasized: <Text style={{
                    fontSize: 14,
                    textDecorationLine: 'underline',
                    color: Colors.secondary,
                  }}
                  />,
                }}
              />
            </section>
          </div>
        </section>
        <Footer />
      </section>
      <DrawerRight />

    </article>
  );
};

export default InvitationCodeTreatmentSelection;
