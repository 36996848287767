import isSameDay from 'date-fns/isSameDay';
import get from 'lodash/get';
import toArray from 'lodash/toArray';
import filter from 'lodash/filter';
import slice from 'lodash/slice';
import orderBy from 'lodash/orderBy';
import find from 'lodash/find';
import flatMap from 'lodash/flatMap';
import size from 'lodash/size';

import { AppState } from '../reducers';
import { QuestionnairesState } from './types';
import {
  TSortOrder,
  TDate,
  QuestionnaireReturnType,
  QuestionnairesReturnType,
  TQuestionnaireQuestionReturn,
} from '../../../types';

import EMPTY_OBJECT from '../../utils/empty-object';
import toDate from '../../utils/toDate';
import createDeepEqualSelector from '../../selectors/createDeepEqualSelector';

const sort = (
  collection: QuestionnaireReturnType | QuestionnairesState,
  key = 'createdAt',
  order = 'desc' as TSortOrder,
): QuestionnairesReturnType => (
  orderBy(collection, [key], [order])
);

const getByIdSelector = (state: QuestionnairesState, id: string): QuestionnaireReturnType => (
  get(state, [id]) || EMPTY_OBJECT
);

const getById = createDeepEqualSelector(
  (state: AppState) => state.questionnaires,
  (state: any, id: string) => id,
  getByIdSelector,
);

const getQuestionByIdSelector = (
  state: QuestionnairesState,
  id: string,
): TQuestionnaireQuestionReturn => (
  find(flatMap(state, 'questions'), { id }) || EMPTY_OBJECT
);

const getQuestionById = createDeepEqualSelector(
  (state: AppState) => state.questionnaires,
  (state: any, id: string) => id,
  getQuestionByIdSelector,
);

const getByDateSelector = (state: QuestionnairesState, date?: TDate): QuestionnairesReturnType => (
  sort(
    filter(
      state,
      (questionnaire) => isSameDay(toDate(questionnaire.createdAt), toDate(date)),
    ),
  )
);

const getByDate = createDeepEqualSelector(
  (state: AppState) => state.questionnaires,
  (state: any, date?: TDate) => date,
  getByDateSelector,
);

const getSelector = (state: QuestionnairesState, limit = 2): QuestionnairesReturnType => (
  slice(sort(toArray(state)), 0, limit)
);

const makeGet = () => (
  createDeepEqualSelector(
    (state: AppState) => state.questionnaires,
    (state: any, limit: number) => limit,
    getSelector,
  )
);

const getLastUpdatedSelector = (
  state: QuestionnairesState,
  limit = 2,
): QuestionnairesReturnType => (
  slice(orderBy(state, [({ updatedAt, createdAt }) => updatedAt || createdAt], ['desc']), 0, limit)
);

const makeGetLastUpdated = () => (
  createDeepEqualSelector(
    (state: AppState) => state.questionnaires,
    (state: any, limit: number) => limit,
    getLastUpdatedSelector,
  )
);

const getAllSelector = (state: QuestionnairesState): QuestionnairesReturnType => (
  sort(toArray(state))
);

const getAll = createDeepEqualSelector(
  (state: AppState) => state.questionnaires,
  getAllSelector,
);

const countSelector = (state: QuestionnairesState) => size(state);

const count = createDeepEqualSelector(
  (state: AppState) => state.questionnaires,
  countSelector,
);

export {
  getById,
  getQuestionById,
  getByDate,
  makeGet,
  makeGetLastUpdated,
  getAll,
  count,
};
