import { Reducer } from 'redux';

import * as utils from './utils';
import * as actionTypes from './actionTypes';
import {
  TreatmentsState,
  TreatmentsActionTypes,
  TreatmentsAddActionType,
  TreatmentsRemoveActionType,
  TreatmentsUpdateActionType,
} from './types';

const initialState: TreatmentsState = {};

const reducer: Reducer<TreatmentsState, TreatmentsActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD: return utils.add(state, action as TreatmentsAddActionType);
    case actionTypes.REMOVE: return utils.remove(state, action as TreatmentsRemoveActionType);
    case actionTypes.UPDATE: return utils.update(state, action as TreatmentsUpdateActionType);
    case actionTypes.CLEAR: return utils.clear();
    default: return state;
  }
};

export default reducer;
