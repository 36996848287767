import React, { useState, useEffect, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';

import { AppState } from '../state/reducers';
import { QuestionnaireType, TQuestionnaireQuestionReturn, TQuestionnaireQuestion } from '../../types';

import Navigation from '../components/Navigation';
import DrawerButtons from '../components/DrawerButtons';
import Footer from '../components/Footer';
import Button from '../components/Button';
import Drawer from '../components/Drawer';
import DrawerRightHeader from '../components/DrawerRightHeader';
import ListItem from '../components/ListItem';
import QuestionEditModal from '../components/QuestionEditModal';
import { actions as questionnairesActions, selectors as questionnairesSelectors } from '../state/questionnaires';
import { actions as uiActions } from '../state/ui';
import getUnixTime from '../utils/getUnixTime';
import '../css/Questionnaire.css';
import { DeleteIconDestructive } from '../theme/Icons';
import Modal from '../components/Modal';
import Colors from '../theme/Colors';
import SuggestedQuestionsModal from '../components/SuggestedQuestionsModal';

type Params = {
  questionnaireId: string;
};

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const Questionnaire = () => {
  const { questionnaireId } = useParams<Params>();

  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();

  const [title, setTitle] = useState('');
  const [question, setQuestion] = useState<TQuestionnaireQuestionReturn>();
  const [deleteQuestion, setDeleteQuestion] = useState('');
  const [deleteQuestionnaire, setDeleteQuestionnaire] = useState('');
  const [showSuggestedQuestionsModal, setShowSuggestedQuestionsModal] = useState(false);

  const questionnaire = (
    useSelector((state: AppState) => questionnairesSelectors.getById(state, questionnaireId))
  ) as QuestionnaireType;

  useEffect(() => {
    if (questionnaire && questionnaire.title) {
      setTitle(questionnaire.title);
    }
  }, [questionnaire]);

  const handleSave = () => {
    if (!title.trim()) {
      return false;
    }

    dispatch(questionnairesActions.update([{
      ...questionnaire,
      title,
      updatedAt: getUnixTime(),
    }]));

    return history.push('/questionnaires');
  };

  const showQuestionAdd = () => dispatch(uiActions.modalShow({ type: 'questionAdd', data: questionnaireId }));

  const handleDeleteQuestion = () => {
    dispatch(questionnairesActions.removeQuestions({
      questionnaireId,
      questionIds: [deleteQuestion],
    }));

    setDeleteQuestion('');
  };

  const handleDeleteQuestionnaire = () => {
    dispatch(questionnairesActions.remove([deleteQuestionnaire]));

    setDeleteQuestionnaire('');
    history.goBack();
  };

  const hideQuestionModal = () => {
    setDeleteQuestion('');
  };

  const hideQuestionnaireModal = () => {
    setDeleteQuestionnaire('');
  };

  const handleDeleteIconClick = (questionId:string) => (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setDeleteQuestion(questionId);
  };

  const handleDeleteButtonClick = (questionId:string) => () => {
    setDeleteQuestionnaire(questionId);
  };

  // const showSuggestedQuestions = () => dispatch(uiActions.modalShow({ type: 'suggestedQuestions', data: questionnaireId }));
  // const showQuestionAdd = () => dispatch(uiActions.modalShow({ type: 'questionAdd', data: { questionnaireId, selectedQuestions: questionnaire.questions } }));
  const handleSuggestedQuestionsButtonClick = () => {
    setShowSuggestedQuestionsModal(true);
  };

  return (
    <>
      <article className="page questionnaire row">
        <Navigation />
        <section className="container">
          <section className="content">
            <DrawerButtons title={t('questionnaire')} showRightButton />
            <section className="diary-item">
              <label htmlFor="title">
                <span className="body light">{t('title')}</span>
                <input
                  value={title}
                  onChange={(event) => setTitle(event.currentTarget.value)}
                  name="title"
                  id="title"
                />
              </label>
              <h2 className="heading-secondary">{t('questions')}</h2>
              <hr className="divider" />
              {map(questionnaire.questions, (q) => (
                <ListItem
                  key={q.id}
                  primaryText={q.question}
                  secondaryText={q.answer}
                  divider
                  rightIcon={(
                    <button
                      type="button"
                      onClick={handleDeleteIconClick(q.id)}
                    >
                      {DeleteIconDestructive}
                    </button>
                )}
                  onClick={() => setQuestion(q)}
                />
              ))}
              <section className="buttons">
                <Button
                  labelText={t('save')}
                  onClick={handleSave}
                  disabled={!title.trim() || title.trim() === questionnaire?.title?.trim()}
                />
                <Button
                  labelText={t('add-question')}
                  onClick={showQuestionAdd}
                />
                <Button
                  labelText={t('add-suggested-question')}
                  onClick={handleSuggestedQuestionsButtonClick}
                />
                <Button
                  labelText={t('delete')}
                  color={Colors.destructiveRed}
                  onClick={handleDeleteButtonClick(questionnaireId)}
                />
              </section>
            </section>
          </section>
          <Footer />
        </section>
        <DrawerRight />
        <QuestionEditModal
          visible={!isEmpty(question)}
          hideModal={() => setQuestion({})}
          question={question as TQuestionnaireQuestion}
          questionnaireId={questionnaireId}
        />
      </article>
      <Modal
        visible={!isEmpty(deleteQuestion)}
        hideModal={hideQuestionModal}
        actions={[
          {
            title: t('cancel'),
            onClick: hideQuestionModal,
          },
          {
            title: t('delete'),
            onClick: handleDeleteQuestion,
            destructive: true,
          },
        ]}
      >
        <>
          <h1 className="heading">{t('delete-questionnaire-question')}</h1>
          <p className="body">{t('delete-questionnaire-question-confirm')}</p>
        </>
      </Modal>

      <Modal
        visible={!isEmpty(deleteQuestionnaire)}
        hideModal={hideQuestionnaireModal}
        actions={[
          {
            title: t('cancel'),
            onClick: hideQuestionnaireModal,
          },
          {
            title: t('delete'),
            onClick: handleDeleteQuestionnaire,
            destructive: true,
          },
        ]}
      >
        <>
          <h1 className="heading">{t('delete-questionnaire-question')}</h1>
          <p className="body">{t('delete-questionnaire-question-confirm')}</p>
        </>
      </Modal>
      <SuggestedQuestionsModal
        showModal={showSuggestedQuestionsModal}
        setShowModal={setShowSuggestedQuestionsModal}
        questionnaire={questionnaire}
      />
    </>
  );
};

export default Questionnaire;
