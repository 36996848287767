import { Reducer } from 'redux';

import * as actionTypes from './actionTypes';
import {
  UIState,
  UIActionTypes,
  UIShowNotificationActionType,
  UIRecordingUpdateActionType,
  UIModalShowActionType,
  UIValidateNavigationModalShowActionType,
} from './types';

const initialState: UIState = {
  notification: {
    visible: false,
    text: '',
    type: '',
  },
  profileCompletedModalVisible: false,
  profileIncompleteModalVisible: false,
  validateNavigationModal: {
    visible: false,
    type: '',
    data: '',
  },
  recording: {
    active: false,
    paused: false,
    timestamp: 0,
  },
  drawerLeftOpen: false,
  drawerRightOpen: false,
  modal: {
    visible: false,
    type: '',
    data: '',
  },
};

const reducer: Reducer<UIState, UIActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_NOTIFICATION: {
      const { payload } = action as UIShowNotificationActionType;

      return {
        ...state,
        notification: {
          ...payload,
          visible: true,
        },
      };
    }
    case actionTypes.CLEAR_NOTIFICATION: return {
      ...state,
      notification: initialState.notification,
    };
    case actionTypes.PROFILE_COMPLETED_MODAL_SHOW: return {
      ...state,
      profileCompletedModalVisible: true,
    };
    case actionTypes.PROFILE_COMPLETED_MODAL_HIDE: return {
      ...state,
      profileCompletedModalVisible: false,
    };
    case actionTypes.PROFILE_INCOMPLETE_MODAL_SHOW: return {
      ...state,
      profileIncompleteModalVisible: true,
    };
    case actionTypes.PROFILE_INCOMPLETE_MODAL_HIDE: return {
      ...state,
      profileIncompleteModalVisible: false,
    };
    case actionTypes.RECORDING_UPDATE: {
      const { payload } = action as UIRecordingUpdateActionType;

      return {
        ...state,
        recording: {
          ...state.recording,
          ...payload,
        },
      };
    }
    case actionTypes.TOGGLE_DRAWER_LEFT: return {
      ...state,
      drawerLeftOpen: !state.drawerLeftOpen,
    };
    case actionTypes.TOGGLE_DRAWER_RIGHT: return {
      ...state,
      drawerRightOpen: !state.drawerRightOpen,
    };
    case actionTypes.MODAL_SHOW: {
      const { payload } = action as UIModalShowActionType;

      return {
        ...state,
        modal: {
          ...payload,
          visible: true,
        },
      };
    }
    case actionTypes.MODAL_HIDE: return {
      ...state,
      modal: initialState.modal,
    };
    case actionTypes.VALIDATE_NAVIGATION_MODAL_SHOW: {
      const { payload } = action as UIValidateNavigationModalShowActionType;

      return {
        ...state,
        validateNavigationModal: {
          ...payload,
          visible: true,
        },
      };
    }
    case actionTypes.VALIDATE_NAVIGATION_MODAL_HIDE: return {
      ...state,
      validateNavigationModal: initialState.validateNavigationModal,
    };
    default: return state;
  }
};

export default reducer;
