import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AppState } from '../state/reducers';
import { TTreatmentType } from '../../types';

import '../css/Treatment.css';
import Treatment from '../components/Treatment';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRightHeader from '../components/DrawerRightHeader';
import { selectors as treatmentTypesSelectors } from '../state/treatmentTypes';
import isOfType from '../utils/isOfType';

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const TreatmentAdd = () => {
  const { t, i18n } = useTranslation();

  const memoizedTreatmentTypesGetByLocaleSelector = useMemo(
    treatmentTypesSelectors.makeGetByLocale,
    [],
  );

  const treatmentTypes = useSelector(
    (state: AppState) => (
      memoizedTreatmentTypesGetByLocaleSelector(state, i18n.language)
    ),
  );
  let content = <p className="body light">{t('treatmentTypes:empty-state-heading')}</p>;

  if (isOfType<TTreatmentType[]>(treatmentTypes)) {
    content = <Treatment treatmentTypes={treatmentTypes} />;
  }

  return (
    <article className="page treatment row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('add-treatment')} showRightButton />
          {content}
        </section>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default TreatmentAdd;
