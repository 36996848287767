/* eslint react/require-default-props: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Path } from 'react-native-svg';
import ResizeableSVG from '../ResizeableSVG';

import Colors from '../../theme/Colors';
import Constants from '../../theme/Constants';

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

type Props = {
  color?: string;
  size?: number;
};

const Account = (props: Props) => {
  const {
    color = Colors.primary,
    size = Constants.defaultIconSize,
  } = props;

  return (
    <ResizeableSVG size={size}>
      <Path d="M13.9996234,13.8188665 C12.538505,13.8188665 11.3532291,12.6345321 11.3532291,11.1715308 C11.3532291,9.71229524 12.5394464,8.52701939 13.9996234,8.52701939 C15.4607419,8.52701939 16.6469591,9.71229524 16.6469591,11.1715308 C16.6469591,12.6345321 15.4616833,13.8188665 13.9996234,13.8188665 M13.9995293,1.5 C20.9031256,1.5 26.5,7.09593297 26.5,13.9985878 C26.5,20.9021841 20.9031256,26.5 13.9995293,26.5 C7.09687441,26.5 1.5,20.9021841 1.5,13.9985878 C1.5,7.09593297 7.09687441,1.5 13.9995293,1.5 Z M13.9995293,16.6741668 C10.6376389,16.6741668 7.83778949,19.0531915 7.17783845,22.2202034 C9.02871399,23.7575786 11.4058558,24.6839578 13.9995293,24.6839578 C16.5941442,24.6839578 18.971286,23.7575786 20.8221616,22.2202034 C20.1622105,19.0541329 17.3623611,16.6741668 13.9995293,16.6741668 Z M13.9995293,3.31510073 C8.09951045,3.31510073 3.31604218,8.09856901 3.31604218,13.9985878 C3.31604218,16.8323291 4.42694408,19.4015251 6.22698174,21.3145359 C7.23055922,17.9658256 10.32508,15.5237243 13.9995293,15.5237243 C17.67492,15.5237243 20.7703822,17.9658256 21.7730183,21.3145359 C23.5730559,19.4015251 24.6848993,16.8323291 24.6848993,13.9985878 C24.6848993,8.09856901 19.901431,3.31510073 13.9995293,3.31510073 Z M13.9996234,7.37657692 C16.0962154,7.37657692 17.7955187,9.07682169 17.7955187,11.1715308 C17.7955187,13.2690642 16.0971568,14.969309 13.9996234,14.969309 C11.9030314,14.969309 10.2046696,13.2690642 10.2046696,11.1715308 C10.2046696,9.07682169 11.9039729,7.37657692 13.9996234,7.37657692 Z" fill={color} fillRule="evenodd" />
    </ResizeableSVG>
  );
};

Account.propTypes = propTypes;

export default Account;
