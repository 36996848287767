/* eslint react/require-default-props: 0 */
import React, { useReducer, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import startOfMinute from 'date-fns/startOfMinute';
import noop from 'lodash/noop';
import isEqual from 'lodash/isEqual';
import DatePicker from 'react-datepicker';
import { useHistory } from 'react-router-dom';

import { TState } from './types';
import { NoteType } from '../../../types';
import { NotePropType } from '../../../propTypes';

import reducer from './reducer';
import * as actions from './actions';
import Button from '../Button';
import Modal from '../Modal';
import { actions as notesActions } from '../../state/notes';
import { actions as appointmentsActions } from '../../state/appointments';
import { text as textSchema, date as dateSchema } from '../../utils/schemas';
import { formValidation } from '../../utils/formValidation';
import getUnixTime from '../../utils/getUnixTime';
import toDate from '../../utils/toDate';
import defaultGet from '../../utils/defaultGet';
import isOfType from '../../utils/isOfType';
import Colors from '../../theme/Colors';
import '../../css/Note.css';

type Props = {
  note?: NoteType;
  appointmentId?: string;
};

const propTypes = {
  note: NotePropType,
  appointmentId: PropTypes.string,
};

const Note = (props: Props) => {
  const {
    note = {},
    appointmentId,
  } = props;

  const history = useHistory();

  const [visible, setVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const now = startOfMinute(new Date());

  const createdAt = toDate(defaultGet(note, 'createdAt', now));
  const title = defaultGet(note, 'title', '');
  const body = defaultGet(note, 'body', '');

  const initialState: TState = {
    createdAt,
    title,
    body,
  };

  const [state, localDispatch] = useReducer(reducer, initialState);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    if (!isEqual(state, initialState)) {
      const shape = {
        createdAt: dateSchema(true),
        title: textSchema(true),
        body: textSchema(false),
      };

      const schema = yup
        .object()
        .shape(shape);

      const formData = {
        createdAt: state.createdAt,
        title: state.title,
        body: state.body,
      };

      if (!formValidation(schema, formData, noop)) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    } else {
      setDisabled(true);
    }
  }, [state]);

  const handleRemove = () => {
    if (!isOfType<NoteType>(note, 'id')) {
      return false;
    }

    dispatch(notesActions.remove([note.id]));

    return history.goBack();
  };

  const handleSave = () => {
    if (isOfType<NoteType>(note, 'id')) {
      dispatch(notesActions.update([{
        ...note,
        createdAt: getUnixTime(state.createdAt),
        updatedAt: getUnixTime(),
        title: state.title,
        body: state.body,
      }]));
    } else {
      const id = uuidv4();

      dispatch(notesActions.add([{
        id,
        createdAt: getUnixTime(state.createdAt),
        title: state.title,
        body: state.body,
        appointmentId,
      }]));

      if (appointmentId) {
        dispatch(appointmentsActions.addNotes({
          appointmentId,
          noteIds: [id],
        }));
      }
    }

    history.goBack();
  };

  return (
    <>
      <section className="note-content">
        <label // eslint-disable-line jsx-a11y/label-has-associated-control
          htmlFor="created-at"
        >
          <span className="body light">{t('date-time')}</span>
          <DatePicker
            selected={state.createdAt}
            onChange={(date: Date) => localDispatch(actions.updateCreatedAt(date))}
            showTimeSelect
            dateFormat="dd/LL/yyyy HH:mm"
            timeFormat="HH:mm"
            id="created-at"
          />
        </label>
        <label htmlFor="title">
          <span className="body light">{t('title')}</span>
          <input
            value={state.title}
            onChange={(event) => localDispatch(actions.updateTitle(event.currentTarget.value))}
            name="title"
            id="title"
          />
        </label>
        <div className="separator" />
        <label htmlFor="note">
          <span className="body light">{t('note')}</span>
          <textarea
            value={state.body}
            onChange={(event) => localDispatch(actions.updateBody(event.currentTarget.value))}
            name="note"
            id="note"
          />
        </label>
        <section className="buttons">
          {
            isOfType<NoteType>(note, 'id')
              ? (
                <Button
                  labelText={t('delete')}
                  onClick={() => setVisible(true)}
                  color={Colors.destructiveRed}
                  size="medium"
                />
              )
              : null
          }
          <Button
            labelText={t('save')}
            onClick={handleSave}
            size="medium"
            disabled={disabled}
          />
        </section>
      </section>
      <Modal
        visible={visible}
        hideModal={() => setVisible(false)}
        actions={[
          {
            title: t('cancel'),
            onClick: () => setVisible(false),
          },
          {
            title: t('delete'),
            onClick: handleRemove,
            destructive: true,
          },
        ]}
      >
        <h1 className="heading">{t('alerts.delete.title', { w: t('note') })}</h1>
        <p className="body">{t('alerts.delete.body', { w: t('note') })}</p>
      </Modal>
    </>
  );
};

Note.propTypes = propTypes;

export default Note;
