import React, { Dispatch, SetStateAction, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Dropdown from 'react-dropdown';

import { TDrugModule } from 'types';
import Modal from '../Modal';
import Button from '../Button';
import Text from '../Text';

import { actions as settingsActions } from '../../state/settings';
import Colors from '../../theme/Colors';
import { ReactComponent as IconCompleted } from '../../assets/icons/Completed.svg';
import { ReactComponent as BulbSVG } from '../../assets/icons/Bulb.svg';
import { ReactComponent as IconTreatmentPlan } from '../../assets/icons/TreatmentPlan.svg';

type TTreatmentValue = TDrugModule | 'other' | '';
type TTreatment = { label: string, value: TTreatmentValue };

export type TreatmentSelectionModalProps = {
  invitationCode: string,
  isModalVisible: boolean,
  setModalVisible: Dispatch<SetStateAction<boolean>>,
  setModalCodeVisible: Dispatch<SetStateAction<boolean>>,
  setInvitationCode: Dispatch<SetStateAction<string>>,
};

const TreatmentSelectionModal = (props: TreatmentSelectionModalProps) => {
  const {
    invitationCode, isModalVisible, setModalVisible, setModalCodeVisible, setInvitationCode,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const treatments: TTreatment[] = [
    {
      label: t('other-idk'),
      value: 'other',
    },
    {
      label: 'Olaparib (Lynparza®)',
      value: 'lynparza',
    },
    {
      label: 'Trastuzumab deruxtecan (Enhertu®)',
      value: 'enhertu',
    },
  ];

  const [treatment, setTreatment] = useState<TTreatment>(treatments[0]);

  const checkIsDrugModule = (value: string): value is TDrugModule => !!value && value !== 'other';

  const handleContinue = () => {
    if (!invitationCode) {
      setModalVisible(false);
      setModalCodeVisible(false);
      return;
    }

    if (checkIsDrugModule(treatment.value)) {
      const moduleName = treatment.value;
      dispatch(settingsActions.drugModulesAdd([
        {
          invitationCode: invitationCode.toUpperCase(),
          drugModule: moduleName,
        },
      ]));
      setInvitationCode('');
      setModalVisible(false);
      setModalCodeVisible(false);
    }
    setInvitationCode('');
    setModalVisible(false);
    setModalCodeVisible(false);
  };

  const ComingSoonBlock = () => (
    <section className="coming-soon-container">
      <div className="coming-soon-icon">
        <BulbSVG fill={Colors.primary} className="icon medium" />
      </div>
      <p className="select-support-features">{t('invitation-code-screen.coming-soon')}</p>
    </section>
  );

  return (
    <Modal
      visible={isModalVisible}
      hideModal={() => setModalVisible(false)}
      actions={[]}
      className="modal-treatment-selection"
    >
      <section>
        <div className="select-support-container">

          <h3 className="select-support-title">{t('invitation-code-screen.title')}</h3>
          <IconCompleted fill={Colors.secondary} className="img-center icons" />
          <section className="support-features">
            <Trans
              i18nKey="invitation-code-screen.support-features"
              parent={Text}
              components={{ bold: <Text style={{ fontWeight: 'bold' }} /> }}
            />
          </section>
          {ComingSoonBlock()}

          <h4 className="select-support-subtitle mt40">{t('invitation-code-screen.subtitle')}</h4>

          <section className="select-support-dropdown-container">
            <div className="coming-soon-icon mb15">
              <IconTreatmentPlan fill={Colors.primary} className="icon" />
            </div>
            <Dropdown
              className="select-support-dropdown"
              options={treatments}
              onChange={
                  (option) => setTreatment(
                    { label: option.label, value: option.value } as TTreatment,
                  )
                }
              placeholder={t('other-idk')}
            />
          </section>

          <div>
            <Button
              size="medium"
              extraClass="btn-extra-class"
              onClick={handleContinue}
              labelText={t('save')}
              disabled={!treatment.value}
            />
          </div>

          <section className="mt40 mb40">

            <Trans
              className="select-support-note"
              style={{ color: Colors.lightGray }}
              i18nKey="invitation-code-screen.note"
              parent={Text}
              components={{
                emphasized: <Text style={{
                  fontSize: 14,
                  textDecorationLine: 'underline',
                  color: Colors.secondary,
                }}
                />,
              }}
            />
          </section>
        </div>
      </section>
    </Modal>
  );
};

export default TreatmentSelectionModal;
