/* eslint react/require-default-props: 0 */
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  memo,
} from 'react';
import PropTypes from 'prop-types';
import { View as RNView } from 'react-native';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as d3Format from 'd3-format';
import round from 'lodash/round';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { AppState } from '../../state/reducers';
import { TrendIndicatorType } from '../../../types';
import { TrendIndicatorPropType } from '../../../propTypes';

import styles from './styles';
import Divider from '../Divider';
import Slider from '../Slider';
import NumericInput from '../NumericInput';
import HeartRateInput from '../HeartRateInput';
import PSALevelInput from '../PSALevelInput';
import Touchable from '../Touchable';
import { InfoIcon } from '../../theme/Icons';
import { selectors as trendsSelectors } from '../../state/trends';
import getLocalizedText from '../../utils/getLocalizedText';

export type Measurement = Record<string, { value: number, changed: boolean }>;

type Props = {
  indicator: TrendIndicatorType;
  setMeasurements?: React.Dispatch<React.SetStateAction<{}>>;
  setTestFitnessMeasurements?: (measurement: Measurement) => void
  showModal: (title: string, description: string) => void;
  dividers?: boolean;
};

const propTypes = {
  indicator: TrendIndicatorPropType.isRequired,
  setMeasurements: PropTypes.func,
  setTestFitnessMeasurements: PropTypes.func,
  showModal: PropTypes.func.isRequired,
  dividers: PropTypes.bool,
};

const TrendIndicator = (props: Props) => {
  const {
    indicator,
    setMeasurements,
    setTestFitnessMeasurements,
    showModal,
    dividers = true,
  } = props;

  const {
    id,
    type,
    minimumValue,
    maximumValue,
    decimals = 1,
    step,
    color,
    labels,
  } = indicator;

  const memoizedGetLatestDataByTrendIndicatorIdSelector = useMemo(
    trendsSelectors.makeGetLatestDataByTrendIndicatorId,
    [],
  );

  const latestData = useSelector(
    (state: AppState) => memoizedGetLatestDataByTrendIndicatorIdSelector(state, id),
  );

  let defaultValue = get(indicator, 'defaultValue');

  if (!isEmpty(latestData)) {
    const explicitValue = get(latestData, ['explicitData', id]);

    if (explicitValue || explicitValue === 0) {
      defaultValue = explicitValue;
    }
  }

  const { i18n } = useTranslation();

  const title = getLocalizedText(indicator, i18n.language, 'title');
  const description = getLocalizedText(indicator, i18n.language, 'description');
  const minimumValueLabel = getLocalizedText(indicator, i18n.language, 'minimumValueLabel');
  const maximumValueLabel = getLocalizedText(indicator, i18n.language, 'maximumValueLabel');
  const labelUnit = getLocalizedText(indicator, i18n.language, 'labelUnit');

  const [value, setValue] = useState(defaultValue);
  const [pristine, setPristine] = useState(true);
  const [measurement, setMeasurement] = useState({ value: defaultValue, changed: false });

  useEffect(() => {
    if (setTestFitnessMeasurements) {
      setTestFitnessMeasurements({ [id]: measurement });
      return;
    }

    if (setMeasurements) {
      setMeasurements((prevMeasurements) => ({
        ...prevMeasurements,
        [id]: measurement,
      }));
    }
  }, [id, measurement, setMeasurements, setTestFitnessMeasurements]);

  const onValueChange = useCallback((changedValue: number) => {
    setValue(changedValue);
    setPristine(false);
    setMeasurement({ value: round(changedValue, decimals), changed: true });
  }, []);

  let valueLabelText;

  if (!isEmpty(labels)) {
    valueLabelText = getLocalizedText(labels, i18n.language, value);
  } else {
    valueLabelText = d3Format.format('.1f')(value);

    if (step > 0 && step < 1) {
      valueLabelText = d3Format.format('.1f')(round(value, 1));
    }

    if (labelUnit) {
      valueLabelText = `${valueLabelText} ${labelUnit}`;
    }
  }

  const headerButton = (
    <Touchable
      onPress={() => showModal(title, description)}
      opacity
    >
      {InfoIcon}
    </Touchable>
  );

  let input = (
    <Slider
      key={`trend-indicator-slider-${id}`}
      divider={dividers}
      labelText={title}
      value={defaultValue}
      onSlidingComplete={onValueChange}
      onValueChange={onValueChange}
      minimumValue={minimumValue}
      maximumValue={maximumValue}
      step={step}
      showValue={false}
      valueLabelText={valueLabelText}
      minimumValueLabelText={minimumValueLabel}
      maximumValueLabelText={maximumValueLabel}
      minimumTrackTintColor={color}
      pristine={pristine}
      headerButton={headerButton}
    />
  );

  if (
    type === 'weight'
      || type === 'temperature'
      || type === 'bp'
      || type === 'numeric'
  ) {
    input = (
      <NumericInput
        key={`trend-indicator-numeric-input-${id}`}
        labelText={title}
        unitLabelText={labelUnit}
        value={value}
        onChange={onValueChange}
        minValue={minimumValue}
        maxValue={maximumValue}
        step={step}
        decimals={decimals}
        divider={dividers}
        pristine={pristine}
        headerButton={headerButton}
      />
    );
  }

  if (type === 'bpm') {
    input = (
      <HeartRateInput
        key={`trend-indicator-numeric-input-${id}`}
        labelText={title}
        onValueChange={onValueChange}
        labelUnit={labelUnit}
        value={value}
        divider={dividers}
        pristine={pristine}
        headerButton={headerButton}
        minimumValue={minimumValue}
        maximumValue={maximumValue}
      />
    );
  }

  if (type === 'psa') {
    input = (
      <PSALevelInput
        labelText={title}
        onValueChange={onValueChange}
        labelUnit={labelUnit}
        maximumValue={maximumValue}
        value={value}
        headerButton={headerButton}
        divider={dividers}
        decimals={decimals}
      />
    );
  }

  return (
    <RNView style={styles.content}>
      {dividers ? <Divider /> : null}
      {input}
    </RNView>
  );
};

TrendIndicator.propTypes = propTypes;

export default memo(TrendIndicator);
