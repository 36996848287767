import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import Tooltip from 'react-tooltip';

import ListItem from './ListItem';
import IconRadio from './Icons/Radio';
import { InfoIcon } from '../theme/Icons';
import Colors from '../theme/Colors';

type Props = {
  value: string;
  options: {
    value: string;
    labelText: string;
    description?: string;
  }[];
  onChange: (value: string) => void;
};

const propTypes = {
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    labelText: PropTypes.node.isRequired,
    description: PropTypes.string,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
};

const RadioGroup = (props: Props) => {
  const { options, value, onChange } = props;

  return (
    <section className="radio-group">
      {map(options, (option) => {
        const active = option.value === value;

        return (
          <ListItem
            key={option.value}
            primaryText={option.labelText}
            onClick={() => onChange(option.value)}
            leftIcon={
              <IconRadio active={active} color={Colors.secondary} />
            }
            divider
            active={active}
            rightIcon={
              option.description
                ? (
                  <>
                    <div data-tip={option.description}>
                      {InfoIcon}
                    </div>
                    <Tooltip
                      className="tooltip body contrast"
                      backgroundColor={Colors.black}
                    />
                  </>
                )
                : null
            }
          />
        );
      })}
    </section>
  );
};

RadioGroup.propTypes = propTypes;

export default RadioGroup;
