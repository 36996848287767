import isNil from 'lodash/isNil';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

const isOfType = <T>(value: any, property?: keyof T): value is T => {
  if (isNil(value)) {
    return false;
  }

  if (isEmpty(value)) {
    return false;
  }

  if (isArray(value)) {
    return true;
  }

  if (isObject(value)) {
    if (isNil(property)) {
      return false;
    }

    return !isNil(get(value, [property]));
  }

  return false;
};

export default isOfType;
