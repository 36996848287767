import omit from 'lodash/omit';
import keyBy from 'lodash/keyBy';

import {
  PhotosState,
  PhotosAddActionType,
  PhotosRemoveActionType,
  PhotosUpdateActionType,
} from './types';

const add = (state: PhotosState, action: PhotosAddActionType) => {
  const { payload } = action;

  const photos = keyBy(payload, 'id');

  return {
    ...state,
    ...photos,
  };
};

const remove = (state: PhotosState, action: PhotosRemoveActionType) => {
  const { payload } = action;

  return omit(state, payload);
};

const update = (state: PhotosState, action: PhotosUpdateActionType) => {
  const { payload } = action;

  const photos = keyBy(payload, 'id');

  return {
    ...state,
    ...photos,
  };
};

const clear = () => ({});

export {
  add,
  remove,
  update,
  clear,
};
