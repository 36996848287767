import * as actionTypes from './actionTypes';
import {
  SettingsTrendIndicatorsActionTypes,
  SettingsGeneralUpdateActionType,
  SettingsTrendIndicatorsUpdatePayload,
  SettingsTrendIndicatorsUpdateDisabledPayload,
  SettingsTrendIndicatorsUpdateFavoritePayload,
  SettingsTrendIndicatorsUpdateOrderPayload,
  SettingsGeneralUpdatePayload,
  AccountStatusType,
  ChangeAccountStatusActionType,
  IsFirstLaunchActionType,
  ContentModulesUpdatePayload,
  ContentDrugModulesAddPayload,
  ContentDrugModulesRemovePayload,
} from './types';

const trendIndicatorsUpdate = (
  payload: SettingsTrendIndicatorsUpdatePayload,
): SettingsTrendIndicatorsActionTypes => ({
  type: actionTypes.TREND_INDICATORS_UPDATE,
  payload,
});

const trendIndicatorsUpdateDisabled = (
  payload: SettingsTrendIndicatorsUpdateDisabledPayload,
): SettingsTrendIndicatorsActionTypes => ({
  type: actionTypes.TREND_INDICATORS_UPDATE_DISABLED,
  payload,
});

const trendIndicatorsUpdateFavorite = (
  payload: SettingsTrendIndicatorsUpdateFavoritePayload,
): SettingsTrendIndicatorsActionTypes => ({
  type: actionTypes.TREND_INDICATORS_UPDATE_FAVORITE,
  payload,
});

const trendIndicatorsUpdateOrder = (
  payload: SettingsTrendIndicatorsUpdateOrderPayload,
): SettingsTrendIndicatorsActionTypes => ({
  type: actionTypes.TREND_INDICATORS_UPDATE_ORDER,
  payload,
});

const trendIndicatorsClear = (): SettingsTrendIndicatorsActionTypes => ({
  type: actionTypes.TREND_INDICATORS_CLEAR,
});

const generalUpdate = (
  payload: SettingsGeneralUpdatePayload,
): SettingsGeneralUpdateActionType => ({
  type: actionTypes.GENERAL_UPDATE,
  payload,
});

const invitationCodesAdd = (payload: string[]) => ({
  type: actionTypes.INVITATION_CODES_ADD,
  payload,
});

const invitationCodesEdit = (payload: string[]) => ({
  type: actionTypes.INVITATION_CODES_EDIT,
  payload,
});

const invitationCodesRemove = (payload: string[]) => ({
  type: actionTypes.INVITATION_CODES_REMOVE,
  payload,
});

const invitationLastCodeRemove = (payload: string[]) => ({
  type: actionTypes.INVITATION_LAST_CODE_REMOVE,
  payload,
});

const invitationLastCodeAndDataRemove = (payload: string[]) => ({
  type: actionTypes.INVITATION_LAST_CODE_AND_DATA_REMOVE,
  payload,
});

const hospitalGroupAccessCodesAdd = (payload: string[]) => ({
  type: actionTypes.HOSPITAL_GROUP_ACCESS_CODES_ADD,
  payload,
});

const hospitalGroupAccessCodesRemove = (payload: string[]) => ({
  type: actionTypes.HOSPITAL_GROUP_ACCESS_CODES_REMOVE,
  payload,
});

const bannersTrendsNext = () => ({
  type: actionTypes.BANNERS_TRENDS_NEXT,
});

const clear = () => ({
  type: actionTypes.CLEAR,
});

const changeAccountStatus = (payload: AccountStatusType): ChangeAccountStatusActionType => ({
  type: actionTypes.CHANGE_STATUS,
  payload,
});

const setIsFirstLaunch = (payload: boolean): IsFirstLaunchActionType => ({
  type: actionTypes.IS_FIRST_LAUNCH,
  payload,
});

const contentModulesUpdate = (payload: ContentModulesUpdatePayload) => ({
  type: actionTypes.CONTENT_MODULES_UPDATE,
  payload,
});

const drugModulesAdd = (payload: ContentDrugModulesAddPayload) => ({
  type: actionTypes.CONTENT_DRUG_MODULES_ADD,
  payload,
});

const drugModulesRemove = (payload: ContentDrugModulesRemovePayload) => ({
  type: actionTypes.CONTENT_DRUG_MODULES_REMOVE,
  payload,
});

const contentModulesClear = () => ({
  type: actionTypes.CONTENT_MODULES_CLEAR,
});

export {
  trendIndicatorsUpdate,
  trendIndicatorsUpdateDisabled,
  trendIndicatorsUpdateFavorite,
  trendIndicatorsUpdateOrder,
  trendIndicatorsClear,
  generalUpdate,
  invitationCodesAdd,
  invitationCodesEdit,
  invitationCodesRemove,
  invitationLastCodeRemove,
  invitationLastCodeAndDataRemove,
  hospitalGroupAccessCodesAdd,
  hospitalGroupAccessCodesRemove,
  bannersTrendsNext,
  clear,
  changeAccountStatus,
  contentModulesUpdate,
  contentModulesClear,
  setIsFirstLaunch,
  drugModulesAdd,
  drugModulesRemove,
};
