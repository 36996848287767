import { Reducer } from 'redux';

import * as utils from './utils';
import * as actionTypes from './actionTypes';
import {
  TagsState,
  TagsActionTypes,
  TagsAddActionType,
  TagsRemoveActionType,
} from './types';

const initialState: TagsState = {};

const reducer: Reducer<TagsState, TagsActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD: return utils.add(state, action as TagsAddActionType);
    case actionTypes.REMOVE: return utils.remove(state, action as TagsRemoveActionType);
    case actionTypes.CLEAR: return utils.clear();
    default: return state;
  }
};

export default reducer;
