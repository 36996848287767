import get from 'lodash/get';
import toArray from 'lodash/toArray';
import size from 'lodash/size';
import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';
import isSameDay from 'date-fns/isSameDay';
import isSameWeek from 'date-fns/isSameWeek';
import isSameMonth from 'date-fns/isSameMonth';

import { AppState } from '../reducers';
import { MessagesState } from './types';
import { TDate, MessageReturnType, MessagesReturnType } from '../../../types';

import EMPTY_OBJECT from '../../utils/empty-object';
import toDate from '../../utils/toDate';
import createDeepEqualSelector from '../../selectors/createDeepEqualSelector';

const sort = (collection: MessagesReturnType | MessagesState): MessagesReturnType => (
  orderBy(collection, ['createdAt'], ['desc'])
);

const getByIdSelector = (state: MessagesState, id: string): MessageReturnType => (
  get(state, [id]) || EMPTY_OBJECT
);

const makeGetById = () => (
  createDeepEqualSelector(
    (state: AppState) => state.messages,
    (state: any, id: string) => id,
    getByIdSelector,
  )
);

const getByDateSelector = (state: MessagesState, date?: TDate) => (
  sort(filter(state, (message) => isSameDay(toDate(message.createdAt), toDate(date))))
);

const makeGetByDate = () => (
  createDeepEqualSelector(
    (state: AppState) => state.messages,
    (state: any, date: TDate) => date,
    getByDateSelector,
  )
);

const getByWeekSelector = (state: MessagesState, date?: TDate) => (
  sort(filter(state, (message) => isSameWeek(toDate(message.createdAt), toDate(date))))
);

const makeGetByWeek = () => (
  createDeepEqualSelector(
    (state: AppState) => state.messages,
    (state: any, date: TDate) => date,
    getByWeekSelector,
  )
);

const getByMonthSelector = (state: MessagesState, date?: TDate) => (
  sort(filter(state, (message) => isSameMonth(toDate(message.createdAt), toDate(date))))
);

const makeGetByMonth = () => (
  createDeepEqualSelector(
    (state: AppState) => state.messages,
    (state: any, date: TDate) => date,
    getByMonthSelector,
  )
);

const getAllSelector = (state: MessagesState) => sort(toArray(state));

const getAll = createDeepEqualSelector(
  (state: AppState) => state.messages,
  getAllSelector,
);

const getAllByViewAndDateSelector = (state: MessagesState, view = 'all', date: TDate): MessagesReturnType => {
  switch (view) {
    case 'all': return getAllSelector(state);
    case 'day': return getByDateSelector(state, date);
    case 'week': return getByWeekSelector(state, date);
    case 'month': return getByMonthSelector(state, date);
    default:
  }

  return getAllSelector(state);
};

const makeGetAllByViewAndDate = () => (
  createDeepEqualSelector(
    (state: AppState) => state.messages,
    (state: any, view: string) => view,
    (state: any, view: string, date: TDate) => date,
    getAllByViewAndDateSelector,
  )
);

const countSelector = (state: MessagesState) => size(state);

const count = createDeepEqualSelector(
  (state: AppState) => state.messages,
  countSelector,
);

const unreadSelector = (state: MessagesState) => size(filter(state, (message) => !message.readAt));

const unread = createDeepEqualSelector(
  (state: AppState) => state.messages,
  unreadSelector,
);

export {
  makeGetById,
  makeGetByDate,
  makeGetByWeek,
  makeGetByMonth,
  getAll,
  makeGetAllByViewAndDate,
  count,
  unread,
};
