import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import keyBy from 'lodash/keyBy';

import {
  TrendIndicatorsState,
  TrendIndicatorsAddActionType,
  TrendIndicatorsReplaceActionType,
} from './types';

const add = (state: TrendIndicatorsState, action: TrendIndicatorsAddActionType) => {
  const { payload } = action;

  const trendIndicators = keyBy(payload, 'id');

  return {
    ...state,
    ...trendIndicators,
  };
};

const replace = (state: TrendIndicatorsState, action: TrendIndicatorsReplaceActionType) => {
  const { payload } = action;

  if (isEmpty(payload)) {
    return state;
  }

  const newState = keyBy(payload, 'id');

  if (isEqual(state, newState)) {
    return state;
  }

  return newState;
};

const clear = () => ({});

export {
  add,
  replace,
  clear,
};
