import { StyleSheet as RNStyleSheet } from 'react-native';

import Colors from '../../theme/Colors';

export default RNStyleSheet.create({
  container: {
    paddingLeft: 17,
    paddingRight: 11,
    paddingTop: 8,
    paddingBottom: 12,
  },
  inputContainer: {
    flexDirection: 'row',
    padding: 8,
    borderWidth: 2,
    borderColor: Colors.lightPrimary,
    borderRadius: 28,
    alignItems: 'center',
    alignSelf: 'center',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 12,
  },
  centeredHeader: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  button: {
    width: 40,
    height: 40,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.secondary,
  },
  disabled: {
    opacity: 0.5,
  },
  textContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 12,
    minWidth: 100,
  },
  text: {
    textAlign: 'center',
    fontVariant: ['tabular-nums'], // Convert to monospace font
    color: Colors.primary,
  },
  units: {
    lineHeight: 38,
    marginLeft: 4,
  },
});
