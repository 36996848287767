import { createSelector } from 'reselect';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import includes from 'lodash/includes';

import { selectors as tagsSelectors } from '../state/tags';
import { selectors as profileQuestionCategoriesSelectors } from '../state/profileQuestionCategories';
import { selectors as profileQuestionsSelectors } from '../state/profileQuestions';
import includesAny from '../utils/includesAny';

const getProfileQuestionCategories = createSelector(
  [
    tagsSelectors.getAll,
    profileQuestionCategoriesSelectors.getAll,
    profileQuestionsSelectors.getAll,
  ],
  (tags, profileQuestionCategories, profileQuestions) => (
    map(profileQuestionCategories, (category) => {
      const categoryQuestions = filter(
        profileQuestions,
        (question) => includes(category.profileQuestionIds, question.id),
      );

      const profileQuestionIds = map(
        filter(categoryQuestions, (question) => {
          if (isEmpty(question.tags)) {
            return false;
          }

          // TODO: This is a quick fix and needs a better solution!
          // Ignore treatmentplan tag
          if (includesAny(question.tags, ['treatmentplan:yes', 'treatmentplan:no'])) {
            return true;
          }

          return includesAny(question.tags, tags);
        }),
        'id',
      );

      return {
        ...category,
        profileQuestionIds,
      };
    })
  ),
);

export default getProfileQuestionCategories;
