import { BackendModule } from 'i18next';
import noop from 'lodash/noop';
import includes from 'lodash/includes';

import * as config from './config';

const translationLoader: BackendModule = {
  type: 'backend',
  init: noop,
  read: (detectedLanguage: string, namespace: string, callback: Function) => {
    let resource: object;

    // Either use the detected language or the default language
    const language = includes(config.allowedLanguages, detectedLanguage)
      ? detectedLanguage
      : config.fallbackLng;

    try {
      resource = config
        .supportedLocales[language]
        .translationFileLoader(namespace)[namespace];
    } catch (error) {
      return callback(error, null);
    }

    return callback(null, resource);
  },
  create: noop,
};

export default translationLoader;
