import format from 'date-fns/format';
import {
  enGB,
  nl,
  enUS,
  es,
} from 'date-fns/locale';

import toDate from './toDate';

import { TDate } from '../../types';

const dateFormat = (
  language: string,
  date: TDate,
  customFormat = 'PPPP p',
): string => {
  switch (language) {
    case 'en-GB': return format(toDate(date), customFormat, { locale: enGB });
    case 'nl-NL': return format(toDate(date), customFormat, { locale: nl });
    case 'en-US': return format(toDate(date), customFormat, { locale: enUS });
    case 'es-US': return format(toDate(date), customFormat, { locale: es });
    default: return format(toDate(date), customFormat);
  }
};

export default dateFormat;
