/* eslint-disable max-len */
import assignIn from 'lodash/assignIn';
import omit from 'lodash/omit';
import reduce from 'lodash/reduce';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';

import {
  PushNotificationState,
  PushNotificationAddActionType,
  PushNotificationRemoveActionType,
  PushNotificationSetReadAtActionType,
} from './types';

import getUnixTime from '../../utils/getUnixTime';

const add = (state: PushNotificationState, action: PushNotificationAddActionType) => {
  const { payload } = action;

  const pushNotifications = keyBy(payload, 'id');

  return {
    ...state,
    ...pushNotifications,
  };
};

const remove = (state: PushNotificationState, action: PushNotificationRemoveActionType) => {
  const { payload } = action;

  if (!payload) {
    return state;
  }

  return omit(state, payload);
};

const setReadAt = (state: PushNotificationState, action: PushNotificationSetReadAtActionType) => {
  const { payload } = action;

  const pushNotifications: Record<string, any> = reduce(
    payload,
    (result, id) => {
      const pushNotification = get(state, [id]);

      if (isEmpty(pushNotification) || pushNotification.readAt) {
        return result;
      }

      const updatedPushNotification = assignIn({}, pushNotification, { readAt: getUnixTime(), status: 'read' });

      return assignIn(result, { [id]: updatedPushNotification });
    },
    {},
  );

  return {
    ...state,
    ...pushNotifications,
  };
};

const clear = () => ({});

export {
  add,
  remove,
  setReadAt,
  clear,
};
