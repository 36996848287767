import get from 'lodash/get';
import orderBy from 'lodash/orderBy';

import { AppState } from '../reducers';
import { SuggestedQuestionsReturnType } from '../../../types';
import { SuggestedQuestionsState } from './types';

import EMPTY_ARRAY from '../../utils/empty-array';
import createDeepEqualSelector from '../../selectors/createDeepEqualSelector';

const makeGetByLocale = (locale: string) => (
  createDeepEqualSelector(
    (state: AppState) => state.generatedSuggestedQuestions,
    (state: SuggestedQuestionsState): SuggestedQuestionsReturnType => {
      if (!locale || !get(state, [locale])) {
        return EMPTY_ARRAY;
      }

      return orderBy(get(state, [locale]), ['order']);
    },
  )
);

export {
  makeGetByLocale, // eslint-disable-line import/prefer-default-export
};
