// TODO: Check wether we need to show and delete hospital codes on this screen
import React, { useState } from 'react';
import Loader from 'react-loader-spinner';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import isEmpty from 'lodash/isEmpty';
import toUpper from 'lodash/toUpper';
import map from 'lodash/map';
import includes from 'lodash/includes';
import uniq from 'lodash/uniq';
// import noop from 'lodash/noop';

import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRightHeader from '../components/DrawerRightHeader';
// import ListItem from '../components/ListItem';
import Button from '../components/Button';
import Colors from '../theme/Colors';
// import Modal from '../components/Modal';
// import useDeleteHospitalCodeWithRelations from '../hooks/useDeleteHospitalCodeWithRelations';
import { AppState } from '../state/reducers';
import {
  // selectors as settingsSelectors,
  actions as settingsActions,
} from '../state/settings';
// import { CodeIcon, DeleteIconDestructive } from '../theme/Icons';
import { selectors as hospitalsSelectors } from '../state/hospitals';

import '../css/InvitationCodes.css';

type TFormErrors = {
  newCode?: string;
};

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const HospitalGroupAccessCodes = () => {
  const history = useHistory();

  const [errors, setErrors] = useState<TFormErrors>({});
  const [newCode, setNewCode] = useState('');
  const [loading, setLoading] = useState(false);
  // const [modal, setModal] = useState({ visible: false, onClick: noop });

  // const { deleteHospitalCodeWithRelations } = useDeleteHospitalCodeWithRelations();

  // const showModal = (onClick: () => void) => setModal({ visible: true, onClick });

  // const hideModal = () => setModal({ visible: false, onClick: noop });

  const hospitals = useSelector((state: AppState) => hospitalsSelectors.getAll(state));

  // const hospitalCodes = (
  //   useSelector((state: AppState) => settingsSelectors.getAllHospitalGroupAccessCodes(state))
  // );

  const validHospitalCodes = uniq(map(hospitals, 'hospitalGroup'));

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleCodeAdd = () => {
    setLoading(true);
    if (!isEmpty(errors)) {
      setErrors({});
    }

    if (!newCode) {
      setLoading(false);
      return false;
    }

    if (!includes(validHospitalCodes, newCode)) {
      setLoading(false);
      return setErrors({ newCode: t('errors:field-invalid', { field: t('code') }) });
    }

    dispatch(settingsActions.hospitalGroupAccessCodesAdd([newCode]));
    setLoading(false);
    setNewCode('');
    return history.push('/settings/invitation-codes');
  };

  // const handleCodeDelete = (code: string) => (
  //   deleteHospitalCodeWithRelations(code)
  // );

  return (
    <article className="page diary row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('add-hospital-code')} showRightButton />
          <h1 className="heading-secondary accent">{t('code-title-hospital')}</h1>
          <p className="body">{t('code-description-hospital')}</p>
          <section className="diary-item">
            <div className={errors.newCode ? 'add-code mb5' : 'add-code'}>
              <label htmlFor="new-code">
                <span className="body light">{t('code')}</span>
                <input
                  value={newCode}
                  onChange={(event) => setNewCode(toUpper(event.currentTarget.value))}
                  id="new-code"
                  name="new-code"
                />
              </label>
              {!loading ? (
                <Button onClick={handleCodeAdd} labelText={t('add')} />
              ) : (
                <Loader
                  type="TailSpin"
                  color={Colors.primary}
                  height={24}
                  width={24}
                />
              )}
            </div>
            {errors.newCode && (<p className="body error mt0">{t(errors.newCode)}</p>)}
            {/* { !isEmpty(hospitalCodes)
              ? map(hospitalCodes, (code) => (
                <ListItem
                  key={code}
                  primaryText={code}
                  leftIcon={CodeIcon}
                  rightIcon={(
                    <button
                      type="button"
                      onClick={() => showModal(() => {
                        handleCodeDelete(code);
                        hideModal();
                      })}
                    >
                      {DeleteIconDestructive}
                    </button>
                    )}
                  divider
                />
              ))
              : <p className="body light">{t('empty-state-my-codes')}</p>} */}
          </section>

        </section>
        {/* <Modal
          visible={modal.visible}
          hideModal={hideModal}
          actions={[
            {
              title: t('cancel'),
              onClick: hideModal,
            },
            {
              title: t('delete'),
              onClick: modal.onClick,
              destructive: true,
            },
          ]}
        >
          <>
            <h1 className="heading">{t('delete-code')}</h1>
            <p className="body">{t('delete-code-confirm')}</p>
          </>
        </Modal> */}
        <Footer />
      </section>
      <DrawerRight />

    </article>
  );
};

export default HospitalGroupAccessCodes;

// ---------------------------------------------  Old component ------------------------------------------------- //
// import React, { useState } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { useTranslation } from 'react-i18next';
// import nl2br from 'react-nl2br';
// import map from 'lodash/map';
// import isEmpty from 'lodash/isEmpty';
// import uniq from 'lodash/uniq';
// import noop from 'lodash/noop';
// import includes from 'lodash/includes';
// import toUpper from 'lodash/toUpper';

// import { AppState } from '../state/reducers';

// import '../css/HospitalGroupAccessCodes.css';
// import Header from '../components/Header';
// import PageHeader from '../components/PageHeader';
// import Footer from '../components/Footer';
// import ListItem from '../components/ListItem';
// import Modal from '../components/Modal';
// import { CodeIcon, DeleteIconDestructive } from '../theme/Icons';
// import { selectors as settingsSelectors, actions as settingsActions } from '../state/settings';
// import { selectors as hospitalsSelectors } from '../state/hospitals';

// type TFormErrors = {
//   newCode?: string;
// };

// const HospitalGroupAccessCodes = () => {
//   const [modal, setModal] = useState({ visible: false, onClick: noop });
//   const [errors, setErrors] = useState<TFormErrors>({});
//   const [newCode, setNewCode] = useState('');

//   const hospitals = useSelector((state: AppState) => hospitalsSelectors.getAll(state));

//   const validCodes = uniq(map(hospitals, 'hospitalGroup'));

//   const hospitalGroupAccessCodes = (
//     useSelector((state: AppState) => settingsSelectors.getAllHospitalGroupAccessCodes(state))
//   );

//   const { t } = useTranslation();

//   const dispatch = useDispatch();

//   const showModal = (onClick: () => void) => setModal({ visible: true, onClick });

//   const hideModal = () => setModal({ visible: false, onClick: noop });

//   const handleCodeAdd = () => {
//     if (!isEmpty(errors)) {
//       setErrors({});
//     }

//     if (!newCode) {
//       return false;
//     }

//     if (!includes(validCodes, newCode)) {
//       return setErrors({ newCode: t('errors:field-invalid', { field: t('code') }) });
//     }

//     dispatch(settingsActions.hospitalGroupAccessCodesAdd([newCode]));

//     return setNewCode('');
//   };

//   const handleCodeDelete = (code: string) => (
//     dispatch(settingsActions.hospitalGroupAccessCodesRemove([code]))
//   );

//   return (
//     <article className="app hospital-group-access-codes">
//       <Header auth documentTitle={t('hospital-access-codes')} />
//       <PageHeader
//         title={t('pageHeaders.hospitalGroupAccessCodes.title')}
//         subtitle={nl2br(t('pageHeaders.hospitalGroupAccessCodes.subTitle')) as unknown as string}
//       />
//       <section className="content">
//         <div>
//           <input
//             value={newCode}
//             onChange={(event) => setNewCode(toUpper(event.currentTarget.value))}
//           />
//           <button type="button" className="button accent" onClick={handleCodeAdd}>
//             {t('add')}
//           </button>
//         </div>
//         {
//           errors.newCode && <p className="body error">{t(errors.newCode)}</p>
//         }
//         {
//           !isEmpty(hospitalGroupAccessCodes)
//             ? map(hospitalGroupAccessCodes, (code) => (
//               <ListItem
//                 key={code}
//                 primaryText={code}
//                 leftIcon={CodeIcon}
//                 rightIcon={(
//                   <button
//                     type="button"
//                     onClick={() => showModal(() => {
//                       handleCodeDelete(code);
//                       hideModal();
//                     })}
//                   >
//                     {DeleteIconDestructive}
//                   </button>
//                 )}
//                 divider
//               />
//             ))
//             : <p className="body light">{t('empty-state-my-codes')}</p>
//         }
//       </section>
//       <Modal
//         visible={modal.visible}
//         hideModal={hideModal}
//         actions={[
//           {
//             title: t('cancel'),
//             onClick: hideModal,
//           },
//           {
//             title: t('delete'),
//             onClick: modal.onClick,
//             destructive: true,
//           },
//         ]}
//       >
//         <>
//           <h1 className="heading">{t('delete-code')}</h1>
//           <p className="body">{t('delete-code-confirm')}</p>
//         </>
//       </Modal>
//       <Footer />
//     </article>
//   );
// };

// export default HospitalGroupAccessCodes;
