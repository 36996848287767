/* eslint react/require-default-props: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Path } from 'react-native-svg';
import ResizeableSVG from '../ResizeableSVG';

import Colors from '../../theme/Colors';
import Constants from '../../theme/Constants';

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

type Props = {
  color?: string;
  size?: number;
};

const GCSF = (props: Props) => {
  const {
    color = Colors.primary,
    size = Constants.defaultIconSize,
  } = props;

  return (
    <ResizeableSVG size={size}>
      <Path d="m9.9642 13.8918c-.432.194-.913.209-1.354.042-.444-.167-.795-.496-.99-.926s-.21-.911-.042-1.354c.167-.444.496-.795.926-.99.232-.105.48-.157.727-.157.212 0 .424.038.628.115.443.167.794.496.989.926.402.89.006 1.942-.884 2.344m-3.13 6.889c.195.43.21.912.043 1.355-.167.444-.496.795-.926.989-.43.195-.911.211-1.355.043-.444-.167-.795-.496-.989-.926-.403-.89-.006-1.942.883-2.344.237-.107.484-.157.728-.157.675 0 1.321.387 1.616 1.04m16.522-12.947c-.43.194-.911.21-1.355.042-.443-.167-.794-.495-.989-.925-.194-.431-.21-.912-.043-1.355.167-.444.496-.795.927-.99.232-.105.479-.157.727-.157.211 0 .424.038.627.115.443.167.795.496.989.926.403.89.007 1.942-.883 2.344m-3.13 6.889c.195.43.21.912.043 1.355s-.496.795-.927.989c-.432.195-.913.21-1.354.043-.443-.167-.795-.496-.989-.926-.403-.891-.007-1.942.883-2.344.236-.107.484-.158.728-.158.674 0 1.32.388 1.616 1.041m5.333-9.83c-.354-.782-.992-1.38-1.798-1.684-.805-.303-1.68-.275-2.462.078-.783.354-1.381.992-1.685 1.798-.303.806-.276 1.68.078 2.462.21.464.528.87.928 1.188l-1.44 3.551c-.638-.115-1.302-.034-1.895.234-.592.268-1.091.713-1.426 1.268l-3.422-1.195c.056-.561-.035-1.124-.269-1.641-.354-.783-.993-1.381-1.799-1.685-.805-.303-1.68-.276-2.462.078-.783.354-1.381.992-1.685 1.798-.303.806-.276 1.68.078 2.462.235.519.598.96 1.059 1.288l-1.394 3.49c-.694-.167-1.421-.099-2.071.195-.783.354-1.381.993-1.685 1.798-.304.806-.276 1.68.078 2.462.354.783.992 1.381 1.798 1.685.371.14.755.209 1.14.209.45 0 .9-.096 1.322-.287 1.618-.732 2.339-2.643 1.607-4.261-.203-.447-.507-.842-.887-1.154l1.429-3.58c.631.109 1.284.025 1.865-.238.592-.268 1.076-.696 1.412-1.246l3.433 1.198c-.05.553.042 1.107.273 1.616.354.783.993 1.381 1.798 1.685.806.303 1.68.276 2.462-.078.783-.354 1.381-.993 1.685-1.798.303-.806.276-1.68-.078-2.463-.231-.51-.586-.945-1.034-1.271l1.418-3.495c.68.152 1.388.081 2.023-.206 1.617-.732 2.338-2.643 1.606-4.261" fill={color} fillRule="evenodd" />
    </ResizeableSVG>
  );
};

GCSF.propTypes = propTypes;

export default GCSF;
