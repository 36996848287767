import * as actionTypes from './actionTypes';
import { TemplateActionTypes } from './types';

const clear = (): TemplateActionTypes => ({
  type: actionTypes.CLEAR,
});

export {
  clear, // eslint-disable-line import/prefer-default-export
};
