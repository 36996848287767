import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { UsefulLinksState, UsefulLinksReplaceActionType } from './types';

const replace = (state: UsefulLinksState, action: UsefulLinksReplaceActionType) => {
  const { payload } = action;

  if (isEmpty(payload) || isEqual(state, payload)) {
    return state;
  }

  return payload;
};

const clear = () => ({});

export {
  replace,
  clear,
};
