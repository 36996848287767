import * as actionTypes from './actionTypes';
import { UsefulLinksActionTypes, UsefulLinksReplacePayload } from './types';

const replace = (payload: UsefulLinksReplacePayload): UsefulLinksActionTypes => ({
  type: actionTypes.REPLACE,
  payload,
});

const clear = (): UsefulLinksActionTypes => ({
  type: actionTypes.CLEAR,
});

export {
  replace,
  clear,
};
