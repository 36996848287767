/* eslint react/jsx-props-no-spreading: 0 */
import React, { useEffect, useRef, useMemo } from 'react';
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import round from 'lodash/round';
import map from 'lodash/map';
import { AppState } from '../../state/reducers';
import { TrendIndicatorType, TGraphData, TrendSeries } from '../../../types';
import { scaleRange10ToRange5, setUpTrendGraph } from './graph';
import { handleCombinedTooltipMouseMove, Point } from './tooltips';
import { selectors as trendsSelectors } from '../../state/trends';
import getLocalizedText from '../../utils/getLocalizedText';

import SvgTrendPlot from './SvgTrendPlot';

import './styles.css';

type Props = {
  startDate: Date;
  endDate: Date;
  width: number;
  height: number;
  xPadding: number;
  yPadding: number;
  trendIndicatorList: TrendIndicatorType[];
};

const getTooltipText = (
  datum: TGraphData,
  trendSeries: TrendSeries,
  language: string,
): string => {
  if (datum.originalValue) {
    if (trendSeries.labels) {
      const label = trendSeries.labels[datum.originalValue];

      if (label) {
        return getLocalizedText(label, language);
      }
    }

    const labelUnit = getLocalizedText(trendSeries, language, 'labelUnit');

    if (labelUnit) {
      return `${round(datum.originalValue)} ${labelUnit}`;
    }

    return `${round(datum.originalValue)}`;
  }

  if (trendSeries.labels) {
    let label = trendSeries.labels[datum.value];

    if (!label) {
      label = trendSeries.labels[scaleRange10ToRange5(datum.value)];
    }

    if (label) {
      return getLocalizedText(label, language);
    }
  }
  return `${round(datum.value)}`;
};

const CombinedTrendGraph = (props: Props) => {
  const {
    startDate,
    endDate,
    width,
    height,
    trendIndicatorList,
    xPadding,
    yPadding,
  } = props;

  const { i18n } = useTranslation();

  const svgRef = useRef(null);

  const memoizedGetLatestDataPerDayByTrendIndicators = useMemo(
    trendsSelectors.makeGetLatestDataPerDayByTrendIndicators,
    [],
  );

  const { trendSeries } = useSelector(
    (state: AppState) => memoizedGetLatestDataPerDayByTrendIndicators(
      state,
      trendIndicatorList,
      startDate,
      endDate,
    ),
  );

  const {
    line,
    scaleX,
    scaleY,
    xAxis,
    bisectDate,
  } = setUpTrendGraph({
    startDate,
    endDate,
    height,
    maximumValue: 10,
    minimumValue: 0,
    width,
    xPadding,
    yPadding,
  });

  const getSvg = () => d3.select(svgRef.current);

  // This has to be a function for some reason...
  function onMousemove(this: any): void {
    handleCombinedTooltipMouseMove(
      this,
      scaleX,
      scaleY,
      bisectDate,
      trendSeries,
      getTooltipText,
      getSvg(),
      75,
      i18n.language,
    );
  }

  const onMouseOver = (): void => {
    const svg = getSvg();
    svg.select('.trend-graph-focus').style('display', null);
    svg.select('.trend-graph-focus-circle').style('display', null);
  };

  const onMouseOut = (): void => {
    const svg = getSvg();
    svg.select('.trend-graph-focus').style('display', 'none');

    svg.select('.trend-graph-focus-circle').style('display', 'none');
  };

  const points: Point[] = [];

  useEffect(() => {
    const svg = getSvg();

    (svg.select('.axis-line') as d3.Selection<
    SVGGElement,
    unknown,
    null,
    undefined
    >).call(xAxis);

    const dataList = trendSeries.map((trend) => ({
      title: getLocalizedText(trend, i18n.language, 'title'),
      data: trend.data,
    }));

    const lines = svg.selectAll('.trend-series');
    lines.data(dataList);
    lines.attr('d', (d: any) => line(d.data));

    svg.select('.trend-graph-tooltip-overlay').on('mousemove', onMousemove);

    return () => {
      svg.select('.trend-graph-tooltip-overlay').on('mousemove', null);
    };
  }, [i18n.language, line, onMousemove, points, trendSeries, xAxis]);

  const seriesPaths = trendSeries.map((trend) => (
    <path
      key={trend.id}
      className="trend-series"
      style={{ stroke: trend.color }}
    />
  ));

  const circles = map(points, (point, index) => <circle key={index} className={`trend-graph-focus-circle index-${index}`} style={{ fill: point.color || 'red' }} r={5} />);

  return (
    <>
      <svg ref={svgRef} width={width} height={height}>
        <SvgTrendPlot
          width={width}
          height={height}
          xPadding={xPadding}
          yPadding={yPadding}
          showGuideText
          showTooltipTitle
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
        >
          <>
            {seriesPaths}
            {circles}
          </>
        </SvgTrendPlot>
      </svg>
    </>
  );
};

export default CombinedTrendGraph;
