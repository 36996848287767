/* eslint react/require-default-props: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Path } from 'react-native-svg';
import ResizeableSVG from '../ResizeableSVG';

import Colors from '../../theme/Colors';
import Constants from '../../theme/Constants';

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

type Props = {
  color?: string;
  size?: number;
};

const Note = (props: Props) => {
  const {
    color = Colors.primary,
    size = Constants.defaultIconSize,
  } = props;

  return (
    <ResizeableSVG size={size}>
      <Path d="m21.4346405 3c1.4019608 0 2.5163399 1.11437908 2.5163399 2.51633987v16.96732023c0 1.4019608-1.1143791 2.5163399-2.5163399 2.5163399h-15.63725488c-1.00653595 0-1.79738562-.7908497-1.79738562-1.7973856v-18.40522878c0-1.00653595.79084967-1.79738562 1.79738562-1.79738562zm-2.6960784 1.4379085h-12.94117648c-.21568627 0-.35947712.14379085-.35947712.35947712v18.40522878c0 .2156863.17973856.3594771.35947712.3594771h12.94117648zm2.7320261 0h-1.2581699v19.124183h1.2581699c.6111111 0 1.0784314-.4673203 1.0784314-1.0784314v-16.96732023c0-.61111111-.503268-1.07843137-1.0784314-1.07843137zm-4.9607843 7.5849673v1.4379085h-8.62745096v-1.4379085zm0-3.59477122v1.43790849h-8.62745096v-1.43790849z" fill={color} fillRule="evenodd" />
    </ResizeableSVG>
  );
};

Note.propTypes = propTypes;

export default Note;
