const prefix = 'DAILY_SUGGESTIONS';

const ADD = `${prefix}/ADD`;
const UPDATE = `${prefix}/UPDATE`;
const CLEAR_DRUG_MODULE = `${prefix}/CLEAR_DRUG_MODULE`;
const CHECK = `${prefix}/CHECK`;
const TRIGGER = `${prefix}/TRIGGER`;
const INTERVAL_TRIGGER = `${prefix}/INTERVAL_TRIGGER`;
const CLEAR = `${prefix}/CLEAR`;

export {
  ADD,
  UPDATE,
  CHECK,
  TRIGGER,
  CLEAR,
  INTERVAL_TRIGGER,
  CLEAR_DRUG_MODULE,
};
