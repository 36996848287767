/* eslint react/require-default-props: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Path } from 'react-native-svg';
import ResizeableSVG from '../ResizeableSVG';

import Colors from '../../theme/Colors';
import Constants from '../../theme/Constants';

type Props = {
  color?: string;
  size?: number;
};

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

const Minus = (props: Props) => {
  const {
    color = Colors.primary,
    size = Constants.defaultIconSize,
  } = props;

  return (
    <ResizeableSVG size={size}>
      <Path d="m20 13h-12v2h12z" fill={color} fillRule="evenodd" />
    </ResizeableSVG>
  );
};

Minus.propTypes = propTypes;

export default Minus;
