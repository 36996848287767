import { takeEvery, put } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';

import {
  actionTypes as audioRecordingsActionTypes,
  AudioRecordingsCleanupActionType,
} from '../state/audioRecordings';
import { actions as questionnairesActions } from '../state/questionnaires';

function* cleanup(action: AudioRecordingsCleanupActionType) {
  const {
    payload: audioRecordings,
  } = action;

  for (const audioRecording of audioRecordings) { // eslint-disable-line no-restricted-syntax
    if (isEmpty(audioRecording)) {
      return false;
    }

    const {
      id,
      questionnaireId,
      questionnaireQuestionId,
    } = audioRecording;

    if (questionnaireId && questionnaireQuestionId) {
      yield put(questionnairesActions.removeQuestionAudioRecordings({
        questionnaireId,
        questionId: questionnaireQuestionId,
        audioRecordingIds: [id],
      }));
    }
  }

  return true;
}

export default function* watchAudioRecordings() {
  yield takeEvery(audioRecordingsActionTypes.CLEANUP, cleanup);
}
