import React, { useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import { useTranslation } from 'react-i18next';

import { AppState } from '../state/reducers';

import { selectors as audioRecordingsSelectors } from '../state/audioRecordings';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRightHeader from '../components/DrawerRightHeader';
import AudioRecordingListItem from '../components/AudioRecordingListItem';

type Params = {
  view: string;
  date?: string;
};

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const AudioRecordings = () => {
  const { t } = useTranslation();

  const { view, date } = useParams<Params>();

  const history = useHistory();

  const memoizedGetSelector = useMemo(
    audioRecordingsSelectors.makeGetAllByViewAndDate,
    [],
  );

  const audioRecordings = useSelector(
    (state: AppState) => memoizedGetSelector(state, view, date, true),
  );

  return (
    <article className="page diary row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('recordings')} showRightButton />
          <button
            type="button"
            className="navigation accent back"
            onClick={history.goBack}
          >
            &lt; {t('back')}
          </button>
          <section className={isEmpty(audioRecordings) ? 'diary-item' : 'list'}>
            {
              !isEmpty(audioRecordings)
                ? map(audioRecordings, (audioRecording, index) => (
                  <AudioRecordingListItem
                    audioRecording={audioRecording}
                    key={audioRecording.id}
                    divider={(index + 1) !== size(audioRecordings)}
                  />
                ))
                : <span className="body light">{t('audioRecordings:empty-state-no-audio-recordings')}</span>
            }
          </section>
        </section>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default AudioRecordings;
