import React from 'react';
import { useTranslation } from 'react-i18next';

import { TAudioRecording } from '../../types';

import ListItem from './ListItem';
import dateFormat from '../utils/dateFormat';
import { ReactComponent as IconRecording } from '../assets/icons/Recording.svg';
import { ReactComponent as IconChevronRight } from '../assets/icons/ChevronRight.svg';
import Colors from '../theme/Colors';

type Props = {
  audioRecording: TAudioRecording;
  divider?: boolean;
  dividerOffset?: number;
};

const AppointmentListItem = (props: Props) => {
  const { i18n } = useTranslation();

  const { audioRecording, divider = true, dividerOffset = 0 } = props;

  return (
    <ListItem
      key={audioRecording.id}
      primaryText={audioRecording.title}
      secondaryText={dateFormat(i18n.language, audioRecording.createdAt)}
      leftIcon={<IconRecording fill={Colors.primary} className="icon" />}
      rightIcon={<IconChevronRight fill={Colors.primary} className="icon small" />}
      divider={divider}
      dividerOffset={dividerOffset}
      to={`/recording/${audioRecording.id}`}
    />
  );
};

export default AppointmentListItem;
