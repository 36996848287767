import React from 'react';
import { useTranslation } from 'react-i18next';

import { TPhoto } from '../../types';

import ListItem from './ListItem';
import dateFormat from '../utils/dateFormat';
import { ReactComponent as IconPhoto } from '../assets/icons/Photo.svg';
import { ReactComponent as IconChevronRight } from '../assets/icons/ChevronRight.svg';
import Colors from '../theme/Colors';

type Props = {
  photo: TPhoto;
  divider?: boolean;
  dividerOffset?: number;
};

const PhotoListItem = (props: Props) => {
  const { i18n } = useTranslation();

  const { photo, divider = true, dividerOffset = 0 } = props;

  return (
    <ListItem
      key={photo.id}
      primaryText={photo.title}
      secondaryText={dateFormat(i18n.language, photo.createdAt)}
      leftIcon={<IconPhoto fill={Colors.primary} className="icon" />}
      rightIcon={<IconChevronRight fill={Colors.primary} className="icon small" />}
      divider={divider}
      dividerOffset={dividerOffset}
      to={`/photo/${photo.id}`}
    />
  );
};

export default PhotoListItem;
