import some from 'lodash/some';
import isArray from 'lodash/isArray';
import first from 'lodash/first';
import every from 'lodash/every';
import includes from 'lodash/includes';

/**
 * Determine if an array or array of arrays contains one or more items from another array
 * {@link includesAny}
 * @exports includesAny
 * @param {array} a - array to search
 * @param {array} b - array providing items to check for in a
 * @returns {boolean} true|false if a contains at least one item from b
 */
const includesAny = (a: any, b: any) => {
  if (isArray(first(a))) {
    return some(a, (c: any) => every(c, (d: any) => includes(b, d)));
  }

  return some(a, (c) => includes(b, c));
};

export default includesAny;
