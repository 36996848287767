import omit from 'lodash/omit';
import keyBy from 'lodash/keyBy';

import {
  ReportsState,
  ReportsAddActionType,
  ReportsRemoveActionType,
  ReportsUpdateActionType,
} from './types';

const add = (state: ReportsState, action: ReportsAddActionType) => {
  const { payload } = action;

  const reports = keyBy(payload, 'id');

  return {
    ...state,
    ...reports,
  };
};

const remove = (state: ReportsState, action: ReportsRemoveActionType) => {
  const { payload } = action;

  return omit(state, payload);
};

const update = (state: ReportsState, action: ReportsUpdateActionType) => {
  const { payload } = action;

  const reports = keyBy(payload, 'id');

  return {
    ...state,
    ...reports,
  };
};

const clear = () => ({});

export {
  add,
  remove,
  update,
  clear,
};
