import isSameDay from 'date-fns/isSameDay';
import isSameWeek from 'date-fns/isSameWeek';
import isSameMonth from 'date-fns/isSameMonth';
import get from 'lodash/get';
import toArray from 'lodash/toArray';
import size from 'lodash/size';
import filter from 'lodash/filter';
import slice from 'lodash/slice';
import orderBy from 'lodash/orderBy';
import includes from 'lodash/includes';

import { AppState } from '../reducers';

import { NotesState } from './types';
import {
  TSortOrder,
  TDate,
  NoteReturnType,
  NotesReturnType,
} from '../../../types';

import EMPTY_OBJECT from '../../utils/empty-object';
import EMPTY_ARRAY from '../../utils/empty-array';
import toDate from '../../utils/toDate';
import createDeepEqualSelector from '../../selectors/createDeepEqualSelector';

const sort = (
  collection: NotesReturnType | NotesState,
  key = 'createdAt',
  order = 'desc' as TSortOrder,
): NotesReturnType => (
  orderBy(collection, [key], [order])
);

const getByIdSelector = (state: NotesState, id: string): NoteReturnType => (
  get(state, [id]) || EMPTY_OBJECT
);

const makeGetById = () => (
  createDeepEqualSelector(
    (state: AppState) => state.notes,
    (state: any, id: string) => id,
    getByIdSelector,
  )
);

const getByIdsSelector = (
  state: NotesState,
  ids: string[] | typeof EMPTY_ARRAY,
): NotesReturnType => (
  sort(filter(state, (note) => includes(ids, note.id)))
);

const makeGetByIds = () => (
  createDeepEqualSelector(
    (state: AppState) => state.notes,
    (state: any, ids: string[] | typeof EMPTY_ARRAY) => ids,
    getByIdsSelector,
  )
);

const getByDateSelector = (state: NotesState, date?: TDate) => (
  sort(filter(state, (note) => isSameDay(toDate(note.createdAt), toDate(date))))
);

const makeGetByDate = () => (
  createDeepEqualSelector(
    (state: AppState) => state.notes,
    (state: any, date: TDate) => date,
    getByDateSelector,
  )
);

const getByWeekSelector = (state: NotesState, date?: TDate) => (
  sort(filter(state, (note) => isSameWeek(toDate(note.createdAt), toDate(date))))
);

const makeGetByWeek = () => (
  createDeepEqualSelector(
    (state: AppState) => state.notes,
    (state: any, date: TDate) => date,
    getByWeekSelector,
  )
);

const getByMonthSelector = (state: NotesState, date?: TDate) => (
  sort(filter(state, (note) => isSameMonth(toDate(note.createdAt), toDate(date))))
);

const makeGetByMonth = () => (
  createDeepEqualSelector(
    (state: AppState) => state.notes,
    (state: any, date: TDate) => date,
    getByMonthSelector,
  )
);

const getSelector = (state: NotesState, limit = 2): NotesReturnType => (
  slice(sort(state), 0, limit)
);

const makeGet = () => (
  createDeepEqualSelector(
    (state: AppState) => state.notes,
    (state: any, limit: number) => limit,
    getSelector,
  )
);

const getLastUpdatedSelector = (state: NotesState, limit = 2): NotesReturnType => (
  // updatedAt has priority over createdAt when sorting
  slice(orderBy(state, [({ updatedAt, createdAt }) => updatedAt || createdAt], ['desc']), 0, limit)
);

const makeGetLastUpdated = () => (
  createDeepEqualSelector(
    (state: AppState) => state.notes,
    (state: any, limit: number) => limit,
    getLastUpdatedSelector,
  )
);

const getAllSelector = (state: NotesState) => sort(toArray(state));

const getAll = createDeepEqualSelector(
  (state: AppState) => state.notes,
  getAllSelector,
);

const getAllByViewAndDateSelector = (state: NotesState, view = 'all', date: TDate): NotesReturnType => {
  switch (view) {
    case 'all': return getAllSelector(state);
    case 'day': return getByDateSelector(state, date);
    case 'week': return getByWeekSelector(state, date);
    case 'month': return getByMonthSelector(state, date);
    default:
  }

  return getAllSelector(state);
};

const makeGetAllByViewAndDate = () => (
  createDeepEqualSelector(
    (state: AppState) => state.notes,
    (state: any, view: string) => view,
    (state: any, view: string, date: TDate) => date,
    getAllByViewAndDateSelector,
  )
);

const countSelector = (state: NotesState) => size(state);

const count = createDeepEqualSelector(
  (state: AppState) => state.notes,
  countSelector,
);

export {
  makeGetById,
  makeGetByIds,
  makeGetByDate,
  makeGetByWeek,
  makeGetByMonth,
  makeGet,
  getAll,
  makeGetLastUpdated,
  makeGetAllByViewAndDate,
  count,
};
