const prefix = 'CONTACTS';

const ADD = `${prefix}/ADD`;
const ADD_PHOTOS = `${prefix}/ADD_PHOTOS`;
const REMOVE = `${prefix}/REMOVE`;
const REMOVE_PHOTOS = `${prefix}/REMOVE_PHOTOS`;
const UPDATE = `${prefix}/UPDATE`;
const CLEAR = `${prefix}/CLEAR`;

export {
  ADD,
  ADD_PHOTOS,
  REMOVE,
  REMOVE_PHOTOS,
  UPDATE,
  CLEAR,
};
