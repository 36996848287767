const UPDATE_TREATMENT_TYPE_ID = 'UPDATE_TREATMENT_TYPE_ID';
const UPDATE_TREATMENT_TYPE_OPTION_ID = 'UPDATE_TREATMENT_TYPE_OPTION_ID';
const UPDATE_TREATMENT_DOSAGE = 'UPDATE_TREATMENT_DOSAGE';
const UPDATE_TREATMENT_TYPE_TEXT = 'UPDATE_TREATMENT_TYPE_TEXT';
const UPDATE_START_AT = 'UPDATE_START_AT';
const UPDATE_END_AT = 'UPDATE_END_AT';
const UPDATE_DURATION = 'UPDATE_DURATION';
const UPDATE_COMPLETED = 'UPDATE_COMPLETED';

export {
  UPDATE_TREATMENT_TYPE_ID,
  UPDATE_TREATMENT_TYPE_OPTION_ID,
  UPDATE_TREATMENT_DOSAGE,
  UPDATE_TREATMENT_TYPE_TEXT,
  UPDATE_START_AT,
  UPDATE_END_AT,
  UPDATE_DURATION,
  UPDATE_COMPLETED,
};
