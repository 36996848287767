import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import get from 'lodash/get';
import Tooltip from 'react-tooltip';

import { ProfileQuestionType } from '../../types';

import ListItem from './ListItem';
import Modal from './Modal';
import { ReactComponent as IconInfo } from '../assets/icons/Info.svg';
import { ReactComponent as IconSearch } from '../assets/icons/Search.svg';
import { ReactComponent as IconRadio } from '../assets/icons/Radio.svg';
import { ReactComponent as IconRadioInactive } from '../assets/icons/RadioInactive.svg';
import Colors from '../theme/Colors';
import images from '../images';
import getLocalizedText from '../utils/getLocalizedText';

type Props = {
  question: ProfileQuestionType,
  value: string;
  onChangeValue: (value: string) => void;
};

const ProfileQuestionSingleWithImagesInput = (props: Props) => {
  const {
    question,
    value,
    onChangeValue,
  } = props;

  const { i18n, t } = useTranslation();

  const [modal, setModal] = useState({
    visible: false,
    title: '',
    description: '',
    imageSource: undefined,
  });

  const localizedOptions = map(question.answers, (answer) => ({
    value: answer.id,
    labelText: getLocalizedText(answer, i18n.language, 'title'),
    description: getLocalizedText(answer, i18n.language, 'description'),
  }));

  const hideModal = () => setModal({
    visible: false,
    title: '',
    description: '',
    imageSource: undefined,
  });

  return (
    <section className="single-images-input">
      <section className="images">
        {map(question.images, (image) => {
          const title = getLocalizedText(image, i18n.language, 'title');
          const description = getLocalizedText(image, i18n.language, 'description');
          const imageSource = get(images, ['profileQuestions', question.id, i18n.language, image.id]);

          return (
            <button
              key={image.id}
              type="button"
              onClick={() => setModal({
                visible: true,
                title,
                description,
                imageSource: imageSource.default,
              })}
            >
              <h1 className="heading">{title}</h1>
              <img src={imageSource.default} alt={title} />
              <IconSearch fill={Colors.primary} className="icon" />
            </button>
          );
        })}
      </section>
      {map(localizedOptions, (option) => {
        const active = option.value === value;

        return (
          <ListItem
            key={option.value}
            onClick={() => onChangeValue(option.value)}
            leftIcon={
              active ? <IconRadio fill={Colors.secondary} className="icon" /> : <IconRadioInactive fill={Colors.secondary} className="icon" />
            }
            primaryText={option.labelText}
            divider
            active={active}
            primaryTextClassName="body"
            rightIcon={
              option.description
                ? (
                  <>
                    <div data-tip={option.description}>
                      <IconInfo fill={Colors.primary} className="icon" />
                    </div>
                    <Tooltip
                      className="tooltip body contrast"
                      backgroundColor={Colors.black}
                    />
                  </>
                )
                : null
            }
          />
        );
      })}
      <Modal
        visible={modal.visible}
        hideModal={hideModal}
        actions={[
          {
            title: t('close'),
            onClick: hideModal,
          },
        ]}
      >
        <h1 className="heading">{modal.title}</h1>
        {modal.description ? <p className="body">{modal.description}</p> : null}
        {modal.imageSource ? <img className="image" src={modal.imageSource} alt={modal.title} /> : null}
      </Modal>
    </section>
  );
};

export default ProfileQuestionSingleWithImagesInput;
