import axios, { AxiosRequestConfig } from 'axios';

import localforage from 'localforage';
import includes from 'lodash/includes';
import set from 'lodash/set';
import get from 'lodash/get';
import localConfig from '../config';

const authApi = axios.create({
  baseURL: localConfig.authApi.prod.url,
  timeout: localConfig.authApi.timeout,
  validateStatus: (status) => {
    if (status >= 400 && status <= 403) {
      return true;
    }

    return status >= 200 && status < 300;
  },
});

const requestHandlerAsync = async (config: AxiosRequestConfig) => {
  try {
    const environment = await localforage.getItem('@Settings:environment') as any;

    if (
      environment
      && environment !== 'prod'
      && includes(localConfig.environments, environment)
    ) {
      set(config, 'baseURL', get(localConfig, ['authApi', environment, 'url']));
    }
  } catch (error) {
    if (__DEV__) {
      console.error('[authApi.requestHandlerAsync]', 'catch', error); // eslint-disable-line no-console
      console.error('[authApi.requestHandlerAsync]', 'catch', error.response); // eslint-disable-line no-console
    }
  }

  return config;
};

authApi.interceptors.request.use(requestHandlerAsync);

export default authApi;
