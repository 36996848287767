import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import get from 'lodash/get';
import flatten from 'lodash/flatten';

const getLocalizedText = (
  object: object | undefined,
  language: string,
  path?: string | string[] | number | number[] | undefined,
) => {
  if (isEmpty(object) || !isPlainObject(object)) {
    return '';
  }

  if (!path) {
    return get(object, [language]);
  }

  return get(object, flatten([path, language]));
};

export default getLocalizedText;
