import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import map from 'lodash/map';

import Modal from './Modal';
import { actions as apiActions } from '../state/api';
import { actions as settingsActions } from '../state/settings';

import '../css/InvitationCodes.css';
import useGetLinkedDrugModulesByCode from '../hooks/useGetLinkedDrugModulesByCode';
import { getCurrentCodeType } from '../utils/inviteCodeHelpers';

type TInvitationCodeEditModalProps = {
  editedCode: string,
  setEditedCode: React.Dispatch<React.SetStateAction<string>>,
  setEditCodeError: React.Dispatch<React.SetStateAction<boolean>>,
  country: string,
};

const InvitationCodeEditModal = (
  {
    editedCode, setEditedCode, setEditCodeError, country,
  }: TInvitationCodeEditModalProps,
) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [newCode, setNewCode] = useState('');

  const linkedDrug = useGetLinkedDrugModulesByCode(editedCode);

  const handleClose = () => {
    setNewCode('');
    setEditedCode('');
  };

  const handleSave = () => {
    if (!newCode) {
      return false;
    }
    if (getCurrentCodeType(newCode, country) !== getCurrentCodeType(editedCode, country)) {
      setEditCodeError(true);
      setNewCode('');
      handleClose();
      return false;
    }

    dispatch(apiActions.validateInvite({
      code: newCode,
      country,
      onSuccess: () => {
        dispatch(settingsActions.invitationCodesEdit([editedCode, newCode.toUpperCase()]));
        map(linkedDrug, (item, i) => {
          dispatch(settingsActions.contentModulesUpdate(
            {
              moduleName: linkedDrug[i],
              invitationCode: newCode.toUpperCase(),
            },
          ));
        });
        setNewCode('');
        handleClose();
      },
      onError: () => {
        setNewCode('');
        setEditCodeError(true);
      },
    }));

    return handleClose();
  };

  return (
    <Modal
      visible={!!editedCode}
      hideModal={handleClose}
      actions={[
        {
          title: t('cancel'),
          onClick: handleClose,
        },
        {
          title: t('save'),
          onClick: handleSave,
          disabled: !newCode,
          primary: true,
        },
      ]}
      className="suggested-questions"
    >
      <h1 className="heading">{t('code-edit-title')}</h1>
      <label htmlFor="question">
        <span className="body light">{t('code-edit-description')}</span>
        <input
          value={newCode}
          onChange={(event) => setNewCode(event.currentTarget.value)}
          name="question"
          id="question"
          placeholder={t('code')}
          className="edit-code-edit-modal"
        />
      </label>
    </Modal>
  );
};

export default InvitationCodeEditModal;
