import produce, { Draft } from 'immer';
import omit from 'lodash/omit';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import union from 'lodash/union';
import without from 'lodash/without';
import set from 'lodash/set';
import keyBy from 'lodash/keyBy';

import {
  AppointmentsState,
  AppointmentsAddActionType,
  AppointmentsAddPhotosActionType,
  AppointmentsAddAudioRecordingsActionType,
  AppointmentsAddNotesActionType,
  AppointmentsRemoveActionType,
  AppointmentsRemovePhotosActionType,
  AppointmentsRemoveAudioRecordingsActionType,
} from './types';

const add = (state: AppointmentsState, action: AppointmentsAddActionType) => {
  const { payload } = action;

  const appointments = keyBy(payload, 'id');

  return {
    ...state,
    ...appointments,
  };
};

const addPhotos = produce((
  draft: Draft<AppointmentsState>,
  action: AppointmentsAddPhotosActionType,
) => {
  const { payload } = action;
  const { appointmentId, photoIds } = payload;

  const appointment = get(draft, [appointmentId]);

  if (isEmpty(appointment)) {
    return draft;
  }

  const draftPhotoIds = get(draft, [appointmentId, 'photoIds']);
  const newPhotoIds = union(draftPhotoIds, photoIds);

  set(draft, [appointmentId, 'photoIds'], newPhotoIds);

  return draft;
});

const addAudioRecordings = produce((
  draft: Draft<AppointmentsState>,
  action: AppointmentsAddAudioRecordingsActionType,
) => {
  const { payload } = action;
  const { appointmentId, audioRecordingIds } = payload;

  const appointment = get(draft, [appointmentId]);

  if (isEmpty(appointment)) {
    return draft;
  }

  const draftAudioRecordingIds = get(draft, [appointmentId, 'audioRecordingIds']);
  const newAudioRecordingIds = union(draftAudioRecordingIds, audioRecordingIds);

  set(draft, [appointmentId, 'audioRecordingIds'], newAudioRecordingIds);

  return draft;
});

const addNotes = produce((
  draft: Draft<AppointmentsState>,
  action: AppointmentsAddNotesActionType,
) => {
  const { payload } = action;
  const { appointmentId, noteIds } = payload;

  const appointment = get(draft, [appointmentId]);

  if (isEmpty(appointment)) {
    return draft;
  }

  const draftNoteIds = get(draft, [appointmentId, 'noteIds']);
  const newNoteIds = union(draftNoteIds, noteIds);

  set(draft, [appointmentId, 'noteIds'], newNoteIds);

  return draft;
});

const remove = (state: AppointmentsState, action: AppointmentsRemoveActionType) => {
  const { payload } = action;

  return omit(state, payload);
};

const removePhotos = produce((
  draft: Draft<AppointmentsState>,
  action: AppointmentsRemovePhotosActionType,
) => {
  const { payload } = action;
  const { appointmentId, photoIds } = payload;

  const appointment = get(draft, [appointmentId]);

  if (isEmpty(appointment)) {
    return draft;
  }

  const draftPhotoIds = get(draft, [appointmentId, 'photoIds']);
  const newPhotoIds = without(draftPhotoIds, ...photoIds);

  set(draft, [appointmentId, 'photoIds'], newPhotoIds);

  return draft;
});

const removeAudioRecordings = produce((
  draft: Draft<AppointmentsState>,
  action: AppointmentsRemoveAudioRecordingsActionType,
) => {
  const { payload } = action;
  const { appointmentId, audioRecordingIds } = payload;

  const appointment = get(draft, [appointmentId]);

  if (isEmpty(appointment)) {
    return draft;
  }

  const draftAudioRecordingIds = get(draft, [appointmentId, 'audioRecordingIds']);
  const newAudioRecordingIds = without(draftAudioRecordingIds, ...audioRecordingIds);

  set(draft, [appointmentId, 'audioRecordingIds'], newAudioRecordingIds);

  return draft;
});

const update = (state: AppointmentsState, action: AppointmentsAddActionType) => {
  const { payload } = action;

  const appointments = keyBy(payload, 'id');

  return {
    ...state,
    ...appointments,
  };
};

const clear = () => ({});

export {
  add,
  addPhotos,
  addAudioRecordings,
  addNotes,
  remove,
  removePhotos,
  removeAudioRecordings,
  update,
  clear,
};
