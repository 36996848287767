import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import '../css/Home.css';
import DailySuggestions from 'src/components/DailySuggestions';
import Button from 'src/components/Button';
import { useHistory, Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import GanttChart from 'src/components/GanttChart';
import getTime from 'date-fns/getTime';
import startOfDay from 'date-fns/startOfDay';
import addMonths from 'date-fns/addMonths';
import { useSelector } from 'react-redux';
import { AppState } from 'src/state/reducers';
import TrendsGrid from 'src/components/TrendsGrid';
import Drawer from 'src/components/Drawer';
import DrawerRightHeader from 'src/components/DrawerRightHeader';
import map from 'lodash/map';
import Navigation from 'src/components/Navigation';
import Footer from 'src/components/Footer';
import DrawerButtons from '../components/DrawerButtons';
import { selectors as treatmentsSelectors } from '../state/treatments';
import { TTreatment } from '../../types';
import ListItem from '../components/ListItem';

import Colors from '../theme/Colors';
import getProfileProgressSelector from '../selectors/getProfileProgress';
import { selectors as appointmentsSelectors } from '../state/appointments';
import { selectors as notesSelectors } from '../state/notes';
import { selectors as photosSelectors } from '../state/photos';
import { selectors as audioRecordingsSelectors } from '../state/audioRecordings';
import { selectors as questionnairesSelectors } from '../state/questionnaires';
import { ReactComponent as IconAppointment } from '../assets/icons/Appointment.svg';
import { ReactComponent as IconNote } from '../assets/icons/Note.svg';
import { ReactComponent as IconRecording } from '../assets/icons/Recording.svg';
import { ReactComponent as IconPhoto } from '../assets/icons/Photo.svg';
import { ReactComponent as IconQuestionnaire } from '../assets/icons/Questionnaire.svg';
import { ReactComponent as IconChevronRight } from '../assets/icons/ChevronRight.svg';
import { ReactComponent as IconPersonalised } from '../assets/icons/Personalised.svg';
import AppointmentListItem from '../components/AppointmentListItem';
import NoteListItem from '../components/NoteListItem';
import PhotoListItem from '../components/PhotoListItem';
import AudioRecordingListItem from '../components/AudioRecordingListItem';
import QuestionnaireListItem from '../components/QuestionnaireListItem';

const DrawerRight = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const { percentage, completed } = useSelector(
    (state: AppState) => getProfileProgressSelector(state),
  );

  const memoizedAppointmentsSelector = useMemo(
    appointmentsSelectors.makeGetUpcoming,
    [],
  );

  const appointments = useSelector(
    (state: AppState) => memoizedAppointmentsSelector(state, null, 2),
  );

  const memoizedNotesSelector = useMemo(
    notesSelectors.makeGetLastUpdated,
    [],
  );

  const notes = useSelector(
    (state: AppState) => memoizedNotesSelector(state, 2),
  );

  const memoizedPhotosSelector = useMemo(
    photosSelectors.makeGetLastUpdated,
    [],
  );

  const photos = useSelector(
    (state: AppState) => memoizedPhotosSelector(state, 2),
  );

  const memoizedAudioRecordingsSelector = useMemo(
    audioRecordingsSelectors.makeGetLastUpdated,
    [],
  );

  const audioRecordings = useSelector(
    (state: AppState) => memoizedAudioRecordingsSelector(state, 2),
  );

  const memoizedQuestionnairesSelector = useMemo(
    questionnairesSelectors.makeGetLastUpdated,
    [],
  );

  const questionnaires = useSelector(
    (state: AppState) => memoizedQuestionnairesSelector(state, 2),
  );

  return (
    <Drawer position="right">
      <section>
        <DrawerRightHeader />
        <section className="drawer-item">
          <header className="row between center">
            <h1 className="heading">{t('profile')}</h1>
            <p className="body light">{t('profile-completed', { percentage })}</p>
          </header>
          <Link to={completed ? '/profile' : '/profile/complete'} className="navigation accent align-end">
            {t(completed ? 'change-profile' : 'complete-profile')} &gt;
          </Link>
        </section>
        <section className="drawer-item">
          <header className="row between center">
            <h1 className="heading">{t('diary')}</h1>
            <Link to="/diary/all" className="button accent">{t('see-all')}</Link>
          </header>
          <section>
            <ListItem
              primaryText={t('appointments')}
              leftIcon={(
                <IconAppointment fill={Colors.primary} className="icon" />
                  )}
              rightIcon={<Button labelText={t('add')} onClick={() => history.push('/appointments/add')} />}
            />
            {
                !isEmpty(appointments)
                  ? map(appointments, (appointment) => (
                    <AppointmentListItem appointment={appointment} key={appointment.id} />
                  ))
                  : <span className="body light">{t('appointments:empty-state-no-appointments')}</span>
              }
          </section>
          <section>
            <ListItem
              primaryText={t('notes')}
              leftIcon={(
                <IconNote fill={Colors.primary} className="icon" />
                  )}
              rightIcon={<Button labelText={t('add')} onClick={() => history.push('/notes/add')} />}
            />
            {
                !isEmpty(notes)
                  ? map(notes, (note) => <NoteListItem note={note} key={note.id} />)
                  : <span className="body light">{t('notes:empty-state-no-notes')}</span>
              }
          </section>
          <section>
            <ListItem
              primaryText={t('photos')}
              leftIcon={(
                <IconPhoto fill={Colors.primary} className="icon" />
                  )}
              rightIcon={<Button labelText={t('add')} onClick={() => history.push('/photos/add')} />}
            />
            {
                !isEmpty(photos)
                  ? map(photos, (photo) => <PhotoListItem photo={photo} key={photo.id} />)
                  : <span className="body light">{t('photos:empty-state-no-photos')}</span>
              }

          </section>
          <section>
            <ListItem
              primaryText={t('recordings')}
              leftIcon={(
                <IconRecording fill={Colors.primary} className="icon" />
                  )}
            />
            { !isEmpty(audioRecordings)
              ? map(audioRecordings, (audioRecording) => (
                <AudioRecordingListItem audioRecording={audioRecording} key={audioRecording.id} />
              ))
              : <span className="body light">{t('audioRecordings:empty-state-no-audio-recordings')}</span>}
          </section>
        </section>
        <section className="drawer-item">
          <header className="row between center">
            <h1 className="heading">{t('questionnaires')}</h1>
            <Link to="/questionnaires" className="button accent">{t('see-all')}</Link>
          </header>
          {
              !isEmpty(questionnaires)
                ? map(questionnaires, (questionnaire) => (
                  <QuestionnaireListItem key={questionnaire.id} questionnaire={questionnaire} />
                ))
                : <span className="body light">{t('questionnaires:empty-state-no-questionnaires')}</span>
            }
        </section>
      </section>
    </Drawer>
  );
};

const Home = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const todayTimestamp = getTime(startOfDay(Date.now()));

  // Use start of day so the values stay the same for a whole day
  const graphStart = startOfDay(addMonths(todayTimestamp, -2));
  const graphEnd = startOfDay(addMonths(todayTimestamp, 2));

  const memoizedRelevantTreatmentsSelector = useMemo(
    () => treatmentsSelectors.makeGetMostRelevant(graphStart, graphEnd, todayTimestamp, 3),
    [graphStart, graphEnd],
  );

  const treatments = useSelector(
    (state: AppState) => memoizedRelevantTreatmentsSelector(state),
  );

  return (
    <article className="page overview row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('overview')} showRightButton />
          <DailySuggestions />
          <section className="add-trends">
            <div className="add-trends-content-wrapper">
              <div className="text-wrapper">
                <div>
                  <h1 className="heading">{t('how-do-i-feel')}</h1>
                </div>

                <p className="body">{t('how-do-i-feel-body')}</p>
              </div>
              <IconPersonalised className="small add-trends-personalised-icon" />
            </div>
            <Button
              labelText={t('fill-in-trends')}
              size="medium"
              onClick={() => history.push('/trends/add')}
            />
          </section>
          <header className="row between center">
            <h1 className="body light">{t('plan')}</h1>
            <Link to="/treatments" className="button accent">{t('see-all')}</Link>
          </header>
          {
                      !isEmpty(treatments)
                        ? <GanttChart treatments={treatments as TTreatment[]} />
                        : (
                          <section className="overview-empty-state">
                            <h1 className="heading">{t('treatments:empty-state-home-heading')}</h1>
                            <p className="body">{t('treatments:empty-state-home-body')}</p>
                            <Button
                              labelText={t('add-treatment')}
                              size="medium"
                              onClick={() => history.push('/treatments/add')}
                            />
                          </section>
                        )
                  }
          <h1 className="body light">{t('tools')}</h1>
          <section className="tools">
            <ListItem
              leftIcon={<IconQuestionnaire fill={Colors.primary} className="icon medium" />}
              primaryText={t('questionnaires')}
              secondaryText={t('questionnaires-subtitle')}
              rightIcon={<IconChevronRight fill={Colors.primary} className="icon small" />}
              to="/questionnaires"
            />
          </section>
          <header className="row between center">
            <h1 className="body light">{t('trends')}</h1>
            <Link to="/trends" className="button accent">{t('see-all')}</Link>
          </header>
          <TrendsGrid type="favorites" />
        </section>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default Home;
