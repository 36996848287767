/* eslint react/require-default-props: 0 */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import toString from 'lodash/toString';
import size from 'lodash/size';
import endsWith from 'lodash/endsWith';
import round from 'lodash/round';
import replace from 'lodash/replace';
import map from 'lodash/map';
import Icon from 'react-native-vector-icons/Ionicons';

import { ProfileQuestionType } from '../../../types';
import { ProfileQuestionPropType } from '../../../propTypes';

import ListItem from '../ListItem';
import Colors from '../../theme/Colors';
import count from '../../utils/count';
import focusRef from '../../utils/focusRef';
import getLocalizedText from '../../utils/getLocalizedText';

type Props = {
  question: ProfileQuestionType;
  onChangeValue: (value: string) => void;
  value: number;
  maximumValue: number;
  decimals?: number;
};

const propTypes = {
  question: ProfileQuestionPropType.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  maximumValue: PropTypes.number.isRequired,
  decimals: PropTypes.number,
};

const OncotypeDXInput = (props: Props) => {
  const {
    question,
    onChangeValue,
    value,
    maximumValue,
    decimals = 1,
  } = props;

  const { t, i18n } = useTranslation();

  // A value of -1 means that the user did not fill in a score.
  // The input will be disabled and the checkbox will be checked
  const hasScore = value === -1;

  const [internalValue, setInternalValue] = useState(hasScore ? '' : toString(value));
  const [disabled, setDisabled] = useState(hasScore);
  const [error, setError] = useState('');

  const maxLength = size(toString(maximumValue)) + decimals + 1;

  const handleTextChange = (text: string) => {
    const formattedText = replace(text, ',', '.');

    // Only allow one .
    if (count(formattedText, '.') > 1) {
      return false;
    }

    // Update internal value with partial decimal
    if (endsWith(formattedText, '.')) {
      return setInternalValue(formattedText);
    }

    let newValue = 0;

    if (formattedText) {
      newValue = round(parseFloat(formattedText), decimals);
    }

    // Set all values to maximumValue if we exceed maximumValue and display an error
    if (newValue > maximumValue) {
      setError(t('errors:max-value', { maxValue: maximumValue }));
      setInternalValue(toString(maximumValue));
      return onChangeValue(toString(maximumValue));
    }

    setError('');
    setInternalValue(toString(newValue));
    return onChangeValue(toString(newValue));
  };

  const inputRef = useRef(null);

  const handleCheckboxChange = () => {
    setError('');
    setDisabled(!disabled);

    // Only set value of -1 if we disable the input
    if (!disabled) {
      setInternalValue('');
      return onChangeValue(toString(-1));
    }

    // If we enable the input, set value to 0
    setInternalValue(toString(0));
    return onChangeValue(toString(0));
  };

  return (
    <section className="oncotype-dx">
      <button type="button" onClick={() => focusRef(inputRef)}>
        <section
          className="row"
        >
          <input
            value={internalValue}
            onChange={(event) => handleTextChange(event.currentTarget.value)}
            type="numeric"
            maxLength={maxLength}
            ref={inputRef}
            disabled={disabled}
          />
          {/* <p className="body light unit">{getLocalizedText(question, i18n.language, 'answerUnit')}</p> */}
          <p className="body light unit">ng/mL</p>
        </section>
      </button>
      {map(question.answers, (answer) => (
        <ListItem
          key={answer.id}
          onClick={handleCheckboxChange}
          leftIcon={(
            <Icon
              name={disabled ? 'checkbox' : 'square-outline'}
              color={Colors.secondary}
              size={28}
            />
          )}
          primaryText={getLocalizedText(answer, i18n.language, 'title')}
          active={disabled}
          divider
        />
      ))}
      {
        error
          ? <p className="body error">{error}</p>
          : null
      }
    </section>
  );
};

OncotypeDXInput.propTypes = propTypes;

export default OncotypeDXInput;
