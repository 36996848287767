import React, { useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import { useTranslation } from 'react-i18next';

import { AppState } from '../state/reducers';

import { selectors as notesSelectors } from '../state/notes';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRightHeader from '../components/DrawerRightHeader';
import NoteListItem from '../components/NoteListItem';

type Params = {
  view: string;
  date?: string;
};

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const Notes = () => {
  const { t } = useTranslation();

  const { view, date } = useParams<Params>();

  const history = useHistory();

  const memoizedGetSelector = useMemo(
    notesSelectors.makeGetAllByViewAndDate,
    [],
  );

  const notes = useSelector(
    (state: AppState) => memoizedGetSelector(state, view, date, true),
  );

  return (
    <article className="page diary row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('notes')} showRightButton />
          <button
            type="button"
            className="navigation accent back"
            onClick={history.goBack}
          >
            &lt; {t('back')}
          </button>
          <section className={isEmpty(notes) ? 'diary-item' : 'list'}>
            {
              !isEmpty(notes)
                ? map(notes, (note, index) => (
                  <NoteListItem
                    note={note}
                    key={note.id}
                    divider={(index + 1) !== size(notes)}
                  />
                ))
                : <span className="body light">{t('notes:empty-state-no-notes')}</span>
            }
          </section>
        </section>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default Notes;
