// See https://github.com/microsoft/TypeScript/issues/22019
import { ViewStyle, TextStyle } from 'react-native';

import Constants from './Constants';
import Colors from './Colors';
import generateShadowStyles from '../utils/generateShadowStyles';

const flex = {
  flex: 1,
};

const flexGrow = {
  flexGrow: 1,
  backgroundColor: Colors.white,
};

const borderRadius = {
  borderRadius: Constants.defaultBorderRadius,
};

const listItemIcon: ViewStyle = {
  flex: 1,
  width: Constants.defaultIconSize,
  height: Constants.defaultIconSize,
  justifyContent: 'center',
  alignItems: 'center',
};

const listItemIconLarge: ViewStyle = {
  ...listItemIcon,
  width: Constants.iconSizeLarge,
  height: Constants.iconSizeLarge,
};

const dividerProps = {
  offsetLeft: 17,
};

const textInputDividerProps = {
  lineStyle: {
    backgroundColor: Colors.lightPrimary,
  },
  style: {
    height: 1,
  },
};

const textAreaProps = {
  maxHeight: 240,
};

const dividerBottomProps = {
  offsetLeft: 44,
};

const dividerBottomPropsLarge = {
  offsetLeft: 60,
};

const saveButton: ViewStyle = {
  alignSelf: 'center',
  marginTop: 24,
  marginBottom: 16,
  ...generateShadowStyles(2),
};

const destructiveButton: ViewStyle = {
  backgroundColor: Colors.destructiveRed,
  alignSelf: 'center',
  marginTop: 24,
  ...generateShadowStyles(2),
};

const deleteButton: ViewStyle = {
  height: 44,
  paddingHorizontal: 24,
  alignItems: 'center',
  justifyContent: 'center',
};

const deleteText: TextStyle = {
  color: Colors.destructiveRed,
};

const listContainer = {
  paddingTop: 0,
};

const formFieldSeparator = {
  marginBottom: 24,
};

const floatingButton: ViewStyle = {
  position: 'absolute',
  alignSelf: 'center',
  bottom: 16,
  ...generateShadowStyles(2),
};

const listContainerFloatingButton = {
  ...listContainer,
  paddingBottom: Constants.floatingButtonPaddingBottom,
};

export default {
  flex,
  flexGrow,
  borderRadius,
  listItemIcon,
  listItemIconLarge,
  dividerProps,
  textInputDividerProps,
  textAreaProps,
  dividerBottomProps,
  dividerBottomPropsLarge,
  saveButton,
  deleteButton,
  deleteText,
  listContainer,
  formFieldSeparator,
  floatingButton,
  listContainerFloatingButton,
  destructiveButton,
};
