import { createSelector } from 'reselect';
import get from 'lodash/get';
import toArray from 'lodash/toArray';

import { AppState } from '../reducers';
import { HospitalsState } from './types';
import { HospitalReturnType, HospitalsReturnType } from '../../../types';

import EMPTY_OBJECT from '../../utils/empty-object';

const getByIdSelector = (state: HospitalsState, id: string): HospitalReturnType => (
  get(state, [id]) || EMPTY_OBJECT
);

const makeGetById = () => (
  createSelector(
    (state: AppState) => state.hospitals,
    (state: any, id: string) => id,
    getByIdSelector,
  )
);

const getAllSelector = (state: HospitalsState): HospitalsReturnType => toArray(state);

const getAll = createSelector(
  (state: AppState) => state.hospitals,
  getAllSelector,
);

export {
  makeGetById,
  getAll,
};
