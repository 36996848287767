import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import formatISO from 'date-fns/formatISO';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import subWeeks from 'date-fns/subWeeks';
import addWeeks from 'date-fns/addWeeks';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { PushNotificationReturnType, PushNotificationType } from 'types';
import { AppState } from '../state/reducers';

import Button from './Button';
import AppointmentListItem from './AppointmentListItem';
import NoteListItem from './NoteListItem';
import MessageListItem from './MessageListItem';
import PhotoListItem from './PhotoListItem';
import AudioRecordingListItem from './AudioRecordingListItem';
import Colors from '../theme/Colors';
import { selectors as appointmentsSelectors } from '../state/appointments';
import { selectors as notesSelectors } from '../state/notes';
import { selectors as photosSelectors } from '../state/photos';
import { selectors as audioRecordingsSelectors } from '../state/audioRecordings';
import { selectors as messagesSelectors } from '../state/messages';
import { selectors as pushNotificationsSelectors } from '../state/notifications';
import { ReactComponent as IconChevronLeft } from '../assets/icons/ChevronLeft.svg';
import { ReactComponent as IconChevronRight } from '../assets/icons/ChevronRight.svg';
import dateFormat from '../utils/dateFormat';
import isOfType from '../utils/isOfType';

const DiaryWeek = () => {
  const [startDate, setStartDate] = useState(startOfWeek(new Date(), { weekStartsOn: 1 }));

  const customFormat = 'd MMM yyyy';

  const { i18n, t } = useTranslation();

  const history = useHistory();

  const memoizedAppointmentsSelector = useMemo(
    appointmentsSelectors.makeGetByWeek,
    [],
  );

  const appointments = useSelector(
    (state: AppState) => memoizedAppointmentsSelector(state, formatISO(startDate)),
  );

  const memoizedNotesSelector = useMemo(
    notesSelectors.makeGetByWeek,
    [],
  );

  const notes = useSelector(
    (state: AppState) => memoizedNotesSelector(state, formatISO(startDate)),
  );

  const memoizedMessagesSelector = useMemo(
    messagesSelectors.makeGetByWeek,
    [],
  );

  const messages = useSelector(
    (state: AppState) => memoizedMessagesSelector(state, formatISO(startDate)),
  );

  const memoizePushNotificationsGetByWeekSelector = useMemo(
    pushNotificationsSelectors.makeGetByWeek,
    [],
  );

  const pushNotifications = useSelector(
    (state: AppState) => memoizePushNotificationsGetByWeekSelector(state, formatISO(startDate)),
  ) as PushNotificationReturnType[];

  const memoizedPhotosSelector = useMemo(
    photosSelectors.makeGetByWeek,
    [],
  );

  const photos = useSelector(
    (state: AppState) => memoizedPhotosSelector(state, formatISO(startDate)),
  );

  const memoizedAudioRecordingsSelector = useMemo(
    audioRecordingsSelectors.makeGetByWeek,
    [],
  );

  const audioRecordings = useSelector(
    (state: AppState) => memoizedAudioRecordingsSelector(state, formatISO(startDate)),
  );

  const pushAndMessages = [...pushNotifications, ...messages].sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  );

  const handleBackPress = () => (
    setStartDate(subWeeks(startDate, 1))
  );

  const handleForwardPress = () => (
    setStartDate(addWeeks(startDate, 1))
  );

  return (
    <>
      <header className="diary-header center">
        <button type="button" onClick={handleBackPress}>
          <IconChevronLeft
            fill={Colors.primary}
            className="icon small"
          />
        </button>
        <span className="button accent">
          {dateFormat(i18n.language, startDate, customFormat)}
          &nbsp;-&nbsp;
          {dateFormat(i18n.language, endOfWeek(startDate, { weekStartsOn: 1 }), customFormat)}
        </span>
        <button type="button" onClick={handleForwardPress}>
          <IconChevronRight
            fill={Colors.primary}
            className="icon small"
          />
        </button>
      </header>
      <header className="list-header">
        <h1 className="heading-secondary accent">{t('my-appointments')}</h1>
        <Button labelText={t('add')} onClick={() => history.push('/appointments/add')} />
      </header>
      <section className={classNames('list', { empty: isEmpty(appointments) })}>
        {
          !isEmpty(appointments)
            ? map(appointments, (appointment, index) => (
              <AppointmentListItem
                appointment={appointment}
                key={appointment.id}
                divider={size(appointments) !== (index + 1)}
              />
            ))
            : <span className="body light">{t('appointments:empty-state-no-appointments')}</span>
        }
      </section>
      <header className="list-header">
        <h1 className="heading-secondary accent">{t('my-notes')}</h1>
        <Button labelText={t('add')} onClick={() => history.push('/notes/add')} />
      </header>
      <section className={classNames('list', { empty: isEmpty(notes) })}>
        {
          !isEmpty(notes)
            ? map(notes, (note, index) => (
              <NoteListItem
                note={note}
                key={note.id}
                divider={size(notes) !== (index + 1)}
              />
            ))
            : <span className="body light">{t('notes:empty-state-no-notes')}</span>
        }
      </section>
      <header className="list-header">
        <h1 className="heading-secondary accent">{t('my-messages')}</h1>
      </header>
      <section className={classNames('list', { empty: isEmpty(pushAndMessages) })}>
        {
          !isEmpty(pushAndMessages)
            ? map(pushAndMessages, (message, index) => {
              const isPushNotification = isOfType<PushNotificationType>(message, 'showInMailbox');
              return (
                <MessageListItem
                  message={message}
                  key={message.id}
                  divider={size(pushAndMessages) !== (index + 1)}
                  isPushNotification={isPushNotification}
                />
              );
            })
            : <span className="body light">{t('messages:empty-state-no-messages')}</span>
        }
      </section>
      <header className="list-header">
        <h1 className="heading-secondary accent">{t('my-photos')}</h1>
        <Button labelText={t('add')} onClick={() => history.push('/photos/add')} />
      </header>
      <section className={classNames('list', { empty: isEmpty(photos) })}>
        {
          !isEmpty(photos)
            ? map(photos, (photo, index) => (
              <PhotoListItem
                photo={photo}
                key={photo.id}
                divider={size(photos) !== (index + 1)}
              />
            ))
            : <span className="body light">{t('photos:empty-state-no-photos')}</span>
        }
      </section>
      <header className="list-header">
        <h1 className="heading-secondary accent">{t('my-audio-recordings')}</h1>
      </header>
      <section className={classNames('list', { empty: isEmpty(audioRecordings) })}>
        {
          !isEmpty(audioRecordings)
            ? map(audioRecordings, (audioRecording, index) => (
              <AudioRecordingListItem
                audioRecording={audioRecording}
                key={audioRecording.id}
                divider={size(audioRecordings) !== (index + 1)}
              />
            ))
            : <span className="body light">{t('audioRecordings:empty-state-no-audio-recordings')}</span>
        }
      </section>
    </>
  );
};

export default DiaryWeek;
