import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { View as RNView, StyleSheet as RNStyleSheet } from 'react-native';

import includes from 'lodash/includes';
import { TDrugModule } from '../../types';

import Modal from '../components/Modal';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import Button from '../components/Button';
import Navigation from '../components/Navigation';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRightHeader from '../components/DrawerRightHeader';
import Colors from '../theme/Colors';
import { ReactComponent as IconGlossary } from '../assets/icons/Glossary.svg';
import { ReactComponent as IconWarning } from '../assets/icons/Warning.svg';
import { ReactComponent as IconFillInSideEffects } from '../assets/icons/MyTrend.svg';
import { ReactComponent as IconFillInSick } from '../assets/icons/FeelSick.svg';
import { ReactComponent as IconMyResearch } from '../assets/icons/MyResearch.svg';
import { ReactComponent as IconResearchWoman } from '../assets/icons/ResearchWoman.svg';
import { ReactComponent as IconFitness } from '../assets/icons/Fitness.svg';
import { ReactComponent as IconQuestionmarkCircle } from '../assets/icons/QuestionmarkCircle.svg';

import Text from '../components/Text';
import LeaveDrugModuleSupportHoc from '../hocs/LeaveDrugModuleSupportHoc';
import SideEffectReportingBlock from '../components/SideEffectReportingBlock';

import '../css/DrugModule.css';

const styles = RNStyleSheet.create({
  contentBlock: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 20,
    marginTop: 20,
  },
  footerBlock: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

type Params = {
  drugModule: TDrugModule;
};

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const DrugModule = () => {
  const { t } = useTranslation();

  const { drugModule } = useParams<Params>();

  const [openedWelcomeModal, setOpenedWelcomeModal] = useState(false);

  const location = useLocation<{[x: string]: boolean | string}>();

  const toggleWelcomeModal = () => {
    setOpenedWelcomeModal((prev) => !prev);
  };

  useEffect(() => {
    if (!includes(location.state, '/support-menu/')) {
      setOpenedWelcomeModal(!location.state.isFromDrugWelcomeScreen);
    }
  }, []);

  return (
    <article className="page diary row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons titleColor={Colors.drugModuleColors.primary[drugModule]} title={t(`contentModules:drug:${drugModule}:my-research-navigation-title`)} showRightButton />
          <p>{t(`contentModules:drug:${drugModule}:my-research-subtitle`)}</p>
          <section className="drug-module">
            <NavLink className="navigation" activeClassName="emphasized" exact to={`/support-menu/${drugModule}/treatment-guide/`}>
              <IconGlossary fill={Colors.black} className="icon" />
              <span>{t('contentModules:drug:common:treatment-guide')}</span>
            </NavLink>
            <NavLink className="navigation" activeClassName="emphasized" exact to={`/support-menu/${drugModule}/side-effects/`}>
              <IconWarning fill={Colors.black} className="icon" />
              <span>{t('contentModules:drug:common:side-effects')}</span>
            </NavLink>
            <NavLink className="navigation" activeClassName="emphasized" exact to={`/support-menu/${drugModule}/fill-in-side-effects/`}>
              <IconFillInSideEffects fill={Colors.black} className="icon" />
              <span>{t('contentModules:drug:common:fill-in-side-effects')}</span>
            </NavLink>
            <NavLink className="navigation" activeClassName="emphasized" exact to={`/support-menu/${drugModule}/when-seek-help/`}>
              <IconFillInSick fill={Colors.black} className="icon" />
              <span>{t('contentModules:drug:common:when-seek-help')}</span>
            </NavLink>
            <NavLink className="navigation" activeClassName="emphasized" exact to={`/support-menu/${drugModule}/my-research/`}>
              <IconMyResearch fill={Colors.black} className="icon" />
              <span>{t('contentModules:drug:common:my-research')}</span>
            </NavLink>
            <NavLink className="navigation" activeClassName="emphasized" exact to={`/support-menu/${drugModule}/test-fitness/`}>
              <IconFitness fill={Colors.black} className="icon" />
              <span>{t(`contentModules:drug:${drugModule}:test-fitness`)}</span>
            </NavLink>
            <NavLink className="navigation" activeClassName="emphasized" exact to={`/support-menu/${drugModule}/faq/`}>
              <IconQuestionmarkCircle className="icon" fill={Colors.primary} />
              <span>{t('contentModules:drug:common:faq:title')}</span>
            </NavLink>
          </section>

          <section>
            <RNView style={styles.contentBlock}>
              <SideEffectReportingBlock drugModule={drugModule} body="side-effect-reporting" />
            </RNView>
          </section>
          <RNView style={styles.footerBlock}>
            <Text type="body-light">{drugModule === 'enhertu' ? 'GB-51123' : 'GB-53833'}</Text>
            <Text type="body-light">May 2024</Text>
          </RNView>

          <Modal
            className="default-modal welcome-modal align-center"
            visible={openedWelcomeModal}
            hideModal={toggleWelcomeModal}
            actions={[
            ]}
          >
            <h1 className="heading">{t(`contentModules:drug:${drugModule}:modals:welcome-modal:title`)}</h1>
            <IconResearchWoman fill={Colors.drugModuleColors.primary[drugModule]} className="research-woman" />
            <p>{t(`contentModules:drug:${drugModule}:modals:welcome-modal:body`)}</p>
            <p>{t('contentModules:drug:common:modals:welcome-modal:area-intended')} <span className="drug-module-modal-intended">{t('contentModules:drug:common:modals:welcome-modal:intended')}</span> <span className="drug-module-modal-intended-bold">{t(`contentModules:drug:${drugModule}:welcome-modal:prescribed`)}</span>*</p>
            <Button labelText={t('understand')} size="medium" onClick={toggleWelcomeModal} />
            <p className="footnote">{t('contentModules:drug:common:modals:welcome-modal:footnote')}</p>
          </Modal>
        </section>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default LeaveDrugModuleSupportHoc(DrugModule);
