import { Reducer } from 'redux';

import * as utils from './utils';
import * as actionTypes from './actionTypes';
import {
  ContactsState,
  ContactsActionTypes,
  ContactsAddActionType,
  ContactsAddPhotosActionType,
  ContactsRemoveActionType,
  ContactsRemovePhotosActionType,
  ContactsUpdateActionType,
} from './types';

const initialState: ContactsState = {};

const reducer: Reducer<
ContactsState,
ContactsActionTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD: {
      return utils.add(state, action as ContactsAddActionType);
    }
    case actionTypes.ADD_PHOTOS: return utils.addPhotos(
      state,
      action as ContactsAddPhotosActionType,
    );
    case actionTypes.REMOVE: {
      return utils.remove(state, action as ContactsRemoveActionType);
    }
    case actionTypes.REMOVE_PHOTOS: {
      return utils.removePhotos(
        state,
        action as ContactsRemovePhotosActionType,
      );
    }
    case actionTypes.UPDATE: {
      return utils.update(state, action as ContactsUpdateActionType);
    }
    case actionTypes.CLEAR: return utils.clear();
    default: return state;
  }
};

export default reducer;
