import { Reducer } from 'redux';

import * as utils from './utils';
import * as actionTypes from './actionTypes';
import { TemplatesState, TemplateActionTypes } from './types';

import { templates } from '../../../data/system-document.json';

const initialState = templates as TemplatesState;

const reducer: Reducer<
TemplatesState,
TemplateActionTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLEAR: return utils.clear();
    default: return state;
  }
};

export default reducer;
