import produce, { Draft, current } from 'immer';
import map from 'lodash/map';
import set from 'lodash/set';
import keyBy from 'lodash/keyBy';
import size from 'lodash/size';
import get from 'lodash/get';
import includes from 'lodash/includes';
import omit from 'lodash/omit';

import {
  SettingsTrendIndicatorsState,
  SettingsTrendIndicatorsUpdateActionType,
  SettingsTrendIndicatorsUpdateDisabledActionType,
  SettingsTrendIndicatorsUpdateFavoriteActionType,
  SettingsTrendIndicatorsUpdateOrderActionType,
  SettingsBannersState,
  ContentModulesState,
  ContentModulesUpdateActionType,
  DrugModulesUpdatePayload,
  ContentModulesUpdatePayload,
} from './types';

import getUnixTime from '../../utils/getUnixTime';
import images from '../../images';
import getAvailableDrugModules from '../../utils/getAvailableDrugModules';

const trendIndicatorsUpdate = (
  state: SettingsTrendIndicatorsState,
  action: SettingsTrendIndicatorsUpdateActionType,
) => {
  const { payload } = action;

  const trendIndicators = keyBy(payload, 'id');

  return {
    ...state,
    ...trendIndicators,
  };
};

const trendIndicatorsUpdateDisabled = produce((
  draft: Draft<SettingsTrendIndicatorsState>,
  action: SettingsTrendIndicatorsUpdateDisabledActionType,
) => {
  const { payload } = action;

  map(payload, ({ id, disabled, order }) => {
    set(draft, [id, 'id'], id);
    set(draft, [id, 'updatedAt'], getUnixTime());
    set(draft, [id, 'disabled'], disabled);
    set(draft, [id, 'order'], order);
  });
});

const trendIndicatorsUpdateFavorite = produce((
  draft: Draft<SettingsTrendIndicatorsState>,
  action: SettingsTrendIndicatorsUpdateFavoriteActionType,
) => {
  const { payload } = action;

  map(payload, ({ id, favorite }) => {
    set(draft, [id, 'id'], id);
    set(draft, [id, 'updatedAt'], getUnixTime());
    set(draft, [id, 'favorite'], favorite);
  });
});

const trendIndicatorsUpdateOrder = produce((
  draft: Draft<SettingsTrendIndicatorsState>,
  action: SettingsTrendIndicatorsUpdateOrderActionType,
) => {
  const { payload } = action;

  map(payload, (id, index) => {
    set(draft, [id, 'id'], id);
    set(draft, [id, 'order'], index);
  });
});

const bannersTrendsNext = produce((
  draft: Draft<SettingsBannersState>,
) => {
  const currentIndex = get(current(draft), 'trends');
  const total = size(get(images, 'banners.trends'));

  let nextIndex = currentIndex + 1;

  if (nextIndex > (total - 1)) {
    nextIndex = 0;
  }

  set(draft, 'trends', nextIndex);
});

const isDrugModule = (payload: ContentModulesUpdatePayload):
  payload is DrugModulesUpdatePayload => includes(getAvailableDrugModules(), payload.moduleName);

const contentModuleUpdate = produce((
  draft: Draft<ContentModulesState>,
  action: ContentModulesUpdateActionType,
) => {
  const { payload } = action;

  if (isDrugModule(payload)) {
    const {
      moduleName,
      surveyKey,
      ...rest
    } = payload;

    const currentSurveyKey = get(current(draft), [moduleName, 'surveyKey']);

    // surveyKey can only be set once!
    if (!currentSurveyKey && surveyKey) {
      set(draft, [moduleName, 'surveyKey'], surveyKey);
    }

    const DrugModuleKeys = Object.keys(omit(rest, 'surveyKey')) as (keyof typeof rest)[];

    DrugModuleKeys.forEach((key) => {
      set(draft, [moduleName, key], rest[key]);
    });
  } else {
    const {
      moduleName,
      ...rest
    } = payload;

    const defaultModuleKeys = Object.keys(omit(rest, 'moduleName')) as (keyof typeof rest)[];

    defaultModuleKeys.forEach((key) => {
      set(draft, [moduleName, key], rest[key]);
    });
  }
});

export {
  trendIndicatorsUpdate,
  trendIndicatorsUpdateDisabled,
  trendIndicatorsUpdateFavorite,
  trendIndicatorsUpdateOrder,
  bannersTrendsNext,
  contentModuleUpdate,
};
