/* eslint react/require-default-props: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Path } from 'react-native-svg';
import ResizeableSVG from '../ResizeableSVG';

import Colors from '../../theme/Colors';
import Constants from '../../theme/Constants';

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

type Props = {
  color?: string;
  size?: number;
};

const Link = (props: Props) => {
  const {
    color = Colors.primary,
    size = Constants.defaultIconSize,
  } = props;

  return (
    <ResizeableSVG size={size}>
      <Path d="M16.2883977,11.7116028 C16.66165,12.0848552 16.66165,12.6900168 16.2883977,13.0632691 C15.9151453,13.4365215 15.3099837,13.4365215 14.9367313,13.0632691 C13.537035,11.6635728 11.2676789,11.6635728 9.86798253,13.0632691 L4.46131715,18.4699343 C3.06162079,19.8696306 3.06162079,22.1389867 4.46131715,23.538683 C5.86101347,24.9383792 8.13036959,24.9383793 9.53006595,23.538683 L10.8988961,22.1698528 C11.0441795,22.0245695 11.8058579,21.2645406 13.1854735,19.8882266 C13.5591727,19.5154217 14.1643339,19.5161466 14.5371389,19.8898458 C14.9099438,20.263545 14.9092188,20.8687062 14.5355201,21.2415116 C13.6166109,22.1582211 12.9715672,22.801798 12.6008458,23.171786 L10.8817323,24.8903493 C8.73553123,27.0365503 5.2558518,27.0365503 3.1096508,24.8903493 C0.963449769,22.7441482 0.963449769,19.2644691 3.1096508,17.118268 L8.5163161,11.7116027 C10.6625171,9.56540173 14.1421966,9.56540179 16.2883977,11.7116028 Z M24.8903492,3.10965073 C27.0365502,5.25585173 27.0365502,8.73553097 24.8903492,10.881732 L19.4836839,16.2883973 C17.3374829,18.4345983 13.8578033,18.4345982 11.7116023,16.2883972 C11.33835,15.9151448 11.33835,15.3099832 11.7116023,14.9367309 C12.0848547,14.5634785 12.6900163,14.5634785 13.0632687,14.9367309 C14.462965,16.3364272 16.7323211,16.3364272 18.1320175,14.9367309 L23.5386829,9.53006567 C24.9383792,8.13036939 24.9383792,5.86101331 23.5386829,4.46131704 C22.1389865,3.06162073 19.8696304,3.06162076 18.4699341,4.46131704 L17.1011039,5.83014717 C16.9558205,5.97543058 16.1941421,6.7354594 14.8145265,8.11177344 C14.4408273,8.48457838 13.835666,8.48385341 13.4628611,8.11015417 C13.0900561,7.73645497 13.0907811,7.13129379 13.4644799,6.75848836 C14.3833891,5.84177887 15.0284328,5.19820198 15.3991542,4.82821403 L17.1182677,3.10965073 C19.2644688,0.963449772 22.7441482,0.963449703 24.8903492,3.10965073 Z" fill={color} fillRule="evenodd" />
    </ResizeableSVG>
  );
};

Link.propTypes = propTypes;

export default Link;
