import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import map from 'lodash/map';
import { sprintf } from 'sprintf-js';
import reduce from 'lodash/reduce';
import assignIn from 'lodash/assignIn';
import size from 'lodash/size';
import without from 'lodash/without';
import union from 'lodash/union';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { TDrugModule, TrendMeasurementType } from '../../types';
import { ReactComponent as IconChevronDown } from '../assets/icons/ChevronDown.svg';
import { ReactComponent as IconChevronUp } from '../assets/icons/ChevronUp.svg';
import { ReactComponent as IconPerson } from '../assets/icons/Person.svg';
import { ReactComponent as IconPersonWalking } from '../assets/icons/PersonWalking.svg';
import { ReactComponent as IconFitnessThumbsUp } from '../assets/icons/FitnessThumbsUp.svg';
import { ReactComponent as IconFitnessHalfway } from '../assets/icons/FitnessHalfway.svg';
import { ReactComponent as IconFitnessThumbsUpSplash } from '../assets/icons/FitnessThumbsUpSplash.svg';
import { ReactComponent as IconFitnessDone } from '../assets/icons/FitnessDone.svg';
import { actions as dailySuggestions } from '../state/dailySuggestions';
import { selectors as trendIndicatorsSelectors } from '../state/trendIndicators';
import { contentModules } from '../../data/system-document.json';

import '../css/TestFitness.css';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRightHeader from '../components/DrawerRightHeader';
import LeaveDrugModuleSupportHoc from '../hocs/LeaveDrugModuleSupportHoc';
import Colors from '../theme/Colors';
import checkIsSupportedDrugModule from '../utils/checkIsSupportedDrugModule';
import DrugModuleBreadcrumbs from '../components/DrugModuleBreadcrumbs';
import config from '../config';
import Button from '../components/Button';
import { AppState } from '../state/reducers';
import { testFitnessIndicatorIds, vitalSignsIndicatorIds } from '../utils/idsData';
import { intervalTriggerIds } from '../utils/intervalTrigger';
import TrendIndicator, { Measurement } from '../components/TrendIndicator';
import Modal from '../components/Modal';
import NumericInput from '../components/NumericInput';
import RadioGroup from '../components/RadioGroup';

import {
  actions as testFitnessActions,
  TestFitnessQuestionnaire,
  selectors as testFitnessSelectors,
} from '../state/customTrends';

import CheckboxGroup from '../components/CheckboxGroup';
import getLocalizedText from '../utils/getLocalizedText';
import getUnixTime from '../utils/getUnixTime';

type Params = {
  drugModule: TDrugModule;
};

export type TestFitnessMeasurementKey = 'start' | 'end';

export type TestFitnessMeasurements = Record<TestFitnessMeasurementKey, Measurement>;

const DrawerRight = () => (
  <>
    <Drawer position="right">
      <section>
        <DrawerRightHeader />
        <section className="drawer-item" />
      </section>
    </Drawer>
  </>
);

const TestFitness = () => {
  const { t, i18n } = useTranslation();

  const { drugModule } = useParams<Params>();
  const dispatch = useDispatch();

  const [step, setStep] = useState(0);
  const [counter, setCounter] = useState<number>(config.walkTestFitnessCounterSetting);
  const [lapDistance, setLapDistance] = useState(0);
  const [laps, setLaps] = useState(0);
  const [startCounter, setStartCounter] = useState(false);
  const [measurements, setMeasurements] = useState<TestFitnessMeasurements>(
    { start: {}, end: {} },
  );

  const [date, setDate] = useState(
    { start: 0, end: 0 },
  );

  const [questionnaire, setQuestionnaire] = useState<TestFitnessQuestionnaire>({});
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [showVitalSigns, setShowVitalSigns] = useState(false);

  const [modal, setModal] = useState({ visible: false, name: '', description: '' });

  const questions = useMemo(() => get(contentModules, ['drug', drugModule, 'customTrends', 'testFitness', 'questions']), [drugModule]);

  const lastTestFitnessData = useSelector(
    (state: AppState) => testFitnessSelectors.getLastTestFitnessData(state, drugModule),
  );

  const showResultsButton = step === 0 && !isEmpty(lastTestFitnessData?.start);

  const showModal = (name: string, description: string): void => (
    setModal({ visible: true, name, description })
  );

  const hideModal = (): void => setModal({ visible: false, name: '', description: '' });

  const setTestFitnessMeasurements = useCallback((measurement: Measurement) => {
    const firstStep = step === 1;
    const fieldToUpdate = firstStep ? 'start' : 'end';

    setDate((prev) => ({ ...prev, [fieldToUpdate]: getUnixTime() }));

    setMeasurements((prev) => (
      { ...prev, [fieldToUpdate]: { ...prev[fieldToUpdate], ...measurement } }
    ));
  }, [step]);

  const isSupportedDrugModule = checkIsSupportedDrugModule(drugModule);

  const memoizedTrendIndicatorsGetByIdsSelector = useMemo(
    trendIndicatorsSelectors.makeGetByIds,
    [],
  );

  const indicators = useSelector(
    (state: AppState) => memoizedTrendIndicatorsGetByIdsSelector(
      state, testFitnessIndicatorIds[drugModule],
    ),
  );

  const vitalSignsIndicators = useSelector(
    (state: AppState) => memoizedTrendIndicatorsGetByIdsSelector(
      state, vitalSignsIndicatorIds[drugModule],
    ),
  );

  const toggleShowMode = () => {
    setShowMoreInfo((prev) => !prev);
  };

  const toggleVitalSigns = () => {
    setShowVitalSigns((prev) => !prev);
  };

  const intervalTriggerForceUpdate = useCallback(() => {
    if (step === 5) {
      dispatch(dailySuggestions.intervalTrigger(
        { ids: [intervalTriggerIds[drugModule].fitness], forceUpdate: true },
      ));
    }
  }, [drugModule, dispatch, step]);

  const getExplicitData = useCallback((data: Measurement) => reduce(
    data,
    (result, measurement: TrendMeasurementType, trendIndicatorId) => {
      if (measurement.changed) {
        return assignIn(result, { [trendIndicatorId]: measurement.value });
      }

      return result;
    },
    {},
  ), []);

  const addTrends = useCallback(() => {
    const trendDataStart = getExplicitData(measurements.start);
    const trendDataEnd = getExplicitData(measurements.end);

    dispatch(testFitnessActions.addTestFitnessData(
      {
        start: { measuredAt: date.start, explicitData: trendDataStart },
        end: { measuredAt: date.end, explicitData: trendDataEnd },
        drugModule,
        questionnaire,
      },
    ));

    intervalTriggerForceUpdate();
  }, [
    drugModule, date.end, date.start, dispatch, intervalTriggerForceUpdate,
    measurements.end, measurements.start, questionnaire,
  ]);

  const next = () => setStep(step + 1);

  const IconShowMore = showMoreInfo ? IconChevronUp : IconChevronDown;
  const IconVitalSigns = showVitalSigns ? IconChevronUp : IconChevronDown;

  const startTest = () => {
    setStep(4);
    setStartCounter(true);
  };

  const restartTest = () => {
    setStep(3);
    setStartCounter(false);
    setCounter(config.walkTestFitnessCounterSetting);
    setLaps(0);
  };

  const history = useHistory();

  const navigateToTestFitnessResults = useCallback(() => {
    history.replace(`/support-menu/${drugModule}/test-fitness-result`);
  }, [drugModule, history]);

  const handleClick = () => {
    const contentContainer = document.querySelector('.container')!;
    setShowVitalSigns(false);

    contentContainer.scrollTop = 0;

    if (step === 1) {
      // addTrends(true, measurements.start);
    }

    if (step === 3) {
      return startTest();
    }

    if (step === 4) {
      if (counter > 0) {
        return restartTest();
      }

      const data = {
        [testFitnessIndicatorIds[drugModule][2]]: {
          value: lapDistance,
          changed: true,
        },
        [testFitnessIndicatorIds[drugModule][3]]: {
          value: laps,
          changed: true,
        },
        [testFitnessIndicatorIds[drugModule][4]]: {
          value: lapDistance * laps,
          changed: true,
        },
      };

      setTestFitnessMeasurements(data);
    }

    if (step === 5) {
      addTrends();

      navigateToTestFitnessResults();
    }

    // Default
    return next();
  };

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (counter > 0) {
      timeout = setTimeout(() => {
        if (startCounter) {
          setCounter(counter - 1);
        }
      }, 1000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [counter, startCounter]);

  const checkIfMeasurementChanged = (
    field: TestFitnessMeasurementKey, indicatorId: string,
  ) => measurements[field]?.[indicatorId]?.changed;

  const checkIfRequiredMeasurementsChanged = (
    field: TestFitnessMeasurementKey,
  ) => checkIfMeasurementChanged(field, indicators[0].id)
      && checkIfMeasurementChanged(field, indicators[1].id);

  const vitalSigns = (
    <>
      <button type="button" className="row show-vital-button" onClick={toggleVitalSigns}>
        <p className="heading primary semi-bold">{t('testFitness:add-vital-signs')}</p>
        <IconVitalSigns fill={Colors.primary} className="icon small" />
      </button>
      {showVitalSigns && (
      <div className="trend-indicators">
        {map(vitalSignsIndicators, ((vitalSignsIndicator) => (
          <TrendIndicator
            key={vitalSignsIndicator.id}
            setTestFitnessMeasurements={setTestFitnessMeasurements}
            indicator={vitalSignsIndicator}
            showModal={showModal}
            dividers={false}
          />
        )))}
      </div>
      )}
    </>
  );

  let content = (
    <section className="test-fitness-content">
      <h4 className="heading primary"> {t('testFitness:your-walk-test')}</h4>
      <p>{t(`testFitness:${drugModule}:assess-your-fitness`)}</p>
      <p className="heading">{t('testFitness:before-start')}</p>
      <p>{`• ${t('testFitness:performed-in-corridor')}`}</p>
      <p>{`• ${t('testFitness:free-space')}`}</p>
      <p>{`• ${t('testFitness:lap-description')}`}</p>
      <p>{`• ${t('testFitness:measured-distance')}`}</p>
      <p>{`• ${t('testFitness:find-good-time')}`}</p>

      <button onClick={toggleShowMode} type="button" className="row center read-more-button">
        <p className="primary heading align-center">{t('read-more')}</p>
        <IconShowMore fill={Colors.primary} className="icon small" />
      </button>
      {showMoreInfo && (
      <div>
        <p className="primary heading"> {t('testFitness:more-information')}</p>
        <p className="prepare-walk-test">{t('testFitness:prepare-walk-test')}</p>
      </div>
      )}
    </section>
  );

  let buttonLabel = t('testFitness:start-test');
  let buttonDisabled = false;

  if (step === 1) {
    buttonLabel = t('next');

    buttonDisabled = !checkIfRequiredMeasurementsChanged('start');

    content = (
      <section className="test-fitness-content">
        <div className="trend-indicators">
          <p className="heading semi-bold">
            {`${t('step')} 1:`}
          </p>
          <p className="heading primary">
            {t('testFitness:before-start-test')}
          </p>
          <TrendIndicator
            key={indicators[0].id}
            setTestFitnessMeasurements={setTestFitnessMeasurements}
            indicator={indicators[0]}
            showModal={showModal}
            dividers={false}
          />
          <p className="heading primary">
            {t('testFitness:fill-exertion')}
          </p>
          <TrendIndicator
            key={indicators[1].id}
            setTestFitnessMeasurements={setTestFitnessMeasurements}
            indicator={indicators[1]}
            showModal={showModal}
            dividers={false}
          />
        </div>

        {vitalSigns}
      </section>
    );
  }

  if (step === 2) {
    buttonLabel = t('next');

    content = (
      <section className="test-fitness-content">
        <p className="heading semi-bold">
          {`${t('step')} 2:`}
        </p>
        <p className="heading semi-bold primary">
          {t('testFitness:route-length')}
        </p>
        <p>
          {t('testFitness:find-flat-surface')}
        </p>
        <div>
          <NumericInput
            labelText={t('testFitness:distance-in-meters')}
            unitLabelText="m"
            minValue={0}
            maxValue={100}
            step={0.5}
            value={lapDistance}
            onChange={setLapDistance}
            decimals={1}
            pristine={false}
            centeredHeader
          />
        </div>
      </section>
    );
  }

  if (step === 3) {
    const minutes = Math.floor(counter / 60);
    const seconds = counter - minutes * 60;

    buttonLabel = t('testFitness:start-test');

    content = (
      <section className="test-fitness-content">
        <div className="align-center">
          <p className="heading primary">
            {t('testFitness:start-when-ready')}
          </p>
          <IconPerson height={200} />
          <p className="heading walk-test-timer">{sprintf(`${t('time')} %02d:%02d`, minutes, seconds)}</p>
        </div>
      </section>
    );
  }

  if (step === 4) {
    const minutes = Math.floor(counter / 60);
    const seconds = counter - minutes * 60;

    buttonLabel = t('restart');

    if (counter === 0) {
      buttonLabel = t('next');
    }

    let text = t('testFitness:count-your-laps');
    let icon = <IconPersonWalking height={200} />;

    // 5 minute
    if (minutes === 4 && seconds >= 50 && seconds <= 59) {
      text = t('testFitness:one-minute-done');
      icon = <IconFitnessThumbsUp height={200} />;
    }

    // 4 minutes
    if (minutes === 3 && seconds >= 50 && seconds <= 59) {
      text = t('testFitness:third-of-the-way');
      icon = <IconFitnessThumbsUp height={200} />;
    }

    // 3 minutes
    if (minutes === 2 && seconds >= 50 && seconds <= 59) {
      text = t('testFitness:halfway');
      icon = <IconFitnessHalfway height={200} />;
    }

    // 2 minutes
    if (minutes === 1 && seconds >= 50 && seconds <= 59) {
      text = t('testFitness:doing-great');
      icon = <IconFitnessThumbsUp height={200} />;
    }

    // 1 minute
    if (minutes === 0 && seconds >= 50 && seconds <= 59) {
      text = t('testFitness:one-minute-to-go');
      icon = <IconFitnessThumbsUp height={200} />;
    }

    // 20 seconds
    if (minutes === 0 && seconds >= 10 && seconds <= 19) {
      text = t('testFitness:twenty-seconds');
      icon = <IconFitnessThumbsUpSplash height={200} />;
    }

    // done
    if (minutes === 0 && seconds === 0) {
      text = t('testFitness:you-are-done');
      icon = <IconFitnessDone height={200} />;
    }

    content = (
      <section className="test-fitness-content align-center">
        <p className="heading primary">{text}</p>
        {icon}
        <p className="heading walk-test-timer">{sprintf(`${t('time')} %02d:%02d`, minutes, seconds)}</p>
        <NumericInput
          labelText={t('testFitness:lap-counter')}
          unitLabelText={t('testFitness:laps')}
          minValue={0}
          maxValue={1000}
          step={1}
          value={laps}
          onChange={setLaps}
          pristine={false}
          centeredHeader
        />
      </section>
    );
  }

  if (step === 5 || step === 6) {
    buttonDisabled = !(checkIfRequiredMeasurementsChanged('end') && size(questionnaire) === size(questions));

    const distance = lapDistance * laps;

    buttonLabel = t('testFitness:save-test');

    const onChangeAnswer = (question: { id: string, answerType: string }) => (
      changedValue: string,
      checked?: boolean,
    ) => {
      if (question.answerType === 'multiple') {
        if (checked) {
          setQuestionnaire((prev) => (
            {
              ...prev,
              [question.id]: {
                answerIds: without(prev[question.id].answerIds, changedValue),
              },
            }));

          return;
        }

        setQuestionnaire((prev) => (
          {
            ...prev,
            [question.id]: {
              answerIds: union(prev[question.id].answerIds, [changedValue]),
            },
          }));

        return;
      }

      setQuestionnaire((prev) => (
        {
          ...prev,
          [question.id]: {
            answerIds: [changedValue],
          },
        }));
    };

    content = (
      <section className="test-fitness-content">
        <p className="heading primary">
          {t('testFitness:total-distance')}
        </p>
        <p className="heading">
          {`${distance} m`}
        </p>
        <p className="heading primary">
          {t('testFitness:well-done')}
        </p>
        <p>
          {t('testFitness:fill-current-trends')}
        </p>
        <div className="trend-indicators">
          <TrendIndicator
            key={indicators[0].id}
            setTestFitnessMeasurements={setTestFitnessMeasurements}
            indicator={indicators[0]}
            showModal={showModal}
            dividers={false}
          />
          <TrendIndicator
            key={indicators[1].id}
            setTestFitnessMeasurements={setTestFitnessMeasurements}
            indicator={indicators[1]}
            showModal={showModal}
            dividers={false}
          />
        </div>
        {vitalSigns}
        {map(questions, (question: any) => {
          const options = map(question.answers, (answer) => ({
            value: answer.id,
            labelText: getLocalizedText(answer, i18n.language, 'title'),
            description: getLocalizedText(answer, i18n.language, 'description'),
          }));

          return (
            <div>
              <p>
                {getLocalizedText(question, i18n.language, 'title')}
              </p>

              {
                question.answerType === 'single' && (
                  <RadioGroup
                    onChange={onChangeAnswer(question)}
                    options={options}
                    value={questionnaire?.[question.id]?.answerIds?.[0]}
                  />
                )
              }

              {
                question.answerType === 'multiple' && (
                  <CheckboxGroup
                    onChange={onChangeAnswer(question)}
                    options={options}
                    values={questionnaire?.[question.id]?.answerIds}
                  />
                )
              }

            </div>
          );
        })}
      </section>
    );
  }

  return (
    <article className="page test-fitness row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t(`contentModules:drug:${drugModule}:test-fitness`)} showRightButton />
          <DrugModuleBreadcrumbs drugModule={drugModule} />
          {isSupportedDrugModule ? content : null}
          <div className="test-fitness-button-wrapper">
            <Button
              size="medium"
              labelText={buttonLabel}
              disabled={buttonDisabled}
              onClick={handleClick}
            />
            {showResultsButton && (
            <Button
              size="medium"
              labelText={t('testFitness:view-my-results')}
              onClick={navigateToTestFitnessResults}
            />
            )}
          </div>
          <Modal
            visible={modal.visible}
            hideModal={hideModal}
            actions={[
              {
                title: t('close'),
                onClick: hideModal,
                primary: true,
              },
            ]}
          >
            <h1 className="heading">{modal.name}</h1>
            <p className="body">{modal.description}</p>
          </Modal>
        </section>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default LeaveDrugModuleSupportHoc(TestFitness);
