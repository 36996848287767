import find from 'lodash/find';
import map from 'lodash/map';
import toArray from 'lodash/toArray';
import size from 'lodash/size';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import slice from 'lodash/slice';
import orderBy from 'lodash/orderBy';
import concat from 'lodash/concat';
import uniq from 'lodash/uniq';
import includes from 'lodash/includes';
import isSameDay from 'date-fns/isSameDay';
import isSameWeek from 'date-fns/isSameWeek';
import isSameMonth from 'date-fns/isSameMonth';

import { AppState } from '../reducers';
import { PhotosState } from './types';
import { TDate, TPhotoReturn, TPhotosReturn } from '../../../types';

import EMPTY_OBJECT from '../../utils/empty-object';
import EMPTY_ARRAY from '../../utils/empty-array';
import toDate from '../../utils/toDate';
import createDeepEqualSelector from '../../selectors/createDeepEqualSelector';

const sort = (collection: TPhotosReturn | PhotosState): TPhotosReturn => (
  orderBy(collection, ['createdAt'], ['desc'])
);

const getByIdSelector = (state: PhotosState, id: string): TPhotoReturn => (
  find(state, { id }) || EMPTY_OBJECT
);

const makeGetById = () => (
  createDeepEqualSelector(
    (state: AppState) => state.photos,
    (state: any, id: string) => id,
    getByIdSelector,
  )
);

const getByIdsSelector = (
  state: PhotosState,
  ids: string[] | typeof EMPTY_ARRAY,
): TPhotosReturn => (
  sort(filter(state, (photo) => includes(ids, photo.id)))
);

const makeGetByIds = () => (
  createDeepEqualSelector(
    (state: AppState) => state.photos,
    (state: any, ids: string[] | typeof EMPTY_ARRAY) => ids,
    getByIdsSelector,
  )
);

const getByDateSelector = (state: PhotosState, date: TDate): TPhotosReturn => (
  sort(filter(state, (photo) => isSameDay(toDate(photo.createdAt), toDate(date))))
);

const makeGetByDate = () => (
  createDeepEqualSelector(
    (state: AppState) => state.photos,
    (state: any, date: TDate) => date,
    getByDateSelector,
  )
);

const getByWeekSelector = (state: PhotosState, date: TDate): TPhotosReturn => (
  sort(filter(state, (photo) => isSameWeek(toDate(photo.createdAt), toDate(date))))
);

const makeGetByWeek = () => (
  createDeepEqualSelector(
    (state: AppState) => state.photos,
    (state: any, date: TDate) => date,
    getByWeekSelector,
  )
);

const getByMonthSelector = (state: PhotosState, date?: TDate): TPhotosReturn => (
  sort(filter(state, (photo) => isSameMonth(toDate(photo.createdAt), toDate(date))))
);

const makeGetByMonth = () => (
  createDeepEqualSelector(
    (state: AppState) => state.photos,
    (state: any, date: TDate) => date,
    getByMonthSelector,
  )
);

const getSelector = (state: PhotosState, limit = 2): TPhotosReturn => (
  slice(sort(state), 0, limit)
);

const makeGet = () => (
  createDeepEqualSelector(
    (state: AppState) => state.photos,
    (state: any, limit: number) => limit,
    getSelector,
  )
);

const getLastUpdatedSelector = (state: PhotosState, limit = 2): TPhotosReturn => (
  // updatedAt has priority over createdAt when sorting
  slice(orderBy(state, [({ updatedAt, createdAt }) => updatedAt || createdAt], ['desc']), 0, limit)
);

const makeGetLastUpdated = () => (
  createDeepEqualSelector(
    (state: AppState) => state.photos,
    (state: any, limit: number) => limit,
    getLastUpdatedSelector,
  )
);

const getAllSelector = (state: PhotosState) => sort(toArray(state));

const getAll = createDeepEqualSelector(
  (state: AppState) => state.photos,
  getAllSelector,
);

const getAllByViewAndDateSelector = (state: PhotosState, view = 'all', date: TDate): TPhotosReturn => {
  switch (view) {
    case 'all': return getAllSelector(state);
    case 'day': return getByDateSelector(state, date);
    case 'week': return getByWeekSelector(state, date);
    case 'month': return getByMonthSelector(state, date);
    default:
  }

  return getAllSelector(state);
};

const makeGetAllByViewAndDate = () => (
  createDeepEqualSelector(
    (state: AppState) => state.photos,
    (state: any, view: string) => view,
    (state: any, view: string, date: TDate) => date,
    getAllByViewAndDateSelector,
  )
);

const getAllUrisSelector = (state: PhotosState) => (
  uniq(compact(concat(map(state, 'uri'), map(state, 'source.uri'))))
);

const getAllUris = createDeepEqualSelector(
  (state: AppState) => state.photos,
  getAllUrisSelector,
);

const countSelector = (state: PhotosState) => size(state);

const count = createDeepEqualSelector(
  (state: AppState) => state.photos,
  countSelector,
);

export {
  makeGetById,
  makeGetByIds,
  makeGetByDate,
  makeGetByWeek,
  makeGetByMonth,
  makeGet,
  makeGetLastUpdated,
  getAll,
  makeGetAllByViewAndDate,
  getAllUris,
  count,
};
