import { Reducer } from 'redux';

import * as utils from './utils';
import * as actionTypes from './actionTypes';
import {
  ProfileQuestionCategoriesState,
  ProfileQuestionCategoriesActionTypes,
  ProfileQuestionCategoriesReplaceActionType,
} from './types';

import { profileQuestionCategories } from '../../../data/system-document.json';

const initialState = profileQuestionCategories as ProfileQuestionCategoriesState;

const reducer: Reducer<
ProfileQuestionCategoriesState,
ProfileQuestionCategoriesActionTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REPLACE: {
      return utils.replace(state, action as ProfileQuestionCategoriesReplaceActionType);
    }
    case actionTypes.CLEAR: return utils.clear();
    default: return state;
  }
};

export default reducer;
