const prefix = 'QUESTIONNAIRES';

const ADD = `${prefix}/ADD`;
const ADD_QUESTIONS = `${prefix}/ADD_QUESTIONS`;
const ADD_QUESTION_PHOTOS = `${prefix}/ADD_QUESTION_PHOTOS`;
const ADD_QUESTION_AUDIO_RECORDINGS = `${prefix}/ADD_QUESTION_AUDIO_RECORDINGS`;
const REMOVE = `${prefix}/REMOVE`;
const REMOVE_QUESTIONS = `${prefix}/REMOVE_QUESTIONS`;
const REMOVE_QUESTION_PHOTOS = `${prefix}/REMOVE_QUESTION_PHOTOS`;
const REMOVE_QUESTION_AUDIO_RECORDINGS = `${prefix}/REMOVE_QUESTION_AUDIO_RECORDINGS`;
const UPDATE = `${prefix}/UPDATE`;
const UPDATE_QUESTIONS = `${prefix}/UPDATE_QUESTIONS`;
const REPLACE_QUESTIONS = `${prefix}/REPLACE_QUESTIONS`;
const CLEAR = `${prefix}/CLEAR`;
const CHECK_QUESTION = `${prefix}/CHECK_QUESTION`;
const UNCHECK_QUESTION = `${prefix}/UNCHECK_QUESTION`;

export {
  ADD,
  ADD_QUESTIONS,
  ADD_QUESTION_PHOTOS,
  ADD_QUESTION_AUDIO_RECORDINGS,
  REMOVE,
  REMOVE_QUESTIONS,
  REMOVE_QUESTION_PHOTOS,
  REMOVE_QUESTION_AUDIO_RECORDINGS,
  UPDATE,
  UPDATE_QUESTIONS,
  REPLACE_QUESTIONS,
  CLEAR,
  CHECK_QUESTION,
  UNCHECK_QUESTION,
};
