import * as actionTypes from './actionTypes';
import { TContact } from '../../../types';
import {
  ContactsActionTypes,
  ContactsAddPhotosPayload,
  ContactsRemovePhotosPayload,
} from './types';

const add = (payload: TContact[]): ContactsActionTypes => ({
  type: actionTypes.ADD,
  payload,
});

const addPhotos = (payload: ContactsAddPhotosPayload): ContactsActionTypes => ({
  type: actionTypes.ADD_PHOTOS,
  payload,
});

const remove = (payload: string[]): ContactsActionTypes => ({
  type: actionTypes.REMOVE,
  payload,
});

const removePhotos = (
  payload: ContactsRemovePhotosPayload,
): ContactsActionTypes => ({
  type: actionTypes.REMOVE_PHOTOS,
  payload,
});

const update = (payload: TContact[]): ContactsActionTypes => ({
  type: actionTypes.UPDATE,
  payload,
});

const clear = (): ContactsActionTypes => ({
  type: actionTypes.CLEAR,
});

export {
  add,
  addPhotos,
  remove,
  removePhotos,
  update,
  clear,
};
