import { TState, TActions } from './types';

import * as actionTypes from './actionTypes';

const reducer = (state: TState, action: TActions): TState => {
  switch (action.type) {
    case actionTypes.UPDATE: {
      const { payload } = action;

      if (!payload) {
        return state;
      }

      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
