import React, { useState, useEffect } from 'react';
import map from 'lodash/map';
import { useTranslation } from 'react-i18next';
import useDimensions from 'react-cool-dimensions';
import subWeeks from 'date-fns/subWeeks';
import addWeeks from 'date-fns/addWeeks';
import subMonths from 'date-fns/subMonths';
import addMonths from 'date-fns/addMonths';
import subYears from 'date-fns/subYears';
import addYears from 'date-fns/addYears';
import startOfDay from 'date-fns/startOfDay';

import endOfDay from 'date-fns/endOfDay';
import { TDrugModule, TrendIndicatorType } from '../../types';

import getLocalizedText from '../utils/getLocalizedText';
import dateFormat from '../utils/dateFormat';
import { ReactComponent as IconChevronLeft } from '../assets/icons/ChevronLeft.svg';
import { ReactComponent as IconChevronRight } from '../assets/icons/ChevronRight.svg';
import Colors from '../theme/Colors';
import TrendsShareModal from './TrendsShareModal';
import CombinedTestFitnessTrendGraph from './TrendGraph/CombinedTestFitnessTrendGraph';

type ListProps = {
  trendIndicators: TrendIndicatorType[];
  view?: string;
  shareModalVisible?: boolean,
  hideShareModal?: () => void
  combined?: boolean
  drugModule: TDrugModule
};

const TestFitnessTrendList = (props: ListProps) => { // TODO: add HOC for all trend lists
  const {
    trendIndicators,
    view,
    shareModalVisible = false,
    combined = false,
    hideShareModal = () => {},
    drugModule,
  } = props;

  const { i18n } = useTranslation();

  const { observe, width } = useDimensions<HTMLElement | null>();

  const tilePadding = 8;
  const tileBorder = 1;

  const minWidth = 288;

  const minTileWidth = minWidth + (tilePadding * 2) + (tileBorder * 2);

  // graphWith can not be negative
  const containerWidth = width || 100;

  let graphWidth = containerWidth - (tileBorder * 2);

  if ((containerWidth / minTileWidth) > 2) {
    graphWidth = (containerWidth / 2) - (tilePadding * 2) - (tileBorder * 2);
  }

  if ((containerWidth / minTileWidth) > 3) {
    graphWidth = (containerWidth / 3) - (tilePadding * 2) - (tileBorder * 2);
  }

  const now = new Date();

  let initialStartDate;

  if (view === 'month') {
    initialStartDate = startOfDay(subMonths(now, 1));
  } else if (view === 'year') {
    initialStartDate = startOfDay(subYears(now, 1));
  } else {
    initialStartDate = startOfDay(subWeeks(now, 1));
  }

  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(endOfDay(now));

  const shareIndicators = trendIndicators.map((indicator) => indicator.id);

  useEffect(() => {
    let newStartDate;

    if (view === 'month') {
      newStartDate = startOfDay(subMonths(now, 1));
    } else if (view === 'year') {
      newStartDate = startOfDay(subYears(now, 1));
    } else {
      newStartDate = startOfDay(subWeeks(now, 1));
    }

    setStartDate(newStartDate);
    setEndDate(startOfDay(now));
  }, [view]);

  const handleBackClick = () => {
    let sub = subWeeks;

    if (view === 'month') {
      sub = subMonths;
    } else if (view === 'year') {
      sub = subYears;
    }

    setStartDate(sub(startDate, 1));
    setEndDate(sub(endDate, 1));

    return true;
  };

  const handleForwardClick = () => {
    let add = addWeeks;

    if (view === 'month') {
      add = addMonths;
    } else if (view === 'year') {
      add = addYears;
    }

    setStartDate(add(startDate, 1));
    setEndDate(add(endDate, 1));

    return true;
  };

  const customFormat = 'd MMM yyyy';

  return (
    <>
      <header className="diary-header center">
        <button type="button" onClick={handleBackClick}>
          <IconChevronLeft
            fill={Colors.primary}
            className="icon small"
          />
        </button>
        <span className="button accent">
          {dateFormat(i18n.language, startDate, customFormat)}
          &nbsp;-&nbsp;
          {dateFormat(i18n.language, endDate, customFormat)}
        </span>
        <button type="button" onClick={handleForwardClick}>
          <IconChevronRight
            fill={Colors.primary}
            className="icon small"
          />
        </button>
      </header>
      <section className="trends-container" ref={observe}>
        {combined ? (
          <CombinedTestFitnessTrendGraph
            startDate={startDate}
            endDate={endDate}
            xPadding={30}
            yPadding={20}
            width={containerWidth}
            height={400}
            trendIndicatorList={trendIndicators}
            drugModule={drugModule}
            combined={combined}
          />
        )
          : map(trendIndicators, (item) => (
            <section key={item.id} className="trend-tile">
              <h1 className="button">{getLocalizedText(item.title, i18n.language)}</h1>
              <CombinedTestFitnessTrendGraph
                startDate={startDate}
                endDate={endDate}
                xPadding={30}
                yPadding={20}
                width={graphWidth - (tilePadding * 2)}
                height={240}
                trendIndicatorList={[item]}
                drugModule={drugModule}
                combined={combined}

              />
            </section>
          ))}
      </section>

      <TrendsShareModal
        type="id"
        visible={shareModalVisible}
        hideModal={hideShareModal}
        data={{ trends: shareIndicators }}
      />
    </>
  );
};

export default TestFitnessTrendList;
