import { Reducer } from 'redux';

import * as utils from './utils';
import * as actionTypes from './actionTypes';
import {
  PushNotificationState,
  PushNotificationActionTypes,
  PushNotificationAddActionType,
  PushNotificationRemoveActionType,
  PushNotificationSetReadAtActionType,
} from './types';

const initialState: PushNotificationState = {};

// eslint-disable-next-line max-len
const reducer: Reducer<PushNotificationState, PushNotificationActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD: {
      return utils.add(state, action as PushNotificationAddActionType);
    }
    case actionTypes.REMOVE: {
      return utils.remove(state, action as PushNotificationRemoveActionType);
    }
    case actionTypes.SET_READAT: {
      return utils.setReadAt(state, action as PushNotificationSetReadAtActionType);
    }
    case actionTypes.CLEAR: return utils.clear();
    default: return state;
  }
};

export default reducer;
