/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useHistory } from 'react-router-dom';
import arrayMove from 'array-move';
import map from 'lodash/map';
import size from 'lodash/size';
import find from 'lodash/find';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';

import { TrendIndicatorType } from 'types';
import { AppState } from '../state/reducers';
import Navigation from '../components/Navigation';
import DrawerButtons from '../components/DrawerButtons';
import Drawer from '../components/Drawer';
import DrawerRightHeader from '../components/DrawerRightHeader';
import SettingTrendIndicatorListItem from '../components/SettingTrendIndicatorListItem';
import Footer from '../components/Footer';
import Button from '../components/Button';
import { selectors as trendIndicatorsSelectors } from '../state/trendIndicators';
import getAllTrendIndicatorsSelector from '../selectors/getAllTrendIndicators';
import { ReactComponent as IconArrowLeft } from '../assets/icons/ArrowLeft.svg';
import { ReactComponent as IconArrowRight } from '../assets/icons/ArrowRight.svg';
import Colors from '../theme/Colors';
import { actions as settingsActions } from '../state/settings';
import LeaveDrugModuleSupportHoc from '../hocs/LeaveDrugModuleSupportHoc';

type DragResult = {
  source: {
    index: number
  };
  destination?: {
    index: number
  };
};

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

type Props = {
  isSideEffect?: boolean
  requiredList?: string[]
};
const TrendsSettings = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const history = useHistory();

  const historyState = history?.location?.state as Props || {};
  const { isSideEffect, requiredList } = historyState;

  const indicators = useSelector(
    (state: AppState) => getAllTrendIndicatorsSelector(state),
  ) as TrendIndicatorType[];

  const memoizedTrendIndicatorsGetByIdsSelector = useMemo(
    trendIndicatorsSelectors.makeGetByIds,
    [],
  );

  const drugModuleTrendIndicators = useSelector(
    (state: AppState) => (
      memoizedTrendIndicatorsGetByIdsSelector(state, requiredList || [])
    ),
  ) as TrendIndicatorType[];

  const getCurrentOrder = (trendId: string) => {
    const res = find(indicators, (item) => item.id === trendId)?.order;
    return res;
  };

  const onDragEnd = (result: DragResult) => {
    // Ignore when dropped outside the list
    if (!result.destination) {
      return false;
    }
    const data = arrayMove(
      indicators,
      result.source.index,
      result.destination.index,
    );

    const dataIds = map(data, 'id');
    const indicatorIds = map(indicators, 'id');

    // Only update order when there is a difference
    if (shallowEqual(dataIds, indicatorIds)) {
      return false;
    }

    return dispatch(settingsActions.trendIndicatorsUpdateOrder(dataIds));
  };

  return (
    <article className="page trends-settings row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={isSideEffect ? t('side-effect-settings') : t('trends-settings')} />
          <header className="settings-header">
            <div className="row center">
              <IconArrowLeft fill={Colors.primary} className="icon large" />
              <p className="body">{t('trends:settings-favorites')}</p>
            </div>
            <div className="row center">
              <p className="body">{t('trends:settings-track')}</p>
              <IconArrowRight fill={Colors.primary} className="icon large" />
            </div>
          </header>
          <section className="list">
            {!isEmpty(drugModuleTrendIndicators) ? (
              <DragDropContext onDragEnd={() => {}}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {map(drugModuleTrendIndicators, (item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                          isDragDisabled={isSideEffect}
                        >
                          {(p) => (
                            <div
                              ref={p.innerRef}
                              {...p.draggableProps}
                              {...p.dragHandleProps}
                            >
                              <SettingTrendIndicatorListItem
                                key={item.id}
                                indicator={item}
                                divider={(index + 1) !== size(drugModuleTrendIndicators)}
                                isSideEffect={isSideEffect}
                                isRequired
                                currentOrder={getCurrentOrder(item.id)}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : null}
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {map(indicators, (item, index) => (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(p) => {
                          const isRequired = includes(requiredList, item.id);
                          return (
                            <div
                              ref={p.innerRef}
                              {...p.draggableProps}
                              {...p.dragHandleProps}
                            >
                              <SettingTrendIndicatorListItem
                                key={item.id}
                                indicator={item}
                                divider={(index + 1) !== size(indicators)}
                                dividerTop={index === 1}
                                isSideEffect={isSideEffect}
                                isRequired={isRequired}
                                disabled={isRequired}
                              />
                            </div>
                          );
                        }}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </section>
          <Button
            labelText={t('save')}
            size="medium"
            onClick={() => history.goBack()}
          />
        </section>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default LeaveDrugModuleSupportHoc(TrendsSettings);
