import iconFontIonicons from 'react-native-vector-icons/Fonts/Ionicons.ttf';
import iconFontFontAwseome from 'react-native-vector-icons/Fonts/FontAwesome.ttf';

const iconFontStyles = `
  @font-face {
    src: url(${iconFontIonicons});
    font-family: Ionicons;
  }

  @font-face {
    src: url(${iconFontFontAwseome});
    font-family: FontAwesome;
  }
`;

// Create stylesheet
const style = document.createElement('style');

style.type = 'text/css';

if (style.styleSheet) {
  style.styleSheet.cssText = iconFontStyles;
} else {
  style.appendChild(document.createTextNode(iconFontStyles));
}

// Inject stylesheet
document.head.appendChild(style);
