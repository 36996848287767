import replace from 'lodash/replace';
import split from 'lodash/split';
import toLower from 'lodash/toLower';
import toUpper from 'lodash/toUpper';

const formatLocale = (locale: string) => {
  const [language, territory] = split(replace(locale, '_', '-'), '-');

  return territory ? `${toLower(language)}-${toUpper(territory)}` : toLower(language);
};

export default formatLocale;
