import { Reducer } from 'redux';

import * as utils from './utils';
import * as actionTypes from './actionTypes';
import {
  ProfileQuestionsState,
  ProfileQuestionsActionTypes,
  ProfileQuestionsReplaceActionType,
} from './types';

import { profileQuestions } from '../../../data/system-document.json';

const initialState = profileQuestions as ProfileQuestionsState | any; // TODO: remove any after going life with AZ

const reducer: Reducer<
ProfileQuestionsState,
ProfileQuestionsActionTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REPLACE: {
      return utils.replace(state, action as ProfileQuestionsReplaceActionType);
    }
    case actionTypes.CLEAR: return utils.clear();
    default: return state;
  }
};

export default reducer;
