/* eslint-disable max-len */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import includes from 'lodash/includes';

import { TDrugModule } from '../../types';

import '../css/Consent.css';
import DrugModuleLeaveModal from '../components/DrugModuleLeaveModal';
import { selectors as tagsSelectors } from '../state/tags';
import { AppState } from '../state/reducers';

type Params = {
  drugModule: TDrugModule;
};

const LeaveDrugModuleHoc = (
  OriginalComponent: React.FunctionComponent,
) => {
  const { drugModule } = useParams<Params>();

  const [triggerExit, setTriggerExit] = useState({
    onOk: false,
    path: '',
  });
  const [openedLeaveModal, setOpenedLeaveModal] = useState(false);

  const history = useHistory();

  const toggleLeaveModal = () => {
    setOpenedLeaveModal((prev) => !prev);
  };

  const onPressYes = () => {
    setTriggerExit((prev) => ({
      ...prev,
      onOk: true,
    }));
    toggleLeaveModal();
  };

  const onPressNo = () => {
    setTriggerExit((prev) => ({
      ...prev,
      path: '',
    }));
    toggleLeaveModal();
  };

  const handleGoToIntendedPage = useCallback(
    (location:any) => history.push(location),
    [history],
  );

  const memoizedTagsMakeGetValueByKey = useMemo(
    tagsSelectors.makeGetValueByKey,
    [],
  );

  const drugModules = useSelector(
    (state: AppState) => memoizedTagsMakeGetValueByKey(state, 'drugModules'),
  ) as unknown as TDrugModule[];

  useEffect(() => {
    if (triggerExit.onOk) {
      handleGoToIntendedPage(triggerExit.path);
    }
    // @ts-ignore
    const unblock = history.block((location) => {
      if (!includes(location.pathname, '/support-menu/')) {
        setOpenedLeaveModal(true);
        setTriggerExit((obj) => ({ ...obj, path: location.pathname }));
        return triggerExit.onOk;
      }
      return true;
    });

    if (!includes(history.location.pathname, `/support-menu/${drugModule}/fill-in-side-effects/settings`)) {
      history.replace({ ...history.location, state: '/support-menu/' });
    }

    return () => {
      unblock();
    };
  }, [handleGoToIntendedPage, history, triggerExit.onOk, triggerExit.path]);

  useEffect(() => {
    if (!includes(drugModules, drugModule)) {
      setTriggerExit({ onOk: true, path: '/' });
    }
  }, [drugModules, drugModule]);

  return (
    <>
      <OriginalComponent />
      <DrugModuleLeaveModal
        drugModule={drugModule}
        openedCohortLeaveModal={openedLeaveModal}
        toggleCohortLeaveModal={toggleLeaveModal}
        onPressYes={onPressYes}
        onPressNo={onPressNo}
      />
    </>
  );
};

const LeaveDrugModuleSupportHoc = (
  OriginalComponent: React.FunctionComponent,
) => {
  const history = useHistory();
  const isTrendSettings = includes(history.location.pathname, '/trends/settings');

  return (
    <>
      {isTrendSettings ? <OriginalComponent /> : LeaveDrugModuleHoc(OriginalComponent)}
    </>
  );
};

export default (OriginalComponent: React.FunctionComponent) => () => LeaveDrugModuleSupportHoc(
  OriginalComponent,
);
