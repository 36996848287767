import map from 'lodash/map';
import { useSelector } from 'react-redux';
import { TContentModule } from 'types';
import { AppState } from '../state/reducers';
import { selectors as settingsSelectors } from '../state/settings';

const useGetLinkedDrugModulesByCode = (inviteCode: string) => {
  const drugContentModule = useSelector(
    (state: AppState) => settingsSelectors.getDrugContentModules(state),
  );

  const linkedDrugArr: TContentModule[] = [];
  map(drugContentModule, (value, key) => {
    if (value.invitationCode === inviteCode) {
      linkedDrugArr.push(key as TContentModule);
    }
  });

  return linkedDrugArr;
};

export default useGetLinkedDrugModulesByCode;
