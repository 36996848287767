import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import map from 'lodash/map';
import first from 'lodash/first';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import filter from 'lodash/filter';
import startsWith from 'lodash/startsWith';
import toUpper from 'lodash/toUpper';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import { useTranslation } from 'react-i18next';

import { GlossaryReturnType } from 'types';
import { AppState } from '../state/reducers';

import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRightHeader from '../components/DrawerRightHeader';
import { selectors as glossarySelectors } from '../state/glossary';
import { ReactComponent as IconClose } from '../assets/icons/Close.svg';
import Colors from '../theme/Colors';
import '../css/Glossary.css';

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const Glossary = () => {
  const { i18n, t } = useTranslation();

  const [wordFilter, setWordFilter] = useState('');
  const [query, setQuery] = useState('');

  const glossary = useSelector(
    (state: AppState) => glossarySelectors.getByLocale(state, i18n.language),
  );

  const letters = sortBy(uniq(map(glossary, ({ word }) => toUpper(first(word)))));

  let words = glossary;

  if (query) {
    words = filter(glossary, (item) => (
      toUpper(item.word).includes(toUpper(query))
          || toUpper(item.description).includes(toUpper(query))
    ));
  }

  if (!isEmpty(wordFilter)) {
    const queryFilteredWords: GlossaryReturnType = [...words];
    let tempWords: GlossaryReturnType;
    if (query) {
      tempWords = filter(queryFilteredWords, ({ word }) => startsWith(toUpper(word), wordFilter));
      words = filter(tempWords, ({ word }) => startsWith(toUpper(word), wordFilter));
    } else {
      words = filter(glossary, ({ word }) => startsWith(toUpper(word), wordFilter));
    }
  }

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.currentTarget.value);

    if (wordFilter) {
      setWordFilter('');
    }
  };

  return (
    <article className="page glossary row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('glossary')} showRightButton />
          <section className="search">
            <input
              value={query}
              placeholder={t('search')}
              onChange={handleQueryChange}
            />
            {
                query
                  ? (
                    <button type="button" onClick={() => setQuery('')}>
                      <IconClose fill={Colors.lightGray} className="icon small" />
                    </button>
                  )
                  : null
              }
          </section>
          <section className="letters">
            {map(letters, (letter) => (
              letter === wordFilter
                ? <button className="button contrast active" type="button" key={letter} onClick={() => setWordFilter(letter)}>{letter}</button>
                : <button className="button contrast" type="button" key={letter} onClick={() => setWordFilter(letter)}>{letter}</button>
            ))}
            <button className={`button contrast ${isEmpty(wordFilter) ? 'active' : null}`} type="button" onClick={() => setWordFilter('')}>*</button>
          </section>
          <section className="list">
            {map(words, ({ word, description }, index) => (
              <>
                <section key={word} className="word">
                  <h2 className="heading">{word}</h2>
                  <p className="body">{description}</p>
                </section>
                {(index + 1) !== size(words) ? <hr className="divider" /> : null}
              </>
            ))}
          </section>
        </section>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default Glossary;
