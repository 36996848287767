import { Reducer } from 'redux';

import * as utils from './utils';
import * as actionTypes from './actionTypes';
import {
  TrendsState,
  TrendsActionTypes,
  TrendsAddActionType,
  TrendsClearDrugModuleActionType,
} from './types';

const initialState: TrendsState = [];

const reducer: Reducer<TrendsState, TrendsActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD: return utils.add(state, action as TrendsAddActionType);
    case actionTypes.CLEAR: return utils.clear();
    case actionTypes.CLEAR_DRUG_MODULE: return utils.clearDrugModuleTrends(
      state, action as TrendsClearDrugModuleActionType,
    );
    default: return state;
  }
};

export default reducer;
