import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import map from 'lodash/map';
import find from 'lodash/find';
import { AppState } from '../state/reducers';
import { actions as settingsActions } from '../state/settings';
import { selectors as hospitalsSelectors } from '../state/hospitals';
import { selectors as hospitalAccessCodesSelectors, actions as hospitalAccessCodesActions } from '../state/hospitalAccessCodes';

const useDeleteHospitalCodeWithRelations = () => {
  const dispatch = useDispatch();
  const hospitals = useSelector((state: AppState) => hospitalsSelectors.getAll(state));
  const hospitalAccessCodes = (
    useSelector((state: AppState) => hospitalAccessCodesSelectors.getAll(state), shallowEqual)
  );
  const deleteHospitalCodeWithRelations = (hospitalCode: string) => {
    const hospitalId = find(hospitals, (item) => item.hospitalGroup === hospitalCode);
    map(hospitalAccessCodes, (item) => {
      if (item.hospitalId === hospitalId.id) {
        dispatch(hospitalAccessCodesActions.remove([item.id]));
      }
    });

    dispatch(settingsActions.hospitalGroupAccessCodesRemove([hospitalCode]));
  };

  return { deleteHospitalCodeWithRelations };
};

export default useDeleteHospitalCodeWithRelations;
