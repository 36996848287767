const prefix = 'HOSPITALS';

const ADD = `${prefix}/ADD`;
const REPLACE = `${prefix}/REPLACE`;
const CLEAR = `${prefix}/CLEAR`;

export {
  ADD,
  REPLACE,
  CLEAR,
};
