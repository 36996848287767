import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import clone from 'lodash/clone';
import {
  TestFitnessState,
  TestFitnessAddActionType,
  TestFitnessClearActionType,
} from './types';

const addTestFitnessData = (state: TestFitnessState, action: TestFitnessAddActionType) => {
  const {
    payload: {
      drugModule,
      start,
      end,
      questionnaire,
    },
  } = action;

  const newDrugModuleState = clone(state[drugModule]);

  newDrugModuleState.push({
    start,
    end,
    questionnaire,
  });

  return { ...state, [drugModule]: newDrugModuleState };
};

const clearDrugModuleTrends = (state: TestFitnessState, action: TestFitnessClearActionType) => {
  const { payload } = action;

  if (isEmpty(payload)) {
    return state;
  }

  const newState = omit(state, [payload]) as TestFitnessState;

  newState[payload] = [];

  return newState;
};

export {
  addTestFitnessData,
  clearDrugModuleTrends,
};
