import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { AppState } from '../state/reducers';

import { selectors as contactsSelectors } from '../state/contacts';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRightHeader from '../components/DrawerRightHeader';
import Contact from '../components/Contact';

type Params = {
  contactId: string;
};

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const ContactScreen = () => {
  const { t } = useTranslation();

  const { contactId } = useParams<Params>();

  const memoizedContactsGetByIdSelector = useMemo(
    contactsSelectors.makeGetById,
    [],
  );

  const contact = useSelector(
    (state: AppState) => memoizedContactsGetByIdSelector(state, contactId),
  );

  return (
    <article className="page contact row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('contact')} showRightButton />
          <Contact contact={contact} />
        </section>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default ContactScreen;
