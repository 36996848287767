import { Reducer } from 'redux';

import * as utils from './utils';
import * as actionTypes from './actionTypes';
import {
  DailySuggestionsState,
  DailySuggestionsActionTypes,
  DailySuggestionsAddActionType,
  DailySuggestionsUpdateActionType,
  DailySuggestionsClearDrugModuleActionType,
} from './types';

const initialState: DailySuggestionsState = {};

const reducer: Reducer<DailySuggestionsState, DailySuggestionsActionTypes> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.ADD: return utils.add(state, action as DailySuggestionsAddActionType);
    case actionTypes.UPDATE: return utils.update(state, action as DailySuggestionsUpdateActionType);
    case actionTypes.CLEAR: return utils.clear();
    case actionTypes.CLEAR_DRUG_MODULE: return utils.clearDrugModuleSuggestions(
      state, action as DailySuggestionsClearDrugModuleActionType,
    );
    case actionTypes.CHECK:
    case actionTypes.TRIGGER:
    default: return state;
  }
};

export default reducer;
