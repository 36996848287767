import React from 'react';
import { useTranslation } from 'react-i18next';
import size from 'lodash/size';

import { QuestionnaireType } from '../../types';

import ListItem from './ListItem';
import tWithPlurals from '../utils/tWithPlurals';
import { ReactComponent as IconQuestionnaire } from '../assets/icons/Questionnaire.svg';
import { ReactComponent as IconChevronRight } from '../assets/icons/ChevronRight.svg';
import Colors from '../theme/Colors';

type Props = {
  questionnaire: QuestionnaireType;
  divider?: boolean;
  dividerOffset?: number;
};

const QuestionnaireListItem = (props: Props) => {
  const { t } = useTranslation();

  const { questionnaire, divider = true, dividerOffset = 0 } = props;

  const secondaryText = tWithPlurals(t, 'questionnaire-questions-count', size(questionnaire.questions));

  return (
    <ListItem
      key={questionnaire.id}
      primaryText={questionnaire.title}
      secondaryText={secondaryText}
      leftIcon={<IconQuestionnaire fill={Colors.primary} className="icon" />}
      rightIcon={<IconChevronRight fill={Colors.primary} className="icon small" />}
      divider={divider}
      dividerOffset={dividerOffset}
      to={`/questionnaires/${questionnaire.id}`}
    />
  );
};

export default QuestionnaireListItem;
