import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { AppState } from '../state/reducers';
import { selectors as hospitalsSelectors } from '../state/hospitals';
import { selectors as contactsSelectors } from '../state/contacts';
import { selectors as hospitalAccessCodesSelectors } from '../state/hospitalAccessCodes';
import ListItem from '../components/ListItem';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRightHeader from '../components/DrawerRightHeader';
import Button from '../components/Button';
import { HospitalAccessCodeType } from '../../types';
import defaultGet from '../utils/defaultGet';
import dateFormat from '../utils/dateFormat';
import { ShareDataIcon, ForwardIcon } from '../theme/Icons';

type HospitalAccessCodeListItemProps = {
  hospitalAccessCode: HospitalAccessCodeType;
  divider?: boolean;
  dividerOffset?: number;
};

const HospitalAccessCodeListItem = (props: HospitalAccessCodeListItemProps) => {
  const {
    hospitalAccessCode,
    divider = true,
    dividerOffset = 0,
  } = props;

  const memoizedHospitalsGetByIdSelector = useMemo(
    hospitalsSelectors.makeGetById,
    [],
  );

  const hospital = useSelector(
    (state: AppState) => memoizedHospitalsGetByIdSelector(state, hospitalAccessCode.hospitalId),
  );

  const { i18n } = useTranslation();

  return (
    <ListItem
      to={`/share-my-data/${hospitalAccessCode.id}`}
      primaryText={defaultGet(hospital, 'name', '')}
      secondaryText={dateFormat(i18n.language, hospitalAccessCode.createdAt)}
      leftIcon={ShareDataIcon}
      rightIcon={ForwardIcon}
      divider={divider}
      dividerOffset={dividerOffset}
    />
  );
};

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const ShareMyData = () => {
  const { t } = useTranslation();

  const contacts = useSelector((state: AppState) => contactsSelectors.getAll(state));

  const hospitalAccessCodes = (
    useSelector((state: AppState) => hospitalAccessCodesSelectors.getAll(state))
  );

  const history = useHistory();

  return (
    <article className="page contacts row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('share-my-data-subtitle')} showRightButton />
          <section className={classNames('list', { empty: isEmpty(contacts) })}>
            { !isEmpty(hospitalAccessCodes)
              ? map(hospitalAccessCodes, (hospitalAccessCode, index) => (
                <HospitalAccessCodeListItem
                  key={hospitalAccessCode.id}
                  hospitalAccessCode={hospitalAccessCode}
                  divider={(index + 1 !== size(hospitalAccessCodes))}
                />
              ))
              : <p className="body light">{t('shareMyData:empty-state-heading')}</p>}
          </section>
          <Button
            labelText={`+ ${t('share-my-data')}`}
            size="medium"
            onClick={() => history.push('/share-my-data/add')}
          />
        </section>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default ShareMyData;

// ---------------------------------------------  Previous component with old design ------------------------------------------------- //
// import React, { useMemo } from 'react';
// import { useSelector } from 'react-redux';
// import { useTranslation } from 'react-i18next';
// import { Link } from 'react-router-dom';
// import map from 'lodash/map';
// import isEmpty from 'lodash/isEmpty';

// import '../css/ShareMyData.css';
// import Header from '../components/Header';
// import PageHeader from '../components/PageHeader';
// import Footer from '../components/Footer';
// import ListItem from '../components/ListItem';
// import { AppState } from '../state/reducers';
// import { selectors as hospitalsSelectors } from '../state/hospitals';
// import { selectors as hospitalAccessCodesSelectors } from '../state/hospitalAccessCodes';
// import { HospitalAccessCodeType } from '../../types';
// import defaultGet from '../utils/defaultGet';
// import dateFormat from '../utils/dateFormat';
// import { ShareDataIcon } from '../theme/Icons';

// type HospitalAccessCodeListItemProps = {
//   hospitalAccessCode: HospitalAccessCodeType;
// };

// const HospitalAccessCodeListItem = (props: HospitalAccessCodeListItemProps) => {
//   const {
//     hospitalAccessCode,
//   } = props;

//   const memoizedHospitalsGetByIdSelector = useMemo(
//     hospitalsSelectors.makeGetById,
//     [],
//   );

//   const hospital = useSelector(
//     (state: AppState) => memoizedHospitalsGetByIdSelector(state, hospitalAccessCode.hospitalId),
//   );

//   const { i18n } = useTranslation();

//   return (
//     <ListItem
//       to={`/settings/share-my-data/${hospitalAccessCode.id}`}
//       primaryText={defaultGet(hospital, 'name', '')}
//       secondaryText={dateFormat(i18n.language, hospitalAccessCode.createdAt)}
//       leftIcon={ShareDataIcon}
//       divider
//     />
//   );
// };

// const ShareMyData = () => {
//   const { t } = useTranslation();

//   const hospitalAccessCodes = (
//     useSelector((state: AppState) => hospitalAccessCodesSelectors.getAll(state))
//   );

//   return (
//     <article className="app share-my-data">
//       <Header auth documentTitle={t('share-my-data')} />
//       <PageHeader title={t('pageHeaders.shareMyData.title')} />
//       <section className="content">
//         {
//           !isEmpty(hospitalAccessCodes)
//             ? map(hospitalAccessCodes, (hospitalAccessCode) => (
//               <HospitalAccessCodeListItem
//                 key={hospitalAccessCode.id}
//                 hospitalAccessCode={hospitalAccessCode}
//               />
//             ))
//             : <p className="body light">{t('shareMyData:empty-state-heading')}</p>
//         }
//         <Link to="/settings/share-my-data/add" className="navigation">{t('share-my-data')} &gt;</Link>
//       </section>
//       <Footer />
//     </article>
//   );
// };

// export default ShareMyData;
