import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import size from 'lodash/size';
import includes from 'lodash/includes';
import without from 'lodash/without';
import union from 'lodash/union';
import filter from 'lodash/filter';

import { AppState } from '../state/reducers';

import Modal from './Modal';
import ListItem from './ListItem';
import { ReactComponent as IconChecked } from '../assets/icons/Checked.svg';
import { ReactComponent as IconUnchecked } from '../assets/icons/Unchecked.svg';
import Colors from '../theme/Colors';
import { actions as questionnairesActions } from '../state/questionnaires';
import { selectors as generatedSuggestedQuestionsSelectors } from '../state/generatedSuggestedQuestions';
import { QuestionnaireType } from '../../types';

type Props = {
  showModal: boolean;
  setShowModal: (state: boolean) =>void;
  questionnaire: QuestionnaireType

};
const SuggestedQuestionsModal = ({ showModal, setShowModal, questionnaire }:Props) => {
  const { i18n, t } = useTranslation();

  const dispatch = useDispatch();

  const questionnaireQuestionsIds = map(questionnaire.questions, (question) => question.id);

  const [suggestedQuestionIds, setSuggestedQuestionIds] = useState(questionnaireQuestionsIds);
  const handleSuggestedQuestionCheck = (id: string) => {
    if (includes(suggestedQuestionIds, id)) {
      return setSuggestedQuestionIds(without(suggestedQuestionIds, id));
    }

    return setSuggestedQuestionIds(union(suggestedQuestionIds, [id]));
  };

  const memoizedGeneratedSuggestedQuestionsGetByLocaleSelector = useMemo(
    () => generatedSuggestedQuestionsSelectors.makeGetByLocale(i18n.language),
    [i18n],
  );

  const suggestedQuestions = useSelector(
    (state: AppState) => memoizedGeneratedSuggestedQuestionsGetByLocaleSelector(state),
  );

  const handleClose = () => {
    setShowModal(false);
  };

  const handleSave = () => {
    if (isEmpty(suggestedQuestionIds)) {
      return false;
    }

    const questions = filter(
      suggestedQuestions,
      (question) => includes(suggestedQuestionIds, question.id),
    );

    dispatch(questionnairesActions.addQuestions({
      questionnaireId: questionnaire.id,
      questions,
    }));

    return handleClose();
  };

  useEffect(() => {
    if (showModal) {
      setSuggestedQuestionIds(questionnaireQuestionsIds);
    }
  }, [showModal]);

  return (
    <Modal
      visible={showModal}
      hideModal={handleClose}
      actions={[
        {
          title: t('cancel'),
          onClick: handleClose,
        },
        {
          title: t('save'),
          onClick: handleSave,
          disabled: isEmpty(suggestedQuestionIds),
          primary: true,
        },
      ]}
      className="suggested-questions"
    >
      <>
        <h1 className="heading">{t('add-suggested-questions')}</h1>
        <section className="questions">
          {
            map(suggestedQuestions, (question, index) => (
              <ListItem
                key={question.id}
                primaryText={question.question}
                leftIcon={(
                  includes(suggestedQuestionIds, question.id)
                    ? <IconChecked fill={Colors.secondary} className="icon" />
                    : <IconUnchecked fill={Colors.secondary} className="icon" />
                )}
                disabled={includes(questionnaireQuestionsIds, question.id)}
                divider={(index + 1 !== size(suggestedQuestions))}
                onClick={() => handleSuggestedQuestionCheck(question.id)}
              />
            ))
          }
        </section>
      </>
    </Modal>
  );
};

export default SuggestedQuestionsModal;
