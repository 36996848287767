/* eslint react/require-default-props: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Path } from 'react-native-svg';
import ResizeableSVG from '../ResizeableSVG';

import Colors from '../../theme/Colors';
import Constants from '../../theme/Constants';

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

type Props = {
  color?: string;
  size?: number;
};

const TargetedTherapy = (props: Props) => {
  const {
    color = Colors.primary,
    size = Constants.defaultIconSize,
  } = props;

  return (
    <ResizeableSVG size={size}>
      <Path d="m19.1889767 2c.3663518 0 .669119.27223789.7170361.62544812l.006606.09819394v7.0434494c0 2.01903634-1.1091667 3.78968774-2.7487311 4.73318534 1.5827695.9099167 2.6712716 2.5920439 2.7446776 4.5240361l.003957.2085956v7.0434494c0 .3996565-.3239856.7236421-.7236421.7236421-.3663517 0-.6691189-.2722379-.717036-.6254481l-.006606-.098194-.0002382-2.2373579-4.0086424.0000497c-.3996564 0-.723642-.3239856-.723642-.7236421 0-.3663518.2722379-.669119.6254481-.7170361l.0981939-.006606 4.0086424-.0007655.0002382-3.3580915c0-2.1960354-1.7930542-3.9972851-3.9858415-4.0098754l-.0423967-.0000331-.1862359.0052265c-1.9185616.0984091-3.4948472 1.5662705-3.7631799 3.4380464l3.9689664.0000721c.3996565 0 .7236421.3239856.7236421.7236421 0 .3663518-.2722379.669119-.6254482.7170361l-.0981939.006606-4.0095506-.0006292.0002841 6.1633579c0 .3996565-.3239856.7236421-.72364204.7236421-.36635177 0-.66911897-.2722379-.71703606-.6254481l-.006606-.098194v-7.0434494c0-2.0189203 1.1095249-3.7900479 2.7490831-4.73387-1.5827578-.9090796-2.67161354-2.5911015-2.74502989-4.52336207l-.00395672-.20858497v-7.0434494c0-.39965647.32398558-.72364206.72364206-.72364206.36635175 0 .66911895.27223789.71703605.62544812l.006606.09819394-.0003806 2.23635794 4.0092611.00046792c.3996565 0 .7236421.32398558.7236421.72364206 0 .36635177-.2722379.66911897-.6254481.71703606l-.098194.006606-4.0092611-.00075204.0003806 3.36009146c0 2.19647874 1.7939233 3.99742594 3.9878499 4.00892164l.0397695-.0010131.1869285-.0041882c1.9192099-.0983509 3.4949028-1.5653619 3.7631235-3.4370778l-3.9685014-.0005615c-.3996565 0-.7236421-.3239856-.7236421-.72364204 0-.36635177.2722379-.66911897.6254482-.71703606l.0981939-.006606 4.0084494-.0008884.0003347-6.16135794c0-.39965647.3239855-.72364206.723642-.72364206z" fill={color} fillRule="evenodd" />
    </ResizeableSVG>
  );
};

TargetedTherapy.propTypes = propTypes;

export default TargetedTherapy;
