import { AppState } from '../reducers';
import { GeneratedReportsState } from './types';

import createDeepEqualSelector from '../../selectors/createDeepEqualSelector';

const getAllSelector = (state: GeneratedReportsState) => state;

const getAll = createDeepEqualSelector(
  (state: AppState) => state.generatedReports,
  getAllSelector,
);

export {
  getAll, // eslint-disable-line import/prefer-default-export
};
