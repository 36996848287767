import isEmpty from 'lodash/isEmpty';
import concat from 'lodash/concat';
import filter from 'lodash/filter';

import {
  TrendsState,
  TrendsAddActionType, TrendsClearDrugModuleActionType,
} from './types';
import { testFitnessIndicatorIds } from '../../utils/idsData';

const add = (state: TrendsState, action: TrendsAddActionType) => {
  const { payload } = action;

  if (isEmpty(payload)) {
    return state;
  }

  return concat(state, payload);
};

const clear = () => ([]);

const clearDrugModuleTrends = (state: TrendsState, action: TrendsClearDrugModuleActionType) => {
  const { payload } = action;

  if (isEmpty(payload)) {
    return state;
  }

  return filter(state, ({ explicitData }) => !testFitnessIndicatorIds[payload].some(
    (drugModuleIndicator) => drugModuleIndicator in explicitData,
  ));
};

export {
  add,
  clear,
  clearDrugModuleTrends,
};
