import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';

import {
  HospitalsState,
  HospitalsAddActionType,
  HospitalsReplaceActionType,
} from './types';

const add = (state: HospitalsState, action: HospitalsAddActionType) => {
  const { payload } = action;

  const hospitals = keyBy(payload, 'id');

  return {
    ...state,
    ...hospitals,
  };
};

const replace = (state: HospitalsState, action: HospitalsReplaceActionType) => {
  const { payload } = action;

  if (isEmpty(payload)) {
    return state;
  }

  const newState = keyBy(payload, 'id');

  if (isEqual(state, newState)) {
    return state;
  }

  return newState;
};

const clear = () => ({});

export {
  add,
  replace,
  clear,
};
