import { combineReducers, Reducer } from 'redux';

import * as actionTypes from './actionTypes';
import {
  RuntimeSyncState,
  RuntimeSyncActionTypes,
} from './types';

const syncInitialState: RuntimeSyncState = {
  userSyncInProgress: false,
  userSyncStale: false,
  lastUserSync: 0,
  lastUserEtag: '',
  lastFailure: 0,
};

const sync: Reducer<
RuntimeSyncState,
RuntimeSyncActionTypes
> = (state = syncInitialState, action) => {
  switch (action.type) {
    case actionTypes.SYNC_UPDATE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: return state;
  }
};

const reducer = combineReducers({
  sync,
});

export default reducer;
