import { createSelector } from 'reselect';
import get from 'lodash/get';
import map from 'lodash/map';
import some from 'lodash/some';
import orderBy from 'lodash/orderBy';
import find from 'lodash/find';
import reject from 'lodash/reject';
import isEmpty from 'lodash/isEmpty';
import toArray from 'lodash/toArray';

import { AppState } from '../reducers';
import { TrendIndicatorsState } from './types';
import { SettingTrendIndicatorType, TrendIndicatorReturnType, TrendIndicatorsReturnType } from '../../../types';

import EMPTY_OBJECT from '../../utils/empty-object';
import EMPTY_ARRAY from '../../utils/empty-array';

const getByIdSelector = (state: TrendIndicatorsState, id: string): TrendIndicatorReturnType => (
  get(state, [id]) || EMPTY_OBJECT
);

const makeGetById = () => (
  createSelector(
    (state: AppState) => state.trendIndicators,
    (state: any, id: string) => id,
    getByIdSelector,
  )
);

const getByIdsSelector = (
  state: TrendIndicatorsState,
  ids: string[],
): TrendIndicatorsReturnType => (
  map(ids, (id) => getByIdSelector(state, id))
);

const makeGetByIds = () => (
  createSelector(
    (state: AppState) => state.trendIndicators,
    (state: any, ids: string[]) => ids,
    getByIdsSelector,
  )
);

const getAllSelector = (state: TrendIndicatorsState) => toArray(state);

const getAll = createSelector(
  (state: AppState) => state.trendIndicators,
  getAllSelector,
);

const getEnabledSelector = (
  state: TrendIndicatorsState,
  settings: SettingTrendIndicatorType[] | typeof EMPTY_ARRAY,
): TrendIndicatorsReturnType => {
  if (isEmpty(state)) {
    return EMPTY_ARRAY;
  }

  const overwriteSystemOrder = some(settings, 'order');

  const indicators = map(state, (indicator) => {
    const setting = find(settings, { id: indicator.id }) || {};

    // Overwrite system defined order if user changed order
    const order = overwriteSystemOrder ? setting.order : indicator.order;

    // Exclude disabled indicators
    const exclude = setting.disabled ? true : null;

    if (exclude) {
      return {
        ...indicator,
        exclude,
        order,
      };
    }

    return {
      ...indicator,
      order,
    };
  });

  return orderBy(reject(indicators, 'exclude'), ['order']);
};

const makeGetEnabled = () => (
  createSelector(
    (state: AppState) => state.trendIndicators,
    (state: AppState, settings: SettingTrendIndicatorType[] | typeof EMPTY_ARRAY) => settings,
    getEnabledSelector,
  )
);

const getFavoriteSelector = (
  state: TrendIndicatorsState,
  settings: SettingTrendIndicatorType[] | typeof EMPTY_ARRAY,
): TrendIndicatorsReturnType => {
  if (isEmpty(state)) {
    return EMPTY_ARRAY;
  }

  const overwriteSystemOrder = some(settings, 'order');

  const indicators = map(state, (indicator) => {
    const setting = find(settings, { id: indicator.id }) || {};

    // Overwrite system defined order if user changed order
    const order = overwriteSystemOrder ? setting.order : indicator.order;

    // Exclude disabled or non favorite indicators
    const exclude = setting.disabled || !setting.favorite ? true : null;

    if (exclude) {
      return {
        ...indicator,
        exclude,
        order,
      };
    }

    return {
      ...indicator,
      order,
    };
  });

  return orderBy(reject(indicators, 'exclude'), ['order']);
};

const makeGetFavorite = () => (
  createSelector(
    (state: AppState) => state.trendIndicators,
    (state: AppState, settings: SettingTrendIndicatorType[] | typeof EMPTY_ARRAY) => settings,
    getFavoriteSelector,
  )
);

export {
  getAll,
  makeGetById,
  makeGetByIds,
  makeGetEnabled,
  makeGetFavorite,
};
