/* eslint global-require: 0 */

export default {
  icons: {
    sdm: require('../assets/images/icons/sdm.png'),
  },
  personalize: {
    personalize: require('../assets/images/personalize/personalize.png'),
  },
};
