/* eslint react/require-default-props: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Path, G, Circle } from 'react-native-svg';
import ResizeableSVG from '../ResizeableSVG';

import Colors from '../../theme/Colors';
import Constants from '../../theme/Constants';

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

type Props = {
  color?: string;
  size?: number;
};

const Check = (props: Props) => {
  const {
    color = Colors.primary,
    size = Constants.defaultIconSize,
  } = props;

  return (
    <ResizeableSVG size={size}>
      <G fill="none" fillRule="evenodd">
        <Circle cx="14" cy="14" fill={color} r="12.5" />
        <Path
          d="m18.7905719 8.0156841c.1273822-.01997471.2597922-.02247166.3670608.00187287l.0977708.0336385 1.1442006.81676136c.4144482.20580475.5592461.48864838.4108883.79517047l-.0533256.09261362-6.8652038 10.01420458c-.2279465.3417966-.5944477.4616477-.8223942.4616477-.1966593 0-.3860215-.0782697-.5547432-.1668928l-.3391635-.1882208-4.00470222-3.9772727c-.20261912-.2012313-.22513236-.5883491-.06753971-.9119456l.06753971-.117884 1.03693182-1.0298295c.20515185-.2037467.58045815-.2025478.8196409.0488992l.0742658.0931462 2.645964 2.5923296 5.6137343-8.27414779c.1162085-.16867841.2592335-.25745682.4290752-.28409091z"
          fill="#fff"
        />
      </G>
    </ResizeableSVG>
  );
};

Check.propTypes = propTypes;

export default Check;
