import omit from 'lodash/omit';
import keyBy from 'lodash/keyBy';

import {
  HospitalAccessCodesState,
  HospitalAccessCodesAddActionType,
  HospitalAccessCodesRemoveActionType,
  HospitalAccessCodesUpdateActionType,
} from './types';

const add = (state: HospitalAccessCodesState, action: HospitalAccessCodesAddActionType) => {
  const { payload } = action;

  const hospitalAccessCodes = keyBy(payload, 'id');

  return {
    ...state,
    ...hospitalAccessCodes,
  };
};

const remove = (state: HospitalAccessCodesState, action: HospitalAccessCodesRemoveActionType) => {
  const { payload } = action;

  return omit(state, payload);
};

const update = (state: HospitalAccessCodesState, action: HospitalAccessCodesUpdateActionType) => {
  const { payload } = action;

  const hospitalAccessCodes = keyBy(payload, 'id');

  return {
    ...state,
    ...hospitalAccessCodes,
  };
};

const clear = () => ({});

export {
  add,
  remove,
  update,
  clear,
};
