import * as actionTypes from './actionTypes';
import {
  TestFitnessActionTypes,
  TestFitnessAddPayload,
} from './types';
import { TDrugModule } from '../../../types';

const addTestFitnessData = (payload: TestFitnessAddPayload): TestFitnessActionTypes => ({
  type: actionTypes.ADD_TEST_FITNESS_DATA,
  payload,
});

const clearDrugModuleTrends = (payload: TDrugModule): TestFitnessActionTypes => ({
  type: actionTypes.CLEAR,
  payload,
});

export {
  addTestFitnessData,
  clearDrugModuleTrends,
};
