import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AppState } from '../state/reducers';

import Navigation from '../components/Navigation';
import DrawerButtons from '../components/DrawerButtons';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import DrawerRightHeader from '../components/DrawerRightHeader';
import ProfileQuestion from '../components/ProfileQuestion';
import getProfileProgressSelector from '../selectors/getProfileProgress';

type Params = {
  questionId: string;
};

const DrawerRight = () => {
  const { t } = useTranslation();

  const { percentage, completed } = useSelector(
    (state: AppState) => getProfileProgressSelector(state),
  );

  return (
    <Drawer position="right">
      <section>
        <DrawerRightHeader />
        <section className="drawer-item">
          <header className="row between center">
            <h1 className="heading">{t('profile')}</h1>
            <p className="body light">{t('profile-completed', { percentage })}</p>
          </header>
          <Link to="/profile" className="navigation accent align-end">
            {t(completed ? 'change-profile' : 'complete-profile')} &gt;
          </Link>
        </section>
      </section>
    </Drawer>
  );
};

const ProfileChange = () => {
  const { questionId } = useParams<Params>();

  const { t } = useTranslation();

  return (
    <article className="page profile row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('profile')} showRightButton />
          <ProfileQuestion questionId={questionId} />
        </section>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default ProfileChange;
