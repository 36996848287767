import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import reduce from 'lodash/reduce';
import assignIn from 'lodash/assignIn';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import { AppState } from '../state/reducers';
import { TrendMeasurementType } from '../../types';

import TrendIndicator from '../components/TrendIndicator';
import Navigation from '../components/Navigation';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRightHeader from '../components/DrawerRightHeader';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import Button from '../components/Button';
import Modal from '../components/Modal';
import ListItem from '../components/ListItem';
import getEnabledTrendIndicators from '../selectors/getEnabledTrendIndicators';
import { actions as trendsActions } from '../state/trends';
import { ReactComponent as IconSettings } from '../assets/icons/Settings.svg';
import getUnixTime from '../utils/getUnixTime';
import Colors from '../theme/Colors';

const DrawerRight = () => {
  const { t } = useTranslation();

  return (
    <Drawer position="right">
      <section>
        <DrawerRightHeader />
        <section className="drawer-item">
          <ListItem
            primaryText={t('trends-settings')}
            leftIcon={<IconSettings fill={Colors.primary} className="icon" />}
            divider
            to="/trends/settings"
          />
        </section>
      </section>
    </Drawer>
  );
};

const TrendsAdd = () => {
  const history = useHistory();

  const enabledTrendIndicators = useSelector((state: AppState) => getEnabledTrendIndicators(state));

  const now = new Date();

  const [measurements, setMeasurements] = useState<{[key: string]:{'changed': boolean, 'value': number}}>({});
  const [measuredAt, setMeasuredAt] = useState(now);
  const [modal, setModal] = useState({ visible: false, name: '', description: '' });

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const showModal = (name: string, description: string): void => (
    setModal({ visible: true, name, description })
  );

  const hideModal = (): void => setModal({ visible: false, name: '', description: '' });

  const handleSave = () => {
    const explicitData = reduce(
      measurements,
      (result, measurement: TrendMeasurementType, trendIndicatorId) => {
        if (measurement.changed) {
          return assignIn(result, { [trendIndicatorId]: measurement.value });
        }

        return result;
      },
      {},
    );

    const implicitData = reduce(
      measurements,
      (result, measurement: TrendMeasurementType, trendIndicatorId) => {
        if (!measurement.changed) {
          return assignIn(result, { [trendIndicatorId]: measurement.value });
        }

        return result;
      },
      {},
    );

    dispatch(trendsActions.add([{
      createdAt: getUnixTime(),
      measuredAt: getUnixTime(measuredAt),
      explicitData,
      implicitData,
    }]));

    history.push('/trends');
  };

  let content = (
    <>
      <label // eslint-disable-line jsx-a11y/label-has-associated-control
        htmlFor="measured-at"
      >
        <span className="body light">{t('date-time')}</span>
        <DatePicker
          selected={measuredAt}
          onChange={(date: Date) => date <= now && setMeasuredAt(date)}
          showTimeSelect
          dateFormat="dd/LL/yyyy HH:mm"
          timeFormat="HH:mm"
          id="measured-at"
        />
      </label>
      {
                map(enabledTrendIndicators, (indicator) => (
                  <TrendIndicator
                    key={indicator.id}
                    setMeasurements={setMeasurements}
                    indicator={indicator}
                    showModal={showModal}
                    dividers={false}
                  />
                ))
            }
      <Button labelText={t('save')} size="medium" onClick={handleSave} />
    </>
  );

  if (isEmpty(enabledTrendIndicators)) {
    content = (
      <section className="empty-state">
        <h1 className="heading">{t('trends:empty-state-disabled-heading')}</h1>
        <Link to="/trends/settings" className="navigation accent">{t('trends:empty-state-body')} &gt;</Link>
      </section>
    );
  }

  return (
    <article className="page trends-add row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('add-trends')} showRightButton />
          {content}
        </section>
        <Footer />
      </section>
      <DrawerRight />
      <Modal
        visible={modal.visible}
        hideModal={hideModal}
        actions={[
          {
            title: t('close'),
            onClick: hideModal,
            primary: true,
          },
        ]}
      >
        <h1 className="heading">{modal.name}</h1>
        <p className="body">{modal.description}</p>
      </Modal>
    </article>
  );
};

export default TrendsAdd;
