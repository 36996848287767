/* eslint react/require-default-props: 0 */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import isEmpty from 'lodash/isEmpty';

import { TrendIndicatorType } from 'types';
import { ReactComponent as ArrowDown } from '../../assets/icons/ArrowDown.svg';
import { ReactComponent as SettingsSVG } from '../../assets/icons/Settings.svg';
import Text from '../Text';
import Constants from '../../theme/Constants';
import Colors from '../../theme/Colors';
import styles from './styles';

export type DividerProps = {
  id: number;
  indicators: TrendIndicatorType[];
};

const propTypes = {
  id: PropTypes.number.isRequired,
};

const Banner = (props: DividerProps) => {
  const { id, indicators } = props;
  const { t } = useTranslation();

  if (indicators.length !== 5 && indicators.length - 1 === id) {
    return null;
  }

  if (isEmpty(indicators) || id === 0) {
    return null;
  }

  if (((id + 1) % 10 === 0) && ((indicators.length - id) >= 1)) {
    return (
      <View style={styles.banner}>
        <View style={styles.bannerTextContainer}>
          <View style={styles.bannerTitle}>
            <Text type="heading">{t('banner.more.title')}</Text>
          </View>
          <View style={styles.bannerIcon}>
            <ArrowDown
              width={Constants.defaultIconSize}
              height={Constants.defaultIconSize}
              fill={Colors.primary}
            />
          </View>
        </View>

      </View>
    );
  }

  if (((id + 1) % 5 === 0) && ((indicators.length - id) >= 1)) {
    const title = id === 4 && indicators.length <= 9 ? t('banner.settings.title-more') : t('banner.settings.title-too-many');
    return (
      <View style={styles.banner}>
        <Text type="heading">{title}</Text>
        <View style={styles.bannerTextContainer}>
          <Text type="body">{t('banner.settings.body-start')}</Text>
          <View style={styles.bannerIcon}>
            <SettingsSVG
              width={Constants.defaultIconSize}
              height={Constants.defaultIconSize}
              fill={Colors.primary}
            />
          </View>
          <Text type="body">{t('banner.settings.body-end')}</Text>
        </View>
      </View>
    );
  }

  return null;
};

Banner.propTypes = propTypes;

export default memo(Banner);
