import React from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

import { MessageType } from '../../types';

import ListItem from './ListItem';
import dateFormat from '../utils/dateFormat';
import { ReactComponent as IconMessage } from '../assets/icons/Message.svg';
import { ReactComponent as IconBell } from '../assets/icons/Bell.svg';
import { ReactComponent as IconChevronRight } from '../assets/icons/ChevronRight.svg';
import Colors from '../theme/Colors';

type Props = {
  message: MessageType;
  divider?: boolean;
  dividerOffset?: number;
  isPushNotification?: boolean;
};

const MessageListItem = (props: Props) => {
  const { i18n } = useTranslation();

  const {
    message, divider = true, dividerOffset = 0, isPushNotification = false,
  } = props;

  const title = get(message, ['title', i18n.language]) ?? message.title;

  return (
    <ListItem
      key={message.id}
      primaryText={title}
      secondaryText={dateFormat(i18n.language, message.createdAt)}
      leftIcon={isPushNotification ? <IconBell fill={Colors.primary} className="icon" /> : <IconMessage fill={Colors.primary} className="icon" />}
      rightIcon={<IconChevronRight fill={Colors.primary} className="icon small" />}
      divider={divider}
      primaryTextClassName={message.readAt ? 'body' : 'heading-secondary'}
      dividerOffset={dividerOffset}
      to={`/message/${message.id}/${isPushNotification}`}
    />
  );
};

export default MessageListItem;
