import omit from 'lodash/omit';
import keyBy from 'lodash/keyBy';

import {
  NotesState,
  NotesAddActionType,
  NotesRemoveActionType,
  NotesUpdateActionType,
} from './types';

const add = (state: NotesState, action: NotesAddActionType) => {
  const { payload } = action;

  const notes = keyBy(payload, 'id');

  return {
    ...state,
    ...notes,
  };
};

const remove = (state: NotesState, action: NotesRemoveActionType) => {
  const { payload } = action;

  return omit(state, payload);
};

const update = (state: NotesState, action: NotesUpdateActionType) => {
  const { payload } = action;

  const notes = keyBy(payload, 'id');

  return {
    ...state,
    ...notes,
  };
};

const clear = () => ({});

export {
  add,
  remove,
  update,
  clear,
};
