import { largestTriangleThreeBucket } from 'd3fc-sample';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import eachMinuteOfInterval from 'date-fns/eachMinuteOfInterval';
import map from 'lodash/map';
import mean from 'lodash/mean';
import findLast from 'lodash/findLast';
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import find from 'lodash/find';
import concat from 'lodash/concat';
import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';
import isWithinInterval from 'date-fns/isWithinInterval';
import fromUnixTime from 'date-fns/fromUnixTime';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import isEmpty from 'lodash/isEmpty';
import assignIn from 'lodash/assignIn';
import toArray from 'lodash/toArray';
import set from 'lodash/set';
import size from 'lodash/size';
import round from 'lodash/round';
import sum from 'lodash/sum';
import pickBy from 'lodash/pickBy';
import { createSelector } from 'reselect';

import some from 'lodash/some';
import startOfMinute from 'date-fns/startOfMinute';
import { endOfMinute } from 'date-fns';
import sortBy from 'lodash/sortBy';
import startOfHour from 'date-fns/startOfHour';
import endOfHour from 'date-fns/endOfHour';
import eachHourOfInterval from 'date-fns/eachHourOfInterval';
import { TrendsState } from './types';
import { AppState } from '../reducers';
import {
  TDate, TGraphData, TrendIndicatorType, TrendSeries, TrendType,
} from '../../../types';

import getUnixTime from '../../utils/getUnixTime';
import toDate from '../../utils/toDate';
import createDeepEqualSelector from '../../selectors/createDeepEqualSelector';
import defaultGet from '../../utils/defaultGet';

const getLatestDataByTrendIndicatorIdSelector = (state: TrendsState, trendIndicatorId: string) => (
  find(
    orderBy(state, ['measuredAt'], ['desc']),
    (data) => get(data, ['explicitData', trendIndicatorId]),
  )
);

const makeGetLatestDataByTrendIndicatorId = () => (
  createDeepEqualSelector(
    (state: AppState) => state.trends,
    (state: any, trendIndicatorId: string) => trendIndicatorId,
    getLatestDataByTrendIndicatorIdSelector,
  )
);

const getSelectorTrendData = (type: 'hours' | 'minutes' | 'days', startDate: TDate, endDate: TDate, state: TrendsState, trendIndicatorId: string) => {
  let interval: { start: number | Date; end: number | Date; };
  let periodInterval:Date[] = [];

  if (type === 'hours') {
    interval = {
      start: startOfHour(toDate(startDate)),
      end: endOfHour(toDate(endDate)),
    };
    periodInterval = eachHourOfInterval(interval);
  } else if (type === 'minutes') {
    interval = {
      start: startOfMinute(toDate(startDate)), end: endOfMinute(toDate(endDate)),
    };

    periodInterval = eachMinuteOfInterval(interval);
  } else if (type === 'days') {
    interval = {
      start: startOfMinute(toDate(startDate)), end: endOfMinute(toDate(endDate)),
    };

    periodInterval = eachMinuteOfInterval(interval);
  }

  // Filter measurements outside of interval. Make sure all measurements are sorted ascending
  const filteredState = orderBy(
    filter(state, ({ measuredAt }) => isWithinInterval(toDate(measuredAt), interval)),
    ['measuredAt'],
    ['asc'],
  );

  let startValue: number | null = null;
  let endValue: number | null = null;

  const data = reduce(
    filteredState,
    (result, trend) => {
      const { measuredAt } = trend;

      // Only select explicit data
      const value = get(trend, ['explicitData', trendIndicatorId]);

      if (value == null) {
        return result;
      }

      // Only update startValue once
      if (startValue == null) {
        startValue = value;
      }

      endValue = value;

      // Get UNIX timestamp
      const date = getUnixTime(toDate(measuredAt));

      // Get values for date
      const values = defaultGet(result, [date, 'values'], []);

      // Add current value to values
      const newValues = [...values, value];

      return assignIn(
        result,
        {
          [measuredAt]: {
            date: measuredAt,
            value,
            values: newValues,
            labelValue: value,
            selectable: true,
          },
        },
      );
    },
    {},
  );

  if (type === 'days') {
    const startTimestamp = getUnixTime(startOfDay(toDate(startDate)), true);
    const endTimestamp = getUnixTime(startOfDay(toDate(endDate)), true);

    // Make sure we have a value for startTimestamp
    if (!get(data, [startTimestamp])) {
      set(
        data,
        [startTimestamp],
        {
          date: startTimestamp,
          value: startValue,
          labelValue: startValue,
          selectable: false,
        },
      );
    }

    // Make sure we have a value for endTimestamp
    if (!get(data, [endTimestamp])) {
      set(
        data,
        [endTimestamp],
        {
          date: endTimestamp,
          value: endValue,
          labelValue: endValue,
          selectable: false,
        },
      );
    }
  }

  return {
    data,
    periodInterval,
  };
};

const getFilteredTrends = (
  trends: TrendType[], indicators: TrendIndicatorType[], startDate: TDate, endDate: TDate,
) => {
  const filteredByDateTrends = trends.filter((trend) => {
    const interval = { start: toDate(startDate), end: toDate(endDate) };
    return isWithinInterval(fromUnixTime(trend.measuredAt), interval);
  });

  const filteredIndicators = filter(indicators, (({ id }) => some(
    filteredByDateTrends, ((trend) => trend.explicitData[id]),
  )));

  const filteredByIndicatorTrends = filteredByDateTrends.reduce(
    (acc: TrendType[], curr: TrendType) => {
      const filteredExplicitData = pickBy(curr.explicitData,
        (_, key) => some(filteredIndicators, (indicator) => indicator.id === key));

      if (size(filteredExplicitData)) {
        return [...acc, { ...curr, explicitData: filteredExplicitData }];
      }

      return acc;
    }, [],
  );

  return {
    filteredIndicators,
    filteredByIndicatorTrends,
  };
};

const getLatestDataPerDayByTrendIndicatorIdSelector = (
  state: TrendsState,
  trendIndicatorId: string,
  defaultValue: number,
  startDate: TDate,
  endDate: TDate,
  type = 'latest',
  millis = false,
  isTestFitness?: boolean,
) => {
  const interval = { start: startOfDay(toDate(startDate)), end: endOfDay(toDate(endDate)) };

  const startTimestamp = getUnixTime(startOfDay(toDate(startDate)), millis);
  const endTimestamp = getUnixTime(startOfDay(toDate(endDate)), millis);

  // Generate days for X axis
  const days = eachDayOfInterval(interval);

  // Filter measurements outside of interval. Make sure all measurements are sorted ascending
  const filteredState = orderBy(
    filter(state, ({ measuredAt }) => isWithinInterval(fromUnixTime(measuredAt), interval)),
    ['measuredAt'],
    ['asc'],
  );

  // startValue is used for setting the initial value, endValue is used for setting
  // the value of the last day, if none exist
  let startValue: number | null = null;
  let endValue: number | null = null;

  const data = reduce(
    filteredState,
    (result, trend) => {
      const { measuredAt } = trend;

      // Only select explicit data
      const value = get(trend, ['explicitData', trendIndicatorId]);

      if (value == null) {
        return result;
      }

      // Only update startValue once
      if (startValue == null) {
        startValue = value;
      }

      // Always update endValue
      endValue = value;
      // Get UNIX timestamp
      const date = getUnixTime(startOfDay(toDate(measuredAt)), millis);

      // Get values for date
      const values = defaultGet(result, [date, 'values'], []);

      // Add current value to values
      const newValues = [...values, value];

      // Use latest value by default
      let newValue = value;

      if (type === 'cumulative') {
        newValue = sum(newValues);
      } else if (type === 'average') {
        newValue = round(mean(newValues));
      }

      return assignIn(
        result,
        {
          [date]: {
            date,
            value: newValue,
            values: newValues,
            labelValue: newValue,
            selectable: true,
          },
        },
      );
    },
    {},
  );

  // Fall back to defaultValue
  if (startValue == null) {
    startValue = defaultValue;
  }

  // Fall back to defaultValue
  if (endValue == null) {
    endValue = defaultValue;
  }

  // Make sure we have a value for startTimestamp
  if (!get(data, [startTimestamp])) {
    set(
      data,
      [startTimestamp],
      {
        date: startTimestamp,
        value: isTestFitness ? defaultValue : startValue,
        labelValue: isTestFitness ? defaultValue : startValue,
        selectable: false,
      },
    );
  }

  // Make sure we have a value for endTimestamp
  if (!get(data, [endTimestamp])) {
    set(
      data,
      [endTimestamp],
      {
        date: endTimestamp,
        value: endValue,
        labelValue: endValue,
        selectable: false,
      },
    );
  }

  // Generate xAxis data
  const xAxis = map(
    days,
    (date) => ({
      date: getUnixTime(date, millis),
      value: defaultValue,
      labelValue: defaultValue,
    }),
  ) as TGraphData[];

  return {
    graph: toArray(data) as TGraphData[],
    xAxis,
  };
};

const getLatestDataPerDayByTrendIndicatorsSelector = (
  state: TrendsState,
  trendIndicators: TrendIndicatorType[],
  startDate: Date,
  endDate: Date,
) => {
  const trendSeries: TrendSeries[] = [];
  trendIndicators.forEach((trendIndicator) => {
    const {
      defaultValue = 0,
      graphConfig = {
        showThumbs: true,
        data: 'latest',
      },
    } = trendIndicator;

    const { graph } = getLatestDataPerDayByTrendIndicatorIdSelector(
      state,
      trendIndicator.id,
      defaultValue,
      startDate,
      endDate,
      graphConfig.data,
      true,
    );

    const data = sortBy(graph.map((datum) => ({
      ...datum,
      originalValue: datum.value,
      value: (datum.value / trendIndicator.maximumValue) * 10,
    })), 'date');

    trendSeries.push({ ...trendIndicator, data });
  });

  return {
    trendSeries,
  };
};

const getDataPerMinuteByTrendIndicatorIdSelector = (
  state: TrendsState,
  trendIndicatorId: string,
  defaultValue: number,
  startDate: TDate,
  endDate: TDate,
) => {
  const {
    periodInterval, data,
  } = getSelectorTrendData('minutes', startDate, endDate, state, trendIndicatorId);

  // Generate xAxis data
  const xAxis = map(
    periodInterval,
    (date) => ({
      date: getUnixTime(date),
      value: defaultValue,
      labelValue: defaultValue,
    }),
  ) as TGraphData[];

  return {
    graph: toArray(data) as TGraphData[],
    xAxis,
  };
};

const makeGetLatestDataPerDayByTrendIndicatorId = () => (
  createDeepEqualSelector(
    (state: AppState) => state.trends,
    (state: any, trendIndicatorId: string) => trendIndicatorId,
    (state: any, trendIndicatorId: string, defaultValue: number) => defaultValue,
    (state: any, trendIndicatorId: string, defaultValue: number, startDate: TDate) => startDate,
    (
      state: any,
      trendIndicatorId: string,
      defaultValue: number,
      startDate: TDate,
      endDate: TDate,
    ) => endDate,
    (
      state: any,
      trendIndicatorId: string,
      defaultValue: number,
      startDate: TDate,
      endDate: TDate,
      type: string,
    ) => type,
    (
      state: any,
      trendIndicatorId: string,
      defaultValue: number,
      startDate: TDate,
      endDate: TDate,
      type: string,
      millis: boolean,
    ) => millis,
    getLatestDataPerDayByTrendIndicatorIdSelector,
  )
);

const makeGetDataPerMinuteByTrendIndicatorId = () => (
  createDeepEqualSelector(
    (state: AppState) => state.trends,
    (state: any, trendIndicatorId: string) => trendIndicatorId,
    (state: any, trendIndicatorId: string, defaultValue: number) => defaultValue,
    (state: any, trendIndicatorId: string, defaultValue: number, startDate: Date) => startDate,
    (
      state: any,
      trendIndicatorId: string,
      defaultValue: number,
      startDate: Date,
      endDate: Date,
    ) => endDate,
    getDataPerMinuteByTrendIndicatorIdSelector,
  )
);
const makeGetLatestDataPerDayByTrendIndicators = () => (
  createDeepEqualSelector(
    (state: AppState) => state.trends,
    (state: any, trendIndicators: TrendIndicatorType[]) => trendIndicators,
    (state: any, trendIndicators: TrendIndicatorType[], startDate: Date) => startDate,
    (state: any, trendIndicators: TrendIndicatorType[], startDate: Date, endDate: Date) => endDate,
    getLatestDataPerDayByTrendIndicatorsSelector,
  )
);

const getAveragedDataByTrendIndicatorIdSelector = (
  state: TrendsState,
  trendIndicatorId: string,
  defaultValue: number,
  startDate: TDate,
  endDate: TDate,
) => {
  const start = startOfDay(toDate(startDate));
  const end = endOfDay(toDate(endDate));

  const filteredData = filter(state, (trend) => (
    isWithinInterval(fromUnixTime(trend.measuredAt), { start, end })
  ));

  // Generate xAxis data
  const xAxis = map(
    eachDayOfInterval({ start, end }),
    (date) => ({ date: getUnixTime(date), value: defaultValue }),
  ) as TGraphData[];

  const startData = [
    { date: getUnixTime(start), value: defaultValue },
  ];

  const endData = [
    { date: getUnixTime(end), value: defaultValue },
  ];

  const data = reduce(
    filteredData,
    (result: TGraphData[], trend: TrendType) => {
      const { measuredAt } = trend;

      const explicitData = get(trend, ['explicitData', trendIndicatorId]);
      const implicitData = get(trend, ['implicitData', trendIndicatorId]);

      const value = explicitData ?? implicitData;

      if (value == null) {
        return result;
      }

      return concat(result, [{ date: measuredAt, value, selectable: true }]);
    },
    [],
  );

  if (isEmpty(data)) {
    return {
      graph: [...startData, ...endData] as TGraphData[],
      xAxis,
    };
  }

  // Place the measurements in one bucket per day if there are max 10 days
  let sampledData: TGraphData[];
  if (xAxis.length < 11) {
    const dv = { values: [] as TGraphData[] };
    const sampler = map(xAxis, (v) => ({ date: v.date, values: [] as TGraphData[] }));
    data.forEach((d) => (findLast(sampler, (sample) => d.date > sample.date) || dv).values.push(d));
    sampledData = sampler.map((d) => ({
      date: d.date,
      value: mean(map(d.values, (v) => (v.value))),
      selectable: d.values.length > 0,
    }));

    let lastValue = find(sampledData, (d) => !Number.isNaN(d.value));
    if (!lastValue) {
      return {
        graph: [...startData, ...endData] as TGraphData[],
        xAxis,
      };
    }

    sampledData.forEach((dataPoint) => {
      if (Number.isNaN(dataPoint.value) && lastValue) {
        // eslint-disable-next-line no-param-reassign
        dataPoint.value = lastValue.value;
      }
      lastValue = dataPoint;
    });
  } else {
    const orderedData = orderBy(data, ['date'], ['asc']);

    // Make sure we start at the same level as the first datapoint and end at the same level as the last datapoint
    orderedData.unshift({ date: startData[0].date, value: orderedData[0].value });
    orderedData.push({ date: endData[0].date, value: orderedData[orderedData.length - 1].value });

    // See https://github.com/d3fc/d3fc/blob/master/packages/d3fc-sample/README.md

    // Create the sampler
    const sampler = largestTriangleThreeBucket();

    // Configure the x / y value accessors
    sampler
      .x((d: TGraphData) => d.date)
      .y((d: TGraphData) => d.value);

    // Configure the size of the buckets used to downsample the data.
    sampler.bucketSize(20);

    // Run the sampler
    sampledData = sampler(orderedData) as TGraphData[];
  }

  return {
    graph: sampledData,
    xAxis,
  };
};

const makeGetAveragedDataByTrendIndicatorId = () => (
  createDeepEqualSelector(
    (state: AppState) => state.trends,
    (state: any, trendIndicatorId: string) => trendIndicatorId,
    (state: any, trendIndicatorId: string, defaultValue: number) => defaultValue,
    (state: any, trendIndicatorId: string, defaultValue: number, startDate: TDate) => startDate,
    (
      state: any,
      trendIndicatorId: string,
      defaultValue: number,
      startDate: TDate,
      endDate: TDate,
    ) => endDate,
    getAveragedDataByTrendIndicatorIdSelector,
  )
);

const countSelector = (state: TrendsState) => size(state);

const count = createDeepEqualSelector(
  (state: AppState) => state.trends,
  countSelector,
);

const getByIdSelector = (state: TrendsState, id: string) => (
  state.map((item) => get(item.explicitData, [id]) || 0)
);

const getByIdsSelector = (
  state: TrendsState,
  ids: string[],
) => (
  map(ids, (id) => getByIdSelector(state, id))
);

const getTrendsSummarySum = (state: TrendsState, ids: string[]) => (
  getByIdsSelector(state, ids)
    .reduce((prev, elem) => prev + elem.reduce((acc, item) => acc + item, 0), 0)
);

const getTrendsSummaryByIds = () => (
  createSelector(
    (state: AppState) => state.trends,
    (state: any, ids: string[]) => ids,
    getByIdsSelector,
  )
);

const getTrendsSummarySumByIds = () => (
  createSelector(
    (state: AppState) => state.trends,
    (state: any, ids: string[]) => ids,
    getTrendsSummarySum,
  )
);

const makeGetFilteredTrends = () => createDeepEqualSelector(
  (state: AppState) => state.trends,
  (trends: any, indicators: TrendIndicatorType[]) => indicators,
  (trends: any, indicators: TrendIndicatorType[], startDate: TDate) => startDate,
  (trends: any, indicators: TrendIndicatorType[], startDate: TDate, endDate: TDate) => endDate,
  getFilteredTrends,
);

export {
  makeGetLatestDataByTrendIndicatorId,
  makeGetLatestDataPerDayByTrendIndicatorId,
  makeGetAveragedDataByTrendIndicatorId,
  getTrendsSummaryByIds,
  count,
  getTrendsSummarySumByIds,
  makeGetFilteredTrends,
  makeGetDataPerMinuteByTrendIndicatorId,
  makeGetLatestDataPerDayByTrendIndicators,
};
