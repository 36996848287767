import React, { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';

import Button from '../components/Button';
import { AppState } from '../state/reducers';
import { MessageType, PushNotificationType, TDrugModule } from '../../types';

import '../css/Message.css';
import { actions as messagesActions, selectors as messagesSelectors } from '../state/messages';
import { actions as pushNotificationsActions, selectors as pushNotificationsSelectors } from '../state/notifications';
import { actions as apiActions } from '../state/api';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRightHeader from '../components/DrawerRightHeader';
import Colors from '../theme/Colors';

type Params = {
  messageId: string;
  isPushNotification?: string
};

type MessageDrugModule = {
  [key: string]: TDrugModule;
};

const messageIds: MessageDrugModule = {
  'b49a3097-aeb9-4e36-8ca2-ae35096f411d': 'enhertu',
  '57e01a92-351e-41cd-b8e6-3b7c0afbd5a7': 'lynparza',
};

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const Message = () => {
  const { i18n, t } = useTranslation();

  const { messageId, isPushNotification } = useParams<Params>();

  const dispatch = useDispatch();

  const history = useHistory();

  const isPushMailbox = (isPushNotification === 'true');

  const memoizedMessagesGetByIdSelector = useMemo(
    messagesSelectors.makeGetById,
    [],
  );

  const message = (
    useSelector((state: AppState) => memoizedMessagesGetByIdSelector(state, messageId))
  ) as MessageType;

  const memoizedPushNotificationsGetByIdSelector = useMemo(
    pushNotificationsSelectors.makeGetById,
    [],
  );

  const pushNotification = (
    useSelector((state: AppState) => memoizedPushNotificationsGetByIdSelector(state, messageId))
  ) as PushNotificationType;

  const drugModule = isPushMailbox ? pushNotification.data?.drugModule : '';

  useEffect(() => {
    if (isPushMailbox) {
      dispatch(pushNotificationsActions.setReadAt([pushNotification.id]));
    } else {
      dispatch(messagesActions.setReadAt([message.id]));
    }
  }, [dispatch, isPushMailbox, isPushNotification, message, pushNotification.id]);

  const title = isPushMailbox ? get(pushNotification, ['title', i18n.language]) : get(message, ['title', i18n.language]) ?? message.title;
  let body = isPushMailbox ? get(pushNotification, ['body', i18n.language]) : get(message, ['body', i18n.language]) ?? message.body;
  body = body?.replace('href="DrugModule"', `href="/support-menu/${messageIds[message.id]}"`);

  const pushNotificationNavigation = () => {
    if (isPushMailbox) {
      dispatch(apiActions.validateNavigation({
        contentType: drugModule,
        onSuccess: () => {
          history.push({ pathname: `/support-menu/${drugModule}`, state: { isFromDrugWelcomeScreen: false } });
        },
      }));
    }
  };

  return (
    <article className="page diary row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('message')} showRightButton />
          <section className="message">
            <h1 className="heading-secondary">{title}</h1>
            <p className="body" dangerouslySetInnerHTML={{ __html: body }} />

            {drugModule !== '' && isPushMailbox
              ? (
                <Button
                  labelText={title}
                  onClick={pushNotificationNavigation}
                  color={Colors.secondary}
                  size="medium"
                />
              )
              : null}
          </section>
        </section>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default Message;
