import React from 'react';
import trim from 'lodash/trim';

import { TContact } from '../../types';

import ListItem from './ListItem';
import { ReactComponent as IconProfileCard } from '../assets/icons/ProfileCard.svg';
import { ReactComponent as IconChevronRight } from '../assets/icons/ChevronRight.svg';
import Colors from '../theme/Colors';

type Props = {
  contact: TContact;
  divider?: boolean;
  dividerOffset?: number;
};

const ContactListItem = (props: Props) => {
  const {
    contact,
    divider = true,
    dividerOffset = 0,
  } = props;

  const { firstName, lastName, company } = contact;

  let primaryText = company;
  let secondaryText = company;

  if (firstName || lastName) {
    primaryText = trim(`${contact.firstName} ${contact.lastName}`);
  }

  if (!secondaryText || primaryText === secondaryText) {
    secondaryText = '';
  }

  return (
    <ListItem
      primaryText={primaryText}
      secondaryText={secondaryText || <div>&nbsp;</div>}
      leftIcon={<IconProfileCard fill={Colors.primary} className="icon" />}
      rightIcon={<IconChevronRight fill={Colors.primary} className="icon small" />}
      divider={divider}
      dividerOffset={dividerOffset}
      to={`/contacts/${contact.id}`}
    />
  );
};

export default ContactListItem;
