/* eslint-disable max-len */

import React from 'react';
import { NavLink } from 'react-router-dom';
import { TDrugModule } from '../../types';
import veryShortBreathImg from '../assets/images/very-short-breath-active.png';
import temperatureImg from '../assets/images/temperature.png';
import tiredImg from '../assets/images/tired.png';
import veryTiredImg from '../assets/images/very-tired.png';
import { ReactComponent as IconDoctor } from '../assets/icons/Doctor.svg';
import { ReactComponent as IconBloodCell } from '../assets/icons/BloodCell.svg';
import { ReactComponent as IconHeart } from '../assets/icons/HeartOrgan.svg';
import { ReactComponent as DegreeSchedule } from '../assets/icons/DegreeSchedule.svg';
import { ReactComponent as IconLungs } from '../assets/icons/Lungs.svg';
import { ReactComponent as IconLiver } from '../assets/icons/Liver.svg';
import { ReactComponent as IconWarning } from '../assets/icons/Warning.svg';

import Colors from '../theme/Colors';

const getEnhertuSideEffectData = () => (
  <>
    <h3>Your guide for Side Effects to look out for</h3>

    <div className="content-block">
      <h4>
        What are the key signs of serious side effects to watch out for?
      </h4>
      <p>
        Trastuzumab deruxtecan can cause serious side effects that you can find listed in full in the trastuzumab deruxtecan <a href="https://www.medicines.org.uk/emc/files/pil.12135.pdf" rel="noreferrer" target="_blank">patient information leaflet (PIL)</a>. You should watch out for the following:
      </p>

      <IconLungs className="img-center icon" />
      <h3 className="secondary align-center">
        Lung problems like interstitial
        lung disease
      </h3>

      <p>Call or see your oncology treatment team right away if you develop any of the following signs and symptoms or
        if these symptoms get worse:
      </p>

      <ul className="two-column">
        <li>Cough</li>
        <li>Trouble breathing or shortness of breath</li>
        <li>Fever</li>
        <li>Other new or worsening breathing symptoms</li>
      </ul>

      <p>
        These may be symptoms of a serious and potentially fatal lung disease called interstitial lung disease. You should let your oncology treatment team know as soon as you experience any of these symptoms because early detection of  lung problems can reduce the severity of scarring or inflammation to the lungs. If you develop lung problems, your oncology treatment team may treat you with anti-inflammatory medicines known as corticosteroids to help with any inflammation of the lungs. Your oncology treatment team may need to temporarily pause or permanently stop your trastuzumab deruxtecan treatment.
      </p>

      <img
        className="img-center schedule"
        src={veryShortBreathImg}
        alt="while active"
      />

      <p className="italic-text  align-center">
        For illustrative purposes
      </p>
      <p className="mt40">
        If you assess that you are out of breath after taking the fitness test (using the sliders), you should contact your care team.
      </p>

      <IconBloodCell className="img-center icon" />
      <h3 className="secondary align-center">
        An infection caused by a reduced number of neutrophils (a type of white blood cell)
      </h3>

      <p>Tell your oncology treatment team immediately if you develop any of the following signs or symptoms as this could indicate neutropenia.</p>

      <ul className="two-column">
        <li>Chills</li>
        <li>Fever</li>
        <li>Sores in your mouth</li>
        <li>Stomach pain</li>
        <li>Pain when urinating</li>
      </ul>

      <p>Low white blood cell counts are very common with trastuzumab deruxtecan treatment. Because white blood cells are vital for your immune system to fight off infections, your oncology treatment team will check your white blood cell counts before starting trastuzumab deruxtecan treatment and before each dose. If neutropenia is found, your oncology treatment team may temporarily pause your trastuzumab deruxtecan treatment until your neutropenia shows signs of improvement or may permanently reduce your dose.</p>

      <img
        className="img-center schedule"
        src={temperatureImg}
        alt="temperature"
      />

      <p className="italic-text  align-center">
        For illustrative purposes
      </p>
      <p className="mt40">
        If your body temperature increases to 37.5°C or above, you should contact your doctor immediately.
      </p>

      <IconHeart className="img-center icon" />
      <h3 className="secondary align-center">
        Heart problems
      </h3>

      <p>Signs and symptoms related to heart problems may include:  </p>

      <ul className="two-column">
        <li>New or worsening shortness of breath</li>
        <li>Coughing</li>
        <li>Feeling tired</li>
        <li>Swelling of your ankles or legs</li>
        <li>Irregular heartbeat</li>
        <li>Sudden weight gain</li>
        <li>Dizziness</li>
        <li>Loss of consciousness</li>
      </ul>

      <p>Your oncology team will check your heart function before starting treatment with trastuzumab deruxtecan and at regular intervals as needed</p>

      <img
        className="img-center schedule"
        src={tiredImg}
        alt="tired"
      />

      <p className="italic-text  align-center">
        For illustrative purposes
      </p>
      <p className="mt40">
        If you are feeling a level of fatigue that is interfering with some of your normal activities, you should contact your doctor immediately.
      </p>

      <IconLiver className="img-center icon" />
      <h3 className="secondary align-center">
        Liver problems
      </h3>

      <p>Your oncology team may have to monitor your liver function while you are taking trastuzumab deruxtecan.</p>

      <p>For further information on possible side effects that you may experience while taking trastuzumab deruxtecan, please read the Patient Information Leaflet in your pack or see <NavLink className="navigation underline secondary" activeClassName="emphasized" exact to="/support-menu/enhertu/treatment-guide/side-effects">Side effects that you may experience</NavLink>.
      </p>

    </div>
  </>
);

const getLynparzaSideEffectData = () => (
  <>
    <h3>Your guide for Side Effects to look out for<span className="sup">1</span></h3>

    <div className="content-block">

      <div className="bordered-text-block">
        <IconWarning className="treatment img-center icon" fill={Colors.primary} />

        <h3 className="primary align-center">
          Side effects
        </h3>
        <p className="primary align-center">
          Information on serious potential side effects of olaparib is provided below.
          For further information on possible side effects that you may experience while taking olaparib, please read the {' '}
          <a href="https://www.medicines.org.uk/emc/files/pil.9204.pdf" rel="noreferrer" target="_blank">Patient Information Leaflet</a>
          , or see{' '}
          <NavLink activeClassName="emphasized" exact to="/support-menu/lynparza/treatment-guide/side-effects">
            <span>side effects that you may experience during treatment</span>
          </NavLink> in the treatment guide section.
        </p>
      </div>

      <h4>
        What are the key signs of serious side effects to watch out for?
      </h4>
      <p>
        Olaparib can cause serious side effects that you should watch out for, including:
      </p>
      <p>
        Please tell your doctor or prescriber immediately if you notice any of the following
        symptoms:
      </p>

      <IconBloodCell className="img-center icon" />
      <h3 className="secondary align-center">
        Low blood cell counts
      </h3>

      <p>If you have low blood cell counts on testing. These may be low counts for red or white blood cells, or low platelet counts. Signs to look out for:</p>

      <ul className="two-column">
        <li>Fever</li>
        <li>Infection</li>

        <li>Bruising</li>
        <li>Bleeding</li>
      </ul>

      <p>
        Rarely, these may be a sign of more serious problems with the bone marrow such as &apos;myelodysplastic syndrome&apos;
        (MDS) or &apos;acute myeloid leukaemia&apos; (AML).
      </p>

      <DegreeSchedule className="img-center schedule" />

      <p className="italic-text  align-center">
        For illustrative purposes
      </p>
      <p className="mt40">
        If your body temperature increases to 37.5°C or above, you should contact your doctor immediately.
      </p>

      <IconLungs className="img-center icon" />

      <h3 className="secondary align-center">
        Pneumonitis
      </h3>

      <p>
        If you experience any new or worsening symptoms of:
      </p>

      <ul className="two-column">
        <li>Shortness of breath</li>
        <li>Coughing</li>
        <li>Wheezing</li>
      </ul>

      <p>
        A small number of patients treated with Lynparza experienced inflammation of the lungs (pneumonitis). Pneumonitis
        is a serious condition that can often require hospital treatment.<span className="sup">2</span>
      </p>

      <img
        className="img-center schedule"
        src={veryShortBreathImg}
        alt="while active"
      />

      <p className="italic-text  align-center">
        For illustrative purposes
      </p>
      <p className="mt40">
        If you notice you are very short of breath when active, you should contact your doctor immediately.
      </p>
      <br />
      <hr />

      <IconDoctor className="img-center icon" fill={Colors.secondary} />
      <h3 className="secondary align-center">Tell your doctor straight away if you notice any of the following</h3>
      <ul>
        <li>A small number of patients treated with Lynparza have been reported to develop a blood
          clot in a deep vein, usually in the leg (venous thrombosis), or a clot in the lungs (pulmonary
          embolism). Symptoms may include new or worsening symptoms of pain or swelling in an
          extremity, shortness of breath, chest pain, breathing that is more rapid than normal or heart
          beats faster than normal
        </li>

        <li>Liver problems, with signs such as yellowing of your skin or the whites of your eyes (jaundice),
          unexplained nausea or vomiting, pain on the right side of your stomach area (abdomen),
          dark urine (brown coloured), feeling less hungry than usual, tiredness
        </li>

      </ul>

      <br />

      <h3 className="secondary align-center">
        Very common (may affect more than 1 in 10 people)
      </h3>

      <ul className="two-column">
        <li>Feeling short of breath</li>
        <li>Feeling very tired</li>
        <li>Pale skin</li>
        <li>Fast heartbeat</li>
      </ul>

      <p>These may be symptoms of a decrease in the number of red blood cells (anaemia).</p>
      <img
        className="img-center schedule"
        src={veryTiredImg}
        alt="tired"
      />

      <p className="italic-text  align-center">
        For illustrative purposes
      </p>
      <p className="mt40">
        If you notice you are very tired, you should contact your doctor immediately.
      </p>

      <br />

      <h3 className="secondary align-center">Uncommon (may affect up to 1 in 100 people)</h3>
      <ul>
        <li>Allergic reactions (e.g. swelling of the face, lips, tongue or throat, hives, difficulty breathing or
          swallowing, dizziness which are signs and symptoms of hypersensitivity reactions
        </li>

        <li>Itchy rash or swollen, reddened skin (dermatitis)</li>

        <li>Serious problems with bone marrow (myelodysplastic syndrome or acute myeloid leukaemia)</li>
      </ul>

      <br />

      <h3 className="secondary align-center">Rare side effects (may affect up to 1 in 1,000 people)</h3>
      <ul>
        <li>Facial swelling (angioedema)</li>
        <li>Painful inflammation of the fatty tissue under the skin (erythema nodosum)</li>
      </ul>

      <p>
        For further information on possible side effects that you may experience while taking olaparib, please read the <a href="https://www.medicines.org.uk/emc/files/pil.9204.pdf" rel="noreferrer" target="_blank">Patient Information Leaflet</a>.
      </p>

      <br />

      <h4>References</h4>
      <ol>
        <li>
          LYNPARZA (olaparib) 100 mg and 150 mg film-coated tablets. Summary of Product Characteristics
        </li>
        <li>
          AstraZeneca. Package leaflet: Information for the patient
        </li>
      </ol>
    </div>
  </>
);

const getSideEffectData = (drugModule: TDrugModule) => {
  switch (drugModule) {
    case 'enhertu':
      return getEnhertuSideEffectData();
    case 'lynparza':
      return getLynparzaSideEffectData();
    default:
      return null;
  }
};

export default getSideEffectData;
