import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import toUpper from 'lodash/toUpper';
import includes from 'lodash/includes';
import has from 'lodash/has';
import uniq from 'lodash/uniq';

import { AppState } from '../state/reducers';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRightHeader from '../components/DrawerRightHeader';
import Modal from '../components/Modal';
import Button from '../components/Button';
import Colors from '../theme/Colors';
import {
  selectors as settingsSelectors,
  actions as settingsActions,
} from '../state/settings';
import { actions as apiActions } from '../state/api';
import testInvitationCodeByArray from '../utils/testInvitationCodeByArray';

import azInvitationCodes from '../../data/azInvitationCodes';
import { selectors as tagsSelectors } from '../state/tags';
import { selectors as hospitalsSelectors } from '../state/hospitals';
import { CodeTypes, getCurrentCodeType, getLinkedCodesTypes } from '../utils/inviteCodeHelpers';

import '../css/InvitationCodes.css';

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const InvitationCodeAdd = () => {
  const history = useHistory();

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [newCode, setNewCode] = useState('');
  const [showInvalidCodeModal, setShowInvalidCodeModal] = useState(false);

  const invitationCodes = useSelector(
    (state: AppState) => settingsSelectors.getAllInvitationCodes(state),
  );

  const memoizedTagsMakeGetValueByKey = useMemo(
    tagsSelectors.makeGetValueByKey,
    [],
  );

  const country = useSelector(
    (state: AppState) => memoizedTagsMakeGetValueByKey(state, 'country'),
  ) as string;

  const hospitalCodes = (
    useSelector((state: AppState) => settingsSelectors.getAllHospitalGroupAccessCodes(state))
  );

  const codeTypes = getLinkedCodesTypes(invitationCodes, country);

  const hospitals = useSelector((state: AppState) => hospitalsSelectors.getAll(state));

  const validHospitalCodes = uniq(map(hospitals, 'hospitalGroup'));

  const showErrorCodeModal = () => {
    setShowInvalidCodeModal(true);
  };

  const hideErrorCodeModal = () => {
    setNewCode('');
    setShowInvalidCodeModal(false);
  };

  const handleHospitalCodeAdd = () => {
    if (!includes(validHospitalCodes, newCode)) {
      showErrorCodeModal();
      return;
    }

    dispatch(settingsActions.hospitalGroupAccessCodesAdd([newCode]));
    setLoading(false);
    setNewCode('');
    history.push('/settings/invitation-codes');
  };

  const handleCodeAdd = () => {
    if (!newCode) {
      return false;
    }

    if (includes(invitationCodes, newCode.toUpperCase())
    || has(codeTypes, getCurrentCodeType(newCode, country))) {
      showErrorCodeModal();
      return false;
    }

    if (includes(hospitalCodes, newCode.toUpperCase())) {
      showErrorCodeModal();
      return false;
    }

    setLoading(true);

    if (getCurrentCodeType(newCode, country, validHospitalCodes) === CodeTypes.hospital) {
      handleHospitalCodeAdd();
    } else {
      dispatch(
        apiActions.validateInvite({
          code: newCode,
          country,
          onSuccess: () => {
            dispatch(settingsActions.invitationCodesAdd([newCode.toUpperCase()]));
            setLoading(false);
            setNewCode('');
            if (testInvitationCodeByArray(newCode, azInvitationCodes[country])) {
            // to stop tracking user data call
              window.Countly.q.push(['opt_out']);
              history.push({
                pathname: '/settings/invitation-codes/treatment-selection',
                state: {
                  invitationCode: newCode,
                  isFromAddCodeAndSupport: false,
                },
              });
            } else {
              history.push('/settings/invitation-codes');
            }
            return true;
          },
          onError: () => {
            if (testInvitationCodeByArray(newCode, azInvitationCodes[country])) {
              window.Countly.opt_in();
            }
            setLoading(false);
            showErrorCodeModal();
            return false;
          },
        }),
      );
    }
    return true;
  };

  return (
    <article className="page diary row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('add-invitation-code')} showRightButton />
          <h1 className="heading-secondary accent">{t('code-title')}</h1>
          <p className="body">{t('code-description')}</p>
          <section className="diary-item">
            <div className="add-code">
              <label htmlFor="new-code">
                <span className="body light">{t('code')}</span>
                <input
                  value={newCode}
                  onChange={(event) => setNewCode(toUpper(event.currentTarget.value))}
                  id="new-code"
                  name="new-code"
                />
              </label>
              {!loading ? (
                <Button onClick={handleCodeAdd} labelText={t('add')} />
              ) : (
                <Loader
                  type="TailSpin"
                  color={Colors.primary}
                  height={24}
                  width={24}
                />
              )}
            </div>
          </section>
        </section>
        <Footer />
      </section>
      <DrawerRight />

      <Modal
        className="default-modal welcome-modal align-center"
        visible={showInvalidCodeModal}
        hideModal={hideErrorCodeModal}
        actions={[
        ]}
      >
        <p>{ getCurrentCodeType(newCode, country) === CodeTypes.regional
          ? t('modals.invalid-region-code.title')
          : t('modals.invalid-code.title')}
        </p>
        <Button labelText={t('close')} size="medium" onClick={hideErrorCodeModal} />
      </Modal>

    </article>
  );
};

export default InvitationCodeAdd;
