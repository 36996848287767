/* eslint react/require-default-props: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Path } from 'react-native-svg';
import ResizeableSVG from '../ResizeableSVG';

import Colors from '../../theme/Colors';
import Constants from '../../theme/Constants';

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

type Props = {
  color?: string;
  size?: number;
};

const Share = (props: Props) => {
  const {
    color = Colors.primary,
    size = Constants.defaultIconSize,
  } = props;

  return (
    <ResizeableSVG size={size}>
      <Path d="m22.6108061 9.25796678c.9516175 0 1.7265307.77405122 1.7265307 1.72566872v13.7898339c0 .9516174-.7749132 1.7265306-1.7265307 1.7265306h-17.23513742c-.9516175 0-1.72566868-.7749132-1.72566868-1.7265306v-13.7898339c0-.9516175.77405118-1.72566872 1.72566868-1.72566872l3.44616551.00172394c.47580875 0 .86197237.38616362.86197237.86197238 0 .4758087-.38616362.8619724-.86197237.8619724l-3.44616551-.001724-.00172395 13.7915579 17.23686137.0025859.0025859-13.7915579-3.4478894-.0025859c-.4766707 0-.8619724-.3861636-.8619724-.8619724 0-.4758087.3853017-.86197232.8619724-.86197232zm-9.2266384-7.50519338c.3370312-.3370312.8817978-.3370312 1.2188289 0l4.3098619 4.30986182c.3370312.3370312.3370312.88179773 0 1.21882893-.1680846.16808461-.3887496.2525579-.6094145.2525579s-.4413298-.08447329-.6094145-.2525579l-2.838475-2.838475v12.57272895c0 .4766707-.3861636.8619723-.8619723.8619723-.4758088 0-.8619724-.3853016-.8619724-.8619723v-12.57272895l-2.838475 2.838475c-.33703117.33703119-.88179771.33703119-1.2188289 0-.3370312-.3370312-.3370312-.88179773 0-1.21882893z" fill={color} fillRule="evenodd" />
    </ResizeableSVG>
  );
};

Share.propTypes = propTypes;

export default Share;
