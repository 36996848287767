/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-dropdown';
import Tooltip from 'react-tooltip';
import map from 'lodash/map';
import range from 'lodash/range';
import toString from 'lodash/toString';
import get from 'lodash/get';
import first from 'lodash/first';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import without from 'lodash/without';
import union from 'lodash/union';
import isEqual from 'lodash/isEqual';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import isArray from 'lodash/isArray';
import some from 'lodash/some';
import size from 'lodash/size';

import toNumber from 'lodash/toNumber';
import { AppState } from '../state/reducers';
import { ProfileQuestionType, TProfileQuestionAnswerState } from '../../types';

import ProfileQuestionTNMInput from './ProfileQuestionTNMInput';
import ProfileQuestionMedicalConditionInput from './ProfileQuestionMedicalConditionInput';
import ProfileQuestionSingleWithImagesInput from './ProfileQuestionSingleWithImagesInput';
import RadioGroup from './RadioGroup';
import Button from './Button';
import CheckboxGroup from './CheckboxGroup';
import { selectors as profileQuestionsSelectors } from '../state/profileQuestions';
import {
  actions as profileQuestionAnswersActions,
  selectors as profileQuestionAnswersSelectors,
} from '../state/profileQuestionAnswers';
import { selectors as tagsSelectors } from '../state/tags';
import { actions as uiActions } from '../state/ui';
import { actions as reportsActions } from '../state/reports';
import getLocalizedText from '../utils/getLocalizedText';
import defaultGet from '../utils/defaultGet';
import { ReactComponent as IconInfo } from '../assets/icons/Info.svg';
import { ReactComponent as IconChevronLeft } from '../assets/icons/ChevronLeft.svg';
import Colors from '../theme/Colors';
import getCurrentYear from '../utils/getCurrentYear';
import getUnixTime from '../utils/getUnixTime';
import isOfType from '../utils/isOfType';
import OncotypeDXInput from './OncotypeDXInput';

type TFormErrors = {
  values?: string;
};

type Props = {
  prevQuestionId?: string;
  nextQuestionId?: string;
  questionId: string;
  isFirstQuestion?: boolean;
  isLastQuestion?: boolean;
};

const propTypes = {
  prevQuestionId: PropTypes.string,
  nextQuestionId: PropTypes.string,
  isFirstQuestion: PropTypes.bool,
  isLastQuestion: PropTypes.bool,
};

let years = map(range(getCurrentYear(), 1899), (year) => toString(year));

const questionYearIds = {
  yearOfBirth: '0000000a-0000-1000-a000-000000000002',
  yearOfDiagnosed: '0000000a-0000-1000-a000-000000000006',
};

const ProfileQuestion = (props: Props) => {
  const {
    prevQuestionId,
    nextQuestionId,
    questionId,
    isFirstQuestion = false,
    isLastQuestion = false,
  } = props;

  const [values, setValues] = useState<string[]>(['']);
  const [errors, setErrors] = useState<TFormErrors>({});

  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  const history = useHistory();

  const tags = useSelector((state: AppState) => tagsSelectors.getAll(state));

  const memoizedProfileQuestionGetById = useMemo(
    profileQuestionsSelectors.makeGetById,
    [],
  );

  const question = useSelector(
    (state: AppState) => memoizedProfileQuestionGetById(state, questionId),
  );

  const memoizedProfileQuestionAnswersGetByProfileQuestionId = useMemo(
    profileQuestionAnswersSelectors.makeGetByProfileQuestionId,
    [],
  );

  const answer = useSelector(
    (state: AppState) => memoizedProfileQuestionAnswersGetByProfileQuestionId(state, questionId),
  );

  const yearOfBirthAnswer = useSelector(
    (state: AppState) => memoizedProfileQuestionAnswersGetByProfileQuestionId(state, questionYearIds.yearOfBirth),
  ) as {year: string};

  const yearOfDiagnosedAnswer = useSelector(
    (state: AppState) => memoizedProfileQuestionAnswersGetByProfileQuestionId(state, questionYearIds.yearOfDiagnosed),
  ) as {year: string};

  const isQuestionYearOfBirth = questionId === questionYearIds.yearOfBirth;
  const defaultAnswers = get(question, ['defaultAnswers', i18n.language], []);

  useEffect(() => {
    const firstDefaultAnswer = first(defaultAnswers) || '';

    let defaultValues: string[] = [];

    if (isOfType<ProfileQuestionType>(question, 'id')) {
      if (question.answerType === 'single') {
        defaultValues = [firstDefaultAnswer as string];

        if (isOfType<TProfileQuestionAnswerState>(answer, 'id')) {
          const profileQuestionAnswerId = first(answer.profileQuestionAnswerIds) || '';
          defaultValues = [profileQuestionAnswerId];
        }
      } else if (question.answerType === 'year') {
        if (yearOfBirthAnswer && !isQuestionYearOfBirth) {
          years = filter(years, (item) => Number(item) >= Number(yearOfBirthAnswer.year));
        } else {
          years = map(range(getCurrentYear(), 1899), (year) => toString(year));
        }
        const year = defaultGet(answer, 'year', firstDefaultAnswer);
        defaultValues = [year];
      } else if (question.answerType === 'date') {
        const date = defaultGet(answer, 'date', firstDefaultAnswer);
        defaultValues = [date];
      } else if (question.answerType === 'text') {
        const text = defaultGet(answer, 'text', firstDefaultAnswer);
        defaultValues = [text];
        // multiple
        // stage
      } else {
        defaultValues = defaultGet(answer, 'profileQuestionAnswerIds', defaultAnswers);
      }

      if (!isEqual(values, defaultValues)) {
        setValues(defaultValues);
      }
    }
  }, [question, answer]);

  if (!isOfType<ProfileQuestionType>(question, 'id')) {
    return null;
  }

  const handleSave = () => {
    if (question.answerType !== 'oncotype-dx'
      && isEmpty(compact(values))) {
      if (isEmpty(errors)) {
        setErrors({ values: t('errors:field-required', { field: t('field') }) });
      }

      return false;
    }

    setErrors({});

    let profileQuestionAnswerIds;
    let year;
    let date;
    let text;
    let value;

    if (
      question.answerType === 'single'
      || question.answerType === 'single-with-images'
      || question.answerType === 'multiple'
      || question.answerType === 'medical-condition'
      || question.answerType === 'stage'
    ) {
      profileQuestionAnswerIds = values;
    }

    if (question.answerType === 'year') {
      if (isQuestionYearOfBirth) {
        if (Number(yearOfDiagnosedAnswer?.year) < Number(first(values))) {
          dispatch(profileQuestionAnswersActions.deleteById(questionYearIds.yearOfDiagnosed));
          year = first(values);
        } else {
          year = first(values);
        }
      } else {
        year = first(values);
      }
    }

    if (question.answerType === 'date') {
      date = first(values);
    }

    if (question.answerType === 'text') {
      text = first(values);
    }

    if (question.answerType === 'oncotype-dx') {
      value = toNumber(first(values));
    }

    if (isOfType<TProfileQuestionAnswerState>(answer, 'id')) {
      dispatch(profileQuestionAnswersActions.update([{
        ...answer,
        answeredAt: getUnixTime(),
        profileQuestionAnswerIds,
        year,
        date,
        text,
        value,
      }]));
    } else {
      dispatch(profileQuestionAnswersActions.add([{
        id: question.id,
        createdAt: getUnixTime(),
        answeredAt: getUnixTime(),
        profileQuestionId: question.id,
        profileQuestionAnswerIds,
        year,
        date,
        text,
        value,
      }]));
    }

    if (nextQuestionId && !isLastQuestion) {
      return history.push(`/profile/complete/${nextQuestionId}`);
    }

    if (isLastQuestion) {
      // Users on a treatment plan do not see a diagnostic report
      if (includes(tags, 'treatmentplan:yes')) {
        return history.push('/');
      }

      // Generate diagnostic report
      return dispatch(reportsActions.generate({
        type: 'diagnostic-full',
        onSuccess: (reportId: string) => (
          history.push({
            pathname: `/reports/${reportId}`,
            state: { fromProfileComplete: true },
          })
        ),
        onError: () => {
          dispatch(uiActions.addNotification({
            type: 'warning',
            text: t('reports:failed-notification'),
          }));

          return history.push('/');
        },
      }));
    }

    return history.goBack();
  };
  const answerType = defaultGet(question, 'answerType', '');
  const answers = defaultGet(question, 'answers', []);
  const description = getLocalizedText(question, i18n.language, 'description');

  const onChange = (changedValue: string, checked?: boolean) => {
    if (
      question.answerType === 'multiple'
      || question.answerType === 'medical-condition'
    ) {
      if (checked) {
        return setValues(without(values, changedValue));
      }

      if (isArray(question.singleAnswers)) {
        if (some(values, (value) => includes(question.singleAnswers, value))) {
          return null;
        }
        if (size(values) && includes(question.singleAnswers, changedValue)) {
          return null;
        }
      }

      return setValues(union(values, [changedValue]));
    }

    // single
    // single-with-images
    // stage
    // psa-level
    return setValues([changedValue]);
  };

  return (
    <div className="profile-question-container">
      <header>
        <h1 className="heading">{getLocalizedText(question, i18n.language, 'title')}</h1>
        {
          description && (
            <>
              <div data-tip={description}>
                <IconInfo fill={Colors.primary} className="icon" />
              </div>
              <Tooltip
                className="tooltip body contrast"
                backgroundColor={Colors.black}
              />
            </>
          )
        }
      </header>
      {
        answerType === 'stage' && (
          <ProfileQuestionTNMInput
            question={question as ProfileQuestionType}
            value={first(values) || ''}
            onChange={onChange}
          />
        )
      } {
        answerType === 'oncotype-dx' && (
          <OncotypeDXInput
            question={question as ProfileQuestionType}
            value={toNumber(first(values)) || 0}
            onChangeValue={onChange}
            maximumValue={100}
          />
        )
      }
      {
        answerType === 'single' && (
          <RadioGroup
            options={map(answers, (a) => ({
              value: a.id,
              labelText: getLocalizedText(a, i18n.language, 'title'),
              description: getLocalizedText(a, i18n.language, 'description'),
            }))}
            value={first(values) || ''}
            onChange={onChange}
          />
        )
      }
      {
        answerType === 'year' && (
          <Dropdown
            options={years}
            onChange={(option) => setValues([option.value])}
            value={first(values) || ''}
            placeholder={t('dropdown-placeholder')}
          />
        )
      }
      {
        answerType === 'text' && (
          <input
            value={values}
            onChange={(event) => setValues([event.currentTarget.value])}
          />
        )
      }
      {
        answerType === 'medical-condition' && (
          <ProfileQuestionMedicalConditionInput
            question={question}
            values={values}
            onChangeValue={onChange}
          />
        )
      }
      {
        answerType === 'single-with-images' && (
          <ProfileQuestionSingleWithImagesInput
            question={question}
            value={first(values) || ''}
            onChangeValue={onChange}
          />
        )
      }
      {
        answerType === 'multiple' && (
          <CheckboxGroup
            options={map(answers, (a) => ({
              value: a.id,
              labelText: getLocalizedText(a, i18n.language, 'title'),
              description: getLocalizedText(a, i18n.language, 'description'),
            }))}
            values={values}
            onChange={onChange}
          />
        )
      }
      <p className="body error">{errors.values}</p>
      <div className="buttons">
        {
          prevQuestionId && !isFirstQuestion
            ? (
              <Link to={`/profile/complete/${prevQuestionId}`} className="previous">
                <IconChevronLeft fill={Colors.primary} className="icon small" />
                <p className="navigation accent">{t('previous-question')}</p>
              </Link>
            )
            : <div />
        }
        <Button labelText={isEmpty(answer) ? t('next') : t('save')} onClick={debounce(handleSave, 200)} />
      </div>
    </div>
  );
};

ProfileQuestion.propTypes = propTypes;

export default ProfileQuestion;
