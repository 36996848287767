import * as actionTypes from './actionTypes';
import {
  UIActionTypes,
  UIAddNotificationPayloadType,
  UIShowNotificationPayloadType,
  UIRecordingUpdatePayloadType,
  UIAddAlertPayloadType,
  UIModalShowPayloadType,
  UIValidateNavigationModalShowPayloadType,
} from './types';

const addNotification = (payload: UIAddNotificationPayloadType): UIActionTypes => ({
  type: actionTypes.ADD_NOTIFICATION,
  payload,
});

const showNotification = (payload: UIShowNotificationPayloadType): UIActionTypes => ({
  type: actionTypes.SHOW_NOTIFICATION,
  payload,
});

const clearNotification = (): UIActionTypes => ({
  type: actionTypes.CLEAR_NOTIFICATION,
});

const profileCompletedModalShow = (): UIActionTypes => ({
  type: actionTypes.PROFILE_COMPLETED_MODAL_SHOW,
});

const profileCompletedModalHide = (): UIActionTypes => ({
  type: actionTypes.PROFILE_COMPLETED_MODAL_HIDE,
});

// eslint-disable-next-line max-len
const validateNavigationModalShow = (payload: UIValidateNavigationModalShowPayloadType): UIActionTypes => ({
  type: actionTypes.VALIDATE_NAVIGATION_MODAL_SHOW,
  payload,
});

const validateNavigationModalHide = (): UIActionTypes => ({
  type: actionTypes.VALIDATE_NAVIGATION_MODAL_HIDE,
});

const profileIncompleteModalShow = (): UIActionTypes => ({
  type: actionTypes.PROFILE_INCOMPLETE_MODAL_SHOW,
});

const profileIncompleteModalHide = (): UIActionTypes => ({
  type: actionTypes.PROFILE_INCOMPLETE_MODAL_HIDE,
});

const recordingUpdate = (payload: UIRecordingUpdatePayloadType): UIActionTypes => ({
  type: actionTypes.RECORDING_UPDATE,
  payload,
});

const addAlert = (payload: UIAddAlertPayloadType): UIActionTypes => ({
  type: actionTypes.ADD_ALERT,
  payload,
});

const toggleDrawerLeft = () => ({
  type: actionTypes.TOGGLE_DRAWER_LEFT,
});

const toggleDrawerRight = () => ({
  type: actionTypes.TOGGLE_DRAWER_RIGHT,
});

const modalShow = (payload: UIModalShowPayloadType): UIActionTypes => ({
  type: actionTypes.MODAL_SHOW,
  payload,
});

const modalHide = (): UIActionTypes => ({
  type: actionTypes.MODAL_HIDE,
});

export {
  addNotification,
  showNotification,
  clearNotification,
  profileCompletedModalShow,
  profileCompletedModalHide,
  profileIncompleteModalShow,
  profileIncompleteModalHide,
  validateNavigationModalShow,
  validateNavigationModalHide,
  recordingUpdate,
  addAlert,
  toggleDrawerLeft,
  toggleDrawerRight,
  modalShow,
  modalHide,
};
