import * as actionTypes from './actionTypes';
import {
  TActions,
  TUpdateCreatedAtPayload,
  TUpdateTitlePayload,
  TUpdateBodyPayload,
} from './types';

const updateCreatedAt = (payload: TUpdateCreatedAtPayload): TActions => ({
  type: actionTypes.UPDATE_CREATED_AT,
  payload,
});

const updateTitle = (payload: TUpdateTitlePayload): TActions => ({
  type: actionTypes.UPDATE_TITLE,
  payload,
});

const updateBody = (payload: TUpdateBodyPayload): TActions => ({
  type: actionTypes.UPDATE_BODY,
  payload,
});

export {
  updateCreatedAt,
  updateTitle,
  updateBody,
};
