import {
  take,
  actionChannel,
  call,
  delay,
  put,
} from 'redux-saga/effects';

import {
  UIAddNotificationActionType,
  actionTypes as UIActionTypes,
  actions as UIActions,
} from '../state/ui';

function* notification(action: UIAddNotificationActionType) {
  const { payload } = action;

  yield put(UIActions.showNotification(payload));
  yield delay(5000);
  yield put(UIActions.clearNotification());
}

function* watchUINotification() {
  const requestChannel = yield actionChannel(UIActionTypes.ADD_NOTIFICATION);

  while (true) {
    const action = yield take(requestChannel);
    yield call(notification, action);
  }
}

export default function* watchUI() {
  yield watchUINotification();
}
