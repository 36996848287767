import * as actionTypes from './actionTypes';
import { NoteType } from '../../../types';
import { NotesActionTypes } from './types';

const add = (payload: NoteType[]): NotesActionTypes => ({
  type: actionTypes.ADD,
  payload,
});

const remove = (payload: string[]): NotesActionTypes => ({
  type: actionTypes.REMOVE,
  payload,
});

const update = (payload: NoteType[]): NotesActionTypes => ({
  type: actionTypes.UPDATE,
  payload,
});

const clear = (): NotesActionTypes => ({
  type: actionTypes.CLEAR,
});

export {
  add,
  remove,
  update,
  clear,
};
