/* eslint react/jsx-props-no-spreading: 0 */
import React, { useEffect, useRef, useMemo } from 'react';
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import round from 'lodash/round';
import map from 'lodash/map';
import clamp from 'lodash/clamp';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import { AppState } from '../../state/reducers';
import {
  TrendIndicatorType,
  TGraphData,
  TrendSeries,
  TDrugModule,
} from '../../../types';
import { scaleRange10ToRange5, setUpTrendGraph } from './graph';
import { handleCombinedTooltipMouseMove, Point } from './tooltips';
import { selectors as customTrendsSelectors } from '../../state/customTrends';
import getLocalizedText from '../../utils/getLocalizedText';

import SvgTrendPlot from './SvgTrendPlot';

import './styles.css';

type Props = {
  startDate: Date;
  endDate: Date;
  width: number;
  height: number;
  xPadding: number;
  yPadding: number;
  trendIndicatorList: TrendIndicatorType[];
  drugModule: TDrugModule;
  combined?: boolean
};

const getTooltipText = (
  datum: TGraphData,
  trendSeries: TrendSeries,
  language: string,
): string => {
  if (datum.originalValue) {
    if (trendSeries.labels) {
      const label = trendSeries.labels[datum.originalValue];

      if (label) {
        return getLocalizedText(label, language);
      }
    }

    const labelUnit = getLocalizedText(trendSeries, language, 'labelUnit');

    if (labelUnit) {
      return `${round(datum.originalValue)} ${labelUnit}`;
    }

    return `${round(datum.originalValue)}`;
  }

  if (trendSeries.labels) {
    let label = trendSeries.labels[datum.value];

    if (!label) {
      label = trendSeries.labels[scaleRange10ToRange5(datum.value)];
    }

    if (label) {
      return getLocalizedText(label, language);
    }
  }
  return `${round(datum.value)}`;
};

const CombinedTestFitnessTrendGraph = (props: Props) => {
  const {
    startDate,
    endDate,
    width,
    height,
    trendIndicatorList,
    xPadding,
    yPadding,
    drugModule,
    combined,
  } = props;

  const { t, i18n } = useTranslation();

  const svgRef = useRef(null);

  const memoizedGetTestFitnessLatestDataPerDayByTrendIndicators = useMemo(
    customTrendsSelectors.makeGetLatestDataPerDayByTrendIndicators,
    [],
  );

  const { trendSeries } = useSelector(
    (state: AppState) => memoizedGetTestFitnessLatestDataPerDayByTrendIndicators(
      state as any,
      drugModule,
      trendIndicatorList,
      startDate,
      endDate,
      combined,
    ),
  );

  const trendSeriesData = useMemo(() => {
    if (!combined) {
      return trendSeries;
    }

    return filter(trendSeries, (trend) => !trend.id.includes('start'));
  }, [combined, trendSeries]);

  const {
    line,
    scaleX,
    scaleY,
    xAxis,
    bisectDate,
  } = setUpTrendGraph({
    startDate,
    endDate,
    height,
    maximumValue: combined ? 10 : trendSeries[0].maximumValue,
    minimumValue: combined ? 0 : trendSeries[0].minimumValue,
    width,
    xPadding,
    yPadding,
    millis: false,
  });

  const getSvg = () => d3.select(svgRef.current);

  // This has to be a function for some reason...
  function onMousemove(this: any): void {
    handleCombinedTooltipMouseMove(
      this,
      scaleX,
      scaleY,
      bisectDate,
      trendSeriesData,
      getTooltipText,
      getSvg(),
      75,
      i18n.language,
      true,
    );
  }

  const onMouseOver = (): void => {
    const svg = getSvg();
    svg.select('.trend-graph-focus').style('display', null);
  };

  const onMouseOut = (): void => {
    const svg = getSvg();
    svg.select('.trend-graph-focus').style('display', 'none');
  };

  const points: Point[] = [];

  trendSeriesData.forEach((trend) => {
    trend.data.filter((el) => el.selectable).forEach(({ date, value, labelValue }) => {
      points.push(new Point(
        clamp(scaleX(date) || 0, 0, width) as number,
        scaleY(value) as number,
        trend.id,
        labelValue,
        undefined,
        trend.color,
      ));
    });
  });

  useEffect(() => {
    const svg = getSvg();

    (svg.select('.axis-line') as d3.Selection<
    SVGGElement,
    unknown,
    null,
    undefined
    >).call(xAxis);

    points.forEach((point, index) => {
      svg
        .select(`.trend-graph-focus-circle.index-${index}`)
        .attr('transform', `translate(${point.x}, ${point.y})`);
    });

    const dataList = trendSeriesData.map((trend) => ({
      title: getLocalizedText(trend, i18n.language, 'title'),
      data: trend.data,
    }));

    const lines = svg.selectAll('.trend-series');
    lines.data(dataList);
    lines.attr('d', (d: any) => line(d.data));

    svg.select('.trend-graph-tooltip-overlay').on('mousemove', onMousemove);

    return () => {
      svg.select('.trend-graph-tooltip-overlay').on('mousemove', null);
    };
  }, [i18n.language, line, onMousemove, points, trendSeriesData, xAxis]);

  const seriesPaths = trendSeriesData.map((trend) => (
    <path
      key={trend.id}
      className="trend-series"
      style={{ stroke: trend.color }}
    />
  ));

  const circles = map(points, (point, index) => <circle key={index} className={`trend-graph-focus-circle index-${index}`} style={{ fill: point.color || 'red' }} r={5} />);

  const generateTrendsForLegend = () => {
    if (combined) {
      return map(trendSeriesData, (trend) => (
        <div key={trend.id} className="trend-legend-item combined">
          <div className="trend-legend-line" style={{ backgroundColor: trend.color }} />
          <span>{getLocalizedText(trend, i18n.language, 'title')}</span>
        </div>
      ));
    }

    return map(trendSeries, (trend) => (
      <div key={trend.id} className="trend-legend-item"><div className="trend-legend-line" style={{ backgroundColor: trend.color }} /> <span>{t(`${trend.id.includes('start') ? 'before-test' : 'after-test'}`)}</span></div>
    ));
  };

  const shouldShowGuideText = (): boolean => !combined && !isEmpty(trendIndicatorList[0].labels);

  const labelUnit = !combined && getLocalizedText(trendIndicatorList[0].labelUnit, i18n.language);

  return (
    <>
      <svg ref={svgRef} width={width} height={height}>
        <SvgTrendPlot
          width={width}
          height={height}
          xPadding={xPadding}
          yPadding={yPadding}
          showGuideText={shouldShowGuideText()}
          showTooltipTitle={!!combined}
          labelUnit={labelUnit}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
        >
          <>
            {seriesPaths}
            {circles}
          </>
        </SvgTrendPlot>
      </svg>
      <div className={classNames('trends-legend-wrapper', { combined })}>
        {generateTrendsForLegend()}
      </div>
    </>
  );
};

export default CombinedTestFitnessTrendGraph;
