import i18next, { TOptionsBase } from 'i18next';
import { initReactI18next } from 'react-i18next';
import localforage from 'localforage';
import toLower from 'lodash/toLower';
import toUpper from 'lodash/toUpper';
import get from 'lodash/get';
import find from 'lodash/find';

import languageDetector from './languageDetector';
import translationLoader from './translationLoader';
import * as config from './config';
import localConfig from '../../config';

i18next
  .use(languageDetector)
  .use(translationLoader)
  .use(initReactI18next) // Pass i18n down to react-i18next
  .init({
    fallbackLng: config.fallbackLng,
    ns: config.namespaces,
    defaultNS: config.defaultNamespace,
    load: 'currentOnly', // Only load current language
    debug: false,
    interpolation: {
      format: (value, format) => { // eslint-disable-line @typescript-eslint/no-unused-vars
        if (format === 'lowercase') {
          return toLower(value);
        }

        if (format === 'uppercase') {
          return toUpper(value);
        }

        return value;
      },
      escapeValue: false, // Not needed for React
    },
  });

const t = (key: string, options?: TOptionsBase) => i18next.t(key, options);

const getLocale = () => i18next.language;

const onLanguageChanged = (fn: (...args: any[]) => void) => i18next.on('languageChanged', fn);

const changeLanguageAsync = async (language: string, country = 'uk') => {
  const availableLanguages = get(localConfig, ['availableLanguages', country]);
  const defaultLanguage = get(find(availableLanguages, ['default', true]), 'language');

  let availableLanguage = get(find(availableLanguages, ['language', language]), 'language');

  // Fall back to the default language if language is not available
  if (!availableLanguage) {
    availableLanguage = defaultLanguage;
  }

  // Set language in async storage
  await localforage.setItem('@Settings:language', availableLanguage);

  i18next.changeLanguage(availableLanguage);
};

export {
  t,
  getLocale,
  onLanguageChanged,
  changeLanguageAsync,
};

export default i18next;
