import { takeEvery, put, select } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import isFunction from 'lodash/isFunction';
import noop from 'lodash/noop';
import get from 'lodash/get';

import { TReport } from '../../types';

import { getLocale, t } from '../services/i18n';
import { selectors as generatedReportsSelectors } from '../state/generatedReports';
import {
  ReportsGenerateActionType,
  actionTypes as reportsActionTypes,
  actions as reportsActions,
  selectors as reportsSelectors,
} from '../state/reports';
import getUnixTime from '../utils/getUnixTime';
import getLocalizedText from '../utils/getLocalizedText';

function* generate(action: ReportsGenerateActionType) {
  const {
    payload: {
      type,
      onSuccess: actionOnSuccess,
      onError: actionOnError,
    },
  } = action;

  const onSuccess = isFunction(actionOnSuccess) ? actionOnSuccess : noop;
  const onError = isFunction(actionOnError) ? actionOnError : noop;

  try {
    const generatedReports: TReport[] = yield select(generatedReportsSelectors.getAll);
    const reportCounts: number = yield select(reportsSelectors.counts);

    const report = getLocalizedText(generatedReports, getLocale(), [type]);

    if (!report) {
      return onError();
    }

    const reportId = uuidv4();
    const count = get(reportCounts, [type]) || 0;

    yield put(reportsActions.add([{
      id: reportId,
      createdAt: getUnixTime(),
      type,
      title: `${t(`reports:${type}`)} ${count + 1}`,
      content: {
        body: report,
      },
    }]));

    return onSuccess(reportId);
  } catch (error) {
    if (__DEV__) {
      console.log('[reports.generate]', 'catch', error); // eslint-disable-line no-console
    }

    return onError(error);
  }
}

export default function* watchReports() {
  yield takeEvery(reportsActionTypes.GENERATE, generate);
}
