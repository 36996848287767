import get from 'lodash/get';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import find from 'lodash/find';
import size from 'lodash/size';
import toArray from 'lodash/toArray';

import { AppState } from '../reducers';
import { ProfileQuestionAnswersState } from './types';
import { ProfileQuestionAnswerReturnType, ProfileQuestionAnswersReturnType } from '../../../types';

import EMPTY_OBJECT from '../../utils/empty-object';
import createDeepEqualSelector from '../../selectors/createDeepEqualSelector';

const getByIdSelector = (
  state: ProfileQuestionAnswersState,
  id: string,
): ProfileQuestionAnswerReturnType => (
  get(state, [id]) || EMPTY_OBJECT
);

const makeGetById = () => (
  createDeepEqualSelector(
    (state: AppState) => state.profileQuestionAnswers,
    (state: any, id: string) => id,
    getByIdSelector,
  )
);

const getByIdsSelector = (
  state: ProfileQuestionAnswersState,
  ids: string[],
): ProfileQuestionAnswersReturnType => (
  filter(state, (answer) => includes(ids, answer.id))
);

const makeGetByIds = () => (
  createDeepEqualSelector(
    (state: AppState) => state.profileQuestionAnswers,
    (state: any, ids: string[]) => ids,
    getByIdsSelector,
  )
);

const getByProfileQuestionIdSelector = (
  state: ProfileQuestionAnswersState,
  profileQuestionId: string,
): ProfileQuestionAnswerReturnType => (
  find(state, { profileQuestionId }) || EMPTY_OBJECT
);

const makeGetByProfileQuestionId = () => (
  createDeepEqualSelector(
    (state: AppState) => state.profileQuestionAnswers,
    (state: any, profileQuestionId: string) => profileQuestionId,
    getByProfileQuestionIdSelector,
  )
);

const getAllSelector = (state: ProfileQuestionAnswersState): ProfileQuestionAnswersReturnType => (
  toArray(state)
);

const getAll = createDeepEqualSelector(
  (state: AppState) => state.profileQuestionAnswers,
  getAllSelector,
);

const countSelector = (state: ProfileQuestionAnswersState) => size(state);

const count = createDeepEqualSelector(
  (state: AppState) => state.profileQuestionAnswers,
  countSelector,
);

const countByProfileQuestionIdsSelector = (
  state: ProfileQuestionAnswersState,
  profileQuestionIds: string[],
) => (
  size(filter(
    state,
    (answer) => includes(profileQuestionIds, answer.profileQuestionId),
  ))
);

const makeCountByProfileQuestionIds = () => (
  createDeepEqualSelector(
    (state: AppState) => state.profileQuestionAnswers,
    (state: any, profileQuestionIds: string[]) => profileQuestionIds,
    countByProfileQuestionIdsSelector,
  )
);

export {
  makeGetById,
  makeGetByIds,
  makeGetByProfileQuestionId,
  getAll,
  count,
  makeCountByProfileQuestionIds,
};
