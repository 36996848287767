import React from 'react';
import Switch from './Switch';
import '../css/Agree.css';

type AgreeProps = {
  question: string;
  value: boolean;
  disabled: boolean;
  onValueChange: () => void;
};

const Agree = (props: AgreeProps) => {
  const {
    question, value, onValueChange, disabled,
  } = props;

  return (
    <div className="agree-wrapper">
      <p>{question}</p>
      <div className="switch-wrapper">
        <Switch value={value} onValueChange={onValueChange} disabled={disabled} />
      </div>
    </div>
  );
};

export default Agree;
