import * as actionTypes from './actionTypes';
import { QuestionnaireType } from '../../../types';
import {
  QuestionnairesActionTypes,
  QuestionnairesAddQuestionsPayload,
  QuestionnairesAddQuestionPhotosPayload,
  QuestionnairesAddQuestionAudioRecordingsPayload,
  QuestionnairesRemoveQuestionsPayload,
  QuestionnairesRemoveQuestionPhotosPayload,
  QuestionnairesRemoveQuestionAudioRecordingsPayload,
  QuestionnairesUpdateQuestionsPayload,
  QuestionnairesReplaceQuestionsPayload,
  QuestionnairesCheckQuestionPayload,
  QuestionnairesUncheckQuestionPayload,
} from './types';

const add = (payload: QuestionnaireType[]): QuestionnairesActionTypes => ({
  type: actionTypes.ADD,
  payload,
});

const addQuestions = (payload: QuestionnairesAddQuestionsPayload): QuestionnairesActionTypes => ({
  type: actionTypes.ADD_QUESTIONS,
  payload,
});

const addQuestionPhotos = (
  payload: QuestionnairesAddQuestionPhotosPayload,
): QuestionnairesActionTypes => ({
  type: actionTypes.ADD_QUESTION_PHOTOS,
  payload,
});

const addQuestionAudioRecordings = (
  payload: QuestionnairesAddQuestionAudioRecordingsPayload,
): QuestionnairesActionTypes => ({
  type: actionTypes.ADD_QUESTION_AUDIO_RECORDINGS,
  payload,
});

const remove = (payload: string[]): QuestionnairesActionTypes => ({
  type: actionTypes.REMOVE,
  payload,
});

const removeQuestions = (
  payload: QuestionnairesRemoveQuestionsPayload,
): QuestionnairesActionTypes => ({
  type: actionTypes.REMOVE_QUESTIONS,
  payload,
});

const removeQuestionPhotos = (
  payload: QuestionnairesRemoveQuestionPhotosPayload,
): QuestionnairesActionTypes => ({
  type: actionTypes.REMOVE_QUESTION_PHOTOS,
  payload,
});

const removeQuestionAudioRecordings = (
  payload: QuestionnairesRemoveQuestionAudioRecordingsPayload,
): QuestionnairesActionTypes => ({
  type: actionTypes.REMOVE_QUESTION_AUDIO_RECORDINGS,
  payload,
});

const update = (payload: QuestionnaireType[]): QuestionnairesActionTypes => ({
  type: actionTypes.UPDATE,
  payload,
});

const updateQuestions = (
  payload: QuestionnairesUpdateQuestionsPayload,
): QuestionnairesActionTypes => ({
  type: actionTypes.UPDATE_QUESTIONS,
  payload,
});

const replaceQuestions = (
  payload: QuestionnairesReplaceQuestionsPayload,
): QuestionnairesActionTypes => ({
  type: actionTypes.REPLACE_QUESTIONS,
  payload,
});

const clear = (): QuestionnairesActionTypes => ({
  type: actionTypes.CLEAR,
});

const checkQuestion = (payload: QuestionnairesCheckQuestionPayload): QuestionnairesActionTypes => ({
  type: actionTypes.CHECK_QUESTION,
  payload,
});

const uncheckQuestion = (
  payload: QuestionnairesUncheckQuestionPayload,
): QuestionnairesActionTypes => ({
  type: actionTypes.UNCHECK_QUESTION,
  payload,
});

export {
  add,
  addQuestions,
  addQuestionPhotos,
  addQuestionAudioRecordings,
  remove,
  removeQuestions,
  removeQuestionPhotos,
  removeQuestionAudioRecordings,
  update,
  updateQuestions,
  replaceQuestions,
  clear,
  checkQuestion,
  uncheckQuestion,
};
