import React from 'react';
import { useSelector } from 'react-redux';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { AppState } from '../state/reducers';

import { selectors as contactsSelectors } from '../state/contacts';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import DrawerButtons from '../components/DrawerButtons';
import ContactListItem from '../components/ContactListItem';
import DrawerRightHeader from '../components/DrawerRightHeader';
import Button from '../components/Button';

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const Contacts = () => {
  const { t } = useTranslation();

  const contacts = useSelector((state: AppState) => contactsSelectors.getAll(state));

  const history = useHistory();

  return (
    <article className="page contacts row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('phone-book')} showRightButton />
          <section className={classNames('list', { empty: isEmpty(contacts) })}>
            {
              !isEmpty(contacts)
                ? map(contacts, (contact, index) => (
                  <ContactListItem
                    contact={contact}
                    key={contact.id}
                    divider={(index + 1 !== size(contacts))}
                  />
                ))
                : <span className="body light">{t('contacts:empty-state-heading')}</span>
            }
          </section>
          <Button
            labelText={t('add-contact')}
            size="medium"
            onClick={() => history.push('/contacts/add')}
          />
        </section>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default Contacts;
