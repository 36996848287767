import { combineReducers, Reducer } from 'redux';
import union from 'lodash/union';
import without from 'lodash/without';
import uniq from 'lodash/uniq';
import forEach from 'lodash/forEach';

import * as utils from './utils';
import * as actionTypes from './actionTypes';
import {
  SettingsTrendIndicatorsState,
  SettingsTrendIndicatorsActionTypes,
  SettingsTrendIndicatorsUpdateActionType,
  SettingsTrendIndicatorsUpdateDisabledActionType,
  SettingsTrendIndicatorsUpdateFavoriteActionType,
  SettingsTrendIndicatorsUpdateOrderActionType,
  SettingsGeneralState,
  SettingsGeneralActionTypes,
  SettingsInvitationCodesState,
  SettingsInvitationCodesActionTypes,
  SettingsHospitalGroupAccessCodesState,
  SettingsHospitalGroupAccessCodesActionTypes,
  SettingsBannersState,
  SettingsBannersActionTypes,
  AccountStatusState,
  ChangeAccountStatusActionType,
  IsFirstLaunchState,
  IsFirstLaunchActionType,
  ContentModulesUpdateActionType,
  ContentModuleActionTypes,
  ContentModulesState,
} from './types';
import { trendIndicators as initialEnabledTrendIndicators } from '../../../data/system-document.json';

const getTrendIndicatorsInitialState = () => {
  const defaultState: SettingsTrendIndicatorsState = {};
  forEach(initialEnabledTrendIndicators, (item, key) => {
    if (item.disabled) {
      (defaultState[key] = {
        id: key,
        disabled: true,
        updatedAt: Date.now(),
        order: item.order,
      });
    }
  });

  return defaultState;
};

const trendIndicators: Reducer<
SettingsTrendIndicatorsState,
SettingsTrendIndicatorsActionTypes
> = (state = getTrendIndicatorsInitialState(), action) => {
  switch (action.type) {
    case actionTypes.TREND_INDICATORS_UPDATE: {
      return utils.trendIndicatorsUpdate(
        state,
        action as SettingsTrendIndicatorsUpdateActionType,
      );
    }
    case actionTypes.TREND_INDICATORS_UPDATE_DISABLED: {
      return utils.trendIndicatorsUpdateDisabled(
        state,
        action as SettingsTrendIndicatorsUpdateDisabledActionType,
      );
    }
    case actionTypes.TREND_INDICATORS_UPDATE_FAVORITE: {
      return utils.trendIndicatorsUpdateFavorite(
        state,
        action as SettingsTrendIndicatorsUpdateFavoriteActionType,
      );
    }
    case actionTypes.TREND_INDICATORS_UPDATE_ORDER: {
      return utils.trendIndicatorsUpdateOrder(
        state,
        action as SettingsTrendIndicatorsUpdateOrderActionType,
      );
    }
    case actionTypes.TREND_INDICATORS_CLEAR: return getTrendIndicatorsInitialState();
    case actionTypes.CLEAR: return getTrendIndicatorsInitialState();
    default: return state;
  }
};

const generalInitialState: SettingsGeneralState = {
  profileCompleted: false,
  profileCompleteModalShown: false,
  profileCompletedModalShown: false,
  profileIncompleteModalShown: false,
  showIntro: true,
  suggestions: {
    enabled: true,
  },
};

const general: Reducer<
SettingsGeneralState,
SettingsGeneralActionTypes
> = (state = generalInitialState, action) => {
  switch (action.type) {
    case actionTypes.GENERAL_UPDATE: {
      const { payload } = action;

      return {
        ...state,
        ...payload,
      };
    }
    case actionTypes.CLEAR: return generalInitialState;
    default: return state;
  }
};

const invitationCodesInitialState: SettingsInvitationCodesState = [];

const invitationCodes: Reducer<
SettingsInvitationCodesState,
SettingsInvitationCodesActionTypes
> = (state = invitationCodesInitialState, action) => {
  switch (action.type) {
    case actionTypes.INVITATION_CODES_EDIT: {
      const { payload } = action;
      const itemIndex = state.indexOf(payload[0]);
      const newState = [...state];
      [,newState[itemIndex]] = payload;

      return uniq(newState);
    }
    case actionTypes.INVITATION_CODES_ADD: {
      const { payload } = action;

      return union(state, payload);
    }
    case actionTypes.INVITATION_CODES_REMOVE:
    case actionTypes.INVITATION_LAST_CODE_REMOVE:
    case actionTypes.INVITATION_LAST_CODE_AND_DATA_REMOVE: {
      const { payload } = action;

      return without(state, ...payload);
    }
    case actionTypes.CLEAR: return invitationCodesInitialState;
    default: return state;
  }
};
const hospitalGroupAccessCodesInitialState: SettingsHospitalGroupAccessCodesState = [];

const hospitalGroupAccessCodes: Reducer<
SettingsHospitalGroupAccessCodesState,
SettingsHospitalGroupAccessCodesActionTypes
> = (state = hospitalGroupAccessCodesInitialState, action) => {
  switch (action.type) {
    case actionTypes.HOSPITAL_GROUP_ACCESS_CODES_ADD: {
      const { payload } = action;

      return union(state, payload);
    }
    case actionTypes.HOSPITAL_GROUP_ACCESS_CODES_REMOVE: {
      const { payload } = action;

      return without(state, ...payload);
    }
    case actionTypes.CLEAR: return hospitalGroupAccessCodesInitialState;
    default: return state;
  }
};

const bannersInitialState: SettingsBannersState = {
  trends: 0,
};

const banners: Reducer<
SettingsBannersState,
SettingsBannersActionTypes
> = (state = bannersInitialState, action) => {
  switch (action.type) {
    case actionTypes.BANNERS_TRENDS_NEXT: return utils.bannersTrendsNext(state);
    case actionTypes.CLEAR: return bannersInitialState;
    default: return state;
  }
};

const accountStatusInitialState: AccountStatusState = { status: 'active' };

const accountStatus: Reducer<AccountStatusState, ChangeAccountStatusActionType > = (
  state = accountStatusInitialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.CHANGE_STATUS: return {
      status: action.payload,
    };
    default: return state;
  }
};

const launchInitialState: IsFirstLaunchState = { isFirstLaunch: true };

const launch: Reducer<IsFirstLaunchState, IsFirstLaunchActionType> = (
  state = launchInitialState, action,
) => {
  switch (action.type) {
    case actionTypes.IS_FIRST_LAUNCH:
      return {
        isFirstLaunch: action.payload,
      };
    default: return state;
  }
};

const contentModulesState: ContentModulesState = {
  enhertu: { active: false, surveyVisited: false },
  lynparza: { active: false, surveyVisited: false },
};

const contentModules: Reducer<
ContentModulesState,
ContentModuleActionTypes
> = (state = contentModulesState, action) => {
  switch (action.type) {
    case actionTypes.CONTENT_MODULES_UPDATE: {
      return utils.contentModuleUpdate(
        state,
        action as ContentModulesUpdateActionType,
      );
    }
    case actionTypes.CONTENT_MODULES_CLEAR: return contentModulesState;
    case actionTypes.CLEAR: return contentModulesState;
    default: return state;
  }
};

const reducer = combineReducers({
  trendIndicators,
  general,
  invitationCodes,
  hospitalGroupAccessCodes,
  banners,
  accountStatus,
  launch,
  contentModules,
});

export default reducer;
