/* eslint react/require-default-props: 0 */
import React, { ReactNode, memo } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';

import Text, { TextProps } from '../Text';
import getStyles from './styles';

export type DividerProps = {
  style?: object;
  lineStyle?: object;
  offsetLeft?: number;
  offsetRight?: number;
  offsetColor?: string;
  text?: ReactNode;
  textStyle?: object;
  textProps?: TextProps;
};

const propTypes = {
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  lineStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  offsetLeft: PropTypes.number,
  offsetRight: PropTypes.number,
  offsetColor: PropTypes.string,
  text: PropTypes.node,
  textStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  textProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

const Divider = (props: DividerProps) => {
  const {
    style = {},
    lineStyle = {},
    offsetLeft = 0,
    offsetRight = 0,
    offsetColor = '',
    text = null,
    textStyle = {},
    textProps = {},
  } = props;

  const styles = getStyles(offsetLeft, offsetRight, offsetColor);

  if (text) {
    return (
      <View style={[styles.text, style]}>
        <Text
          type="divider"
          style={textStyle}
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...textProps}
        >
          {text}
        </Text>
      </View>
    );
  }

  return (
    <View style={[styles.container, style]}>
      {offsetLeft > 0 ? <View style={styles.offsetLeft} /> : null}
      <View style={[styles.line, lineStyle]} />
      {offsetRight > 0 ? <View style={styles.offsetRight} /> : null}
    </View>
  );
};

Divider.whyDidYouRender = true;

Divider.propTypes = propTypes;

export default memo(Divider);
