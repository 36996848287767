const prefix = 'REPORTS';

const ADD = `${prefix}/ADD`;
const GENERATE = `${prefix}/GENERATE`;
const REMOVE = `${prefix}/REMOVE`;
const UPDATE = `${prefix}/UPDATE`;
const CLEAR = `${prefix}/CLEAR`;

export {
  ADD,
  GENERATE,
  REMOVE,
  UPDATE,
  CLEAR,
};
