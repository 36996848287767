/* eslint react/require-default-props: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Path } from 'react-native-svg';
import ResizeableSVG from '../ResizeableSVG';

import Colors from '../../theme/Colors';
import Constants from '../../theme/Constants';

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

type Props = {
  color?: string;
  size?: number;
};

const Home = (props: Props) => {
  const {
    color = Colors.primary,
    size = Constants.defaultIconSize,
  } = props;

  return (
    <ResizeableSVG size={size}>
      <Path d="M13.6114397,2.16708605 L1.65352018,14.1670208 C1.31634398,14.5291058 1.56935763,15.1134505 2.0743329,15.1134505 L4.12369086,15.1134505 C4.4324622,15.1134505 4.68494983,15.3644056 4.68494983,15.6706648 L4.68494983,25.4433074 C4.68494983,25.7495666 4.93743747,26 5.2462088,26 L10.6078889,26 C10.9166602,26 11.1691479,25.7495666 11.1691479,25.4433074 L11.1691479,19.7355123 C11.1691479,19.4292531 11.4221615,19.1788197 11.7309329,19.1788197 L16.3062192,19.1788197 C16.6149905,19.1788197 16.8674782,19.4292531 16.8674782,19.7355123 L16.8674782,25.4433074 C16.8674782,25.7495666 17.1204918,26 17.4292632,26 L22.7625384,26 C23.0713097,26 23.3237974,25.7495666 23.3237974,25.4433074 L23.3237974,15.6706648 C23.3237974,15.3644056 23.576285,15.1134505 23.8850563,15.1134505 L25.9344143,15.1134505 C26.4393896,15.1134505 26.6924032,14.5291058 26.3273482,14.1670208 L14.3973075,2.16708605 C14.1726987,1.94430465 13.8360485,1.94430465 13.6114397,2.16708605" fill={color} fillRule="evenodd" />
    </ResizeableSVG>
  );
};

Home.propTypes = propTypes;

export default Home;
