import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../state/reducers';

import { actions as uiActions } from '../state/ui';
import { ReactComponent as IconMenuLeft } from '../assets/icons/MenuLeft.svg';
import { ReactComponent as IconMenuRight } from '../assets/icons/MenuRight.svg';
import { selectors as messagesSelectors } from '../state/messages';
import { selectors as pushNotificationsSelectors } from '../state/notifications';
import Colors from '../theme/Colors';

const propTypes = {
  title: PropTypes.node,
  showRightButton: PropTypes.bool,
};

type Props = {
  title?: ReactNode;
  titleColor?: string,
  showRightButton?: boolean;
};

const DrawerButtons = (props: Props) => {
  const { title, showRightButton = false, titleColor } = props;

  const dispatch = useDispatch();

  const toggleDrawerLeft = () => dispatch(uiActions.toggleDrawerLeft());
  const toggleDrawerRight = () => dispatch(uiActions.toggleDrawerRight());

  const unreadMessages = useSelector((state: AppState) => messagesSelectors.unread(state));
  const unreadPushNotifications = useSelector((state: AppState) => (
    pushNotificationsSelectors.unread(state)));
  const unread = unreadMessages + unreadPushNotifications;

  return (
    <header className="drawer-buttons">
      <button type="button" className="drawer-button left icon-button" onClick={toggleDrawerLeft}>
        <IconMenuLeft fill={Colors.black} className="icon" />
      </button>
      {title ? <h1 className="heading-large accent" style={{ color: titleColor }}>{title}</h1> : null}
      {
        showRightButton && (
          <button type="button" className="drawer-button right icon-button" onClick={toggleDrawerRight}>
            <div className="unread">
              <IconMenuRight fill={Colors.black} className="icon" />
              {unread > 0 ? <div className="badge">{unread > 9 ? '9+' : unread}</div> : null}
            </div>
          </button>
        )
      }
    </header>
  );
};

DrawerButtons.propTypes = propTypes;

export default DrawerButtons;
