import get from 'lodash/get';
import toArray from 'lodash/toArray';
import size from 'lodash/size';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import slice from 'lodash/slice';
import isAfter from 'date-fns/isAfter';
import isSameDay from 'date-fns/isSameDay';
import isSameWeek from 'date-fns/isSameWeek';
import isSameMonth from 'date-fns/isSameMonth';
import isWithinInterval from 'date-fns/isWithinInterval';

import { AppState } from '../reducers';
import { AppointmentsState } from './types';
import {
  TDate,
  AppointmentReturnType,
  AppointmentsReturnType,
} from '../../../types';

import EMPTY_OBJECT from '../../utils/empty-object';
import EMPTY_ARRAY from '../../utils/empty-array';
import toDate from '../../utils/toDate';
import createDeepEqualSelector from '../../selectors/createDeepEqualSelector';

const sort = (
  collection: AppointmentsReturnType | AppointmentsState,
  reverse = false,
): AppointmentsReturnType => (
  orderBy(collection, ['startDateTime'], [reverse ? 'desc' : 'asc'])
);

const getByIdSelector = (state: AppointmentsState, id: string): AppointmentReturnType => (
  get(state, [id]) || EMPTY_OBJECT
);

const makeGetById = () => (
  createDeepEqualSelector(
    (state: AppState) => state.appointments,
    (state: any, id: string) => id,
    getByIdSelector,
  )
);

const getByDateSelector = (
  state: AppointmentsState,
  date?: TDate,
  reverse?: boolean,
): AppointmentsReturnType => (
  sort(
    filter(
      state,
      (appointment) => isSameDay(toDate(appointment.startDateTime), toDate(date)),
    ),
    reverse,
  )
);

const makeGetByDate = () => (
  createDeepEqualSelector(
    (state: AppState) => state.appointments,
    (state: any, date: TDate) => date,
    getByDateSelector,
  )
);

const getByWeekSelector = (
  state: AppointmentsState,
  date?: TDate,
  reverse?: boolean,
): AppointmentsReturnType => (
  sort(
    filter(
      state,
      (appointment) => (
        isSameWeek(toDate(appointment.startDateTime), toDate(date), { weekStartsOn: 1 })
      ),
    ),
    reverse,
  )
);

const makeGetByWeek = () => (
  createDeepEqualSelector(
    (state: AppState) => state.appointments,
    (state: any, date: TDate) => date,
    (state: any, date: TDate, reverse: boolean) => reverse,
    getByWeekSelector,
  )
);

const getByMonthSelector = (
  state: AppointmentsState,
  date?: TDate,
  reverse?: boolean,
): AppointmentsReturnType => (
  sort(
    filter(
      state,
      (appointment) => isSameMonth(toDate(appointment.startDateTime), toDate(date)),
    ),
    reverse,
  )
);

const makeGetByMonth = () => (
  createDeepEqualSelector(
    (state: AppState) => state.appointments,
    (state: any, date: TDate) => date,
    getByMonthSelector,
  )
);

const getUpcomingSelector = (
  state: AppointmentsState,
  endDate: Date | null,
  limit?: number,
): AppointmentsReturnType => {
  const now = new Date();

  let upcoming;

  if (endDate == null) {
    upcoming = sort(filter(state, ({ startDateTime }) => isAfter(toDate(startDateTime), now)));
  } else {
    upcoming = sort(
      filter(
        state,
        ({ startDateTime }) => isWithinInterval(
          toDate(startDateTime),
          { start: now, end: endDate },
        ),
      ),
    );
  }

  if (isEmpty(upcoming)) {
    return EMPTY_ARRAY;
  }

  if (limit) {
    return slice(upcoming, 0, limit);
  }

  return upcoming;
};

const makeGetUpcoming = () => (
  createDeepEqualSelector(
    (state: AppState) => state.appointments,
    (state: any, endDate: Date | null) => endDate,
    (state: any, endDate: Date | null, limit?: number) => limit,
    getUpcomingSelector,
  )
);

const getAllSelector = (state: AppointmentsState, reverse = false) => sort(toArray(state), reverse);

const getAll = createDeepEqualSelector(
  (state: AppState) => state.appointments,
  getAllSelector,
);

const getAllByViewAndDateSelector = (
  state: AppointmentsState,
  view = 'all',
  date: TDate,
  reverse?: boolean,
): AppointmentsReturnType => {
  switch (view) {
    case 'all': return getAllSelector(state, reverse);
    case 'day': return getByDateSelector(state, date, reverse);
    case 'week': return getByWeekSelector(state, date, reverse);
    case 'month': return getByMonthSelector(state, date, reverse);
    default:
  }

  return getAllSelector(state, reverse);
};

const makeGetAllByViewAndDate = () => (
  createDeepEqualSelector(
    (state: AppState) => state.appointments,
    (state: any, view: string) => view,
    (state: any, view: string, date: TDate) => date,
    (state: any, view: string, date: TDate, reverse: boolean) => reverse,
    getAllByViewAndDateSelector,
  )
);

const countSelector = (state: AppointmentsState) => size(state);

const count = createDeepEqualSelector(
  (state: AppState) => state.appointments,
  countSelector,
);

export {
  getAll,
  makeGetAllByViewAndDate,
  makeGetUpcoming,
  makeGetByDate,
  makeGetByWeek,
  makeGetByMonth,
  makeGetById,
  count,
};
