const UPDATE_TITLE = 'UPDATE_TITLE';
const UPDATE_LOCATION = 'UPDATE_LOCATION';
const UPDATE_START_AT = 'UPDATE_START_AT';
const UPDATE_END_AT = 'UPDATE_END_AT';
const UPDATE_BODY = 'UPDATE_BODY';

export {
  UPDATE_TITLE,
  UPDATE_LOCATION,
  UPDATE_START_AT,
  UPDATE_END_AT,
  UPDATE_BODY,
};
