import * as actionTypes from './actionTypes';
import {
  SyncUserDataActionType,
  SyncUpdateUserDataActionType,
  SyncUpdateUserDataActionPayload,
  SyncUploadedUserDataType,
} from './types';

const userData = (): SyncUserDataActionType => ({
  type: actionTypes.USER_DATA,
});

const updateUserData = (
  payload: SyncUpdateUserDataActionPayload,
): SyncUpdateUserDataActionType => ({
  type: actionTypes.UPDATE_USER_DATA,
  payload,
});

const uploadedUserData = (
  payload: SyncUpdateUserDataActionPayload,
): SyncUploadedUserDataType => ({
  type: actionTypes.UPLOADED_USER_DATA,
  payload,
});

export {
  userData,
  updateUserData,
  uploadedUserData,
};
