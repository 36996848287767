import { takeLatest, put, select } from 'redux-saga/effects';
import differenceInHours from 'date-fns/differenceInHours';
import fromUnixTime from 'date-fns/fromUnixTime';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { MessageType } from '../../types';

import {
  selectors as messagesSelectors,
  actionTypes as messagesActionTypes,
  actions as messagesActions,
} from '../state/messages';
import { selectors as templatesSelectors } from '../state/templates';
import { selectors as tagsSelectors } from '../state/tags';
import getUnixTime from '../utils/getUnixTime';

import { triggers } from '../../data/system-document.json';

function* check(): Generator<any, any, any> {
  const contentModulesTemplates = yield select(templatesSelectors.get, 'contentModules');

  const drugModules = yield select(tagsSelectors.getValueByKey, 'drugModules');

  if (isEmpty(drugModules)) {
    return false;
  }

  const messages: MessageType[] = [];

  for (const drug of drugModules) { // eslint-disable-line no-restricted-syntax
    const welcomeMessageId = get(triggers, ['contentModules', 'drug', drug, 'messages', 'welcome', 'templateMessageId']);
    const myResearchMessageId = get(triggers, ['contentModules', 'drug', drug, 'messages', 'myResearch', 'templateMessageId']);
    if (!welcomeMessageId || !myResearchMessageId) {
      continue; // eslint-disable-line no-continue
    }

    const welcomeMessage = yield select(messagesSelectors.makeGetById(), welcomeMessageId);

    if (isEmpty(welcomeMessage)) {
      continue; // eslint-disable-line no-continue
    }

    const myResearchMessage = yield select(messagesSelectors.makeGetById(), myResearchMessageId);

    if (!isEmpty(myResearchMessage)) {
      continue; // eslint-disable-line no-continue
    }

    const { createdAt } = welcomeMessage;
    const hoursAgo = differenceInHours(new Date(), fromUnixTime(createdAt));

    if (hoursAgo < 24) {
      continue; // eslint-disable-line no-continue
    }

    const message = get(contentModulesTemplates, ['drug', drug, 'messages', myResearchMessageId]);

    if (!isEmpty(message)) {
      messages.push({ ...message, createdAt: getUnixTime() });
    }
  }

  yield put(messagesActions.add(messages));

  return true;
}

export default function* watchMessages() {
  yield takeLatest(messagesActionTypes.CHECK, check);
}
