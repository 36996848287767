import * as actionTypes from './actionTypes';
import { TPhoto } from '../../../types';
import { PhotosActionTypes, PhotosDownloadPayload } from './types';

const add = (payload: TPhoto[]): PhotosActionTypes => ({
  type: actionTypes.ADD,
  payload,
});

const remove = (payload: string[]): PhotosActionTypes => ({
  type: actionTypes.REMOVE,
  payload,
});

const unlinkUri = (payload: string): PhotosActionTypes => ({
  type: actionTypes.UNLINK_URI,
  payload,
});

const unlinkAll = (): PhotosActionTypes => ({
  type: actionTypes.UNLINK_ALL,
});

const update = (payload: TPhoto[]): PhotosActionTypes => ({
  type: actionTypes.UPDATE,
  payload,
});

const download = (payload: PhotosDownloadPayload): PhotosActionTypes => ({
  type: actionTypes.DOWNLOAD,
  payload,
});

const cleanup = (payload: TPhoto[]): PhotosActionTypes => ({
  type: actionTypes.CLEANUP,
  payload,
});

const clear = (): PhotosActionTypes => ({
  type: actionTypes.CLEAR,
});

export {
  add,
  remove,
  unlinkUri,
  unlinkAll,
  update,
  download,
  cleanup,
  clear,
};
