import produce, { Draft } from 'immer';
import map from 'lodash/map';
import lodashSet from 'lodash/set';

import { DraftsState, DraftsSetActionType, DraftsUnsetActionType } from './types';

const set = produce((
  draft: Draft<DraftsState>,
  action: DraftsSetActionType,
) => {
  const { payload } = action;

  map(payload, ({ name, value }) => {
    lodashSet(draft, [name], value);
  });
});

const unset = produce((
  draft: Draft<DraftsState>,
  action: DraftsUnsetActionType,
) => {
  const { payload } = action;

  map(payload, ({ name }) => {
    lodashSet(draft, [name], '');
  });
});

const clear = () => ({});

export {
  set,
  unset,
  clear,
};
