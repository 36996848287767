type TAzInvitationCodes = { [key: string]: string [] };

const azInvitationCodes: TAzInvitationCodes = {
  // regional codes- OW-950
  uk: [
    'SCO2419',
    'NIR2481',
    'WAL2427',
    'LON2462',
    'SWE2435',
    'SEA2418',
    'MID2424',
    'NEW2439',
    'YOH2459',
    'DM1',
    'DM2',
  ],
  nl: [],
  us: [],
};

export default azInvitationCodes;
