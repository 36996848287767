const prefix = 'NOTIFICATIONS';

const ADD = `${prefix}/ADD`;
const REMOVE = `${prefix}/REMOVE`;
const SET_READAT = `${prefix}/SET_READAT`;
const CLEAR = `${prefix}/CLEAR`;

export {
  ADD,
  REMOVE,
  SET_READAT,
  CLEAR,
};
