import toUpper from 'lodash/toUpper';
import includes from 'lodash/includes';
import toLower from 'lodash/toLower';
import map from 'lodash/map';

import { SettingsHospitalGroupAccessCodesReturnType, SettingsInvitationCodesReturnType } from 'types';
import azInvitationCodes from '../../data/azInvitationCodes';
import testInvitationCodeByRegex from './testInvitationCodeByRegex';
import testInvitationCodeByArray from './testInvitationCodeByArray';
import invitationCodePatterns from '../../data/invitationCodePatterns';
import invitationCodesJSON from '../../data/invitation-codes.json';

export enum CodeTypes {
  regional = 'regional',
  knl = 'knl',
  hospital = 'hospital',
  other = 'other',
}

const getCurrentCodeType = (
  code: string,
  country: string,
  validHospitalCodes?: SettingsHospitalGroupAccessCodesReturnType,
) => {
  if (includes(validHospitalCodes, code)) {
    return CodeTypes.hospital;
  }

  if (testInvitationCodeByArray(toUpper(code), azInvitationCodes[country])) {
    return CodeTypes.regional;
  }

  if (testInvitationCodeByRegex(toUpper(code), invitationCodePatterns)) {
    return CodeTypes.knl;
  }

  if (includes(invitationCodesJSON, toLower(code))
    || includes(invitationCodesJSON, toUpper(code))) {
    return CodeTypes.other;
  }
  return '';
};

type TCodeTypes = {
  regional?: SettingsInvitationCodesReturnType,
  knl?: SettingsInvitationCodesReturnType,
  other?: SettingsInvitationCodesReturnType,
};

const getLinkedCodesTypes = (codes: SettingsInvitationCodesReturnType, country: string) => {
  const codeTypes: TCodeTypes = {};

  map(codes, (code) => {
    if (testInvitationCodeByArray(code, azInvitationCodes[country])) {
      codeTypes.regional = code;
    } else if (testInvitationCodeByRegex(code, invitationCodePatterns)) {
      codeTypes.knl = code;
    } else if (includes(invitationCodesJSON, toLower(code))) {
      codeTypes.other = code;
    }
  });

  return codeTypes;
};

export {
  getCurrentCodeType,
  getLinkedCodesTypes,
};
