import * as actionTypes from './actionTypes';
import { GlossaryActionTypes, GlossaryReplacePayload } from './types';

const replace = (payload: GlossaryReplacePayload): GlossaryActionTypes => ({
  type: actionTypes.REPLACE,
  payload,
});

const clear = (): GlossaryActionTypes => ({
  type: actionTypes.CLEAR,
});

export {
  replace,
  clear,
};
