import find from 'lodash/find';

import defaultGet from './defaultGet';
import getLocalizedText from './getLocalizedText';
import { TTreatment, TTreatmentType } from '../../types';

const getTreatmentTitle = (
  treatment: TTreatment,
  treatmentTypes: TTreatmentType[],
  language: string,
) => {
  const treatmentType = find(treatmentTypes, { id: treatment.treatmentTypeId });

  let title = getLocalizedText(defaultGet(treatmentType, 'title', ''), language);

  if (treatmentType && treatmentType.subType === 'text') {
    const text = defaultGet(treatment, 'treatmentTypeText', '');

    // Override default title if text is filled in by the user
    if (text) {
      title = text;
    }
  }

  return title;
};

export default getTreatmentTitle;
