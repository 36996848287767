import {
  Interval,
  getTime,
  isWithinInterval,
  isAfter,
  isBefore,
} from 'date-fns';
import get from 'lodash/get';
import toArray from 'lodash/toArray';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import slice from 'lodash/slice';
import take from 'lodash/take';
import size from 'lodash/size';

import { AppState } from '../reducers';
import { TreatmentsState } from './types';
import { TreatmentReturnType, TreatmentsReturnType, TTreatment } from '../../../types';

import EMPTY_OBJECT from '../../utils/empty-object';
import EMPTY_ARRAY from '../../utils/empty-array';
import getUnixTime from '../../utils/getUnixTime';
import createDeepEqualSelector from '../../selectors/createDeepEqualSelector';

const sort = (collection: TreatmentsReturnType | TreatmentsState, order: 'asc' | 'desc' = 'desc'): TreatmentsReturnType => (
  orderBy(collection, ['startDateTime', 'endDateTime'], [order])
);

const uncompletedFilter = (treatments: TreatmentsState): TreatmentsReturnType => (
  filter(treatments, (treatment) => {
    const { endDateTime, completed } = treatment;

    return getUnixTime(endDateTime) >= getUnixTime() && !completed;
  })
);

const getByIdSelector = (state: TreatmentsState, id: string): TreatmentReturnType => (
  get(state, [id]) || EMPTY_OBJECT
);

const getById = createDeepEqualSelector(
  (state: AppState) => state.treatments,
  (state: any, id: string) => id,
  getByIdSelector,
);

const getUncompletedSelector = (state: TreatmentsState, limit?: number): TreatmentsReturnType => {
  const filtered = uncompletedFilter(state);

  if (isEmpty(filtered)) {
    return EMPTY_ARRAY;
  }

  const sorted = sort(filtered);

  if (limit) {
    return slice(sorted, 0, limit);
  }

  return sorted;
};

const makeGetUncompleted = () => (
  createDeepEqualSelector(
    (state: AppState) => state.treatments,
    (state: any, limit: number) => limit,
    getUncompletedSelector,
  )
);

const getAllSelector = (state: TreatmentsState): TreatmentsReturnType => (
  sort(toArray(state))
);

const getAll = createDeepEqualSelector(
  (state: AppState) => state.treatments,
  getAllSelector,
);

const makeGetWithinInterval = (start: Date | number, end: Date | number, limit?: number) => (
  createDeepEqualSelector(
    [getAll],
    (treatments) => {
      const filtered = filter(treatments, (treatment) => {
        const { startDateTime, endDateTime } = treatment;

        if (getUnixTime(endDateTime, true) < getTime(start)) { return false; }
        if (getUnixTime(startDateTime, true) > getTime(end)) { return false; }

        return true;
      });

      if (limit) {
        return slice(filtered, 0, limit);
      }

      return filtered;
    },
  )
);

const toInterval = (treatment: TTreatment): Interval => ({
  start: getUnixTime(treatment.startDateTime, true),
  end: getUnixTime(treatment.endDateTime, true),
});

const makeGetMostRelevant = (
  start: Date | number,
  end: Date | number,
  now: Date | number,
  count: number,
) => (
  createDeepEqualSelector(
    makeGetWithinInterval(start, end),
    (treatments) => {
      const active = filter(treatments, (t) => isWithinInterval(now, toInterval(t)));
      const future = filter(treatments, (t) => isAfter(getUnixTime(t.startDateTime, true), now));
      const past = filter(treatments, (t) => isBefore(getUnixTime(t.endDateTime, true), now));

      return sort(take([...active, ...sort(future, 'asc'), ...past], count));
    },
  )
);

const countSelector = (state: TreatmentsState) => size(state);

const count = createDeepEqualSelector(
  (state: AppState) => state.treatments,
  countSelector,
);

export {
  getById,
  makeGetMostRelevant,
  makeGetUncompleted,
  makeGetWithinInterval,
  getAll,
  count,
};
