import * as actionTypes from './actionTypes';
import { TActions, TUpdatePayload } from './types';

const update = (payload: TUpdatePayload): TActions => ({
  type: actionTypes.UPDATE,
  payload,
});

export {
  update, // eslint-disable-line import/prefer-default-export
};
