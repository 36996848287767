import { Reducer } from 'redux';

import { SuggestedQuestionsState, SuggestedQuestionsActionTypes } from './types';

const initialState: SuggestedQuestionsState = {};

const reducer: Reducer<
SuggestedQuestionsState,
SuggestedQuestionsActionTypes
> = (state = initialState, action) => {
  switch (action.type) {
    default: return state;
  }
};

export default reducer;
