/* eslint react/require-default-props: 0 */
import React, { ReactNode, memo } from 'react';
import PropTypes from 'prop-types';
import { View as RNView } from 'react-native';
import SVG, { G } from 'react-native-svg';

import Constants from '../../theme/Constants';

const propTypes = {
  size: PropTypes.number,
  children: PropTypes.node.isRequired,
};

type Props = {
  size?: number;
  children: ReactNode;
};

const ResizeableSVG = (props: Props) => {
  const {
    size = Constants.defaultIconSize,
    children,
  } = props;

  const containerStyle = {
    width: size,
    height: size,
  };

  const isAndroid = Constants.isAndroid();

  return (
    <RNView style={containerStyle}>
      <SVG
        width={size}
        height={size}
        viewBox={isAndroid ? `0 0 ${size} ${size}` : `0 0 ${Constants.defaultIconSize} ${Constants.defaultIconSize}`}
      >
        <G scale={isAndroid ? size / Constants.defaultIconSize : 1}>
          {children}
        </G>
      </SVG>
    </RNView>
  );
};

ResizeableSVG.propTypes = propTypes;

ResizeableSVG.whyDidYouRender = true;

export default memo(ResizeableSVG);
