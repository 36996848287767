import * as actionTypes from './actionTypes';
import {
  ApiAuthRegisterPayload,
  ApiAuthLoginPayload,
  ApiAuthResetPayload,
  ApiAuthPasswordPayload,
  ApiValidateInvitePayload,
  ApiBLOBPostPayload,
  ApiShareTrendsPayload,
  ApiDownloadReportPayload,
  ApiAuthRegisterActionType,
  ApiAuthLoginActionType,
  ApiAuthResetActionType,
  ApiAuthPasswordActionType,
  ApiValidateInviteActionType,
  ApiBLOBPostActionType,
  ApiShareTrendsActionType,
  ApiDownloadReportActionType,
  ApiShareCustomTrendsPayload,
  ApiShareCustomTrendsByIdPayload,
  ApiShareCustomTrendsActionType,
  ApiShareCustomTrendsByIdActionType,
  ApiDeactivateAccountPayload,
  ApiDeleteAccountPayload,
  ApiDeactivateAccountActionType,
  ApiDeleteAccountActionType,
  ApiGetUniqueHospitalAccessCodePayload,
  ApiGetUniqueHospitalAccessCodeType,
  ApiValidateNavigationPayload,
  ApiValidateNavigationActionType,
} from './types';

const authRegister = (payload: ApiAuthRegisterPayload): ApiAuthRegisterActionType => ({
  type: actionTypes.AUTH_REGISTER,
  payload,
});

const authLogin = (payload: ApiAuthLoginPayload): ApiAuthLoginActionType => ({
  type: actionTypes.AUTH_LOGIN,
  payload,
});

const authReset = (payload: ApiAuthResetPayload): ApiAuthResetActionType => ({
  type: actionTypes.AUTH_RESET,
  payload,
});

const authPassword = (payload: ApiAuthPasswordPayload): ApiAuthPasswordActionType => ({
  type: actionTypes.AUTH_PASSWORD,
  payload,
});

const validateInvite = (payload: ApiValidateInvitePayload): ApiValidateInviteActionType => ({
  type: actionTypes.VALIDATE_INVITE,
  payload,
});

// eslint-disable-next-line max-len
const validateNavigation = (payload: ApiValidateNavigationPayload): ApiValidateNavigationActionType => ({
  type: actionTypes.VALIDATE_NAVIGATION,
  payload,
});

const BLOBPost = (payload: ApiBLOBPostPayload): ApiBLOBPostActionType => ({
  type: actionTypes.BLOB_POST,
  payload,
});

const shareTrends = (payload: ApiShareTrendsPayload): ApiShareTrendsActionType => ({
  type: actionTypes.SHARE_TRENDS,
  payload,
});

const shareCustomTrends = (
  payload: ApiShareCustomTrendsPayload,
): ApiShareCustomTrendsActionType => ({
  type: actionTypes.SHARE_CUSTOM_TRENDS,
  payload,
});

const shareCustomTrendsById = (
  payload: ApiShareCustomTrendsByIdPayload,
): ApiShareCustomTrendsByIdActionType => ({
  type: actionTypes.SHARE_CUSTOM_TRENDS_BY_ID,
  payload,
});

const downloadReport = (payload: ApiDownloadReportPayload): ApiDownloadReportActionType => ({
  type: actionTypes.DOWNLOAD_REPORT,
  payload,
});

const deactivateAccount = (
  payload: ApiDeactivateAccountPayload,
): ApiDeactivateAccountActionType => ({
  type: actionTypes.DEACTIVATE_ACCOUNT,
  payload,
});

const deleteAccount = (
  payload: ApiDeleteAccountPayload,
): ApiDeleteAccountActionType => ({
  type: actionTypes.DELETE_ACCOUNT,
  payload,
});

// eslint-disable-next-line max-len
const getUniqueHospitalAccessCode = (payload: ApiGetUniqueHospitalAccessCodePayload): ApiGetUniqueHospitalAccessCodeType => ({
  type: actionTypes.GET_HOSPITAL_UNIQUE_ACCESS_CODE,
  payload,
});

export {
  authRegister,
  authLogin,
  authReset,
  authPassword,
  validateInvite,
  validateNavigation,
  BLOBPost,
  shareTrends,
  shareCustomTrends,
  shareCustomTrendsById,
  downloadReport,
  deactivateAccount,
  deleteAccount,
  getUniqueHospitalAccessCode,
};
