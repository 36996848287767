/* eslint react/require-default-props: 0, max-len: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Path } from 'react-native-svg';
import ResizeableSVG from '../ResizeableSVG';

import Colors from '../../theme/Colors';
import Constants from '../../theme/Constants';

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

type Props = {
  color?: string;
  size?: number;
};

const SyncInProgress = (props: Props) => {
  const {
    color = Colors.primary,
    size = Constants.defaultIconSize,
  } = props;

  return (
    <ResizeableSVG size={size}>
      <Path d="m4.43431458 11.2343361c.30752226-.3075223.80438504-.3130423 1.11866354-.0124281l2.3 2.2c.31928326.3054014.33053675.8118083.02513537 1.1310916-.30540139.3192833-.81180834.3305368-1.13109161.0251354l-.90702188-.868135v.2980215c0 4.4991131 3.65076823 8.142 8.1588423 8.1420008 2.4772153-.0035848 4.820792-1.1238144 6.3793295-3.0493194.2779752-.3434263.7817206-.3964848 1.1251468-.1185096.3434263.2779752.3964848.7817206.1185096 1.1251468-1.8618101 2.3001853-4.6614188 3.6383982-7.6218282 3.6426814-5.33819309 0-9.67253986-4.271723-9.75869298-9.5808046l-.00130702-.1611954v-.3180215l-.87431458.8757069c-.28401766.2840177-.72845892.3098375-1.04164452.0774594l-.08972632-.0774594c-.31241944-.3124194-.31241944-.8189514 0-1.1313708zm9.53918542-6.98432876c5.3354639 0 9.6724853 4.27393702 9.7586922 9.57845896l.0013078.1610552-.0005.3454785.9013146-.9006639c.2840176-.2840177.7284589-.3098375 1.0416445-.0774594l.0897263.0774594c.3124195.3124194.3124195.8189514 0 1.1313708l-2.2 2.2c-.3075222.3075223-.804385.3130423-1.1186635.0124281l-2.3-2.2c-.3192833-.3054014-.3305368-.8118084-.0251354-1.1310916.3054014-.3192833.8118084-.3305368 1.1310916-.0251354l.8800219.842092.0005-.2744785c0-4.49432735-3.6534841-8.1395068-8.1609823-8.1395074-2.4830447-.00304887-4.83203823 1.1260252-6.38067797 3.06695651-.27556199.34536559-.77892325.4019526-1.12428885.12639061-.34536559-.27556198-.4019526-.77892325-.12639061-1.12428884 1.85266671-2.32197247 4.66281083-3.67270449 7.63233973-3.66906504z" fill={color} fillRule="evenodd" />
    </ResizeableSVG>
  );
};

SyncInProgress.propTypes = propTypes;

export default SyncInProgress;
