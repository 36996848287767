import React, { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import startOfDay from 'date-fns/startOfDay';
import subWeeks from 'date-fns/subWeeks';
import endOfDay from 'date-fns/endOfDay';
import subMonths from 'date-fns/subMonths';
import { TDrugModule, TrendIndicatorType } from '../../types';

import '../css/TestFitnessResult.css';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRightHeader from '../components/DrawerRightHeader';
import LeaveDrugModuleSupportHoc from '../hocs/LeaveDrugModuleSupportHoc';
import Tabs, { TabType } from '../components/Tabs';
import { AppState } from '../state/reducers';
import { testFitnessIndicatorIds } from '../utils/idsData';
import { selectors as customTrendsSelectors } from '../state/customTrends';

import isOfType from '../utils/isOfType';
import ListItem from '../components/ListItem';
import Colors from '../theme/Colors';
import TrendsShareModal from '../components/TrendsShareModal';
import { ReactComponent as IconShare } from '../assets/icons/Share.svg';
import checkIsSupportedDrugModule from '../utils/checkIsSupportedDrugModule';
import DrugModuleBreadcrumbs from '../components/DrugModuleBreadcrumbs';
import { TGraphTab } from './Trends';
import toDate from '../utils/toDate';
import TestFitnessTrendList from '../components/TestFitnessTrendList';

type Params = {
  drugModule: TDrugModule;
  lastTestResult?: string
};

const DrawerRight = (
  { shareIndicators }: {shareIndicators: string[]},
) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  return (
    <>
      <Drawer position="right">
        <section>
          <DrawerRightHeader />
          <section className="drawer-item">
            <ListItem
              primaryText={t('share-trends')}
              leftIcon={<IconShare fill={Colors.primary} className="icon" />}
              onClick={() => setVisible(true)}
            />
          </section>
        </section>
      </Drawer>
      <TrendsShareModal data={{ trends: shareIndicators, type: 'TestFitness' }} type="id" visible={visible} hideModal={() => setVisible(false)} />
    </>
  );
};

const TestFitnessResult = () => {
  const { t } = useTranslation();

  const { drugModule, lastTestResult } = useParams<Params>();

  const isLastTestResult = lastTestResult === 'last';

  const now = new Date();

  const testFitnessData = useSelector(
    (state: AppState) => customTrendsSelectors.getLastTestFitnessData(state, drugModule),
  )!;

  let initialStartDate = toDate(testFitnessData.start.measuredAt);

  if (!isLastTestResult) {
    initialStartDate = startOfDay(subWeeks(now, 1));
  }

  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate] = useState(toDate(testFitnessData.end.measuredAt || endOfDay(now)));

  const [activeGraphTab, setActiveGraphTab] = useState<TGraphTab>('separate');
  const [activeTab, setActiveTab] = useState('week');

  const graphTabs: { name: TGraphTab, labelText: string}[] = [
    {
      name: 'separate',
      labelText: t('trendsDashboard:separate'),
    },
    {
      name: 'combined',
      labelText: t('trendsDashboard:combined'),
    },
  ];

  const handleChangeActiveTab = ({ name }: TabType) => {
    setActiveTab(name);
    if (name === 'week') {
      setStartDate(initialStartDate);
      return;
    }
    setStartDate(startOfDay(subMonths(now, 1)));
  };

  const tabs = [
    {
      name: 'week',
      labelText: t('week'),
    },
    {
      name: 'month',
      labelText: t('month'),
    },
    {
      name: 'year',
      labelText: t('year'),
    },
  ];

  const memoizedGetFilteredIndicators = useMemo(
    customTrendsSelectors.makeGetTestFitnessTrends,
    [],
  );

  const { testFitnessTrendIndicators } = useSelector(
    (state: AppState) => memoizedGetFilteredIndicators(state, startDate, endDate, drugModule),
  );

  const shareIndicators = testFitnessTrendIndicators.map((indicator) => indicator.id);

  const isSupportedDrugModule = checkIsSupportedDrugModule(drugModule);

  const checkId = (id: string) => Number(testFitnessIndicatorIds[drugModule].includes(id)); // testFitnessIds should be first

  const trendIndicators = testFitnessTrendIndicators.sort(
    (
      a: TrendIndicatorType,
      b: TrendIndicatorType,
    ) => checkId(b.id) - checkId(a.id),
  );

  return (
    <article className="page when-seek-help row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('fitness-results')} showRightButton />
          <DrugModuleBreadcrumbs drugModule={drugModule} />
          {isSupportedDrugModule ? (
            <section className="test-fitness-result-container">
              {!isLastTestResult && (
              <div className="trends-tabs-wrapper">
                <Tabs tabs={tabs} onTabClick={handleChangeActiveTab} activeTab={activeTab} />

                <Tabs
                  tabs={graphTabs}
                  onTabClick={(tab) => setActiveGraphTab(tab.name as TGraphTab)}
                  activeTab={activeGraphTab}
                />
              </div>
              )}
              {!isOfType<TrendIndicatorType[]>(trendIndicators)
                ? null
                : (
                  <TestFitnessTrendList
                    trendIndicators={trendIndicators}
                    view={activeTab}
                    combined={activeGraphTab === 'combined'}
                    drugModule={drugModule}
                  />
                )}

            </section>
          ) : null}
        </section>
        <Footer />
      </section>
      <DrawerRight shareIndicators={shareIndicators} />
    </article>
  );
};

export default LeaveDrugModuleSupportHoc(TestFitnessResult);
