/* eslint react/require-default-props: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Path } from 'react-native-svg';
import ResizeableSVG from '../ResizeableSVG';

import Colors from '../../theme/Colors';
import Constants from '../../theme/Constants';

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

type Props = {
  color?: string;
  size?: number;
};

const Photo = (props: Props) => {
  const {
    color = Colors.primary,
    size = Constants.defaultIconSize,
  } = props;

  return (
    <ResizeableSVG size={size}>
      <Path d="m10.865416 5h6.269168c1.0817213 0 2.0338648.69878885 2.409404 1.73402319.0345305.09543293.1437753.17575332.2824652.1967544l.0715892.0053526h2.4657423c1.4056469 0 2.5544831 1.1420783 2.6320418 2.58089328l.0041735.15525571v10.59157182c0 1.4589322-1.1003656 2.6513185-2.4866301 2.7318173l-.1495852.0043317h-16.72756937c-1.40564695 0-2.55448316-1.1420783-2.63204185-2.5808933l-.00417348-.1552557v-10.59157182c0-1.45893222 1.10036563-2.65131853 2.4866301-2.73181731l.14958523-.00433168h2.46655792c.17102775 0 .31296322-.09079623.35342123-.20261067.35667886-.98324215 1.23322632-1.66282558 2.24791512-1.72832582l.1613064-.0051937h6.269168zm6.269168 1.27495062h-6.269168c-.5253205 0-.99130164.31455099-1.21275755.79384596l-.0977213.23736697c-.24249618.52248916-.75646703.85490159-1.32107211.89972332l-.13109179.00519393h-2.46655792c-.73651294 0-1.34106516.58723629-1.40266203 1.33513426l-.00516831.12606412v10.59157182c0 .7644327.56578838 1.3919023 1.28637052 1.4558342l.12145982.0053642h16.72756937c.7365129 0 1.3410651-.5872363 1.402662-1.3351343l.0051683-.1260641v-10.59157182c0-.76443268-.5657884-1.39190226-1.2863705-1.45583415l-.1214598-.00536423h-2.4657423c-.6156247 0-1.1917494-.34208133-1.4528176-.90447668l-.0977219-.23734319c-.2031139-.4395181-.612008-.74069432-1.0830268-.78783815zm-3.1343393 3.00697018c2.7063759 0 4.9004894 2.2766893 4.9004894 5.0854106 0 2.8088452-2.1942329 5.0862572-4.9004894 5.0862572s-4.9004894-2.277412-4.9004894-5.0862572c0-2.8087213 2.1941135-5.0854106 4.9004894-5.0854106zm0 1.2749506c-2.0280299 0-3.6721044 1.7059495-3.6721044 3.81046 0 2.1047094 1.6442662 3.8113065 3.6721044 3.8113065s3.6721044-1.7065971 3.6721044-3.8113065c0-2.1045105-1.6440745-3.81046-3.6721044-3.81046zm-7.054323-.5191233c.56280587 0 1.01957585.4740852 1.01957585 1.0582259 0 .5841408-.45676998 1.058226-1.01957585 1.058226-.56280588 0-1.01957586-.4740852-1.01957586-1.058226 0-.5841407.45676998-1.0582259 1.01957586-1.0582259z" fill={color} fillRule="evenodd" />
    </ResizeableSVG>
  );
};

Photo.propTypes = propTypes;

export default Photo;
