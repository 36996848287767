import React, {
  useState, useMemo, useEffect, Fragment,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import reduce from 'lodash/reduce';
import assignIn from 'lodash/assignIn';
import map from 'lodash/map';
import concat from 'lodash/concat';
import filter from 'lodash/filter';
import setMinutes from 'date-fns/setMinutes';
import getHours from 'date-fns/getHours';
import isToday from 'date-fns/isToday';
import setHours from 'date-fns/setHours';

import { AppState } from '../state/reducers';
import { TDrugModule, TrendIndicatorType, TrendMeasurementType } from '../../types';

import TrendIndicator from '../components/TrendIndicator';
import Navigation from '../components/Navigation';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRightHeader from '../components/DrawerRightHeader';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import Button from '../components/Button';
import Modal from '../components/Modal';
import getEnabledTrendIndicators from '../selectors/getEnabledTrendIndicators';
import { actions as trendsActions } from '../state/trends';
import getUnixTime from '../utils/getUnixTime';
import includesAny from '../utils/includesAny';
import { selectors as trendIndicatorsSelectors } from '../state/trendIndicators';
import Tabs from '../components/Tabs';
import { TrendList } from '../components/TrendsGrid';
import isOfType from '../utils/isOfType';
import Touchable from '../components/Touchable';
import { InfoIcon } from '../theme/Icons';
import Text from '../components/Text';

import LeaveDrugModuleSupportHoc from '../hocs/LeaveDrugModuleSupportHoc';
import DrugModuleBreadcrumbs from '../components/DrugModuleBreadcrumbs';
import ListItem from '../components/ListItem';
import { ReactComponent as IconSettings } from '../assets/icons/Settings.svg';
import Banner from '../components/Banner';
import Colors from '../theme/Colors';

import '../css/FillInSideEffects.css';

export const indicatorIds = {
  enhertu: [
    '0000000b-0000-1000-a000-00000000003a', // Breathless
    '0000000b-0000-1000-a000-000000000004', // Fatigue
    '0000000b-0000-1000-a000-000000000008', // Nausea
    '0000000b-0000-1000-a000-00000000003c', // Vomiting
    '1782777b-caab-4ed7-ab27-5efedc578a56', // Hair loss
  ],
  lynparza: [
    '0000000b-0000-1000-a000-000000000004', // Fatigue
    '0000000b-0000-1000-a000-000000000008', // Nausea
    '0000000b-0000-1000-a000-00000000003c', // Vomiting
    '0000000b-0000-1000-a000-000000000007', // Appetite
    '0000000b-0000-1000-a000-00000000003a', // Breathless
  ],
};

enum Pages {
  'FILL_IN'= 'fill_in',
  'VIEW'= 'view',
}

type Params = {
  drugModule: TDrugModule;
};

type TDrawerRight = {
  activePageTab: string;
  drugModule: TDrugModule;
};
const DrawerRight = ({
  activePageTab,
  drugModule,
}: TDrawerRight) => {
  const { t } = useTranslation();
  const requiredList = indicatorIds[drugModule];
  return (
    <Drawer position="right">
      <section>
        <DrawerRightHeader />
        {activePageTab === Pages.FILL_IN ? (
          <section className="drawer-item">
            <ListItem
              primaryText={t('side-effect-settings')}
              leftIcon={<IconSettings fill={Colors.primary} className="icon" />}
              divider
              to={{ pathname: `/support-menu/${drugModule}/fill-in-side-effects/settings`, state: { isSideEffect: true, requiredList } }}
            />
          </section>
        ) : null}
      </section>
    </Drawer>
  );
};

const FillInSideEffects = () => {
  const [isVisibleShareModal, setIsVisibleShareModal] = useState(false);

  const hideShareModal = () => {
    setIsVisibleShareModal(false);
  };

  const showShareModal = () => {
    setIsVisibleShareModal(true);
  };

  const { drugModule } = useParams<Params>();

  const memoizedTrendIndicatorsGetByIdsSelector = useMemo(
    trendIndicatorsSelectors.makeGetByIds,
    [],
  );

  const drugModuleIndicators = indicatorIds[drugModule];

  const drugModuleTrendIndicators = useSelector(
    (state: AppState) => memoizedTrendIndicatorsGetByIdsSelector(state, drugModuleIndicators),
  );

  const enabledTrendIndicators = useSelector((state: AppState) => getEnabledTrendIndicators(state));

  const filteredEnabledIndicators = filter(
    enabledTrendIndicators, ({ id }) => !includesAny(drugModuleIndicators, id),
  );

  const combinedTrendIndicators = concat(
    drugModuleTrendIndicators,
    filteredEnabledIndicators,
  );

  const now = new Date();

  const [measurements, setMeasurements] = useState<{[key: string]:{'changed': boolean, 'value': number}}>({});
  const [measuredAt, setMeasuredAt] = useState(now);
  const [modal, setModal] = useState({ visible: false, name: '', description: '' });
  const [activeTab, setActiveTab] = useState('week');
  const [activePageTab, setActivePageTab] = useState(Pages.FILL_IN);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const tabs = [
    {
      name: 'week',
      labelText: t('week'),
    },
    {
      name: 'month',
      labelText: t('month'),
    },
    {
      name: 'year',
      labelText: t('year'),
    },
  ];

  const pageTabs = [
    {
      name: Pages.FILL_IN,
      labelText: t('contentModules:drug:common:fill-in'),
    },
    {
      name: Pages.VIEW,
      labelText: t('contentModules:drug:common:view'),
    },
  ];

  const showModal = (name: string, description: string): void => (
    setModal({ visible: true, name, description })
  );

  const hideModal = (): void => setModal({ visible: false, name: '', description: '' });

  const handleSave = () => {
    const explicitData = reduce(
      measurements,
      (result, measurement: TrendMeasurementType, trendIndicatorId) => {
        if (measurement.changed) {
          return assignIn(result, { [trendIndicatorId]: measurement.value });
        }

        return result;
      },
      {},
    );

    const implicitData = reduce(
      measurements,
      (result, measurement: TrendMeasurementType, trendIndicatorId) => {
        if (!measurement.changed) {
          return assignIn(result, { [trendIndicatorId]: measurement.value });
        }

        return result;
      },
      {},
    );

    dispatch(trendsActions.add([{
      createdAt: getUnixTime(),
      measuredAt: getUnixTime(measuredAt),
      explicitData,
      implicitData,
    }]));

    setActivePageTab(Pages.VIEW);
  };

  useEffect(() => {
    const contentContainer = document.getElementById('container')!;

    contentContainer.scrollTop = 0;
  }, [activePageTab]);

  const filterTime = (date: Date) => {
    if (isToday(measuredAt)) {
      return getUnixTime(date) <= getUnixTime(now);
    }
    return true;
  };

  const AboutBlock = () => (
    <section className="fill-in-about">
      <section className="fill-in-about-text">
        <Text type="navigation">
          {t('contentModules:drug:common:fill-in-about-section')}
        </Text>
      </section>
      <section className="fill-in-info-icon">
        <Touchable
          onPress={() => showModal('', t('contentModules:drug:common:fill-in-description'))}
          opacity
        >
          {InfoIcon}
        </Touchable>
      </section>
    </section>
  );

  const fillInContent = (
    <>
      <AboutBlock />
      <label // eslint-disable-line jsx-a11y/label-has-associated-control
        htmlFor="measured-at"
      >
        <span className="body light">{t('date-time')}</span>
        <DatePicker
          maxDate={now}
          selected={measuredAt}
          minTime={setMinutes(setHours(now, getHours(now)), 0)}
          maxTime={setHours(now, 24)}
          filterTime={filterTime}
          onChange={(date: Date) => setMeasuredAt(date)}
          showTimeSelect
          dateFormat="dd/LL/yyyy HH:mm"
          timeFormat="HH:mm"
          id="measured-at"
        />
      </label>
      {
        map(combinedTrendIndicators, (indicator, index) => (
          <Fragment key={indicator.id}>
            <TrendIndicator
              setMeasurements={setMeasurements}
              indicator={indicator}
              showModal={showModal}
              dividers={false}
            />
            <Banner id={index} indicators={combinedTrendIndicators} />
          </Fragment>
        ))
      }
      <div className="row buttons-block">
        <Button extraClass="wide" labelText={t('save')} size="medium" onClick={handleSave} />
      </div>
    </>
  );

  const viewContent = (

    <>
      {
        isOfType<TrendIndicatorType[]>(drugModuleTrendIndicators)
          ? (
            <TrendList
              trendIndicators={drugModuleTrendIndicators}
              additionalTrendIndicators={filteredEnabledIndicators}
              view={activeTab}
              type="all"
              shareModalVisible={isVisibleShareModal}
              hideShareModal={hideShareModal}
            />
          )
          : null
      }
      <div className="row buttons-block">
        <Button
          labelText={t('add-trends')}
          size="medium"
          onClick={() => setActivePageTab(Pages.FILL_IN)}
        />
        <Button
          labelText={t('share-trends')}
          size="medium"
          onClick={showShareModal}
        />
      </div>
    </>
  );

  return (
    <article className="page my-side-effects row">
      <Navigation />
      <section id="container" className="container">
        <section className="content">
          <DrawerButtons title={t('contentModules:drug:common:my-side-effects')} showRightButton />
          <DrugModuleBreadcrumbs drugModule={drugModule} />
          <div className="test-fitness-result-container">
            <div className="trends-tabs-wrapper">
              <Tabs
                tabs={pageTabs}
                onTabClick={(tab) => setActivePageTab(tab.name as Pages)}
                activeTab={activePageTab}
              />
              {activePageTab === Pages.VIEW
                ? (
                  <Tabs
                    tabs={tabs}
                    onTabClick={(tab) => setActiveTab(tab.name)}
                    activeTab={activeTab}
                  />
                )
                : null}
            </div>
            {activePageTab === Pages.FILL_IN ? fillInContent : viewContent}
          </div>
        </section>
        <Footer />
      </section>
      <DrawerRight
        activePageTab={activePageTab}
        drugModule={drugModule}
      />
      <Modal
        visible={modal.visible}
        hideModal={hideModal}
        actions={[
          {
            title: t('close'),
            onClick: hideModal,
            primary: true,
          },
        ]}
      >
        {modal.name ? <h1 className="heading">{modal.name}</h1> : null}
        <p className="body">{modal.description}</p>
      </Modal>
    </article>
  );
};

export default LeaveDrugModuleSupportHoc(FillInSideEffects);
