import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  View as RNView, StyleProp, TextStyle, ViewStyle,
} from 'react-native';
import map from 'lodash/map';

import { TDrugModule } from 'types';
import Text from '../Text';
import styles from './styles';

import '../../css/SideEffectReportingBlock.css';

type Props = {
  drugModule: TDrugModule;
  body: string
  isDrugWelcomeScreen?: boolean
  hideTitle?: boolean
  containerStyle?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
};

const SideEffectReportingBlock = ({
  drugModule,
  body,
  isDrugWelcomeScreen = false,
  hideTitle = false,
  containerStyle = {},
  textStyle = {},
}: Props) => {
  const { t } = useTranslation();

  const links = [
    {
      url: 'https://yellowcard.mhra.gov.uk/',
      text: 'mhra.gov.uk/yellowcard',
    },
    {
      url: drugModule === 'enhertu' ? 'tel:08000285122' : 'https://contactazmedical.astrazeneca.com',
      text: drugModule === 'enhertu' ? '0800 028 5122' : 'contactazmedical.astrazeneca.com',
    },
    {
      url: drugModule === 'enhertu' ? 'mailto:pharmacovigilance@daiichi-sankyo.co.uk' : 'tel:08007830033',
      text: drugModule === 'enhertu' ? 'pharmacovigilance@daiichi-sankyo.co.uk' : '0800 783 0033',
    },
  ];

  const getComponents = () => map(
    links,
    (link) => <a href={link.url} style={styles.emphasized} target="_blank" rel="noreferrer">{link.text}</a>,
  );

  return (
    <RNView style={[styles.container, containerStyle]}>

      {!hideTitle ? <Text type="heading" style={styles.titleText}>{t('contentModules:drug:common:side-effect-reporting:title')}</Text> : null}
      <Text
        style={[styles.text, textStyle]}
      >
        {isDrugWelcomeScreen && <Text type="body" style={styles.text}>{t('contentModules:drug:common:side-effect-reporting:welcome')}</Text>}
        {' '}
        <Trans
          style={styles.text}
          i18nKey={`contentModules:drug:${drugModule}:${body}:body`}
          parent={Text}
          values={{ link1: links[0].text, link2: links[1].text, link3: links[2].text }}
          components={getComponents()}
        />
      </Text>
    </RNView>
  );
};

export default SideEffectReportingBlock;
