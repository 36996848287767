/* eslint react/require-default-props: 0 */
import React, { useState, useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import noop from 'lodash/noop';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';

import PropTypes from 'prop-types';
import { TState } from './types';
import { TContact, TContentModule } from '../../../types';
import { ContactPropType } from '../../../propTypes';

import reducer from './reducer';
import * as actions from './actions';
import Button from '../Button';
import Modal from '../Modal';
import { actions as contactsActions } from '../../state/contacts';
import { text as textSchema } from '../../utils/schemas';
import { formValidation } from '../../utils/formValidation';
import getUnixTime from '../../utils/getUnixTime';
import defaultGet from '../../utils/defaultGet';
import isOfType from '../../utils/isOfType';
import Colors from '../../theme/Colors';
import '../../css/Contact.css';

type Props = {
  contact?: TContact;
  contentModule?: TContentModule
};

const propTypes = {
  contact: ContactPropType,
  contentModule: PropTypes.string,
};

const Contact = (props: Props) => {
  const {
    contact = {},
    contentModule: propsContentModule,
  } = props;

  const [disabled, setDisabled] = useState(true);
  const [visible, setVisible] = useState(false);

  const history = useHistory();

  const firstName = defaultGet(contact, 'firstName', '');
  const lastName = defaultGet(contact, 'lastName', '');
  const company = defaultGet(contact, 'company', '');
  const phoneNumber = defaultGet(contact, 'phoneNumber', '');
  const address = defaultGet(contact, 'address', '');
  const postalCode = defaultGet(contact, 'postalCode', '');
  const city = defaultGet(contact, 'city', '');
  const country = defaultGet(contact, 'country', '');
  const email = defaultGet(contact, 'email', '');
  const readOnly = defaultGet(contact, 'readOnly', false);
  const contentModule = propsContentModule || defaultGet(contact, 'contentModule', undefined);
  const isPinned = defaultGet(contact, 'isPinned', false);

  const initialState: TState = {
    firstName,
    lastName,
    company,
    phoneNumber,
    address,
    postalCode,
    city,
    country,
    email,
    contentModule,
    isPinned,
  };

  const [state, localDispatch] = useReducer(reducer, initialState);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    if (!isEqual(state, initialState)) {
      const shape = {
        firstName: textSchema(false),
        lastName: textSchema(false),
        company: textSchema(false),
        phoneNumber: textSchema(false),
        address: textSchema(false),
        postalCode: textSchema(false),
        city: textSchema(false),
        country: textSchema(false),
        email: textSchema(false),
      };

      // OW-291
      // company is required if firstName and lastName are empty
      if (!state.firstName.trim() && !state.lastName.trim()) {
        set(shape, 'company', textSchema(true));
      }

      // firstName is required if lastName and company are empty
      if (!state.lastName.trim() && !state.company.trim()) {
        set(shape, 'firstName', textSchema(true));
      }

      // lastName is required if firstName and company are empty
      if (!state.firstName.trim() && !state.company.trim()) {
        set(shape, 'lastName', textSchema(true));
      }

      const schema = yup
        .object()
        .shape(shape);

      const formData = {
        firstName: state.firstName,
        lastName: state.lastName,
        company: state.company,
        phoneNumber: state.phoneNumber,
        address: state.address,
        postalCode: state.postalCode,
        city: state.city,
        country: state.country,
        email: state.email,
      };

      if (!formValidation(schema, formData, noop)) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    } else {
      setDisabled(true);
    }
  }, [state]);

  const handleSave = () => {
    if (isOfType<TContact>(contact, 'id')) {
      dispatch(contactsActions.update([{
        ...contact,
        updatedAt: getUnixTime(),
        firstName: state.firstName,
        lastName: state.lastName,
        company: state.company,
        phoneNumber: state.phoneNumber,
        address: state.address,
        postalCode: state.postalCode,
        city: state.city,
        country: state.country,
        email: state.email,
        contentModule: state.contentModule,
        isPinned: !!state.isPinned,
      }]));
    } else {
      dispatch(contactsActions.add([{
        id: uuidv4(),
        createdAt: getUnixTime(),
        firstName: state.firstName,
        lastName: state.lastName,
        company: state.company,
        phoneNumber: state.phoneNumber,
        address: state.address,
        postalCode: state.postalCode,
        city: state.city,
        country: state.country,
        email: state.email,
        contentModule: state.contentModule,
        isPinned: !!state.isPinned,
      }]));
    }

    return history.goBack();
  };

  const handleRemove = () => {
    if (!isOfType<TContact>(contact, 'id')) {
      return false;
    }

    setVisible(false);

    dispatch(contactsActions.remove([contact.id]));

    return history.goBack();
  };

  const handleUpdate = (field: string, value: string) => (
    localDispatch(actions.update({ [field]: value }))
  );

  return (
    <>
      <section className="contact-content">
        <label htmlFor="first-name">
          <span className="body light">{t('first-name')}</span>
          <input
            value={state.firstName}
            onChange={(event) => handleUpdate('firstName', event.currentTarget.value)}
            name="first-name"
            id="first-name"
            placeholder={t('placeholders.first-name')}
            disabled={readOnly}
          />
        </label>
        <label htmlFor="last-name">
          <span className="body light">{t('last-name')}</span>
          <input
            value={state.lastName}
            onChange={(event) => handleUpdate('lastName', event.currentTarget.value)}
            name="last-name"
            id="last-name"
            placeholder={t('placeholders.last-name')}
            disabled={readOnly}
          />
        </label>
        <label htmlFor="company-hospital">
          <span className="body light">{t('company-hospital')}</span>
          <input
            value={state.company}
            onChange={(event) => handleUpdate('company', event.currentTarget.value)}
            name="company-hospital"
            id="company-hospital"
            placeholder={t('placeholders.company-hospital')}
            disabled={readOnly}
          />
        </label>
        <div className="separator" />
        <label htmlFor="phone-number">
          <span className="body light">{t('phone-number')}</span>
          <input
            value={state.phoneNumber}
            onChange={(event) => handleUpdate('phoneNumber', event.currentTarget.value)}
            name="phone-number"
            id="phone-number"
            disabled={readOnly}
          />
        </label>
        <label htmlFor="email">
          <span className="body light">{t('email')}</span>
          <input
            value={state.email}
            onChange={(event) => handleUpdate('email', event.currentTarget.value)}
            name="email"
            id="email"
            disabled={readOnly}
          />
        </label>
        <h1 className="heading-secondary accent">{t('address')}</h1>
        <label htmlFor="street">
          <span className="body light">{t('street')}</span>
          <input
            value={state.address}
            onChange={(event) => handleUpdate('address', event.currentTarget.value)}
            name="street"
            id="street"
            disabled={readOnly}
          />
        </label>
        <label htmlFor="postal-code">
          <span className="body light">{t('postal-code')}</span>
          <input
            value={state.postalCode}
            onChange={(event) => handleUpdate('postalCode', event.currentTarget.value)}
            name="postal-code"
            id="postal-code"
            disabled={readOnly}
          />
        </label>
        <label htmlFor="city">
          <span className="body light">{t('city')}</span>
          <input
            value={state.city}
            onChange={(event) => handleUpdate('city', event.currentTarget.value)}
            name="city"
            id="city"
            disabled={readOnly}
          />
        </label>
        <label htmlFor="country">
          <span className="body light">{t('country')}</span>
          <input
            value={state.country}
            onChange={(event) => handleUpdate('country', event.currentTarget.value)}
            name="country"
            id="country"
            disabled={readOnly}
          />
        </label>
        {!readOnly && (
        <section className="buttons">
          {
            isOfType<TContact>(contact, 'id')
              ? (
                <Button
                  labelText={t('delete')}
                  onClick={() => setVisible(true)}
                  color={Colors.destructiveRed}
                  size="medium"
                />
              )
              : null
          }
          <Button
            labelText={t('save')}
            onClick={handleSave}
            size="medium"
            disabled={disabled}
          />
        </section>
        )}
      </section>
      <Modal
        classNameOverlay="delete-modal-overlay"
        visible={visible}
        hideModal={() => setVisible(false)}
        actions={[
          {
            title: t('cancel'),
            onClick: () => setVisible(false),
          },
          {
            title: t('delete'),
            onClick: handleRemove,
            destructive: true,
          },
        ]}
      >
        <h1 className="heading">{t('alerts.delete.title', { w: t('contact') })}</h1>
        <p className="body">{t('alerts.delete.body', { w: t('contact') })}</p>
      </Modal>
    </>
  );
};

Contact.propTypes = propTypes;

export default Contact;
