const getTimezone = () => {
  if (
    Intl
    && Intl.DateTimeFormat
    && Intl.DateTimeFormat().resolvedOptions
    && Intl.DateTimeFormat().resolvedOptions().timeZone
  ) {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  return '';
};

export default getTimezone;
