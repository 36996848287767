/* eslint react/require-default-props: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Path } from 'react-native-svg';
import ResizeableSVG from '../ResizeableSVG';

import Colors from '../../theme/Colors';
import Constants from '../../theme/Constants';

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

type Props = {
  color?: string;
  size?: number;
};

const Glossary = (props: Props) => {
  const {
    color = Colors.primary,
    size = Constants.defaultIconSize,
  } = props;

  return (
    <ResizeableSVG size={size}>
      <Path d="m22.617 3.06624026c1.751-.358 3.459.771 3.817 2.52.044.213.067.431.067.649v14.12500004c0 1.538-1.081 2.862-2.586 3.169l-9.785 2.003c-.044.009-.085-.003-.129-.003s-.085.012-.131.003l-9.784-2.003c-1.504-.307-2.586-1.631-2.586-3.169v-14.12500004c0-.218.022-.436.066-.649.358-1.749 2.068-2.878 3.818-2.52l8.617 1.763zm2.518 2.645c-.272-.973-1.254-1.583-2.258-1.378l-8.23 1.684v18.08900004l9.008-1.844c.856-.175 1.483-.898 1.546-1.757l.006-.145v-14.12500004c0-.131-.013-.261-.039-.389zm-20.01-1.378c-1.005-.205-1.987.405-2.259 1.378l-.033.135c-.025.128-.039.258-.039.389v14.12500004l.006.145c.064.859.691 1.582 1.546 1.757l9.007 1.844v-18.08900004zm17.771 14.08400004c.348-.083.697.132.779.48.077.318-.098.638-.395.752l-.084.026-6.55 1.56c-.348.083-.697-.131-.779-.479-.076-.318.098-.639.396-.753l.083-.026zm-18.571.48c.083-.348.432-.563.78-.48l6.55 1.56.084.026c.297.114.471.435.396.753-.083.348-.433.561-.78.479l-6.55-1.559-.084-.026c-.297-.115-.471-.435-.396-.753zm18.571-4.223c.348-.082.697.132.779.479.077.319-.098.639-.395.753l-.084.027-6.55 1.559c-.348.083-.697-.132-.779-.479-.076-.319.098-.639.396-.753l.083-.027zm-18.571.48c.083-.348.432-.563.78-.48l6.55 1.56.084.026c.297.114.471.434.396.753-.083.348-.433.562-.78.479l-6.55-1.558-.084-.028c-.297-.114-.471-.434-.396-.752zm18.571-4.223c.348-.083.697.132.779.48.077.318-.098.638-.395.753l-.084.025-6.55 1.56c-.348.083-.697-.132-.779-.479-.076-.319.098-.639.396-.753l.083-.027zm-18.571.48c.083-.348.432-.563.78-.48l6.55 1.559.084.027c.297.115.471.435.396.753-.083.347-.433.562-.78.48l-6.55-1.56-.084-.026c-.297-.115-.471-.435-.396-.753zm18.571-4.29800004c.348-.083.697.133.779.48.077.318-.097.638-.395.753l-.084.026-6.55 1.56c-.348.08300004-.696-.133-.779-.48-.076-.319.098-.638.396-.753l.083-.027zm-18.571.48c.083-.348.432-.563.78-.48l6.55 1.559.084.027c.297.115.471.434.396.753-.083.347-.432.56300004-.78.48l-6.55-1.56-.084-.026c-.297-.115-.472-.435-.396-.753z" fill={color} fillRule="evenodd" />
    </ResizeableSVG>
  );
};

Glossary.propTypes = propTypes;

export default Glossary;
