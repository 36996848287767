/* eslint react/require-default-props: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Path } from 'react-native-svg';
import ResizeableSVG from '../ResizeableSVG';

import Colors from '../../theme/Colors';
import Constants from '../../theme/Constants';

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

type Props = {
  color?: string;
  size?: number;
};

const Recording = (props: Props) => {
  const {
    color = Colors.primary,
    size = Constants.defaultIconSize,
  } = props;

  return (
    <ResizeableSVG size={size}>
      <Path d="M6.58499466,10.4865387 C6.93802934,10.4922603 7.25410444,10.8186356 7.24865139,11.1716702 L7.24865139,11.1716702 L7.24865139,14.5426668 C7.24865139,18.289722 10.2441951,21.2852657 13.9910484,21.2852657 C17.7381036,21.2852657 20.7336473,18.289722 20.7336473,14.5426668 L20.7336473,14.5426668 L20.7336473,11.1716702 C20.7285982,10.8154041 21.0515401,10.4876151 21.4078062,10.4876151 C21.7640723,10.4876151 22.0870142,10.8154041 22.082221,11.1716702 L22.082221,11.1716702 L22.082221,14.5426668 C22.082221,18.7877675 18.8220493,22.2617661 14.6654092,22.602077 L14.6654092,22.602077 L14.6654092,25.6572004 C14.6654092,26.2190668 14.4406223,26.5 13.9910484,26.5 C13.5416764,26.5 13.3168894,26.2190668 13.3168894,25.6572004 L13.3168894,25.6572004 L13.3168894,22.602077 C9.16004742,22.2617661 5.900065,18.7877675 5.900065,14.5426668 L5.900065,14.5426668 L5.900065,11.1716702 C5.89467855,10.8115668 6.2248912,10.4811522 6.58499466,10.4865387 Z M13.9910686,1.5 C16.9431068,1.5 19.3410446,3.89793771 19.3410446,6.84997599 L19.3410446,6.84997599 L19.3410446,14.1801801 C19.3410446,17.1322184 16.9431068,19.5301561 13.9910686,19.5301561 C11.039196,19.5301561 8.64129453,17.1321822 8.64129453,14.1801801 L8.64129453,14.1801801 L8.64129453,6.84997599 C8.64129453,3.89797393 11.039196,1.5 13.9910686,1.5 Z M13.9910686,2.96611573 C11.8489193,2.96611573 10.1074103,4.70767737 10.1074103,6.84997599 L10.1074103,6.84997599 L10.1074103,14.1801801 C10.1074103,16.3224787 11.8489193,18.0640404 13.9910686,18.0640404 C16.1333935,18.0640404 17.8749288,16.322505 17.8749288,14.1801801 L17.8749288,14.1801801 L17.8749288,6.84997599 C17.8749288,4.70765107 16.1333935,2.96611573 13.9910686,2.96611573 Z" fill={color} fillRule="evenodd" />
    </ResizeableSVG>
  );
};

Recording.propTypes = propTypes;

export default Recording;
