import { createSelector } from 'reselect';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import toUpper from 'lodash/toUpper';

import { AppState } from '../reducers';
import { GlossaryState } from './types';
import { GlossaryReturnType } from '../../../types';

import EMPTY_ARRAY from '../../utils/empty-array';

const getByLocaleSelector = (state: GlossaryState, locale: string): GlossaryReturnType => {
  if (!locale) {
    return EMPTY_ARRAY;
  }

  const glossary = get(state, [locale]);

  if (isEmpty(glossary)) {
    return EMPTY_ARRAY;
  }

  return orderBy(glossary, (item) => toUpper(item.word), ['asc']);
};

const getByLocale = createSelector(
  (state: AppState) => state.glossary,
  (state: any, locale: string) => locale,
  getByLocaleSelector,
);

const makeGetByLocale = () => (
  createSelector(
    (state: AppState) => state.glossary,
    (state: any, locale: string) => locale,
    getByLocaleSelector,
  )
);

export {
  getByLocale,
  makeGetByLocale,
};
