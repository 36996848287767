import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import capitalize from 'lodash/capitalize';
import times from 'lodash/times';

import { TDrugModule } from '../../types';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRightHeader from '../components/DrawerRightHeader';
import LeaveDrugModuleSupportHoc from '../hocs/LeaveDrugModuleSupportHoc';
import DrugModuleBreadcrumbs from '../components/DrugModuleBreadcrumbs';
import FAQAccordion from '../components/FAQAccordion';
import ListItem from '../components/ListItem';
import { ReactComponent as IconChevronUp } from '../assets/icons/ChevronUp.svg';
import { ReactComponent as IconChevronDown } from '../assets/icons/ChevronDown.svg';
import Colors from '../theme/Colors';
import { ReactComponent as IconQuestionmarkCircle } from '../assets/icons/QuestionmarkCircle.svg';

import '../css/Message.css';
import '../css/WhenSeekHelp.css';
import '../css/FAQAccordion.css';

type Params = {
  drugModule: TDrugModule;
};

type Item = {
  id: string;
};

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const FAQ = () => {
  const { t } = useTranslation();

  const { drugModule } = useParams<Params>();
  const [activeSections, setActiveSections] = useState<number[]>([]);

  // This function receives the number of FAQ questions depending on selected drug.
  // For now it's set to 12 both enhertu and lynparza.
  const createOptions = (
    { num = 12 }: { num?: number } = {},
  ): Item[] => times(num, (i) => ({ id: i.toString() }));

  const keyExtractor = (item: Item) => item.id;

  const renderHeader = () => (item: Item, isActive: boolean) => {
    const { id } = item;
    const title = t(`contentModules:drug:common:faq:questions:q${id}:question`, { drug: capitalize(drugModule) as TDrugModule });

    return (
      <ListItem
        primaryTextClassName="header-text"
        className={isActive ? 'header-container' : ''}
        primaryText={title}
        leftIcon={<IconQuestionmarkCircle className="icon" fill={Colors.primary} />}
        rightIcon={
          isActive
            ? <IconChevronUp fill={Colors.primary} className="icon small" />
            : <IconChevronDown fill={Colors.primary} className="icon small" />
        }
      />
    );
  };

  const renderContent = () => (item: Item, isActive: boolean) => {
    const { id } = item;
    if (!isActive) {
      return null;
    }

    let link = drugModule === 'enhertu' ? 'enhertu@owise.uk' : 'lynparza@owise.uk';
    let link2 = { url: '', title: '' };

    if (id === '1') {
      link = 'privacy@owise.uk';
      link2 = {
        url: 'https://owise.uk/privacy/',
        title: `${t('contentModules:drug:common:faq:our-website')}`,
      };
    }

    return (
      <section className="accordion-section-container answer-text">
        <Trans
          i18nKey={`contentModules:drug:common:faq:questions:q${id}:answer`}
          values={{ link, drug: capitalize(drugModule), link2: link2.title }}
          components={[
            <a href={`mailto:${link}`}>{link}</a>,
            <a href={link2.url} target="_blank" rel="noreferrer">{link2.title}</a>,
          ]}
        />
      </section>
    );
  };

  return (
    <article className="page when-seek-help row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('contentModules:drug:common:faq:title')} showRightButton />
          <DrugModuleBreadcrumbs drugModule={drugModule} />
          <section className="when-seek-help-container">
            <h3>{t('contentModules:drug:common:faq:title')}</h3>
            <p className="faq-subtitle">{t('contentModules:drug:common:faq:body', { drug: capitalize(drugModule) })}</p>
            <FAQAccordion
              sections={createOptions()}
              activeSections={activeSections}
              onChange={setActiveSections}
              renderHeader={renderHeader()}
              renderContent={renderContent()}
              keyExtractor={keyExtractor}
            />
          </section>
        </section>

        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default LeaveDrugModuleSupportHoc(FAQ);
