import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TDrugModule } from 'types';
import capitalize from 'lodash/capitalize';

import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import DrawerRightHeader from '../components/DrawerRightHeader';
import Button from '../components/Button';
import Colors from '../theme/Colors';
import { ReactComponent as IconResearchWoman } from '../assets/icons/ResearchWoman.svg';
import SideEffectReportingBlock from '../components/SideEffectReportingBlock';
import { selectors as settingsSelectors, actions as settingsActions } from '../state/settings';
import '../css/DrugWelcomeScreen.css';
import Text from '../components/Text';
import { AppState } from '../state/reducers';

type Params = {
  invitationCode: string;
  drugModule: TDrugModule,
};

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const DrugWelcomeScreen = () => {
  const { t } = useTranslation();
  const location = useLocation<Params>();
  const dispatch = useDispatch();
  const history = useHistory();

  const { drugModule } = location.state;

  const allContentModules = useSelector(
    (state: AppState) => settingsSelectors.getDrugContentModules(state),
  );

  const onNoPressed = () => {
    try {
      dispatch(settingsActions.drugModulesRemove({
        removeCodes: false,
        invitationCodes: [allContentModules[drugModule].invitationCode] as string[],
        drugModule: drugModule as TDrugModule,
      }));
      history.push('/');
    } catch (error) {
      if (__DEV__) {
        console.log('[drugModuleRemove]', error); // eslint-disable-line no-console
      }
    }
  };

  const onYesPressed = () => {
    history.push({ pathname: `/support-menu/${drugModule}`, state: { isFromDrugWelcomeScreen: true } });
  };

  return (
    <article className="page diary row">
      <Navigation />
      <section className="container">
        <section className="content">

          <p className="text">
            {t('contentModules:drug:common:welcome-screen:header')}
            {' '}
            {t(`contentModules:drug:${drugModule}:welcome-screen:header`)}
          </p>

          <section className="centered-container">
            <h1 className="heading-large">{t(`contentModules:drug:${drugModule}:welcome-screen:title`)}</h1>
            <IconResearchWoman fill={Colors.drugModuleColors.primary[drugModule]} className="research-woman mt40" />
          </section>

          <section>
            <div>
              <p className="text">
                {'\n'}
                {t('contentModules:drug:common:welcome-screen:area-intended')}
                {' '}
                <span className="text outlined">
                  {t('contentModules:drug:common:welcome-screen:prescribed')}
                  {' '}
                  {`${capitalize(drugModule)}`}.
                </span>
              </p>
            </div>
            <br />
            <div>
              <p className="text">
                {t('contentModules:drug:common:welcome-screen:body-start')}
                {' '}
                {`${capitalize(drugModule)}`}
                {' '}
                {t('contentModules:drug:common:welcome-screen:body-end')}
              </p>
            </div>
          </section>

          <div className="list-header">
            <div className="btn-block">
              <Text
                type="button-accent"
                style={{ textDecorationLine: 'underline' }}
                onPress={onNoPressed}
              >
                {t('contentModules:drug:common:welcome-screen:button:no')}
              </Text>
            </div>
            <div className="btn-block">
              <Button labelText={t('contentModules:drug:common:welcome-screen:button:yes')} onClick={onYesPressed} />
            </div>
          </div>

          <SideEffectReportingBlock drugModule={drugModule} body="side-effect-reporting" />

          <Footer />
        </section>
      </section>
      <DrawerRight />

    </article>
  );
};

export default DrugWelcomeScreen;
