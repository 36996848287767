import React from 'react';
import { useTranslation } from 'react-i18next';

import { TReport } from '../../types';

import ListItem from './ListItem';
import dateFormat from '../utils/dateFormat';
import { ReactComponent as IconReport } from '../assets/icons/Report.svg';
import { ReactComponent as IconChevronRight } from '../assets/icons/ChevronRight.svg';
import Colors from '../theme/Colors';

type Props = {
  report: TReport;
  divider?: boolean;
  dividerOffset?: number;
};

const ReportListItem = (props: Props) => {
  const { i18n } = useTranslation();

  const { report, divider = true, dividerOffset = 0 } = props;

  return (
    <ListItem
      key={report.id}
      primaryText={report.title}
      secondaryText={dateFormat(i18n.language, report.createdAt)}
      leftIcon={<IconReport fill={Colors.primary} className="icon" />}
      rightIcon={<IconChevronRight fill={Colors.primary} className="icon small" />}
      divider={divider}
      dividerOffset={dividerOffset}
      to={`/reports/${report.id}`}
    />
  );
};

export default ReportListItem;
