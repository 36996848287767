import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import size from 'lodash/size';

import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRightHeader from '../components/DrawerRightHeader';
import '../css/TreatmentGuide.css';
import { actions as dailySuggestionsActions } from '../state/dailySuggestions';
import { TDrugModule } from '../../types';
import Button from '../components/Button';
import { getTreatmentGuideContent, treatmentGuideNavigation, treatmentGuidePDF } from '../utils/getTreatmenuGuideData';
import { selectors as tagsSelectors } from '../state/tags';
import { AppState } from '../state/reducers';
import LeaveDrugModuleSupportHoc from '../hocs/LeaveDrugModuleSupportHoc';
import DrugModuleBreadcrumbs from '../components/DrugModuleBreadcrumbs';

type Params = {
  drugModule: TDrugModule;
  navigateFrom?: string;
};

export const guideSuggestionIds = {
  enhertu: '378ce446-65ed-4e1c-9cb2-70371e7311a9',
  lynparza: '229ba1a6-2a9b-4ce9-9f54-99395ed956e5',
};

type TNavigation = {
  page: number;
  title: string;
};

type TPDF = {
  title: string;
  link: string;
};

type DrawerRightProps = {
  navigation: TNavigation[];
  pdf: TPDF;
  currentPage: number;
  setPage: (page: number) => void;
};
const DrawerRight = ({
  navigation, pdf, currentPage, setPage,
}: DrawerRightProps) => {
  const onClickItem = (page: number) => (e: React.MouseEvent) => {
    e.stopPropagation();
    setPage(page);
  };

  const navigateToPDF = (url: string) => () => {
    window.open(url, '_blank');
  };

  return (
    <Drawer position="right">
      <section>
        <DrawerRightHeader />
        <nav className="drawer-nav">
          {navigation.map((item) => (
            <section key={item.title} className={classNames('drawer-item', { selected: currentPage === item.page })}>
              <button
                className="nav-button"
                type="button"
                onClick={onClickItem(item.page)}
              >
                {item.title}
              </button>
            </section>
          ))}
          <section className={classNames('drawer-item')}>
            <button
              className="nav-button"
              type="button"
              onClick={navigateToPDF(pdf.link)}
            >
              {pdf.title}
            </button>
          </section>
        </nav>
      </section>
    </Drawer>
  );
};

const TreatmentGuide = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { drugModule, navigateFrom } = useParams<Params>();
  const [page, setPage] = useState(1);

  const memoizedTagsMakeGetValueByKey = useMemo(
    tagsSelectors.makeGetValueByKey,
    [],
  );

  const drugModules: TDrugModule[] = useSelector(
    (state: AppState) => memoizedTagsMakeGetValueByKey(state, 'drugModules'),
  ) as unknown as TDrugModule[];

  useEffect(() => {
    if (drugModule && drugModules.includes(drugModule)) {
      if (navigateFrom === 'side-effects') {
        setPage(4);
      } else {
        setPage(1);
      }

      dispatch(dailySuggestionsActions.trigger({
        ids: [guideSuggestionIds[drugModule]],
        forceClose: true,
      }));
    }
  }, [drugModules, drugModule, navigateFrom]);

  if (!drugModules.includes(drugModule)) {
    return null;
  }

  const handeChangePage = (variant: 'prev' | 'next') => () => {
    const treatmentGuideContainer = document.getElementById('treatmentGuideContainer')!;

    treatmentGuideContainer.scrollTop = 0;
    setPage((prev) => (variant === 'prev' ? prev - 1 : prev + 1));
  };

  return (
    <>
      <article className="page treatment-guide row">
        <Navigation />
        <section id="treatmentGuideContainer" className="container">
          <section className="content drug-moduletainer">
            <DrawerButtons title={t('contentModules:drug:common:treatment-guide')} showRightButton />
            <DrugModuleBreadcrumbs drugModule={drugModule} />
            <section className="treatment-guide-container">
              {getTreatmentGuideContent(drugModule, page)}
              <div className="row between buttons-block">
                <div>
                  {page > 1 && (
                  <Button
                    labelText={t('previous')}
                    size="medium"
                    onClick={handeChangePage('prev')}
                  />

                  )}
                </div>
                <div>
                  {page < size(treatmentGuideNavigation[drugModule]) && (
                  <Button
                    labelText={t('next')}
                    size="medium"
                    onClick={handeChangePage('next')}
                  />
                  )}
                </div>
              </div>
            </section>

          </section>
          <Footer />
        </section>
        <DrawerRight
          navigation={treatmentGuideNavigation[drugModule]}
          pdf={treatmentGuidePDF[drugModule]}
          currentPage={page}
          setPage={setPage}
        />
      </article>
    </>
  );
};

export default LeaveDrugModuleSupportHoc(TreatmentGuide);
