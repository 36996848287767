import { StyleSheet as RNStyleSheet } from 'react-native';

import Colors from '../../theme/Colors';

const styles = RNStyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.softerPrimary,
  },
  firstContent: {
    marginTop: 24,
  },
  content: {
    marginTop: 24,
    backgroundColor: Colors.white,
    position: 'relative',
  },
  lastContent: {
    marginBottom: 24,
  },
  numericInputContainer: {
    paddingLeft: 17,
    paddingRight: 11,
    paddingVertical: 8,
  },
  heading: {
    marginBottom: 8,
  },
  numericIcon: {
    color: Colors.white,
  },
  button: {
    borderRadius: 0,
  },
  modalHeading: {
    textAlign: 'center',
    marginBottom: 16,
  },
  modalText: {
    textAlign: 'center',
  },
  banner: {
    flex: 1,
    height: 'auto',
    backgroundColor: Colors.softPrimary,
    paddingHorizontal: 16,
    paddingVertical: 10,
    marginTop: 20,
  },
  bannerTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 6,
  },
  bannerIcon: {
    marginHorizontal: 4,
  },
  bannerTitle: {
    flex: 1,
    paddingRight: 24,
  },
});

export default styles;
