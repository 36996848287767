const prefix = 'TRENDS';

const ADD = `${prefix}/ADD`;
const CLEAR = `${prefix}/CLEAR`;
const CLEAR_DRUG_MODULE = `${prefix}/CLEAR_DRUG_MODULE`;

export {
  ADD,
  CLEAR,
  CLEAR_DRUG_MODULE,
};
