import * as actionTypes from './actionTypes';
import { TokenRefreshActionType } from './types';

const refresh = (): TokenRefreshActionType => ({
  type: actionTypes.REFRESH,
});

export {
  refresh, // eslint-disable-line import/prefer-default-export
};
