import addMinutes from 'date-fns/addMinutes';
import addHours from 'date-fns/addHours';
import addDays from 'date-fns/addDays';
import addWeeks from 'date-fns/addWeeks';
import addMonths from 'date-fns/addMonths';
import addYears from 'date-fns/addYears';

import { TDate } from '../../types';

import toDate from './toDate';

const dateAddDuration = (date: TDate, amount: number, unit: string): Date => {
  switch (unit) {
    case 'minutes': return addMinutes(toDate(date), amount);
    case 'hours': return addHours(toDate(date), amount);
    case 'days': return addDays(toDate(date), amount);
    case 'weeks': return addWeeks(toDate(date), amount);
    case 'months': return addMonths(toDate(date), amount);
    case 'years': return addYears(toDate(date), amount);
    default:
  }

  return toDate(date);
};

export default dateAddDuration;
